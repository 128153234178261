import React, {useContext, useState} from 'react'
import {AddOpenPositionLightbox} from './AddOpenPositionLightbox'

const OpenPositionContext = React.createContext<{addOpenPositionUnder: (idStr: string | null) => void} | null>(null)

export const useOpenPosition = () => {
    const openPositionContext = useContext(OpenPositionContext)
    if (!openPositionContext)
        throw new Error('Cannot use open position context outside of the <OpenPositionProvider />')

    return openPositionContext
}

export const OpenPositionProvider: React.FC = ({children}) => {
    const [managerIdStr, setManagerIdStr] = useState<string | null>(null)

    return (
        <OpenPositionContext.Provider value={{addOpenPositionUnder: setManagerIdStr}}>
            {children}
            {managerIdStr && (
                <AddOpenPositionLightbox
                    open={!!managerIdStr}
                    onClose={() => setManagerIdStr(null)}
                    managerIdStr={managerIdStr}
                />
            )}
        </OpenPositionContext.Provider>
    )
}
