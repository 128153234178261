import {colors, Spacer} from 'nf-ui'
import React from 'react'
import styled from 'styled-components'
import PlayStore from '~/components/playstore.svg'
import AppStore from '~/components/appstore.svg'

export const MEDIA_PANEL_HEIGHT = 248

const Video = styled.video`
    max-width: 100%;
    max-height: 100%;
    height: auto;
`

const Image = styled.img`
    width: 100%;
    height: auto;
`

const SvgWrapper = styled.div`
    :hover {
        cursor: pointer;
    }
`

const MobileContainer = styled.div`
    width: 100%;
    height: ${MEDIA_PANEL_HEIGHT - 8}px;
    background-color: ${colors.primary[100]};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
`

type StepContent = {
    title: string
    content: string
    buttonLabel: string
    media?: JSX.Element
    overlay?: boolean
}

const openLink = (link: string) => {
    window.open(link, '_blank')
}

export const STEP_CONTENT: Record<number, StepContent> = {
    1: {
        title: 'Welcome to Names & Faces',
        content: 'Names & Faces is your always up-to-date company directory and org chart.',
        buttonLabel: "Let's get started",
        media: (
            <Video autoPlay={true} loop={true} muted={true}>
                <source
                    type="video/mp4"
                    src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/1_welcome.mp4"
                />
            </Video>
        ),
    },
    2: {
        title: 'Find team members',
        content: 'Use filters & search to easily find team members in seconds.',
        buttonLabel: 'Next',
        media: (
            <Image
                src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/2_find_people.jpg"
                alt="Find team members"
            />
        ),
    },
    3: {
        title: 'Get in touch',
        content: 'Click to call, email or text when you are viewing a person’s profile.',
        buttonLabel: 'Next',
        media: (
            <Image
                src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/3_contact_people.jpg"
                alt="Get in touch"
            />
        ),
    },
    4: {
        title: 'Understand reporting lines',
        content: 'Explore the structure of your company through an interactive and printable org chart.',
        buttonLabel: 'Next',
        media: (
            <Video autoPlay={true} loop={true} muted={true}>
                <source
                    type="video/mp4"
                    src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/4_org_chart.mp4"
                />
            </Video>
        ),
    },
    5: {
        title: 'Update your profile',
        content: 'Upload a recent photo and add missing details to ensure your profile is up to date.',
        buttonLabel: 'Next',
        media: (
            <Image
                src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/5_edit_profile.jpg"
                alt="Update your profile"
            />
        ),
    },
    6: {
        title: 'Get mobile',
        content: 'Visit the app store and download Names & Faces for instant access on your mobile phone.',
        buttonLabel: 'Done',
        media: (
            <MobileContainer>
                <SvgWrapper onClick={() => openLink('https://itunes.apple.com/za/app/names-faces/id949875464')}>
                    <AppStore />
                </SvgWrapper>
                <Spacer width={24} />
                <SvgWrapper
                    onClick={() => openLink('https://play.google.com/store/apps/details?id=za.co.polymorph.namesfaces')}
                >
                    <PlayStore />
                </SvgWrapper>
            </MobileContainer>
        ),
        overlay: false,
    },
}
