import React from 'react'
import GoogleCalendar from '../../../components/Integrations/logos/GoogleCalendar.png'
import {IntegrationCard} from '~/components/Integrations/IntegrationCard'
import {GoogleButton} from '~/components/GoogleButton'
import {useGenerateGoogleCalendarCredentials, useGoogleCalendarOAuthUrl} from './useGoogleCalender'
import {useCleanupOnUnmount} from '~/components/useOAuthFlow/useCleanupOnUnmount'
import {createAuthWindow, OAuthSuccessType, waitForOAuthMessage} from '~/components/useOAuthFlow/authWindow'
import {useCalendarConnected} from './useCalendarConnected'
import styled from 'styled-components'
import {colors} from 'nf-ui'

const StyledIntegrationCard = styled(IntegrationCard)`
    background-color: ${colors.white};
`

export const ConnectCalendarCard = () => {
    const {setConnected} = useCalendarConnected()
    const {oAuthUrl, loading} = useGoogleCalendarOAuthUrl()
    const {generateCredentials} = useGenerateGoogleCalendarCredentials()
    const cleanupRef = useCleanupOnUnmount()

    const onClick = async () => {
        if (loading || !oAuthUrl) return

        const authWindow = createAuthWindow(oAuthUrl)
        if (!authWindow) throw new Error('Could not create auth window')

        const oAuthResponse = await waitForOAuthMessage(authWindow, cleanupRef)
        if (oAuthResponse.type === OAuthSuccessType) {
            await generateCredentials(oAuthResponse.code)
            setConnected(true)
        }
    }

    return (
        <StyledIntegrationCard
            logo={GoogleCalendar}
            label={'Google calendar'}
            text={'Connect your account to see who you meeting with.'}
            topRightContent={<GoogleButton label="Connect with Google" onClick={onClick} />}
        />
    )
}
