import {Button, Spacer, Typography} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React from 'react'
import {useSignout} from './components/useSignout'
import {WizardLayout} from './components/WizardLayout'

export const OrganisationNoAccess: React.FC = () => {
    const {signOut} = useSignout()

    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <Typography.Heading>No directories</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading> You don't have access to any directories</Typography.Subheading>
                <Spacer height={32} />
                <Button onClick={signOut}>
                    Sign out <Button.Icon icon={SvgArrowRight} />
                </Button>
            </WizardLayout.Body>
        </WizardLayout>
    )
}
