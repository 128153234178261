import {differenceInCalendarDays} from 'date-fns'
import {useEffect, useRef} from 'react'

const SESSION_KEY = 'DAILY_APP_SESSION'

type SessionState = {
    sessionCount: number
    lastSession: number | null
}

const DEFAULT_STATE: SessionState = {
    sessionCount: 0,
    lastSession: null,
}

const getSessionState = (): SessionState => {
    const state = localStorage.getItem(SESSION_KEY)

    return state ? JSON.parse(state) : DEFAULT_STATE
}

const logSession = (currentState: SessionState) => {
    localStorage.setItem(
        SESSION_KEY,
        JSON.stringify({
            sessionCount: currentState.sessionCount + 1,
            lastSession: new Date().getTime(),
        }),
    )
}

const dayHasPassed = (timestamp: number) => {
    const difference = differenceInCalendarDays(new Date(), new Date(timestamp))
    return difference >= 1
}

const logNewDailySession = () => {
    const storedState = getSessionState()

    if (!storedState.lastSession || dayHasPassed(storedState.lastSession)) {
        logSession(storedState)
    }
}

export const dailySessionCount = () => getSessionState().sessionCount

export const useDailySession = () => {
    const loggedSession = useRef(false)

    useEffect(() => {
        if (loggedSession.current) return

        logNewDailySession()

        loggedSession.current = true
    }, [])
}
