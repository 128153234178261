import {motion} from 'framer-motion'
import React, {ReactNode} from 'react'
import {CollapsibleBody} from './CollapsibleBody'
import {CollapsibleContext} from './CollapsibleContext'
import {CollapsibleGroup, useCollapsibleGroupContext} from './CollapsibleGroup'
import {CollapsibleHeader} from './CollapsibleHeader'

const Collapsible = ({children, index}: {children: ReactNode; index: number}) => {
    const {expandedIndex} = useCollapsibleGroupContext()
    const expanded = expandedIndex === index

    const currentVariant = expanded ? 'expanded' : 'collapsed'

    return (
        <CollapsibleContext.Provider value={index}>
            <motion.div initial={currentVariant} animate={currentVariant}>
                {children}
            </motion.div>
        </CollapsibleContext.Provider>
    )
}

Collapsible.Body = CollapsibleBody
Collapsible.Header = CollapsibleHeader

export {CollapsibleGroup, Collapsible}
