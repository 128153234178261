import React, {useEffect, useState} from 'react'
import {useMutationRequestCommunityBuildOtp, useQueryForms} from '~/apollo/queries/Forms'
import {
    useMutationGiveCommunityBuildConsent,
    useMutationUpdateCommunityBuildDraftProfile,
    useMutationUploadCommunityBuildPhotos,
} from '~/apollo/queries/Responses'
import {Token} from '../TokenContext'
import {ResumeSubmission} from './ResumeSubmission'
import {useMutationSignInOtp} from '~/apollo/queries/SignInOtp'
import {CommunityBuildFlow, Page, PageState} from './CommunityBuildFlow'
import {DraftProfileInput, RegionInput} from '~/globalTypes'
import {mergeFormState} from '../Primitives/Forms'

export const CommunityBuild = ({formIdStr}: {formIdStr: string}) => {
    const [page, setPage] = useState<Page>('loading')
    const [pageState, setPageState] = useState<PageState>({
        errorState: {},
        formState: {},
    })

    const [{data: formsData}] = useQueryForms(formIdStr, null)
    const [, callRequestOtp] = useMutationRequestCommunityBuildOtp(formIdStr)
    const [, callSignInOtp] = useMutationSignInOtp()
    const [, callUpdateDraftProfile] = useMutationUpdateCommunityBuildDraftProfile()
    const [, callUploadPhoto] = useMutationUploadCommunityBuildPhotos()
    const [, callGiveConsent] = useMutationGiveCommunityBuildConsent()
    const {setToken} = Token.useContainer()

    useEffect(() => {
        if (formsData?.forms?.length) {
            setPageState({
                ...pageState,
                directoryName: formsData?.forms[0]?.directoryName,
                organisationIdStr: formsData?.forms[0]?.organisationIdStr,
                masterDirectoryIdStr: formsData?.forms[0]?.masterDirectoryIdStr || undefined,
            })
            setPage('sign-up')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formsData])

    if (page === 'resume-submission') {
        return (
            <ResumeSubmission
                formIdStr={formIdStr}
                emailAddress={pageState.emailAddress!}
                onNext={(responseIdStr, formState) => {
                    setPageState({
                        ...pageState,
                        responseIdStr,
                        formState: mergeFormState(pageState.formState, formState),
                        errorState: {},
                    })
                    setPage('pupil-details')
                }}
            ></ResumeSubmission>
        )
    }

    return (
        <CommunityBuildFlow
            page={page}
            setPage={setPage}
            pageState={pageState}
            setPageState={setPageState}
            formFields={formsData?.forms?.[0]?.fields || []}
            requestOtp={async (emailAddress: string) => {
                await callRequestOtp({emailAddress})
            }}
            signInOtp={async (otp: string, email: string) => {
                const result = await callSignInOtp({otp, email})
                result && setToken(result)
                return !!result
            }}
            updateDraftProfile={async (
                responseIdStr: string,
                emailAddress: string,
                draftProfile: DraftProfileInput[],
            ) => {
                await callUpdateDraftProfile({
                    responseIdStr,
                    emailAddress,
                    draftProfile,
                })
            }}
            uploadPhotoHousehold1={async (
                responseIdStr: string,
                emailAddress: string,
                originalFile: File,
                thumbRegion?: RegionInput,
            ) => {
                await callUploadPhoto({
                    responseIdStr,
                    emailAddress,
                    originalFile,
                    thumbRegion,
                    photoIndex: 0,
                })
            }}
            uploadPhotoHousehold2={async (
                responseIdStr: string,
                emailAddress: string,
                originalFile: File,
                thumbRegion?: RegionInput,
            ) => {
                await callUploadPhoto({
                    responseIdStr,
                    emailAddress,
                    originalFile,
                    thumbRegion,
                    photoIndex: 1,
                })
            }}
            giveConsent={async (responseIdStr: string, emailAddress: string) => {
                await callGiveConsent({
                    responseIdStr,
                    emailAddress,
                })
            }}
        />
    )
}
