import React from 'react'
import {AnimatePresenceProps, AnimatePresence, motion} from 'framer-motion'

/**
 * Works like AnimatePresence, but with fading built in.
 */
export const FadePresence: React.FC<AnimatePresenceProps & {itemKey?: React.Key; className?: string}> = ({
    children,
    itemKey,
    className,
    ...animatePresenceProps
}) => {
    return (
        <AnimatePresence {...animatePresenceProps}>
            {children && (
                <motion.div
                    className={className}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.25}}
                    key={itemKey}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    )
}
