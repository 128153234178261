import {gql} from '@apollo/client'
import {GraphQLError} from 'graphql'
import {asyncSemaphore} from '~/util/async'
import {SwapTokens} from './__types__/SwapTokens'

const SWAP_TOKENS = gql`
    mutation SwapTokens($accessToken: String!) {
        swapTokens(accessToken: $accessToken) {
            accessToken
        }
    }
`
export type CustomFetch = {
    analyticsId: string | null
    token: string
    setToken: (token: string) => void
    signOut: () => void
}

const swapTokens = asyncSemaphore(async ({token, setToken, signOut}: CustomFetch) => {
    const res = await fetch(`${process.env.API_URL}/graphql`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            //@ts-ignore
            operationName: SWAP_TOKENS.definitions[0].name.value,
            variables: {accessToken: token},
            query: SWAP_TOKENS.loc?.source.body,
        }),
    })

    if (!res.ok) throw new Error(`Token failed with code ${res.status}`)
    const {errors, data}: {errors: GraphQLError[]; data: SwapTokens} = await res.json()

    if (errors && errors.length) {
        signOut()
        throw new Error(errors[0].message)
    }

    const newToken = data.swapTokens.accessToken
    setToken(newToken)
    return newToken
})

const REFRESH_ERROR_CODES = ['TOKEN_INVALID', 'TOKEN_EXPIRED']

export const customFetch: (args: CustomFetch) => WindowOrWorkerGlobalScope['fetch'] = (fetchArgs) => async (
    uri,
    options,
) => {
    const {token, analyticsId} = fetchArgs
    const initialFetch = await fetch(uri, {
        ...options,
        headers: {
            ...options?.headers,
            Authorization: token ? `Bearer ${token}` : '',
        },
    })

    const initialResponse = await initialFetch.clone().json()

    const accessDeniedErrors = initialResponse.errors?.some((error: GraphQLError) =>
        REFRESH_ERROR_CODES.includes(error.extensions?.code),
    )

    if (!accessDeniedErrors) return initialFetch

    const newAccessToken = await swapTokens(fetchArgs)

    return fetch(uri, {
        ...options,
        headers: {
            ...options?.headers,
            Authorization: token ? `Bearer ${newAccessToken}` : '',
            'X-ANALYTICS-ID': analyticsId ?? '',
        },
    })
}
