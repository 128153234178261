import {List, Typography, useAnalyticsEventTracker} from 'nf-ui'
import React, {FC} from 'react'
import styled from 'styled-components'
import {PanelLayout} from '~/components/PanelLayout'
import {PANEL_WIDTH} from '~/constants'

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

type SettingsPanelProps = {onClose: () => void; autoInvite: boolean; autoInviteOnClick: () => void}

export const SettingsPanel: FC<SettingsPanelProps> = ({onClose, autoInvite, autoInviteOnClick}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const settings = [
        {
            label: 'Auto-invite...',
            analyticsEvent: autoInvite ? 'select_auto_invite_off' : 'select_enable_auto_invite',
            onClick: () => {
                autoInviteOnClick()
                onClose()
            },
        },
    ]

    return (
        <PanelLayout width={PANEL_WIDTH}>
            <Typography.Label>User management settings</Typography.Label>
            <List
                width={PANEL_WIDTH}
                rows={settings}
                renderRow={(setting) => (
                    <ActionRow
                        onClick={() => {
                            setting.onClick()
                            trackAnalyticsEvent(setting.analyticsEvent)
                        }}
                    >
                        <div>{setting.label}</div>
                    </ActionRow>
                )}
            ></List>
        </PanelLayout>
    )
}
