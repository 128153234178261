import {gql, useMutation} from '@apollo/client'
import {HTMLAttributes, useRef} from 'react'
import {useAlert} from 'react-alert'
import {DATA_SOURCES} from '~/pages/Adminland/Integrations'
import {getGraphQLErrorMessage} from '~/util'
import {useOrganisationIdStr} from '../useOrganisationIdStr'
import {DataSourceCardReUpload, DataSourceCardReUploadVariables} from './__types__/DataSourceCardReUpload'
import {DataSourceCard_DataSource} from './__types__/DataSourceCard_DataSource'

const RE_UPLOAD_DATA = gql`
    mutation DataSourceCardReUpload($dataSourceIdStr: String!, $fileUpload: Upload!) {
        reUploadData(dataSourceIdStr: $dataSourceIdStr, fileUpload: $fileUpload)
    }
`

export const useUploadData = ({
    dataSource,
    closePanel,
}: {
    dataSource: DataSourceCard_DataSource
    closePanel: () => void
}) => {
    const alert = useAlert()

    const organisationIdStr = useOrganisationIdStr()
    const [reUploadData, {loading}] = useMutation<DataSourceCardReUpload, DataSourceCardReUploadVariables>(
        RE_UPLOAD_DATA,
    )

    const ref = useRef<HTMLInputElement>(null)

    const onChange: HTMLAttributes<HTMLInputElement>['onChange'] = async (event) => {
        try {
            const files = event.currentTarget.files || []
            const fileUpload = files[0]
            if (!fileUpload) return

            event.currentTarget.value = ''

            await reUploadData({
                variables: {
                    dataSourceIdStr: dataSource.idStr,
                    fileUpload,
                },
                refetchQueries: [{query: DATA_SOURCES, variables: {organisationIdStr}}],
                awaitRefetchQueries: true,
            })
            closePanel()
            alert.success('Data uploaded successfully.')
        } catch (error) {
            closePanel()
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    return {
        loading,
        inputProps: {
            ref,
            type: 'file',
            accept: '.csv,.xls,.xlsx',
            onChange,
            hidden: true,
        },
        openPicker: () => ref.current && ref.current.click(),
    }
}
