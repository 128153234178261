import {gql, useQuery} from '@apollo/client'
import {HomeItemFragment} from './HomeItemFragment'
import {AllHomeItemQuery, AllHomeItemQueryVariables} from './__types__/AllHomeItemQuery'

const ALL_HOME_ITEM_QUERY = gql`
    query AllHomeItemQuery($idStr: String!) {
        organisation(idStr: $idStr) {
            idStr
            ...HomeItemData
        }
    }
    ${HomeItemFragment}
`

export const useQueryAllHomeItemData = (organisationIdStr: string) => {
    const query = useQuery<AllHomeItemQuery, AllHomeItemQueryVariables>(ALL_HOME_ITEM_QUERY, {
        variables: {
            idStr: organisationIdStr,
        },
    })

    return [query]
}
