import * as React from 'react'
const SvgDelete = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgDelete(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M9 0H7.4a2.183 2.183 0 00-2.195 2.033L5.2 2.19v.3H1.6c-.338 0-.6.242-.6.53 0 .26.212.481.5.523l.1.007h1.1v10.261c0 1.163.89 2.105 2.041 2.185L4.9 16h6.6a2.183 2.183 0 002.195-2.033l.005-.157V3.55h1.1c.339 0 .6-.241.6-.53 0-.26-.212-.481-.501-.523L14.8 2.49h-3.6v-.3c0-1.163-.89-2.105-2.041-2.185L9 0zm3.6 3.55v10.261c0 .597-.39 1.064-.972 1.124l-.128.006H4.9c-.616 0-1.127-.435-1.193-1.006l-.007-.124V3.55h8.9zM6.5 5.81c-.22 0-.444.199-.491.439L6 6.34v5.811c0 .278.251.53.5.53.304 0 .547-.196.592-.445l.008-.085V6.34c0-.288-.262-.53-.6-.53zm3.4 0c-.304 0-.547.196-.592.445L9.3 6.34v5.811c0 .288.262.53.6.53.22 0 .444-.199.491-.439l.009-.091V6.34c0-.278-.251-.53-.5-.53zM9 1.06c.616 0 1.127.435 1.193 1.006l.007.124v.3h-4v-.3c0-.588.47-1.063 1.07-1.123l.13-.007H9z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgDelete
