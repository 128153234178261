import {measureTextWidth} from '../../util'
import {Data} from '../Table'
import {HEADER_FONT_WEIGHT, COLUMN_MAX_WIDTH, CELL_FONT_WEIGHT, CELL_PADDING, BORDER_WIDTH} from '../constants'

/**
 * Given a set of data, measure the text width for each column value,
 * then take the longest measurement and use that for the column width.
 *
 * The column width can't exceed `COLUMN_MAX_WIDTH`.
 *
 * This is used to auto-size columns.
 */
export const getColumnWidths = (data: Data) => {
    const columns = Object.keys(data[0])

    const columnWidths: number[] = []

    for (const column of columns) {
        let columnWidth = measureTextWidth(column, {fontWeight: HEADER_FONT_WEIGHT})

        for (const row of data) {
            if (columnWidth >= COLUMN_MAX_WIDTH) {
                columnWidth = COLUMN_MAX_WIDTH
                break
            }

            const width = measureTextWidth(row[column], {fontWeight: CELL_FONT_WEIGHT})
            if (width > columnWidth) columnWidth = width
        }

        columnWidths.push(columnWidth + CELL_PADDING * 2 + BORDER_WIDTH * 2)
    }

    return columnWidths
}
