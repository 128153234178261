import React, {useRef, useContext} from 'react'
import {Manager} from 'react-popper'
import styled from 'styled-components'

const ContainerContext = React.createContext<React.RefObject<HTMLDivElement> | null>(null)

export const useToolTipContainer = () => {
    const toolTipContainer = useContext(ContainerContext)

    if (!toolTipContainer) {
        throw new Error('Your component tree must be wrapped with at least one <TooltipContext /> element.')
    }

    return toolTipContainer
}

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10001;
    width: 100% !important;
    height: 0 !important;
`

const TooltipContext: React.FC = ({children}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    return (
        <ContainerContext.Provider value={containerRef}>
            <Manager>
                {children}
                <Container ref={containerRef} />
            </Manager>
        </ContainerContext.Provider>
    )
}

export {TooltipContext}
