import {useCallback, useState} from 'react'

/**
 * This hook is used to force a rerender of a component.
 * It generally shouldn't be used unless some caching outside of your control breaks, apollo for example.
 *
 * @export
 * @returns function that when called will rerender the component
 */
export function useForceRender() {
    const [, setValue] = useState(0)
    return useCallback(() => setValue((v) => v + 1), [setValue])
}
