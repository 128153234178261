import useWindowSize from '@rooks/use-window-size'
import {useRef} from 'react'
import {FOOTER_HEIGHT} from '~/components/ModalLayout/Footer'

const MODAL_BODY_WIDTH = 960
const TABLE_TOP_OFFSET = 259

const DEFAULT_OPTIONS = {
    offsetHeight: 0,
    offsetWidth: 0,
    useFullWidth: false,
}

export type FullScreenTableOptions = Partial<typeof DEFAULT_OPTIONS>

export const useFullScreenTable = (options: FullScreenTableOptions = {}) => {
    const {offsetHeight, offsetWidth, useFullWidth} = {...DEFAULT_OPTIONS, ...options}
    const ref = useRef<HTMLDivElement>(null)
    const {innerHeight, innerWidth} = useWindowSize() as {innerHeight: number; innerWidth: number}

    const width = useFullWidth
        ? innerWidth + offsetWidth
        : (innerWidth - MODAL_BODY_WIDTH) / 2 + MODAL_BODY_WIDTH + offsetWidth
    const height = innerHeight - TABLE_TOP_OFFSET - FOOTER_HEIGHT + offsetHeight

    return {ref, height, width}
}
