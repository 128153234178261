import {Document, Font, Page, PDFViewer, StyleSheet} from '@react-pdf/renderer'
import {useTheme} from 'nf-ui'
import React, {ReactElement, useState} from 'react'
import {DefaultTheme} from 'styled-components'
import {PageLoading} from '~/components/PageLoading'
import {DisplayOptionsProvider} from '../FullOrgChart/useDisplayOptions'
import {FullOrgChartData_organisation} from '../__types__/FullOrgChartData'
import {GUTTER, Orientation, PAGE_PADDING, Profile} from './constants'
import halyard from './fonts/HalyardDisBook.otf'
import halyardBold from './fonts/HalyardDisMed.otf'
import publicoBanner from './fonts/PublicoBanner-Light.otf'
import {VerticalLine} from './getVerticalLines'
import {PageHeader} from './PageHeader'
import {ProfileBrick} from './ProfileBrick'
import {ProfileGrid} from './ProfileGrid'
import {ProfileRow} from './reportingLine'
import {Spacer} from './Spacer'
import {useBuildPdfData} from './useBuildPdfData'
import {PrintContext, usePrintContext} from './usePrintContext'
import {VerticalLines} from './VerticalLines'

Font.register({family: 'halyard', src: halyard})
Font.register({family: 'halyard-bold', src: halyardBold})
Font.register({family: 'publico-banner', src: publicoBanner})

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: `${PAGE_PADDING}px`,
        backgroundColor: '#fff',
    },
})

const PDFDocument = ({
    onRender,
    paginatedProfileRows,
    solidLines,
    dottedLines,
}: {
    onRender: () => void
    paginatedProfileRows: ProfileRow[][] | null
    solidLines: VerticalLine[]
    dottedLines: VerticalLine[] | null
}) => {
    const {orientation} = usePrintContext()
    const hasVerticalLines = solidLines.length || dottedLines?.length

    return (
        <Document onRender={onRender}>
            {paginatedProfileRows?.map((profileRows, index) => {
                const isFirstPage = index === 0

                return (
                    <Page size="LETTER" orientation={orientation} style={styles.page} key={index}>
                        {hasVerticalLines && (
                            <VerticalLines solidLines={solidLines} dottedLines={dottedLines} page={index + 1} />
                        )}
                        {isFirstPage && <PageHeader />}
                        {profileRows.map((row, rowIndex) => {
                            const isSingleProfile = row.profiles.length === 1
                            return (
                                <React.Fragment key={`row_${rowIndex}`}>
                                    {isSingleProfile ? (
                                        <ProfileBrick
                                            profile={row.profiles[0]}
                                            indentation={row.indentation}
                                            reportsCount={row.reportsCount}
                                            additionalReport={!!row.additional}
                                            parentIdStr={row.parentIdStr}
                                            collapsedCount={row.collapsedCount}
                                        />
                                    ) : (
                                        <ProfileGrid
                                            profiles={row.profiles}
                                            indentation={row.indentation}
                                            page={index + 1}
                                        />
                                    )}
                                    {rowIndex !== profileRows.length - 1 && <Spacer height={GUTTER} />}
                                </React.Fragment>
                            )
                        })}
                    </Page>
                )
            })}
        </Document>
    )
}

const OrgChartWithData: React.FC<{
    showDocument: () => void
    profiles: Profile[]
    getProfileFromIdStr: (profileIdStr: string) => Profile | null
    orientation: Orientation
    organisation: FullOrgChartData_organisation
    theme: DefaultTheme
}> = ({showDocument, profiles, getProfileFromIdStr, orientation, organisation, theme}) => {
    const {paginatedProfileRows, solidLines, dottedLines, indentationWidth, loading} = useBuildPdfData({
        profiles,
        getProfileFromIdStr,
        orientation,
    })

    if (loading || !solidLines) return null

    return (
        <PrintContext.Provider initialState={{orientation, theme, organisation, indentationWidth, getProfileFromIdStr}}>
            <PDFDocument
                onRender={showDocument}
                paginatedProfileRows={paginatedProfileRows}
                solidLines={solidLines}
                dottedLines={dottedLines}
            />
        </PrintContext.Provider>
    )
}

type RenderProps = {showDocument: () => void}

const RenderLoadingWrapper: React.FC<{children: (props: RenderProps) => ReactElement}> = ({children}) => {
    const [rendered, setRendered] = useState(false)
    const showDocument = () => {
        if (!rendered) setRendered(true)
    }

    return (
        <>
            {!rendered && <PageLoading />}
            {children({showDocument})}
        </>
    )
}

export const OrgChartPdf = React.memo(
    (props: {
        profiles: Profile[]
        getProfileFromIdStr: (profileIdStr: string) => Profile | null
        organisation: FullOrgChartData_organisation
        orientation: Orientation
    }) => {
        const theme = useTheme()
        const {organisation} = props

        return (
            <RenderLoadingWrapper>
                {({showDocument}) => (
                    <PDFViewer style={{width: '100vw', height: '100vh'}}>
                        <DisplayOptionsProvider organisation={organisation}>
                            <OrgChartWithData showDocument={showDocument} theme={theme} {...props} />
                        </DisplayOptionsProvider>
                    </PDFViewer>
                )}
            </RenderLoadingWrapper>
        )
    },
)
