import {Button, Typography, useTheme} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import pluralize from 'pluralize'
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {ListChildComponentProps, VariableSizeList} from 'react-window'
import styled from 'styled-components'
import {genericMemo} from '~/util/genericMemo'
import {useScrollBooster} from './HorizontalProfilesList/useScrollbooster'
import {PHOTO_HEIGHT, PHOTO_WIDTH} from './Photo/Photo'
import {MemoizedProfile} from './Profile'
import {useSortProfiles} from './SortProfiles'
import {useOrganisationIdStr} from './useOrganisationIdStr'
import {useRelativeRoute} from './useRelativeRoute'
import {LinesWithValuesByProfile, getThumbLinesByProfile} from './ProfilesGrid/ProfilesGrid'
import {MaybeSearchResult} from '~/util/search'
import {TProfile, OrganisationDataWithTypes, useOrganisationContext} from './OrganisationContext'
import {useCurrentOrganisation} from './CurrentOrganisationContext'

const GAP = 8
const SCROLLBAR_HEIGHT = 16

const HeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const StyledVariableSizeList = styled(VariableSizeList)<{padding: number}>`
    display: flex;
    margin: 0 -${(props) => props.padding}px -${SCROLLBAR_HEIGHT}px;
    cursor: move;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }

    > div {
        padding-right: ${({padding}) => padding * 2 - GAP}px;
        position: absolute;
        pointer-events: initial !important;
    }
`

const getOffsetForProfile = (index: number) => {
    return index * (PHOTO_WIDTH + GAP)
}

const ListItem: React.FC<ListChildComponentProps> = ({data, index, style}) => {
    const {profiles, padding, scrollIntoView, scrollTo, lines, onClickItem, highlightedProfileIdStr} = data

    if (profiles[index] === 'spacer') {
        return (
            <div
                style={{
                    ...style,
                    left: Number(style.left) + padding,
                }}
            />
        )
    }

    return (
        <MemoizedProfile
            style={{...style, left: Number(style.left) + padding}}
            onClick={() => {
                if (scrollIntoView) {
                    scrollTo(getOffsetForProfile(index - 1))
                }

                onClickItem && onClickItem()
            }}
            profile={profiles[index]}
            lines={lines[profiles[index].idStr]}
            highlightedProfileIdStr={highlightedProfileIdStr}
        />
    )
}

type HorizontalScrollerAlignment = 'left' | 'center'

export type HorizontalProfilesListMethods = {
    resetScrollPosition: () => void
}

type HorizontalProfilesListProps = {
    padding?: number
    label?: string
    childCategoryIdStr?: string
    alignment?: HorizontalScrollerAlignment
    scrollIntoView?: boolean
    initialCenteredItem?: TProfile
    highlightedProfileIdStr?: string
    testId?: string
    analyticsEvent?: {event: string; properties: Record<any, any>}
    listRef?: React.RefObject<HorizontalProfilesListMethods>
    onClickItem?: () => void
} & OrganisationDataWithTypes

type ListContainerProps = {
    profiles: MaybeSearchResult<TProfile>[]
    width: number
    setShowLink: (showLink: boolean) => void
    variableSizeListRef: React.RefObject<VariableSizeList>
    resetListOffsets: () => void
    lines: LinesWithValuesByProfile
} & Pick<
    HorizontalProfilesListProps,
    | 'alignment'
    | 'padding'
    | 'initialCenteredItem'
    | 'highlightedProfileIdStr'
    | 'scrollIntoView'
    | 'analyticsEvent'
    | 'listRef'
    | 'onClickItem'
>

function ListContainer({
    width,
    setShowLink,
    profiles,
    lines,
    alignment,
    padding = 0,
    variableSizeListRef,
    resetListOffsets,
    initialCenteredItem,
    highlightedProfileIdStr,
    scrollIntoView,
    analyticsEvent,
    listRef: ref,
    onClickItem,
}: ListContainerProps) {
    const profilesData = alignment === 'center' ? ['spacer', ...profiles, 'spacer'] : profiles

    useEffect(resetListOffsets, [profilesData.length])

    useEffect(() => {
        setShowLink(width < profiles.length * (PHOTO_WIDTH + GAP))
    }, [width, profiles, setShowLink])

    let initialScrollOffset = alignment === 'center' ? (profiles.length * (PHOTO_WIDTH + GAP)) / 2 - padding * 2 : 0

    const centeredItemIndex = profiles.findIndex((profile) => profile === initialCenteredItem)
    if (centeredItemIndex > -1) {
        initialScrollOffset = getOffsetForProfile(centeredItemIndex)
    }

    const calculateSize = (index: number) => {
        if (profilesData[index] === 'spacer') return (width - PHOTO_WIDTH) / 2
        return PHOTO_WIDTH + GAP
    }

    const lastScrollTo = useRef(initialScrollOffset)

    const {scrollBooster} = useScrollBooster({variableSizeListRef, analyticsEvent, initialScrollOffset})

    const scrollTo = (x: number) => {
        scrollBooster.current?.scrollTo({x})
        lastScrollTo.current = x
    }

    useImperativeHandle(ref, () => ({
        resetScrollPosition: () => {
            scrollTo(lastScrollTo.current)
        },
    }))

    return (
        <StyledVariableSizeList
            ref={variableSizeListRef}
            className="list"
            height={PHOTO_HEIGHT + SCROLLBAR_HEIGHT}
            itemCount={profilesData.length}
            itemData={{
                profiles: profilesData,
                lines,
                padding,
                scrollIntoView,
                variableSizeListRef,
                highlightedProfileIdStr,
                scrollTo,
                onClickItem,
            }}
            itemSize={calculateSize}
            layout="horizontal"
            width={width + padding * 2}
            overscanCount={20}
            padding={padding}
        >
            {ListItem}
        </StyledVariableSizeList>
    )
}

const MemoizedList = genericMemo(ListContainer)

export function HorizontalProfilesList({
    label,
    childCategoryIdStr,
    testId,
    ...listContainerProps
}: HorizontalProfilesListProps) {
    const [dataEditValues] = useOrganisationContext.useDataEditValues()
    const [dataEditFields] = useOrganisationContext.useDataEditFields()
    const [profileLines] = useOrganisationContext.useProfileLines()
    const {currentOrganisation} = useCurrentOrganisation()

    const lines = getThumbLinesByProfile(
        {...listContainerProps, dataEditValues, dataEditFields, profileLines},
        {
            numberOfLines: 2,
            removeProfileLineGaps: currentOrganisation?.appFeatures?.masterDirectory,
        },
    )
    const profiles = useSortProfiles(listContainerProps.profiles)
    const organisationId = useOrganisationIdStr()
    const [showLink, setShowLink] = useState(false)
    const themeContext = useTheme()
    const {pushRelative} = useRelativeRoute(`/${organisationId}`)

    const variableSizeListRef = useRef<VariableSizeList>(null)

    const resetListOffsets = () => {
        variableSizeListRef.current?.resetAfterIndex(0, true) //https://github.com/bvaughn/react-window/issues/344
    }

    return (
        <div data-testid={testId}>
            <HeadingContainer>
                {label && <Typography.Paragraph bottomMargin={false}>{label}</Typography.Paragraph>}
                {childCategoryIdStr && showLink && (
                    <Button
                        variant="tertiary"
                        style={{marginRight: -16}}
                        color={themeContext.primary.color}
                        onClick={() => pushRelative(`/childCategory/${childCategoryIdStr}`, {keepSearch: true})}
                    >
                        {`View ${profiles.length} ${pluralize('people', profiles.length)}`}
                        <Button.Icon icon={SvgArrowRight} />
                    </Button>
                )}
            </HeadingContainer>
            <AutoSizer onResize={resetListOffsets} disableHeight>
                {({width}) => (
                    <MemoizedList
                        width={width}
                        setShowLink={setShowLink}
                        variableSizeListRef={variableSizeListRef}
                        resetListOffsets={resetListOffsets}
                        {...listContainerProps}
                        profiles={profiles}
                        lines={lines}
                    />
                )}
            </AutoSizer>
        </div>
    )
}
