import {useTheme} from 'nf-ui/NFThemeProvider'
import React from 'react'
import {ModalLayout} from '~/components/ModalLayout'
import {PanelNavigatorProvider} from '~/components/PanelNavigator'
import {GroupMessageModalBody} from './GroupMessageModal/GroupMessageModalBody'

export const GroupMessageModal: React.FC = () => {
    const theme = useTheme()

    return (
        <ModalLayout>
            <ModalLayout.Navigation closeColor={theme.primary.color} />
            <ModalLayout.Body>
                <PanelNavigatorProvider>
                    <GroupMessageModalBody />
                </PanelNavigatorProvider>
            </ModalLayout.Body>
        </ModalLayout>
    )
}
