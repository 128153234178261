import {useMotionValue, useTransform} from 'framer-motion'
import {useCallback} from 'react'

export type TableScrollState = ReturnType<typeof useTableScroll>

export const useTableScroll = () => {
    const top = useMotionValue(0)
    const left = useMotionValue(0)

    const onScroll = useCallback(
        (event: {scrollTop: number; scrollLeft: number}) => {
            top.set(event.scrollTop)
            left.set(event.scrollLeft)
        },
        [top, left],
    )

    const inverse = {
        top: useTransform(top, (v) => v * -1),
        left: useTransform(left, (v) => v * -1),
    }

    return {position: {top, left}, onScroll, inverse}
}
