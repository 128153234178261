import React, {InputHTMLAttributes} from 'react'
import {useGroupContext} from './Group'
import styled, {css} from 'styled-components'
import {colors} from '../theme'
import {Typography, paragraphFontStyle} from '../Typography'
import {Icon} from '../Icon'
import SvgClose from '../Icons/Close'
import SvgCaret from 'nf-ui/Icons/Caret'

export interface InputProps
    extends Omit<
        InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        'onChange' | 'value'
    > {
    error?: string
    icon?: React.ReactNode
    clearButton?: boolean
    onChange?: (value: string) => void
    value?: string | number
    width?: number | string
    highlighted?: boolean
    containerStyle?: React.CSSProperties
    inputRef?: React.Ref<HTMLInputElement>
    placeholder?: string
    ariaLabel?: string
    useTheme?: boolean
    textarea?: boolean
    isSelect?: boolean
}

export const Container = styled.div<Pick<InputProps, 'width'>>`
    width: ${({width = '100%'}) => (typeof width === 'string' ? width : `${width}px`)};
    display: flex;
    flex-direction: column;
    position: relative;
`

const inputStyle = css<{error: boolean; hasIcon: boolean; highlighted: boolean; $useTheme: boolean}>`
    ${paragraphFontStyle}
    height: 40px;
    border-radius: 3px;
    border: 1px solid ${colors.darkGray};
    outline: 0;
    :disabled {
        color: ${colors.darkGray};
    }
    :not(:disabled) {
        color: ${colors.black};
    }
    box-sizing: border-box;
    transition: 0.2s border-color ease-in-out;
    padding: 0 16px;
    display: block;

    ${({hasIcon}) =>
        hasIcon &&
        css`
            padding-right: 40px;
        `}

    ${({error}) =>
        error &&
        css`
            border-color: ${colors.red};
        `}

    ${({highlighted, $useTheme}) =>
        highlighted &&
        css`
            border-color: ${({theme}) => ($useTheme ? theme.primary.color : colors.primary[100])};
        `}

    :not(:disabled):hover, :not(:disabled):focus {
        border-color: ${({theme, $useTheme}) => ($useTheme ? theme.primary.color : colors.primary[100])}
    }
`

const StyledInput = styled.input<{error: boolean; hasIcon: boolean; highlighted: boolean; $useTheme: boolean}>`
    ${inputStyle}
`

const StyledTextarea = styled.textarea<{error: boolean; hasIcon: boolean; highlighted: boolean; $useTheme: boolean}>`
    ${inputStyle}
    padding: 16px;
`

const StyledSelect = styled.select<{error: boolean; hasIcon: boolean; highlighted: boolean; $useTheme: boolean}>`
    ${inputStyle}
    background: white;
`

export const InputLabel = styled(Typography.Tiny).attrs({
    block: true,
})`
    position: relative;
    top: 8px;
    height: 0;
`

const IconContainer = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    width: 40px;
    ${(props: {transparent?: boolean}) =>
        props.transparent === false
            ? `
        background: white;
        top: 2px;
        bottom: 2px;
        right: 2px;
        width: 36px;
    `
            : ''}
`

const CloseIcon = styled(IconContainer)`
    cursor: pointer;
    pointer-events: visible;
`

const Input: React.FC<InputProps> = ({
    error,
    icon,
    clearButton,
    value,
    onChange,
    width,
    highlighted = false,
    containerStyle,
    inputRef,
    placeholder,
    ariaLabel,
    useTheme = false,
    textarea = false,
    isSelect = false,
    ...props
}) => {
    const {name} = useGroupContext()

    const onTextIcon =
        clearButton && value ? (
            <CloseIcon onClick={() => onChange?.('')}>
                <Icon icon={SvgClose} tint={colors.darkGray} />
            </CloseIcon>
        ) : isSelect ? (
            <IconContainer transparent={false}>
                <Icon size={24} icon={SvgCaret} />
            </IconContainer>
        ) : (
            <IconContainer>{icon}</IconContainer>
        )

    const inputProps = {
        ref: inputRef,
        value,
        onChange: (event: any) => onChange?.(event.target.value),
        ...props,
        name,
        id: name,
        error: !!error,
        hasIcon: !!icon,
        highlighted,
        placeholder,
        $useTheme: useTheme,
    }

    return (
        <Container width={width} style={containerStyle}>
            {textarea ? (
                <StyledTextarea {...inputProps} ref={undefined} aria-label={ariaLabel || placeholder} />
            ) : isSelect ? (
                <StyledSelect {...inputProps} ref={undefined} aria-label={ariaLabel || placeholder}>
                    {props.children}
                </StyledSelect>
            ) : (
                <StyledInput {...inputProps} aria-label={ariaLabel || placeholder} />
            )}
            {error && <InputLabel color="red">{error}</InputLabel>}
            {onTextIcon}
        </Container>
    )
}

export {Input}
