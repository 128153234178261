import React from 'react'
import {Switch, SwitchProps} from '../Switch'
import {useField, useFormikContext} from 'formik'
import {useGroupContext} from '../Group'

const FormikSwitch: React.FC<SwitchProps> = ({label, invertValue = false, onClick}) => {
    const {name} = useGroupContext()
    const [field] = useField<boolean>(name)
    const {setFieldValue} = useFormikContext<Record<string, boolean>>()

    const fieldValue = invertValue ? !field.value : field.value

    return (
        <Switch
            name={name}
            checked={fieldValue}
            onClick={onClick}
            onChange={() => setFieldValue(name, !field.value)}
            label={label}
        />
    )
}

export {FormikSwitch}
