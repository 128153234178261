import {motion} from 'framer-motion'
import {Button, Spacer, Typography} from 'nf-ui'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'
import React from 'react'
import styled from 'styled-components'

interface WizardFooterProps {
    backAction?: () => void
}

const FooterSpacer = styled.div`
    height: 104px;
    width: 100%;
`

const Container = styled(motion.div)`
    height: 72px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
`
const InnerContainer = styled.div<{back: boolean}>`
    width: 100%;
    padding: 0 32px;
    display: flex;
    margin: 0 auto;
    justify-content: ${(props) => (props.back ? 'space-between' : 'flex-end')};
    align-items: center;
`

const ChildrenContainer = styled.div`
    display: flex;
    align-items: center;
`

const variants = {
    open: {opacity: 1},
    closed: {opacity: 0},
}

const Footer: React.FC<WizardFooterProps> = ({children, backAction}) => {
    // Add "or" in between video elements
    const buttons = React.Children.map(children, (button, index) => {
        if (index !== 0) {
            return (
                <React.Fragment>
                    <Spacer width={24} />
                    <Typography.Paragraph bottomMargin={false}>or</Typography.Paragraph>
                    <Spacer width={24} />
                    {button}
                </React.Fragment>
            )
        } else {
            return button
        }
    })

    return (
        <React.Fragment>
            <FooterSpacer />
            <Container variants={variants}>
                <InnerContainer back={!!backAction}>
                    {backAction && (
                        <Button variant="tertiary" onClick={backAction}>
                            <Button.Icon icon={SvgArrowLeft} /> Back
                        </Button>
                    )}
                    <ChildrenContainer>{buttons}</ChildrenContainer>
                </InnerContainer>
            </Container>
        </React.Fragment>
    )
}

export {Footer}
