import {FieldArrayRenderProps} from 'formik'
import {motion} from 'framer-motion'
import {Collapsible, colors, Icon, Typography, useTheme} from 'nf-ui'
import SvgChevronDown from 'nf-ui/Icons/ChevronDown'
import React from 'react'
import styled from 'styled-components'
import {Field, FieldForm} from './FieldForm'

const CollapsibleContent = styled(motion.div)`
    border: 1px solid;
    border-radius: 3px;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 24px;
`

const Content = styled.div`
    padding: 0px 24px 24px 24px;
`

type CollapsibleFieldProps = {
    field: Partial<Field & {id: string}>
    index: number
    fieldsHelpers: FieldArrayRenderProps
}

export const CollapsibleField = ({field, index, fieldsHelpers}: CollapsibleFieldProps) => {
    const theme = useTheme()

    return (
        <Collapsible index={index}>
            <CollapsibleContent
                variants={{
                    collapsed: {borderColor: colors.darkGray},
                    expanded: {borderColor: theme.primary.color},
                }}
            >
                <Collapsible.Header>
                    <Header>
                        <Typography.Label>{field.name || 'Field Name'}</Typography.Label>
                        <motion.div
                            variants={{
                                collapsed: {rotate: 0},
                                expanded: {rotate: 180},
                            }}
                        >
                            <Icon icon={SvgChevronDown} />
                        </motion.div>
                    </Header>
                </Collapsible.Header>
                <Collapsible.Body>
                    <Content>
                        <FieldForm key={field.id} field={field} index={index} fieldsHelpers={fieldsHelpers} />
                    </Content>
                </Collapsible.Body>
            </CollapsibleContent>
        </Collapsible>
    )
}
