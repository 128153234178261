import {gql, useMutation} from '@apollo/client'
import React, {useCallback, useContext} from 'react'
import {useAlert} from 'react-alert'
import {useHistory} from 'react-router-dom'
import {DataSourceType} from '~/globalTypes'
import {useClearCloudfrontCookies} from '~/useSetCloudfrontCookies'
import {bugsnagClient} from '../bugSnag'
import {CurrentOrganisation} from './CurrentOrganisationContext'
import {Token} from './TokenContext'
import {UserSettings} from './UserSettingsContext'
import {SignOut, SignOutVariables} from './__types__/SignOut'

export const SIGN_OUT = gql`
    mutation SignOut($accessToken: String!) {
        signOut(accessToken: $accessToken)
    }
`

const SignoutURLContext = React.createContext<string | null>(null)

export const SignoutURLProvider: React.FC = ({children}) => {
    const {currentOrganisation} = CurrentOrganisation.useContainer()

    let url = null
    if (currentOrganisation?.primaryDataSourceType === DataSourceType.ADP) {
        const redirectURI = encodeURIComponent(`${window.location.protocol}//${window.location.host}`)
        url = `https://accounts.adp.com/auth/oauth/v2/logout?post_logout_redirect_uri=${redirectURI}`
    }

    return <SignoutURLContext.Provider value={url}>{children}</SignoutURLContext.Provider>
}

export function useWebSignout() {
    const {push} = useHistory()
    const {setToken} = Token.useContainer()
    const alert = useAlert()
    const {clearUserSettings} = UserSettings.useContainer()
    const clearCloudfrontCookies = useClearCloudfrontCookies()
    const redirectURL = useContext(SignoutURLContext)

    const webSignOut = useCallback(() => {
        try {
            window.analytics?.reset()
            if (bugsnagClient) bugsnagClient.setUser()
            setToken('')
            clearUserSettings()
            clearCloudfrontCookies()

            if (redirectURL) window.location.href = redirectURL
            else push('/')
        } catch (err) {
            alert.error('Failed to sign out')
            throw err
        }
    }, [setToken, alert, clearUserSettings, push, clearCloudfrontCookies, redirectURL])

    return {
        webSignOut,
    }
}

export function useSignout() {
    const alert = useAlert()
    const {token: accessToken} = Token.useContainer()
    const {webSignOut} = useWebSignout()

    const [apiSignOut] = useMutation<SignOut, SignOutVariables>(SIGN_OUT)

    const signOut = useCallback(async () => {
        try {
            await apiSignOut({
                variables: {
                    accessToken,
                },
            })
            webSignOut()
        } catch (error) {
            alert.error('Failed to sign out')
        }
    }, [accessToken, apiSignOut, webSignOut, alert])

    return {
        signOut,
    }
}
