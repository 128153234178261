import {useQuery} from '@apollo/client'
import {AnimatePresence, motion} from 'framer-motion'
import {Typography} from 'nf-ui'
import React, {useState} from 'react'
import {useRouteMatch} from 'react-router-dom'
import styled from 'styled-components'
import {PageLoading} from '~/components/PageLoading'
import {PhonePreviewHome} from '~/components/PhonePreview/PhonePreviewHome'
import {SidePanelLayout} from '~/components/SidePanelLayout'
import {useForceRender} from '~/components/useForceRender'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {HomeItemType} from '~/globalTypes'
import {getGraphQLErrorMessage} from '~/util'
import {EditChildCategory} from './EditChildCategory'
import {EditHomeItem} from './EditHomeItem'
import {HomeItemList} from './HomeItemList'
import {MergeCategories} from './MergeCategories'
import {DESIGN_HOME} from './queries'
import {DesignHomeData, DesignHomeDataVariables} from './__types__/DesignHomeData'

const fadeProps = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0},
}

const FullHeightMotion = styled(motion.div)`
    height: 100%;
`

export const DesignHome: React.FC = () => {
    const organisationIdStr = useOrganisationIdStr()
    const forceRender = useForceRender()
    const {data, error, loading} = useQuery<DesignHomeData, DesignHomeDataVariables>(DESIGN_HOME, {
        variables: {organisationIdStr},
    })
    const routeMatch = useRouteMatch<{idStr?: string}>('*/edit/:idStr')
    const mergeMatch = useRouteMatch<{idStr?: string}>('*/merge/:idStr')
    const [hoveringId, setHoveringId] = useState<string | undefined>()
    const [expanded, setExpanded] = useState<string[]>([])

    const editId = routeMatch?.params?.idStr
    const mergeId = mergeMatch?.params?.idStr

    if (error) return <Typography.Heading>Error: {getGraphQLErrorMessage(error)}</Typography.Heading>
    if (loading || !data) return <PageLoading />

    const logo = data.organisation.logo || null

    const homeItems = data.organisation.homeItems

    const allChildCategories = homeItems
        .filter((item) => item.type === HomeItemType.CATEGORY)
        .flatMap((item) => item.childCategories || [])

    const editChildCategory = allChildCategories.find((child) => child.idStr === editId)
    const editHomeItem = homeItems.find((item) => item.idStr === editId)
    const mergeParentCategory = homeItems.find((homeItem) => homeItem.idStr === mergeId)

    return (
        <SidePanelLayout
            leftContent={
                <PhonePreviewHome
                    accentColor={data?.organisation.accentColor}
                    logo={logo?.file.url}
                    homeItems={data.organisation.homeItems}
                    hoveringId={hoveringId}
                />
            }
        >
            <AnimatePresence initial={false} exitBeforeEnter>
                {mergeParentCategory ? (
                    <FullHeightMotion key="mergeCategories" {...fadeProps}>
                        <MergeCategories categories={mergeParentCategory.childCategories || []} />
                    </FullHeightMotion>
                ) : editHomeItem ? (
                    <FullHeightMotion key="editHomeItem" {...fadeProps}>
                        <EditHomeItem homeItem={editHomeItem} />
                    </FullHeightMotion>
                ) : editChildCategory ? (
                    <FullHeightMotion key="editChildCategory" {...fadeProps}>
                        <EditChildCategory childCategory={editChildCategory}></EditChildCategory>
                    </FullHeightMotion>
                ) : (
                    <motion.div key="fieldList" style={{height: '100%'}} {...fadeProps}>
                        <HomeItemList
                            homeItems={data.organisation.homeItems}
                            setHoveringId={setHoveringId}
                            forceParentRender={forceRender}
                            expanded={expanded}
                            setExpanded={setExpanded}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </SidePanelLayout>
    )
}
