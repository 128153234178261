import React, {FC, useState} from 'react'
import {useGroupContext} from '../Group'
import {useField, useFormikContext} from 'formik'
import {Dropdown, useDropdownContextOrFail} from '../../Dropdown'
import {List} from '../../List'
import styled from 'styled-components'
import {Typography} from '../../Typography'
import SvgCheck from '../../Icons/Check'
import {colors} from '../../theme'
import {Icon} from '../../Icon'
import {Input} from '../Input'
import {Spacer} from 'nf-ui/Spacer'
import {Button} from 'nf-ui/Button/Button'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const AddNewContainer = styled.div`
    position: fixed;
    bottom: 0;
    background: white;
    width: 100%;
`

const AddNewButton = styled.div`
    padding: 8px 16px;
    display: flex;
    justify-content: flex-end;
`

const CreatePanelContainer = styled.div`
    width: 304px;
`

const CreateInputWrapper = styled.div`
    padding: 0 16px;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 16px;
`

const HeadingWrapper = styled.div`
    width: auto;
    min-height: 24px;
    padding: 16px;
`

type Option = {
    label: string
    value: string
}

type CreateOrSelectProps = {
    options?: Option[] | null
    disabled?: boolean
    label?: string
}

const CreatePanel = ({
    setSelecting,
    search,
    setSearch,
    options,
    label,
}: {
    setSelecting: (state: boolean) => void
    search: string
    setSearch: (value: string) => void
    options?: Option[] | null
    label?: string
}) => {
    const {setOpen} = useDropdownContextOrFail()
    const {name} = useGroupContext()
    const {setFieldValue} = useFormikContext<Record<string, string[]>>()

    const handleSave = () => {
        const existingOption = options?.find((option) => option.label.toLowerCase() === search.toLowerCase())

        if (existingOption) {
            setFieldValue(name, existingOption.value)
        } else {
            setFieldValue(name, search)
        }

        setOpen(false)
    }

    return (
        <CreatePanelContainer>
            <Header>
                <Button style={{padding: 0}} variant="tertiary" onClick={() => setSelecting(true)}>
                    <Button.Icon icon={SvgArrowLeft} />
                </Button>
                <Spacer width={16} />
                <Typography.Label>Add new {label}</Typography.Label>
            </Header>
            <Spacer height={4} />
            <CreateInputWrapper>
                <Input value={search} onChange={setSearch} clearButton />
            </CreateInputWrapper>
            <Spacer height={16} />
            <AddNewButton>
                <Button style={{padding: 0}} variant="tertiary" onClick={handleSave} disabled={!search}>
                    Save
                </Button>
            </AddNewButton>
        </CreatePanelContainer>
    )
}

const SelectPanel = ({
    options,
    setSelecting,
    search,
    setSearch,
    label,
}: {
    options?: Option[] | null
    setSelecting: (state: boolean) => void
    search: string
    setSearch: (value: string) => void
    label?: string
}) => {
    const {setOpen} = useDropdownContextOrFail()
    const {name} = useGroupContext()
    const [field] = useField<string>(name)
    const {setFieldValue} = useFormikContext<Record<string, string[]>>()

    const isSelected = (option: Option) => {
        return field.value === option.value
    }

    const toggleOption = (option: Option) => {
        if (isSelected(option)) {
            setFieldValue(name, '')
        } else {
            setFieldValue(name, option.value)
        }

        setOpen(false)
    }

    const filteredOptions = search
        ? options?.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()))
        : options

    return (
        <>
            {filteredOptions && (
                <>
                    <HeadingWrapper>
                        <Typography.Label>Select {label}</Typography.Label>
                        <Spacer height={16} />
                        <Input onChange={setSearch} value={search} clearButton />
                    </HeadingWrapper>
                    <List
                        onClick={toggleOption}
                        rows={filteredOptions}
                        renderRow={(option) => {
                            return (
                                <RowContainer>
                                    <Typography.Paragraph bottomMargin={false}>{option.label}</Typography.Paragraph>
                                    {isSelected(option) && <Icon icon={SvgCheck} tint={colors.green[100]} />}
                                </RowContainer>
                            )
                        }}
                        width={304}
                    />
                </>
            )}
            <Spacer height={56} />
            <AddNewContainer>
                <AddNewButton>
                    <Button style={{padding: 0}} variant="tertiary" onClick={() => setSelecting(false)}>
                        Add new&nbsp;{label}
                    </Button>
                </AddNewButton>
            </AddNewContainer>
        </>
    )
}

export const FormikCreateOrSelect: FC<CreateOrSelectProps> = ({options, disabled, label}) => {
    const {name} = useGroupContext()
    const [field, {error}] = useField<string>(name)
    const [search, setSearch] = useState<string>('')
    const [selecting, setSelecting] = useState<boolean>(true)

    const resetPanel = () => {
        setSelecting(true)
        setSearch('')
    }

    const inputLabel = label?.toLowerCase() || ''
    const displayValue = options?.find((option) => option.value === field.value)?.label || field.value

    return (
        <Dropdown buttonTarget closeOnClick={false} onClose={resetPanel}>
            <Dropdown.Button error={error} disabled={disabled}>
                {displayValue}
            </Dropdown.Button>
            <Dropdown.Menu>
                {selecting ? (
                    <SelectPanel
                        options={options}
                        setSelecting={setSelecting}
                        search={search}
                        setSearch={setSearch}
                        label={inputLabel}
                    />
                ) : (
                    <CreatePanel
                        setSelecting={setSelecting}
                        search={search}
                        setSearch={setSearch}
                        options={options}
                        label={inputLabel}
                    />
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}
