import * as React from 'react'
const SvgOpenLink = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgOpenLink(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M1.438 1.123h4.025l.082.01c.221.049.405.28.405.524a.555.555 0 01-.405.524l-.082.01-3.538-.05-.05 11.668 12.113.05v-3.252l.008-.089c.046-.241.26-.44.479-.44.287 0 .523.193.568.447l.007.086v3.737l-.007.082a.554.554 0 01-.475.44l-.093.007H1.432l-.077-.01a.553.553 0 01-.397-.433l-.008-.09V1.651l.009-.089c.04-.21.21-.39.397-.431l.081-.01zm13.041 0l.082.003c.104.008.18.034.225.075l.083.096.062.06a.46.46 0 01.111.223l.008.084-.088 5.323-.006.098c-.036.267-.218.432-.48.43-.263-.002-.444-.173-.481-.437l-.007-.103.085-4.112-5.352 5.508-.064.058c-.194.147-.463.118-.702-.081-.145-.166-.156-.454-.032-.662l.061-.084 5.368-5.401-4.047.037-.077-.008a.544.544 0 01-.403-.519c0-.247.189-.48.407-.53l.083-.01 5.164-.048z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgOpenLink
