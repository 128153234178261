import React from 'react'
import {Typography} from './Typography'
import {Spacer} from './Spacer'
import styled from 'styled-components'

type HeaderLayoutProps = {
    heading: string | React.ReactElement
    subheading?: string
    rightContent?: React.ReactElement | null
    className?: string
    skipPadding?: boolean
}

const Container = styled.div`
    padding: ${({skipPadding}: {skipPadding?: boolean}) => (skipPadding ? '0px' : '90px 32px 40px 40px')};
`

const SubheadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const MarginTop16 = styled.div`
    margin-top: 16px;
`

const RightContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    top: -12px;
    padding-left: 24px;
    flex-shrink: 0;

    > * {
        flex-shrink: 0;
    }
`

const Heading = ({heading}: {heading: string | React.ReactElement}) => {
    if (typeof heading === 'string') return <Typography.Heading>{heading}</Typography.Heading>
    return <>{heading}</>
}

const HeaderLayout: React.FC<HeaderLayoutProps> = ({
    heading,
    subheading,
    rightContent,
    children,
    className,
    skipPadding,
}) => {
    return (
        <Container className={className} data-testid="HeaderLayout" skipPadding={skipPadding}>
            {rightContent && (
                <SubheadingContainer>
                    <div>
                        <Heading heading={heading}></Heading>
                        <MarginTop16>
                            <Typography.Subheading>{subheading}</Typography.Subheading>
                        </MarginTop16>
                    </div>
                    <RightContentContainer>{rightContent}</RightContentContainer>
                </SubheadingContainer>
            )}
            {!rightContent && (
                <>
                    <Heading heading={heading}></Heading>
                    <MarginTop16>
                        <Typography.Subheading>{subheading}</Typography.Subheading>
                    </MarginTop16>
                </>
            )}
            <Spacer height={16} />
            {children}
        </Container>
    )
}

export {HeaderLayout}
