import * as React from 'react'
const SvgMakeAdmin = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgMakeAdmin(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M13 0H3C1.887 0 .89.935.806 2.065L.8 2.22v9.04c0 .754.44 1.492 1.092 1.904l.143.083 5 2.52a2.108 2.108 0 001.76.077l.17-.077 5.006-2.523a2.134 2.134 0 001.223-1.812l.006-.171V2.22c0-1.18-.89-2.134-2.041-2.215L13 0zm0 1.08c.61 0 1.04.43 1.094 1.013l.006.127v9.04c0 .35-.177.695-.456.935l-.109.085-4.983 2.51c-.252.149-.585.168-.937.062l-.152-.053-4.891-2.516a1.157 1.157 0 01-.664-.893l-.008-.13V2.22c0-.573.452-1.07.985-1.133L3 1.08h10zm-2.57 4.069a.568.568 0 00-.718.038l-.07.075-2.04 2.797-.833-1.13a.556.556 0 00-.761-.112.6.6 0 00-.124.701l.053.08 1.278 1.654c.165.17.29.23.478.227a.487.487 0 00.324-.163l.042-.061 2.476-3.321c.16-.3.12-.632-.104-.785z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgMakeAdmin
