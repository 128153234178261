import {RefObject, useCallback, useMemo} from 'react'
import {VariableSizeGrid, VariableSizeGridProps} from 'react-window'
import {Data, TableProps} from '../Table'
import {getColumnWidths} from './getColumnWidths'
import {Style, useAnimateGrid} from './useAnimateGrid'
import {CellHoverState} from './useCellHover'

export type Column = {
    column: string
    size: number
    offset: number
    disabled: boolean
    style: Style
    firstVisible: boolean
    lastVisible: boolean
    index: number
}

export const useColumns = ({
    data,
    hiddenColumns,
    disabledColumns,
    gridRef,
}: {
    data: Data
    hiddenColumns: string[]
    disabledColumns: string[]
    gridRef: RefObject<VariableSizeGrid>
}) => {
    // IMPROVEMENT: To improve performance a bit here, we can do a smart diff,
    // meaning, we can diff data when its reference changes, and only re-measure the
    // cell values that have changed.
    const columnWidths = useMemo(() => getColumnWidths(data), [data])

    const firstRow = data[0]
    const columnNames = useMemo(() => Object.keys(firstRow), [firstRow])

    const {collapsed, styles} = useAnimateGrid({
        items: columnNames,
        hiddenItems: hiddenColumns,
        gridRef,
    })

    const columnWidth = useCallback<VariableSizeGridProps['columnWidth']>(
        (index) => {
            return collapsed.current[columnNames[index]] ? 0 : columnWidths[index]
        },
        [collapsed, columnWidths, columnNames],
    )

    const visibleColumns = columnNames.filter((column) => !hiddenColumns.includes(column))
    const lastVisibleColumn = visibleColumns[visibleColumns.length - 1]
    const firstVisibleColumn = visibleColumns[0]

    const columns = useMemo<Column[]>(() => {
        let offset = 0
        return columnNames.map((column, index) => {
            offset += index === 0 ? 0 : styles[columnNames[index - 1]].collapsed ? 0 : columnWidths[index - 1]

            return {
                column,
                size: columnWidths[index],
                offset,
                disabled: disabledColumns.includes(column),
                style: styles[column],
                firstVisible: firstVisibleColumn === column,
                lastVisible: lastVisibleColumn === column,
                index,
            }
        })
    }, [columnWidths, columnNames, disabledColumns, styles, lastVisibleColumn, firstVisibleColumn])

    return {columns, columnWidth}
}

export const getTooltipTitle = (columnTooltip: TableProps['columnTooltip'], activeCell: CellHoverState['cell']) => {
    if (typeof columnTooltip === 'string') {
        return columnTooltip
    } else if (typeof columnTooltip === 'function' && activeCell) {
        return columnTooltip({column: activeCell.column})
    }
    return null
}
