import {AnimatePresence} from 'framer-motion'
import {castArray, isReactElement} from 'nf-ui'
import React, {PropsWithChildren, useCallback, useContext} from 'react'

type ModalNavigatorContext = {
    onClose: () => void
}

const ModalNavigatorContext = React.createContext<null | ModalNavigatorContext>(null)

export const useModalNavigator = () => {
    const context = useContext(ModalNavigatorContext)

    if (!context) {
        throw new Error('A ModalLayout must be contained within a ModalNavigator component.')
    }

    return context
}

type ModalNavigatorProps<Modal> = PropsWithChildren<{
    modal: Modal | null | undefined
    onClose?: () => void
    setModal: (modal: Modal | null) => void
}>

export function ModalNavigator<Modal extends string>({children, modal, setModal, onClose}: ModalNavigatorProps<Modal>) {
    // Not using React.Children.toArray here because it alters child keys
    const visibleChild = castArray(children).find((child) => isReactElement(child) && child.key === modal)

    const onCloseFn = useCallback(() => {
        setModal(null)
        onClose?.()
    }, [onClose, setModal])

    return (
        <ModalNavigatorContext.Provider value={{onClose: onCloseFn}}>
            <AnimatePresence>{visibleChild}</AnimatePresence>
        </ModalNavigatorContext.Provider>
    )
}
