import {Button, colors, Icon, IconType, List, Panel, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import {Switch} from 'nf-ui/Form/Switch'
import SvgCaret from 'nf-ui/Icons/Caret'
import SvgClose from 'nf-ui/Icons/Close'
import SvgMakeAdmin from 'nf-ui/Icons/MakeAdmin'
import SvgRemoveAdmin from 'nf-ui/Icons/RemoveAdmin'
import SvgDelete from 'nf-ui/Icons/Delete'
import pluralize from 'pluralize'
import React, {FC, useRef, useState} from 'react'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {PanelLayout} from '~/components/PanelLayout'
import {useSetSelectedItems} from '~/components/SelectionList/SelectionState'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {PANEL_WIDTH} from '~/constants'
import {UserRole} from '~/globalTypes'
import {UsersPageData_organisation_users} from '../__types__/UsersPageData'
import {useRevokeMutations} from './useRevokeMutation'
import {useRoleMutations} from './useRoleMutation'
import {useRemoveOrganisationUsersMutation} from './useRemoveOrganisationUsersMutation'

const Container = styled.div`
    height: 72px;
    background: ${colors.primary[100]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
`

const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 16px;
`

const RightContainer = styled.div`
    display: flex;
    align-items: center;
    padding-right: 32px;
`

type ActionType = {
    onClick: () => void
    label: string
    analyticsEvent: string
    icon?: IconType
}

type MultiselectBarType = {
    selectedUsers: UsersPageData_organisation_users[]
    onClose: () => void
}

export const MultiselectBar: FC<MultiselectBarType> = ({selectedUsers, onClose}) => {
    const {setSelectedIdStrs} = useSetSelectedItems()
    const organisationIdStr = useOrganisationIdStr()
    const {addAdmin, removeAdmin, makeUser, makeGuest} = useRoleMutations()
    const {revokeUsers} = useRevokeMutations()
    const {removeOrganisationUsers} = useRemoveOrganisationUsersMutation()
    const [, setModal] = useQueryParam<'invite-users' | null>('modal')

    const actionsRef = useRef<HTMLDivElement>(null)
    const [actionsPanelOpen, setActionsPanelOpen] = useState(false)

    const userIdStrs = selectedUsers.map((u) => u.idStr)

    const unrevoke = selectedUsers.some((user) => user.isRevoked)
    const revoking = !unrevoke

    const allActions: ActionType[] = [
        {
            onClick: () => {
                onClose()
                revokeUsers({organisationIdStr, userIdStrs, unrevoke})
            },
            analyticsEvent: 'multiselect_revoke',
            label: 'Directory access',
        },
    ]
    const nonAdmins = selectedUsers
        .filter((user) => ![UserRole.AdminGuest, UserRole.AdminUser].includes(user.role))
        .map((u) => u.idStr)
    if (nonAdmins.length)
        allActions.push({
            onClick: () => {
                onClose()
                addAdmin({organisationIdStr, userIdStrs: nonAdmins})
            },
            analyticsEvent: 'multiselect_add_admin_status',
            label: 'Make Admin',
            icon: SvgMakeAdmin,
        })

    const admins = selectedUsers
        .filter((user) => [UserRole.AdminGuest, UserRole.AdminUser].includes(user.role))
        .map((u) => u.idStr)
    if (admins.length)
        allActions.push({
            onClick: () => {
                onClose()
                removeAdmin({organisationIdStr, userIdStrs: admins})
            },
            analyticsEvent: 'multiselect_remove_admin_status',
            label: 'Remove admin status',
            icon: SvgRemoveAdmin,
        })

    const guestsWithProfiles = selectedUsers
        .filter((user) => !!user.profiles.length && [UserRole.AdminGuest, UserRole.Guest].includes(user.role))
        .map((u) => u.idStr)
    if (guestsWithProfiles.length)
        allActions.push({
            onClick: () => {
                onClose()
                makeUser({organisationIdStr, userIdStrs: guestsWithProfiles})
            },
            analyticsEvent: 'multiselect_make_user',
            label: 'Make User',
            icon: SvgMakeAdmin,
        })

    const users = selectedUsers
        .filter((user) => [UserRole.AdminUser, UserRole.User].includes(user.role))
        .map((u) => u.idStr)
    if (users.length)
        allActions.push({
            onClick: () => {
                onClose()
                makeGuest({organisationIdStr, userIdStrs: users})
            },
            analyticsEvent: 'multiselect_make_guest',
            label: 'Make Guest',
            icon: SvgMakeAdmin,
        })

    allActions.push({
        onClick: () => {
            onClose()
            removeOrganisationUsers({organisationIdStr, userIdStrs})
        },
        analyticsEvent: 'multiselect_remove_organisation_user',
        label: 'Remove from organisation',
        icon: SvgDelete,
    })

    const unrevokeAction = {
        onClick: () => {
            onClose()
            revokeUsers({organisationIdStr, userIdStrs, unrevoke})
        },
        analyticsEvent: 'multiselect_unrevoke',
        label: 'Directory access',
    }

    const actions: ActionType[] = revoking ? allActions : [unrevokeAction]

    return (
        <Container>
            <LeftContainer>
                <Button onClick={() => onClose()} variant="primary">
                    Cancel
                    <Button.Icon icon={SvgClose} />
                </Button>
            </LeftContainer>
            <RightContainer>
                <Typography.Label color="white">
                    {selectedUsers.length} {pluralize('User', selectedUsers.length)} selected
                </Typography.Label>
                <Spacer width={16} />
                <div ref={actionsRef}>
                    <Button
                        onClick={() => setActionsPanelOpen(true)}
                        variant="primary"
                        dimmed={true}
                        transparent={false}
                        loading={false}
                    >
                        Actions
                        <Button.Icon icon={SvgCaret} />
                    </Button>
                </div>
                {revoking && (
                    <>
                        <Spacer width={16} />
                        <Button
                            onClick={() => {
                                setSelectedIdStrs(userIdStrs)
                                setModal('invite-users')
                                onClose()
                            }}
                            variant="tertiary"
                            transparent={false}
                            loading={false}
                            onClickAnalyticsEvent="multiselect_invite_users"
                        >
                            Invite users
                        </Button>
                    </>
                )}
            </RightContainer>
            <Panel
                open={actionsPanelOpen}
                onClose={() => setActionsPanelOpen(false)}
                targetRef={actionsRef}
                placement="bottom-end"
                boundariesElement="scrollParent"
            >
                <ActionsPanel onClose={() => setActionsPanelOpen(false)} actions={actions} revoking={revoking} />
            </Panel>
        </Container>
    )
}

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ActionsPanel: React.FC<{onClose: () => void; actions: ActionType[]; revoking: boolean}> = ({
    onClose,
    actions,
    revoking,
}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    return (
        <PanelLayout width={PANEL_WIDTH}>
            <Typography.Label>What would you like to do?</Typography.Label>
            <List
                width={PANEL_WIDTH}
                rows={actions}
                renderRow={(action) =>
                    action.label === 'Directory access' ? (
                        <ActionRow>
                            <div>{action.label}</div>
                            <Switch
                                name="Directory access"
                                checked={revoking}
                                onClick={() => {
                                    action.onClick()
                                    onClose()
                                    trackAnalyticsEvent(action.analyticsEvent)
                                }}
                            />
                        </ActionRow>
                    ) : (
                        <ActionRow
                            onClick={() => {
                                action.onClick()
                                onClose()
                                trackAnalyticsEvent(action.analyticsEvent)
                            }}
                        >
                            <div>{action.label}</div>
                            {action.icon && <Icon size={16} icon={action.icon} />}
                        </ActionRow>
                    )
                }
            ></List>
        </PanelLayout>
    )
}
