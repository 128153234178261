import {gql, useMutation} from '@apollo/client'
import {Button, Form, Lightbox, Spacer, Typography} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import React, {useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {CURRENT_ORGANISATION_DATA} from '~/components/CurrentOrganisationContext'
import {SCENARIOS_PAGE_DATA} from '../Scenarios'
import {CreateScenario, CreateScenarioVariables} from './__types__/CreateScenario'

const CREATE_SCENARIO = gql`
    mutation CreateScenario($organisationIdStr: String!, $name: String!) {
        createScenario(organisationIdStr: $organisationIdStr, name: $name)
    }
`

const LightboxHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const CreateScenarioLightbox: React.FC<{
    open: boolean
    onClose: () => void
    organisationIdStr: string
}> = ({open, onClose, organisationIdStr}) => {
    const [name, setName] = useState<string>('')
    const alert = useAlert()

    const [createScenario, {loading}] = useMutation<CreateScenario, CreateScenarioVariables>(CREATE_SCENARIO, {
        onCompleted: () => {
            alert.success('New scenario successfully created')
            onClose()
            setName('')
        },
        refetchQueries: [
            {query: SCENARIOS_PAGE_DATA, variables: {organisationIdStr}},
            {query: CURRENT_ORGANISATION_DATA},
        ],
        awaitRefetchQueries: true,
    })

    return (
        <Lightbox open={open} onClose={onClose} width={549}>
            <LightboxHeader>
                <Typography.Heading>Create new scenario</Typography.Heading>
                <Button variant="tertiary" onClick={onClose}>
                    Close <Button.Icon icon={SvgClose} />
                </Button>
            </LightboxHeader>
            <Spacer height={24} />
            <Typography.Paragraph>
                Scenarios allow you to make a copy of your org chart and create hypothetical team changes. These changes
                do not reflect in your live org chart and can only be accessed by admins.
            </Typography.Paragraph>
            <Form.Group name="scenarioName">
                <Form.Label>Scenario name</Form.Label>
                <Form.Input width={300} value={name} onChange={setName} />
            </Form.Group>
            <Spacer height={32} />
            <Button
                disabled={!name}
                loading={loading}
                onClick={() => {
                    if (!name) return
                    createScenario({variables: {organisationIdStr, name}})
                }}
            >
                Create
            </Button>
        </Lightbox>
    )
}
