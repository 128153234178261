import {gql} from '@apollo/client'

export const HomeItemFragment = gql`
    fragment HomeItemData on OrganisationObject {
        idStr
        homeItems(visibility: BOTH) {
            idStr
            label
            visible
            priority
            type
            displayMode
            sourceIdStr
            childCategories {
                idStr
                name
                label
                priority
                combinedCategories
            }
        }
    }
`
