import {gql, useMutation} from '@apollo/client'
import {DocumentNode} from 'graphql'
import {useAlert} from 'react-alert'
import {CURRENT_ORGANISATION_DATA} from '~/components/CurrentOrganisationContext'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {DataSourceType} from '~/globalTypes'
import {ColumnMap} from '~/objectTypes'
import {getGraphQLErrorMessage} from '~/util'
import {AddDataSource} from '../AddDataSourceModal'
import {isEditDataSource} from '../ConfigureDataSourceModal'
import {DATA_SOURCES} from '../Integrations'
import {useConfigureDataSource} from '../useConfigureDataSource'
import {AddAdditionalDataSource, AddAdditionalDataSourceVariables} from './__types__/AddAdditionalDataSource'
import {
    ReconfigureAdditionalDataSource,
    ReconfigureAdditionalDataSourceVariables,
} from './__types__/ReconfigureAdditionalDataSource'

export const ADD_DATASOURCE = gql`
    mutation AddAdditionalDataSource(
        $organisationIdStr: String!
        $type: DataSourceType!
        $columnMap: [ColumnMap!]!
        $googleSheetsData: GoogleSheetsData
        $directUploadFile: Upload
    ) {
        addAdditionalDataSource(
            organisationIdStr: $organisationIdStr
            type: $type
            columnMap: $columnMap
            googleSheetsData: $googleSheetsData
            directUploadFile: $directUploadFile
        ) {
            idStr
            name
        }
    }
`

export const RECONFIGURE_DATASOURCE = gql`
    mutation ReconfigureAdditionalDataSource(
        $organisationIdStr: String!
        $dataSourceIdStr: String!
        $columnMap: [ColumnMap!]!
    ) {
        reconfigureAdditionalDataSource(
            organisationIdStr: $organisationIdStr
            columnMap: $columnMap
            dataSourceIdStr: $dataSourceIdStr
        ) {
            idStr
            name
        }
    }
`

export const useAdditionalDataSourceMutation = <Mutation, Variables>(
    mutation: DocumentNode,
    organisationIdStr: string,
) => {
    return useMutation<Mutation, Variables>(mutation, {
        refetchQueries: [{query: DATA_SOURCES, variables: {organisationIdStr}}, {query: CURRENT_ORGANISATION_DATA}],
        awaitRefetchQueries: true,
    })
}

const getAddDataSourceData = (dataSourceData: AddDataSource) => {
    if (dataSourceData.type === DataSourceType.GOOGLE_SHEETS) {
        return {
            googleSheetsData: {
                refreshToken: dataSourceData.refreshToken,
                sheetId: dataSourceData.sheetId,
            },
        }
    } else if (dataSourceData.type === DataSourceType.DIRECT_UPLOAD) {
        return {directUploadFile: dataSourceData.file}
    }

    throw new Error('Invalid data source type supplied')
}

const generateColumnMap = (mappedColumns: Record<string, string>) => {
    const columnMap: ColumnMap[] = []

    for (const idStr in mappedColumns) {
        columnMap.push({fieldOrCatIdStr: idStr, column: mappedColumns[idStr]})
    }

    return columnMap
}

export const useAdditionalDataSourceMutations = () => {
    const {state, organisationIdStr} = useConfigureDataSource()
    const {onClose} = useModalNavigator()
    const alert = useAlert()

    const [addDataSource, {loading: adding}] = useAdditionalDataSourceMutation<
        AddAdditionalDataSource,
        AddAdditionalDataSourceVariables
    >(ADD_DATASOURCE, organisationIdStr)

    const [reconfigureDataSource, {loading: reconfiguring}] = useAdditionalDataSourceMutation<
        ReconfigureAdditionalDataSource,
        ReconfigureAdditionalDataSourceVariables
    >(RECONFIGURE_DATASOURCE, organisationIdStr)

    const {dataSourceData} = state

    const columnMap = generateColumnMap(state.mappedColumns)

    const addDataSourceMutation = async () => {
        if (isEditDataSource(dataSourceData)) return

        const data = getAddDataSourceData(dataSourceData)

        await addDataSource({
            variables: {
                organisationIdStr,
                type: dataSourceData.type,
                columnMap,
                ...data,
            },
        })
    }

    const editDataSourceMutation = async () => {
        if (!isEditDataSource(dataSourceData)) return

        await reconfigureDataSource({
            variables: {
                organisationIdStr,
                columnMap,
                dataSourceIdStr: dataSourceData.dataSourceIdStr,
            },
        })
    }

    const onSubmit = async () => {
        try {
            const mutation = isEditDataSource(dataSourceData) ? editDataSourceMutation : addDataSourceMutation
            await mutation()
        } catch (error) {
            onClose()
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    return {loading: reconfiguring || adding, onSubmit}
}
