import {gql, MutationFunctionOptions, MutationUpdaterFn, useMutation} from '@apollo/client'
import {cloneDeep} from '@apollo/client/utilities'
import {from} from 'fromfrom'
import {ExecutionResult} from 'graphql'
import produce from 'immer'
import {useAlert} from 'react-alert'
import {PAGE_DATA} from '~/pages/Adminland/Photos'
import {PhotosData, PhotosDataVariables, PhotosData_organisation_photos} from '~/pages/Adminland/__types__/PhotosData'
import {CurrentOrganisation} from './CurrentOrganisationContext'
import {useOrganisationIdStr} from './useOrganisationIdStr'
import {useQueue} from './useQueue'
import {ApprovePhotos, ApprovePhotosVariables} from './__types__/ApprovePhotos'
import {DeletePhoto, DeletePhotoVariables} from './__types__/DeletePhoto'
import {DeletePhotos, DeletePhotosVariables} from './__types__/DeletePhotos'
import {LinkPhoto, LinkPhotoVariables} from './__types__/LinkPhoto'
import {CropAndRotate, CropAndRotateVariables} from './__types__/CropAndRotate'
import {SendPhotoCollectionEmails, SendPhotoCollectionEmailsVariables} from './__types__/SendPhotoCollectionEmails'
import {UnlinkPhoto, UnlinkPhotoVariables} from './__types__/UnlinkPhoto'
import {UnlinkPhotos, UnlinkPhotosVariables} from './__types__/UnlinkPhotos'
import {UploadPhoto, UploadPhotoVariables} from './__types__/UploadPhoto'

function searchProfiles<ProfileType extends {fullName: string}>(profiles: ProfileType[], searchTerm: string) {
    return from(profiles)
        .filter((profile) => profile.fullName.toLowerCase().includes(searchTerm.toLocaleLowerCase()))
        .sortBy((profile) => profile.fullName.toLowerCase())
        .toArray()
}

function searchPhotos<
    PhotoType extends {
        filename: string
        profile?: {fullName: string} | null
        pendingProfile?: {fullName: string} | null
    }
>(photos: PhotoType[], searchTerm: string) {
    return photos.filter((photo) => {
        if (photo.profile) return photo.profile.fullName.toLowerCase().includes(searchTerm.toLowerCase())
        if (photo.pendingProfile) return photo.pendingProfile.fullName.toLowerCase().includes(searchTerm.toLowerCase())

        return photo.filename.toLowerCase().includes(searchTerm.toLowerCase())
    })
}

function sortProfiles<ProfileType extends {fullName: string}>(profiles: ProfileType[]) {
    return from(profiles)
        .sortBy((profile) => profile.fullName.toLowerCase())
        .toArray()
}

function sortPhotos<PhotoType extends {filename: string; profile?: null | {fullName: string}}>(photos: PhotoType[]) {
    return (
        from(photos)
            // Unmatched first
            .sortBy((photo) => (photo.profile ? 1 : 0))
            // Sort by profile name if matched, or photo name
            .sortBy((photo) => {
                if (photo.profile) return photo.profile.fullName.toLowerCase()
                return photo.filename.toLowerCase()
            })
            .toArray()
    )
}

export const LINK_PHOTO = gql`
    mutation LinkPhoto($photoIdStr: String!, $profileIdStr: String!) {
        linkPhotoToProfile(photoIdStr: $photoIdStr, profileIdStr: $profileIdStr) {
            photos {
                idStr
                profile {
                    idStr
                }
            }
            profiles {
                idStr
                photos {
                    idStr
                }
            }
        }
    }
`

const useLinkPhoto = () => useMutation<LinkPhoto, LinkPhotoVariables>(LINK_PHOTO)

export type DeletePhotoMutationUpdater = MutationUpdaterFn<DeletePhoto>
const DELETE_PHOTO = gql`
    mutation DeletePhoto($photoIdStr: String!) {
        deletePhoto(photoIdStr: $photoIdStr) {
            photo {
                idStr
            }
        }
    }
`

const useDeletePhoto = ({photoIdStr, update}: {photoIdStr: string; update: MutationUpdaterFn<DeletePhoto>}) => {
    return useMutation<DeletePhoto, DeletePhotoVariables>(DELETE_PHOTO, {
        variables: {
            photoIdStr,
        },
        update,
    })
}

const CROP_AND_ROTATE = gql`
    mutation CropAndRotate($photoIdStr: String!, $cropAndRotate: CropAndRotateInput!) {
        cropAndRotate(photoIdStr: $photoIdStr, cropAndRotate: $cropAndRotate) {
            idStr
            originalUrl
            thumbUrl
            profileUrl
            priority
        }
    }
`

const useCropAndRotate = () => {
    return useMutation<CropAndRotate, CropAndRotateVariables>(CROP_AND_ROTATE)
}

export type DeletePhotosMutationUpdater = MutationUpdaterFn<DeletePhotos>
const DELETE_PHOTOS = gql`
    mutation DeletePhotos($photoIdStrs: [String!]!) {
        deletePhotos(photoIdStrs: $photoIdStrs) {
            photos {
                idStr
            }
        }
    }
`
interface UseDeletePhotosProps {
    photoIdStrs: string[]
    update: DeletePhotosMutationUpdater | undefined
}
const useDeletePhotos = ({photoIdStrs, update}: UseDeletePhotosProps) => {
    return useMutation<DeletePhotos, DeletePhotosVariables>(DELETE_PHOTOS, {
        variables: {
            photoIdStrs,
        },
        update,
    })
}

const UNLINK_PHOTO = gql`
    mutation UnlinkPhoto($photoIdStr: String!) {
        unlinkPhoto(photoIdStr: $photoIdStr) {
            photo {
                idStr
                profile {
                    idStr
                }
            }
            profile {
                idStr
                photos {
                    idStr
                }
            }
        }
    }
`

const useUnlinkPhoto = () => useMutation<UnlinkPhoto, UnlinkPhotoVariables>(UNLINK_PHOTO)

const UNLINK_PHOTOS = gql`
    mutation UnlinkPhotos($photoIdStrs: [String!]!) {
        unlinkPhotos(photoIdStrs: $photoIdStrs) {
            photos {
                idStr
                profile {
                    idStr
                }
            }
            profiles {
                idStr
                photos {
                    idStr
                }
            }
        }
    }
`

const useUnlinkPhotos = ({photoIdStrs}: {photoIdStrs: string[]}) =>
    useMutation<UnlinkPhotos, UnlinkPhotosVariables>(UNLINK_PHOTOS, {variables: {photoIdStrs}})

const APPROVE_PHOTOS = gql`
    mutation ApprovePhotos($photoIdStrs: [String!]!) {
        approvePhotos(photoIdStrs: $photoIdStrs) {
            photos {
                idStr
                profile {
                    idStr
                }
                pendingProfile {
                    idStr
                }
            }
            profiles {
                idStr
                photos {
                    idStr
                }
            }
        }
    }
`

const useApprovePhotos = ({photoIdStrs}: {photoIdStrs: string[]}) => {
    const {approvePhotosUpdate: update} = useApprovePhotosUpdater()
    return useMutation<ApprovePhotos, ApprovePhotosVariables>(APPROVE_PHOTOS, {
        variables: {photoIdStrs},
        update,
    })
}

const UPLOAD_PHOTO = gql`
    mutation UploadPhoto($imgFile: Upload!, $organisationIdStr: String!) {
        uploadPhoto(imgFile: $imgFile, organisationIdStr: $organisationIdStr) {
            idStr
            filename
            thumbUrl
            originalUrl
            profileUrl
            priority
            createdAt
            profile {
                idStr
                fullName
                firstName
                email
                photoRequested
            }
            pendingProfile {
                idStr
                fullName
            }
        }
    }
`

const useApprovePhotosUpdater = () => {
    const {currentOrganisation} = CurrentOrganisation.useContainer()

    const approvePhotosUpdate: MutationUpdaterFn<ApprovePhotos> = (cache, {data}) => {
        if (!currentOrganisation) return
        if (!data || !data.approvePhotos || !data.approvePhotos.photos || !data.approvePhotos.profiles) return

        const approvedPhotos = data.approvePhotos.photos.map((photo) => photo.idStr)
        const approvedProfiles = data.approvePhotos.profiles.map((profile) => profile.idStr)

        const deletedPhoto = (photo: PhotosData_organisation_photos) =>
            photo.profile && !approvedPhotos.includes(photo.idStr) && approvedProfiles.includes(photo.profile.idStr)

        const organisationIdStr = currentOrganisation.idStr

        const getPhotosResult = cache.readQuery<PhotosData, PhotosDataVariables>({
            query: PAGE_DATA,
            variables: {organisationIdStr},
        })
        if (!getPhotosResult) return

        cache.writeQuery<PhotosData, PhotosDataVariables>({
            query: PAGE_DATA,
            data: produce(cloneDeep(getPhotosResult), (draftState) => {
                draftState.organisation.photos = draftState.organisation.photos.filter(
                    (photo) => approvedPhotos.includes(photo.idStr) || !deletedPhoto(photo),
                )
            }),
            variables: {organisationIdStr},
        })
    }

    return {approvePhotosUpdate}
}

export type UploadPhotoMutationFn = (
    options?: MutationFunctionOptions<UploadPhoto, UploadPhotoVariables> | undefined,
) => Promise<void | ExecutionResult<UploadPhoto>>

type FileWithProfileId = {
    file: File
    profileIdStr: string
}

type PhotoQueueType = FileWithProfileId | File

export function isFile(queueItem: PhotoQueueType): queueItem is File {
    return queueItem instanceof File
}

const useUploadPhotoQueue = (uploadPhoto: UploadPhotoMutationFn) => {
    const organisationIdStr = useOrganisationIdStr()
    const [linkPhoto] = useLinkPhoto()
    const alert = useAlert()

    const queueState = useQueue<PhotoQueueType>(async (photoQueueItem) => {
        const file = photoQueueItem instanceof File ? photoQueueItem : photoQueueItem.file
        const profileIdStr = photoQueueItem instanceof File ? null : photoQueueItem.profileIdStr

        try {
            const response = await uploadPhoto({
                variables: {
                    organisationIdStr,
                    imgFile: file,
                },
            })
            if (profileIdStr && response && response.data && response.data.uploadPhoto) {
                await linkPhoto({
                    variables: {
                        photoIdStr: response.data.uploadPhoto.idStr,
                        profileIdStr,
                    },
                })
            }
        } catch (error) {
            alert.error(`Failed to upload ${file.name}`)
        }
    })

    const profilesLoading: string[] = []

    for (const photoQueueItem of [...queueState.pending, ...queueState.current]) {
        if (!(photoQueueItem instanceof File)) {
            profilesLoading.push(photoQueueItem.profileIdStr)
        }
    }

    return {...queueState, profilesLoading}
}

export const SEND_PHOTO_COLLECTION_EMAIL = gql`
    mutation SendPhotoCollectionEmails($profileIdStrs: [String!]!, $organisationIdStr: String!) {
        sendPhotoCollectionEmails(profileIdStrs: $profileIdStrs, organisationIdStr: $organisationIdStr) {
            idStr
            photoRequested
        }
    }
`

const useSendPhotoCollectionEmails = () =>
    useMutation<SendPhotoCollectionEmails, SendPhotoCollectionEmailsVariables>(SEND_PHOTO_COLLECTION_EMAIL)

export {
    useUnlinkPhoto,
    useUnlinkPhotos,
    useDeletePhoto,
    useCropAndRotate,
    useDeletePhotos,
    searchProfiles,
    useLinkPhoto,
    useApprovePhotos,
    searchPhotos,
    sortProfiles,
    sortPhotos,
    useUploadPhotoQueue,
    useSendPhotoCollectionEmails,
    UPLOAD_PHOTO,
}
