import styled from 'styled-components'
import {HideableCss, HideableCssProps} from './Layout'

export const Heading1 = styled.span<{fontSize?: string} & HideableCssProps>`
    color: #111111;
    font-family: 'publico-banner';
    font-size: ${({fontSize}) => fontSize || '72px'};
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 1.1em;
    paragraph-spacing: 0px;
    ${HideableCss}
`

export const Heading3 = styled.span<
    {
        fontSize?: string
        letterSpacing?: string
        maxWidth?: string
        centerHorizontal?: boolean
        cursor?: string
        backgroundColor?: string
    } & HideableCssProps
>`
    font-family: 'inter';
    font-size: ${({fontSize}) => fontSize || '20px'};
    font-style: normal;
    font-weight: 400;
    letter-spacing: ${({letterSpacing}) => letterSpacing || '-0.4px'};
    line-height: 1.4em;
    paragraph-spacing: 0px;
    color: ${({color}) => (color ? color : `#5c5c5c`)};
    ${({backgroundColor}) => (backgroundColor ? `background-color: ${backgroundColor};` : ``)};
    ${({maxWidth}) => (maxWidth ? `max-width: ${maxWidth};` : '')}
    ${({centerHorizontal}) => (centerHorizontal ? 'text-align: center;' : '')}
    ${({cursor}) => (cursor ? `cursor: ${cursor};` : '')}
    ${HideableCss}
`

export const Inter = styled.span<{fontSize?: string; fontWeight?: string; color?: string}>`
    font-family: 'inter';
    font-size: ${({fontSize}) => fontSize || '14px'};
    ${({fontWeight}) => (fontWeight ? `font-weight: ${fontWeight};` : '')}
    ${({color}) => (color ? `color: ${color};` : '')}
`

export const FieldSubText = styled.span`
    font-family: 'inter';
    font-size: 12px;
    color: rgb(92, 92, 92);
`
