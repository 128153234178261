import {colors, Icon, List, Typography} from 'nf-ui'
import SvgCheck from 'nf-ui/Icons/Check'
import React from 'react'
import styled from 'styled-components'
import {PANEL_WIDTH} from '~/constants'
import {PhotoSort} from './usePhotoSort'

export type OptionType<FilterType> = {
    title: string
    filter?: FilterType
    sort?: PhotoSort
    header?: true
    analyticsEvent?: string
}

const OptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

function SortList<FilterType>({
    heading,
    options,
    onClick,
    isOptionChecked,
}: {
    heading: string
    options: OptionType<FilterType>[]
    onClick: (option: OptionType<FilterType>) => void
    isOptionChecked: (option: OptionType<FilterType>) => boolean
}) {
    return (
        <List
            width={PANEL_WIDTH}
            heading={
                <OptionContainer>
                    <Typography.Label>{heading}</Typography.Label>
                </OptionContainer>
            }
            rows={options}
            renderRow={(option) => {
                return (
                    <OptionContainer>
                        <Typography.Paragraph bottomMargin={false}>{option.title}</Typography.Paragraph>
                        {isOptionChecked(option) && <Icon icon={SvgCheck} tint={colors.green[100]} />}
                    </OptionContainer>
                )
            }}
            onClick={onClick}
            variant="dropdown"
        />
    )
}

export {SortList}
