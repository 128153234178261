import * as React from 'react'
const SvgPhotosRegular = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgPhotosRegular(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M13.8 1.66H2.2A2.183 2.183 0 00.005 3.693L0 3.85v8.3a2.18 2.18 0 002.041 2.185l.159.005h11.6c1.174 0 2.115-.89 2.195-2.033L16 12.15v-8.3c0-1.163-.89-2.105-2.041-2.185L13.8 1.66zm0 1.06c.608 0 1.04.427 1.094 1.004l.006.126v8.3c0 .598-.39 1.064-.972 1.124l-.128.006H2.2c-.608 0-1.04-.426-1.094-1.004L1.1 12.15v-8.3c0-.597.39-1.064.972-1.124L2.2 2.72h11.6zM8.05 4.15c-2.142 0-3.9 1.731-3.9 3.85 0 2.119 1.758 3.85 3.9 3.85 2.12 0 3.8-1.71 3.8-3.85s-1.68-3.85-3.8-3.85zM8 5.21c1.56 0 2.8 1.243 2.8 2.79S9.56 10.79 8 10.79c-1.56 0-2.8-1.243-2.8-2.79S6.44 5.21 8 5.21z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgPhotosRegular
