import {Typography} from 'nf-ui'
import React from 'react'
import {useQueryChildCategoryData} from '~/apollo/queries/ChildCategoryData'
import {PageLoading} from '~/components/PageLoading'
import {UserlandActionBar} from './ActionBar'
import {ChildCategoryProfiles} from './ChildCategoryProfiles'

export const ChildCategory = ({childCategoryIdStr}: {childCategoryIdStr: string}) => {
    const [{data: childCategoryData, error, loading}] = useQueryChildCategoryData(childCategoryIdStr)

    if (error) return <Typography.Heading>Error</Typography.Heading>

    if (!childCategoryData || loading)
        return (
            <>
                <PageLoading />
                <UserlandActionBar loading />
            </>
        )

    return <ChildCategoryProfiles childCategory={childCategoryData.childCategory} />
}
