import * as React from 'react'
const SvgFlag = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgFlag(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M3.5 0a.467.467 0 00-.493.427L3 .53v14.94c0 .315.195.53.5.53.271 0 .455-.17.493-.429L4 15.47v-5.28h8.3c.502 0 .997-.28 1.24-.71.23-.4.234-.853-.014-1.255l-.082-.118-1.306-2.134 1.603-2.135c.277-.47.322-1.012.096-1.434-.238-.436-.598-.69-1.074-.736L12.6 1.66H4V.53C4 .209 3.809 0 3.5 0zM4 2.72h9.187l-1.534 2.12-.512.809c-.135.182-.18.37-.124.512l.036.067.504.787 1.274 2.115H4V2.72z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgFlag
