import {Profile} from '../useFullOrgChartData'
import {GRID_COLUMNS} from './constants'

function lastItemOf<T>(arrays: Array<T[]>) {
    const arraysWithLength = arrays.filter((array) => array.length).flatMap((arr) => arr)

    return arraysWithLength[arraysWithLength.length - 1] || null
}

function firstItemOf<T>(arrays: Array<T[]>) {
    const arraysWithLength = arrays.filter((array) => array.length).flatMap((arr) => arr)

    return arraysWithLength[0] || null
}

export const getProfileReports = (profile: Profile, getProfileFromIdStr: (idStr: string) => Profile | null) => {
    const directReports = profile.directReports
        .map((idStr) => getProfileFromIdStr(idStr))
        .filter((profile) => profile !== null) as Profile[]

    const profilesWithReports = directReports
        .filter((report) => report.directReports.length > 0 || report.additionalReports.length > 0)
        .sort((a, b) => {
            if (a.directReports.length > b.directReports.length) return 1
            if (a.directReports.length === b.directReports.length) return 0
            return -1
        })

    const additionalReports = profile.additionalReports
        .map((idStr) => getProfileFromIdStr(idStr))
        .filter((profile) => profile !== null) as Profile[]

    const withoutAdditionalManagers = (profiles: Profile[]) =>
        profiles.filter((profile) => !profile.additionalManagers.length)
    const withAdditionalManagers = (profiles: Profile[]) =>
        profiles.filter((profile) => profile.additionalManagers.length > 0)

    const reportsWithoutReports = directReports.filter(
        (report) => !report.directReports.length && !report.additionalReports.length,
    )

    const gridProfiles = withoutAdditionalManagers(reportsWithoutReports)

    const shouldShowGrid = gridProfiles.length > GRID_COLUMNS

    const profilesForGrid = shouldShowGrid
        ? gridProfiles.sort((a, b) => a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase()))
        : []
    const singleProfiles = shouldShowGrid ? [] : gridProfiles
    const additionalManagerProfiles = withAdditionalManagers(reportsWithoutReports)

    const reportsCount = directReports.length + additionalReports.length

    const allChildren = [
        profilesForGrid,
        singleProfiles,
        additionalManagerProfiles,
        profilesWithReports,
        additionalReports,
    ]

    const firstChild = firstItemOf<Profile>(allChildren)
    const lastChild = lastItemOf<Profile>(allChildren)
    const lastDirectChild = lastItemOf<Profile>([
        profilesForGrid,
        singleProfiles,
        additionalManagerProfiles,
        profilesWithReports,
    ])

    return {
        profilesWithReports,
        profilesForGrid,
        singleProfiles,
        additionalManagerProfiles,
        additionalReports,
        reportsCount,
        firstChild,
        lastChild,
        lastDirectChild,
    }
}
