import React, {FC} from 'react'
import {Icon} from '../Icon'
import SvgSearch from '../Icons/Search'
import {colors} from '../theme'
import {Input, InputProps} from './Input'
import styled from 'styled-components'

const SearchInputBase = styled(Input)`
    -webkit-appearance: textfield;

    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
`

SearchInputBase.defaultProps = {
    width: 304,
}

export const SearchInput: FC<InputProps> = (inputProps) => {
    return (
        <SearchInputBase
            type="search"
            autoComplete="off"
            {...inputProps}
            clearButton
            icon={<Icon tint={colors.darkGray} icon={SvgSearch} />}
        />
    )
}
