import * as React from 'react'
const SvgSend = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgSend(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M15.642.231a.53.53 0 00-.43-.083l-.087.03L.447 6.633a.532.532 0 00-.313.5c0 .178.097.344.25.438l.082.04 5.66 2.264 2.255 5.654a.535.535 0 00.489.338c.18.003.35-.09.448-.238l.042-.08L15.82.876a.528.528 0 00-.05-.516l-.059-.071-.07-.057zm-8.49 9.364l6.561-6.558-4.812 10.93-1.749-4.372zM2.03 7.097l10.926-4.81-6.548 6.558-4.378-1.748z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgSend
