import {gql, useQuery} from '@apollo/client'
import {List, Spacer, Typography} from 'nf-ui'
import React from 'react'
import {useAlert} from 'react-alert'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {DataSourceRequestType} from '~/globalTypes'
import {getGraphQLErrorMessage} from '~/util'
import {RequestDataSource} from './RequestDataSource'
import {SuggestedDataSource} from './SuggestedDataSource'
import {RequestListData, RequestListDataVariables} from './__types__/RequestListData'

export const DATA = gql`
    query RequestListData($idStr: String!) {
        organisation(idStr: $idStr) {
            idStr
            dataSourceRequests {
                idStr
                dataSource
            }
        }
    }
`

const buildRequestableList = () => {
    const requestable = Object.keys(DataSourceRequestType).filter((type) => type !== DataSourceRequestType.OTHER)
    requestable.push(DataSourceRequestType.OTHER)

    return requestable as DataSourceRequestType[]
}

export const RequestList = () => {
    const requestableDataSources = buildRequestableList()
    const alert = useAlert()

    const organisationIdStr = useOrganisationIdStr()
    const {data} = useQuery<RequestListData, RequestListDataVariables>(DATA, {
        variables: {idStr: organisationIdStr},
        onError: (error) => alert.error(getGraphQLErrorMessage(error)),
    })

    return (
        <>
            <Typography.Label>Request an integration</Typography.Label>
            <Spacer height={16} />
            <List
                width={304}
                rows={requestableDataSources}
                padding={false}
                renderRow={(dataSource) => {
                    if (dataSource === DataSourceRequestType.OTHER)
                        return <SuggestedDataSource dataSource={dataSource} />

                    const requested =
                        data?.organisation.dataSourceRequests?.some((request) => request.dataSource === dataSource) ||
                        false
                    return <RequestDataSource dataSource={dataSource} requested={requested} />
                }}
            />
        </>
    )
}
