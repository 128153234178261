import * as React from 'react'
const SvgDataBold = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgDataBold(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" ref={svgRef} {...props}>
            <path
                d="M9.461.953h0-.001a1.29 1.29 0 00-.731-.045h0L8.72.91l-.119.037h0l-.01.004L1.584 3.84a1.052 1.052 0 00-.116 1.94h0l.006.004.115.051h0l.003.001 1.582.669-1.59.67a1.052 1.052 0 00-.116 1.94h0l.006.003.115.052h0l.003.001 1.582.667-1.59.67a1.052 1.052 0 00-.118 1.94h0l.006.004.115.051h0l.004.002 6.996 2.884h.001c.218.087.497.1.73.045h0l.007-.002.122-.037h0l.01-.004 7.007-2.889a1.052 1.052 0 00.116-1.94h0l-.006-.004-.115-.051h0l-.003-.001-1.582-.668 1.59-.67a1.052 1.052 0 00.116-1.94h0l-.006-.004-.115-.051h0l-.003-.001-1.582-.667 1.59-.672a1.052 1.052 0 00.118-1.94h0l-.006-.003-.115-.051h0l-.004-.002L9.461.953zm-.014 11.105h0l.01-.003 3.666-1.509 2.366.958-6.466 2.626-6.464-2.626 2.366-.958 3.662 1.508h.001c.217.087.5.1.732.044h0l.008-.002.12-.038zm0-3.334h0l.01-.003 3.666-1.51 2.366.96-6.466 2.624L2.56 8.17l2.366-.959L8.587 8.72h.001c.218.087.5.1.731.045h0l.009-.002.12-.038zm-.424-1.263L2.558 4.835l6.465-2.624 6.467 2.624-6.467 2.626z"
                fill="#3C8EFF"
                stroke="#3C8EFF"
                strokeWidth={0.25}
            />
        </svg>
    )
})
export default SvgDataBold
