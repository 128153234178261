import dateFormat from 'dateformat'

export const today = () => new Date()

export const toStringAndPad = (component: number, width: number, char?: string) => {
    return component.toString().padStart(width, char || '0')
}

export const formatDateDDMMYYYY = (date: Date) => {
    return `${toStringAndPad(date.getDate(), 2)}/${toStringAndPad(date.getMonth() + 1, 2)}/${toStringAndPad(
        date.getFullYear(),
        4,
    )}`
}

export const formatDateYYYYMMDD = (date: Date) => {
    return `${toStringAndPad(date.getFullYear(), 4)}/${toStringAndPad(date.getMonth() + 1, 2)}/${toStringAndPad(
        date.getDate(),
        2,
    )}`
}

export const formatDateDDMM = (date: Date) => {
    return `${toStringAndPad(date.getDate(), 2)}/${toStringAndPad(date.getMonth() + 1, 2)}`
}

export const parseDateYYYYMMDD = (value: string, separatorLength: number = 1) => {
    const year = value.slice(0, 4)
    const month = value.slice(4 + separatorLength, 4 + separatorLength + 2)
    const day = value.slice(6 + separatorLength * 2, 6 + separatorLength * 2 + 2)
    if (monthNumberOfDays(parseInt(month), parseInt(year)) < parseInt(day)) {
        return undefined
    }
    const date = new Date(`${year}/${month}/${day}`)
    return isNaN(date.getDate()) ? undefined : date
}
export const parseDate = (value: string) => {
    const [month, day, year] = value.split('-')
    const dateValue = year ? `${month}/${day}/${year}` : day ? `${month}/${day}/0000` : month
    const date = new Date(dateValue)
    return isNaN(date.getDate()) ? null : date
}
export const toStorage = (date: Date | null) => date && dateFormat(date, 'yyyy-mm-dd')
export const toDisplay = (date: Date, options?: {hideYear?: boolean}) =>
    dateFormat(date, options?.hideYear ? 'd mmmm' : 'd mmmm yyyy')

export const monthNumberOfDays = (month: number, year: number) => {
    return new Date(year, month, 0).getDate()
}

export const convertDatesToYYYYMMDD = (dates: string[]): string[] => {
    if (dates.length === 0) return []

    const separators = ['/', '\\', '-']
    const isUS = Intl.DateTimeFormat()
        .resolvedOptions()
        .locale.startsWith('en-US')

    const hasDayGreaterThan12AtStartRegex = /^(3[0-1]|2[0-9]|1[3-9])[^0-9]/
    const hasDayGreaterThan12AtStart = dates.some((date) => hasDayGreaterThan12AtStartRegex.test(date))

    const hasDayGreaterThan12InMiddleRegex = /[^0-9](3[0-1]|2[0-9]|1[3-9])[^0-9]/
    const hasDayGreaterThan12InMiddle = dates.some((date) => hasDayGreaterThan12InMiddleRegex.test(date))

    const hasYearAtStartRegex = /^[0-9]{4}[^0-9]/
    const hasYearAtStart = dates.some((date) => hasYearAtStartRegex.test(date))

    const detectedFormat = hasYearAtStart
        ? 'YMD'
        : hasDayGreaterThan12AtStart === hasDayGreaterThan12InMiddle
        ? isUS
            ? 'MDY'
            : 'DMY'
        : hasDayGreaterThan12AtStart
        ? 'DMY'
        : 'MDY'

    return dates.map((date) => {
        const separator = separators.find((sep) => date.includes(sep))
        if (!separator) return date

        const parts = date.split(separator)
        if (parts.length !== 3 || parts.some((part) => isNaN(Number(part)))) return date

        if (!parts.some((part) => part.length === 4)) {
            return date
        }

        if (parts.every((part) => parseInt(part) > 12)) {
            return date
        }

        const hasDayGreaterThan12InMiddle = hasDayGreaterThan12InMiddleRegex.test(date)
        const [part1, part2, part3] = parts

        if (part1.length === 4 && hasDayGreaterThan12InMiddle) {
            return `${part1}/${part3.padStart(2, '0')}/${part2.padStart(2, '0')}`
        }
        if (part1.length === 4) {
            return `${part1}/${part2.padStart(2, '0')}/${part3.padStart(2, '0')}`
        }
        if (part3.length === 4 && hasDayGreaterThan12InMiddle) {
            return `${part3}/${part1.padStart(2, '0')}/${part2.padStart(2, '0')}`
        }

        const hasDayGreaterThan12AtStart = hasDayGreaterThan12AtStartRegex.test(date)
        if (part3.length === 4 && hasDayGreaterThan12AtStart) {
            return `${part3}/${part2.padStart(2, '0')}/${part1.padStart(2, '0')}`
        }

        const year = detectedFormat === 'YMD' ? part1 : part3
        if (year.length === 4) {
            const month = detectedFormat === 'MDY' ? part1 : part2
            const day = detectedFormat === 'YMD' ? part3 : detectedFormat === 'MDY' ? part2 : part1
            return `${year}/${month.padStart(2, '0')}/${day.padStart(2, '0')}`
        }
        if (part1.length === 4) {
            return `${part1}/${part2.padStart(2, '0')}/${part3.padStart(2, '0')}`
        } else if (isUS) {
            return `${part3}/${part1.padStart(2, '0')}/${part2.padStart(2, '0')}`
        } else {
            return `${part3}/${part2.padStart(2, '0')}/${part1.padStart(2, '0')}`
        }
    })
}
