import React from 'react'
import {LINE_SIZE, PAGE_PADDING, VERTICAL_LINE_OFFSET} from './constants'
import {styled} from './syledPdf'
import {usePrintContext} from './usePrintContext'
import {linesForPage, VerticalLine} from './getVerticalLines'

const SolidLine = styled.View`
    position: absolute;
    background-color: black;
    width: ${LINE_SIZE}px;
`

const DottedLine = styled.View`
    position: absolute;
    border-left: 1px dashed #a0a6aa;
    width: ${LINE_SIZE}px;
`

const toPixels = (pixels: number) => `${pixels}px`

export const SolidVerticalLine = ({top, left, height}: {top: number; left: number; height: number}) => {
    return (
        <SolidLine
            style={{
                top: toPixels(top),
                left: toPixels(left),
                height: toPixels(height),
            }}
        />
    )
}

export const VerticalLines = ({
    solidLines,
    dottedLines,
    page,
}: {
    solidLines: VerticalLine[]
    dottedLines: VerticalLine[] | null
    page: number
}) => {
    const {indentationWidth} = usePrintContext()
    const solidVerticalLines = linesForPage(page, solidLines)
    const dottedVerticalLines = linesForPage(page, dottedLines || [])

    const indendatationFor = (line: VerticalLine) =>
        PAGE_PADDING + line.indentation * indentationWidth + VERTICAL_LINE_OFFSET - 0.5

    return (
        <>
            {solidVerticalLines.map((line, lineIndex) => {
                return (
                    <SolidVerticalLine
                        top={line.start}
                        left={indendatationFor(line)}
                        height={line.end - line.start}
                        key={`solidLine_${lineIndex}`}
                    />
                )
            })}
            {dottedVerticalLines.map((line, lineIndex) => {
                return (
                    <DottedLine
                        style={{
                            top: toPixels(line.start),
                            left: toPixels(indendatationFor(line)),
                            height: toPixels(line.end - line.start),
                        }}
                        key={`dottedLine_${lineIndex}`}
                    />
                )
            })}
        </>
    )
}
