import {gql, useQuery} from '@apollo/client'
import {Token} from './TokenContext'
import {SystemRedirectData} from './__types__/SystemRedirectData'

const SYSTEM_REDIRECT_DATA = gql`
    query SystemRedirectData {
        systemAccessCheck
    }
`

export function useSystemAcessCheck() {
    const {token} = Token.useContainer()

    const {data, loading, error} = useQuery<SystemRedirectData>(SYSTEM_REDIRECT_DATA, {
        fetchPolicy: 'network-only',
        skip: !token,
    })

    return {
        systemAccessCheck: data?.systemAccessCheck,
        loading,
        error,
    }
}
