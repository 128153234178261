import React from 'react'
import styled from 'styled-components'

export const MEDIA_PANEL_HEIGHT = 248

const Image = styled.img`
    width: 100%;
    height: auto;
`

type StepContent = {
    title: string
    content: string
    buttonLabel: string
    media?: JSX.Element
    overlay?: boolean
}

export const STEP_CONTENT: Record<number, StepContent> = {
    1: {
        title: 'Welcome to Meetings',
        content: 'Connect your Google Calender to view insights of the people you will be meeting with this week.',
        buttonLabel: 'Tell me more',
        media: (
            <Image
                src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/1_welcome.png"
                alt="Find team members"
            />
        ),
    },
    2: {
        title: 'Your calendar, now with faces',
        content: 'View the profiles of people in your upcoming meetings to get context of all the attendees.',
        buttonLabel: 'Next',
        media: (
            <Image
                src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/2_faces.png"
                alt="Find team members"
            />
        ),
    },
    3: {
        title: 'Everyone is included',
        content: 'Profiles of people outside your organization are automatically populated with data from Linkedin.',
        buttonLabel: 'Get started',
        media: (
            <Image
                src="https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/3_external.png"
                alt="Get in touch"
            />
        ),
    },
}
