import * as React from 'react'
const SvgRemove = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgRemove(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M8 0a7.99 7.99 0 00-8 8 7.99 7.99 0 008 8c4.428 0 8-3.576 8-8 0-4.424-3.571-8-8-8zm5.34 3.466l.204.288C14.465 5.049 15 6.514 15 7.999c0 3.834-3.135 6.94-7 6.94-1.417 0-2.788-.444-4.016-1.268l-.26-.183-.285-.207 9.9-9.815zM8 1.059c1.46 0 2.929.472 4.064 1.291l.223.169.263.209-9.775 9.793-.21-.262C1.615 11.069 1.1 9.587 1.1 7.999c0-3.85 3.053-6.94 6.9-6.94z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgRemove
