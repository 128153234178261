import React from 'react'
import styled from 'styled-components'
import {ROW_HEIGHT, BORDER_WIDTH} from './constants'
import {FadePresence} from '../FadePresence'
import {CellHoverState} from './utils/useCellHover'
import {TableScrollState} from './utils/useTableScroll'
import {BorderHighlight} from './utils/BorderHighlight'
import {RowHighlightState} from './utils/useRowHighlight'

export const Container = styled(BorderHighlight)`
    width: 100%;
    height: ${ROW_HEIGHT + BORDER_WIDTH}px;
`

const RowHighlight: React.FC<{
    activeCell: CellHoverState['cell']
    tableScroll: TableScrollState
    tableContentWidth: number
    tableWidth: number
}> = ({activeCell, tableScroll, tableContentWidth, tableWidth}) => {
    const scrollLeft = tableScroll.position.left.get()
    const noBorderLeft = scrollLeft > 0
    const noBorderRight = tableContentWidth >= tableWidth + scrollLeft + BORDER_WIDTH * 2

    return (
        <FadePresence>
            {activeCell && !activeCell.header && (
                <Container
                    animate={{translateY: activeCell.top + BORDER_WIDTH}}
                    style={{
                        top: tableScroll.inverse.top,
                        borderLeft: noBorderLeft ? 'none' : undefined,
                        borderRight: noBorderRight ? 'none' : undefined,
                    }}
                />
            )}
        </FadePresence>
    )
}

const RowsHighlight: React.FC<{
    activeCells: RowHighlightState['cells']
    tableScroll: TableScrollState
    tableContentWidth: number
    tableWidth: number
}> = ({activeCells, tableScroll, tableContentWidth, tableWidth}) => {
    const scrollLeft = tableScroll.position.left.get()
    const noBorderLeft = scrollLeft > 0
    const noBorderRight = tableContentWidth >= tableWidth + scrollLeft + BORDER_WIDTH * 2

    return (
        <FadePresence>
            {activeCells &&
                activeCells.map((activeCell) => {
                    if (activeCell) {
                        return (
                            <Container
                                animate={{translateY: activeCell.top + BORDER_WIDTH}}
                                style={{
                                    top: tableScroll.inverse.top,
                                    borderLeft: noBorderLeft ? 'none' : undefined,
                                    borderTopLeftRadius: noBorderLeft ? 0 : undefined,
                                    borderBottomLeftRadius: noBorderLeft ? 0 : undefined,
                                    borderRight: noBorderRight ? 'none' : undefined,
                                    borderTopRightRadius: noBorderRight ? 0 : undefined,
                                    borderBottomRightRadius: noBorderRight ? 0 : undefined,
                                }}
                            />
                        )
                    }
                    return <></>
                })}
        </FadePresence>
    )
}

export {RowHighlight, RowsHighlight}
