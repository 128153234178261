import {gql, useQuery} from '@apollo/client'
import {Button, FileInput, Form, Panel, Spacer, Typography} from 'nf-ui'
import {SearchInput} from 'nf-ui/Form/SearchInput'
import SvgAdd from 'nf-ui/Icons/Add'
import React, {Dispatch, FC, SetStateAction, useRef, useState} from 'react'
import styled from 'styled-components'
import {ModalLayout} from '~/components/ModalLayout/index'
import {PageLoading} from '~/components/PageLoading'
import {PanelLayout, PanelLayoutButtons} from '~/components/PanelLayout'
import {Photo} from '~/components/Photo/Photo'
import {searchPhotos, sortPhotos} from '~/components/PhotoManagementCommon'
import {PANEL_WIDTH} from '~/constants'
import {ChoosePhotoModalData, ChoosePhotoModalDataVariables} from './__types__/ChoosePhotoModalData'

const GET_PHOTOS = gql`
    query ChoosePhotoModalData($organisationIdStr: String!) {
        organisation(idStr: $organisationIdStr) {
            idStr
            photos {
                idStr
                filename
                originalUrl
                thumbUrl
                priority
                profile {
                    idStr
                    fullName
                    firstName
                    email
                    photoRequested
                }
            }
        }
    }
`

const PhotosList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 120px);
    grid-gap: 8px;
`

const Toolbar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ConfirmMatch: React.FC<{onCancel: () => void; onConfirm: () => void; profileName: string}> = ({
    onCancel,
    onConfirm,
    profileName,
}) => {
    return (
        <PanelLayout width={PANEL_WIDTH}>
            <Typography.Label>Match this photo</Typography.Label>
            <Typography.Paragraph>
                Matching this photo will unmatch it from {profileName}. Continue?
            </Typography.Paragraph>
            <PanelLayoutButtons>
                <Button variant="tertiary" onClick={onCancel}>
                    No, keep
                </Button>
                <Button variant="tertiary" onClick={onConfirm}>
                    Yes, match
                </Button>
            </PanelLayoutButtons>
        </PanelLayout>
    )
}

type Profile = {
    fullName: string
}

export type ChoosePhotoModalProps = {
    profile: Profile
    organisationIdStr: string
    onChoosePhoto: (photoIdStr: string) => void
    onUploadFile: (file: File) => void
}

type PhotoItemProps = {
    photo: ChoosePhotoModalData['organisation']['photos'][0]
    setShowConfirmMatch: Dispatch<SetStateAction<string | null>>
    showConfirmMatch: string | null
    search: string
    setSelectedPhoto: Dispatch<SetStateAction<string | undefined>>
    selectedPhoto: string | undefined
} & Pick<ChoosePhotoModalProps, 'onChoosePhoto'>

const PhotoItem: FC<PhotoItemProps> = ({
    photo,
    setShowConfirmMatch,
    onChoosePhoto,
    showConfirmMatch,
    search,
    setSelectedPhoto,
    selectedPhoto,
}) => {
    const buttonRef = useRef<HTMLButtonElement>(null)
    return (
        <>
            <Photo
                showEditOnHover={false}
                key={photo.idStr}
                photo={photo}
                ref={buttonRef}
                onClick={() => {
                    if (photo.profile) {
                        setShowConfirmMatch(photo.idStr)
                    } else {
                        onChoosePhoto(photo.idStr)
                    }
                    setSelectedPhoto(photo.idStr)
                }}
                search={search}
                selected={selectedPhoto === photo.idStr}
            />
            <Panel
                targetRef={buttonRef}
                open={photo.idStr === showConfirmMatch}
                onClose={() => setShowConfirmMatch(null)}
            >
                <ConfirmMatch
                    onConfirm={() => {
                        setShowConfirmMatch(null)
                        onChoosePhoto(photo.idStr)
                    }}
                    onCancel={() => {
                        setShowConfirmMatch(null)
                    }}
                    profileName={photo.profile ? photo.profile.fullName : ''}
                />
            </Panel>
        </>
    )
}

const ChoosePhotoModal: FC<ChoosePhotoModalProps> = ({profile, organisationIdStr, onChoosePhoto, onUploadFile}) => {
    const {data, loading} = useQuery<ChoosePhotoModalData, ChoosePhotoModalDataVariables>(GET_PHOTOS, {
        variables: {
            organisationIdStr,
        },
    })

    const [showConfirmMatch, setShowConfirmMatch] = useState<string | null>(null)
    const [selectedPhoto, setSelectedPhoto] = useState<string>()
    const [photoSearch, setPhotoSearch] = useState('')

    if (loading || !data) return <PageLoading />

    const photos = sortPhotos(searchPhotos(data.organisation.photos, photoSearch))

    return (
        <ModalLayout>
            <ModalLayout.Navigation />
            <ModalLayout.Body>
                <Typography.Heading>Pick a photo for {profile.fullName}</Typography.Heading>
                <Spacer height={32} />
                <Toolbar>
                    <Form.Group name="search">
                        <SearchInput value={photoSearch} onChange={setPhotoSearch} />
                    </Form.Group>
                    <Spacer width={16} />
                    <FileInput
                        onChange={(event) => {
                            if (!event.target.files) return
                            onUploadFile(event.target.files[0])
                        }}
                        buttonProps={{variant: 'secondary'}}
                    >
                        Upload a new photo <FileInput.Icon icon={SvgAdd} />
                    </FileInput>
                </Toolbar>
                <Spacer height={32} />
                <PhotosList>
                    {photos.map((photo) => (
                        <PhotoItem
                            key={photo.idStr}
                            onChoosePhoto={onChoosePhoto}
                            photo={photo}
                            setShowConfirmMatch={setShowConfirmMatch}
                            showConfirmMatch={showConfirmMatch}
                            search={photoSearch}
                            selectedPhoto={selectedPhoto}
                            setSelectedPhoto={setSelectedPhoto}
                        />
                    ))}
                </PhotosList>
            </ModalLayout.Body>
        </ModalLayout>
    )
}

export {ChoosePhotoModal}
