import {Icon, Spacer, Typography} from 'nf-ui'
import SvgEmail from 'nf-ui/Icons/Email'
import React from 'react'
import {EmailWrapper} from './AttendeeProfile'

type GuestProfileDetailsProps = {
    email?: string
    title?: string | null
    company?: string | null
    location?: string | null
    bio?: string | null
}

const DetailWithTitle = ({title, value}: {title: string; value: string}) => {
    return (
        <>
            <Typography.Label block>{title}</Typography.Label>
            <Typography.Paragraph bottomMargin={false}>{value}</Typography.Paragraph>
            <Spacer height={16} />
        </>
    )
}

export const GuestProfileDetails: React.FC<GuestProfileDetailsProps> = ({email, title, company, location, bio}) => {
    return (
        <>
            {title && (
                <>
                    <Typography.Label block>Email</Typography.Label>
                    <EmailWrapper email={email || ''}>
                        <Typography.Paragraph bottomMargin={false}>{email}</Typography.Paragraph>
                        <Icon icon={SvgEmail} />
                    </EmailWrapper>
                    <Spacer height={16} />
                </>
            )}
            {company && <DetailWithTitle title="Company" value={company} />}
            {location && <DetailWithTitle title="Location" value={location} />}
            {bio && <DetailWithTitle title="Bio" value={bio} />}
        </>
    )
}
