import {gql, useQuery} from '@apollo/client'
import {CurrentUserData} from './__types__/CurrentUserData'

export const CURRENT_USER = gql`
    query CurrentUserData {
        me {
            idStr
            firstName
            lastName
            email
            hasAcceptedTerms
            hasViewedUserland
            hasViewedOrgChartPanel
            hasViewedMeetings
            isSuperAdmin
            npsOutstanding
            calendarConnected
        }
    }
`

export function useCurrentUser() {
    const {data, loading, error} = useQuery<CurrentUserData>(CURRENT_USER)
    const me = data?.me
    const superAdmin = me?.isSuperAdmin || false

    return {
        me,
        superAdmin,
        loading,
        error,
    }
}
