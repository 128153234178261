import {useCallback, useState} from 'react'
import {createContainer} from 'unstated-next'

const useToken = () => {
    const getInitialToken = () => localStorage.getItem('token') || ''
    const [tokenState, setTokenState] = useState(getInitialToken)

    const setToken = useCallback(
        (token: string) => {
            if (token.length > 0) localStorage.setItem('token', token)
            else localStorage.removeItem('token')

            setTokenState(token)
        },
        [setTokenState],
    )

    return {token: tokenState, setToken}
}

export const Token = createContainer(useToken)
