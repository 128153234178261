import {Button, SquareLogo} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import React from 'react'
import styled from 'styled-components'
import {useModalNavigator} from './ModalNavigator'

const NAVIGATION_HEIGHT = 120
const NAVIGATION_HEIGHT_NARROW = 90
const LOGO_PADDING = 32

const Container = styled.div`
    position: relative;
    height: ${(props: {narrow: boolean}) => (props.narrow ? NAVIGATION_HEIGHT_NARROW : NAVIGATION_HEIGHT)}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const RightContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 28px;
`

const LogoContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding: ${LOGO_PADDING}px;

    a {
        display: block;
    }
`

const Logo: React.FC = () => {
    return (
        <LogoContainer>
            <a href="/">
                <SquareLogo />
            </a>
        </LogoContainer>
    )
}

const Navigation: React.FC<{
    onClose?: () => void
    closeText?: string
    closeColor?: string
    closeable?: boolean
    narrow?: boolean
}> = ({children, onClose: onCloseProp, closeText, closeColor, closeable = true, narrow = false}) => {
    const {onClose} = useModalNavigator()

    return (
        <Container narrow={narrow}>
            <Logo />
            {children}
            {closeable && (
                <RightContainer>
                    <Button variant="tertiary" color={closeColor} onClick={onCloseProp || onClose}>
                        {closeText || 'Cancel'} <Button.Icon icon={SvgClose} />
                    </Button>
                </RightContainer>
            )}
        </Container>
    )
}

export {Navigation, Container}
