/* eslint-disable jsx-a11y/iframe-has-title */
import {Icon} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import React from 'react'
import Transition, {TransitionStatus} from 'react-transition-group/Transition'
import styled, {css, keyframes} from 'styled-components'

const fadein = keyframes`
from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const fadeout = keyframes`
from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`

interface VideoModalProps {
    visible: boolean
    onClose: () => void
}

interface VideoFrameModal extends Pick<VideoModalProps, 'onClose'> {
    state: TransitionStatus
}

const Container = styled.div<{fadeIn: boolean}>`
    animation: ${fadeout} 0.275s;
    opacity: 0;
    background: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.fadeIn
            ? css`
                  animation: ${fadein} 0.275s;
                  opacity: 1;
              `
            : ''}
`

const VideoFrame = styled.iframe`
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
`

const VideoFrameModal = ({state, onClose}: VideoFrameModal) => {
    let fadeIn

    switch (state) {
        case 'entering':
        case 'entered':
            fadeIn = true
            break

        case 'exiting':
            fadeIn = false
            break

        case 'exited':
        default:
            return null
    }

    return (
        <Container fadeIn={fadeIn} onClick={onClose}>
            <Icon icon={SvgClose} />

            <VideoFrame
                src="https://player.vimeo.com/video/283609338?autoplay=1"
                width="880"
                height="495"
                frameBorder="0"
                allowFullScreen
            />
        </Container>
    )
}

export const VideoModal: React.FC<VideoModalProps> = ({visible, onClose}) => {
    return (
        <Transition in={visible} timeout={300} key="video-modal">
            {(state) => <VideoFrameModal state={state} onClose={onClose} />}
        </Transition>
    )
}
