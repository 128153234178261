import {useState} from 'react'
import {createContainer} from 'unstated-next'
import {encodeDelimitedArray, decodeDelimitedArray, useQueryParam} from 'use-query-params'
import {BaseSelectionItem} from './SelectionList'

const CommaArrayParam = {
    encode: (array: string[] | null | undefined) => encodeDelimitedArray(array, ','),
    decode: (arrayStr: string | string[] | null | undefined) => decodeDelimitedArray(arrayStr, ','),
}

export const useSetSelectedItems = () => {
    const [selectedIdStrs, setSelectedIdStrs] = useQueryParam<string[] | undefined>('selectedIdStrs', CommaArrayParam)

    return {
        selectedIdStrs,
        setSelectedIdStrs,
    }
}

export const useListSelection = (initialState: {availableItems?: BaseSelectionItem[]} | undefined) => {
    const availableItems = initialState?.availableItems?.filter((item) => !item.disabled) || []

    const {selectedIdStrs} = useSetSelectedItems()
    const [selection, setSelection] = useState<string[]>(selectedIdStrs || [])
    const [sort, setSort] = useState<string>('ASC')

    const allSelected = selection.length === availableItems?.length

    const toggleAll = () => {
        if (allSelected) {
            setSelection([])
        } else {
            setSelection(availableItems?.map((item) => item.idStr) || [])
        }
    }

    const toggleItem = (idStr: string) => {
        if (selection.includes(idStr)) {
            setSelection(selection.filter((item) => item !== idStr))
        } else {
            setSelection([...selection, idStr])
        }
    }

    return {
        selection,
        toggleAll,
        toggleItem,
        sort,
        setSort,
        allSelected,
    }
}

const SelectionContainer = createContainer(useListSelection)
export const useSelectionContext = SelectionContainer.useContainer
export const SelectionProvider = SelectionContainer.Provider
