import {gql, useMutation} from '@apollo/client'
import {Button, colors, Form, Lightbox, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import SvgClose from 'nf-ui/Icons/Close'
import React, {FormEvent, useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import * as yup from 'yup'
import AppStore from '~/components/appstore.svg'
import PlayStore from '~/components/playstore.svg'
import {getGraphQLErrorMessage} from '~/util'
import {useResendVerificationEmail} from './VerificationEmailSent'
import {RequestDemoAccount, RequestDemoAccountVariables} from './__types__/RequestDemoAccount'

const CREATE_DEMO_ACCOUNT = gql`
    mutation RequestDemoAccount($email: String!, $firstName: String!) {
        requestDemoAccount(email: $email, firstName: $firstName)
    }
`

const useCreateAccount = () => useMutation<RequestDemoAccount, RequestDemoAccountVariables>(CREATE_DEMO_ACCOUNT)

const formValidator = yup.object({
    email: yup
        .string()
        .email()
        .required(),
    firstName: yup.string().required(),
})

const HeadingContainer = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
`

const SvgWrapper = styled.div`
    :hover {
        cursor: pointer;
    }
`

const MobileContainer = styled.div`
    display: flex;
`

const BackgroundImage = styled.div`
    background-image: url('https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/background.jpg');
    height: 100%;

    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const CloseButton = styled.span`
    border-radius: 3px;
    transition: 0.15s background-color ease-in-out;
    height: fit-content;
    :hover {
        cursor: pointer;
        background-color: ${colors.lightGray};
    }
`

const openLink = (link: string) => {
    window.open(link, '_blank')
}

const DemoEmailSent = ({onClickBack, email}: {onClickBack: () => void; email: string}) => {
    const [resendVerificationEmail] = useResendVerificationEmail()

    return (
        <>
            <HeadingContainer>
                <Typography.Heading>Check your inbox</Typography.Heading>
            </HeadingContainer>
            <Spacer height={16} />
            <Typography.Subheading maxWidth={450}>
                We’ve emailed you an invite to join our demo org chart and public directory.
            </Typography.Subheading>
            <Spacer height={32} />
            <Typography.Subheading maxWidth={450}>
                If you want to experience Names & Faces on the go, be sure to download our mobile app.
            </Typography.Subheading>
            <Spacer height={40} />
            <MobileContainer>
                <SvgWrapper onClick={() => openLink('https://itunes.apple.com/za/app/names-faces/id949875464')}>
                    <AppStore style={{height: '40px', width: 'auto'}} />
                </SvgWrapper>
                <Spacer width={24} />
                <SvgWrapper
                    onClick={() => openLink('https://play.google.com/store/apps/details?id=za.co.polymorph.namesfaces')}
                >
                    <PlayStore style={{height: '40px', width: 'auto'}} />
                </SvgWrapper>
            </MobileContainer>
            <Spacer height={32} />
            <Button variant="tertiary" onClick={() => resendVerificationEmail({variables: {email}})}>
                Resend invite <Button.Icon icon={SvgArrowRight} />
            </Button>
        </>
    )
}

export const CreateDemoAccount: React.FC = () => {
    const alert = useAlert()
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [verificationEmail, setVerificationEmail] = useState(false)

    const [createAccount, {loading}] = useCreateAccount()
    const onSubmit = async (event: FormEvent) => {
        try {
            event.preventDefault()
            await createAccount({variables: {email, firstName}})
            setVerificationEmail(true)
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const valid = !loading && formValidator.isValidSync({email, firstName})

    return (
        <div>
            <BackgroundImage />
            <Lightbox open={true} width={480}>
                {verificationEmail ? (
                    <DemoEmailSent onClickBack={() => setVerificationEmail(false)} email={email} />
                ) : (
                    <div>
                        <FlexWrapper>
                            <Typography.Heading>Get started</Typography.Heading>
                            <CloseButton
                                onClick={() => {
                                    trackAnalyticsEvent('demoAccount', {
                                        page: 'request_invite',
                                        component: 'button',
                                        action: 'click',
                                        name: 'close',
                                    })
                                    window.location.href = 'https://www.namesandfaces.com/get-started'
                                }}
                            >
                                <Button.Icon icon={SvgClose} size={24} />
                            </CloseButton>
                        </FlexWrapper>
                        <Spacer height={16} />
                        <Typography.Subheading maxWidth={450}>
                            Add your details below to receive an instant invite to our demo org chart and directory.
                        </Typography.Subheading>
                        <Spacer height={32} />
                        <Form onSubmit={(event) => onSubmit(event)}>
                            <Form.Group name="firstName">
                                <Form.Label>First name</Form.Label>
                                <Form.Input value={firstName} autoFocus onChange={(value) => setFirstName(value)} />
                            </Form.Group>
                            <Form.Group name="email">
                                <Form.Label>Company email address</Form.Label>
                                <Form.Input value={email} onChange={(value) => setEmail(value)} />
                            </Form.Group>
                            <Spacer height={16} />
                            <Button disabled={!valid} variant="primary" type="submit">
                                Get invite <Button.Icon icon={SvgArrowRight} />
                            </Button>
                        </Form>
                    </div>
                )}
            </Lightbox>
        </div>
    )
}
