import * as React from 'react'
const SvgUpload = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgUpload(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M8.4 2c-1.546 0-2.81.658-3.654 1.738-.457.65-.783 1.318-.91 1.88l-.04.206-.03.218-.218.035C1.523 6.407 0 8.257 0 10.128c0 1.88.583 3.135 1.67 3.85.691.473 1.448.667 2.279.698l.251.005h7.5c1.018 0 1.923-.191 2.64-.66 1.046-.657 1.66-1.73 1.66-3.19 0-1.803-1.117-3.306-2.595-3.735l-.172-.045-.208-.047-.023-.213c-.107-.984-.428-1.956-.952-2.75C11.17 2.722 10.002 2 8.4 2zm0 1.06c1.148 0 2.038.497 2.631 1.366l.115.18C11.718 5.423 12 6.5 12 7.51c0 .113.04.217.118.303l.067.06.068.076c.014.021.1.06.191.08l.068.009.074.001c1.158-.055 2.314 1.157 2.314 2.791 0 1.138-.361 1.857-1.048 2.29-.517.304-1.194.463-1.89.494l-.262.006H4.2c-.736 0-1.397-.142-1.973-.521C1.457 12.555 1 11.627 1 10.128c0-1.537 1.456-3.045 3.2-3.045.311 0 .5-.2.507-.573.19-.85.415-1.486.869-2.126C6.338 3.526 7.233 3.06 8.4 3.06zm-.078 3.613a.533.533 0 00-.558-.052l-.087.053-1.682 1.232-.055.061a.574.574 0 00-.08.543l.043.094.06.091c.09.166.392.226.604.108l.074-.053.859-.534v2.615c0 .32.192.53.5.53.22 0 .444-.199.491-.439l.009-.091V8.117l.77.64c.269.186.595.14.798-.108.162-.199.135-.474-.068-.667l-.084-.068-1.594-1.24z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgUpload
