import * as React from 'react'
const SvgFilter = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgFilter(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M5.873 10.651c.242-.277.161-.681-.08-.903-.322-.222-.724-.177-.966.1l-.16.176V4.143c0-.355-.322-.643-.644-.643-.403 0-.724.288-.724.643v5.881l-.08-.176c-.242-.277-.645-.322-.966-.1-.322.222-.322.626-.08.903l1.286 1.607c.161.153.322.242.564.242.16 0 .402-.09.482-.242l1.368-1.607zm2.173 1.206c-.403 0-.724-.288-.724-.643 0-.355.321-.642.724-.642h5.31c.403 0 .644.287.644.642 0 .355-.241.643-.644.643h-5.31zm0-3.215c-.403 0-.724-.287-.724-.642 0-.355.321-.643.724-.643h3.299c.402 0 .643.288.643.643 0 .355-.24.642-.643.642h-3.3zm-.724-3.856c0 .355.321.642.724.642h1.93c.403 0 .725-.287.725-.642 0-.355-.322-.643-.724-.643H8.046c-.403 0-.724.288-.724.643z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgFilter
