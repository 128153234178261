import styled from 'styled-components'
import {HideableCss} from './css'
import {HideableCssProps} from './types'

export const Grid = styled.div<
    {
        columns?: number
        gap?: string
        width?: string
        maxWidth?: string
        minWidth?: string
        height?: string
        maxHeight?: string
        minHeight?: string
        overflow?: string
        overflowX?: string
        overflowY?: string
        backgroundColor?: string
    } & HideableCssProps
>`
    display: grid;
    grid-template-columns: ${({columns}) => `repeat(${columns || 1}, 1fr)`};
    gap: ${({gap}) => gap || '0px'};
    width: ${({width}) => width || '100%'};
    max-width: ${({maxWidth}) => maxWidth || 'none'};
    min-width: ${({minWidth}) => minWidth || '0'};
    height: ${({height}) => height || 'auto'};
    max-height: ${({maxHeight}) => maxHeight || 'none'};
    min-height: ${({minHeight}) => minHeight || '0'};
    overflow: ${({overflow}) => overflow || 'visible'};
    overflow-x: ${({overflowX}) => overflowX || 'visible'};
    overflow-y: ${({overflowY}) => overflowY || 'visible'};
    background-color: ${({backgroundColor}) => backgroundColor || 'transparent'};
    ${HideableCss}
`
