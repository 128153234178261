import React, {useContext, useMemo, useState} from 'react'
import {FullOrgChartData_organisation, FullOrgChartData_organisation_fields} from '../__types__/FullOrgChartData'
import {useCollapsedProfiles} from './useCollapsedProfiles'
import {DetailConfig, useDetailConfig, useSetUpDetailConfig} from './useDetailConfig'

const DisplayOptionsContext = React.createContext<{
    detailConfig: DetailConfig[] | null
    setDetailConfig: (config: DetailConfig[] | null, persist?: boolean) => void
    toggleDetailVisibility: (fieldIdStr: string) => void
    firstNameField: FullOrgChartData_organisation_fields | null
    setFirstNameField: React.Dispatch<React.SetStateAction<FullOrgChartData_organisation_fields | null>>
    toggleCollapsedProfile: (profileIdStr: string) => void
    isCollapsed: (profileIdStr: string) => boolean
} | null>(null)

export const useDisplayOptions = () => {
    const context = useContext(DisplayOptionsContext)
    if (!context) throw new Error('Cannot use display options context outside of the <DisplayOptionsProvider />')

    return context
}

export const DisplayOptionsProvider: React.FC<{organisation: FullOrgChartData_organisation}> = ({
    children,
    organisation,
}) => {
    const {detailConfig, toggleDetailVisibility, setDetailConfig} = useDetailConfig(organisation.idStr)
    const [firstNameField, setFirstNameField] = useState<FullOrgChartData_organisation_fields | null>(null)
    const {toggleCollapsedProfile, isCollapsed} = useCollapsedProfiles(organisation.idStr)

    useSetUpDetailConfig(detailConfig, setDetailConfig, setFirstNameField, organisation)

    const contextValue = useMemo(
        () => ({
            detailConfig,
            setDetailConfig,
            toggleDetailVisibility,
            firstNameField,
            setFirstNameField,
            toggleCollapsedProfile,
            isCollapsed,
        }),
        [
            detailConfig,
            setDetailConfig,
            toggleDetailVisibility,
            firstNameField,
            setFirstNameField,
            toggleCollapsedProfile,
            isCollapsed,
        ],
    )

    return <DisplayOptionsContext.Provider value={contextValue}>{children}</DisplayOptionsContext.Provider>
}
