import {Button, ButtonContainer, Icon as SvgIcon, Spacer, Spinner, useAnalyticsEventTracker} from 'nf-ui'
import SvgAdd from 'nf-ui/Icons/Add'
import React from 'react'
import styled, {css} from 'styled-components'

const Row = styled.div<{isDisabled: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    width: 304px;
    box-sizing: border-box;

    ${ButtonContainer} {
        opacity: 0;
        transition: 0.25s opacity ease-in-out;
    }

    &:hover ${ButtonContainer} {
        opacity: 1;
    }

    ${(props) =>
        props.isDisabled &&
        css`
            opacity: 0.35;
            pointer-events: none;
        `}
`

const Column = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Icon = styled.img`
    width: 32px;
    height: 32px;
`

export type DataSource = {
    icon: string
    label: string
    loading: boolean
    onClick: () => void
    analyticsEvent?: string
}

type DataSourceProps = {
    enabled: boolean
    dataSource: DataSource
    buttonLabel: string
}

export const DataSource = React.forwardRef<HTMLDivElement, DataSourceProps>(
    ({enabled, dataSource, buttonLabel}, ref) => {
        const trackAnalyticsEvent = useAnalyticsEventTracker()

        return (
            <Row
                data-testid="DataSource"
                isDisabled={!enabled}
                onClick={() => {
                    if (!enabled) return

                    dataSource.onClick()
                    dataSource.analyticsEvent && trackAnalyticsEvent(dataSource.analyticsEvent)
                }}
                ref={ref}
            >
                <Column>
                    <Spacer width={16} />
                    {!dataSource.icon ? (
                        <SvgIcon icon={SvgAdd} />
                    ) : (
                        <Icon src={dataSource.icon} alt={dataSource.label} />
                    )}
                    <Spacer width={16} />
                    <span data-testid="label">{dataSource.label}</span>
                </Column>
                <Column>
                    {dataSource.loading ? (
                        <>
                            <Spinner data-testid="spinner" />
                            <Spacer width={24} />
                        </>
                    ) : enabled ? (
                        <>
                            <Button variant="tertiary" data-testid="button">
                                {buttonLabel}
                            </Button>
                            <Spacer width={4} />
                        </>
                    ) : null}
                </Column>
            </Row>
        )
    },
)
