import {Accordion, Form, useAnalyticsEventTracker, useTheme} from 'nf-ui'
import React, {FC, useCallback, useMemo} from 'react'
import styled from 'styled-components'
import {ArrayParam, useQueryParam} from 'use-query-params'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {FilterChildCategory, useFilterCategory} from './useFilterCategory'

const NegativeMargin = styled.div`
    margin-left: -16px;
`

export const FilterSidebar: FC = () => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const [categories, setCategories] = useQueryParam('categories', ArrayParam)
    const orgIdStr = useOrganisationIdStr()
    const {homeItems} = useFilterCategory(orgIdStr)
    const [, setSearch] = useQueryParam<string>('search')

    const hasCategory = useCallback((categoryId: string) => categories?.includes(categoryId) ?? false, [categories])
    const onCategorySelect = useCallback(
        (categoryId: string) => {
            if (hasCategory(categoryId)) {
                setCategories(categories!.filter((category) => category !== categoryId))
            } else {
                setCategories(categories ? [...categories, categoryId] : [categoryId])
            }
        },
        [categories, setCategories, hasCategory],
    )

    const themeContext = useTheme()

    const mapCategoryChildren = useCallback(
        (categories: FilterChildCategory[]) => {
            return categories
                .filter((childCat) => childCat.profilesCount > 0)
                .map((childCat) => ({
                    id: childCat.idStr,
                    label: (
                        <Form.Group name={childCat.idStr}>
                            <Form.Checkbox
                                checked={hasCategory(childCat.idStr)}
                                onCheck={() => {
                                    trackAnalyticsEvent('select_categories', {
                                        label: childCat.label,
                                        selected: !hasCategory(childCat.idStr),
                                    })
                                    onCategorySelect(childCat.idStr)
                                    setSearch('')
                                }}
                                label={childCat.label}
                                color={themeContext.primary.color}
                            />
                        </Form.Group>
                    ),
                    hoverLabel: childCat.profilesCount,
                    data: {parent: false},
                }))
        },
        [hasCategory, onCategorySelect, themeContext, trackAnalyticsEvent, setSearch],
    )

    const accordionData = useMemo(() => {
        if (!homeItems) return []

        return homeItems.map((homeItem) => ({
            id: homeItem.sourceIdStr!,
            label: `${homeItem.label}`,
            data: {parent: true},
            children: mapCategoryChildren(homeItem.childCategories || []),
        }))
    }, [mapCategoryChildren, homeItems])

    const initialExpanded = useMemo(() => {
        if (!categories || !categories.length || !accordionData.length) return []
        return accordionData.filter((ac) => ac.children.some((c) => categories.includes(c.id))).map((ac) => ac.id)
        // This only matters on the first valid render and that's when we have accordianData
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accordionData])

    if (!homeItems) return null

    return (
        <Accordion
            data={accordionData}
            initialExpanded={initialExpanded}
            wrapper={({data, children}) => {
                if (data && data.parent) return <>{children}</>
                return <NegativeMargin>{children}</NegativeMargin>
            }}
        />
    )
}
