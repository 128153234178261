import {gql, useMutation} from '@apollo/client'
import pluralize from 'pluralize'
import {useCallback} from 'react'
import {useAlert} from 'react-alert'
import {RevokeUsers, RevokeUsersVariables} from './__types__/RevokeUsers'
import {updateUserRolesCache} from './useRoleMutation'
import {UserRole} from '~/globalTypes'

export const USER_REVOKE_DATA = gql`
    fragment UserRevokeData on UserObject {
        idStr
        isRevoked
    }
`

const REVOKE_USER_MUTATION = gql`
    mutation RevokeUsers($organisationIdStr: String!, $userIdStrs: [String!]!, $unrevoke: Boolean) {
        revokeUsers(organisationIdStr: $organisationIdStr, userIdStrs: $userIdStrs, unrevoke: $unrevoke) {
            ...UserRevokeData
        }
    }
    ${USER_REVOKE_DATA}
`

export function useRevokeMutations() {
    const {success, error} = useAlert()

    const [revokeUsersMutation, revokeUsersStatus] = useMutation<RevokeUsers, RevokeUsersVariables>(
        REVOKE_USER_MUTATION,
        {
            onCompleted: ({revokeUsers}) =>
                success(
                    `${revokeUsers.length} ${pluralize('user', revokeUsers.length)} successfully ${
                        revokeUsers[0].isRevoked ? 'revoked' : 'unrevoked'
                    }`,
                ),
            onError: () => error('Failed to change revoke status'),
        },
    )

    const revokeUsers = useCallback(
        async (variables: RevokeUsersVariables) => {
            await revokeUsersMutation({
                variables,
                optimisticResponse: (vars) => {
                    const {userIdStrs, unrevoke} = vars as RevokeUsersVariables
                    return {
                        revokeUsers: userIdStrs.map((idStr) => ({
                            __typename: 'UserObject',
                            idStr,
                            isRevoked: !unrevoke,
                        })),
                    }
                },
                update: updateUserRolesCache(variables.organisationIdStr, variables.userIdStrs, (oldRole) =>
                    oldRole === UserRole.AdminUser ? UserRole.AdminGuest : UserRole.Guest,
                ),
            })
        },
        [revokeUsersMutation],
    )

    return {
        revokeUsers,
        revokeUsersStatus,
    }
}
