import React, {useContext, useMemo, useState} from 'react'

const ProfileGroupContext = React.createContext<{
    profileIdStrs: string[]
    setProfileIdStrs: (profileIdStrs: string[]) => void
} | null>(null)

export const useProfileGroupContext = () => {
    const profileGroupContext = useContext(ProfileGroupContext)
    if (!profileGroupContext) throw new Error('Cannot use profile group context outside of the provider')

    return profileGroupContext
}

export const ProfileGroupProvider: React.FC = ({children}) => {
    const [profileIdStrs, setProfileIdStrs] = useState<string[]>([])

    const contextValue = useMemo(
        () => ({
            profileIdStrs,
            setProfileIdStrs,
        }),
        [profileIdStrs, setProfileIdStrs],
    )

    return <ProfileGroupContext.Provider value={contextValue}>{children}</ProfileGroupContext.Provider>
}
