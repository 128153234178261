import * as React from 'react'
const SvgOwner = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgOwner(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M8 .3c2.928 0 5.3 2.372 5.3 5.3 0 1.74-.845 3.34-2.235 4.328l-.41.292.451.222.202.105c1.659.914 2.792 2.78 2.792 4.653a.5.5 0 11-1 0c0-1.995-1.588-3.913-3.495-4.192-.49-.071-1.007-.108-1.605-.108-.6 0-1.118.037-1.596.108C4.496 11.287 2.9 13.206 2.9 15.2a.5.5 0 11-1 0c0-1.95 1.227-3.889 3.003-4.758l.455-.222-.414-.292-.196-.145A5.299 5.299 0 018 .3zm0 1a4.301 4.301 0 000 8.6 4.301 4.301 0 000-8.6z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgOwner
