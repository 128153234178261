import {gql, useQuery} from '@apollo/client'
import {useEffect, useMemo, useRef} from 'react'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {today} from '~/util/date'
import {MeetingsThisWeek, MeetingsThisWeekVariables} from './__types__/MeetingsThisWeek'

export const MEETINGS_THIS_WEEK = gql`
    query MeetingsThisWeek($organisationIdStr: String!) {
        meetingsThisWeek(organisationIdStr: $organisationIdStr) {
            idStr
            name
            startTime
            endTime
            allDay
            meetingLink
            attendees {
                idStr
                firstName
                lastName
                email
                photoUrl
                guest
                profileIdStr
                title
                company
                location
                bio
                linkedIn
            }
        }
    }
`

const todaysMeetings = (meetings: any[]) => {
    return meetings.filter((meeting) => {
        const endDate = new Date(meeting.endTime)
        const isToday = endDate.getDate() === today().getDate()

        if (meeting.allDay) {
            return isToday
        }

        return isToday && endDate.getTime() > today().getTime()
    })
}

export const useUpcomingMeetings = () => {
    const hasPolledForChanges = useRef(false)
    const organisationIdStr = useOrganisationIdStr()

    const {data, loading, error, startPolling, stopPolling} = useQuery<MeetingsThisWeek, MeetingsThisWeekVariables>(
        MEETINGS_THIS_WEEK,
        {
            variables: {organisationIdStr},
            fetchPolicy: 'cache-and-network',
            pollInterval: 0,
        },
    )

    const initiatePoll = () => {
        if (hasPolledForChanges.current) return
        startPolling(40000)
        setTimeout(() => {
            if (!hasPolledForChanges.current) {
                stopPolling()
                hasPolledForChanges.current = true
            }
        }, 60000)
    }

    useEffect(initiatePoll, [startPolling, stopPolling])

    const meetings = useMemo(() => data?.meetingsThisWeek || [], [data])
    const meetingsToday = useMemo(() => todaysMeetings(meetings), [meetings])

    return {
        loading: loading && !data,
        error,
        meetingsThisWeek: meetings,
        meetingsToday,
    }
}
