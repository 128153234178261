import {Button, colors, Spacer, Typography} from 'nf-ui'
import React, {useState} from 'react'
import styled from 'styled-components'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'

const BORDER_WIDTH = 4

const PageBorder = styled.div`
    position: fixed;
    width: 100vw;
    left: 0;
    top: 0;
    height: 100vh;
    pointer-events: none;
    z-index: 15;
    border: ${BORDER_WIDTH}px solid ${colors.green[100]};
    box-sizing: border-box;
`

const ScenarioDescription = styled.div`
    display: inline-flex;
    align-items: center;
    background: ${colors.green[100]};
    top: -${BORDER_WIDTH}px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    padding: 15px 16px;
    border-radius: 3px;
    pointer-events: all;
`

export const ScenarioIndicator = () => {
    const [visible, setVisible] = useState(true)
    const {currentOrganisation} = useCurrentOrganisation()

    if (!visible || !currentOrganisation?.scenario) return null

    return (
        <PageBorder>
            <ScenarioDescription>
                <Typography.Paragraph bottomMargin={false}>
                    Scenario: {currentOrganisation.name} is only accessible by Admins.
                </Typography.Paragraph>
                <Spacer width={8} />
                <Button variant="link" onClick={() => setVisible(false)} color="black">
                    Dismiss
                </Button>
            </ScenarioDescription>
        </PageBorder>
    )
}
