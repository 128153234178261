import {useCallback, useMemo} from 'react'
import {Profile} from './ProfileBrick'
import {DetailConfig} from './useDetailConfig'
import {useDisplayOptions} from './useDisplayOptions'

export const useProfileDetails = (profile: Profile) => {
    const {detailConfig, firstNameField} = useDisplayOptions()
    const firstDetail = detailConfig?.find((config) => config.visible)
    const title = profile.profileLines.find((line) => line.label === 'Title')?.value || null
    const titleAsHeading = profile.openPosition && title

    const getValueFromDetail = useCallback(
        (detail?: DetailConfig) => {
            if (detail?.fieldIdStr === firstNameField?.idStr) return profile.fullName
            return profile.profileLines.find((line) => line.extraData.fieldIdStr === detail?.fieldIdStr)?.value || ''
        },
        [firstNameField, profile.fullName, profile.profileLines],
    )

    const heading = titleAsHeading ? title : getValueFromDetail(firstDetail)

    const lineFromDetailConfig = useMemo(
        () =>
            detailConfig
                ?.filter((config, index) => config.visible && index !== 0)
                .map((config) => getValueFromDetail(config))
                .filter((value) => value)
                .join(' | '),
        [detailConfig, getValueFromDetail],
    )

    const lineFromProfileLines = useMemo(() => {
        if (!titleAsHeading) return ''

        const profileLineValues = profile.profileLines
            ?.filter((line) => line.label !== 'Title')
            .map((line) => line.value)
            .filter((value) => value)

        profileLineValues.unshift(profile.fullName)

        return profileLineValues.join(' | ')
    }, [profile.fullName, profile.profileLines, titleAsHeading])

    return {
        heading,
        secondaryLine: titleAsHeading ? lineFromProfileLines : lineFromDetailConfig,
    }
}
