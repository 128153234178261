import React, {FC, InputHTMLAttributes} from 'react'
import styled, {css} from 'styled-components'
import SvgCheck from '../../Icons/Check'
import {blendWhite, colors} from '../../theme'
import {useGroupContext} from '../Group'

export type CheckVariants = 'circle' | 'square'

export type InputStyleProps = {
    hover?: boolean
    checked?: boolean
    color?: string
    disabled?: boolean
}

export type InputProps = InputHTMLAttributes<HTMLInputElement> & Omit<InputStyleProps, 'checked'>

const Icon = styled(SvgCheck)`
    path {
        fill: ${colors.white};
    }
`

const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: -2px;
    left: -2px;
    opacity: 0;
    margin: 0;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const StyledCheckbox = styled.div<InputStyleProps>`
    position: relative;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    background: ${(props) => (props.checked ? props.color : colors.white)};
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
    transition-property: background-color, border-color, box-shadow;
    overflow: hidden;
    border-color: ${(props) => (props.checked || (props.hover && !props.disabled) ? props.color : colors.darkGray)};

    ${Icon} {
        transition: 0.25s opacity ease-in-out;
        opacity: ${(props) => (props.checked ? 1 : 0)};
    }

    ${(props) =>
        props.checked
            ? css`
                  :focus-within {
                      box-shadow: 0 0 0 3px ${blendWhite(props.color!, 0.35)};
                  }
              `
            : css`
                  :focus-within,
                  :hover {
                      border-color: ${props.color};
                  }
              `}

    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.4;

            :hover,
            :focus-within {
                border-color: ${colors.darkGray};
            }
        `}
`

export type CheckBoxIconProps = Pick<InputStyleProps, 'color'>

export const CheckBoxIcon: FC<CheckBoxIconProps> = ({color = colors.green[100]}) => {
    return (
        <StyledCheckbox checked color={color}>
            <Icon />
        </StyledCheckbox>
    )
}

export const Checkbox = React.forwardRef<HTMLInputElement, InputProps>(
    ({checked, type, hover, color, disabled, ...rest}, ref) => {
        const {name} = useGroupContext()
        return (
            <StyledCheckbox checked={checked} hover={hover} color={color} disabled={disabled}>
                <Icon />
                <HiddenCheckbox ref={ref} name={name} checked={checked} disabled={disabled} readOnly {...rest} />
            </StyledCheckbox>
        )
    },
)
