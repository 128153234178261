import {gql, useMutation} from '@apollo/client'
import {FadePresence, Spacer, Typography} from 'nf-ui'
import React, {FC, useEffect} from 'react'
import {useAlert} from 'react-alert'
import {BackButton} from '~/components/BackButton'
import {PageLoading} from '~/components/PageLoading'
import {getGraphQLErrorMessage} from '~/util'
import {SendMagicLinkEmail, SendMagicLinkEmailVariables} from './__types__/SendMagicLinkEmail'

const SEND_MAGICLINK_MUTATION = gql`
    mutation SendMagicLinkEmail($email: String!) {
        sendMagicLinkEmail(email: $email)
    }
`

export type SignInMagicLinkProps = {
    email: string
    onClickBack: () => void
}

export const SignInMagicLink: FC<SignInMagicLinkProps> = ({email, onClickBack}) => {
    const alert = useAlert()
    const [signInMutation, {loading}] = useMutation<SendMagicLinkEmail, SendMagicLinkEmailVariables>(
        SEND_MAGICLINK_MUTATION,
        {
            variables: {email},
            onError: (error) => alert.error(getGraphQLErrorMessage(error)),
        },
    )

    useEffect(() => {
        signInMutation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <FadePresence exitBeforeEnter itemKey={loading ? 'loading' : 'magic'}>
            {loading ? (
                <PageLoading />
            ) : (
                <>
                    <Typography.Heading>
                        <BackButton onClick={onClickBack} />
                        Check your inbox
                    </Typography.Heading>
                    <Spacer height={16} />
                    <Typography.Subheading maxWidth={590}>
                        To sign in, use the link we sent to {email}.
                        <br />
                        Links expire after one use or 24 hours.
                    </Typography.Subheading>
                </>
            )}
        </FadePresence>
    )
}
