import {Button, ButtonProps, Card, CardProps, colors, Icon, IconType, TooltipTarget, Typography} from 'nf-ui'
import React, {HTMLAttributes, ReactNode} from 'react'
import styled from 'styled-components'

const TooltipWrapper: React.FC<{showToolTip: boolean}> = ({children, showToolTip}) => {
    if (!showToolTip) return <>{children}</>

    return (
        <TooltipTarget position="top" title="Only one data source can be connected a time" maxWidth={160}>
            <div>{children}</div>
        </TooltipTarget>
    )
}

const CardTopConent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
`

const Logo = styled.img`
    width: 40px;
    height: 40px;
    display: block;
`

const CardTextConent = styled.div`
    text-align: left;

    > ${Typography.Paragraph} {
        margin: 0;
        margin-top: 4px;
    }
`

type IntegrationCardProps = CardProps & {
    logo?: string
    icon?: IconType
    label: string
    text: string
    onClick?: HTMLAttributes<HTMLDivElement>['onClick']
    topRightContent?: ReactNode
    buttonProps?: ButtonProps
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({
    logo,
    label,
    icon,
    text,
    disabled,
    topRightContent: topRightContentProp,
    buttonProps = {},
    ...cardProps
}) => {
    const topRightContent = topRightContentProp || (
        <Button variant="secondary" tabIndex={-1} {...buttonProps}>
            Add
        </Button>
    )

    return (
        <TooltipWrapper showToolTip={Boolean(disabled)}>
            <Card disabled={disabled} {...cardProps} data-testid={label}>
                <CardTopConent>
                    {logo && <Logo src={logo} />}
                    {icon && <Icon icon={icon} size={24} tint={colors.primary[100]} />}
                    <div style={{display: 'flex', alignItems: 'center'}}>{topRightContent}</div>
                </CardTopConent>
                <CardTextConent>
                    <Typography.Label>{label}</Typography.Label>
                    <Typography.Paragraph>{text}</Typography.Paragraph>
                </CardTextConent>
            </Card>
        </TooltipWrapper>
    )
}

export {IntegrationCard}
