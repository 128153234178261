import React from 'react'
import styled from 'styled-components'
import GreenCheck from './GreenCheck.svg'

export interface PhotoProps {
    photo: {thumbUrl: string}
    check?: boolean
}

const Container = styled.div`
    border: 0;
    border-radius: 3px;
    padding: 0;
    outline: 0;
    width: 32px;
    height: 32px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
`

const GreenCheckComponent = styled(GreenCheck)`
    position: absolute;
    top: -8px;
    right: -8px;
`

export const ProfileListPhoto: React.FC<PhotoProps> = ({photo, check = true}) => {
    return (
        <Container className="ProfileList_Fade" style={{backgroundImage: `url('${photo.thumbUrl}')`}}>
            {check && <GreenCheckComponent />}
        </Container>
    )
}
