import {useCallback, useState} from 'react'
import {useOnFilterChange} from './useOnFilterChange'

const STORAGE_KEY = 'ORG_CHART_COLLAPSED_PROFILES'

const getProfilesList = () => {
    const config = localStorage.getItem(STORAGE_KEY)
    if (config) return JSON.parse(config)
    return {}
}

const persistProfileList = (newList: string[], organisationIdStr: string) => {
    const config = getProfilesList()
    config[organisationIdStr] = newList
    localStorage.setItem(STORAGE_KEY, JSON.stringify(config))
}

export const useCollapsedProfiles = (organisationIdStr: string) => {
    const savedProfilesList = getProfilesList()[organisationIdStr]
    const [collapsedProfiles, setCollapsedProfiles] = useState<string[]>(savedProfilesList || [])

    const isCollapsed = (profileIdStr: string) => collapsedProfiles.includes(profileIdStr)

    const toggleCollapsedProfile = (profileIdStr: string) => {
        const newList = isCollapsed(profileIdStr)
            ? collapsedProfiles.filter((idStr) => idStr !== profileIdStr)
            : [...collapsedProfiles, profileIdStr]

        setCollapsedProfiles(newList)
        persistProfileList(newList, organisationIdStr)
    }

    const resetCollapsedState = useCallback(() => {
        if (!collapsedProfiles.length) return

        setCollapsedProfiles([])
        persistProfileList([], organisationIdStr)
    }, [collapsedProfiles.length, organisationIdStr])

    useOnFilterChange(resetCollapsedState)

    return {toggleCollapsedProfile, isCollapsed, resetCollapsedState}
}
