import React, {RefAttributes, ForwardRefExoticComponent, FormHTMLAttributes} from 'react'
import styled, {css} from 'styled-components'
import {Input} from './Input'
import {Group, GroupContainer} from './Group'
import {Label} from './Label'
import {Checkbox} from './Checkbox'
import {ColorPicker} from './ColorPicker'
import {SearchInput} from './SearchInput'
import {Switch} from './Switch'
import {MOBILE_MEDIA_QUERY} from '../media'

interface FormType
    extends ForwardRefExoticComponent<
        FormHTMLAttributes<HTMLFormElement> & RefAttributes<HTMLFormElement> & {width?: number}
    > {
    Input: typeof Input
    Group: typeof Group
    Label: typeof Label
    Checkbox: typeof Checkbox
    ColorPicker: typeof ColorPicker
    SearchInput: typeof SearchInput
    Switch: typeof Switch
}

export const formSpacing = css`
    ${GroupContainer} {
        margin-bottom: 24px;
    }
`

export const FormContainer = styled.form<{width?: number}>`
    width: ${(props) => `${props.width || 300}px`};
    ${MOBILE_MEDIA_QUERY} {
        width: 100%;
    }

    ${formSpacing}
`

const Form = React.forwardRef<HTMLFormElement, FormHTMLAttributes<HTMLFormElement> & {width?: number}>(
    ({children, width, ...rest}, ref) => {
        return (
            <FormContainer ref={ref} width={width} {...rest}>
                {children}
            </FormContainer>
        )
    },
) as FormType

Form.Input = Input
Form.Group = Group
Form.Label = Label
Form.ColorPicker = ColorPicker
Form.Checkbox = Checkbox
Form.SearchInput = SearchInput
Form.Switch = Switch

export {Form}
