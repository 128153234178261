import {from} from 'fromfrom'
import {Accordion, AccordionData, colors, Typography, useAnalyticsEventTracker} from 'nf-ui'
import React, {FC} from 'react'
import styled from 'styled-components'
import {useQueryListCategories} from '~/apollo/queries/ListCategories'
import {useCurrentUser} from '~/components/useCurrentUser'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {useRelativeRoute} from '~/components/useRelativeRoute'
import {HomeItemType} from '~/globalTypes'
import {useShowMeetings} from './Meetings/useShowMeetings'

const MeetingsLabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const NewMeetingsIndicator = styled.span`
    height: 24px;
    width: 40px;
    background: ${({theme}) => theme.primary.color};
    display: flex;
    justify-content: center;
    border-radius: 2px;
    align-items: center;
    color: ${colors.white};
`

const MeetingsLabel = () => {
    const {me} = useCurrentUser()

    if (me?.calendarConnected) {
        return <span>Meetings</span>
    }

    return (
        <MeetingsLabelContainer>
            Meetings
            <NewMeetingsIndicator>
                <Typography.Meta style={{fontWeight: 400}}>NEW</Typography.Meta>
            </NewMeetingsIndicator>
        </MeetingsLabelContainer>
    )
}

export const BrowseSidebar: FC = () => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const orgIdStr = useOrganisationIdStr()
    const {pushRelative, relativePath} = useRelativeRoute()

    const {showMeetings} = useShowMeetings()

    const [{data, error}] = useQueryListCategories(orgIdStr)

    if (error) return <Typography.Heading>Error: {error}</Typography.Heading>
    if (!data) return null

    const meetingsRoute = showMeetings ? [{id: '/meetings', label: <MeetingsLabel />}] : []
    const fixedRoutes: AccordionData[] = [{id: '/', label: 'Home'}, ...meetingsRoute]

    const homeItemRoutes: AccordionData[] = from(data.organisation.homeItems)
        .sortBy((item) => item.priority)
        .map((item) => {
            if (item.type === HomeItemType.ALL) return {id: '/all', label: item.label}
            if (item.type === HomeItemType.CHILD_CATEGORY)
                return {id: `/home/childCategory/${item.sourceIdStr}`, label: item.label}
            return {
                id: `/category/${item.sourceIdStr}`,
                label: `${item.label}`,
                children: from(item.childCategories!)
                    .filter((c) => c.profilesCount > 0)
                    .sortBy((c) => c.priority)
                    .map((childCategory) => ({
                        id: `/childCategory/${childCategory.idStr}`,
                        label: childCategory.label,
                        hoverLabel: childCategory.profilesCount + '',
                    }))
                    .toArray(),
            }
        })
        .toArray()

    const links = [...fixedRoutes, ...homeItemRoutes]

    return (
        <Accordion
            data={links}
            selectedKey={relativePath}
            onChange={(selected) => {
                if (typeof selected === 'string') {
                    selected === '/all' && trackAnalyticsEvent('select_all_people')
                    selected === '/' && trackAnalyticsEvent('open_home')
                    pushRelative(selected)
                }
            }}
        />
    )
}
