import {gql, useMutation} from '@apollo/client'
import {useAlert} from 'react-alert'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {SCENARIOS_PAGE_DATA} from '../Scenarios'
import {DeleteScenario, DeleteScenarioVariables} from './__types__/DeleteScenario'

const DELETE_SCENARIO = gql`
    mutation DeleteScenario($scenarioIdStr: String!) {
        deleteScenario(scenarioIdStr: $scenarioIdStr)
    }
`

export const useDeleteScenario = () => {
    const alert = useAlert()
    const organisationIdStr = useOrganisationIdStr()

    const [deleteScenarioMutation, {loading}] = useMutation<DeleteScenario, DeleteScenarioVariables>(DELETE_SCENARIO, {
        onCompleted: () => {
            alert.success('Scenario successfully deleted')
        },
        refetchQueries: [{query: SCENARIOS_PAGE_DATA, variables: {organisationIdStr}}],
        awaitRefetchQueries: true,
    })

    const deleteScenario = (scenarioIdStr: string) => {
        deleteScenarioMutation({variables: {scenarioIdStr}})
    }

    return {deleteScenario, deleteScenarioLoading: loading}
}
