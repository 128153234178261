import {ApolloClient, gql} from '@apollo/client'
import {OAuthProvider} from '~/globalTypes'
import {FetchOAuthUrl, FetchOAuthUrlVariables} from './__types__/FetchOAuthUrl'

export const FETCH_OAUTH_URL = gql`
    query FetchOAuthUrl($oAuthProvider: OAuthProvider!) {
        fetchOAuthUrl(oAuthProvider: $oAuthProvider)
    }
`

export const getOAuthUrl = async (client: ApolloClient<object>, oAuthProvider: OAuthProvider) => {
    const oAuthUrlData = await client.query<FetchOAuthUrl, FetchOAuthUrlVariables>({
        query: FETCH_OAUTH_URL,
        variables: {oAuthProvider},
    })

    return oAuthUrlData.data.fetchOAuthUrl
}
