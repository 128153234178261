import {gql, useMutation} from '@apollo/client'
import {useContext} from 'react'
import {useQueryParam} from 'use-query-params'
import {AnalyticsContext} from '~/AnalyticsContext'

const TRACK_ANALYTICS = gql`
    mutation TrackAnalytics($userIdStr: String!, $event: String!, $attributes: UserEventAttributes!) {
        analyticsEvent(userIdStr: $userIdStr, event: $event, attributes: $attributes)
    }
`

export const useOpenAnalytics = () => {
    const {platform} = useContext(AnalyticsContext)
    const [userIdStr] = useQueryParam<string>('userId')
    const [analyticsEvent] = useMutation(TRACK_ANALYTICS)

    const trackEvent = async (event: string, name?: string) => {
        if (!userIdStr) return

        return analyticsEvent({
            variables: {
                userIdStr,
                event,
                attributes: {
                    device_type: platform.toLowerCase() || 'web',
                    ...(name ? {name} : {}),
                },
            },
        })
    }

    return {trackEvent}
}
