import {colors, Typography} from 'nf-ui'
import React from 'react'
import styled from 'styled-components'

const PreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const HeaderCell = styled.div`
    display: flex;
    align-items: center;
    background-color: ${colors.lightGray};
    border: 1px solid ${colors.darkGray};
    border-radius: 3px 3px 0px 0px;
    width: 180px;
`

const CellText = styled(Typography.Paragraph)<{$borderBottom?: boolean}>`
    position: relative;
    padding: 8px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: ${(props) => (props.$borderBottom ? `1px solid ${colors.darkGray}` : undefined)};
`

const FadedCellText = styled(CellText)`
    border-bottom: 0px;
    :before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0px;
        z-index: 6;
        background: linear-gradient(rgba(0, 0, 0, 0), #fff);
    }
`

const ValuesContainer = styled.div`
    position: relative;
    border: 1px solid ${colors.darkGray};
    border-top: 0px;
    border-radius: 0px 0px 3px 3px;
    width: 180px;
`

export const ColumnPreview = ({heading, values}: {heading: string; values: string[]}) => {
    return (
        <PreviewContainer>
            <HeaderCell>
                <CellText bottomMargin={false}>{heading}</CellText>
            </HeaderCell>
            <ValuesContainer>
                <CellText bottomMargin={false} $borderBottom={true}>
                    {values[0]}
                </CellText>
                {values[1] && <FadedCellText bottomMargin={false}>{values[1]}</FadedCellText>}
            </ValuesContainer>
        </PreviewContainer>
    )
}
