import {colors, Spacer, Typography} from 'nf-ui'
import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {useElementSize} from '~/components/ProfilesGridLayout/useElementSize'
import {FullOrgChartData_organisation_profiles} from '../__types__/FullOrgChartData'
import {Collapsible, useCollapsibleState} from './Collapsible'
import {CollapsibleControls} from './CollapsibleControls'
import {getOtherManagersNames} from './getOtherManagersNames'
import {
    DIRECT_LINE_OFFSET,
    GUTTER,
    INDENT,
    LINE_WIDTH,
    MIN_PROFILES_FOR_BRACKET,
    PROFILE_ROW_HEIGHT,
    PROFILE_ROW_PADDING,
} from './ProfileBrick/constants'
import {ProfileChildren} from './ProfileBrick/ProfileChildren'
import {ProfileThumbnail, PROFILE_PICTURE} from './ProfileBrick/ProfileThumbnail'
import {useHighlightProfile} from './ProfileBrick/useHighlightProfile'
import {useProfilesOnScreen} from './ProfileBrick/useProfilesOnScreen'
import {BrickVerticalLines, RowVerticalLine} from './ProfileBrick/VerticalLines'
import './ProfileRow.css'
import {ProfileRowAction} from './ProfileRowAction'
import {useDisplayOptions} from './useDisplayOptions'
import {useProfileDetails} from './useProfileDetails'

export type Profile = FullOrgChartData_organisation_profiles

const ProfileBrickWrapper = styled.div`
    position: relative;
`

const LeftContent = styled.div`
    display: flex;
    position: relative;
    overflow: hidden;
    flex: 1;
`

const ProfileContent = styled.div`
    position: absolute;
    left: ${PROFILE_ROW_PADDING + PROFILE_PICTURE}px;
    display: flex;
    height: ${PROFILE_PICTURE}px;
    flex-direction: column;
    justify-content: space-between;
`

const ProfileRowBackdrop = styled.div<{openPosition?: boolean}>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: repeating-linear-gradient(-45deg, #e6e6e6, #e6e6e6 1px, transparent 1px, transparent 4px);
`

const ProfileRowContainer = styled.div<{additional: boolean; openPosition?: boolean}>`
    position: relative;
    border: ${({additional}) => (additional ? `1px dashed #A0A6AA` : `1px solid ${colors.darkGray}`)};
    padding: ${PROFILE_ROW_PADDING}px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
    cursor: pointer;
    background-color: ${({openPosition}) => (openPosition ? colors.white : colors.lightGray)};

    :hover {
        background-color: ${({openPosition}) => (openPosition ? colors.lightGray : '#edeff0')};
    }
`

const HorizontalLine = styled.div.attrs({className: 'org-chart-line horizonal-line'})<{additional: boolean}>`
    position: absolute;
    width: ${INDENT - DIRECT_LINE_OFFSET}px;
    top: ${PROFILE_ROW_HEIGHT / 2}px;
    left: -${INDENT - DIRECT_LINE_OFFSET}px;
    border-top: ${LINE_WIDTH}px;
    border-top-style: ${({additional}) => (additional ? 'dashed' : 'solid')};
    border-top-color: ${({additional}) => (additional ? '#A0A6AA' : 'colors.black')};
    transition: border-color 0.3s ease-in-out;
    z-index: -2;
`

const ProfileName = styled(Typography.Label)`
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    max-width: 100%;
    line-height: 16px;
`

const SecondaryLine = styled(Typography.Tiny)`
    line-height: 15px;
    overflow-x: hidden;
    overflow-y: visible;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    max-width: 100%;
    display: flex;
`

const AdditionalManagersContainer = styled.div`
    display: flex;
    flex-direction: row;
    z-index: 1;
`

const ManagersTextContainer = styled.div`
    display: flex;
    background-color: white;
    border: 0.5px solid ${colors.darkGray};
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    padding: 0 ${PROFILE_ROW_PADDING}px;
    padding-top: 1px;
    flex-direction: row;
    flex-wrap: nowrap;
`

const OtherManagersText = styled(Typography.Tiny)``

const OtherManagerNames = ({names}: {names: string[]}) => {
    return (
        <>
            <Typography.Tiny>Also reports to </Typography.Tiny>
            {names.map((manager, index) => {
                const nameSeparator = index === names.length - 1 ? 'and' : ','
                return (
                    <React.Fragment key={index}>
                        {index !== 0 && <Typography.Tiny>{nameSeparator} </Typography.Tiny>}
                        <OtherManagersText>&nbsp;{manager}&nbsp;</OtherManagersText>
                    </React.Fragment>
                )
            })}
        </>
    )
}

const shouldShowHorizontalLine = ({
    topLevel,
    grid,
    showLine,
}: {
    topLevel?: boolean
    grid?: number
    showLine?: boolean
}) => {
    if (topLevel) return false
    if (grid && grid < MIN_PROFILES_FOR_BRACKET && showLine) return true
    if (!grid && showLine) return true
    return false
}

export const ProfileRow: React.FC<{
    profile: Profile
    additional?: boolean
    grid?: number
    parentIdStr?: string
    rest?: React.HTMLAttributes<HTMLDivElement>
}> = React.memo(({profile, children, additional = false, grid = 0, parentIdStr = '', ...rest}) => {
    const [, setProfileId] = useQueryParam<string>('profileId')
    const {heading, secondaryLine} = useProfileDetails(profile)

    return (
        <ProfileRowContainer
            className="profile-brick"
            id={`${grid ? 'grid' : 'row'}-${parentIdStr}-${profile.idStr}`}
            additional={additional}
            openPosition={profile.openPosition}
            {...rest}
        >
            {profile.openPosition && <ProfileRowBackdrop openPosition={profile.openPosition} />}
            <LeftContent onClick={() => setProfileId(profile.idStr)}>
                <ProfileThumbnail profile={profile} />
                <div style={{width: `${PROFILE_ROW_PADDING + LINE_WIDTH}px`}} />
                <ProfileContent>
                    <ProfileName>{heading}</ProfileName>
                    <SecondaryLine>{secondaryLine}</SecondaryLine>
                </ProfileContent>
            </LeftContent>
            {children}
        </ProfileRowContainer>
    )
})

export const ProfileBrick = React.memo(
    ({
        profile,
        getProfileFromIdStr,
        topLevel = false,
        grid = 0,
        showLine = true,
        additional = false,
        parentIdStr = '',
        isFirstChild = false,
        topVerticalLine,
        bottomVerticalLine,
    }: {
        profile: Profile
        getProfileFromIdStr: (idStr: string) => Profile | null
        topLevel?: boolean
        grid?: number
        showLine?: boolean
        additional?: boolean
        isFirstChild?: boolean
        parentIdStr?: string
        topVerticalLine?: RowVerticalLine
        bottomVerticalLine?: RowVerticalLine
    }) => {
        const containerRef = useRef<HTMLDivElement>(null)
        const {height: containerHeight} = useElementSize(containerRef)
        const [hover, setHover] = useState(false)
        const childrenRef = useRef<HTMLDivElement>(null)
        const {expanded, toggleCollapsible} = useCollapsibleState(profile.idStr)
        const {highlighted, profilesFiltered} = useHighlightProfile(profile)

        const {isCollapsed} = useDisplayOptions()
        const profilesOnScreen = useProfilesOnScreen(isCollapsed)

        const reportsCount = profile.directReports.length + profile.additionalReports.length
        const hasReports = reportsCount > 0 && !additional

        const canShowBranch = !topLevel && hasReports && !additional
        const horizontalLine = shouldShowHorizontalLine({topLevel, grid, showLine})

        const otherManagersNames = getOtherManagersNames({
            profile,
            additionalReport: additional,
            parentIdStr,
            getProfileFromIdStr,
        })

        const rowHoverProps = {
            onMouseEnter: () => {
                setHover(true)
            },
            onMouseLeave: () => {
                setHover(false)
            },
        }

        const rowAtttributes = highlighted ? {'data-highlighted': 'true'} : {}

        const animateCollapse = profilesFiltered ? false : profilesOnScreen < 450

        return (
            <ProfileBrickWrapper ref={containerRef} id={`profile_${profile.idStr}`}>
                {parentIdStr && (
                    <BrickVerticalLines
                        containerHeight={containerHeight}
                        isFirstChild={isFirstChild}
                        grid={grid}
                        topLine={topVerticalLine}
                        bottomLine={bottomVerticalLine}
                    />
                )}
                {horizontalLine && <HorizontalLine additional={additional} />}

                <ProfileRow profile={profile} additional={additional} {...rowAtttributes} {...rowHoverProps}>
                    {otherManagersNames && (
                        <AdditionalManagersContainer>
                            <ManagersTextContainer>
                                <OtherManagerNames names={otherManagersNames}></OtherManagerNames>
                            </ManagersTextContainer>
                        </AdditionalManagersContainer>
                    )}
                    {hover && <ProfileRowAction profile={profile} canShowBranch={canShowBranch} />}
                    {hasReports && (
                        <CollapsibleControls
                            hover={hover}
                            expanded={expanded}
                            toggle={toggleCollapsible}
                            profile={profile}
                            getProfileFromIdStr={getProfileFromIdStr}
                            profileChildrenRef={childrenRef}
                        />
                    )}
                </ProfileRow>

                {!grid && <Spacer height={GUTTER} />}

                {hasReports && (
                    <div ref={childrenRef}>
                        <Collapsible expanded={expanded} animation={animateCollapse}>
                            <ProfileChildren profile={profile} getProfileFromIdStr={getProfileFromIdStr} />
                        </Collapsible>
                    </div>
                )}
            </ProfileBrickWrapper>
        )
    },
)
