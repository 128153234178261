import {gql, useMutation, useQuery} from '@apollo/client'
import {colors, Form, isHexColor, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import {ColorPicker} from 'nf-ui/Form/ColorPicker'
import React, {useEffect, useState} from 'react'
import {useAlert} from 'react-alert'
import {LogoFileInput} from '~/components/LogoFileInput'
import {PhonePreviewHome, PhonePreviewHomeFragment} from '~/components/PhonePreview/PhonePreviewHome'
import {SidePanelLayout} from '~/components/SidePanelLayout'
import {useMessages} from '~/components/useMessages'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {getGraphQLErrorMessage} from '~/util'
import {AdminlandUpdateAccentColor, AdminlandUpdateAccentColorVariables} from './__types__/AdminlandUpdateAccentColor'
import {AdminlandUpdateLogo, AdminlandUpdateLogoVariables} from './__types__/AdminlandUpdateLogo'
import {BrandingData, BrandingDataVariables} from './__types__/BrandingData'

export const BRANDING = gql`
    query BrandingData($organisationIdStr: String!) {
        organisation(idStr: $organisationIdStr) {
            idStr
            accentColor
            ...PhonePreviewHomeData
            logo {
                idStr
                file {
                    idStr
                    url
                }
            }
        }
    }
    ${PhonePreviewHomeFragment}
`

export const UPDATE_LOGO = gql`
    mutation AdminlandUpdateLogo($organisationIdStr: String!, $logo: Upload) {
        updateOrganisation(organisationIdStr: $organisationIdStr, logo: {fileInput: $logo, height: 0}) {
            idStr
            logo {
                idStr
                file {
                    idStr
                    url
                }
            }
        }
    }
`

export const UPDATE_ACCENT_COLOR = gql`
    mutation AdminlandUpdateAccentColor($organisationIdStr: String!, $accentColor: String) {
        updateOrganisation(organisationIdStr: $organisationIdStr, accentColor: $accentColor) {
            idStr
            accentColor
        }
    }
`

export const Branding: React.FC = () => {
    const alert = useAlert()
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const {onCompleted} = useMessages()
    const organisationIdStr = useOrganisationIdStr()
    const {data, error} = useQuery<BrandingData, BrandingDataVariables>(BRANDING, {
        variables: {organisationIdStr},
    })

    const currentAccentColor = data?.organisation.accentColor
    const currentLogo = data?.organisation.logo || null

    const [updateLogo] = useMutation<AdminlandUpdateLogo, AdminlandUpdateLogoVariables>(UPDATE_LOGO, {
        onCompleted,
    })

    const [updateAccentColor] = useMutation<AdminlandUpdateAccentColor, AdminlandUpdateAccentColorVariables>(
        UPDATE_ACCENT_COLOR,
        {
            onCompleted,
        },
    )

    const [color, setColor] = useState(data?.organisation.accentColor || colors.black)
    useEffect(() => {
        if (!currentAccentColor) return
        setColor(currentAccentColor)
    }, [currentAccentColor])

    const [logo, setLogo] = useState(currentLogo)
    useEffect(() => {
        if (!currentLogo) return
        setLogo(currentLogo)
    }, [currentLogo])

    const [uploadingLogo, setUploadingLogo] = useState(false)

    const uploadLogo = async (logo: File) => {
        try {
            setUploadingLogo(true)

            await updateLogo({
                variables: {
                    organisationIdStr,
                    logo,
                },
            })
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }

        setUploadingLogo(false)
    }

    const isColorValid = isHexColor(color)

    const updateColor = async () => {
        if (!isColorValid) return
        if (color === currentAccentColor) return

        trackAnalyticsEvent('change_accent_color')

        try {
            await updateAccentColor({
                variables: {
                    organisationIdStr,
                    accentColor: color,
                },
            })
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    if (error) {
        return <Typography.Heading data-testid="error">Error: {getGraphQLErrorMessage(error)}</Typography.Heading>
    }

    return (
        <SidePanelLayout
            leftContent={
                <PhonePreviewHome
                    accentColor={color}
                    logo={logo?.file.url}
                    homeItems={data?.organisation.homeItems || []}
                />
            }
        >
            <Typography.Heading>Branding</Typography.Heading>
            <Spacer height={24} />
            <Form.Group name="logo">
                <Form.Label>Upload logo</Form.Label>
                <Typography.Paragraph maxWidth={275} bottomMargin={false}>
                    We recommend uploading a transparent PNG at least 200px in width.
                </Typography.Paragraph>
                <Spacer height={8} />
                <LogoFileInput
                    loading={uploadingLogo}
                    logo={logo?.file.url || null}
                    onChange={(event) => {
                        if (!event.target.files) return
                        uploadLogo(event.target.files[0])
                    }}
                    onClick={() => trackAnalyticsEvent('select_upload_logo')}
                />
            </Form.Group>
            <Spacer height={32} />
            <Form.Group name="color">
                <Form.Label>Choose accent color</Form.Label>
                <ColorPicker
                    width={162}
                    value={color}
                    color={color}
                    onChange={setColor}
                    onBlur={updateColor}
                    onClosePicker={updateColor}
                    error={!isColorValid ? 'Please enter a valid hexidecimal color.' : undefined}
                />
            </Form.Group>
        </SidePanelLayout>
    )
}
