import {useField, useFormikContext} from 'formik'
import React, {FC} from 'react'
import {useGroupContext} from '../Group'
import {Input} from '../Input'

const numbersAndPlus = /^[0-9+]+$/

export const FormikMobileNumber: FC = () => {
    const {name} = useGroupContext()
    const [field, {error}] = useField(name)
    const {setFieldValue} = useFormikContext<Record<string, string>>()

    const handleChange = (name: string, value: string) => {
        if (value === '' || numbersAndPlus.test(value)) {
            setFieldValue(name, value)
        }
    }

    return (
        <Input
            name={name}
            error={error}
            onChange={(value) => handleChange(name, value)}
            onBlur={field.onBlur}
            value={field.value}
        ></Input>
    )
}
