import React, {PropsWithChildren, ReactNode, ReactText, useCallback, useRef, useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import styled, {css} from 'styled-components'
import {Button} from './Button/Button'
import {Checkbox} from './Form/Checkbox'
import {highlightMatchedText} from './highlightMatchedText'
import SvgChevronDown from './Icons/ChevronDown'
import SvgChevronUp from './Icons/ChevronUp'
import {
    colors,
    Form,
    Icon,
    IconType,
    labelFontStyle,
    List,
    ListProps,
    LIST_ROW_HEIGHT,
    paragraphFontStyle,
    Spacer,
} from './index'
import {Panel, PanelProps} from './Panel'
import {Multiselect} from './useMultiselect'
import {Sort} from './useSort'
import {warnOrThrow} from './util'

export type Column<TItem> = {
    name: string
    width: number
    value: (item: TItem) => ReactText
    sortValue?: (item: TItem) => ReactText
    highlight?: boolean
    path?: (item: TItem) => string
    linkType?: 'a' | 'Link'
    hoverLabel?: (item: TItem) => ReactNode
}

export type Columns<TItem> = Column<TItem>[]

type MultiColumnListProps<TItem> = {
    columns: Column<TItem>[]
    multiselect?: Multiselect<TItem>
    onHover?: (item: TItem | null) => void
    sort?: Sort<TItem>
    actions?: {
        visible?: (item: TItem) => boolean
        button: {
            icon: IconType
            iconPosition?: 'left' | 'right'
            label: string
            color?: string
        }
        panel?: (item: TItem, onClose: () => void) => React.ReactNode
        onClick?: (item: TItem) => void
    }
    /**
     * Whether the actions cell should stop click event propagation.
     * Use if your actions have an onClick.
     */
    actionsClickable?: boolean
    /**
     * If present will highlight values from columns where the highlight is valid
     *
     * @type {string}
     */
    highlightValue?: string
    disabledSelector?: (item: TItem) => boolean
} & Omit<ListProps<TItem>, 'renderRow'>

const ActionsContainerOuter = styled.div`
    position: sticky;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
`

const ActionsContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-left: 80px;
    background: linear-gradient(-90deg, rgb(244, 246, 247) 0%, rgba(244, 246, 247, 0) 100%);
    transition: 0.25s opacity ease-in-out;
    white-space: nowrap;
`

type ActionVisibility = 'hover' | 'hide' | 'show'

const RowContainer = styled.div<{actionVisibility?: ActionVisibility; disabled?: boolean; rowHeight?: number}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${({rowHeight}) => (rowHeight === undefined ? LIST_ROW_HEIGHT : rowHeight)}px;
    color: ${({disabled}) => (disabled ? colors.darkGray2 : undefined)};

    ${({actionVisibility}) => {
        switch (actionVisibility) {
            case 'hide':
                return css`
                    background-color: ${colors.white};
                    ${ActionsContainer} {
                        opacity: 0;
                    }
                `
            case 'show':
                return css`
                    background-color: ${colors.lightGray};
                    ${ActionsContainer} {
                        opacity: 1;
                    }
                `

            default:
                return css`
                    ${ActionsContainer} {
                        opacity: 0;
                    }
                    :hover {
                        ${ActionsContainer} {
                            opacity: 1;
                        }
                    }
                `
        }
    }}
`

const ColumnContainer = styled.div<{width?: number}>`
    width: ${({width}) => (width ? `${width}px` : 'auto')};
    padding-left: 16px;
    display: flex;
    align-items: center;
    height: 100%;
    flex-shrink: 0;
    box-sizing: border-box;
`

const ColumnLabel = styled.div<{bold?: boolean}>`
    ${(props) => (props.bold ? labelFontStyle : paragraphFontStyle)}
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
`

const Link = styled(RouterLink)`
    color: ${colors.black};
`

type RowProps<TItem> = PropsWithChildren<{
    item: TItem
    onHover?: (item: TItem | null) => void
    multiselect?: Multiselect<TItem>
    columns: MultiColumnListProps<TItem>['columns']
    actionVisibility?: ActionVisibility
    panel: Pick<PanelProps, 'open' | 'onClose'>
    minWidth: number
    rowHeight?: number
}> &
    Pick<MultiColumnListProps<TItem>, 'highlightValue' | 'onClick' | 'actions' | 'disabledSelector'>

function Row<TItem>({
    columns,
    item,
    multiselect,
    highlightValue,
    onHover,
    onClick,
    actionVisibility,
    actions,
    panel,
    minWidth,
    disabledSelector,
    rowHeight,
}: RowProps<TItem>) {
    const targetRef = useRef<HTMLDivElement>(null)
    const [hovering, setHovering] = useState(false)

    const renderLabel = (column: Column<TItem>) => {
        let label: string | JSX.Element = column.value(item).toString()

        if (column.hoverLabel && hovering) return column.hoverLabel(item)

        if (column.highlight) label = highlightMatchedText(label, highlightValue ?? '')
        if (column.path && hovering) {
            if (column.linkType === 'a') {
                label = (
                    <a style={{color: 'inherit'}} href={column.path(item)}>
                        {label}
                    </a>
                )
            } else {
                label = <Link to={column.path(item)}>{label}</Link>
            }
        }

        return label
    }

    return (
        <>
            {actions?.panel && (
                <Panel {...panel} targetRef={targetRef} placement="bottom-end" boundariesElement="scrollParent">
                    {actions.panel(item, panel.onClose)}
                </Panel>
            )}
            <RowContainer
                onClick={() => onClick?.(item)}
                onMouseEnter={() => {
                    onHover?.(item)
                    setHovering(true)
                }}
                onMouseLeave={() => {
                    onHover?.(null)
                    setHovering(false)
                }}
                actionVisibility={actionVisibility}
                disabled={disabledSelector?.(item)}
                style={{minWidth}}
                rowHeight={rowHeight || LIST_ROW_HEIGHT}
            >
                {multiselect && (
                    <ColumnContainer onClick={(event) => event.stopPropagation()}>
                        <Form.Group name="Checkbox">
                            <Checkbox
                                checked={multiselect.isSelected(item)}
                                disabled={multiselect.isDisabled(item)}
                                color={colors.primary[100]}
                                onChange={() => multiselect.toggleSelected(item)}
                            />
                        </Form.Group>
                    </ColumnContainer>
                )}
                {columns.map((column, index) => {
                    return (
                        <ColumnContainer key={index} width={column.width}>
                            <ColumnLabel>{renderLabel(column)}</ColumnLabel>
                        </ColumnContainer>
                    )
                })}
                <div style={{flex: 1}} />
                {actions && (
                    <ActionsContainerOuter>
                        <ActionsContainer ref={targetRef}>
                            {actions.button && (
                                <Button
                                    color={actions.button.color}
                                    variant="tertiary"
                                    onClick={(e) => {
                                        if (actions.onClick) {
                                            e.stopPropagation()
                                            actions.onClick(item)
                                        }
                                    }}
                                >
                                    {actions.button.iconPosition !== 'right' && (
                                        <Button.Icon icon={actions.button.icon} />
                                    )}

                                    {actions.button.label}
                                    {actions.button.iconPosition === 'right' && (
                                        <Button.Icon icon={actions.button.icon} />
                                    )}
                                </Button>
                            )}
                        </ActionsContainer>
                    </ActionsContainerOuter>
                )}
            </RowContainer>
        </>
    )
}

type HeadingProps<TItem> = Pick<MultiColumnListProps<TItem>, 'multiselect' | 'columns' | 'sort'>

function Heading<TItem>({multiselect, columns, sort, rowHeight}: HeadingProps<TItem> & {rowHeight?: number}) {
    return (
        <RowContainer rowHeight={rowHeight} style={{userSelect: 'none', WebkitUserSelect: 'none'}}>
            {multiselect && (
                <ColumnContainer>
                    <Form.Group name="Checkbpox">
                        <Checkbox
                            color={colors.primary[100]}
                            checked={multiselect.allSelected}
                            onChange={multiselect.toggleSelectAll}
                        />
                    </Form.Group>
                </ColumnContainer>
            )}
            {columns.map((column, index) => {
                const sortable = sort?.isColumnSortable(column.name)

                return (
                    <ColumnContainer
                        key={index}
                        width={column.width}
                        style={{cursor: sortable ? 'pointer' : undefined}}
                        onClick={() => sort?.toggleColumn(column.name)}
                    >
                        <ColumnLabel bold>{column.name}</ColumnLabel>
                        {sort && sort.isColumnActive(column.name) && (
                            <>
                                <Spacer width={4} />
                                <Icon icon={sort.sortAscending ? SvgChevronDown : SvgChevronUp} />
                            </>
                        )}
                    </ColumnContainer>
                )
            })}
        </RowContainer>
    )
}

export function MultiColumnList<TItem>({
    rows,
    columns,
    multiselect,
    onHover,
    sort,
    highlightValue,
    disabledSelector,
    actions,
    onClick,
    width,
    ...listProps
}: MultiColumnListProps<TItem>) {
    const data = sort?.sortedRows || rows
    const [selected, setSelected] = useState<TItem | undefined>()
    const hasSelected = selected && rows.includes(selected)

    if (actions?.panel && onClick) {
        warnOrThrow('You cannot supply an `onClick` function if `actions.panel` is configured.')
    }

    const deselectItem = useCallback(() => {
        setSelected(undefined)
    }, [])

    const onRowClick = useCallback(
        (item: TItem) => {
            if (actions?.panel) {
                if (item === selected) {
                    deselectItem()
                } else {
                    setSelected(item)
                }
            } else {
                onClick?.(item)
            }
        },
        [actions, selected, onClick, deselectItem],
    )

    let columnWidths = columns.reduce((sum, column) => sum + column.width, 0)
    if (multiselect) columnWidths += 32

    return (
        <List
            {...listProps}
            maxHeight={listProps.maxHeight && listProps.maxHeight - LIST_ROW_HEIGHT}
            firstChildBorder
            rows={data}
            width={width}
            stickyHeading={
                <Heading multiselect={multiselect} columns={columns} sort={sort} rowHeight={listProps.rowHeight} />
            }
            itemMinWidth={columnWidths}
            renderRow={(item) => {
                const hideAction = actions?.visible?.(item) === false

                let actionVisibility: ActionVisibility | null = null
                if (hideAction) actionVisibility = 'hide'
                else if (!hasSelected) actionVisibility = 'hover'
                else actionVisibility = selected === item ? 'show' : 'hide'

                return (
                    <Row
                        minWidth={columnWidths}
                        rowHeight={listProps.rowHeight}
                        onClick={hideAction ? undefined : onRowClick}
                        item={item}
                        columns={columns}
                        multiselect={multiselect}
                        highlightValue={highlightValue}
                        onHover={onHover}
                        actionVisibility={actionVisibility}
                        disabledSelector={disabledSelector}
                        actions={actions}
                        panel={{
                            open: selected === item,
                            onClose: deselectItem,
                        }}
                    />
                )
            }}
            padding={false}
        />
    )
}
