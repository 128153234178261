import {AnimatePresence, motion} from 'framer-motion'
import {Button} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React from 'react'
import {Illustrations} from '~/components/Illustrations'
import {ModalLayout} from '~/components/ModalLayout'
import WeightLifterWoman from '../../../illustrations/weight_lifter_woman.png'
import {StepProps} from '../useConfigureDataSource'
import {ColumnHeadings} from './Data/ColumnHeadings'
import {MapRequiredFields} from './Data/MapRequiredFields'

const Data: React.FC<StepProps> = ({dispatch, state, onClickCancel}) => {
    const currentStepFieldName = state.currentColumn?.name
    const skippable = state.currentColumn && !state.currentColumn?.required
    const StepComponent = currentStepFieldName ? MapRequiredFields : ColumnHeadings
    const skipText = currentStepFieldName ? `I don't have a ${currentStepFieldName} column` : 'Skip this step'

    return (
        <>
            <ModalLayout.Body>
                <AnimatePresence exitBeforeEnter>
                    <motion.div
                        initial={{opacity: 0, y: 30}}
                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: {ease: [0.455, 0.03, 0.515, 0.955], duration: 0.3, delay: 0.45},
                        }}
                        exit={{opacity: 0, transition: {duration: 0.3}}}
                        key={currentStepFieldName}
                    >
                        <StepComponent dispatch={dispatch} state={state} onClickCancel={onClickCancel} />
                    </motion.div>
                </AnimatePresence>
            </ModalLayout.Body>
            <Illustrations LeftIllustration={WeightLifterWoman} footer />

            <ModalLayout.Footer>
                {skippable && (
                    <Button
                        variant="tertiary"
                        onClick={async () => {
                            dispatch({
                                type: 'NEXT_FIELD',
                            })
                        }}
                        onClickAnalyticsEvent="select_skip_step"
                    >
                        {skipText} <Button.Icon icon={SvgArrowRight} />
                    </Button>
                )}
            </ModalLayout.Footer>
        </>
    )
}

export {Data}
