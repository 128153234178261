import {gql, useQuery} from '@apollo/client'
import {Typography} from 'nf-ui'
import pluralize from 'pluralize'
import React, {FC, useMemo} from 'react'
import {ArrayParam, useQueryParam} from 'use-query-params'
import {PageLoading} from '~/components/PageLoading'
import {ProfilesGridLayout} from '~/components/ProfilesGridLayout/ProfilesGridLayout'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {getGraphQLErrorMessage} from '~/util'
import {UserlandActionBar} from './ActionBar'
import {useFilterCategory} from './useFilterCategory'
import {FilterStaffData, FilterStaffDataVariables} from './__types__/FilterStaffData'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'

const FILTER_DATA = gql`
    query FilterStaffData($orgIdStr: String!) {
        organisation(idStr: $orgIdStr) {
            idStr
            profilesCount
            profiles {
                idStr
                firstName
                lastName
                fullName
                childCategoryIdStrs
                photos {
                    idStr
                    thumbUrl
                    priority
                }
            }
        }
    }
`

export const FilterStaff: FC = () => {
    const [queryCategories, setQueryCategories] = useQueryParam('categories', ArrayParam)

    const orgIdStr = useOrganisationIdStr()
    const {homeItems, categoryLoading, catError} = useFilterCategory(orgIdStr)

    const {data: staffData, loading: allLoading, error: allError} = useQuery<FilterStaffData, FilterStaffDataVariables>(
        FILTER_DATA,
        {
            variables: {
                orgIdStr,
            },
        },
    )

    const currentOrganisation = useCurrentOrganisation()

    const profiles = useMemo(() => {
        if (!staffData) return []

        const profiles = staffData.organisation.profiles
        if (!queryCategories || !queryCategories.length) return profiles

        const categoryGroups =
            homeItems
                .map(({childCategories}) =>
                    childCategories!.map(({idStr}) => idStr).filter((idStr) => queryCategories.includes(idStr)),
                )
                .filter((row) => row.length > 0) ?? []

        return profiles.filter(({childCategoryIdStrs}) =>
            categoryGroups.every((categoryGroup) =>
                childCategoryIdStrs.some((categoryIdStr) => categoryGroup.includes(categoryIdStr)),
            ),
        )
    }, [staffData, queryCategories, homeItems])

    const categories = useMemo(() => {
        if (!homeItems || !queryCategories) return []

        const filteredCategories = homeItems
            .flatMap((homeItem) => homeItem.childCategories!)
            .filter((category) => queryCategories.includes(category.idStr))
            .map((category) => ({idStr: category.idStr, name: category.label}))

        //Keep in order added to query parameters
        return queryCategories.map((id) => filteredCategories.find((cat) => cat.idStr === id)!)
    }, [homeItems, queryCategories])

    const actionBarContent = `${profiles.length} ${pluralize('person', profiles.length)}${
        categories.length ? ' in' : ''
    }`
    const profileIdStrs = profiles.map((profile) => profile.idStr)

    const error = catError || allError

    if (error) return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>

    if (categoryLoading || allLoading) {
        return (
            <>
                <PageLoading />
                <UserlandActionBar loading />
            </>
        )
    }

    return (
        <>
            <ProfilesGridLayout
                data={{
                    organisation: currentOrganisation.currentOrganisation,
                    profiles,
                }}
            />
            <UserlandActionBar
                label={actionBarContent}
                pills={categories}
                onPillChange={(categoryList: typeof categories) => setQueryCategories(categoryList.map((c) => c.idStr))}
                pillLabelExtractor={(category) => category.name}
                profileIdStrs={profileIdStrs}
            />
        </>
    )
}
