import styled from 'styled-components'
import {HideableCssProps} from './Layout/types'
import {HideableCss} from './Layout/css'

export const Questions = styled.span<{fontSize?: string} & HideableCssProps>`
    border-width: 1px;
    border-color: rgb(0, 0, 0);
    border-style: solid;
    :hover {
        background-color: rgb(217, 217, 217);
    }
    border-radius: 36px;
    height: 38px;
    padding: 0px 22px;

    font-family: 'inter';
    font-size: ${({fontSize}) => fontSize || '12px'};
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.2px;
    paragraph-spacing: 0px;

    display: flex;
    align-items: center;

    cursor: pointer;

    ${HideableCss}
`

export const DidntGetCodeButton = styled.span`
    font-family: 'inter';
    font-size: 12px;
    color: rgb(92, 92, 92);
    :hover {
        color: #0088ff;
    }
    text-decoration: underline;
    cursor: pointer;
`

export const AddAnotherParentButton = styled.span`
    font-family: 'inter';
    font-size: 16px;
    font-weight: 600;
    color: #0088ff;
    text-decoration: underline;
    cursor: pointer;
`
