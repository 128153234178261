import {Icon, Spacer, Table, TooltipTarget, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgAlert from 'nf-ui/Icons/Alert'
import React from 'react'
import styled from 'styled-components'
import {SelectedColumn} from '~/objectTypes'
import {StepProps} from '../../useConfigureDataSource'
import {useFullScreenTable} from '../useFullScreenTable'

const AlertWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

/**
 * Returns the columns that have already been selected to be mapped to a field.
 */
const getSelectedColumns = (mappedColumns: Record<string, string>, selectedColumns: Record<string, SelectedColumn>) => {
    const mappedColumnHeaders = [...Object.keys(selectedColumns)]
    for (const idStr in mappedColumns) {
        mappedColumnHeaders.push(mappedColumns[idStr])
    }
    return mappedColumnHeaders
}

export const MapRequiredFields: React.FC<StepProps> = ({state, dispatch}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const {ref, height, width} = useFullScreenTable({offsetHeight: 52})

    const disabledColumns = getSelectedColumns(state.mappedColumns, state.selectedColumns)

    const emailAddress = state.currentColumn?.name === 'Email address'

    const title = `Choose your '${state.currentColumn?.name}' column`
    const tooltip = `This is my ${state.currentColumn?.name} column`

    const onClickCell = async ({column}: {column: string}) => {
        trackAnalyticsEvent('click_field_column')

        if (state.primary) {
            dispatch({type: 'SET_SELECTED_COLUMN', column, fieldName: state.currentColumn?.name})
        } else {
            dispatch({type: 'SET_FIELD_COLUMN_MAPPING', column, stepField: state.currentColumn!})
        }

        dispatch({type: 'NEXT_FIELD'})
    }

    return (
        <>
            <AlertWrapper>
                <Typography.Heading>{title}</Typography.Heading>
                <Spacer width={8} />
                {emailAddress && (
                    <TooltipTarget
                        title={`Email address is required as it will be used to identify each profile in your data.\n \n
                        If you do not have email address in your data. You can get around this by selecting a column that has a unique field in each row.`}
                        position="right"
                        maxWidth={290}
                    >
                        <Icon style={{paddingTop: 10}} icon={SvgAlert} />
                    </TooltipTarget>
                )}
            </AlertWrapper>
            <Spacer height={32} />
            <Table
                ref={ref}
                data={state.dataSourceData.data}
                disabledColumns={disabledColumns}
                hoverHighlight={['column']}
                maxWidth={width}
                maxHeight={height}
                headerCellsClickable
                columnTooltip={tooltip}
                onClickCell={onClickCell}
            />
        </>
    )
}
