import React from 'react'
import {Body} from './Body'
import {Footer} from './Footer'
import {Navigation} from './Navigation'

interface WizardLayoutFC extends React.FC {
    Navigation: typeof Navigation
    Body: typeof Body
    Footer: typeof Footer
}

const WizardLayout: WizardLayoutFC = ({children}) => {
    return <div>{children}</div>
}

WizardLayout.Navigation = Navigation
WizardLayout.Body = Body
WizardLayout.Footer = Footer

export {WizardLayout}
