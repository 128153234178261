import React from 'react'
import {useQueryParam} from 'use-query-params'
import {ModalLayout} from '~/components/ModalLayout'
import {EditProfile, EditTabOption} from './EditProfileModal/EditProfile'
import {HiddenFieldProvider} from './EditProfileModal/useHiddenFields'

const EditProfileModal: React.FC<{onDoneEditing: () => void}> = ({onDoneEditing}) => {
    const [editProfileId] = useQueryParam<string | null>('editProfileId')
    const [activeTab] = useQueryParam<EditTabOption>('editProfileActiveTab')

    return (
        <ModalLayout>
            <HiddenFieldProvider>
                <ModalLayout.Navigation closeText="Close" />
                <ModalLayout.Body>
                    {editProfileId && <EditProfile initialTab={activeTab} onDoneEditing={onDoneEditing} />}
                </ModalLayout.Body>
            </HiddenFieldProvider>
        </ModalLayout>
    )
}

export {EditProfileModal}
