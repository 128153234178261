import {useEffect, useState} from 'react'
import {formatDateYYYYMMDD, toStringAndPad} from '~/util/date'
import {scrollIfNeeded} from '../Layout/scroll'

export const useDatePicker = ({
    value,
    onChange,
    onAbort,
    inputRef,
}: {
    value: Date | undefined
    onChange?: (date: Date | undefined) => Promise<void>
    onAbort?: () => void
    inputRef: React.RefObject<HTMLInputElement>
}) => {
    const [yearValue, setYearValue] = useState<string>('yyyy')
    const [monthValue, setMonthValue] = useState<string>('mm')
    const [dayValue, setDayValue] = useState<string>('dd')
    const [initialStringValue, setInitialStringValue] = useState<string>('')
    const [inputPosition, setInputPosition] = useState<'year' | 'month' | 'day' | 'complete'>('year')
    const [hasFocus, setHasFocus] = useState<boolean>(false)
    const [regainFocus, setRegainFocus] = useState<boolean>(false)

    const setSelection = (position?: 'year' | 'month' | 'day' | 'complete') => {
        const element = inputRef.current
        if (!element) return
        const _position = position === undefined ? inputPosition : position
        if (_position === 'year') {
            element.selectionStart = 0
            element.selectionEnd = 4
            return
        }
        if (_position === 'month') {
            element.selectionStart = 5
            element.selectionEnd = 7
            return
        }
        element.selectionStart = 8
        element.selectionEnd = 10
    }

    const updatePosition = (position: 'year' | 'month' | 'day' | 'complete') => {
        // console.log('useDatePicker updatePosition', position)
        setTimeout(() => {
            setInputPosition(position)
            setSelection(position)
        }, 0)
    }

    const pickYearValue = (value: string) => {
        setYearValue(value)
        const hasYear = /^[0-9]{4}$/.test(value)
        if (!hasYear) {
            updatePosition('year')
        } else {
            updatePosition('month')
        }
        setRegainFocus(true)
    }

    const pickMonthValue = (value: string) => {
        setMonthValue(value)
        const hasMonth = /^[0-9]{2}$/.test(value)
        if (!hasMonth) {
            updatePosition('month')
        } else {
            updatePosition('day')
        }
        setRegainFocus(true)
    }

    const pickDayValue = (value: string) => {
        setDayValue(value)
        const hasDay = /^[0-9]{2}$/.test(value)
        if (!hasDay) {
            updatePosition('day')
            setRegainFocus(true)
        } else {
            updatePosition('complete')
        }
    }

    const reset = () => {
        setYearValue('yyyy')
        setMonthValue('mm')
        setDayValue('dd')
        setInitialStringValue('')
    }

    useEffect(() => {
        // console.log('useDatePicker effect [value]', value)
        const newValue = value ? formatDateYYYYMMDD(value) : 'yyyy/mm/dd'
        if (newValue !== initialStringValue) {
            setInitialStringValue(newValue)
            setYearValue(newValue.slice(0, 4))
            setMonthValue(newValue.slice(5, 7))
            setDayValue(newValue.slice(8, 10))
            updatePosition('year')
        }
    }, [value])

    useEffect(() => {
        // console.log('useDatePicker effect [hasFocus]', hasFocus, regainFocus)
        if (hasFocus) {
            if (regainFocus) {
                setRegainFocus(false)
                return
            }
            scrollIfNeeded(inputRef.current, {
                startTimeoutMilliseconds: 400,
                align: 'bottom',
                marginBottom: 190,
            })
        } else {
            if (regainFocus) {
                inputRef.current?.focus()
            }
        }
    }, [hasFocus])

    useEffect(() => {
        // console.log('useDatePicker effect [regainFocus]', regainFocus)
        if (regainFocus && !hasFocus) {
            inputRef.current?.focus()
        }
    }, [regainFocus])

    useEffect(() => {
        // console.log('useDatePicker effect [inputPosition]', inputPosition)
        if (inputPosition === 'complete') {
            const hasYear = /^[0-9]{4}$/.test(yearValue)
            const hasMonth = /^[0-9]{2}$/.test(monthValue)
            const hasDay = /^[0-9]{2}$/.test(dayValue)
            if (hasYear && hasMonth && hasDay) {
                onChange?.(new Date(`${yearValue}/${monthValue}/${dayValue}`))?.then(reset)
            } else if (yearValue === 'yyyy' && monthValue === 'mm' && dayValue === 'dd') {
                onChange?.(undefined)?.then(reset)
            } else {
                updatePosition(!hasYear ? 'year' : !hasMonth ? 'month' : 'day')
            }
        }
    }, [inputPosition])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            ['ArrowLeft', 'ArrowRight', 'Enter', 'ArrowUp', 'ArrowDown', 'Backspace'].includes(event.key) ||
            /^[0-9]$/.test(event.key)
        )
            event.preventDefault()
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            ['ArrowLeft', 'ArrowRight', 'Enter', 'ArrowUp', 'ArrowDown', 'Backspace'].includes(event.key) ||
            /^[0-9]$/.test(event.key)
        )
            event.preventDefault()
    }

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            ['ArrowLeft', 'ArrowRight', 'Enter', 'ArrowUp', 'ArrowDown', 'Backspace'].includes(event.key) ||
            /^[0-9]$/.test(event.key)
        )
            event.preventDefault()

        if (event.key === 'ArrowLeft') {
            updatePosition(inputPosition === 'day' ? 'month' : 'year')
        }
        if (event.key === 'ArrowRight') {
            updatePosition(inputPosition === 'year' ? 'month' : 'day')
        }
        if (event.key === 'Enter') {
            if (inputPosition === 'year') {
                updatePosition('month')
            } else if (inputPosition === 'month') {
                updatePosition('day')
            } else {
                updatePosition('complete')
            }
        }
        if (event.key === 'Escape') {
            onAbort?.()
        }
        if (event.key === 'ArrowUp') {
            if (inputPosition === 'year') {
                const component = parseInt(yearValue)
                if (isNaN(component)) {
                    setYearValue(`${new Date().getFullYear()}`)
                } else {
                    setYearValue(`${toStringAndPad(component + 1, 4)}`)
                }
                updatePosition('year')
            } else if (inputPosition === 'month') {
                const component = parseInt(monthValue)
                if (isNaN(component)) {
                    setMonthValue(`${toStringAndPad(new Date().getMonth() + 1, 2)}`)
                } else if (component > 11) {
                    setMonthValue(`01`)
                } else {
                    setMonthValue(`${toStringAndPad(component + 1, 2)}`)
                }
                updatePosition('month')
            } else {
                const component = parseInt(dayValue)
                if (isNaN(component)) {
                    setDayValue(`${toStringAndPad(new Date().getDate(), 2)}`)
                } else if (component > 30) {
                    setDayValue(`01`)
                } else {
                    setDayValue(`${toStringAndPad(component + 1, 2)}`)
                }
                updatePosition('day')
            }
        }
        if (event.key === 'ArrowDown') {
            if (inputPosition === 'year') {
                const component = parseInt(yearValue)
                if (isNaN(component)) {
                    setYearValue(`${new Date().getFullYear()}`)
                } else if (component < 1) {
                    setYearValue(`9999`)
                } else {
                    setYearValue(`${toStringAndPad(component - 1, 4)}`)
                }
                updatePosition('year')
            } else if (inputPosition === 'month') {
                const component = parseInt(monthValue)
                if (isNaN(component)) {
                    setMonthValue(`${toStringAndPad(new Date().getMonth() + 1, 2)}`)
                } else if (component < 2) {
                    setMonthValue(`12`)
                } else {
                    setMonthValue(`${toStringAndPad(component - 1, 2)}`)
                }
                updatePosition('month')
            } else {
                const component = parseInt(dayValue)
                if (isNaN(component)) {
                    setDayValue(`${toStringAndPad(new Date().getDate(), 2)}`)
                } else if (component < 2) {
                    setDayValue(`31`)
                } else {
                    setDayValue(`${toStringAndPad(component - 1, 2)}`)
                }
                updatePosition('day')
            }
        }
        if (event.key === 'Backspace') {
            if (inputPosition === 'year') {
                setYearValue(`yyyy`)
            } else if (inputPosition === 'month') {
                setMonthValue(`mm`)
            } else {
                setDayValue(`dd`)
            }
        }
        if (/^[0-9]$/.test(event.key)) {
            const digit = parseInt(event.key)
            if (inputPosition === 'year') {
                if (yearValue === 'yyyy') {
                    setYearValue(`${digit}   `)
                    setTimeout(setSelection, 1)
                } else if (yearValue.slice(1, 4) === '   ') {
                    setYearValue(`${yearValue.slice(0, 1)}${digit}  `)
                    setTimeout(setSelection, 1)
                } else if (yearValue.slice(2, 4) === '  ') {
                    setYearValue(`${yearValue.slice(0, 2)}${digit} `)
                    setTimeout(setSelection, 1)
                } else if (yearValue.slice(3, 4) === ' ') {
                    setYearValue(`${yearValue.slice(0, 3)}${digit}`)
                    updatePosition('month')
                } else {
                    setYearValue(`${digit}   `)
                    setTimeout(setSelection, 1)
                }
            } else if (inputPosition === 'month') {
                if (monthValue === 'mm') {
                    setMonthValue(`${digit} `)
                    setTimeout(setSelection, 1)
                } else if (monthValue.slice(6, 7) === ' ') {
                    setMonthValue(`${monthValue.slice(5, 6)}${digit}`)
                    updatePosition('day')
                } else {
                    setMonthValue(`${digit} `)
                    setTimeout(setSelection, 1)
                }
            } else {
                if (dayValue === 'dd') {
                    setDayValue(`${digit} `)
                    setTimeout(setSelection, 1)
                } else if (dayValue.slice(9, 10) === ' ') {
                    setDayValue(`${dayValue.slice(8, 9)}${digit}`)
                    updatePosition('complete')
                } else {
                    setDayValue(`${digit} `)
                    setTimeout(setSelection, 1)
                }
            }
        }
    }

    const handleMouseUp = (event: React.MouseEvent<HTMLInputElement>) => {
        const target = event.currentTarget
        const position =
            (target?.selectionStart || 0) >= 10 && yearValue === 'yyyy'
                ? 'year'
                : (target?.selectionStart || 0) >= 10 && monthValue === 'mm'
                ? 'month'
                : (target?.selectionStart || 0) >= 8
                ? 'day'
                : (target?.selectionStart || 0) >= 4
                ? 'month'
                : 'year'
        // console.log('useDatePicker handleMouseUp', target?.selectionStart, position)
        updatePosition(position)
    }

    return {
        yearValue,
        monthValue,
        dayValue,
        inputPosition,
        hasFocus,
        inputRef,
        handleKeyDown,
        handleKeyPress,
        handleKeyUp,
        handleMouseUp,
        setHasFocus,
        setInputPosition: updatePosition,
        setYearValue: pickYearValue,
        setMonthValue: pickMonthValue,
        setDayValue: pickDayValue,
        setRegainFocus,
        setSelection,
    }
}
