import React, {FC} from 'react'
import styled from 'styled-components'
import {Icon, IconProps} from '../Icon'

export const ButtonIconContainer = styled.div`
    display: block;
    margin-left: 4px;
    margin-right: 4px;

    :first-child {
        margin-left: -4px;
    }

    :last-child {
        margin-right: -4px;
    }

    :only-child {
        margin-left: 0;
        margin-right: 0;
    }
`
const ButtonIcon: FC<IconProps> = ({style, ref, ...rest}) => {
    return (
        <ButtonIconContainer style={style}>
            <Icon {...rest} tint="inherit" />
        </ButtonIconContainer>
    )
}

export {ButtonIcon as Icon}
