import {colors, Icon, IconContainer, Panel, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgOpenLink from 'nf-ui/Icons/OpenLink'
import React, {useRef, useState} from 'react'
import {useRouteMatch} from 'react-router-dom'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {useCurrentOrganisation} from '../CurrentOrganisationContext'
import {UnstyledLink} from '../UnstyledLink'
import {useCurrentUser} from '../useCurrentUser'
import {useViewedOrgChartPanel} from './useViewedOrgChartPanel'

export const Container = styled(UnstyledLink)`
    display: flex;
    margin-bottom: 16px;
    margin-left: 16px;
    align-items: center;
    ${IconContainer} {
        margin-right: 16px;
    }
`

export const DirectoryOrgChartSwitcher = () => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const {currentOrganisation, orgChartEnabled} = useCurrentOrganisation()
    const {me} = useCurrentUser()

    const [search] = useQueryParam<string>('search')
    const {path} = useRouteMatch()!
    const isAdminLand = path.includes('admin')
    const isOrgChart = path.includes('org-chart')

    const showOrgChartPanel = !me?.hasViewedOrgChartPanel && !isOrgChart
    const {setViewedOrgChartPanel} = useViewedOrgChartPanel()

    const [openPanel, setOpenPanel] = useState(showOrgChartPanel)
    const linkRef = useRef(null)

    if (isAdminLand || !currentOrganisation) return null

    const organisationIdStr = currentOrganisation.idStr

    if (!orgChartEnabled) return null

    const setOpen = () => {
        setViewedOrgChartPanel({variables: {organisationIdStr}})
        setOpenPanel(false)
    }

    const directoryUrl = search ? `/${organisationIdStr}/all?search=${search}` : `/${organisationIdStr}`
    const orgChartUrl = search
        ? `/${organisationIdStr}/org-chart/full?search=${search}`
        : `/${organisationIdStr}/org-chart/full`

    const to = isOrgChart ? directoryUrl : orgChartUrl

    return (
        <div ref={linkRef}>
            <Container
                to={to}
                onClick={() => trackAnalyticsEvent(isOrgChart ? 'select_view_directory' : 'select_org_charts')}
            >
                <Icon icon={SvgOpenLink} />
                <Typography.Paragraph bottomMargin={false}>
                    View {isOrgChart ? 'Directory' : 'Org chart'}
                </Typography.Paragraph>
            </Container>
            <ViewOrgChartPanel open={openPanel} setOpen={setOpen} targetRef={linkRef} />
        </div>
    )
}

const PanelContainer = styled.div`
    padding: 16px;
    background-color: ${colors.primary[100]};
    border-radius: 3px;
`

const LinkWrapper = styled.span`
    cursor: pointer;
`

const ViewOrgChartPanel = ({
    open,
    setOpen,
    targetRef,
}: {
    open: boolean
    setOpen: () => void
    targetRef: React.MutableRefObject<null>
}) => {
    return (
        <Panel
            open={open}
            onClose={() => {}}
            targetRef={targetRef}
            overrideColor={colors.primary[100]}
            placement="top"
            positionFixed
        >
            <PanelContainer>
                <Typography.Label color="white">View org charts</Typography.Label>
                <Spacer height={16} />
                <Typography.Paragraph color="white" maxWidth={180}>
                    View your company's org chart and reporting lines.
                </Typography.Paragraph>
                <LinkWrapper onClick={() => setOpen()}>
                    <Typography.Label color="white">Got it</Typography.Label>
                </LinkWrapper>
            </PanelContainer>
        </Panel>
    )
}
