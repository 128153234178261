import {Icon, List, Typography, useAnalyticsEventTracker} from 'nf-ui'
import {Switch} from 'nf-ui/Form/Switch'
import SvgMakeAdmin from 'nf-ui/Icons/MakeAdmin'
import SvgRemove from 'nf-ui/Icons/Remove'
import SvgRemoveAdmin from 'nf-ui/Icons/RemoveAdmin'
import SvgSend from 'nf-ui/Icons/Send'
import SvgDelete from 'nf-ui/Icons/Delete'
import React, {FC, useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {PanelLayout} from '~/components/PanelLayout'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {PANEL_WIDTH} from '~/constants'
import {useInviteUsersMutation} from './useInviteUsersMutation'
import {useRevokeMutations} from './useRevokeMutation'
import {useRoleMutations} from './useRoleMutation'
import {useRemoveOrganisationUsersMutation} from './useRemoveOrganisationUsersMutation'

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const revokeUserAction = {
    label: 'Directory access',
    icon: SvgRemove,
    type: 'revoke',
    analyticsEvent: 'select_revoke',
} as const

const unrevokeUserAction = {
    label: 'Directory access',
    icon: SvgRemove,
    type: 'unrevoke',
    analyticsEvent: 'select_unrevoke',
} as const

const addAdminStatusAction = {
    label: 'Make admin',
    icon: SvgMakeAdmin,
    type: 'admin',
    analyticsEvent: 'select_add_admin_status',
} as const

const removeAdminStatusAction = {
    label: 'Remove admin',
    icon: SvgRemoveAdmin,
    type: 'remove',
    analyticsEvent: 'select_remove_admin_status',
} as const

const makeGuestAction = {
    label: 'Make guest',
    icon: SvgMakeAdmin,
    type: 'guest',
    analyticsEvent: 'select_make_guest',
} as const

const makeUserAction = {
    label: 'Make user',
    icon: SvgMakeAdmin,
    type: 'user',
    analyticsEvent: 'select_make_user',
} as const

const inviteUserAction = {
    label: 'Invite user',
    icon: SvgSend,
    type: 'invite',
    analyticsEvent: 'select_invite_user',
} as const

const removeUserAction = {
    label: 'Remove from organisation',
    icon: SvgDelete,
    type: 'removeUser',
    analyticsEvent: 'select_remove_organisation_user',
} as const

type AllActions =
    | typeof revokeUserAction
    | typeof unrevokeUserAction
    | typeof addAdminStatusAction
    | typeof removeAdminStatusAction
    | typeof makeGuestAction
    | typeof makeUserAction
    | typeof inviteUserAction
    | typeof removeUserAction

export type UserActionProps = {
    userId: string
    isAdmin: boolean
    isGuest: boolean
    hasProfiles: boolean
    isRevoked: boolean
    isPending: boolean
    revokeOnly?: boolean
    onClose: () => void
}

export const UserActions: FC<UserActionProps> = ({
    isAdmin,
    isGuest,
    hasProfiles,
    isRevoked,
    isPending,
    userId,
    revokeOnly,
    onClose,
}) => {
    const actions = useMemo(() => {
        if (revokeOnly) return [unrevokeUserAction]
        const baseActions = [
            isAdmin ? removeAdminStatusAction : addAdminStatusAction,
            ...(isGuest && hasProfiles ? [makeUserAction] : isGuest ? [] : [makeGuestAction]),
            isRevoked ? unrevokeUserAction : revokeUserAction,
            removeUserAction,
        ]

        if (isPending) return [inviteUserAction, ...baseActions]
        return baseActions
    }, [revokeOnly, isRevoked, isAdmin, isPending, isGuest, hasProfiles])
    const organisationIdStr = useOrganisationIdStr()
    const {addAdmin, removeAdmin, makeGuest, makeUser} = useRoleMutations()
    const {revokeUsers} = useRevokeMutations()
    const {inviteUsers} = useInviteUsersMutation()
    const {removeOrganisationUsers} = useRemoveOrganisationUsersMutation()
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const [hasDirectoryAccess, setHasDirectoryAccess] = useState(!isRevoked)
    const isDirectoryAccessAction = (action: AllActions) => ['revoke', 'unrevoke'].includes(action.type)

    const handleAction = useCallback(
        (type: AllActions['type']) => {
            const userIdStrs = [userId]
            switch (type) {
                case 'admin':
                    addAdmin({organisationIdStr, userIdStrs})
                    break
                case 'remove':
                    removeAdmin({organisationIdStr, userIdStrs})
                    break
                case 'guest':
                    makeGuest({organisationIdStr, userIdStrs})
                    break
                case 'user':
                    makeUser({organisationIdStr, userIdStrs})
                    break
                case 'revoke':
                    setHasDirectoryAccess(false)
                    revokeUsers({organisationIdStr, userIdStrs})
                    break
                case 'unrevoke':
                    setHasDirectoryAccess(true)
                    revokeUsers({organisationIdStr, userIdStrs, unrevoke: true})
                    break
                case 'invite':
                    inviteUsers({organisationIdStr, userIdStrs})
                    break
                case 'removeUser':
                    removeOrganisationUsers({organisationIdStr, userIdStrs})
                    break
            }

            onClose()
        },
        [
            organisationIdStr,
            userId,
            addAdmin,
            removeAdmin,
            makeGuest,
            makeUser,
            revokeUsers,
            inviteUsers,
            onClose,
            removeOrganisationUsers,
        ],
    )

    return (
        <PanelLayout width={PANEL_WIDTH}>
            <Typography.Label>What would you like to do?</Typography.Label>
            <List
                width={PANEL_WIDTH}
                rows={actions}
                renderRow={(action) =>
                    isDirectoryAccessAction(action) ? (
                        <ActionRow>
                            <div>{action.label}</div>
                            <Switch
                                name="Directory access"
                                checked={hasDirectoryAccess}
                                onClick={() => {
                                    handleAction(action.type)
                                    trackAnalyticsEvent(action.analyticsEvent)
                                }}
                            />
                        </ActionRow>
                    ) : (
                        <ActionRow
                            onClick={() => {
                                handleAction(action.type)
                                trackAnalyticsEvent(action.analyticsEvent)
                            }}
                        >
                            <div>{action.label}</div>
                            <Icon size={16} icon={action.icon} />
                        </ActionRow>
                    )
                }
            ></List>
        </PanelLayout>
    )
}
