import {Icon} from 'nf-ui'
import {Typography} from 'nf-ui/Typography'
import React, {FC} from 'react'
import styled from 'styled-components'
import {CategoryContainer, categoryDetailStyle} from './CategoryDetail'
import {detailStyle, FIELD_CONFIG, ValueIconContainer} from './Detail'
import {isDetail, ProfileLine} from './FullProfile'

const DetailValue = styled.div`
    ${detailStyle}
`
const CategoryDetailValue = styled(Typography.Subheading)`
    ${categoryDetailStyle}
`

const DetailSecondaryLine: FC<{detail: ProfileLine}> = ({detail}) => {
    const {formatter, link, icon} = FIELD_CONFIG[detail.type] || {}
    const value = formatter ? formatter(detail) : detail.value

    const valueIcon = (
        <ValueIconContainer>
            <Typography.Subheading>{value}</Typography.Subheading>
            {icon && <Icon icon={icon} />}
        </ValueIconContainer>
    )

    return <DetailValue>{link ? <a href={link(detail)}>{valueIcon}</a> : valueIcon}</DetailValue>
}

const CategorySecondaryLine: FC<{category: ProfileLine; profileIdStr?: string | null}> = ({category, profileIdStr}) => {
    return (
        <CategoryDetailValue>
            <CategoryContainer category={category} profileIdStr={profileIdStr} />
        </CategoryDetailValue>
    )
}

export const SecondaryLine: FC<{secondaryLine: ProfileLine; profileIdStr?: string | null}> = ({
    secondaryLine,
    profileIdStr,
}) => {
    return isDetail(secondaryLine) ? (
        <DetailSecondaryLine detail={secondaryLine} />
    ) : (
        <CategorySecondaryLine category={secondaryLine} profileIdStr={profileIdStr} />
    )
}
