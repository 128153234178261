import {FullOrgChartData_organisation_profiles} from '../__types__/FullOrgChartData'

const findMatch = (value?: string | null, searchTerm?: string | null) => {
    if (!value || !searchTerm) return false
    const index = value.toLowerCase().indexOf(searchTerm.trim().toLowerCase())
    if (index === -1) return false
    return true
}

export const matchesSearchTerm = (profile: FullOrgChartData_organisation_profiles, searchTerm?: string | null) => {
    if (!searchTerm) return false

    return (
        findMatch(`${profile.firstName} ${profile.lastName}`, searchTerm) ||
        findMatch(profile.secondaryLine, searchTerm)
    )
}

export const matchesCategory = ({
    profile,
    categoryIdStr,
    categoryProfiles,
}: {
    profile: FullOrgChartData_organisation_profiles
    categoryIdStr?: string | null
    categoryProfiles: Record<string, string[]>
}) => {
    if (!categoryIdStr) return false

    return categoryProfiles[categoryIdStr]?.includes(profile.idStr) || false
}

export const filteredResult = ({
    profiles,
    searchTerm,
    categoryIdStr,
    categoryProfiles,
}: {
    profiles: FullOrgChartData_organisation_profiles[]
    searchTerm?: string
    categoryIdStr?: string
    categoryProfiles: Record<string, string[]>
}) => {
    const searchAndCategory = !!(searchTerm && categoryIdStr)
    const matches = profiles.filter((profile) => {
        const searchMatch = matchesSearchTerm(profile, searchTerm)
        const categoryMatch = matchesCategory({profile, categoryIdStr, categoryProfiles})

        return searchAndCategory ? searchMatch && categoryMatch : searchMatch || categoryMatch
    })

    return matches
}
