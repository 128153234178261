import React, {ReactNode} from 'react'
import {useCollapsibleGroupContext} from './CollapsibleGroup'
import {useCollapsibleContext} from './CollapsibleContext'

type Children = ReactNode | ((props: {expanded: boolean}) => ReactNode)

export const CollapsibleHeader = ({children}: {children: Children}) => {
    const index = useCollapsibleContext()
    const {expandedIndex, setExpandedIndex} = useCollapsibleGroupContext()
    const expanded = expandedIndex === index

    const childElements = typeof children === 'function' ? children({expanded}) : children

    return (
        <div style={{cursor: 'pointer'}} onClick={() => setExpandedIndex(expanded ? null : index)}>
            {childElements}
        </div>
    )
}
