import {gql, useMutation} from '@apollo/client'
import {cloneDeep} from '@apollo/client/utilities'
import {AnimatePresence, motion} from 'framer-motion'
import {from} from 'fromfrom'
import {Button, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import React, {FC, useCallback} from 'react'
import styled from 'styled-components'
import {HomeItemFragment} from '~/apollo/queries/HomeItemFragment'
import {HomeItemData} from '~/apollo/queries/__types__/HomeItemData'
import {animateChildVariants, AnimatedList} from '~/components/AnimatedList'
import {LoadingContainer} from '~/components/LoadingContainer'
import {useMessages} from '~/components/useMessages'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {useRelativeRoute} from '~/components/useRelativeRoute'
import {HomeItemType} from '~/globalTypes'
import {DraggableItem} from '../DraggableItem'
import {DraggableList} from '../DraggableList'
import {onDragEndItemList} from '../onDragEndItemList'
import {UPDATE_HOME_ITEM} from './EditHomeItem'
import {AddHomeItem, AddHomeItemVariables} from './__types__/AddHomeItem'
import {UpdateCategoryPriority, UpdateCategoryPriorityVariables} from './__types__/UpdateCategoryPriority'
import {UpdateHomeItem, UpdateHomeItemVariables} from './__types__/UpdateHomeItem'
import {UpdateHomeItems, UpdateHomeItemsVariables, UpdateHomeItems_updateHomeItems} from './__types__/UpdateHomeItems'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'

const UPDATE_HOME_ITEMS = gql`
    mutation UpdateHomeItems($homeItems: [HomeItemInput!]!) {
        updateHomeItems(homeItems: $homeItems) {
            idStr
            label
            visible
            priority
        }
    }
`

export const UPDATE_CATEGORY_PRIORITY = gql`
    mutation UpdateCategoryPriority($categories: [CategoryPriorityInput!]!, $organisationIdStr: String!) {
        updateChildCategoryPriority(categories: $categories, organisationIdStr: $organisationIdStr) {
            idStr
            priority
        }
    }
`

//Can't move this into a new fragement or it breaks apollo
const ADD_HOME_ITEM = gql`
    mutation AddHomeItem($categoryId: String!) {
        insertHomeItem(categoryIdStr: $categoryId) {
            idStr
            label
            visible
            priority
            type
            sourceIdStr
            childCategories {
                idStr
                name
                label
                priority
                combinedCategories
            }
        }
    }
`

const CombineButtonContainer = styled.div`
    display: flex;
    background-color: white;
    flex-direction: row-reverse;
    padding-bottom: 8px;
`

type HomeListType = {
    setHoveringId: (id: string | undefined) => void
    forceParentRender: () => void
    expanded: string[]
    setExpanded: React.Dispatch<React.SetStateAction<string[]>>
} & Pick<HomeItemData, 'homeItems'>

export const HomeItemList: FC<HomeListType> = ({
    homeItems,
    setHoveringId,
    forceParentRender,
    expanded,
    setExpanded,
}) => {
    const organisationIdStr = useOrganisationIdStr()
    const organisation = useCurrentOrganisation()
    const {pushRelative} = useRelativeRoute()
    const [updateHomeItems] = useMutation<UpdateHomeItems, UpdateHomeItemsVariables>(UPDATE_HOME_ITEMS)
    const [updateHomeItem] = useMutation<UpdateHomeItem, UpdateHomeItemVariables>(UPDATE_HOME_ITEM)
    const [updateCategoryPriority] = useMutation<UpdateCategoryPriority, UpdateCategoryPriorityVariables>(
        UPDATE_CATEGORY_PRIORITY,
    )
    const {onCompleted} = useMessages()
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const promotableCategory = useCallback(
        (idStr: string) => {
            const childCategory = from(homeItems)
                .filter((item) => item.type === HomeItemType.CATEGORY)
                .flatMap((item) => item.childCategories || [])
                .find((child) => child.idStr === idStr)

            return !!childCategory
        },
        [homeItems],
    )

    const [addHomeItemMutation, addHomeItemMutationControl] = useMutation<AddHomeItem, AddHomeItemVariables>(
        ADD_HOME_ITEM,
        {
            update: (proxy, mutationResult) => {
                if (!mutationResult.data) return
                const fragment = {
                    fragment: HomeItemFragment,
                    id: 'OrganisationObject:' + organisationIdStr,
                }

                const data = proxy.readFragment<HomeItemData>(fragment)
                if (!data) return
                const updatedData = cloneDeep(data)
                updatedData.homeItems.push({displayMode: null, ...mutationResult.data.insertHomeItem})

                proxy.writeFragment({
                    ...fragment,
                    data: updatedData,
                })
            },
            onCompleted,
        },
    )

    const addHomeItem = useCallback(
        async (id: string) => {
            const homeItemExists = homeItems.some((item) => item.sourceIdStr === id)

            if (addHomeItemMutationControl.loading || homeItemExists) return
            if (!promotableCategory(id)) return

            await addHomeItemMutation({
                variables: {
                    categoryId: id,
                },
            })
        },
        [addHomeItemMutationControl, homeItems, addHomeItemMutation, promotableCategory],
    )

    const toggleVisibility = useCallback(
        (id: string) => {
            const homeItem = homeItems.find((item) => item.idStr === id)

            if (homeItem) {
                trackAnalyticsEvent('show_this_field')
                updateHomeItem({
                    variables: {homeItem: {idStr: id, visible: !homeItem.visible}},
                    optimisticResponse: {
                        updateHomeItem: {...homeItem, visible: !homeItem.visible},
                    },
                })
                return
            }
        },
        [homeItems, updateHomeItem, trackAnalyticsEvent],
    )

    const optimisticUpdateResponse = (orderedItems: UpdateHomeItems_updateHomeItems[]) => {
        return orderedItems.map((item, index) => {
            return {
                ...item,
                priority: index,
            }
        })
    }

    const onDragEnd = onDragEndItemList<'HomeItemObject'>((reorderedItems) => {
        trackAnalyticsEvent('change_category_order')

        const orderedItems = reorderedItems.map((re) => {
            const homeItem = homeItems.find((hi) => hi.idStr === re.idStr)
            return {
                ...re,
                label: re.label ?? homeItem?.label ?? '',
                visible: re.visible ?? homeItem?.visible ?? true,
            }
        })

        updateHomeItems({
            variables: {
                homeItems: orderedItems.map(({__typename, ...rest}) => rest),
            },
            optimisticResponse: {
                updateHomeItems: optimisticUpdateResponse(orderedItems),
            },
        })
        forceParentRender()
    })

    const onDragEndChildren = onDragEndItemList<'ChildCategoryObject'>((reorderedItems) => {
        updateCategoryPriority({
            variables: {
                categories: reorderedItems.map(({__typename, ...rest}) => rest),
                organisationIdStr,
            },
            optimisticResponse: {
                updateChildCategoryPriority: reorderedItems,
            },
        })
    })

    const draggableItems = from(homeItems)
        .sortBy((item) => item.priority)
        .toArray()
        .map((item) => ({...item, canExpand: !!(item.childCategories && item.childCategories.length)}))

    return (
        <LoadingContainer fullHeight loading={addHomeItemMutationControl.loading}>
            <Typography.Heading>Home page</Typography.Heading>
            <Spacer height={24} />
            <Typography.Paragraph maxWidth={340} bottomMargin={false}>
                Add, manage, and arrange categories as you’d like them to display on your directory's home page
            </Typography.Paragraph>
            <Spacer height={32} />
            {draggableItems && (
                <DraggableList onDragEnd={(result) => onDragEnd(result, draggableItems)} droppableId="DesignHome">
                    {draggableItems.map((item, index) => (
                        <>
                            <DraggableItem
                                toggleVisibility={toggleVisibility}
                                item={item}
                                index={index}
                                key={item.idStr}
                                onMouseEnter={() => setHoveringId(item.idStr)}
                                onMouseLeave={() => setHoveringId(undefined)}
                                expanded={expanded}
                                setExpanded={setExpanded}
                                onEditClickEvent={'select_edit_category'}
                            />
                            <AnimatePresence>
                                {expanded.includes(item.idStr) && item.type === HomeItemType.CATEGORY && (
                                    <AnimatedList>
                                        <motion.div key={index} variants={animateChildVariants}>
                                            <DraggableList
                                                onDragEnd={(result) =>
                                                    onDragEndChildren(
                                                        result,
                                                        from(item.childCategories || [])
                                                            .sortBy((c) => c.priority)
                                                            .toArray(),
                                                    )
                                                }
                                                droppableId="DesignHomeChild"
                                            >
                                                {from(item.childCategories || [])
                                                    .sortBy((c) => c.priority)
                                                    .toArray()
                                                    .map((child, index) => (
                                                        <div style={{marginLeft: 32}}>
                                                            <DraggableItem
                                                                item={child}
                                                                index={index}
                                                                key={child.idStr}
                                                                onMouseEnter={() => setHoveringId(child.idStr)}
                                                                onMouseLeave={() => setHoveringId(undefined)}
                                                                expanded={expanded}
                                                                setExpanded={setExpanded}
                                                                addToHome={
                                                                    promotableCategory(child.idStr)
                                                                        ? addHomeItem
                                                                        : undefined
                                                                }
                                                                onEditClickEvent={'select_edit_category'}
                                                            />
                                                        </div>
                                                    ))}
                                            </DraggableList>

                                            {!organisation.currentOrganisation?.appFeatures?.inAppData ? (
                                                <CombineButtonContainer>
                                                    <Button
                                                        onClickAnalyticsEvent="select_create_merged_category"
                                                        variant="tertiary"
                                                        onClick={() => {
                                                            pushRelative(`/merge/${item.idStr}`)
                                                        }}
                                                    >
                                                        Create combined category
                                                    </Button>
                                                    <Spacer height={24} />
                                                </CombineButtonContainer>
                                            ) : (
                                                undefined
                                            )}
                                        </motion.div>
                                    </AnimatedList>
                                )}
                            </AnimatePresence>
                        </>
                    ))}
                </DraggableList>
            )}
        </LoadingContainer>
    )
}
