import {useQuery} from '@apollo/client'
import {from} from 'fromfrom'
import {colors, Spacer, Typography} from 'nf-ui'
import React from 'react'
import styled from 'styled-components'
import {PageLoading} from '~/components/PageLoading'
import {useCurrentUser} from '~/components/useCurrentUser'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {getGraphQLErrorMessage} from '~/util'
import {HOME_DATA} from './Home'
import {MeetingsPanel} from './Insights/MeetingsPanel'
import {SmartGroupInsight} from './Insights/SmartGroupInsight'
import {ProfileSidePanel} from './Meetings/AttendeeSidePanel'
import {HomeData, HomeDataVariables, HomeData_organisation_smartGroups} from './__types__/HomeData'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'

const Container = styled.div`
    padding: 0 32px 32px;
`

const Header = styled.div`
    align-items: center;
    justify-content: space-between;
    padding: 32px 0 24px 0;
    position: relative;
`

const Logo = styled.div`
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -36px;
    width: 160px;
    height: 72px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`

const MonthlyInsightsContainer = styled.div`
    background-color: ${colors.lightGray};
    border-radius: 4px;
    padding: 24px;
`

const getOrderedSmartGroups = (smartGroups: HomeData_organisation_smartGroups[]) => {
    const smartGroupMap = from(smartGroups).toObject((smartGroup) => smartGroup.idStr)
    return [smartGroupMap['upcomingBirthdays'], smartGroupMap['newJoiners'], smartGroupMap['workAnniversary']].filter(
        Boolean,
    )
}

export const Insights: React.FC = () => {
    const organisationId = useOrganisationIdStr()
    const {data: homeData, error} = useQuery<HomeData, HomeDataVariables>(HOME_DATA, {
        variables: {
            idStr: organisationId,
        },
        fetchPolicy: 'cache-first',
    })
    const {superAdmin} = useCurrentUser()

    const currentOrganisation = useCurrentOrganisation()

    if (error) return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>
    if (!homeData) return <PageLoading />

    const smartGroups = getOrderedSmartGroups(homeData.organisation.smartGroups)
    const insights = homeData.organisation.categoryChangesThisMonth
    const _data = {organisation: currentOrganisation.currentOrganisation}

    return (
        <Container>
            <Header>
                <Typography.Heading maxLines={1}>Welcome to {homeData.organisation.name}</Typography.Heading>
                {homeData.organisation.logo && (
                    <Logo style={{backgroundImage: `url('${homeData.organisation.logo.file.url}')`}} />
                )}
            </Header>

            <MeetingsPanel />

            {smartGroups.length > 0 && (
                <>
                    <Typography.Label>Milestones at {homeData.organisation.name} this week</Typography.Label>
                    <Spacer height={8} />

                    {smartGroups.map((smartGroup, index) => (
                        <React.Fragment key={index}>
                            <SmartGroupInsight smartGroup={smartGroup} startExpanded={index === 0} data={_data} />
                            <Spacer height={8} />
                        </React.Fragment>
                    ))}
                    <Spacer height={8} />
                </>
            )}

            <Typography.Label>Recent updates at {homeData.organisation.name} in the last month</Typography.Label>
            <Spacer height={8} />

            <>
                {insights.map((insight, index) => (
                    <React.Fragment key={index}>
                        <SmartGroupInsight smartGroup={insight} data={_data} />
                        <Spacer height={8} />
                    </React.Fragment>
                ))}
                {insights.length === 0 && (
                    <MonthlyInsightsContainer>
                        <Typography.Paragraph bottomMargin={false}>
                            There have been no recent updates to {homeData.organisation.name} in the last month, check
                            back soon.
                        </Typography.Paragraph>
                    </MonthlyInsightsContainer>
                )}
            </>
            <ProfileSidePanel />
        </Container>
    )
}
