import {useEffect, useRef, useState} from 'react'
import {FieldType} from '~/objectTypes'
import {FullOrgChartData_organisation, FullOrgChartData_organisation_fields} from '../__types__/FullOrgChartData'

const STORAGE_KEY = 'ORG_CHART_DETAIL_CONFIG'

export type DetailConfig = {
    fieldIdStr: string
    visible: boolean
}

const getExistingConfig = () => {
    const config = localStorage.getItem(STORAGE_KEY)
    if (config) return JSON.parse(config)
    return {}
}

const persistConfig = (newConfig: DetailConfig[] | null, organisationIdStr: string) => {
    const config = getExistingConfig()
    config[organisationIdStr] = newConfig
    localStorage.setItem(STORAGE_KEY, JSON.stringify(config))
}

export const useSetUpDetailConfig = (
    detailConfig: DetailConfig[] | null,
    setDetailConfig: (newConfig: DetailConfig[] | null, persist?: boolean) => void,
    setFirstNameField: React.Dispatch<React.SetStateAction<FullOrgChartData_organisation_fields | null>>,
    organisation?: FullOrgChartData_organisation,
) => {
    const loadedConfig = useRef(false)
    const setFirstName = useRef(false)
    const setFromData = useRef(false)

    useEffect(() => {
        if (!organisation) return

        loadedConfig.current = false
        const config = getExistingConfig()
        if (config) {
            const currentOrgConfig = config[organisation.idStr] || []

            setDetailConfig(currentOrgConfig, false)
        }
        loadedConfig.current = true
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation])

    useEffect(() => {
        if (!organisation || setFromData.current || !loadedConfig.current || detailConfig === null) return

        const {fields} = organisation
        const firstName = fields.find((field) => field.type === FieldType.FIRST_NAME)!

        if (!setFirstName.current) {
            setFirstNameField(firstName)
            setFirstName.current = true
        }

        const categories = fields.filter((field) => field.type === FieldType.CATEGORY)
        const availableConfigFields = [firstName, ...categories]

        if (detailConfig.length > 0) {
            const updatedConfig = detailConfig.filter((config) =>
                availableConfigFields.find((field) => field.idStr === config.fieldIdStr),
            )
            const firstNameConfig = updatedConfig.find((config) => config.fieldIdStr === firstName.idStr)
            if (firstNameConfig?.visible === false) {
                firstNameConfig.visible = true
            }

            const newFields = availableConfigFields
                .filter((field) => !updatedConfig.find((config) => config.fieldIdStr === field.idStr))
                .map((field) => ({fieldIdStr: field.idStr, visible: false}))

            setDetailConfig(updatedConfig.concat(newFields))
        } else {
            const initialConfig = availableConfigFields.map((field, index) => {
                return {fieldIdStr: field.idStr, visible: index < 2} as DetailConfig
            })
            setDetailConfig(initialConfig)
        }

        setFromData.current = true
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation, loadedConfig.current, detailConfig])
}

export const useDetailConfig = (organisationIdStr: string) => {
    const [detailConfig, setDetailConfigState] = useState<DetailConfig[] | null>(null)

    const toggleDetailVisibility = (fieldIdStr: string) =>
        setDetailConfigState((currentConfig) => {
            const updatedConfig = currentConfig ? [...currentConfig] : []
            const field = updatedConfig.find((config) => config.fieldIdStr === fieldIdStr)
            if (field) field.visible = !field.visible

            persistConfig(updatedConfig, organisationIdStr)

            return updatedConfig
        })

    const setDetailConfig = (newConfig: DetailConfig[] | null, persist = true) => {
        if (persist) {
            persistConfig(newConfig, organisationIdStr)
        }

        setDetailConfigState(newConfig)
    }

    return {detailConfig, toggleDetailVisibility, setDetailConfig}
}
