import React, {SVGAttributes} from 'react'
import styled, {keyframes} from 'styled-components'
import {useTheme} from './NFThemeProvider'

const dash = (offset: number) => keyframes`
    0% {
        stroke-dashoffset: ${offset};
    }
    50% {
        stroke-dashoffset: ${offset / 4};
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: ${offset};
        transform: rotate(450deg);
    }
`

const rotator = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
`

interface ContainerProps {
    size: number
}

export const Container = styled.svg.attrs<ContainerProps>((props) => ({
    viewBox: `0 0 ${props.size} ${props.size}`,
    xmlns: 'http://www.w3.org/2000/svg',
}))<ContainerProps>`
    animation: ${rotator} 1.4s linear infinite;
    width: ${(props) => props.size - 1}px;
    height: ${(props) => props.size - 1}px;
    display: block;
`

interface CircleProps {
    size: number
    tint: string
    strokeWidth: number
}

const Circle = styled.circle.attrs<CircleProps, {offset: number}>((props) => ({
    offset: props.size * Math.PI,
    fill: 'none',
    strokeLinecap: 'round',
    cx: props.size / 2,
    cy: props.size / 2,
    r: (props.size - props.strokeWidth) / 2,
}))<CircleProps>`
    stroke-dasharray: ${(props) => props.offset};
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: ${(props) => dash(props.offset)} 1.4s ease-in-out infinite;
    stroke: ${(props) => props.tint};
`

interface SpinnerProps extends SVGAttributes<SVGElement> {
    size?: number
    /**
     * If not defined will use the primary color in theme
     *
     * @type {string}
     * @memberof SpinnerProps
     */
    tint?: string
}

const strokeWidthSizeRatio = 3.3 / 32

const Spinner: React.FC<SpinnerProps> = ({tint, size = 16, ...rest}) => {
    const theme = useTheme()
    if (!tint) {
        tint = theme.primary.color
    }
    return (
        <Container {...rest} size={size}>
            <Circle size={size} strokeWidth={strokeWidthSizeRatio * size} tint={tint} />
        </Container>
    )
}

export {Spinner}
