import {AnimatePresence, motion} from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import {Space} from './Spacer'

const StackGrid = styled.div<{space: Space}>`
    display: grid;
    grid-auto-rows: min-content;
    gap: ${(props) => props.space}px 0px;
`

export const Stack: React.FC<{space: Space}> = ({children, space}) => {
    return <StackGrid space={space}>{children}</StackGrid>
}

export const AnimatedStack: React.FC<{space: Space}> = ({children, space}) => {
    return (
        <Stack space={space}>
            <AnimatePresence initial={false}>
                {React.Children.map(children, (child, index) => {
                    if (!child) return null

                    return (
                        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} key={index}>
                            {child}
                        </motion.div>
                    )
                })}
            </AnimatePresence>
        </Stack>
    )
}
