import * as React from 'react'
const SvgFields = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgFields(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M1.3 10.461c-.726 0-1.3.6-1.3 1.36 0 .76.574 1.36 1.3 1.36.813 0 1.4-.588 1.4-1.36 0-.772-.587-1.36-1.4-1.36zm14.1.83H5.5c-.308 0-.5.21-.5.53 0 .284.152.482.402.522l.098.008h9.9c.338 0 .6-.242.6-.53 0-.26-.212-.481-.5-.523l-.1-.007zM1.3 5.481c-.726 0-1.3.6-1.3 1.36 0 .76.575 1.36 1.3 1.36.813 0 1.4-.588 1.4-1.36 0-.772-.587-1.36-1.4-1.36zm14.1.83H5.5c-.308 0-.5.21-.5.53 0 .284.152.482.402.522l.098.008h9.9c.338 0 .6-.242.6-.53 0-.26-.212-.481-.5-.523l-.1-.007zM1.3.5C.575.5 0 1.1 0 1.86c0 .76.574 1.36 1.3 1.36.813 0 1.4-.588 1.4-1.36C2.7 1.088 2.113.5 1.3.5zm14.1.83H5.5c-.308 0-.5.21-.5.53 0 .285.152.482.402.522l.098.008h9.9c.339 0 .6-.241.6-.53 0-.26-.212-.481-.501-.523L15.4 1.33z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgFields
