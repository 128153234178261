import {Button, Columns, HeaderLayout, MultiColumnList, Spacer} from 'nf-ui'
import React from 'react'
import {ModalLayout} from '~/components/ModalLayout'
import {FieldsData_dataSource_importConfig_fieldRules} from './__types__/FieldsData'
import {fieldTypes, ManageFieldsState} from './useManageFields'
import styled from 'styled-components'
import SvgDelete from 'nf-ui/Icons/Delete'

const PadLeft16 = styled.span`
    padding: 0px 0px 0px 16px;
`

const FooterText = styled.span`
    color: white;
    font-family: halyard-display;
    font-weight: 500;
`

const columns: Columns<FieldsData_dataSource_importConfig_fieldRules> = [
    {
        name: 'Field Name',
        width: 240,
        value: (fieldRule) => fieldRule.fieldName,
        highlight: true,
    },
    {
        name: 'Behaviour',
        width: 160,
        value: (fieldRule) => fieldTypes[fieldRule.fieldType] || fieldRule.fieldType,
        highlight: true,
    },
]

interface ManageFieldsProps {
    state: ManageFieldsState
    startFieldRuleEdit: (fieldRule: string | undefined) => void
    startFieldRuleDelete: (fieldRuleName: string) => void
    completeFieldRuleDelete: () => Promise<void>
    cancelFieldRuleDelete: () => void
    setModal: (modal: string) => void
}

export const ManageFields: React.FC<ManageFieldsProps> = ({
    state,
    startFieldRuleEdit,
    startFieldRuleDelete,
    completeFieldRuleDelete,
    cancelFieldRuleDelete,
    setModal,
}) => {
    const rows: FieldsData_dataSource_importConfig_fieldRules[] = state.fieldRules || []

    return (
        <ModalLayout>
            <ModalLayout.Navigation closeable={false} narrow={true}></ModalLayout.Navigation>
            <ModalLayout.Body>
                {state.defineField?.deletingFieldRule && (
                    <HeaderLayout
                        heading={`Deleting field: ${state.defineField?.deletingFieldRule}`}
                        subheading="Are you sure?"
                        skipPadding={true}
                        rightContent={
                            <>
                                <PadLeft16>
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            completeFieldRuleDelete()
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </PadLeft16>
                                <PadLeft16>
                                    <Button
                                        variant="secondary"
                                        onClick={() => {
                                            cancelFieldRuleDelete()
                                        }}
                                    >
                                        No
                                    </Button>
                                </PadLeft16>
                            </>
                        }
                    ></HeaderLayout>
                )}
                {!state.defineField?.deletingFieldRule && (
                    <HeaderLayout
                        heading="Manage fields"
                        subheading="These are the fields displayed in your directory."
                        skipPadding={true}
                        rightContent={
                            <>
                                <PadLeft16>
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            startFieldRuleEdit(undefined)
                                            setModal('defineField')
                                        }}
                                    >
                                        Add field
                                    </Button>
                                </PadLeft16>
                                <PadLeft16>
                                    <Button
                                        variant="secondary"
                                        onClick={() => {
                                            setModal('')
                                        }}
                                    >
                                        Close
                                    </Button>
                                </PadLeft16>
                            </>
                        }
                    >
                        <MultiColumnList
                            width={960}
                            columns={columns}
                            rows={rows}
                            onClick={(row) => {
                                startFieldRuleEdit(row.fieldName)
                                setModal('defineField')
                            }}
                            actions={{
                                button: {
                                    icon: SvgDelete,
                                    iconPosition: 'right',
                                    label: '',
                                },
                                onClick: (row) => {
                                    startFieldRuleDelete(row.fieldName)
                                },
                            }}
                        ></MultiColumnList>
                        <Spacer height={16}></Spacer>
                    </HeaderLayout>
                )}
            </ModalLayout.Body>
            {state.footerAction && (
                <ModalLayout.Footer color="#3c8eff">
                    <FooterText>{state.footerAction.message}</FooterText>
                    <PadLeft16>
                        <Button
                            variant="secondary"
                            onClick={state.footerAction.handler}
                            loading={state.footerAction.busy}
                        >
                            {state.footerAction.button}
                        </Button>
                    </PadLeft16>
                </ModalLayout.Footer>
            )}
        </ModalLayout>
    )
}
