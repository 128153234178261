import {Button, colors, Icon, Spacer, Typography} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React, {useRef} from 'react'
import styled from 'styled-components'
import {useMemoOne} from 'use-memo-one'
import {ModalLayout} from '~/components/ModalLayout'
import {StepProps, useConfigureDataSource} from '../useConfigureDataSource'
import {ColumnPreview} from './ColumnPreview'
import {FieldTypeSelect} from './FieldTypeSelect'

const MatchRow = styled.div`
    display: flex;
    flex-direction: row;
`

const ColumnSeparator = styled.div`
    height: 0px;
    width: 152px;
    flex-shrink: 0;
`

const HeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const MatchArrow = styled(Icon)`
    margin-top: 12px;
    color: ${colors.green[100]};
`

const useFieldOptions = () => {
    const {availableFieldTypes} = useConfigureDataSource()

    const namedOptions = availableFieldTypes.namedFieldTypes.map((fieldType) => ({
        label: fieldType.name,
        name: fieldType.name,
    }))

    const unnamedOptions = availableFieldTypes.unnamedFieldTypes.map((fieldType) => ({
        label: fieldType.name,
        type: fieldType.type,
    }))

    return useMemoOne(() => {
        return {namedOptions, unnamedOptions, allOptions: [...namedOptions, ...unnamedOptions]}
    }, [availableFieldTypes])
}

export const MatchColumns: React.FC<StepProps> = ({dispatch, state}) => {
    const options = useFieldOptions()
    const [firstRow, secondRow] = state.dataSourceData.data
    const requiredFieldColumns = useRef(Object.keys(state.selectedColumns))
    const requiredField = (column: string) => requiredFieldColumns.current.includes(column)

    const optionalColumns = Object.keys(firstRow).filter((column) => !requiredField(column))

    const columnNames = [...requiredFieldColumns.current, ...optionalColumns]

    return (
        <>
            <ModalLayout.Body>
                <Typography.Heading>Finish setting up your import</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading maxWidth={940}>
                    Match columns in your imported data to fields in the Names & Faces app.
                </Typography.Subheading>
                <Spacer height={32} />
                <HeadingContainer>
                    <Typography.Label>Your imported data</Typography.Label>
                    <ColumnSeparator />
                    <Typography.Label>Names & Faces field type</Typography.Label>
                </HeadingContainer>
                <Spacer height={8} />
                {columnNames.map((columnName) => (
                    <>
                        <MatchRow>
                            <ColumnPreview
                                heading={columnName}
                                values={[firstRow[columnName], secondRow[columnName]]}
                            />
                            <Spacer width={40} />
                            <MatchArrow tint={colors.primary[100]} icon={SvgArrowRight} />
                            <Spacer width={40} />
                            <FieldTypeSelect
                                options={options}
                                disabled={requiredField(columnName)}
                                selectedColumn={state.selectedColumns[columnName]}
                                onSelectOption={(option) =>
                                    dispatch({
                                        type: 'SET_SELECTED_COLUMN',
                                        column: columnName,
                                        fieldName: option.name,
                                        fieldType: option.type,
                                    })
                                }
                            />
                        </MatchRow>
                        <Spacer height={56} />
                    </>
                ))}
            </ModalLayout.Body>

            <ModalLayout.Footer>
                <Button
                    variant="tertiary"
                    onClick={async () => {
                        dispatch({type: 'SET_COMPLETED'})
                    }}
                    // onClickAnalyticsEvent="select_skip_step"
                >
                    Next <Button.Icon icon={SvgArrowRight} />
                </Button>
            </ModalLayout.Footer>
        </>
    )
}
