import * as React from 'react'
const SvgArrowRight = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgArrowRight(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M9.195 4.816l2.493 2.493c.179.185.282.434.282.69a.999.999 0 01-.282.692l-2.492 2.492a.986.986 0 01-1.391 0l-.081-.092a.987.987 0 01.08-1.293l.823-.818H5.01A.979.979 0 014.03 8l.007-.115a.98.98 0 01.973-.865h3.617l-.823-.817a.988.988 0 010-1.386.988.988 0 011.391-.001z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgArrowRight
