import * as React from 'react'
const SvgSearch = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgSearch(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M7.065 0c3.84 0 6.93 3.126 6.93 6.998 0 1.73-.617 3.312-1.643 4.532l3.487 3.488a.55.55 0 01-.701.842l-.077-.064-3.482-3.482a6.868 6.868 0 01-4.514 1.681C3.12 13.995 0 10.899 0 6.998 0 3.096 3.12 0 7.065 0zm0 1C3.67 1 1 3.65 1 6.998c0 3.347 2.67 5.997 6.065 5.997 3.285 0 5.93-2.675 5.93-5.997C12.995 3.675 10.35 1 7.065 1z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgSearch
