import {gql, useMutation, useQuery} from '@apollo/client'
import {produce} from 'immer'
import {
    Button,
    colors,
    Form,
    Icon,
    List,
    Panel,
    Spacer,
    TooltipTarget,
    Typography,
    useAnalyticsEventTracker,
} from 'nf-ui'
import SvgAlert from 'nf-ui/Icons/Alert'
import SvgClose from 'nf-ui/Icons/Close'
import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {ModalLayout} from '~/components/ModalLayout'
import {PageLoading} from '~/components/PageLoading'
import {PanelLayout} from '~/components/PanelLayout'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {FULL_ORG_CHART_DATA} from '../useFullOrgChartData'
import {FullOrgChartData} from '../__types__/FullOrgChartData'
import {EditPrimaryDataLightbox} from './EditPrimaryDataLightbox'
import {useEditPrimaryManager} from './useEditPrimaryManager'
import {AddAdditionalManagers, AddAdditionalManagersVariables} from './__types__/AddAdditionalManagers'
import {
    ReportingLineData,
    ReportingLineDataVariables,
    ReportingLineData_profile,
    ReportingLineData_profiles,
} from './__types__/ReportingLineData'

export const REPORTING_LINE_DATA = gql`
    query ReportingLineData($organisationIdStr: String!, $profileIdStr: String!) {
        profiles(organisationIdStr: $organisationIdStr) {
            idStr
            fullName
            managerIdStr
            openPosition
            profileLines {
                label
                value
            }
        }
        profile(idStr: $profileIdStr) {
            idStr
            additionalManagers
            managerIdStr
            fullName
            originalManagerIdStr
            managerEdited
        }
    }
`

const ADD_ADDITIONAL_MANAGER = gql`
    mutation AddAdditionalManagers($organisationIdStr: String!, $profileIdStr: String!, $managerIdStrs: [String!]!) {
        updateAdditionalManagers(
            organisationIdStr: $organisationIdStr
            profileIdStr: $profileIdStr
            managerIdStrs: $managerIdStrs
        )
    }
`

const ManagerContainer = styled.div`
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 13px;
`

const AlertContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

type Profile = ReportingLineData_profiles
type EditProfile = ReportingLineData_profile

const managerDisplayName = (profile: Profile) => {
    if (profile.openPosition) {
        const title = profile.profileLines.find((line) => line.label === 'Title')?.value || ''
        return `${profile.fullName} (${title})`
    }
    return profile.fullName
}

const AdditionalManager = ({
    profile,
    removeAdditionalManager,
}: {
    profile: Profile
    removeAdditionalManager: (idStr: string) => void
}) => {
    return (
        <ManagerContainer>
            <Typography.Paragraph bottomMargin={false}>{profile.fullName}</Typography.Paragraph>

            <div style={{cursor: 'pointer'}}>
                <Icon icon={SvgClose} tint={colors.black} onClick={() => removeAdditionalManager(profile.idStr)} />
            </div>
        </ManagerContainer>
    )
}

const PANEL_WIDTH = 304

const PanelProfileList = ({
    profiles,
    setSearchQuery,
    onClickProfile,
}: {
    setSearchQuery: (query: string) => void
    profiles: Profile[]
    onClickProfile: (idStr: string) => void
}) => {
    return profiles.length ? (
        <List
            width={PANEL_WIDTH}
            rows={profiles}
            renderRow={(profile) => managerDisplayName(profile)}
            onClick={(profile) => {
                onClickProfile(profile.idStr)
                setSearchQuery('')
            }}
        />
    ) : (
        <PanelLayout width={PANEL_WIDTH}>
            <Typography.Label>No results</Typography.Label>
            <Typography.Paragraph>Could not find any profiles matching your search term.</Typography.Paragraph>
        </PanelLayout>
    )
}

const AdditionalManagers = ({
    profileIdStr,
    searchableProfiles,
    additionalManagers,
}: {
    profileIdStr: string
    searchableProfiles: Profile[]
    additionalManagers: Profile[]
}) => {
    const organisationIdStr = useOrganisationIdStr()
    const alert = useAlert()
    const profileSearchRef = useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useState('')
    const [panelOpen, setPanelOpen] = useState(false)
    const trackEvent = useAnalyticsEventTracker()

    const [addAdditionalManagers] = useMutation<AddAdditionalManagers, AddAdditionalManagersVariables>(
        ADD_ADDITIONAL_MANAGER,
        {
            update: (cache, {data}) => {
                if (!data) return
                const reportingLineData = cache.readQuery<ReportingLineData, ReportingLineDataVariables>({
                    query: REPORTING_LINE_DATA,
                    variables: {profileIdStr: profileIdStr!, organisationIdStr},
                })

                if (!reportingLineData || !data) return
                cache.writeQuery<ReportingLineData, ReportingLineDataVariables>({
                    query: REPORTING_LINE_DATA,
                    variables: {profileIdStr: profileIdStr!, organisationIdStr},
                    data: {
                        ...reportingLineData,
                        profile: {
                            ...reportingLineData.profile,
                            additionalManagers: data.updateAdditionalManagers,
                        },
                    },
                })

                const orgChartData = cache.readQuery<FullOrgChartData>({
                    query: FULL_ORG_CHART_DATA,
                    variables: {idStr: organisationIdStr},
                })
                if (!orgChartData) return

                cache.writeQuery<FullOrgChartData>({
                    query: FULL_ORG_CHART_DATA,
                    data: produce(orgChartData, (draftState) => {
                        draftState.organisation.profiles.forEach((profile) => {
                            const isManager = data.updateAdditionalManagers.includes(profile.idStr)
                            const isAlreadyAdditionalReport = profile.additionalReports.includes(profileIdStr)

                            if (!isManager && isAlreadyAdditionalReport) {
                                profile.additionalReports = profile.additionalReports.filter(
                                    (idStr) => idStr !== profileIdStr,
                                )
                            } else if (isManager && !isAlreadyAdditionalReport) {
                                profile.additionalReports = [...profile.additionalReports, profileIdStr]
                            }
                        })
                    }),
                })
            },
            onCompleted: () => {
                alert.success('Successfully updated additional managers')
            },
        },
    )

    const addAdditionalManager = async (newManagerIdStr: string) => {
        const managerIdStrs = [...additionalManagers.map((manager) => manager.idStr), newManagerIdStr]
        await addAdditionalManagers({
            variables: {
                organisationIdStr,
                profileIdStr: profileIdStr!,
                managerIdStrs,
            },
            optimisticResponse: {
                updateAdditionalManagers: managerIdStrs,
            },
        })

        trackEvent('orgCharts', {
            page: 'edit-profile',
            component: 'list',
            type: 'click',
            action: 'add',
            name: 'additional_manager',
        })
    }

    const removeAdditionalManager = async (managerIdStr: string) => {
        const managerIdStrs = additionalManagers
            .filter((additionalManager) => additionalManager.idStr !== managerIdStr)
            .map((manager) => manager.idStr)

        await addAdditionalManagers({
            variables: {
                organisationIdStr,
                profileIdStr: profileIdStr!,
                managerIdStrs,
            },
            optimisticResponse: {
                updateAdditionalManagers: managerIdStrs,
            },
        })

        trackEvent('orgCharts', {
            page: 'edit-profile',
            component: 'button',
            type: 'click',
            action: 'remove',
            name: 'additional_manager',
        })
    }

    const filteredProfiles = searchableProfiles.filter((profile) =>
        profile.fullName.toLowerCase().includes(searchQuery.toLowerCase()),
    )

    return (
        <>
            <TooltipTarget
                title="Profiles with additional managers are shown with a dotted line on the org chart"
                position="top"
            >
                <Typography.Label>Additional Managers</Typography.Label>
            </TooltipTarget>
            <Spacer height={8} />

            <Form.Group name="search">
                <div ref={profileSearchRef}>
                    <Form.SearchInput
                        value={searchQuery}
                        onChange={(value) => {
                            setSearchQuery(value)
                        }}
                        onFocus={() => {
                            setPanelOpen(true)
                        }}
                        onBlur={() => setPanelOpen(false)}
                    />
                </div>
            </Form.Group>
            <Panel targetRef={profileSearchRef} open={panelOpen} onClose={() => setPanelOpen(false)}>
                <PanelProfileList
                    setSearchQuery={setSearchQuery}
                    profiles={filteredProfiles}
                    onClickProfile={addAdditionalManager}
                />
            </Panel>

            <Spacer height={8} />
            {additionalManagers.map((manager) => (
                <AdditionalManager profile={manager} removeAdditionalManager={removeAdditionalManager} />
            ))}
        </>
    )
}

const useProfileMap = (profiles: Profile[]) => {
    return useMemo(() => {
        const profileIdStrMap: Record<string, Profile> = {}

        for (const profile of profiles) {
            profileIdStrMap[profile.idStr] = profile
        }

        return profileIdStrMap
    }, [profiles])
}

const validateReportingLine = ({
    managerIdStr,
    profile,
    profileMap,
}: {
    managerIdStr: string
    profile: EditProfile
    profileMap: Record<string, Profile>
}) => {
    let valid = true
    let manager: Profile | null = profileMap[managerIdStr] || null

    while (manager !== null) {
        if (manager.idStr === profile.idStr) {
            valid = false
            manager = null
            break
        }

        manager = manager.managerIdStr ? profileMap[manager.managerIdStr] : null
    }

    return valid
}

const EditPrimaryManager = ({
    searchableProfiles,
    profileMap,
    profile,
    getProfileByIdStr,
    trackClickEditManager,
}: {
    searchableProfiles: Profile[]
    profileMap: Record<string, Profile>
    profile: EditProfile
    getProfileByIdStr: (idStr: string) => Profile | undefined
    trackClickEditManager: () => void
}) => {
    const alert = useAlert()

    const profileSearchRef = useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useState('')
    const [panelOpen, setPanelOpen] = useState(false)

    const profileIdStr = profile.idStr
    const manager = profile.managerIdStr ? getProfileByIdStr(profile.managerIdStr) : null
    const originalPrimaryManager = profile.originalManagerIdStr ? getProfileByIdStr(profile.originalManagerIdStr) : null

    const filteredProfiles = searchableProfiles.filter((profile) => {
        if (profile.openPosition) {
            return (
                managerDisplayName(profile)
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                profile.fullName.toLowerCase().includes(searchQuery.toLowerCase())
            )
        }
        return profile.fullName.toLowerCase().includes(searchQuery.toLowerCase())
    })

    const editManagerActions = profile.managerIdStr || profile.managerEdited

    const {updateManager, resetManager} = useEditPrimaryManager(profileIdStr)

    const handleManagerUpdate = (managerIdStr: string) => {
        const valid = validateReportingLine({managerIdStr, profile, profileMap})

        if (!valid) {
            const manager = profileMap[managerIdStr]
            alert.error(`Reporting line error: ${profile.fullName} cannot report to ${manager.fullName}`)
            return
        }

        updateManager(managerIdStr, profile.originalManagerIdStr)
    }

    const inputValue = !panelOpen && manager ? managerDisplayName(manager) : searchQuery

    return (
        <>
            <Form.Group name="search">
                <div ref={profileSearchRef}>
                    <Form.Input
                        value={inputValue}
                        onChange={(value) => {
                            setSearchQuery(value)
                        }}
                        onFocus={(event) => {
                            event.target.setAttribute('autocomplete', 'off')
                            setPanelOpen(true)
                            trackClickEditManager()
                        }}
                        onBlur={() => setPanelOpen(false)}
                    />
                </div>
            </Form.Group>

            {editManagerActions && (
                <>
                    <Spacer height={16} />
                    {profile.managerIdStr && (
                        <Button
                            variant="link"
                            color="black"
                            style={{marginLeft: '3px'}}
                            onClick={() =>
                                updateManager(
                                    null,
                                    profile.managerEdited ? profile.originalManagerIdStr : profile.managerIdStr,
                                )
                            }
                        >
                            Remove Manager
                        </Button>
                    )}
                    {profile.managerEdited && originalPrimaryManager && (
                        <Button
                            variant="link"
                            color="black"
                            style={{marginLeft: '3px'}}
                            onClick={() => resetManager(originalPrimaryManager?.idStr || null)}
                        >
                            {`Reset to ${originalPrimaryManager?.fullName}`}
                        </Button>
                    )}
                </>
            )}

            <Panel targetRef={profileSearchRef} open={panelOpen} onClose={() => setPanelOpen(false)}>
                <PanelProfileList
                    setSearchQuery={setSearchQuery}
                    profiles={filteredProfiles}
                    onClickProfile={(managerIdStr) => handleManagerUpdate(managerIdStr)}
                />
            </Panel>
        </>
    )
}

export const ReportingLineTab = ({additionalProfile}: {additionalProfile: boolean}) => {
    const organisationIdStr = useOrganisationIdStr()
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const [profileIdStr] = useQueryParam<string>('editProfileId')

    const {data, loading} = useQuery<ReportingLineData, ReportingLineDataVariables>(REPORTING_LINE_DATA, {
        variables: {profileIdStr: profileIdStr!, organisationIdStr},
        fetchPolicy: 'cache-first',
    })

    const profileMap = useProfileMap(data?.profiles || [])

    const getProfileByIdStr = useCallback(
        (idStr: string) => {
            return profileMap[idStr] || null
        },
        [profileMap],
    )

    const additionalManagers = useMemo(() => {
        const managerIdStrs = data?.profile.additionalManagers || []

        return managerIdStrs.map((idStr) => getProfileByIdStr(idStr) as Profile)
    }, [data?.profile.additionalManagers, getProfileByIdStr])

    const [primaryDataLightboxOpen, setPrimaryDataLightboxOpen] = useState(false)

    if (loading || !data) return <PageLoading />

    const profile = data.profile

    const trackClickEditManager = () => {
        trackAnalyticsEvent('reportingLines', {
            name: 'click_edit_primary_manager',
            additionalProfile,
        })
    }

    const searchableProfiles = data.profiles
        .filter((searchableProfile) => {
            const managerIdStrs = [...profile.additionalManagers, profile.managerIdStr]
            return !(managerIdStrs.includes(searchableProfile.idStr) || profile.idStr === searchableProfile.idStr)
        })
        .sort((a, b) => a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase()))

    return (
        <>
            <Typography.Label>Primary Manager</Typography.Label>
            <Spacer height={8} />
            <EditPrimaryManager
                searchableProfiles={searchableProfiles}
                profileMap={profileMap}
                profile={profile}
                getProfileByIdStr={getProfileByIdStr}
                trackClickEditManager={trackClickEditManager}
            />
            <Spacer height={32} />

            <AdditionalManagers
                profileIdStr={profileIdStr!}
                searchableProfiles={searchableProfiles}
                additionalManagers={additionalManagers}
            />
            <Spacer height={24} />
            <AlertContainer>
                <Icon icon={SvgAlert} />
                <Spacer width={8} />
                <Typography.Paragraph style={{margin: 0}}>
                    Only Admins are able to edit reporting lines
                </Typography.Paragraph>
            </AlertContainer>
            <EditPrimaryDataLightbox
                open={primaryDataLightboxOpen}
                onClose={() => setPrimaryDataLightboxOpen(false)}
                additionalProfile={additionalProfile}
            />
            <ModalLayout.Footer />
        </>
    )
}
