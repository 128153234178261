import {useEffect, RefObject, useMemo} from 'react'

export type HandlerEvent = MouseEvent | TouchEvent

/**
 * Hook to check for outside click handles without adding more dom elements
 *
 * @export
 * @param {(RefObject<null | HTMLElement> | RefObject<null | HTMLElement>[])} ref element to check has been clicked outside of
 * @param {(event: HandlerEvent) => void} handler handler to callback if the target has been clicked outside of
 */
export function useOnClickOutside(
    ref: RefObject<HTMLElement | undefined | null> | RefObject<HTMLElement | undefined | null>[],
    handler: (event: HandlerEvent) => void,
) {
    const refs = useMemo(() => (Array.isArray(ref) ? ref : [ref]), [ref])
    useEffect(() => {
        const listener = (event: HandlerEvent) => {
            // Do nothing if clicking ref's element or descendent elements
            //@ts-ignore
            if (refs.some((r) => r && r.current && r.current.contains(event.target))) {
                return
            }

            handler(event)
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [refs, handler])
}
