import {Formik} from 'formik'
import {Button, Form, FormikInput, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React, {Dispatch, FC, SetStateAction} from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import {ADPSignInButton} from '../ADPSignInButton'

const loginSchema = yup.object({
    email: yup
        .string()
        .required()
        .email(),
})

const Container = styled.div`
    display: flex;
    width: 340px;
    justify-content: center;
`

const Line = styled.div`
    position: absolute;
    border-top: 1px solid #d5dbdf;
    height: 0px;
    width: 307px;
`

const Text = styled(Typography.Paragraph)`
    position: relative;
    top: -12px;

    > span {
        background: #fff;
        padding: 0 10px;
    }
`

const OrLine = () => {
    return (
        <Container>
            <Line />
            <Text>
                <span>OR</span>
            </Text>
        </Container>
    )
}

export type LoginProps = {
    email?: string
    setEmail: Dispatch<SetStateAction<string | undefined>>
    loading: boolean
}

export const LoginADPOrEmail: FC<LoginProps> = ({email = '', setEmail, loading}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    return (
        <>
            <Typography.Heading>Welcome to Names & Faces</Typography.Heading>
            <Spacer height={32} />
            <Formik
                onSubmit={({email}) => {
                    trackAnalyticsEvent('select_continue', {email})
                    setEmail(email)
                }}
                validationSchema={loginSchema}
                validateOnMount
                initialValues={{email}}
            >
                {({handleSubmit, isValid}) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group name="email">
                                <Form.Label>Sign in with email address</Form.Label>
                                <FormikInput autoFocus hideError />
                            </Form.Group>
                            <Button disabled={!isValid} type="submit" loading={loading}>
                                Continue
                                <Button.Icon icon={SvgArrowRight} />
                            </Button>
                        </Form>
                    )
                }}
            </Formik>
            <Spacer height={40} />
            <Spacer height={4} />
            <OrLine />
            <Spacer height={8} />
            <ADPSignInButton />
        </>
    )
}
