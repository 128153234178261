import {Button, ButtonProps} from 'nf-ui'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'
import React, {FC} from 'react'
import styled from 'styled-components'

const LinkButton = styled(Button)`
    bottom: 5px;
    padding-right: 14px;
`

export type BackButtonProps = Partial<ButtonProps>

export const BackButton: FC<BackButtonProps> = (props) => {
    return (
        <LinkButton variant="link" {...props}>
            <Button.Icon icon={SvgArrowLeft} />
        </LinkButton>
    )
}
