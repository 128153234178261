import {Theme} from 'nf-ui'
import React, {ButtonHTMLAttributes, ForwardRefExoticComponent, RefAttributes} from 'react'
import styled, {css, StyledComponent} from 'styled-components'
import {playground} from '~/pages/Playground'
import {CascadeHover} from '../CascadeHover'
import {Spinner} from '../Spinner'
import {blendWhite, colors, hexToRGB} from '../theme'
import {labelFontStyle} from '../Typography'
import {useAnalyticsEventTracker} from '../util'
import {ButtonIconContainer, Icon} from './Icon'

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'link' | 'red'

interface ContainerProps {
    variant: ButtonVariant
    organisationTheme?: Theme
    color?: string
    dimmed?: boolean
    isLoading: boolean
    disabledStyle: boolean
    transparent: boolean
}

const iconTint = (color: string) => css`
    ${ButtonIconContainer} {
        fill: ${color};
        transition: 0.25s fill ease-in-out;
    }
`

const Content = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${labelFontStyle}
`

const Container = styled.button<ContainerProps>`
    position: relative;
    border: none;
    height: 40px;
    border-radius: 3px;
    outline: 0;
    cursor: pointer;
    overflow: hidden;

    /* TODO: Use react spring here? */
    transition: 0.3s all ease-in-out;
    transform: translate3d(0, 0, 0);

    ${(props) =>
        props.variant !== 'link'
            ? css`
                  padding: 0 16px;
                  vertical-align: middle;
              `
            : css`
                  padding-left: 0px;
              `}
    ${(props) =>
        props.disabledStyle
            ? css`
                  cursor: not-allowed;
              `
            : ''}
`

export const ButtonSpinnerContainer = styled.div<{visible: boolean}>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: inherit;
    opacity: ${({visible}) => (visible ? 1 : 0)};
    pointer-events: ${({visible}) => (visible ? 'unset' : 'none')};
    transition: 0.25s opacity ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Containers: Record<ButtonVariant, StyledComponent<'button', any, ContainerProps, never>> = {
    primary: styled(Container)`
        background-color: ${({dimmed}) => blendWhite(colors.primary[100], dimmed ? 0.7 : 1)};
        color: ${colors.white};
        ${iconTint(colors.white)}

        ${(props) =>
            props.disabledStyle
                ? css`
                      background-color: ${colors.primary[35]};
                  `
                : css`
                      &:hover,
                      ${CascadeHover}:hover & {
                          background-color: ${colors.darkBlue};
                          box-shadow: 0 0 0 3px ${colors.primary[25]};
                      }

                      &:focus,
                      ${CascadeHover}:focus & {
                          box-shadow: 0 0 0 3px ${colors.primary[25]};
                      }
                  `}
    `,
    secondary: styled(Container)`
        background-color: ${colors.primary[10]};
        color: ${colors.primary[100]};
        ${iconTint(colors.primary[100])}

        ${(props) =>
            props.disabledStyle
                ? css`
                      background-color: ${colors.primary[6]};
                      color: ${colors.primary[35]};
                      ${iconTint(colors.primary[35])}
                  `
                : css`
                      &:hover,
                      ${CascadeHover}:hover & {
                          background-color: ${colors.primary[100]};
                          box-shadow: 0 0 0 3px ${colors.primary[25]};
                          color: ${colors.white};
                          ${iconTint(colors.white)}
                      }

                      &:focus,
                      ${CascadeHover}:focus & {
                          box-shadow: 0 0 0 3px ${colors.primary[25]};
                      }
                  `};

        ${(props) =>
            props.organisationTheme &&
            props.disabledStyle &&
            css`
                background-color: ${colors.white};
                color: ${props.organisationTheme.primary.color};
                opacity: 0.65;
                ${iconTint(props.organisationTheme.primary.color)}
            `}
        ${(props) =>
            props.organisationTheme &&
            !props.disabledStyle &&
            css`
                      background-color: ${colors.white};
                      color: ${props.organisationTheme.primary.color};
                      ${iconTint(props.organisationTheme.primary.color)}

                      &:hover,
                      ${CascadeHover}:hover & {
                          background-color: ${props.organisationTheme.primary.color};
                          box-shadow: 0 0 0 3px ${blendWhite(props.organisationTheme.primary.color, 0.25)};
                          color: ${props.organisationTheme.primary.textColor};
                          ${iconTint(props.organisationTheme.primary.textColor)}
                      }

                      &:focus,
                      ${CascadeHover}:focus & {
                          background-color: ${colors.white};
                          box-shadow: 0 0 0 3px ${blendWhite(props.organisationTheme.primary.color, 0.25)};
                          color: ${props.organisationTheme.primary.color};
                          ${iconTint(props.organisationTheme.primary.color)}
                      }
                  `}
    `,
    tertiary: styled(Container)`
        background-color: ${({transparent}) => (transparent ? 'transparent' : colors.white)};
        ${(props) => {
            const color = props.color || colors.primary[100]

            return css`
                color: ${color};
                ${iconTint(color)}
            `
        }};

        > *:not(${ButtonSpinnerContainer}) {
            opacity: ${(props) => (props.isLoading ? 0 : 1)};
            transition: 0.25s all ease-in-out;
        }

        ${(props) => {
            const disabledColor = blendWhite(props.color || colors.primary[100], 0.35)
            const hoverColor = props.color || colors.darkBlue

            return props.disabledStyle
                ? css`
                      color: ${disabledColor};
                      ${iconTint(disabledColor)}
                  `
                : css`
                      &:hover,
                      ${CascadeHover}:hover & {
                          color: ${hoverColor};
                          ${iconTint(hoverColor)}
                      }

                      &:focus & {
                          box-shadow: 0 0 0 3px ${props.color ? hexToRGB(props.color, '0.25') : colors.primary[25]};
                      }
                  `
        }}
    `,
    link: styled(Container)`
        height: initial;
        background-color: ${colors.transparent};
        color: ${({color}) => {
            switch (color) {
                case 'red':
                    return colors.red
                case 'black':
                    return colors.black
                default:
                    return colors.primary[100]
            }
        }};

        ${Content}{
        text-decoration: underline;
        font-weight: 300;
        }

        ${iconTint(colors.primary[100])}

        > *:not(${ButtonSpinnerContainer}) {
            opacity: ${(props) => (props.isLoading ? 0 : 1)};
            transition: .25s all ease-in-out;
        }

        ${(props) =>
            props.disabledStyle
                ? css`
                      color: ${colors.primary[35]};
                      ${iconTint(colors.primary[35])}
                  `
                : css`
                      &:hover,
                      &:focus,
                      ${CascadeHover}:hover &,
                      ${CascadeHover}:focus & {
                          color: ${props.color || colors.darkBlue};
                          ${iconTint(props.color || colors.darkBlue)}
                      }
                  `}
    `,
    red: styled(Container)`
        background-color: ${blendWhite(colors.red, 0.1)};
        color: ${colors.red};
        ${iconTint(colors.red)};

        > *:not(${ButtonSpinnerContainer}) {
            opacity: ${(props) => (props.isLoading ? 0 : 1)};
            transition: 0.25s all ease-in-out;
        }

        ${(props) => {
            const disabledColor = blendWhite(colors.red, 0.35)
            const hoverColor = colors.red

            return props.disabledStyle
                ? css`
                      color: ${disabledColor};
                      ${iconTint(disabledColor)}
                  `
                : css`
                      &:hover,
                      ${CascadeHover}:hover & {
                          color: ${hoverColor};
                          ${iconTint(hoverColor)}
                      }

                      &:focus & {
                          box-shadow: 0 0 0 3px ${hexToRGB(colors.red, '0.25')};
                      }
                  `
        }}
    `,
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: ButtonVariant
    loading?: boolean
    color?: string
    dimmed?: boolean
    organisationTheme?: Theme
    onClickAnalyticsEvent?: string
    onClickAnalyticsData?: Record<string, string | number | Record<any, any>>
    transparent?: boolean
}

interface ButtonType extends ForwardRefExoticComponent<ButtonProps & RefAttributes<HTMLButtonElement>> {
    Icon: typeof Icon
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            variant = 'primary',
            type = 'button',
            loading = false,
            disabled,
            onClick,
            onClickAnalyticsEvent,
            onClickAnalyticsData,
            transparent = true,
            ...rest
        },
        ref,
    ) => {
        const trackAnalyticsEvent = useAnalyticsEventTracker()

        const ContainerComponent = Containers[variant]

        const spinnerTint = variant === 'primary' ? colors.white : undefined

        return (
            <ContainerComponent
                data-testid="btnContainer"
                {...rest}
                onClick={(event) => {
                    if (onClick) {
                        if (onClickAnalyticsEvent) {
                            trackAnalyticsEvent(onClickAnalyticsEvent, onClickAnalyticsData)
                        }
                        onClick(event)
                    }
                }}
                type={type}
                variant={variant}
                ref={ref}
                isLoading={loading}
                disabled={disabled || loading}
                disabledStyle={!!disabled}
                transparent={transparent}
            >
                <ButtonSpinnerContainer data-testid="btnSpinner" visible={loading}>
                    <Spinner tint={spinnerTint} />
                </ButtonSpinnerContainer>
                <Content>
                    {React.Children.map(children, (child) => {
                        if (typeof child === 'string') {
                            // Place text nodes in spans so that we can use :first-child
                            // and :last-child to style Button.Icon left and right spacing.
                            // We need this because "these pseudo-classes ignore text nodes".
                            // See https://quirksmode.org/css/selectors/firstchild.html
                            return <span>{child}</span>
                        } else {
                            return child
                        }
                    })}
                </Content>
            </ContainerComponent>
        )
    },
) as ButtonType

Button.Icon = Icon

export {Button, Container as ButtonContainer}

playground.push({
    path: 'ui/Button/Button.tsx',
    component: Button,
    grow: 0,
    props: {
        children: ['Button'],
        variant: 'primary',
        type: 'button',
        loading: false,
        transparent: true,
    },
    propOptions: {
        text: {
            get: (props: any) => (props.children || ['Button'])[0],
            set: (props: any, value: string) => ({...props, children: [value]}),
        },
        variant: ['primary', 'secondary', 'tertiary', 'link'],
        type: ['button', 'submit', 'reset'],
        loading: [false, true],
        disabled: [undefined, false, true],
        transparent: [false, true],
        onClick: () => {},
    },
})
