import React from 'react'
import styled, {css, StyledComponentProps} from 'styled-components'
import {colors} from './theme'
import {TextAlignProperty} from 'csstype'
import {MOBILE_MEDIA_QUERY} from './media'

const linkStyle = css`
    a {
        color: ${colors.primary[100]};
        transition: color 250ms ease-in-out;
        &:hover {
            color: ${colors.darkBlue};
        }
    }
`

const maxWidth = css<{maxWidth?: number | 'auto'}>`
    max-width: ${(props) =>
        typeof props.maxWidth === 'number' ? `${props.maxWidth}px` : props.maxWidth === 'auto' ? 'auto' : 'unset'};
`

// See https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
const maxLines = css<{maxLines?: number}>`
    ${(props) => {
        if (!props.maxLines) return ''
        else if (props.maxLines === 1)
            return css`
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
            `
        return css`
            text-overflow: ellipsis;
            width: 100%;
            overflow-wrap: break-word;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: ${props.maxLines};
            -webkit-box-orient: vertical;
        `
    }}
`

export const textTransitionStyle = css`
    transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
`

export const labelFontStyle = css`
    font-size: 16px;
    line-height: 24px;
    font-family: 'halyard-display';
    font-weight: 500;
`

export const paragraphFontStyle = css`
    font-size: 16px;
    line-height: 24px;
    font-family: 'halyard-display';
    font-weight: 300;
`

const HeadingH1 = styled.h1`
    font-size: 40px;
    font-family: 'publico-banner';
    font-weight: 300;
    line-height: 56px;
    ${MOBILE_MEDIA_QUERY} {
        line-height: 48px;
    }
    margin: 0;
    color: ${colors.black};
    ${linkStyle}
    ${maxLines}
    transform: translateY(5px);
`

const Heading: React.FC<StyledComponentProps<'h1', any, {maxLines?: number}, never>> = (props) => (
    <div>
        <HeadingH1 {...props} />
    </div>
)

const Subheading = styled.h2`
    font-size: 24px;
    line-height: 36px;
    ${MOBILE_MEDIA_QUERY} {
        line-height: 32px;
    }
    font-family: 'halyard-display';
    font-weight: 200;
    margin: 0;
    color: ${colors.black};
    ${linkStyle}
    ${maxWidth}
`

const SectionHeading = styled.h3`
    height: 26px;
    color: ${colors.black};
    font-size: 22px;
    font-family: 'halyard-display';
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 26px;
    margin: 8px 0;
    ${linkStyle}
`

const Text = styled.span<{block?: boolean}>`
    display: ${(props) => (props.block ? 'block' : 'inline')};
    ${linkStyle}
`

Text.defaultProps = {
    block: false,
}

type LabelColor = 'black' | 'primary' | 'white'

export type ColorVariant = 'error' | 'disabled' | undefined

const Label = styled(Text)<{color?: LabelColor; variant?: ColorVariant}>`
    ${textTransitionStyle}
    ${labelFontStyle}
    color: ${({color = 'black', variant}) => {
        if (variant === 'error') return colors.red
        if (variant === 'disabled') return colors.darkGray
        else if (color === 'primary') return colors.primary[100]
        else return colors[color]
    }};
`

Label.defaultProps = {
    color: 'black',
}

type ParagraphStyleProps = {
    bottomMargin?: boolean
    maxWidth?: number | 'auto'
    variant?: ColorVariant
    maxLines?: number
    color?: LabelColor
    bold?: boolean
}

export const ParagraphStyle = css<ParagraphStyleProps>`
    ${paragraphFontStyle}
    font-weight: ${(props) => (props.bold ? 500 : 300)};
    margin: 0;
    margin-bottom: ${({bottomMargin = true}) => (bottomMargin ? `1rem` : '0')};
    color: ${({color = 'black', variant}) => {
        if (variant === 'error') return colors.red
        if (variant === 'disabled') return colors.darkGray
        else if (color === 'primary') return colors.primary[100]
        else return colors[color]
    }};
    ${linkStyle}
    ${maxLines}
    ${maxWidth}
`

const Paragraph = styled.p`
    ${ParagraphStyle}
`

const Meta = styled(Text)`
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    font-family: 'halyard-display';
    font-weight: 300;
`

type TinyColor = 'black' | 'red' | 'primary' | 'green' | 'white'
type TinyProps = {
    color?: TinyColor
    bold?: boolean
    maxLines?: number
    maxWidth?: number
    textAlign?: TextAlignProperty
}

const Tiny = styled(Text)<TinyProps>`
    font-size: 12px;
    line-height: 15px;
    font-family: 'halyard-display';
    font-weight: ${(props) => (props.bold ? 500 : 300)};
    color: ${({color = 'black'}) => {
        if (color === 'primary') return colors.primary[100]
        if (color === 'green') return colors.green[100]
        else return colors[color]
    }};
    text-align: ${(props) => props.textAlign || 'initial'};
    ${maxLines}
    ${maxWidth}
`

Tiny.defaultProps = {
    color: 'black',
}

export const Typography = {Heading, Subheading, Label, Paragraph, Meta, Tiny, SectionHeading}
