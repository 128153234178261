import {gql, useMutation} from '@apollo/client'
import {Formik} from 'formik'
import {Button, Form, FormikInput, Lightbox, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import SvgClose from 'nf-ui/Icons/Close'
import React, {FC, useState} from 'react'
import {useAlert} from 'react-alert'
import * as yup from 'yup'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {DataSourceRequestType} from '~/globalTypes'
import {DataSource} from './DataSource'
import {SuggestDataSource, SuggestDataSourceVariables} from './__types__/SuggestDataSource'

const SUGGEST_DATA_SOURCE = gql`
    mutation SuggestDataSource($dataSource: String!, $organisationIdStr: String!) {
        suggestDataSource(dataSource: $dataSource, organisationIdStr: $organisationIdStr)
    }
`

const dataSourceSchema = yup.object({
    dataSource: yup.string().required(),
})

type SuggestedDataSourceProps = {
    dataSource: DataSourceRequestType
}

export const SuggestedDataSource: FC<SuggestedDataSourceProps> = ({dataSource}) => {
    const [lightboxOpen, setLightboxOpen] = useState(false)
    const organisationIdStr = useOrganisationIdStr()
    const alert = useAlert()
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const [suggestDataSource, {loading}] = useMutation<SuggestDataSource, SuggestDataSourceVariables>(
        SUGGEST_DATA_SOURCE,
    )

    const handleSubmit = async (values: {dataSource: string}) => {
        await suggestDataSource({
            variables: {
                organisationIdStr,
                dataSource: values.dataSource,
            },
        })

        setLightboxOpen(false)
        alert.success('Your suggestion was successfully submitted.')
    }

    const onClose = () => {
        trackAnalyticsEvent('select_cancel')
        setLightboxOpen(false)
    }

    return (
        <>
            <DataSource
                enabled={true}
                buttonLabel=""
                dataSource={{
                    label: 'Suggest an integration',
                    loading: false,
                    icon: '',
                    onClick: () => {
                        setLightboxOpen(true)
                    },
                    analyticsEvent: 'select_suggest_integration',
                }}
            />
            <Lightbox onClose={onClose} open={lightboxOpen} width={720}>
                <div
                    data-testid="lightbox"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography.Heading>Suggest an integration</Typography.Heading>
                    <Button variant="tertiary" disabled={loading} onClick={() => setLightboxOpen(false)}>
                        Close <Button.Icon icon={SvgClose} />
                    </Button>
                </div>
                <Spacer height={16} />
                <Typography.Subheading>
                    Let us know which integration you would like to use and we will notify you by email as soon as it is
                    available.
                </Typography.Subheading>
                <Spacer height={32} />
                <Formik
                    isInitialValid={false}
                    onSubmit={(values) => {
                        handleSubmit(values)
                    }}
                    validationSchema={dataSourceSchema}
                    initialValues={{dataSource: ''}}
                >
                    {({handleSubmit, isSubmitting, isValid}) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group name="dataSource">
                                    <FormikInput autoFocus type="text" hideError />
                                </Form.Group>
                                <Button type="submit" disabled={!isValid} loading={isSubmitting}>
                                    Submit Suggestion
                                    <Button.Icon icon={SvgArrowRight} />
                                </Button>
                            </Form>
                        )
                    }}
                </Formik>
            </Lightbox>
        </>
    )
}
