import React, {useEffect, useMemo} from 'react'
import {useQueryParam} from 'use-query-params'
import {OAuthFailureMessage, OAuthSuccessMessage} from '~/components/useOAuthFlow/authWindow'
import {Typography} from '../../ui'

const SheetsAuth: React.FC = () => {
    const [code] = useQueryParam('code')
    const opener = useMemo(() => window.opener as Window | null, [])

    useEffect(() => {
        if (!opener) return
        if (typeof code === 'string') {
            const message: OAuthSuccessMessage = {
                type: 'OAuthSuccess',
                code,
            }
            opener.postMessage({...message}, window.location.origin)
        } else {
            const message: OAuthFailureMessage = {
                type: 'OAuthFailure',
            }
            opener.postMessage({...message}, window.location.origin)
        }
    }, [code, opener])

    if (!opener) {
        return <Typography.Heading>This route has to be opened from inside of the app</Typography.Heading>
    }

    return <Typography.Heading>Saving authentication...</Typography.Heading>
}

export default SheetsAuth
