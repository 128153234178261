import {colors, Icon, Spacer, Typography} from 'nf-ui'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'
import React, {useContext, useEffect, useState} from 'react'
import styled from 'styled-components'
import {useMemoOne} from 'use-memo-one'
import create from 'zustand'
import shallow from 'zustand/shallow'

interface State {
    panels: string[]
    itemId: string
    panelBeforeBackAction: string | null
}

type Open = (panel: string, {itemId, resetHistory}: {resetHistory?: boolean; itemId: string}) => void

type Actions = {
    openPanel: Open
    goBack: () => void
    closePanel: () => void
}

export const usePanelProps = (itemId: string) => {
    const store = usePanelStore()
    const [open, setOpen] = useState(false)
    const [panel, setPanel] = useState('')

    useEffect(() => {
        return store.api.subscribe<State>((state) => {
            if (!state) return
            setOpen(state.itemId === itemId)

            if (state.itemId === itemId) {
                setPanel(state.panels[state.panels.length - 1])
            } else {
                setPanel('')
            }
        })
    }, [itemId, store.api])

    return {open, panel}
}

export const usePanelNavigationProps = (panel: string) => {
    const store = usePanelStore()
    const panelBeforeBackAction = store.useStore((state) => state.panelBeforeBackAction)
    const panels = store.useStore((state) => state.panels)
    const goBack = store.useStore((state) => state.goBack)

    return {
        backButton: panelBeforeBackAction === panel || panels.indexOf(panel) > 0,
        goBack,
    }
}

const createStore = () =>
    create<State & Actions>((set, getState) => ({
        panels: [],
        itemId: '',
        panelBeforeBackAction: null,
        openPanel: (panel, {itemId, resetHistory}) => {
            const state = getState()
            set({panelBeforeBackAction: null})

            const isOpeningSamePanel = state.panels.length === 1 && panel === state.panels[0]
            if (isOpeningSamePanel && state.itemId === itemId) {
                set({panels: [], itemId: ''})
            } else if (resetHistory || state.itemId !== itemId) {
                set({panels: [panel], itemId: itemId})
            } else if (isOpeningSamePanel) {
                set({panels: [], itemId: ''})
            } else {
                set({panels: [...state.panels, panel], itemId})
            }
        },
        goBack: () => {
            const state = getState()
            const panelBeforeBackAction = state.panels[state.panels.length - 1]

            set({
                panelBeforeBackAction,
                panels: state.panels.slice(0, -1),
            })
        },
        closePanel: () => {
            set({
                panels: [],
                itemId: '',
                panelBeforeBackAction: null,
            })
        },
    }))

type Store = ReturnType<typeof createStore>
const Context = React.createContext<{useStore: Store[0]; api: Store[1]} | null>(null)

export const PanelNavigatorProvider: React.FC = ({children}) => {
    const store = useMemoOne(() => {
        const store = createStore()
        return {useStore: store[0], api: store[1]}
    }, [])

    useEffect(() => {
        return store.api.destroy // Do cleanup
    }, [store.api.destroy])

    return <Context.Provider value={store}>{children}</Context.Provider>
}

export const usePanelStore = () => {
    const store = useContext(Context)
    if (!store) throw new Error('No PanelNavigatorProvider found.')
    return store
}

export const usePanelNavigator = (itemId: string) => {
    const store = usePanelStore()
    const panelProps = usePanelProps(itemId)
    const functions = store.useStore(({openPanel, closePanel}) => ({openPanel, closePanel}), shallow)

    return {
        panelProps: {
            ...panelProps,
            onClose: functions.closePanel,
        },
        panel: panelProps.panel,
        ...functions,
    }
}

const Container = styled.div`
    padding: 16px;
    display: flex;
    align-items: center;
    ${Typography.Label} {
        flex: 1;
        margin-left: 0;
    }
`

export {Container as PanelHeaderContainer}

const BackButton = styled.button.attrs(() => ({
    type: 'button',
}))`
    border: 0;
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
    outline: 0;
    transition: 0.25s box-shadow ease-in-out;
    border-radius: 3px;
    background: ${colors.white};

    :focus {
        box-shadow: 0 0 0 2px ${colors.primary[25]};
    }
`

export const PanelHeader: React.FC<{panel: string}> = ({children, panel}) => {
    const {backButton, goBack} = usePanelNavigationProps(panel)

    return (
        <Container>
            {backButton && (
                <>
                    <BackButton onClick={goBack}>
                        <Icon icon={SvgArrowLeft} tint={colors.primary[100]} />
                    </BackButton>
                    <Spacer width={8} />
                </>
            )}
            <Typography.Label>{children}</Typography.Label>
        </Container>
    )
}
