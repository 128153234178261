import React, {InputHTMLAttributes, useRef, useState} from 'react'
import {ChromePicker} from 'react-color'
import styled from 'styled-components'
import {MIN_RATIO} from '../colorContrast'
import {Panel} from '../Panel'
import {useGroupContext} from './Group'
import {Input, InputLabel} from './Input'
import {useDelayedSet} from '../hooks/useDelayedSet'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`

const Preview = styled.div`
    width: 40px;
    height: 40px;
    background-color: blue;
    border-radius: 3px;
    margin-right: 16px;
    cursor: pointer;
    flex-shrink: 0;
`

export interface ColorPickerProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
    color: string
    onChange: (hex: string) => void
    error?: string
    value: string
    threshold?: number
    onClosePicker?: () => void
}

const InputContainer = styled.div`
    width: calc(100% - 40px - 16px);
`

const ColorPicker: React.FC<ColorPickerProps> = ({
    color,
    onChange,
    width,
    error,
    threshold = MIN_RATIO,
    onClosePicker,
    ...props
}) => {
    const [open, setOpen] = useState(false)
    const {name} = useGroupContext()
    const targetRef = useRef<HTMLDivElement>(null)
    //@ts-ignore
    const [curColor, curOnChange] = useDelayedSet(color, onChange)
    const onPanelClose = () => {
        setOpen(false)
        onClosePicker && onClosePicker()
    }

    return (
        <div>
            <Container style={{width}}>
                <Preview style={{backgroundColor: curColor}} onClick={() => setOpen((o) => !o)} ref={targetRef} />
                <Panel targetRef={targetRef} open={open} onClose={onPanelClose} noPortal>
                    <ChromePicker color={curColor} onChange={({hex}) => curOnChange(hex)} disableAlpha />
                </Panel>
                <InputContainer>
                    <Input
                        data-testid="colorpicker-input"
                        type="text"
                        name={name}
                        onChange={(value) => onChange(value)}
                        {...props}
                    />
                </InputContainer>
            </Container>
            {error && <InputLabel color="red">{error}</InputLabel>}
        </div>
    )
}

export {ColorPicker}
