import {Button, Spacer, Typography} from 'nf-ui'
import React, {ErrorInfo} from 'react'
import {WizardLayout} from './WizardLayout'

export const ErrorFallback: React.FC<{error: Error; info: ErrorInfo; clearError: () => void}> = ({
    error,
    info,
    clearError,
}) => {
    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <Typography.Heading>Something went wrong</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading>The error has been reported.</Typography.Subheading>
                <Spacer height={32} />
                {clearError && (
                    <Button variant="primary" onClick={clearError}>
                        Try again
                    </Button>
                )}
            </WizardLayout.Body>
        </WizardLayout>
    )
}
