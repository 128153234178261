import {AnimatePresence, motion, Variants} from 'framer-motion'
import React, {FC, Key} from 'react'
import styled, {css} from 'styled-components'
import {Icon} from '../Icon'
import {Spacer} from '../Spacer'
import {Typography} from '../Typography'
import {colors} from '../theme'
import {hideVariants, HoverLabel, ListContainer} from './shared'
import {AccordionData} from './types'
import SvgChevronDown from '../Icons/ChevronDown'
import {useTheme} from '../NFThemeProvider'
import {TooltipTarget} from '../Tooltip'
import {useAnalyticsEventTracker} from '../util'

const ItemContainer = styled.div<{disabled: boolean}>`
    color: ${colors.black};
    padding: 8px 0;
    width: 100%;
    outline: 0;
    cursor: pointer;
    transition: 0.25s opacity ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${(props) =>
        props.disabled
            ? css`
                  opacity: 0.4;
                  cursor: not-allowed;
              `
            : css`
                  :focus,
                  :hover {
                      opacity: 0.8;
                  }
              `}
`

const Label = styled(Typography.Paragraph)`
    color: ${colors.black};
    flex: 1;
`

const itemChildrenVariants: Variants = {
    open: {
        transition: {duration: 0.5},
        height: 'auto',
    },
    closed: {
        transition: {duration: 0.5},
        height: 0,
    },
}

const caretTransition = {
    type: 'spring',
    stiffness: 90,
    damping: 90,
    mass: 0.5,
}

const caretVariants: Variants = {
    open: {
        transition: caretTransition,
        rotateZ: 180,
    },
    closed: {
        transition: caretTransition,
        rotateZ: 0,
    },
}

const Gradient = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, ${colors.lightGray} 100%);
    height: 12px;
    pointer-events: none;
`

const MotionChildren = styled(motion.div)`
    overflow: hidden;
    position: relative;
`

MotionChildren.defaultProps = {
    variants: itemChildrenVariants,
    initial: 'closed',
    animate: 'open',
    exit: 'closed',
}

export type AccordionWrapperProps<T = any> = {expanded?: boolean; selected?: boolean} & Pick<
    AccordionData<T>,
    'data' | 'id'
>

export type AccordionItemProps = Pick<
    AccordionData,
    'label' | 'id' | 'data' | 'hoverLabel' | 'icon' | 'selectedIcon' | 'disabled'
> & {
    onSelect: (id: Key) => void
    expanded?: boolean
    selected?: boolean
    hasChildren?: boolean
    wrapper?: FC<AccordionWrapperProps>
    indentation?: number
}

const DefaultWrapper: FC<AccordionWrapperProps> = ({children}) => <>{children}</>

const IconPadded = styled(Icon)`
    margin-top: 4px;
`

const DisabledTooltip: React.FC<{disabled: AccordionData['disabled']; children: React.ReactElement}> = ({
    disabled,
    children,
}) => {
    if (typeof disabled === 'string') {
        return (
            <TooltipTarget position="right" title={disabled} maxWidth={144}>
                {children}
            </TooltipTarget>
        )
    }
    return <>{children}</>
}

export const AccordionItem: FC<AccordionItemProps> = ({
    children,
    expanded,
    id,
    label,
    onSelect,
    selected,
    hasChildren,
    icon,
    selectedIcon,
    hoverLabel,
    data,
    wrapper: Wrapper = DefaultWrapper,
    indentation = 0,
    disabled = false,
}) => {
    const displayIcon = selected && selectedIcon ? selectedIcon : icon
    const theme = useTheme()
    const color = selected ? theme.primary.color : colors.black

    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const onClick = disabled
        ? undefined
        : () => {
              data?.analyticsEvent && trackAnalyticsEvent(data.analyticsEvent)
              onSelect(id)
          }

    return (
        <div onClick={(event) => event.stopPropagation()}>
            <Wrapper expanded={expanded} data={data} id={id} selected={selected}>
                <DisabledTooltip disabled={disabled}>
                    <ItemContainer data-testid="accordion-itemcontainer" onClick={onClick} disabled={!!disabled}>
                        {!!indentation && <Spacer width={indentation as any} />}
                        {displayIcon && (
                            <>
                                <Icon icon={displayIcon} tint={color} />
                                <Spacer width={16} />
                            </>
                        )}
                        <Label bottomMargin={false} bold={selected} style={{color}}>
                            {label}
                        </Label>
                        {!hasChildren && hoverLabel != null && <HoverLabel>{hoverLabel}</HoverLabel>}
                        <motion.div initial={false} animate={expanded ? 'open' : 'closed'} variants={caretVariants}>
                            {hasChildren && <IconPadded icon={SvgChevronDown} />}
                        </motion.div>
                    </ItemContainer>
                </DisabledTooltip>
            </Wrapper>

            <AnimatePresence initial={false}>
                {expanded && (
                    <ListContainer>
                        <motion.div variants={hideVariants}>
                            {children}
                            <Gradient />
                        </motion.div>
                    </ListContainer>
                )}
            </AnimatePresence>
        </div>
    )
}
