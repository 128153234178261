import {gql, useLazyQuery} from '@apollo/client'
import React, {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {FullProfileData_profile} from '../FullProfile/__types__/FullProfileData'
import {
    RecentlyViewedProfiles,
    RecentlyViewedProfilesVariables,
    RecentlyViewedProfiles_profiles,
} from './__types__/RecentlyViewedProfiles'

const DATA_KEY = 'VIEWED_PROFILES'

type Profile = RecentlyViewedProfiles_profiles

type ViewedProfiles = {
    [organisationIdStr: string]: string[]
}

const RECENTLY_VIEWED_PROFILES = gql`
    query RecentlyViewedProfiles($organisationIdStr: String!, $profileIdStrs: [String!]!) {
        profiles(organisationIdStr: $organisationIdStr, profileIdStrs: $profileIdStrs) {
            idStr
            firstName
            lastName
            fullName
            secondaryLine
            photos {
                idStr
                thumbUrl
                priority
            }
        }
    }
`

const ViewedProfilesContext = createContext<{
    add: (profile: FullProfileData_profile) => void
    reset: () => void
    profiles: Profile[]
} | null>(null)

export const ViewedProfilesProvider: React.FC = ({children}) => {
    const organisationIdStr = useOrganisationIdStr()

    const loadedOrganisation = useRef<string | null>(null)
    const [viewedIdStrs, setViewedIdStrsState] = useState<ViewedProfiles | null>(null)
    const [currentProfiles, setCurrentProfiles] = useState<RecentlyViewedProfiles_profiles[] | null>(null)

    const storeCurrentProfiles = useCallback(
        (data: RecentlyViewedProfiles) => {
            if (!viewedIdStrs || !loadedOrganisation.current) return

            const profileIdStrs = viewedIdStrs[loadedOrganisation.current] || []

            const profiles: RecentlyViewedProfiles_profiles[] = profileIdStrs
                .map((idStr) => data.profiles.find((profile) => profile.idStr === idStr))
                .filter((profile) => !!profile) as Profile[]

            setCurrentProfiles(profiles)
        },
        [viewedIdStrs],
    )

    const [fetchProfiles] = useLazyQuery<RecentlyViewedProfiles, RecentlyViewedProfilesVariables>(
        RECENTLY_VIEWED_PROFILES,
        {
            onCompleted: (data) => {
                storeCurrentProfiles(data)
            },
        },
    )

    const fetchRecentlyViewed = useCallback(
        async (organisationIdStr: string) => {
            let data = viewedIdStrs

            if (!viewedIdStrs) {
                data = await getStoredData()
                setViewedIdStrsState(data)
            }

            fetchProfiles({
                variables: {
                    organisationIdStr,
                    profileIdStrs: data?.[organisationIdStr] || [],
                },
            })
        },
        [fetchProfiles, viewedIdStrs],
    )

    useEffect(() => {
        if (organisationIdStr !== loadedOrganisation.current) {
            loadedOrganisation.current = organisationIdStr
            setCurrentProfiles(null)
            fetchRecentlyViewed(organisationIdStr)
        }
    }, [fetchRecentlyViewed, organisationIdStr])

    const setViewedIdStrs = useCallback((newState: ViewedProfiles) => {
        setViewedIdStrsState(newState)
        localStorage.setItem(DATA_KEY, JSON.stringify(newState))
    }, [])

    const pushProfile = useCallback(
        (profile: FullProfileData_profile) => {
            const newProfile = {
                __typename: 'ProfileObject' as const,
                idStr: profile.idStr,
                fullName: profile.fullName,
                firstName: profile.firstName,
                lastName: profile.lastName,
                secondaryLine: profile.profileLines[0]?.value || '',
                photos: profile.photos,
            }

            let newProfiles = viewedIdStrs?.[organisationIdStr] || []
            newProfiles = newProfiles.filter((viewedProfile) => viewedProfile !== profile.idStr)

            newProfiles = [newProfile.idStr, ...newProfiles].slice(0, 15)

            setViewedIdStrs({
                ...viewedIdStrs,
                [organisationIdStr]: newProfiles,
            })

            setCurrentProfiles([
                newProfile,
                ...(currentProfiles?.filter((profile) => profile.idStr !== newProfile.idStr).slice(0, 14) || []),
            ])
        },
        [currentProfiles, organisationIdStr, setViewedIdStrs, viewedIdStrs],
    )

    const add = useCallback(
        (profile: FullProfileData_profile) => {
            if (!loadedOrganisation.current || !viewedIdStrs) return

            pushProfile(profile)
        },
        [viewedIdStrs, pushProfile],
    )

    const reset = useCallback(() => {
        setViewedIdStrs({})
    }, [setViewedIdStrs])

    return (
        <ViewedProfilesContext.Provider value={{profiles: currentProfiles || [], add, reset}}>
            {children}
        </ViewedProfilesContext.Provider>
    )
}

const getStoredData = async () => {
    const storedData = localStorage.getItem(DATA_KEY)

    if (storedData) {
        return JSON.parse(storedData)
    }
    return {}
}

export function useViewedProfiles() {
    const context = useContext(ViewedProfilesContext)
    if (!context) throw new Error('useViewedProfiles used outisde of ViewedProfilesProvider')
    return context
}

export const useTrackProfileView = (profile?: FullProfileData_profile) => {
    const {add} = useViewedProfiles()

    const addRef = useRef(add)
    useEffect(() => {
        addRef.current = add
    }, [add])

    useEffect(() => {
        if (profile) addRef.current(profile)
    }, [profile])
}
