import {gql, useQuery} from '@apollo/client'
import {FadePresence} from 'nf-ui'
import React, {FC, useMemo, useState} from 'react'
import {useAlert} from 'react-alert'
import {Redirect} from 'react-router-dom'
import {Token} from '~/components/TokenContext'
import {WelcomeLaout} from '~/components/WelcomeLayout'
import {getGraphQLErrorMessage} from '~/util'
import {HandleSignInOption} from './Login/HandleSignInOption'
import {LoginADPOrEmail} from './Login/LoginADPOrEmail'
import {SignInOptions} from './Login/SignInOptions'
import {
    Login_SignInOptions,
    Login_SignInOptionsVariables,
    Login_SignInOptions_signInOptions,
} from './__types__/Login_SignInOptions'

export const SIGN_IN_OPTIONS = gql`
    query Login_SignInOptions($email: String!) {
        signInOptions(email: $email) {
            type
            name
            icon
            public_config {
                scope
                client_id
                authorize_uri
                response_type
            }
        }
    }
`

export type SignInOption = Login_SignInOptions_signInOptions

export const Login: FC = () => {
    const {token} = Token.useContainer()

    const [email, setEmail] = useState<string | undefined>()
    const alert = useAlert()
    const {data, loading} = useQuery<Login_SignInOptions, Login_SignInOptionsVariables>(SIGN_IN_OPTIONS, {
        skip: !email,
        variables: {email: email!},
        fetchPolicy: 'network-only',
        onError: (error) => alert.error(getGraphQLErrorMessage(error)),
    })

    const signInOptions = data?.signInOptions ?? []

    const [selectedSignInOption, setSelectedSignInOption] = useState<SignInOption | undefined>()
    const singleSignInOption = signInOptions?.length === 1 ? signInOptions[0] : undefined
    const signInOption = selectedSignInOption ?? singleSignInOption

    const content = useMemo(() => {
        if (signInOption) return 'handleSignInOption'
        if (signInOptions.length) return 'signInOptions'

        return 'email'
    }, [signInOption, signInOptions.length])

    if (token) return <Redirect to="/" />

    const goBack = () => {
        if (content === 'handleSignInOption' && selectedSignInOption) {
            setSelectedSignInOption(undefined)
        } else {
            setEmail(undefined)
        }
    }

    return (
        <WelcomeLaout>
            <FadePresence exitBeforeEnter itemKey={content}>
                {content === 'email' ? (
                    <LoginADPOrEmail email={email} setEmail={setEmail} loading={loading} />
                ) : content === 'handleSignInOption' ? (
                    <HandleSignInOption option={signInOption!} email={email!} onClickBack={goBack} />
                ) : content === 'signInOptions' ? (
                    <SignInOptions
                        options={signInOptions!}
                        onClickBack={goBack}
                        onClickOption={setSelectedSignInOption}
                    />
                ) : null}
            </FadePresence>
        </WelcomeLaout>
    )
}
