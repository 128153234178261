import {gql, useQuery} from '@apollo/client'
import {FadePresence, Spacer, Tabs, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgAlert from 'nf-ui/Icons/Alert'
import React, {useState} from 'react'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {PageLoading} from '~/components/PageLoading'
import {RoleType} from '~/globalTypes'
import {getGraphQLErrorMessage} from '~/util'
import {AdditionalFieldsTab} from './AdditionalFieldsTab'
import {PrimaryFieldsTab} from './PrimaryFieldsTab'
import {ProfilePictureTab} from './ProfilePictureTab'
import {ReportingLineTab} from './ReportingLineTab'
import {useHiddenFields} from './useHiddenFields'
import {EditProfileData, EditProfileDataVariables} from './__types__/EditProfileData'
import {orderPhotos} from '~/components/OrganisationContext'

const TabsContainer = styled.div`
    width: 304px;
`

export const PROFILE_DATA = gql`
    query EditProfileData($idStr: String!) {
        profile(idStr: $idStr) {
            idStr
            fullName
            canEditProfilePicture
            additional
            openPosition
            openPositionJobLink
            baseDirectoryIdStr
            photos {
                idStr
                profileUrl
                originalUrl
                tinyUrl
                thumbUrl
                priority
            }
            pendingPhoto {
                idStr
                profileUrl
            }
            primaryFields {
                idStr
                label
                value
                type
                extraData {
                    hiddenFromProfile
                    fieldIdStr
                    hideable
                    options {
                        idStr
                        label
                    }
                }
            }
            additionalFields {
                idStr
                name
                value
                options {
                    idStr
                    name
                    selected
                }
                type
                helpText
                extraData {
                    hiddenFromProfile
                    fieldIdStr
                    hideable
                }
            }
        }
    }
`

export type EditTabOption = 'primaryFields' | 'profilePicture' | 'additionalFields' | 'reportingLine'

type EditProfileTab = {
    name: EditTabOption
    label: string
    admin?: boolean
    analyticsEvent?: {
        name: string
        event: string
    }
}

const tabIndexMap: EditProfileTab[] = [
    {
        name: 'profilePicture',
        label: 'Profile picture',
    },
    {
        name: 'primaryFields',
        label: 'Primary fields',
    },
    {
        name: 'additionalFields',
        label: 'Additional fields',
    },
    {
        name: 'reportingLine',
        label: 'Reporting Line',
        admin: true,
        analyticsEvent: {
            event: 'reportingLines',
            name: 'click_reporting_lines_tab',
        },
    },
]

export const EditProfile = ({
    onDoneEditing,
    initialTab = 'profilePicture',
}: {
    onDoneEditing: () => void
    initialTab?: EditTabOption
}) => {
    const [editProfileId] = useQueryParam<string>('editProfileId')
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    if (!editProfileId) throw new Error('Missing editProfileId')
    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const isAdmin = currentOrganisation?.currentUserRoles.includes(RoleType.Manage)

    const {setInitialHiddenFields} = useHiddenFields()

    const [activeTabName, setActiveTabName] = useState<string | undefined>(initialTab)

    const {data, error, loading} = useQuery<EditProfileData, EditProfileDataVariables>(PROFILE_DATA, {
        variables: {idStr: editProfileId},
        fetchPolicy: 'no-cache',
    })

    if (data) setInitialHiddenFields([...data.profile.primaryFields, ...data.profile.additionalFields], editProfileId)

    const visibleTabs = tabIndexMap.filter((tab) => {
        if (tab.name === 'additionalFields') {
            return data && data.profile.additionalFields.length > 0
        }
        if (tab.admin === true) return isAdmin

        return true
    })

    const activeTab = visibleTabs.find((visibleTab) => visibleTab.name === activeTabName)

    const activeTabIs = (tabName: EditTabOption) => {
        return activeTab && activeTab.name === tabName
    }

    if (loading || !data) return <PageLoading />
    if (error) return <Typography.Heading>Error: {getGraphQLErrorMessage(error)}</Typography.Heading>

    const {primaryFields = [], additionalFields = [], canEditProfilePicture = true, additional = false} = data.profile

    const changeActiveTab = (tab: EditProfileTab) => {
        setActiveTabName(tab.name)

        if (tab.analyticsEvent)
            trackAnalyticsEvent(tab.analyticsEvent.event, {
                name: tab.analyticsEvent.name,
            })
    }

    const getProfileName = () => {
        const suffix = data?.profile.fullName.endsWith('s') ? '' : 's'
        return `${data?.profile.fullName}'${suffix}`
    }

    const pageTitle =
        currentOrganisation?.editableProfile?.idStr === editProfileId
            ? 'Edit profile'
            : `Edit ${getProfileName()} profile`

    if (!activeTab) return null

    return (
        <>
            <Typography.Heading>{pageTitle}</Typography.Heading>
            <Spacer height={16} />
            <Tabs
                activeTabIndex={visibleTabs.indexOf(activeTab)}
                onChangeActiveTabIndex={(index) => changeActiveTab(visibleTabs[index])}
            >
                {visibleTabs.map((tab, index) => (
                    <Tabs.Tab key={index} icon={tab.admin ? SvgAlert : undefined}>
                        {tab.label}
                    </Tabs.Tab>
                ))}
            </Tabs>
            <Spacer height={32} />

            <TabsContainer>
                <FadePresence itemKey={activeTab.name} exitBeforeEnter>
                    {activeTabIs('profilePicture') && (
                        <ProfilePictureTab
                            currentPhoto={orderPhotos(data.profile.photos)[0]?.profileUrl}
                            pendingPhoto={data.profile.pendingPhoto?.profileUrl}
                            canEditProfilePicture={canEditProfilePicture}
                        />
                    )}
                    {activeTabIs('primaryFields') && (
                        <PrimaryFieldsTab
                            primaryFields={primaryFields}
                            profile={data.profile}
                            onDoneEditing={onDoneEditing}
                        />
                    )}
                    {activeTabIs('additionalFields') && <AdditionalFieldsTab additionalFields={additionalFields} />}
                    {activeTabIs('reportingLine') && <ReportingLineTab additionalProfile={additional} />}
                </FadePresence>
            </TabsContainer>
        </>
    )
}
