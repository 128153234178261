import {AnimatePresence, motion} from 'framer-motion'
import {Stepper} from 'nf-ui'
import React, {FC, useEffect} from 'react'
import {NumberParam, useQueryParam} from 'use-query-params'
import {ModalLayout} from '~/components/ModalLayout'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {CollectStep} from './AddAdditionalDataModal/CollectStep'
import {CreateStep} from './AddAdditionalDataModal/CreateStep'

export const AddAdditionalDataModal: FC = () => {
    const {onClose} = useModalNavigator()

    const [currentStepIndex = 0, setCurrentStepIndex] = useQueryParam('step', NumberParam)
    useEffect(() => {
        return () => setCurrentStepIndex(null)
    }, [setCurrentStepIndex])

    return (
        <ModalLayout
            footerProps={{
                isOnboarding: false,
                onClickBack: () => setCurrentStepIndex(0),
                back: currentStepIndex > 0,
            }}
        >
            <ModalLayout.Navigation onClose={onClose}>
                <Stepper currentStepIndex={currentStepIndex}>
                    <Stepper.Step label="Create"></Stepper.Step>
                    <Stepper.Step label="Collect"></Stepper.Step>
                </Stepper>
            </ModalLayout.Navigation>
            <AnimatePresence exitBeforeEnter>
                <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} key={currentStepIndex}>
                    {currentStepIndex === 0 && <CreateStep onClickNext={() => setCurrentStepIndex(1)} />}
                    {currentStepIndex === 1 && <CollectStep />}
                </motion.div>
            </AnimatePresence>
        </ModalLayout>
    )
}
