import {colors, Form, Icon, Spacer, TooltipTarget} from 'nf-ui'
import SvgCheck from 'nf-ui/Icons/Check'
import React from 'react'
import styled from 'styled-components'
import {Column, Row} from '~/pages/Adminland/PhotoCollection/ListComponents'
import {ProfileSelectState} from './useProfileSelect'

const NameContainer = styled.div`
    flex: 1;
`

type ProfileRowProps<ProfileType> = {
    profile: ProfileType
    multiselect: ProfileSelectState<ProfileType>
    responded?: boolean
}

const ProfileRow = function<ProfileType extends {idStr: string; fullName: string}>({
    profile,
    multiselect,
    responded,
}: ProfileRowProps<ProfileType>) {
    const {checkedProfiles, toggle} = multiselect

    return (
        <Row data-testid={`profile_row_${profile.idStr}`} onClick={() => toggle(profile.idStr)}>
            <Column>
                <Form.Group name="name">
                    <Form.Checkbox checked={checkedProfiles.includes(profile.idStr)} readOnly />
                </Form.Group>
                <Spacer width={16} />

                <NameContainer>{profile.fullName}</NameContainer>

                {responded && (
                    <TooltipTarget position="right" title="This user has responded to all" maxWidth={160}>
                        <Icon icon={SvgCheck} tint={colors.green[100]} />
                    </TooltipTarget>
                )}
            </Column>
        </Row>
    )
}

const Memoized = React.memo(ProfileRow) as typeof ProfileRow

export {Memoized as ProfileRow}
