import {AnimatePresence, motion} from 'framer-motion'
import {Button, Spacer, Tabs} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React, {useState} from 'react'
import styled from 'styled-components'
import {gridSizeFor, ProfilesGrid} from '~/components/ProfilesGrid/ProfilesGrid'
import {ProfilesGridSizer} from '~/components/ProfilesGridLayout/ProfilesGridSizer'
import {useRelativeRoute} from '~/components/useRelativeRoute'
import {
    HomeData_organisation_smartGroups,
    HomeData_organisation_smartGroups_profiles_profile,
} from '../__types__/HomeData'
import {useViewedProfiles} from './useViewedProfiles'
import {OrganisationDataWithTypes} from '~/components/OrganisationContext'

const SmartGroupHeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export function profilesFor(smartGroup: HomeData_organisation_smartGroups) {
    const profiles: HomeData_organisation_smartGroups_profiles_profile[] = []
    const secondaryLines: Record<string, string | null> = {}

    for (const smartGroupProfile of smartGroup.profiles) {
        profiles.push(smartGroupProfile.profile)
        secondaryLines[smartGroupProfile.profileIdStr] = smartGroupProfile.secondaryLine
    }

    return profiles.map((profile) => ({...profile, secondaryLine: secondaryLines[profile.idStr]}))
}

const recentlyViewed = {
    __typename: 'SmartGroup' as const,
    idStr: 'recentlyViewed',
    label: 'Recently Viewed',
}

export const SmartGroups: React.FC<{
    smartGroups: HomeData_organisation_smartGroups[]
    organisationId: string
    data: OrganisationDataWithTypes
}> = ({smartGroups: smartGroupsProp, organisationId, data}) => {
    const [activeIdStr, setActiveIdStr] = useState<string | undefined>()

    const {profiles: viewedProfiles} = useViewedProfiles()

    const {pushRelative} = useRelativeRoute(`/${organisationId}`)

    const smartGroups = [...smartGroupsProp]
    if (viewedProfiles.length > 0) {
        smartGroups.push({
            ...recentlyViewed,
            weeklyCount: 0,
            profiles: viewedProfiles.map((profile) => ({
                __typename: 'SmartGroupProfile',
                profileIdStr: profile.idStr,
                secondaryLine: profile.secondaryLine,
                profile,
            })),
        })
    }

    const activeSmartGroup = smartGroups.find((smartGroup) => smartGroup.idStr === activeIdStr) || smartGroups[0]

    if (!activeSmartGroup) return null

    const profiles = profilesFor(activeSmartGroup)

    const onChangeTab = (idStr: string) => {
        setActiveIdStr(idStr)
    }

    return (
        <ProfilesGridSizer disableHeight>
            {({width}) => (
                <>
                    <SmartGroupHeadingContainer style={{width: gridSizeFor(width).contentWidth}}>
                        <Tabs
                            key={smartGroups.length}
                            activeTabIndex={smartGroups.indexOf(activeSmartGroup)}
                            onChangeActiveTabIndex={(index) => onChangeTab(smartGroups[index].idStr)}
                        >
                            {smartGroups.map((smartGroup) => (
                                <Tabs.Tab key={smartGroup.idStr}>{smartGroup.label}</Tabs.Tab>
                            ))}
                        </Tabs>
                        <Button
                            style={{flexShrink: 0}}
                            variant="tertiary"
                            onClick={() =>
                                pushRelative(`/smartGroup/${activeSmartGroup.idStr}`, {
                                    keepSearch: true,
                                })
                            }
                        >
                            {activeSmartGroup.idStr !== 'recentlyViewed' && (
                                <>
                                    <span>View all {activeSmartGroup.label}</span>
                                    <Button.Icon icon={SvgArrowRight} />
                                </>
                            )}
                        </Button>
                    </SmartGroupHeadingContainer>
                    <Spacer height={24} />
                    <div style={{margin: -3}}>
                        <AnimatePresence exitBeforeEnter initial={false}>
                            <motion.div
                                key={activeSmartGroup.idStr}
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                            >
                                <ProfilesGrid
                                    width={width}
                                    padding={3}
                                    data={{...data, profiles}}
                                    rowCount={1}
                                    sort={false}
                                />
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </>
            )}
        </ProfilesGridSizer>
    )
}
