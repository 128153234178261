import {FullOrgChartData_organisation_profiles} from '../__types__/FullOrgChartData'

export type Profile = FullOrgChartData_organisation_profiles

export const HEADER_HEIGHT = 72
export const PROFILE_ROW_HEIGHT = 26
export const PAGE_PADDING = 48
export const REPORT_COUNT_SIZE = 10
export const REPORT_COUNT_OFFSET = 8.5
export const GRID_COLUMNS = 3
export const GUTTER = 2
export const PROFILE_PICTURE = 16
export const PROFILE_ROW_PADDING = (PROFILE_ROW_HEIGHT - PROFILE_PICTURE) / 2

const INDENTATION = 55
const INDENTATION_SMALL = 40

export const getIndentation = (maxOffset: number) => (maxOffset <= 4 ? INDENTATION : INDENTATION_SMALL)

export const PAGE_ORIENTATIONS = ['landscape', 'portrait'] as const
export type Orientation = typeof PAGE_ORIENTATIONS[number]

const PORTRAIT_HEIGHT = 792
const LANDSCAPE_HEIGHT = 612

export const MAX_PAGE_HEIGHT: Record<Orientation, number> = {
    portrait: PORTRAIT_HEIGHT - PAGE_PADDING,
    landscape: LANDSCAPE_HEIGHT - PAGE_PADDING,
}

export const maxRowsFor = ({orientation, page}: {orientation: Orientation; page: number}) => {
    const verticalOffset = page === 1 ? HEADER_HEIGHT : 0
    const pageHeight = orientation === 'portrait' ? PORTRAIT_HEIGHT : LANDSCAPE_HEIGHT

    return Math.floor((pageHeight - verticalOffset - 2 * PAGE_PADDING) / (PROFILE_ROW_HEIGHT + GUTTER))
}

export const LINE_SIZE = 0.5
export const VERTICAL_LINE_OFFSET = REPORT_COUNT_SIZE / 2 + REPORT_COUNT_OFFSET
export const BRACKET_DEPTH = 5
export const HORIZONTAL_BRACKET_OFFSET = 3
