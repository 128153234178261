import {Typography} from 'nf-ui'
import pluralize from 'pluralize'
import React, {FC} from 'react'
import {PageLoading} from '~/components/PageLoading'
import {ProfilesGridLayout} from '~/components/ProfilesGridLayout/ProfilesGridLayout'
import {getGraphQLErrorMessage} from '~/util'
import {UserlandActionBar} from './ActionBar'
import {useAllHomeItem, useAllStaffData} from './useAllStaffData'

export const AllStaff: FC = () => {
    const staffData = useAllStaffData()
    const {label: allPeopleLabel} = useAllHomeItem()

    if (staffData.error) return <Typography.Heading>{getGraphQLErrorMessage(staffData.error)}</Typography.Heading>

    if (!staffData.data || !allPeopleLabel)
        return (
            <>
                <PageLoading />
                <UserlandActionBar loading />
            </>
        )

    const profilesCount = staffData.data.organisation.profilesCount
    const actionBarContent = `${profilesCount} ${pluralize('person', profilesCount)} in ${allPeopleLabel}`
    const profileIdStrs = staffData.data.profiles.map((profile) => profile.idStr)

    return (
        <>
            <ProfilesGridLayout data={{...staffData.data}} />
            <UserlandActionBar label={actionBarContent} profileIdStrs={profileIdStrs} />
        </>
    )
}
