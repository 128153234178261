import {RefObject, useRef, useEffect, useState, useCallback} from 'react'
import {produce} from 'immer'

import {VariableSizeGrid} from 'react-window'
import {TRANSITION_DURATION} from '../constants'
import {sleep} from '../../util'

type Item = string | number

type Arguments = {
    items: Item[]
    hiddenItems: Item[]
    gridRef: RefObject<VariableSizeGrid>
}

/**
 * Whenever a new array value is passed to the hook,
 * compare to the previous value passed in and return
 * items removed from, and added to, the array.
 */
function useDiff<T>(value: T[]) {
    const previous = useRef(value)

    useEffect(() => {
        previous.current = value
    }, [value])

    const added = value.filter((column) => !previous.current.includes(column))
    const removed = previous.current.filter((column) => !value.includes(column))

    return {added, removed}
}

/**
 * The styles applied to items.
 */
export type Style = {
    /** The item is collapsed (0 width) */
    collapsed: boolean

    /** The item is faded out (0 opacity) */
    fadedOut: boolean

    /** The item is unmounted (nothing rendered to DOM) */
    mounted: boolean
}

type StyleMap = Record<Item, Style>

type Status = 'hiding' | 'showing' | null
type StatusMap = Record<Item, Status>

type CollapsedMap = Record<Item, boolean>

/**
 * This hook gets passed an array of items (columns or rows currently).
 *
 * When an item is hidden/shown, this hook will apply different style properties
 * to that item in a certain order, and with a specified delay between them.
 *
 * See the end of the hook for the order of properties and delays between them.
 */
export const useAnimateGrid = ({items, hiddenItems, gridRef}: Arguments) => {
    const getInitialStyles = () =>
        items.reduce<StyleMap>((styles, item) => {
            const hidden = hiddenItems.includes(item)

            return {
                ...styles,
                [item]: {collapsed: hidden, fadedOut: hidden, mounted: !hidden},
            }
        }, {})

    const [styles, setStyles] = useState<StyleMap>(getInitialStyles)

    const {removed: toShow, added: toHide} = useDiff(hiddenItems)

    const initialStatus = items.reduce<StatusMap>((styles, item) => ({...styles, [item]: null}), {})
    const status = useRef<StatusMap>(initialStatus)

    const setStyle = useCallback(
        (items: Item[], style: Partial<Style>) => {
            const flushTable = () => {
                if (gridRef.current) {
                    gridRef.current.resetAfterColumnIndex(0)
                    gridRef.current.resetAfterRowIndex(0)
                }
            }

            setStyles((styles) =>
                produce(styles, (draft) => {
                    for (const item of items) {
                        draft[item] = {...draft[item], ...style}
                    }
                }),
            )

            if (typeof style.collapsed === 'boolean') {
                for (const item of items) {
                    collapsed.current[item] = style.collapsed
                }
                flushTable()
            }
        },
        [setStyles, gridRef],
    )

    const run = useCallback(
        async (
            itemsToRun: Item[],
            runStatus: 'hiding' | 'showing',
            stages: {style: Partial<Style>; delayAfter?: number}[],
        ) => {
            if (itemsToRun.length === 0) return

            for (const item of itemsToRun) {
                status.current[item] = runStatus
            }

            let itemsStillHiding: Item[] = []
            for (const stage of stages) {
                itemsStillHiding = itemsToRun.filter((item) => status.current[item] === runStatus)
                setStyle(itemsStillHiding, stage.style)
                if (stage.delayAfter) await sleep(stage.delayAfter)
            }

            for (const item of itemsStillHiding) {
                status.current[item] = null
            }
        },
        [setStyle],
    )

    const initialCollapsed = items.reduce<CollapsedMap>(
        (result, item) => ({...result, [item]: styles[item].collapsed}),
        {},
    )
    const collapsed = useRef(initialCollapsed)

    useEffect(() => {
        // When items should hide...
        run(toHide, 'hiding', [
            {style: {fadedOut: true}, delayAfter: TRANSITION_DURATION + 100}, // 1. Fade them out
            {style: {collapsed: true}, delayAfter: TRANSITION_DURATION + 100}, // 2. Collapse them
            {style: {mounted: false}}, // 3. Unmount them
        ])

        // When items should show...
        run(toShow, 'showing', [
            {style: {mounted: true}}, // 1. Mount them
            {style: {collapsed: false}, delayAfter: TRANSITION_DURATION + 100}, // 2. Expand them
            {style: {fadedOut: false}}, // 3. Fade them in
        ])
    }, [toHide, toShow, run])

    return {styles, collapsed}
}
