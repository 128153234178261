import {gql, useMutation} from '@apollo/client'
import {cloneDeep} from '@apollo/client/utilities'
import {useCallback} from 'react'
import {useAlert} from 'react-alert'
import {InviteStatus} from '~/objectTypes'
import {
    UseResendMutation_InviteUsers,
    UseResendMutation_InviteUsersVariables,
} from './__types__/UseResendMutation_InviteUsers'
import {UserInviteQuery, UserInviteQueryVariables} from './__types__/UserInviteQuery'

export const USER_INVITE_DATA = gql`
    fragment UserInviteData on OrganisationObject {
        idStr
        users {
            idStr
            inviteStatus
        }
    }
`

const USER_INVITE_QUERY = gql`
    query UserInviteQuery($idStr: String!) {
        organisation(idStr: $idStr) {
            ...UserInviteData
        }
    }
    ${USER_INVITE_DATA}
`

const INVITE_USERS_MUTATION = gql`
    mutation UseResendMutation_InviteUsers($organisationIdStr: String!, $userIdStrs: [String!]!) {
        inviteUsers(organisationIdStr: $organisationIdStr, userIdStrs: $userIdStrs) {
            idStr
        }
    }
`

export function useInviteUsersMutation() {
    const {success, error} = useAlert()

    const [inviteUsersMutation, inviteUsersState] = useMutation<
        UseResendMutation_InviteUsers,
        UseResendMutation_InviteUsersVariables
    >(INVITE_USERS_MUTATION, {
        onCompleted: () => success(`Welcome email successfully sent`),
        onError: () => error('Failed to invite users'),
    })

    const inviteUsers = useCallback(
        async (variables: UseResendMutation_InviteUsersVariables) => {
            await inviteUsersMutation({
                variables,
                update: (cache) => {
                    const userData = cache.readQuery<UserInviteQuery, UserInviteQueryVariables>({
                        query: USER_INVITE_QUERY,
                        variables: {
                            idStr: variables.organisationIdStr,
                        },
                    })

                    if (!userData) return

                    const clonedUserData = cloneDeep(userData)
                    clonedUserData.organisation.users
                        .filter((user) => variables.userIdStrs.includes(user.idStr))
                        .forEach((user) => {
                            user.inviteStatus = InviteStatus.INVITED
                        })

                    cache.writeQuery({query: USER_INVITE_QUERY, data: clonedUserData})
                },
            })
        },
        [inviteUsersMutation],
    )

    return {
        inviteUsers,
        inviteUsersState,
    }
}
