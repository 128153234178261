import {ACTION_BAR_HEIGHT, useAnalyticsEventTracker} from 'nf-ui'
import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {ProfilesGrid, ProfilesGridProps} from '../ProfilesGrid/ProfilesGrid'
import {Rolodex} from '../Rolodex'
import {ProfilesGridSizer} from './ProfilesGridSizer'

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    padding-right: 32px;
`

export type ProfilesGridLayoutProps = Pick<ProfilesGridProps, 'data' | 'sort'> & {rolodex?: boolean}

/**
 * A full-page layout displaying the virtualized `ProfilesGrid`.
 */
export const ProfilesGridLayout: React.FC<ProfilesGridLayoutProps> = ({rolodex = true, ...props}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const padding = {top: 32, right: 0, bottom: 64 + ACTION_BAR_HEIGHT, left: 32}

    const [rolodexValue, setRolodexValue] = useState<string | null>(null)

    const gridRef = useRef<ProfilesGrid>(null)

    return (
        <Container>
            <ProfilesGridSizer>
                {({width, height}) => (
                    <ProfilesGrid
                        ref={gridRef}
                        padding={padding}
                        width={width}
                        maxHeight={height}
                        onScroll={(event) => {
                            // Don't do anything if the onScroll was trigger by setting scrollTop
                            // since that fires after calling scrollToLetter, which would immediately
                            // unset the Rolodex
                            if (event.scrollUpdateWasRequested) return
                            setRolodexValue(null)
                        }}
                        {...props}
                    />
                )}
            </ProfilesGridSizer>
            {rolodex ? (
                <Rolodex
                    value={rolodexValue}
                    onChange={(value) => {
                        trackAnalyticsEvent('select_scroll')
                        if (gridRef.current) gridRef.current.scrollToLetter(value)
                        setRolodexValue(value)
                    }}
                />
            ) : (
                <div style={{width: 64}}></div>
            )}
        </Container>
    )
}
