import {useState} from 'react'
import {DataCollectionStatus} from '~/objectTypes'

export type CollectStepFilter = 'ALL' | DataCollectionStatus

export function useProfileFilter<ProfileType extends {dataCollectionStatus: DataCollectionStatus}>(
    profiles: ProfileType[],
    initialFilter: CollectStepFilter = 'ALL',
) {
    const [filter, setFilter] = useState<CollectStepFilter>(initialFilter)

    let filteredProfiles = profiles

    if (filter !== 'ALL') {
        filteredProfiles = profiles.filter((profile) => profile.dataCollectionStatus === filter)
    }

    return {
        profiles: filteredProfiles,
        setFilter,
        filter,
    }
}
