import {gql, useQuery} from '@apollo/client'
import {AttendeeProfileLines} from './__types__/AttendeeProfileLines'

const ATTENDEE_PROFILE_LINES = gql`
    query AttendeeProfileLines($idStr: String!) {
        profile(idStr: $idStr) {
            idStr
            canEdit
            profileLines {
                idStr
                label
                value
                type
                extraData {
                    childCategories {
                        idStr
                        label
                    }
                    dateMask
                }
            }
        }
    }
`

export const useAttendeeProfileLinesFor = (idStr?: string | null) => {
    const {data, loading} = useQuery<AttendeeProfileLines>(ATTENDEE_PROFILE_LINES, {
        variables: {idStr},
        skip: !idStr,
    })

    const [secondaryLine, ...profileLines] = data?.profile?.profileLines || []
    return {secondaryLine, profileLines, profileLinesLoading: loading}
}
