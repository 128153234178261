import React from 'react'
import {
    BRACKET_DEPTH,
    maxRowsFor,
    GRID_COLUMNS,
    GUTTER,
    HORIZONTAL_BRACKET_OFFSET,
    LINE_SIZE,
    Profile,
    PROFILE_ROW_HEIGHT,
    VERTICAL_LINE_OFFSET,
} from './constants'
import {HorizontalLine} from './HorizontalLine'
import {ProfileBrick} from './ProfileBrick'
import {getGridDimensions} from './reportingLine'
import {Indentation, Spacer} from './Spacer'
import {styled} from './syledPdf'
import {usePrintContext} from './usePrintContext'
import {SolidVerticalLine} from './VerticalLines'

function chunk<Items>(array: Items[], chunk: number) {
    const chunkedArray = []
    let current = []

    for (let index = 0; index < array.length; index++) {
        const element = array[index]
        current.push(element)
        if ((index + 1) % chunk === 0) {
            chunkedArray.push(current)
            current = []
        }
    }

    if (current.length) chunkedArray.push(current)
    return chunkedArray
}

const GridContainer = styled.View`
    display: flex;
    flex-direction: row;
`

const Grid = styled.View`
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
`

const GridRow = styled.View`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`

const FillHorizontalSpace = styled.View`
    flex: 1;
`

const Bracket = ({indentation, rowCount}: {indentation: number; rowCount: number}) => {
    const {indentationWidth} = usePrintContext()
    return (
        <>
            <HorizontalLine
                top={((PROFILE_ROW_HEIGHT + GUTTER) * rowCount) / 2 - GUTTER}
                left={(indentation - 1) * indentationWidth + VERTICAL_LINE_OFFSET}
                width={indentationWidth - VERTICAL_LINE_OFFSET - BRACKET_DEPTH - HORIZONTAL_BRACKET_OFFSET}
            />
            <SolidVerticalLine
                top={PROFILE_ROW_HEIGHT / 2}
                left={indentation * indentationWidth - HORIZONTAL_BRACKET_OFFSET - LINE_SIZE - BRACKET_DEPTH}
                height={(PROFILE_ROW_HEIGHT + GUTTER) * rowCount - PROFILE_ROW_HEIGHT - GUTTER + LINE_SIZE}
            />
            <HorizontalLine
                top={PROFILE_ROW_HEIGHT / 2}
                left={indentation * indentationWidth - HORIZONTAL_BRACKET_OFFSET - BRACKET_DEPTH}
                width={BRACKET_DEPTH}
            />
            <HorizontalLine
                top={(PROFILE_ROW_HEIGHT + GUTTER) * rowCount - PROFILE_ROW_HEIGHT / 2 - GUTTER}
                left={indentation * indentationWidth - HORIZONTAL_BRACKET_OFFSET - BRACKET_DEPTH}
                width={BRACKET_DEPTH}
            />
        </>
    )
}

const ProfileRow = ({profileRow, rowIndex, columns}: {profileRow: Profile[]; rowIndex: number; columns: number}) => {
    const emptyBricks: any[] = Array.from({length: columns - profileRow.length}).fill(null)
    const profileBricks: Array<Profile | null> = [...profileRow, ...emptyBricks]
    const collapsePhoto = columns > GRID_COLUMNS

    return (
        <GridRow key={rowIndex}>
            {profileBricks.map((profileBrick, index) => {
                return (
                    <React.Fragment key={`report_${index}`}>
                        {profileBrick ? (
                            <FillHorizontalSpace>
                                <ProfileBrick
                                    key={profileBrick.idStr}
                                    profile={profileBrick}
                                    collapsePhoto={collapsePhoto}
                                />
                            </FillHorizontalSpace>
                        ) : (
                            <FillHorizontalSpace />
                        )}

                        {index < columns - 1 && <Spacer width={GUTTER} />}
                    </React.Fragment>
                )
            })}
        </GridRow>
    )
}

export const ProfileGrid = ({
    profiles,
    indentation = 0,
    page,
}: {
    profiles: Profile[]
    indentation?: number
    page: number
}) => {
    const {orientation} = usePrintContext()
    const maxRows = maxRowsFor({orientation, page})
    const {columns} = getGridDimensions({gridSize: profiles.length, maxRows})

    const profileRows = chunk(profiles, columns)
    const rowCount = profileRows.length

    return (
        <GridContainer>
            <Indentation indentation={indentation} />
            <Bracket indentation={indentation} rowCount={rowCount} />
            <Grid>
                {profileRows.map((profileRow, index) => {
                    const gutter = index !== rowCount - 1

                    return (
                        <React.Fragment key={`chunk_${index}`}>
                            <ProfileRow profileRow={profileRow} rowIndex={index} columns={columns} />
                            {gutter && <Spacer height={GUTTER} />}
                        </React.Fragment>
                    )
                })}
            </Grid>
        </GridContainer>
    )
}
