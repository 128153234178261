import {motion} from 'framer-motion'
import {Button, colors, Spacer, Typography} from 'nf-ui'
import SvgCaret from 'nf-ui/Icons/Caret'
import React from 'react'
import styled from 'styled-components'
import {Profile} from '../useFullOrgChartData'
import {getCollapsedProfileCount} from './getCollapsedProfileCount'

const CollapsedReportsCount = styled(Typography.Tiny)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
`

const CaretBorder = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${colors.transparent};
    border-radius: 3px;
    transition: border-color 0.3s ease-in-out;

    :hover {
        border-color: ${colors.primary[35]};
    }
`

export const ExpandControlCaret = ({
    expanded,
    toggle,
    profileChildrenRef,
}: {
    expanded: boolean
    toggle: () => void
    profileChildrenRef?: React.RefObject<HTMLDivElement>
}) => {
    const state = expanded ? 'expanded' : 'collapsed'
    const variants = {
        collapsed: {rotate: 0},
        expanded: {rotate: 180},
    }

    const hoverProps = {
        onMouseEnter: () => {
            profileChildrenRef?.current?.classList.add('expand-control-hover')
        },
        onMouseLeave: () => {
            profileChildrenRef?.current?.classList.remove('expand-control-hover')
        },
    }

    return (
        <Button
            style={{height: 'auto', flexShrink: 0}}
            variant="link"
            onClick={() => toggle()}
            {...hoverProps}
            onClickAnalyticsEvent="orgCharts"
            onClickAnalyticsData={{
                page: 'full-view',
                component: 'button',
                type: 'click',
                action: 'select',
                name: expanded ? 'collapse' : 'expand',
            }}
        >
            <CaretBorder>
                <motion.div initial={state} animate={state} variants={variants}>
                    <Button.Icon icon={SvgCaret} />
                </motion.div>
            </CaretBorder>
        </Button>
    )
}

export const CollapsibleControls = ({
    profile,
    hover,
    expanded,
    toggle,
    getProfileFromIdStr,
    profileChildrenRef,
}: {
    hover: boolean
    expanded: boolean
    toggle: () => void
    profile: Profile
    getProfileFromIdStr: (idStr: string) => Profile | null
    profileChildrenRef: React.RefObject<HTMLDivElement>
}) => {
    if (hover) return <ExpandControlCaret expanded={expanded} toggle={toggle} profileChildrenRef={profileChildrenRef} />
    if (expanded) return null

    const reportsCount = getCollapsedProfileCount(profile, getProfileFromIdStr)

    return (
        <>
            <Spacer width={8} />
            <CollapsedReportsCount color={'primary'} bold>
                {reportsCount}
            </CollapsedReportsCount>
            <ExpandControlCaret expanded={expanded} toggle={toggle} profileChildrenRef={profileChildrenRef} />
        </>
    )
}
