import * as React from 'react'
const SvgSettingsRegular = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgSettingsRegular(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M4.6 8.641c-1.281 0-2.432.861-2.855 2.092l-.056.179-.061.219H.5c-.308 0-.5.21-.5.53 0 .284.152.482.402.522l.098.008h1.128l.06.22c.371 1.325 1.57 2.27 2.912 2.27a3.102 3.102 0 002.958-2.098l.053-.173.061-.219H15.4c.338 0 .6-.242.6-.53 0-.26-.212-.481-.5-.523l-.1-.007H7.672l-.06-.22A3.102 3.102 0 004.6 8.642zm0 1.06c1.145 0 2 .856 2 1.96s-.855 1.96-2 1.96c-1.063 0-1.9-.874-1.9-1.96 0-1.086.837-1.96 1.9-1.96zM11.3 2c-1.376 0-2.528.86-2.865 2.083l-.041.169-.05.238H.5c-.308 0-.5.21-.5.53 0 .285.152.482.402.522L.5 5.55h7.844l.05.238C8.668 7.1 9.86 8.041 11.3 8.041c1.28 0 2.431-.862 2.855-2.093l.056-.179.061-.219H15.4c.339 0 .6-.241.6-.53 0-.26-.212-.481-.501-.523L15.4 4.49h-1.128l-.06-.22C13.84 2.946 12.641 2 11.3 2zm0 1.06c1.063 0 1.9.875 1.9 1.96 0 1.086-.837 1.961-1.9 1.961-1.076 0-2-.888-2-1.961 0-1.072.924-1.96 2-1.96z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgSettingsRegular
