import * as React from 'react'
const SvgDesignBold = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgDesignBold(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M2.41 0h9.638c1.157 0 2.12.866 2.313 2h.868c.482 0 .771.359.771.8v4.801c0 .441-.29.8-.771.8H8v1.25c.771.177 1.253.804 1.253 1.55v3.2c0 .884-.771 1.6-1.639 1.6h-.77c-.868 0-1.64-.716-1.64-1.6v-3.2c0-.746.58-1.373 1.254-1.55v-2.05c0-.212.096-.416.193-.566.192-.15.385-.234.578-.234h7.229V3.6h-.097c-.192 1.135-1.156 2.001-2.313 2.001H2.41C1.157 5.601 0 4.526 0 3.2v-.8C0 1.075 1.157 0 2.41 0zm10.41 2.775V3.2c0 .442-.386.8-.772.8H2.41c-.386 0-.771-.358-.771-.8v-.8c0-.441.385-.8.77-.8h9.64c.385 0 .77.359.77.8v.375zM7.228 11.2h-.386v3.2h.771v-3.2H7.23z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgDesignBold
