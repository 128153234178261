import {useCallback, useEffect, useState} from 'react'
import {createContainer} from 'unstated-next'

export type SortMode = 'firstName' | 'lastName'

interface UserSettings {
    currentOrganisation?: string
    sortMode: SortMode
}

const defaultSettings: UserSettings = {
    sortMode: 'firstName',
}

const userSettingsKey = 'userSettings'

const getInitialUserSettings = (): UserSettings => {
    const storedUserSettings = localStorage.getItem(userSettingsKey)
    return storedUserSettings ? {...defaultSettings, ...JSON.parse(storedUserSettings)} : defaultSettings
}

const useUserSettings = () => {
    const [userSettingsState, setUserSettingsState] = useState(getInitialUserSettings)

    useEffect(() => {
        localStorage.setItem(userSettingsKey, JSON.stringify(userSettingsState))
    }, [userSettingsState])

    const setUserSettings = useCallback((userSettings: Partial<UserSettings>) => {
        setUserSettingsState((state) => ({...state, ...userSettings}))
    }, [])

    const clearUserSettings = useCallback(() => {
        localStorage.removeItem(userSettingsKey)
    }, [])

    return {userSettings: userSettingsState, setUserSettings, clearUserSettings}
}

export const UserSettings = createContainer(useUserSettings)
