import {useLayoutEffect, useState} from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export function useElementSize<E extends HTMLElement>(elementRef: React.RefObject<E>) {
    const [dimensions, setDimensions] = useState<{width: number; height: number}>({width: 0, height: 0})

    useLayoutEffect(() => {
        if (!elementRef.current) return

        // Get initial size
        const {width, height} = elementRef.current.getBoundingClientRect()
        setDimensions({width, height})

        // Use ResizeObserver to listen for changes
        const resizeObserver = new ResizeObserver(([element]) => {
            const {width, height} = element.contentRect
            setDimensions({width, height})
        })

        resizeObserver.observe(elementRef.current)

        return () => resizeObserver.disconnect()
    }, [elementRef])

    return dimensions
}
