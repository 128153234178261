import {ApolloError, FetchResult, gql, MutationTuple, useMutation} from '@apollo/client'
import {useAlert} from 'react-alert'
import {getGraphQLErrorMessage} from '~/util'
import {UseInAppData, UseInAppDataVariables} from './__types__/UseInAppData'

const USE_IN_APP_DATA = gql`
    mutation UseInAppData($organisationIdStr: String!) {
        useInAppData(organisationIdStr: $organisationIdStr)
    }
`

export const useMutationUseInAppData = (): [
    MutationTuple<UseInAppData, UseInAppDataVariables>,
    (organisationIdStr: string) => Promise<FetchResult<UseInAppData, Record<string, any>, Record<string, any>>>,
] => {
    const alert = useAlert()

    const mutation = useMutation<UseInAppData, UseInAppDataVariables>(USE_IN_APP_DATA, {})
    const callMutation = async (organisationIdStr: string) => {
        try {
            const result = await mutation[0]({variables: {organisationIdStr}})
            return result
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}
