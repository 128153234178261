import React from 'react'
import {motion, MotionProps} from 'framer-motion'
import styled from 'styled-components'
import {colors} from '../theme'
import {BORDER_WIDTH, transition} from './constants'

const BorderContainer = styled(motion.div)<{position: 'top' | 'bottom' | 'left' | 'right'; horizontal: boolean}>`
    position: absolute;
    ${({horizontal}) => (horizontal ? 'left' : 'top')}: -1px;
    ${({horizontal}) => (horizontal ? 'right' : 'bottom')}: -1px;
    ${({horizontal}) => (horizontal ? 'height' : 'width')}: 1px;
    ${({position}) => position}: 0;
`

const Border = styled.div`
    position: absolute;
    top: 0;
    background-color: ${colors.white};
    transition: ${transition('opacity', 'transform')};
    transform-origin: left center;
    transform: scale(1, 1, 1);
`

type BordersProps = {
    /**
     * The items for which we each need to render a border.
     */
    items: {index: number; style: {collapsed: boolean; fadedOut: boolean}; size: number; offset: number}[]

    /**
     * This allows us to shift the border container along with the scroll
     * position of the table.
     */
    style: MotionProps['style']

    /**
     * The position of these borders. (i.e. which side they sit on)
     */
    position: 'top' | 'bottom' | 'left' | 'right'
}

/**
 * These borders display over the outer borders of the table.
 *
 * When a column or row is hidden/shown, the outer borders next to that
 * row/column need to fade in/out, too.
 *
 * This is achieved by displaying a white border on top of the table's outer border,
 * that is the same size and position as the column/row being hidden/shown.
 *
 * **It's almost like a "border cover"**
 */
export const Borders: React.FC<BordersProps> = ({items, ...rest}) => {
    const horizontal = rest.position === 'top' || rest.position === 'bottom'

    return (
        <BorderContainer horizontal={horizontal} {...rest}>
            {items.map((item) => {
                const widthPadding = item.index === 0 ? 1 : -BORDER_WIDTH

                const translate = item.index === 0 ? BORDER_WIDTH : item.offset + BORDER_WIDTH * 3

                return (
                    <Border
                        key={item.index}
                        style={{
                            [horizontal ? 'width' : 'height']: item.size + widthPadding,
                            transform: `translate3d(${horizontal ? translate : 0}px, ${
                                horizontal ? 0 : translate
                            }px, 0) scale3d(${item.style.collapsed ? 0 : 1}, 1, 1)`,
                            opacity: item.style.fadedOut ? 1 : 0,
                            [horizontal ? 'height' : 'width']: 1,
                        }}
                    />
                )
            })}
        </BorderContainer>
    )
}
