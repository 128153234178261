import {AnimatePresence} from 'framer-motion'
import {from} from 'fromfrom'
import React, {FC, Key, ReactElement, useCallback, useEffect, useMemo, useState} from 'react'
import {usePrevious} from '../hooks'
import {AccordionWrapperProps} from './AccordionItem'
import {ListContainer} from './shared'
import {AccordionData} from './types'
import {findFirstValidSelectableKey, processIdMap, processValueMap, renderChildren} from './utils'

export type AccordionProps<T = any> = {
    //TODO : Allow invalid value selection
    /**
     * Selected Key value
     *
     * @type {(Key | null)}
     */
    selectedKey?: Key | null

    /**
     * Data to render
     *
     * @type {AccordionData<T>[]}
     */
    data: AccordionData<T>[]

    /**
     * Callback for when a new value is selected
     *
     */
    onChange?: (selected: Key | null, data?: T) => void

    /**
     * Wrap the content of the data item with this component
     *
     * @type {FC<AccordionWrapperProps<T>>}
     */
    wrapper?: FC<AccordionWrapperProps<T>>

    /**
     * The expanded values for this accordion
     *
     * @type {Key[]}
     */
    initialExpanded?: Key[]

    /**
     * If true then a parent will open before being selected
     *
     * @type {boolean}
     */
    lazySelect?: boolean
}

//Use this one as the default so it's reference can be checked when determening if the function was defaulted
const onChangeNoOp = () => {}

export function Accordion<T = any>({
    data,
    onChange = onChangeNoOp,
    selectedKey,
    wrapper,
    initialExpanded = [],
    lazySelect = false,
}: AccordionProps<T>): ReactElement<AccordionProps<T>> | null {
    const idMap = useMemo(() => processIdMap(data), [data])
    const valueMap = useMemo(() => processValueMap(data, {}, onChange === onChangeNoOp), [data, onChange])

    const isExpandableKey = useCallback(
        function(key?: Key | null): key is Key {
            if (!key) return false
            return valueMap[key] && valueMap[key].hasChildren
        },
        [valueMap],
    )

    const [expandedIds, setExpandedIds] = useState(() => {
        const validExpandIds = initialExpanded.filter(isExpandableKey)

        if (isExpandableKey(selectedKey) && !validExpandIds.includes(selectedKey)) {
            validExpandIds.push(selectedKey)
        }

        return validExpandIds
    })

    //Guard that returns the closest selectable key
    const validSelectKey = useMemo(() => findFirstValidSelectableKey(selectedKey, idMap, valueMap), [
        selectedKey,
        idMap,
        valueMap,
    ])

    const previousSelected = usePrevious(selectedKey)

    useEffect(() => {
        setExpandedIds((ids) => {
            if (selectedKey) {
                const value = valueMap[selectedKey]
                if (selectedKey === previousSelected && ids.includes(selectedKey) && value?.hasChildren) {
                    return ids.filter((id) => id !== selectedKey) //collapse open sections when they get selected
                }

                if (selectedKey !== previousSelected && !ids.includes(selectedKey)) {
                    return [...ids, selectedKey]
                }
            }

            return ids
        })
    }, [setExpandedIds, selectedKey, isExpandableKey, previousSelected, valueMap])

    useEffect(() => {
        if (validSelectKey !== selectedKey && onChange !== onChangeNoOp) {
            if (previousSelected === selectedKey) {
                console.error(`Loop found in Accordion, ensure calling component obeys onChange value`)
            }
            onChange(validSelectKey == null ? null : validSelectKey)
        }
    }, [validSelectKey, selectedKey, onChange, previousSelected])

    //Decides when to call the outside onChange
    const handleChange = (id: Key) => {
        if (id === selectedKey) return

        const value = valueMap[id]
        const {data, hasChildren, selectable} = value

        if (lazySelect && hasChildren && !expandedIds.includes(id)) return

        if (selectable) {
            onChange(id, data)
        } else if (!lazySelect) {
            const validSelectKey = findFirstValidSelectableKey(id, idMap, valueMap)
            if (validSelectKey == null) return
            handleChange(validSelectKey)
        }
    }

    const onSelect = (id: Key) => {
        const value = valueMap[id]
        if (!value) {
            console.error(`id ${id} is not valid`)
            return
        }

        const {hasChildren} = value
        handleChange(id)

        if (hasChildren) {
            setExpandedIds((ids) => {
                return !ids.includes(id)
                    ? [...ids, id]
                    : from(ids)
                          .without(idMap[id])
                          .toArray()
            })
        }
    }

    return (
        <AnimatePresence initial={false}>
            <ListContainer>
                {renderChildren(data, {expandedIds, selectedKey: validSelectKey, idMap}, {onSelect, wrapper})}
            </ListContainer>
        </AnimatePresence>
    )
}
