import {gql, useQuery} from '@apollo/client'
import {Typography} from 'nf-ui'
import React from 'react'
import {PageLoading} from '~/components/PageLoading'
import {ProfilesGridLayout} from '~/components/ProfilesGridLayout/ProfilesGridLayout'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {getGraphQLErrorMessage} from '~/util'
import {ActionBarSizer, UserlandActionBar} from './ActionBar'
import {
    SmartGroupData,
    SmartGroupDataVariables,
    SmartGroupData_organisation_smartGroups,
    SmartGroupData_organisation_smartGroups_profiles_profile,
} from './__types__/SmartGroupData'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'

const DATA = gql`
    query SmartGroupData($idStr: String!) {
        organisation(idStr: $idStr) {
            idStr
            smartGroups {
                idStr
                label
                profiles {
                    profileIdStr
                    secondaryLine
                    profile {
                        idStr
                        firstName
                        lastName
                        fullName
                        photos {
                            idStr
                            thumbUrl
                            priority
                        }
                    }
                }
            }
        }
    }
`

export function profilesFor(smartGroup: SmartGroupData_organisation_smartGroups) {
    const profiles: SmartGroupData_organisation_smartGroups_profiles_profile[] = []
    const secondaryLines: Record<string, string | null> = {}

    for (const smartGroupProfile of smartGroup.profiles) {
        profiles.push(smartGroupProfile.profile)
        secondaryLines[smartGroupProfile.profileIdStr] = smartGroupProfile.secondaryLine
    }

    return profiles.map((profile) => ({...profile, secondaryLine: secondaryLines[profile.idStr]}))
}

export const SmartGroup: React.FC<{
    smartGroupId: string
}> = ({smartGroupId}) => {
    const organisationId = useOrganisationIdStr()

    const {data: smartGroupData, error, loading} = useQuery<SmartGroupData, SmartGroupDataVariables>(DATA, {
        variables: {
            idStr: organisationId,
        },
        fetchPolicy: 'cache-first',
    })

    const currentOrganisation = useCurrentOrganisation()

    if (loading) return <PageLoading />
    if (error) return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>

    const smartGroup = smartGroupData?.organisation.smartGroups.find((smartGroup) => smartGroup.idStr === smartGroupId)
    if (!smartGroup) return <Typography.Heading>Smart group not found</Typography.Heading>

    const profiles = profilesFor(smartGroup)
    const _data = {
        organisation: currentOrganisation.currentOrganisation,
        profiles: profiles,
    }
    return (
        <ActionBarSizer>
            <ProfilesGridLayout data={_data} sort={false} rolodex={false} />
            <UserlandActionBar label={smartGroup?.label} showSort={false} showGroupMessage={false} />
        </ActionBarSizer>
    )
}
