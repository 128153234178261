import {gql, useMutation} from '@apollo/client'
import {Button, colors, Form, Spacer, Typography} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React, {FormEvent} from 'react'
import {useAlert} from 'react-alert'
import {Redirect} from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'
import {PasswordInput} from '~/components/PasswordInput'
import {Token} from '~/components/TokenContext'
import {getGraphQLErrorMessage} from '~/util'
import {usePasswordUpdate} from '../usePasswordUpdate'
import {CreateAccount as CreateAccountMutation, CreateAccountVariables} from './__types__/CreateAccount'

const CREATE_ACCOUNT = gql`
    mutation CreateAccount($email: String!, $password: String!) {
        createAccount(email: $email, password: $password)
    }
`

const useCreateAccount = () => useMutation<CreateAccountMutation, CreateAccountVariables>(CREATE_ACCOUNT)

const emailValidator = yup
    .string()
    .email()
    .required()

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const CreateAccountForm = ({
    email,
    setEmail,
    onComplete,
}: {
    email: string
    setEmail: React.Dispatch<React.SetStateAction<string>>
    onComplete: () => void
}) => {
    const {token} = Token.useContainer()
    const alert = useAlert()
    const passwordState = usePasswordUpdate()
    const [createAccount, {loading}] = useCreateAccount()

    if (token) return <Redirect to="/" />

    const onSubmit = async (event: FormEvent) => {
        try {
            event.preventDefault()
            await createAccount({variables: {email, password: passwordState.password}})
            onComplete()
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }
    const valid = !loading && passwordState.valid && emailValidator.isValidSync(email)

    return (
        <>
            <Typography.Heading>Create your account</Typography.Heading>
            <Spacer height={32} />
            <Form onSubmit={(event) => onSubmit(event)} width={420}>
                <Form.Group name="email">
                    <Form.Label>Work email address</Form.Label>
                    <Form.Input value={email} autoFocus onChange={(value) => setEmail(value)} />
                </Form.Group>
                <PasswordInput label passwordState={passwordState} />
                <Spacer height={32} />
                <Typography.Paragraph maxWidth={230} bottomMargin={false}>
                    Already have an account?{' '}
                    <a href="/" target="_blank" rel="noopener noreferrer" style={{color: colors.black}}>
                        Sign In
                    </a>{' '}
                </Typography.Paragraph>
                <Spacer height={32} />
                <ButtonContainer>
                    <Button disabled={!valid} variant="primary" type="submit">
                        Continue
                        <Button.Icon icon={SvgArrowRight} />
                    </Button>
                </ButtonContainer>
            </Form>
        </>
    )
}
