import * as React from 'react'
const SvgContextualMenu = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgContextualMenu(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.374 10.4c-.87 0-1.524.689-1.524 1.6 0 .912.654 1.6 1.524 1.6.907 0 1.676-.724 1.676-1.6 0-.875-.77-1.6-1.676-1.6zm5.702 0c-.906 0-1.676.725-1.676 1.6 0 .876.77 1.6 1.676 1.6.87 0 1.524-.688 1.524-1.6 0-.911-.655-1.6-1.524-1.6zm5.398 0c-.87 0-1.524.689-1.524 1.6 0 .912.654 1.6 1.524 1.6.907 0 1.676-.724 1.676-1.6 0-.875-.77-1.6-1.676-1.6z"
                fill="#101010"
            />
        </svg>
    )
})
export default SvgContextualMenu
