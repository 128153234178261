import {Color} from 'csstype'
import React, {ComponentType, forwardRef} from 'react'
import styled from 'styled-components'

export type IconType = ComponentType<React.SVGAttributes<SVGSVGElement>>

export type IconProps = {
    icon: IconType
    size?: 24 | 16 | 8 | 6
    tint?: Color
    className?: string
    style?: React.CSSProperties
} & React.SVGProps<SVGSVGElement>

export const IconContainer = styled.div<Pick<IconProps, 'tint' | 'size'>>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    svg {
        overflow: visible;
        width: 100%;
        height: 100%;
        display: block;
        path {
            fill: ${(props) => props.tint};
        }
    }
`

export const Icon = forwardRef<SVGSVGElement, IconProps>(function Icon(props, svgRef) {
    const {icon: IconComp, size = 16, tint, className, style, ...rest} = props

    return (
        <IconContainer size={size} tint={tint} style={style} className={className}>
            <IconComp ref={svgRef} {...rest} />
        </IconContainer>
    )
})
