import React from 'react'
import {hex, hsl} from 'color-convert'
import {blendWhite, hexToRGB} from 'nf-ui'
import {getProfileInitials} from 'nf-ui/InitialsText'
import styled, {css} from 'styled-components'

const BASE_ALPHA = 0.85

const lValues = [-24, -20, -16, -12, -8, -4, 0, 4, 8]

const randomLIncrement = (): number => {
    const index = Math.floor(Math.random() * (lValues.length - 1))
    return lValues[index]
}

const randomBackgroundColor = (color: string) => {
    const [h, s, l] = hex.hsl(blendWhite(color, 0.6))
    const randomColor = '#' + hsl.hex([h, s, l + randomLIncrement()])

    return hexToRGB(randomColor, BASE_ALPHA)
}

export const absoluteFillStyle = css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`

export const Image = styled.div<{url?: string}>`
    z-index: 1;
    background-size: cover;
    background-position: center;
    background-image: url('${({url}) => (url ? url : '')}');
    ${absoluteFillStyle}
`

const FallbackContainer = styled.div<{guest?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    ${absoluteFillStyle}
    ${(props) =>
        props.guest
            ? css`
                  background: repeating-linear-gradient(
                      -45deg,
                      rgba(181, 181, 181, 0.85),
                      rgba(181, 181, 181, 0.85) 1px,
                      rgba(196, 196, 196, 0.85) 1px,
                      rgba(196, 196, 196, 0.85) 4px
                  );
              `
            : css`
                  background-color: ${randomBackgroundColor(props.theme.primary.color)};
              `}
`

const InitialsText = styled.div`
    color: ${(props) => props.theme.primary.textColor};
    text-align: center;
    font-weight: 500;
    font-size: 36px;
    font-family: 'halyard-display';
    line-height: 10px;
    margin-top: -2px;
`

const BackgroundImage = styled.div<{url: string}>`
    z-index: 0;
    background-size: cover;
    background-position: center;
    background-image: url('${({url}) => url}');
    ${absoluteFillStyle}
`

type ThumbStripImageSelected = 'selected' | 'unselected'

export const ThumbStrip = styled.div`
    z-index: 2;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
`

export const ThumbStripImage = styled.div<{url: string; selected: ThumbStripImageSelected}>`
    width: ${({selected}) => (selected === 'selected' ? '48px' : '40px')};
    height: ${({selected}) => (selected === 'selected' ? '48px' : '40px')};
    margin: 0px 2px;
    background-size: cover;
    background-position: center;
    background-image: url('${({url}) => url}');
    border-style: solid;
    border-color: white;
    border-width: 1px;
    cursor: pointer;
`

const FallbackPhoto = ({fullName, guest}: {fullName: string; guest?: boolean}) => {
    const initials = getProfileInitials(fullName)
    const fallbackUrl = require(`./profile-fallback-${Math.floor(Math.random() * 5)}.jpg`)

    return (
        <div>
            <BackgroundImage url={fallbackUrl} />
            <FallbackContainer guest={guest}>
                <InitialsText>{initials}</InitialsText>
            </FallbackContainer>
        </div>
    )
}

export const ProfilePicture = React.memo(
    ({url, fullName, guest}: {url?: string; fullName: string; guest?: boolean}) => {
        return url ? <Image url={url} /> : <FallbackPhoto fullName={fullName} guest={guest} />
    },
)
