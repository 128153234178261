import {motion} from 'framer-motion'
import {Button, Spacer, Typography} from 'nf-ui'
import React, {useState} from 'react'
import styled from 'styled-components'
import {FullOrgChartData_organisation_profiles} from '../__types__/FullOrgChartData'
import {Collapsible} from './Collapsible'
import {ProfileRow} from './ProfileBrick'
import {GUTTER} from './ProfileBrick/constants'
import {ProfileRowAction} from './ProfileRowAction'
import {useOrgChartData} from './useOrgChartData'

const OrphanedProfileBrick = ({profile}: {profile: FullOrgChartData_organisation_profiles}) => {
    const [hover, setHover] = useState(false)

    const rowHoverProps = {
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
    }

    return (
        <ProfileRow profile={profile} {...rowHoverProps}>
            {hover && <ProfileRowAction profile={profile} canShowBranch={false} />}
        </ProfileRow>
    )
}

const HeadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const HeadingText = styled(motion.div)`
    display: flex;
`

export const OrphanedProfiles = React.memo(() => {
    const [expanded, setExpanded] = useState(true)
    const {orphanedProfiles} = useOrgChartData()

    if (!orphanedProfiles.length) return null

    const variants = {
        open: {opacity: 1},
        collapsed: {opacity: 0},
    }

    return (
        <div>
            <Spacer height={16} />
            <HeadingWrapper>
                <HeadingText
                    initial="collapsed"
                    animate={expanded ? 'open' : 'collapsed'}
                    variants={variants}
                    transition={{duration: 0.3, ease: 'easeInOut'}}
                >
                    <Typography.Paragraph bottomMargin={false}>
                        Profiles with no reporting structure
                    </Typography.Paragraph>
                    <Spacer width={16} />
                    <Button variant="link" color="black" onClick={() => setExpanded(false)}>
                        Hide
                    </Button>
                </HeadingText>
                <HeadingText
                    initial="open"
                    animate={expanded ? 'collapsed' : 'open'}
                    transition={{duration: 0.3, ease: 'easeInOut'}}
                    variants={variants}
                >
                    <Typography.Paragraph bottomMargin={false}>
                        Hiding profiles with no reporting structure
                    </Typography.Paragraph>
                    <Spacer width={16} />
                    <Button variant="link" color="black" onClick={() => setExpanded(true)}>
                        Show
                    </Button>
                </HeadingText>
            </HeadingWrapper>

            <Spacer height={16} />
            <Collapsible expanded={expanded} animation={true}>
                {orphanedProfiles.map((profile, index) => {
                    const lastProfile = index === orphanedProfiles.length - 1
                    return (
                        <React.Fragment key={index}>
                            <OrphanedProfileBrick profile={profile} />
                            {!lastProfile && <Spacer height={GUTTER} />}
                        </React.Fragment>
                    )
                })}
            </Collapsible>
        </div>
    )
})
