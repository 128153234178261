import {gql, useMutation} from '@apollo/client'
import {useQueryParam} from 'use-query-params'
import {DeleteOpenPosition, DeleteOpenPositionVariables} from './__types__/DeleteOpenPosition'
import {FULL_ORG_CHART_DATA, updateDirectReportsOf} from '../useFullOrgChartData'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {FullOrgChartData} from '../__types__/FullOrgChartData'
import produce from 'immer'
import {FullProfileData_profile} from './__types__/FullProfileData'

const DELETE_OPEN_POSITION = gql`
    mutation DeleteOpenPosition($organisationIdStr: String!, $idStr: String!) {
        deleteOpenPosition(organisationIdStr: $organisationIdStr, idStr: $idStr)
    }
`

export const useDeleteOpenPosition = (openPosition: FullProfileData_profile) => {
    const [, setProfileId] = useQueryParam<string>('profileId')
    const organisationIdStr = useOrganisationIdStr()
    const [deleteOpenPosition, {loading}] = useMutation<DeleteOpenPosition, DeleteOpenPositionVariables>(
        DELETE_OPEN_POSITION,
        {
            onCompleted: () => {
                setProfileId('')
            },
            update: (cache) => {
                const orgCharQuery = cache.readQuery<FullOrgChartData>({
                    query: FULL_ORG_CHART_DATA,
                    variables: {idStr: organisationIdStr},
                })
                if (!orgCharQuery) return

                cache.writeQuery<FullOrgChartData>({
                    query: FULL_ORG_CHART_DATA,
                    data: produce(orgCharQuery, (draftState) => {
                        const directReports = draftState.organisation.profiles.filter(
                            (profile) => profile.reportsTo === openPosition.idStr,
                        )

                        const manager = draftState.organisation.profiles.find(
                            (profile) => profile.idStr === openPosition.reportsTo,
                        )
                        if (manager)
                            manager.directReports =
                                manager?.directReports.filter((idStr) => idStr !== openPosition.idStr) || []

                        draftState.organisation.profiles = draftState.organisation.profiles.filter(
                            (orgProfile) => orgProfile.idStr !== openPosition.idStr,
                        )

                        const directsOriginalManagers = directReports.map((direct) => direct.originalManagerIdStr)
                        const directsWithoutOriginalManagers = directReports
                            .filter((direct) => !direct.originalManagerIdStr)
                            .map((direct) => direct.idStr)

                        draftState.organisation.profiles.forEach((profile) => {
                            if (directsWithoutOriginalManagers.includes(profile.idStr)) {
                                profile.reportsTo = null
                            } else if (directsOriginalManagers.includes(profile.idStr)) {
                                const directs = directReports.filter(
                                    (direct) => direct.originalManagerIdStr === profile.idStr,
                                )

                                for (const direct of directs) {
                                    updateDirectReportsOf(profile, direct.idStr, profile.idStr)
                                }
                            }
                        })
                    }),
                })
            },
        },
    )

    return {deleteOpenPosition, loading}
}
