import {gql, useMutation} from '@apollo/client'
import {Button, Form, Spacer, Typography} from 'nf-ui'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'
import React from 'react'
import styled from 'styled-components'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {useRelativeRoute} from '~/components/useRelativeRoute'
import {UpdateUserRestrictions, UpdateUserRestrictionsVariables} from './__types__/UpdateUserRestrictions'

const Header = styled.div`
    display: flex;
    align-items: flex-start;
`
const Row = styled.div`
    display: flex;
    justify-content: space-between;
`

const BackButton = styled(Button)`
    margin-left: -20px;
    margin-right: 4px;
    margin-top: 14px;
    flex-shrink: 0;
`

export const UPDATE_USER_RESTRICTIONS = gql`
    mutation UpdateUserRestrictions($organisationIdStr: String!, $userRestrictions: UserRestrictionsInput!) {
        updateUserRestrictions(organisationIdStr: $organisationIdStr, userRestrictions: $userRestrictions) {
            idStr
            userRestrictions {
                canEditProfilePicture
            }
        }
    }
`

export const EditProfilePicture = ({canEditProfilePicture}: {canEditProfilePicture: boolean}) => {
    const {pushRelative} = useRelativeRoute()
    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const goBack = () => pushRelative('')

    const [updateRestriction, {loading}] = useMutation<UpdateUserRestrictions, UpdateUserRestrictionsVariables>(
        UPDATE_USER_RESTRICTIONS,
    )

    const handleEditRestriction = () => {
        updateRestriction({
            variables: {
                organisationIdStr: currentOrganisation!.idStr,
                userRestrictions: {
                    canEditProfilePicture: !canEditProfilePicture,
                },
            },
            optimisticResponse: {
                updateUserRestrictions: {
                    __typename: 'OrganisationObject',
                    idStr: currentOrganisation!.idStr,
                    userRestrictions: {
                        __typename: 'UserRestrictions',
                        canEditProfilePicture: !canEditProfilePicture,
                    },
                },
            },
        })
    }

    return (
        <>
            <Header>
                <BackButton variant="tertiary" onClick={goBack}>
                    <Button.Icon icon={SvgArrowLeft} />
                </BackButton>
                <Typography.Heading style={{wordBreak: 'break-word', minHeight: 56}}>
                    Profile picture
                </Typography.Heading>
            </Header>
            <Spacer height={32} />
            <Row>
                <Typography.Label>User can edit this field</Typography.Label>
                <Form.Switch checked={canEditProfilePicture} disabled={loading} onChange={handleEditRestriction} />
            </Row>
        </>
    )
}
