import {BaseButton, Icon} from 'nf-ui'
import styled, {css} from 'styled-components'
import {SortOrder} from '~/components/useColumnSort'

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`

const Column = styled.div<{width?: number}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    ${({width}) =>
        width
            ? css`
                  width: ${width}px;
              `
            : css`
                  flex: 1;
              `}
`

const ColumnButton = styled(BaseButton)`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
`

const RotatableIcon = styled(Icon)<{sortOrder: SortOrder | null}>`
    transform: rotateZ(${(props) => (props.sortOrder === 'asc' ? 180 : 0)}deg);
    transition: 0.25s transform ease-in-out;
`

export {Row, Column, ColumnButton, RotatableIcon}
