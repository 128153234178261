import {Button, Form, List, Spacer, Typography} from 'nf-ui'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'
import React, {FC} from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import styled from 'styled-components'
import {useRelativeRoute} from '~/components/useRelativeRoute'
import {Column, Row} from '../PhotoCollection/ListComponents'
import {useMergeCategories} from './useMergeCategories'
import {DesignHomeData_organisation_homeItems_childCategories} from './__types__/DesignHomeData'

const Header = styled.div`
    display: flex;
    align-items: flex-start;
`
const NameContainer = styled.div`
    flex: 1;
`

export const ListContainer = styled.div`
    padding-bottom: 72px;
    width: 100%;
`

const BackButton = styled(Button)`
    margin-left: -20px;
    margin-right: 4px;
    margin-top: 14px;
    flex-shrink: 0;
`
export const MergeButtonContainer = styled.div`
    display: flex;
    background-color: white;
    flex-direction: row-reverse;
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: 16px 32px 16px 32px;
    width: 340px;
`

export const MergeCategories: FC<{categories: DesignHomeData_organisation_homeItems_childCategories[]}> = ({
    categories,
}) => {
    const {
        selectedCategoryIdStrs,
        toggleCategory,
        mergeSelectedCategories,
        categoryName,
        setCategoryName,
        loading,
    } = useMergeCategories()

    const {pushRelative} = useRelativeRoute()
    const goBack = () => pushRelative('')
    const mergeDisabled = selectedCategoryIdStrs.length < 2 || !categoryName

    const mergeCategories = async () => {
        await mergeSelectedCategories()
        goBack()
    }

    return (
        <>
            <Header>
                <BackButton variant="tertiary" onClick={goBack}>
                    <Button.Icon icon={SvgArrowLeft} />
                </BackButton>
                <Typography.Heading style={{wordBreak: 'break-word', minHeight: 56}}>
                    {categoryName || 'Category Name'}
                </Typography.Heading>
            </Header>
            <Spacer height={24} />
            <Form.Group name="homeItemLabel">
                <Form.Label>Label</Form.Label>
                <Form.Input
                    value={categoryName}
                    onChange={(value) => setCategoryName(value)}
                    placeholder={'Combined category name'}
                    autoFocus
                />
            </Form.Group>
            <Spacer height={16} />
            <Form.Group name="homeItemMerge">
                <Form.Label>Categories included</Form.Label>
                <ListContainer>
                    <AutoSizer disableHeight>
                        {({width}) => (
                            <>
                                <List
                                    width={width}
                                    rows={
                                        [...categories].sort((a, b) => {
                                            if (a.label < b.label) return -1
                                            if (a.label > b.label) return 1
                                            return 0
                                        }) || []
                                    }
                                    renderRow={(category) => {
                                        const categoryDisabled = category.combinedCategories.length > 0
                                        return (
                                            <Row
                                                style={{opacity: categoryDisabled ? 0.6 : 1}}
                                                onClick={() => {
                                                    if (!categoryDisabled) toggleCategory(category.idStr)
                                                }}
                                            >
                                                <Column>
                                                    <Form.Group name="name">
                                                        <Form.Checkbox
                                                            checked={selectedCategoryIdStrs.includes(category.idStr)}
                                                            readOnly
                                                            disabled={categoryDisabled}
                                                        />
                                                    </Form.Group>
                                                    <Spacer width={16} />
                                                    <NameContainer>{category.label}</NameContainer>
                                                </Column>
                                            </Row>
                                        )
                                    }}
                                />
                            </>
                        )}
                    </AutoSizer>
                </ListContainer>
            </Form.Group>
            <MergeButtonContainer>
                <Button
                    disabled={mergeDisabled}
                    loading={loading}
                    variant="primary"
                    onClickAnalyticsEvent="select_merge_categories"
                    onClick={mergeCategories}
                >
                    Save
                </Button>
            </MergeButtonContainer>
        </>
    )
}
