import {useMemo} from 'react'
import {Profile} from '../../useFullOrgChartData'
import {useOrgChartData} from '../useOrgChartData'

function reportsOnScreenCount(
    profile: Profile,
    getProfileFromIdStr: (profileIdStr: string) => Profile | null,
    isCollapsed: (profileIdStr: string) => boolean,
): number {
    if (isCollapsed(profile.idStr)) return 1

    const allDirectReports = profile.directReports
        .map((idStr) => getProfileFromIdStr(idStr))
        .filter((profile) => !!profile) as Profile[]

    const additionalReports = profile.additionalReports
        .map((idStr) => getProfileFromIdStr(idStr))
        .filter((profile) => !!profile)

    const directReportsCount = allDirectReports.reduce((total, current) => {
        return total + reportsOnScreenCount(current, getProfileFromIdStr, isCollapsed)
    }, 0)

    return 1 + directReportsCount + additionalReports.length
}

export const useProfilesOnScreen = (isCollapsed: (profileIdStr: string) => boolean) => {
    const {topLevelProfiles, getProfileFromIdStr} = useOrgChartData()
    return useMemo(() => {
        return (
            topLevelProfiles.reduce((total, current) => {
                return total + reportsOnScreenCount(current, getProfileFromIdStr, isCollapsed)
            }, 0) || 0
        )
    }, [getProfileFromIdStr, topLevelProfiles, isCollapsed])
}
