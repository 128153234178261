import {Button, Panel, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgFilter from 'nf-ui/Icons/Filter'
import pluralize from 'pluralize'
import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {OptionType, SortList} from '~/components/SortList'
import {useProfileSort} from '~/components/useProfileSort'
import {DataCollectionStatus} from '~/objectTypes'
import {CollectStepFilter, useProfileFilter} from './useProfileFilter'

interface HeaderProps {
    count: number
    profileFilter: ReturnType<typeof useProfileFilter>
    profileSort: ReturnType<typeof useProfileSort>
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: -7px;
    margin-right: 22px;
`

const FiltersOptions: OptionType<CollectStepFilter>[] = [
    {title: 'All', filter: 'ALL'},
    {title: 'Completed all', filter: DataCollectionStatus.RESPONDED_ALL},
    {title: 'Completed some', filter: DataCollectionStatus.RESPONDED_SOME},
    {title: 'Completed none', filter: DataCollectionStatus.RESPONDED_NONE},
    {title: 'Form not sent', filter: DataCollectionStatus.NOT_SENT},
]

export const ProfileListHeading: React.FC<HeaderProps> = ({count, profileFilter, profileSort}) => {
    const [open, setOpen] = useState(false)
    const isOptionChecked = (option: OptionType<CollectStepFilter>) =>
        option.filter === profileFilter.filter || option.sort === profileSort.sort
    const buttonRef = useRef<HTMLButtonElement>(null)
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    return (
        <Container>
            <Typography.Label>
                {count} {pluralize('person', count)} selected
            </Typography.Label>
            <Button variant="tertiary" ref={buttonRef} onClick={() => setOpen((o) => !o)}>
                <Button.Icon icon={SvgFilter} /> Filter
            </Button>
            <Panel open={open} onClose={() => setOpen(false)} targetRef={buttonRef}>
                <SortList<CollectStepFilter>
                    heading="Filter People"
                    options={FiltersOptions}
                    onClick={(row) => {
                        if (row.filter) {
                            profileFilter.setFilter(row.filter)
                            trackAnalyticsEvent('click_filter', {filter: row.filter})
                        }
                        setOpen(false)
                    }}
                    isOptionChecked={isOptionChecked}
                />
            </Panel>
        </Container>
    )
}
