import * as React from 'react'
const SvgEdit = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgEdit(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M8.198 5.322l2.47 2.468-3.614 3.645c-.172.132-.287.222-.516.259l-1.376.287a.928.928 0 01-.86-.26.972.972 0 01-.287-.86L4.3 9.445c.058-.183.115-.351.287-.484l3.61-3.639zm2.411-1.043l1.09 1.129a.9.9 0 010 1.344l-.426.427-2.47-2.468.43-.432a.99.99 0 011.376 0z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgEdit
