import {useState} from 'react'
import {createContainer} from 'unstated-next'
import {useQueryParam} from 'use-query-params'
import {DataSourceData} from '../ConfigureDataSourceModal'

const usePrimaryDataSourceState = () => {
    const [modal, setModal] = useQueryParam<string>('modal')
    const [dataSourceData, setDataSourceData] = useState<DataSourceData | null>(null)

    const setData = (data: DataSourceData) => {
        setDataSourceData(data)
        setModal('configurePrimaryDataSource')
    }

    return {
        modal,
        dataSourceData,
        setData,
        setModal,
    }
}

const PrimaryDataSourceContainer = createContainer(usePrimaryDataSourceState)
export const usePrimaryDataSource = PrimaryDataSourceContainer.useContainer
export const ConnectPrimaryDataSourceProvider = PrimaryDataSourceContainer.Provider
