import {ACTION_BAR_HEIGHT, AnimatedStack, FadePresence, Typography, useAnalyticsEventTracker} from 'nf-ui'
import {useRefs} from 'nf-ui/Tabs/useMeasureMultiple'
import React, {useEffect, useRef} from 'react'
import styled from 'styled-components'
import {HorizontalProfilesList, HorizontalProfilesListMethods} from '~/components/HorizontalProfilesList'
import {PageLoading} from '~/components/PageLoading'
import {getGraphQLErrorMessage} from '~/util'
import {UserlandActionBar} from '../ActionBar'
import {ReportingLinesProfile, useReportingLinesData} from './useReportingLinesData'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {useOrganisationContext} from '~/components/OrganisationContext'

const profileListPadding = 32

const ScrollContainer = styled.div`
    position: relative;
    padding: 32px;
    padding-bottom: calc(64px + ${ACTION_BAR_HEIGHT}px);
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    box-sizing: border-box;
`

export const ReportingLines = () => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const {
        profiles,
        error,
        topLevelProfiles,
        reportingLineProfiles,
        getAdjacentProfiles,
        getDirectReportsFor,
    } = useReportingLinesData()

    const currentOrganisation = useCurrentOrganisation()

    const reportingLineLength = useRef(reportingLineProfiles.length)
    useEffect(() => {
        if (reportingLineProfiles.length > reportingLineLength.current && reportingLineLength.current !== 0) {
            trackAnalyticsEvent('click_reporting_line')
            trackAnalyticsEvent('orgCharts', {
                page: 'interactive',
                component: 'horizontalProfileList',
                type: 'click',
                action: 'select',
                name: 'org_chart_row',
            })
        }
        reportingLineLength.current = reportingLineProfiles.length
    }, [reportingLineProfiles, trackAnalyticsEvent])

    const listRefs = useRefs<HorizontalProfilesListMethods>(reportingLineProfiles.length)

    const [refreshing] = useOrganisationContext.useOrganisationRefreshing()

    if (error) return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>
    if (refreshing?.error) return <Typography.Heading>{getGraphQLErrorMessage(refreshing?.error)}</Typography.Heading>

    if (!profiles || refreshing?.loading) return <PageLoading />

    const orgChartSize = profiles.filter((profile) => profile.managerIdStr).length + topLevelProfiles.length

    if (!(reportingLineProfiles.length > 0)) return null

    const bottomLevel = getDirectReportsFor(reportingLineProfiles[reportingLineProfiles.length - 1])

    return (
        <ScrollContainer>
            <AnimatedStack space={8}>
                {[...reportingLineProfiles, 'bottomLevel' as const].map((currentProfile, rowIndex) => {
                    const parentId = reportingLineProfiles[rowIndex - 1]?.idStr

                    const sharedProps = {
                        scrollIntoView: true,
                        getCount: (profile: ReportingLinesProfile) => profile.directReports.length || undefined,
                        padding: profileListPadding,
                        alignment: 'center' as const,
                        testId: `List-${parentId}`,
                        analyticsEvent: {
                            event: 'orgCharts',
                            properties: {
                                page: 'interactive',
                                component: 'horizontalProfileList',
                                type: 'click',
                                action: 'scroll',
                                name: 'org_chart_row',
                            },
                        },
                        listRef: listRefs[rowIndex],
                        onClickItem: () => {
                            for (let index = 0; index < listRefs.length; index++) {
                                if (rowIndex === index) continue

                                const listRef = listRefs[index]
                                listRef?.current?.resetScrollPosition()
                            }
                        },
                    }

                    const fadePresenceProps = {
                        itemKey: parentId,
                        key: rowIndex,
                        exitBeforeEnter: true,
                    }

                    if (currentProfile === 'bottomLevel') {
                        if (bottomLevel.length === 0) return null
                        return (
                            <FadePresence {...fadePresenceProps}>
                                <HorizontalProfilesList
                                    {...sharedProps}
                                    organisation={currentOrganisation.currentOrganisation}
                                    profiles={bottomLevel}
                                />
                            </FadePresence>
                        )
                    }

                    return (
                        <FadePresence {...fadePresenceProps}>
                            <HorizontalProfilesList
                                {...sharedProps}
                                initialCenteredItem={currentProfile}
                                highlightedProfileIdStr={currentProfile?.idStr}
                                organisation={currentOrganisation.currentOrganisation}
                                profiles={getAdjacentProfiles(currentProfile)}
                            />
                        </FadePresence>
                    )
                })}
            </AnimatedStack>
            <UserlandActionBar
                showSort={false}
                showGroupMessage={false}
                label={`${orgChartSize} people in Org Chart`}
            />
        </ScrollContainer>
    )
}
