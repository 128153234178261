import React, {useEffect} from 'react'
import {Button, Form, FormikInput, Spacer, Typography} from 'nf-ui'
import {Formik} from 'formik'
import * as yup from 'yup'
import {gql, useMutation, useQuery} from '@apollo/client'
import {AddOnboardingDetails, AddOnboardingDetailsVariables} from './__types__/AddOnboardingDetails'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {PageLoading} from '~/components/PageLoading'
import {Token} from '~/components/TokenContext'
import {CurrentOrganisationData} from '~/components/__types__/CurrentOrganisationData'
import {CURRENT_ORGANISATION_DATA} from '~/components/CurrentOrganisationContext'

const ADD_ONBOARDING_DETAILS = gql`
    mutation AddOnboardingDetails($firstName: String!, $lastName: String!, $organisationName: String!) {
        addOnboardingDetails(firstName: $firstName, lastName: $lastName, organisationName: $organisationName) {
            idStr
        }
    }
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const detailsSchema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    organisationName: yup.string().required(),
})
const initialValues = {firstName: '', lastName: '', organisationName: ''}

const useAddOnboardingDetails = () =>
    useMutation<AddOnboardingDetails, AddOnboardingDetailsVariables>(ADD_ONBOARDING_DETAILS)

export const AddDetails = ({setOrganisationIdStr}: {setOrganisationIdStr: (idStr: string) => void}) => {
    const {token} = Token.useContainer()
    const {data, loading} = useQuery<CurrentOrganisationData>(CURRENT_ORGANISATION_DATA, {
        skip: !token,
    })
    const [addOnboardingDetails, {data: newOrganisation, loading: creatingOrganisation}] = useAddOnboardingDetails()
    const {push} = useHistory()

    useEffect(() => {
        if (data && data?.organisations.length) {
            push('/')
        }
    }, [data, push])

    useEffect(() => {
        if (newOrganisation) {
            setOrganisationIdStr(newOrganisation.addOnboardingDetails.idStr)
        }
    }, [newOrganisation, setOrganisationIdStr])

    if (loading) return <PageLoading />

    return (
        <>
            <Typography.Heading>Add your details</Typography.Heading>
            <Spacer height={32} />
            <Formik
                onSubmit={(variables) => {
                    addOnboardingDetails({variables})
                }}
                validationSchema={detailsSchema}
                initialValues={initialValues}
                isInitialValid={false}
            >
                {({handleSubmit, isValid}) => {
                    return (
                        <Form onSubmit={handleSubmit} width={420}>
                            <Form.Group name="firstName">
                                <Form.Label>First name</Form.Label>
                                <FormikInput type="text" autoFocus hideError />
                            </Form.Group>
                            <Form.Group name="lastName">
                                <Form.Label>Last name</Form.Label>
                                <FormikInput type="text" hideError />
                            </Form.Group>
                            <Form.Group name="organisationName">
                                <Form.Label>Name of your directory</Form.Label>
                                <FormikInput type="text" hideError />
                            </Form.Group>
                            <Spacer height={16} />
                            <ButtonContainer>
                                <Button
                                    disabled={!isValid || creatingOrganisation}
                                    loading={creatingOrganisation}
                                    variant="primary"
                                    type="submit"
                                >
                                    Continue
                                    <Button.Icon icon={SvgArrowRight} />
                                </Button>
                            </ButtonContainer>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}
