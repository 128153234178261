import {gql, useMutation} from '@apollo/client'
import {FieldArray, FieldArrayRenderProps, useField} from 'formik'
import {
    Button,
    colors,
    Form,
    FormikDropdownSelect,
    FormikInput,
    Icon,
    Panel,
    Spacer,
    Stack,
    Typography,
    useAnalyticsEventTracker,
} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import SvgClose from 'nf-ui/Icons/Close'
import SvgDelete from 'nf-ui/Icons/Delete'
import React, {useRef, useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {PanelLayout, PanelLayoutButtons} from '~/components/PanelLayout'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {PANEL_WIDTH} from '~/constants'
import {AdditionalFieldType} from '~/globalTypes'
import {DATA_SOURCES} from '~/pages/Adminland/Integrations'
import {getGraphQLErrorMessage} from '~/util'
import {DeleteAdditionalField, DeleteAdditionalFieldVariables} from './__types__/DeleteAdditionalField'

export type Option = {name: string}[]

export type Field = {
    type: AdditionalFieldType
    name: string
    helpText: string
    options?: Option
    idStr?: string
}

type FieldFormProps = {
    index: number
    field: Partial<Field>
    fieldsHelpers: FieldArrayRenderProps
}

const FieldTypeMap: Record<string, string> = {
    [AdditionalFieldType.TEXT]: 'Text field',
    [AdditionalFieldType.MOBILE_NUMBER]: 'Telephone number',
    [AdditionalFieldType.EMAIL]: 'Email',
    [AdditionalFieldType.DATE]: 'Date',
    [AdditionalFieldType.SELECT_ONE]: 'Choose one',
    [AdditionalFieldType.SELECT_MULTIPLE]: 'Choose multiple',
}

const FieldWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    gap: 16px 16px;
    align-items: flex-start;
    grid-template-areas: '. . .';
`

const OptionWrapper = styled.div`
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 13px;
`

const Option = ({value, onClear, clearable}: {value: string; onClear: () => void; clearable: boolean}) => (
    <OptionWrapper>
        <Typography.Paragraph bottomMargin={false}>{value}</Typography.Paragraph>
        {clearable && (
            <div style={{cursor: 'pointer'}} onClick={onClear}>
                <Icon icon={SvgClose} tint={colors.black} />
            </div>
        )}
    </OptionWrapper>
)

const DELETE_FIELD = gql`
    mutation DeleteAdditionalField($organisationIdStr: String!, $idStr: String!) {
        deleteAdditionalField(organisationIdStr: $organisationIdStr, idStr: $idStr)
    }
`

const AVAILABLE_FIELD_TYPES = Object.keys(FieldTypeMap).map((type) => {
    return {label: FieldTypeMap[type], value: (type as any) as AdditionalFieldType}
})

const Options = ({disabled, index}: {disabled: boolean; index: number}) => {
    const [options] = useField<Field['options']>(`fields[${index}].options`)
    const [newOption, setNewOption] = useState('')

    return (
        <FieldArray name={`fields[${index}].options`}>
            {(optionsHelpers) => (
                <>
                    <Spacer height={8} />
                    <Stack space={8}>
                        {options.value?.map((option, optionIndex) => (
                            <Option
                                key={optionIndex}
                                clearable={!disabled}
                                onClear={() => optionsHelpers.remove(optionIndex)}
                                value={option.name}
                            />
                        ))}
                    </Stack>
                    {!disabled && (
                        <>
                            <Spacer height={8} />
                            <Form.Input value={newOption} onChange={setNewOption} />
                            <Spacer height={16} />
                            <Button
                                variant="secondary"
                                disabled={!newOption.length}
                                onClick={() => {
                                    optionsHelpers.push({name: newOption})
                                    setNewOption('')
                                }}
                            >
                                Add option
                            </Button>
                        </>
                    )}
                </>
            )}
        </FieldArray>
    )
}

export const isSelectField = (type: AdditionalFieldType) =>
    [AdditionalFieldType.SELECT_ONE, AdditionalFieldType.SELECT_MULTIPLE].includes(type)

export const FieldForm = ({index, field, fieldsHelpers}: FieldFormProps) => {
    const deleteButtonRef = useRef<HTMLButtonElement>(null)
    const [panelOpen, setPanelOpen] = useState(false)
    const editable = true

    return (
        <>
            <FieldWrapper>
                <Form.Group name={`fields[${index}].name`}>
                    <FormikInput placeholder="Field Name" disabled={!editable} hideError />
                </Form.Group>

                <div style={{height: 40, display: 'flex', alignItems: 'center'}}>
                    <SvgArrowRight />
                </div>

                <div>
                    <Form.Group name={`fields[${index}].type`}>
                        <FormikDropdownSelect options={AVAILABLE_FIELD_TYPES} disabled={!editable} />
                    </Form.Group>
                    {isSelectField(field.type!) && <Options disabled={!editable} index={index} />}
                </div>

                <Form.Group name={`fields[${index}].helpText`}>
                    <FormikInput
                        name={`fields[${index}].helpText`}
                        placeholder="Description (optional)"
                        disabled={!editable}
                        hideError
                    />
                </Form.Group>
            </FieldWrapper>

            <Spacer height={16} />
            <Button ref={deleteButtonRef} variant="tertiary" color={colors.red} onClick={() => setPanelOpen(true)}>
                Delete <Button.Icon icon={SvgDelete} />
            </Button>
            <Panel targetRef={deleteButtonRef} open={panelOpen} onClose={() => setPanelOpen(false)}>
                <DeletePanel field={field} remove={fieldsHelpers.remove} index={index} setPanelOpen={setPanelOpen} />
            </Panel>
        </>
    )
}

const DeletePanel: React.FC<{
    field: Partial<Field>
    remove: FieldArrayRenderProps['remove']
    index: number
    setPanelOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({field, remove, index, setPanelOpen}) => {
    const alert = useAlert()
    const organisationIdStr = useOrganisationIdStr()
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const [deleteFieldMutation, {loading}] = useMutation<DeleteAdditionalField, DeleteAdditionalFieldVariables>(
        DELETE_FIELD,
        {refetchQueries: [{query: DATA_SOURCES, variables: {organisationIdStr}}]},
    )

    const deleteField = async () => {
        try {
            if (field.idStr) {
                await deleteFieldMutation({variables: {organisationIdStr, idStr: field.idStr}})
                remove(index)
            } else {
                remove(index)
            }
            trackAnalyticsEvent('delete_field')
            setPanelOpen(false)
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }
    return (
        <PanelLayout width={PANEL_WIDTH}>
            <Typography.Label>Delete this field?</Typography.Label>
            <Typography.Paragraph>
                Would you like to delete this field?{' '}
                {field.idStr && 'All collected data for this field will be deleted. This action is irreversible.'}
            </Typography.Paragraph>
            <PanelLayoutButtons>
                <Button
                    variant="tertiary"
                    onClick={() => {
                        setPanelOpen(false)
                    }}
                >
                    No, keep
                </Button>
                <Button color={colors.red} variant="tertiary" loading={loading} onClick={deleteField}>
                    Yes, delete
                </Button>
            </PanelLayoutButtons>
        </PanelLayout>
    )
}
