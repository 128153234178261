import {AnimatePresence} from 'framer-motion'
import {Stepper} from 'nf-ui'
import React, {FC, useCallback, useEffect, useState} from 'react'
import {v4} from 'uuid'
import {ModalLayout} from '~/components/ModalLayout'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {PageLoading} from '~/components/PageLoading'
import {DataSourceFileType, DataSourceType, FieldType} from '~/globalTypes'
import {capitalize} from '~/util'
import {AddDataSource} from './AddDataSourceModal'
import {CancelIntegrationsModal} from './CancelIntegrationsModal'
import {Data} from './ConfigureDataSource/Data'
import {MappingSummary} from './ConfigureDataSource/MappingSummary'
import {MatchColumns} from './ConfigureDataSource/MatchColumns'
import {useAvailableFields} from './ConnectPrimaryDataSource/useAvailableFields'
import {AvailableFields_availableFields} from './ConnectPrimaryDataSource/__types__/AvailableFields'
import {Action, DataSourceStateProvider, Step, StepField, useConfigureDataSource} from './useConfigureDataSource'
import {DataSources_organisation_datasources} from './__types__/DataSources'

export type EditDataSource = {
    dataSourceIdStr: string
    data: Record<string, string>[]
    type: DataSourceType
    fileType: DataSourceFileType | null
}

export type DataSourceData = AddDataSource | EditDataSource

type Field = AvailableFields_availableFields

export type MappedColumn = {
    field: Field
    column: string
}

export function isEditDataSource(editData: any): editData is EditDataSource {
    if (editData == null) return false
    if (!Array.isArray(editData.data)) return false
    if (!editData.dataSourceIdStr) return false
    return editData.type === DataSourceType.DIRECT_UPLOAD || editData.type === DataSourceType.GOOGLE_SHEETS
}

/**
 * Returns the current step field index
 */
export const getCurrentIndex = (allSteps: StepField[], currentColumn: StepField | null) => {
    if (!currentColumn) return -1
    const stepIdStrs = allSteps.map((step) => step.idStr)
    return stepIdStrs.indexOf(currentColumn.idStr)
}

/**
 * Returns the substep index for a given top-level step.
 */
const getSubstepIndex = (step: Step, substeps: string[]) => substeps.findIndex((substep) => step.includes(substep))

export const requiredFieldTypes = [FieldType.FIRST_NAME, FieldType.LAST_NAME, FieldType.EMAIL]

export type ConfigureDataSourceModalProps = {
    primary: boolean
    dataSourceData: DataSourceData
    organisationIdStr: string
    dataSource?: DataSources_organisation_datasources | undefined
    fetchStepFields?: () => StepField[]
}

const useFetchFields = (fetchStepFields?: () => StepField[]) => {
    const [loading, setLoading] = useState(false)
    const {organisationIdStr} = useConfigureDataSource()
    const {fetchAvailableFields} = useAvailableFields(organisationIdStr)

    const forAdditionalData = async () => {
        const availableFields = await fetchAvailableFields()

        return availableFields
            .map((field) => ({
                idStr: field.idStr,
                name: field.name,
                required: field.type ? requiredFieldTypes.includes(field.type) : false,
            }))
            .sort((a, b) => {
                return a.required === b.required ? 0 : a.required ? -1 : 1
            })
    }

    const fetchFunction = fetchStepFields || forAdditionalData

    const fetchFields = async () => {
        if (!loading) setLoading(true)
        const stepFields = await fetchFunction()
        setLoading(false)
        return stepFields
    }

    return {
        fetchFields,
        loading,
    }
}

const configureSteps = (stepFields: StepField[], dispatch: (value: Action) => void) => {
    const stepHeadings = stepFields.map((step) => step.name)

    dispatch({type: 'SET_STEP_FIELDS', stepFields})
    dispatch({
        type: 'SET_TOP_LEVEL_STEPS',
        topLevelStepLabels: [
            ['CONNECT', []],
            ['IDENTIFY', ['column headings', ...stepHeadings]],
            ['FINISH', ['finish']],
        ],
    })
}

const configurePrimaryDatasourceSteps = (stepFields: StepField[], dispatch: (value: Action) => void) => {
    const stepHeadings = stepFields.map((step) => step.name)

    const fields = [...stepFields, {idStr: v4(), name: 'match columns', required: false}]

    dispatch({type: 'SET_STEP_FIELDS', stepFields: fields})
    dispatch({
        type: 'SET_TOP_LEVEL_STEPS',
        topLevelStepLabels: [
            ['CONNECT', []],
            ['IDENTIFY', ['column headings', ...stepHeadings, 'match columns']],
            ['FINISH', ['finish']],
        ],
    })
}

const ConfigureDataSource = ({fetchStepFields}: {fetchStepFields?: () => StepField[]}) => {
    const {onClose} = useModalNavigator()
    const {state, dispatch, cancel, setCancel, topLevelStepIndex, primary} = useConfigureDataSource()
    const currentStepFieldIndex = getCurrentIndex(state.allSteps, state.currentColumn)

    const {fetchFields, loading} = useFetchFields(fetchStepFields)

    const configureDataTable = useCallback(async () => {
        const stepsFields = await fetchFields()
        primary ? configurePrimaryDatasourceSteps(stepsFields, dispatch) : configureSteps(stepsFields, dispatch)
    }, [dispatch, fetchFields, primary])

    useEffect(() => {
        configureDataTable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const requiredFieldsSet = Object.keys(state.selectedColumns).length >= 3
    const shouldScrollPage = primary && requiredFieldsSet ? true : state.completed

    const Identify = primary && requiredFieldsSet ? MatchColumns : Data
    const StepComponent = state.completed ? MappingSummary : Identify

    return (
        <ModalLayout
            scrollProps={{
                noscroll: !shouldScrollPage,
            }}
            footerProps={{
                isOnboarding: false,
                onClickBack: () => dispatch({type: 'PREVIOUS_FIELD'}),
                back: currentStepFieldIndex > -1,
            }}
        >
            {loading && <PageLoading />}
            <ModalLayout.Navigation onClose={() => setCancel(true)}>
                <Stepper currentStepIndex={topLevelStepIndex}>
                    {state.topLevelStepLabels.map(([label, substeps]) => {
                        return (
                            <Stepper.Step
                                label={label}
                                currentStepIndex={getSubstepIndex(
                                    state.currentColumn?.name || 'column headings',
                                    substeps,
                                )}
                                key={label}
                            >
                                {substeps.length > 1
                                    ? substeps.map((substep) => (
                                          <Stepper.Step label={capitalize(substep)} key={capitalize(substep)} />
                                      ))
                                    : null}
                            </Stepper.Step>
                        )
                    })}
                </Stepper>
            </ModalLayout.Navigation>
            {!cancel && <StepComponent dispatch={dispatch} state={state} onClickCancel={onClose} />}
            <AnimatePresence>
                {cancel && <CancelIntegrationsModal onClickComplete={() => setCancel(false)} onClickCancel={onClose} />}
            </AnimatePresence>
        </ModalLayout>
    )
}

export const ConfigureDataSourceModal: FC<ConfigureDataSourceModalProps> = ({
    dataSourceData,
    fetchStepFields,
    primary,
    organisationIdStr,
}) => {
    const {onClose} = useModalNavigator()

    useEffect(() => {
        if (!dataSourceData) onClose()
    }, [dataSourceData, onClose])

    if (!dataSourceData) return null

    return (
        <DataSourceStateProvider initialState={{dataSourceData, primary, organisationIdStr}}>
            <ConfigureDataSource fetchStepFields={fetchStepFields} />
        </DataSourceStateProvider>
    )
}
