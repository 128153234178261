import React, {useRef} from 'react'
import {motion, MotionStyle} from 'framer-motion'
import styled from 'styled-components'
import {Tooltip as UITooltip} from '../Tooltip/Tooltip'
import {FadePresence} from '../FadePresence'
import {ROW_HEIGHT, TRANSITION} from './constants'
import {CellHoverState} from './utils/useCellHover'
import {TableScrollState} from './utils/useTableScroll'

type TableTooltipProps = {
    title?: string
    attachTo: 'column' | 'cell'
    activeCell: CellHoverState['cell']
    tableScroll: TableScrollState
}

type TableTooltipPropsNonNull = {
    [P in keyof TableTooltipProps]-?: NonNullable<TableTooltipProps[P]>
}

export const usePositionTooltip = () => {
    const ref = useRef<HTMLDivElement>(null)

    const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
        if (!ref.current) return
        ref.current.style.left = `${-event.currentTarget.scrollLeft}px`
    }

    return {ref, onScroll}
}

const Container = styled(motion.div)`
    position: absolute;
    bottom: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
`

const Tooltip: React.FC<TableTooltipPropsNonNull> = ({title, attachTo, activeCell, tableScroll}) => {
    const animate = {translateX: activeCell.offset, width: activeCell.size, translateY: 0}
    const style: MotionStyle = {left: tableScroll.inverse.left}

    if (attachTo === 'cell') {
        animate.translateY = activeCell.top - ROW_HEIGHT
        style.top = tableScroll.inverse.top
    }

    return (
        <Container initial={false} transition={TRANSITION} animate={animate} style={style}>
            <UITooltip size="large" title={title!} position="top" color="primary" />
        </Container>
    )
}

export const TableTooltip: React.FC<TableTooltipProps> = ({title, activeCell, ...rest}) => {
    return (
        <FadePresence>
            {title && activeCell && <Tooltip title={title} activeCell={activeCell} {...rest} />}
        </FadePresence>
    )
}
