import {useCallback} from 'react'
import {useAlert} from 'react-alert'

export function useMessages() {
    const alert = useAlert()
    const onCompleted = useCallback(() => alert.success('Successfully Updated'), [alert])

    return {
        onCompleted,
    }
}
