import React, {useEffect, useRef, useState} from 'react'
import {playground} from '~/pages/Playground'
import {Column, Row} from '../Primitives/Layout'

import {Form} from './Form'
import {AddAnotherParentButton, Heading3, SelectPhoto} from '../Primitives'

import {createDetector, FaceLandmarksDetector, SupportedModels} from '@tensorflow-models/face-landmarks-detection'
import {Spinner} from 'nf-ui'

const DEBUG = 0

type Rectangle = {x: number; y: number; w: number; h: number; tag?: string}
export type Region = {left: number; top: number; width: number; height: number}

const rectangleEqual = (rect1: Rectangle, rect2: Rectangle) =>
    rect1.x === rect2.x && rect1.y === rect2.y && rect1.w === rect2.w && rect1.h === rect2.h

const rectangleVerySimilar = (rect1: Rectangle, rect2: Rectangle) =>
    Math.abs(rect1.x - rect2.x) +
        Math.abs(rect1.y - rect2.y) +
        Math.abs(rect1.x + rect1.w - rect2.x - rect2.w) +
        Math.abs(rect1.y + rect1.h - rect2.y - rect2.h) <
    16

const rectangleHasOverlap = (rect1: Rectangle, rect2: Rectangle) =>
    !(rect1.x + rect1.w < rect2.x) &&
    !(rect2.x + rect2.w < rect1.x) &&
    !(rect1.y + rect1.h < rect2.y) &&
    !(rect2.y + rect2.h < rect1.y)

const rectangleHasPoint = (rect: Rectangle, {x, y}: {x: number; y: number}) =>
    rect.x <= x && rect.y <= y && rect.x + rect.w >= x && rect.y + rect.h >= y

const rectangleDistanceFromCenter = (rect: Rectangle, {x, y}: {x: number; y: number}) =>
    Math.sqrt(Math.pow(rect.x + rect.w / 2 - x, 2) + Math.pow(rect.y + rect.h / 2 - y, 2))

const padRectBy = (padding: number, rect: Rectangle, maxWidth: number, maxHeight: number) => {
    const pad = Math.max(
        0,
        Math.min(
            padding,
            rect.x / rect.w,
            rect.y / rect.h,
            (maxWidth - (rect.x + rect.w)) / rect.w,
            (maxHeight - (rect.y + rect.h)) / rect.h,
        ),
    )
    return {
        x: rect.x - rect.w * pad,
        y: rect.y - rect.h * pad,
        w: rect.w * (1 + pad * 2),
        h: rect.h * (1 + pad * 2),
        tag: rect.tag,
    }
}

const generateEnclosingRectangle = (rectangles: Rectangle[]): Rectangle[] => {
    if (rectangles.length === 0) return []
    if (rectangles.length === 1) return rectangles
    if (rectangles.length === 2)
        return [
            {
                x: Math.min(rectangles[0].x, rectangles[1].x),
                y: Math.min(rectangles[0].y, rectangles[1].y),
                w:
                    Math.max(rectangles[0].x + rectangles[0].w, rectangles[1].x + rectangles[1].w) -
                    Math.min(rectangles[0].x, rectangles[1].x),
                h:
                    Math.max(rectangles[0].y + rectangles[0].h, rectangles[1].y + rectangles[1].h) -
                    Math.min(rectangles[0].y, rectangles[1].y),
            },
        ]
    return generateEnclosingRectangle([rectangles[0], ...generateEnclosingRectangle(rectangles.slice(1))])
}

const mergeOverlappingRectangles = (rectangles: Rectangle[][]): Rectangle[][] => {
    if (rectangles.length === 0) return []
    if (rectangles.length === 1) return rectangles
    if (rectangles.length === 2)
        return rectangles[0].some((rect1) => rectangles[1].some((rect2) => rectangleHasOverlap(rect1, rect2)))
            ? [[...rectangles[0], ...rectangles[1]]]
            : rectangles
    const overlap = rectangles
        .slice(1)
        .findIndex((rects) => rects.some((rect1) => rectangles[0].some((rect2) => rectangleHasOverlap(rect1, rect2))))
    if (overlap === -1) {
        return [rectangles[0], ...mergeOverlappingRectangles(rectangles.slice(1))]
    }
    return mergeOverlappingRectangles([
        ...mergeOverlappingRectangles([rectangles[0], rectangles[overlap + 1]]),
        ...rectangles.slice(1, overlap + 1),
        ...rectangles.slice(overlap + 2),
    ])
}

const enclosingAnotherCenter = (rectangles: Rectangle[]): Rectangle[] => {
    return rectangles.filter(
        (rect1, index) =>
            [...rectangles.slice(0, index), ...rectangles.slice(index + 1)].filter(
                (rect2) =>
                    rect2.x + rect2.w / 2 > rect1.x &&
                    rect2.x + rect2.w / 2 < rect1.x + rect1.w &&
                    rect2.y + rect2.h / 2 > rect1.y &&
                    rect2.y + rect2.h / 2 < rect1.y + rect1.h &&
                    rect2.w * rect2.h < rect1.w * rect1.h,
            ).length > 0,
    )
}

const enclosedCenters = (enclosing: Rectangle, rectangles: Rectangle[]): Rectangle[] => {
    return rectangles.filter(
        (enclosed) =>
            enclosed.x + enclosed.w / 2 > enclosing.x &&
            enclosed.x + enclosed.w / 2 < enclosing.x + enclosing.w &&
            enclosed.y + enclosed.h / 2 > enclosing.y &&
            enclosed.y + enclosed.h / 2 < enclosing.y + enclosing.h &&
            enclosed.w * enclosed.h < enclosing.w * enclosing.h,
    )
}

const rectanglesDifference = (rectangles: Rectangle[], exclude: Rectangle[]): Rectangle[] => {
    return rectangles.filter((rect1) => !exclude.some((rect2) => rectangleEqual(rect1, rect2)))
}

const removeUnnecessaryRectangles = (rectangles: Rectangle[]): Rectangle[] => {
    // const excludeEnclosingAnother = rectanglesDifference(rectangles, enclosingAnotherCenter(rectangles))

    const excludeContained = rectanglesDifference(
        rectangles,
        enclosingAnotherCenter(rectangles).flatMap((rect) => enclosedCenters(rect, rectangles)),
    )

    const excludeVerySimilar = excludeContained.filter((rect1, index) => {
        return !excludeContained.slice(0, index).some((rect2) => rectangleVerySimilar(rect1, rect2))
    })

    return excludeVerySimilar
}

const _createDetector = async () => {
    const detector = await createDetector(SupportedModels.MediaPipeFaceMesh, {
        runtime: 'mediapipe',
        solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh',
        refineLandmarks: false,
        maxFaces: 10,
    })
    // console.log('Detector created')
    return detector
}

let detector: Promise<FaceLandmarksDetector>

const drawRoundedCornerRectanglePath = (
    context: CanvasRenderingContext2D,
    {x, y, w, h, borderRadius, reverse}: {borderRadius?: number; reverse?: boolean} & Rectangle,
) => {
    const r = Math.max(0, borderRadius || 0)
    context.moveTo(x + r, y)
    if (reverse) {
        r > 0 && context.arcTo(x, y, x, y + r, r)
        context.lineTo(x, y + h - r)
        r > 0 && context.arcTo(x, y + h, x + r, y + h, r)
        context.lineTo(x + w - r, y + h)
        r > 0 && context.arcTo(x + w, y + h, x + w, y + h - r, r)
        context.lineTo(x + w, y + r)
        r > 0 && context.arcTo(x + w, y, x + w - r, y, r)
        context.lineTo(x + r, y)
    } else {
        context.lineTo(x + w - r, y)
        r > 0 && context.arcTo(x + w, y, x + w, y + r, r)
        context.lineTo(x + w, y + h - r)
        r > 0 && context.arcTo(x + w, y + h, x + w - r, y + h, r)
        context.lineTo(x + r, y + h)
        r > 0 && context.arcTo(x, y + h, x, y + h - r, r)
        context.lineTo(x, y + r)
        r > 0 && context.arcTo(x, y, x + r, y, r)
    }
}

const drawRectangle = (
    context: CanvasRenderingContext2D,
    {
        strokeColor,
        fillColor,
        x,
        y,
        w,
        h,
        thickness,
        borderRadius,
    }: {
        strokeColor?: string
        fillColor?: string
        thickness?: number
        borderRadius?: number
    } & Rectangle,
) => {
    context.beginPath()
    drawRoundedCornerRectanglePath(context, {x, y, w, h, borderRadius})
    if (thickness && thickness > 1) {
        context.lineTo(x + (borderRadius || 0), y + thickness)
        drawRoundedCornerRectanglePath(context, {
            x: x + thickness,
            y: y + thickness,
            w: w - thickness * 2,
            h: h - thickness * 2,
            borderRadius: (borderRadius || 0) - thickness,
            reverse: true,
        })
        context.lineTo(x + (borderRadius || 0), y)

        if (strokeColor) {
            context.fillStyle = strokeColor
            context.fill()
        }
        if (fillColor) {
            context.beginPath()
            drawRoundedCornerRectanglePath(context, {
                x: x + thickness,
                y: y + thickness,
                w: w - thickness * 2,
                h: h - thickness * 2,
                borderRadius: (borderRadius || 0) - thickness,
            })
            context.fillStyle = fillColor
            context.fill()
        }
    } else {
        if (fillColor) {
            context.fillStyle = fillColor
            context.fill()
        }
        if (strokeColor) {
            context.strokeStyle = strokeColor
            context.stroke()
        }
    }
}

export const UploadFamilyPhoto = ({
    directoryName,
    pupilFirstName,
    heading,
    originalFile,
    setOriginalFile,
    thumbRegion,
    setThumbRegion,
    onNext,
    onBack,
}: {
    directoryName: string
    pupilFirstName: string
    heading: string
    originalFile: File | undefined
    setOriginalFile: (value: File | undefined) => void
    thumbRegion: Region | undefined
    setThumbRegion: (value: Region | undefined) => void
    onNext: () => Promise<void>
    onBack: () => void
}) => {
    detector = detector || _createDetector()

    // const imageRef = React.createRef<HTMLImageElement>()
    const imageCanvasRef = React.createRef<HTMLCanvasElement>()
    const drawingCanvasRef = React.createRef<HTMLCanvasElement>()
    const [image, setImage] = useState<HTMLImageElement | undefined>(undefined)
    const [detectedRectangles, setDetectedRectangles] = useState<Rectangle[] | undefined>(undefined)
    const [hoverRectangle, setHoverRectangle] = useState<Rectangle | undefined>(undefined)
    const [selectedRectangle, setSelectedRectangle] = useState<Rectangle | undefined>(undefined)
    const _debugDetections = useRef<{imageData: ImageData; rectangles: Rectangle[]}[]>()
    const [debugDetections, setDebugDetections] = useState<{imageData: ImageData; rectangles: Rectangle[]}[]>([])

    const detectFaces = async (
        image: CanvasRenderingContext2D,
        increment: number,
        searchRectangle: Rectangle,
    ): Promise<Rectangle[]> => {
        const results: Rectangle[] = []
        const horizontalPoints = Math.max(1, Math.ceil((searchRectangle.w - increment) / increment))
        const verticalPoints = Math.max(1, Math.ceil((searchRectangle.h - increment) / increment))
        const incrementX =
            horizontalPoints === 1 ? increment : (searchRectangle.w - increment * 2) / (horizontalPoints - 1)
        const incrementY = verticalPoints === 1 ? increment : (searchRectangle.h - increment * 2) / (verticalPoints - 1)

        const newDetections: {imageData: ImageData; rectangles: Rectangle[]}[] = []

        for (let x = 0; x < horizontalPoints; x++) {
            for (let y = 0; y < verticalPoints; y++) {
                const imageData = image.getImageData(
                    x * incrementX + searchRectangle.x,
                    y * incrementY + searchRectangle.y,
                    Math.min(searchRectangle.w, increment * 2),
                    Math.min(searchRectangle.h, increment * 2),
                )
                const rectangles = (await (await detector).estimateFaces(imageData)).map(({box}) => ({
                    x: box.xMin + x * incrementX + searchRectangle.x,
                    y: box.yMin + y * incrementY + searchRectangle.y,
                    w: box.width,
                    h: box.height,
                }))
                await new Promise((resolve) => setTimeout(resolve))
                DEBUG &&
                    newDetections.push({
                        imageData,
                        rectangles: rectangles.map((rect) => ({
                            ...rect,
                            x: rect.x - x * incrementX + searchRectangle.x,
                            y: rect.y - y * incrementY + searchRectangle.y,
                        })),
                    })
                results.push(...rectangles)
            }
        }

        DEBUG && (_debugDetections.current = [..._debugDetections.current!, ...newDetections])
        DEBUG && setDebugDetections(_debugDetections.current!)

        return results
    }

    const redrawRectangles = () => {
        if (drawingCanvasRef.current) {
            const context = drawingCanvasRef.current.getContext('2d')
            context!.clearRect(0, 0, drawingCanvasRef.current.width, drawingCanvasRef.current.height)
            const rectangles = [
                ...(detectedRectangles || []).filter(
                    (rect) => !hoverRectangle || !rectangleEqual(rect, hoverRectangle),
                ),
                ...(hoverRectangle ? [hoverRectangle] : []),
            ]
            rectangles.forEach((rect) => {
                if (
                    (hoverRectangle && rectangleEqual(hoverRectangle, rect)) ||
                    (selectedRectangle && rectangleEqual(selectedRectangle, rect))
                ) {
                    drawRectangle(context!, {
                        ...rect,
                        borderRadius: 3,
                        thickness: 2,
                        strokeColor: 'rgb(60,142,255)',
                        fillColor: 'rgba(60,142,255,0.2)',
                    })
                } else {
                    drawRectangle(context!, {
                        ...rect,
                        borderRadius: 3,
                        thickness: 2,
                        strokeColor: 'white',
                        fillColor: 'rgba(255,255,255,0.2)',
                    })
                }
            })
        }
    }

    const detectHoverRectangleChange = (mouseX: number, mouseY: number) => {
        if (!detectedRectangles) {
            if (hoverRectangle) {
                setHoverRectangle(undefined)
            }
            return
        }
        const boundingRect = drawingCanvasRef.current!.getBoundingClientRect()
        const mouse = {x: mouseX - boundingRect.x - window.scrollX, y: mouseY - boundingRect.y - window.scrollY}
        const nearest: Rectangle | undefined = detectedRectangles
            .filter((rect) => rectangleHasPoint(rect, mouse))
            .map((rect) => ({rect, distance: rectangleDistanceFromCenter(rect, mouse)}))
            .sort((a, b) => (a.distance === b.distance ? 0 : a.distance < b.distance ? -1 : 1))
            .map((pair) => pair.rect)[0]
        if (!nearest) {
            if (hoverRectangle) {
                setHoverRectangle(undefined)
            }
            return
        }
        if (!hoverRectangle || !rectangleEqual(nearest, hoverRectangle)) {
            setHoverRectangle(nearest)
        }
    }

    useEffect(() => {
        // console.log(hoverRectangle)
        redrawRectangles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoverRectangle, selectedRectangle])

    useEffect(() => {
        if (hoverRectangle) {
            setHoverRectangle(undefined)
        } else {
            redrawRectangles()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detectedRectangles])

    useEffect(() => {
        if (image && imageCanvasRef.current) {
            const scale = Math.min(
                Math.min(
                    image.width,
                    window.innerWidth > 1080
                        ? 880
                        : window.innerWidth > 809
                        ? window.innerWidth - 200
                        : window.innerWidth - 50,
                ) / image.width,
                Math.min(image.height, window.innerHeight * 0.7) / image.height,
            )

            const canvas = imageCanvasRef.current
            const context = canvas.getContext('2d')!
            canvas.width = image.width * scale
            canvas.height = image.height * scale
            context.drawImage(image, 0, 0, canvas.width, canvas.height)

            if (drawingCanvasRef.current) {
                drawingCanvasRef.current.width = canvas.width
                drawingCanvasRef.current.height = canvas.height
                const drawingContext = drawingCanvasRef.current.getContext('2d')!
                drawRectangle(drawingContext, {
                    fillColor: 'rgba(255, 255, 255, 0.7)',
                    x: 0,
                    y: 0,
                    w: canvas.width,
                    h: canvas.height,
                })
            }

            setTimeout(async () => {
                DEBUG && (_debugDetections.current = [])
                setDebugDetections([])
                let increment = Math.max(canvas.width, canvas.height)
                let stage1: Rectangle[] = []
                while (!stage1.length && increment > 40) {
                    stage1 = await detectFaces(context, Math.max(80, increment), {
                        x: 0,
                        y: 0,
                        w: canvas.width,
                        h: canvas.height,
                    })
                    increment = increment / 2
                }

                increment = Math.max(
                    60,
                    stage1.reduce((prev, rectangle) => Math.max(prev, rectangle.w, rectangle.h), 0),
                )

                const stage2 = await detectFaces(context, increment, {x: 0, y: 0, w: canvas.width, h: canvas.height})
                const stage2Merged = mergeOverlappingRectangles([...stage1, ...stage2].map((rectangle) => [rectangle]))

                const stage2Groups = stage2Merged
                    .filter((rects) => rects.length > 1)
                    .flatMap(generateEnclosingRectangle)
                    .map((rect) => padRectBy(0.25, rect, canvas.width, canvas.height))

                const stage3 = await stage2Groups.reduce<Promise<Rectangle[]>>(async (prev, rect) => {
                    const prevResult = await prev
                    const thisResult = await detectFaces(context, Math.max(rect.w, rect.h), rect)
                    return [...prevResult, ...thisResult]
                }, new Promise((resolve) => resolve([])))

                const stage3Combined = [
                    ...stage1.map((rect) => ({...rect, tag: 'stage1'})),
                    ...stage2Merged
                        .filter((rects) => rects.length === 1)
                        .flatMap(generateEnclosingRectangle)
                        .map((rect) => ({...rect, tag: 'stage2'})),
                    ...stage3.map((rect) => ({...rect, tag: 'stage3'})),
                ].map((rect) => padRectBy(0.25, rect, canvas.width, canvas.height))

                const enclosingAnother = enclosingAnotherCenter(stage3Combined)
                const enclosedByAnother = enclosingAnother.flatMap((rect) => enclosedCenters(rect, stage3Combined))
                const stage4 = await rectanglesDifference(enclosingAnother, enclosedByAnother).reduce<
                    Promise<Rectangle[]>
                >(async (prev, rect) => {
                    const prevResult = await prev
                    const padded = padRectBy(0, rect, canvas.width, canvas.height)
                    const thisResult = await detectFaces(context, Math.max(padded.w, padded.h), padded)
                    return [...prevResult, ...thisResult]
                }, new Promise((resolve) => resolve([])))

                const stage4Combined = [
                    ...rectanglesDifference(stage3Combined, [
                        ...enclosingAnother,
                        ...enclosingAnother.flatMap((rect) => enclosedCenters(rect, stage3Combined)),
                    ]),
                    ...stage4.map((rect) => ({...padRectBy(0.25, rect, canvas.width, canvas.height), tag: 'stage4'})),
                ]

                setDetectedRectangles(removeUnnecessaryRectangles(stage4Combined))
            }, 20)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image])

    useEffect(() => {
        if (originalFile && imageCanvasRef.current) {
            // const image = imageRef.current!
            setDetectedRectangles(undefined)
            const reader = new FileReader()
            reader.onload = (e) => {
                const img = new Image()
                img.addEventListener(
                    'load',
                    () => {
                        setImage(img)
                    },
                    false,
                )
                img.src = e.target!.result as string
            }
            reader.readAsDataURL(originalFile)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalFile])

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (originalFile) {
        return (
            <Form
                directoryName={directoryName}
                heading={`Tap on ${pupilFirstName}`}
                subHeading={`This will create a thumbnail for ${pupilFirstName}'s profile. You can change your family photo at any time.`}
                progress={3}
                onBack={onBack}
                onNext={onNext}
            >
                <Column>
                    <Row position="relative">
                        <canvas
                            ref={drawingCanvasRef}
                            style={{
                                position: 'absolute',
                                cursor: detectedRectangles === undefined ? undefined : 'pointer',
                            }}
                            onMouseMove={(event) => detectHoverRectangleChange(event.pageX, event.pageY)}
                            onClick={() => {
                                if (!hoverRectangle) return

                                if (selectedRectangle && rectangleEqual(selectedRectangle, hoverRectangle)) {
                                    setSelectedRectangle(undefined)
                                    setThumbRegion(undefined)
                                    return
                                }

                                setSelectedRectangle(hoverRectangle)

                                const ratio =
                                    image?.width && imageCanvasRef.current
                                        ? image.width / imageCanvasRef.current?.width
                                        : 1

                                setThumbRegion({
                                    left: Math.floor(hoverRectangle.x * ratio),
                                    top: Math.floor(hoverRectangle.y * ratio),
                                    width: Math.ceil(hoverRectangle.w * ratio),
                                    height: Math.ceil(hoverRectangle.h * ratio),
                                })
                            }}
                        ></canvas>
                        {originalFile && detectedRectangles === undefined && (
                            <Row position="absolute" centerHorizontal top="0px" right="0px" bottom="0px" left="0px">
                                <Column height="100%" position="absolute" centerVertical>
                                    <Row grow={0} backgroundColor="white" borderRadius="5px" padding="10px 20px">
                                        <Column grow={0}>
                                            <Heading3>Processing...</Heading3>
                                        </Column>
                                        <Column grow={0} width="20px"></Column>
                                        <Column grow={0} centerVertical>
                                            <Spinner tint="#5c5c5c" size={20}></Spinner>
                                        </Column>
                                    </Row>
                                </Column>
                            </Row>
                        )}
                        <canvas ref={imageCanvasRef}></canvas>
                    </Row>
                    <Row height="10px"></Row>
                    <Row alignLeft>
                        <SelectPhoto onSelectPhoto={setOriginalFile}>Change your family photo</SelectPhoto>
                        <Column width="16px"></Column>
                        <Column centerVertical>
                            <AddAnotherParentButton
                                onClick={() => {
                                    setOriginalFile(undefined)
                                }}
                            >
                                Remove photo
                            </AddAnotherParentButton>
                        </Column>
                    </Row>
                    {DEBUG ? (
                        debugDetections.map(({imageData, rectangles}, index) => (
                            <Row key={index}>
                                <canvas
                                    onMouseEnter={(event) => {
                                        const element = event.currentTarget
                                        element.width = imageData.width
                                        element.height = imageData.height
                                        const context = element.getContext('2d')!
                                        context.putImageData(imageData, 0, 0)
                                        rectangles.forEach((rectangle) =>
                                            drawRectangle(context, {
                                                strokeColor: 'white',
                                                ...rectangle,
                                                x: rectangle.x,
                                                y: rectangle.y,
                                                thickness: 1,
                                                borderRadius: 1,
                                            }),
                                        )
                                    }}
                                ></canvas>
                            </Row>
                        ))
                    ) : (
                        <></>
                    )}
                </Column>
            </Form>
        )
    }

    return (
        <Form
            directoryName={directoryName}
            heading={heading}
            subHeading="The best photos show the faces of your family clearly. Please avoid photos with sunglasses if possible."
            progress={3}
            onBack={onBack}
            onNext={onNext}
        >
            <Column>
                <SelectPhoto onSelectPhoto={setOriginalFile}>Click to select a photo</SelectPhoto>
            </Column>
        </Form>
    )
}

playground.push({
    path: 'src/components/CommunityBuild/UploadFamilyPhoto.tsx',
    component: UploadFamilyPhoto,
    props: {
        heading: 'Household 1: Upload a family photo',
        directoryName: 'Webpups Grade 3',
        pupilFirstName: 'Hannah',
        originalFile: undefined,
        thumbRegion: undefined,
    },
    propOptions: {
        pupilFirstName: {
            get: (props: any) => props.pupilFirstName || '',
            set: (props: any, value: string) => ({...props, pupilFirstName: value}),
        },
        originalFile: {
            get: (props: any) => props.originalFile?.name || '',
        },
        setOriginalFile: ({props, args}: {props: any; args: any[]}) => ({...props, originalFile: args[0] as File}),
        thumbRegion: {
            get: (props: any) => JSON.stringify(props.thumbRegion),
        },
        setThumbRegion: ({props, args}: {props: any; args: any[]}) => ({...props, thumbRegion: args[0] as Region}),
        onNext: () => {},
        onBack: () => {},
    },
})
