import {css} from 'styled-components'
import {HideableCssProps, PositionCssProps} from './types'
import {
    BREAKPOINT_DESKTOP_MIN,
    BREAKPOINT_TABLET_MAX,
    BREAKPOINT_TABLET_MIN,
    BREAKPOINT_PHONE_MAX,
    BREAKPOINT_PHONE_MIN,
    BREAKPOINT_SMALL_MAX,
} from './breakpoints'

export const PositionCss = css<PositionCssProps>`
    ${({position}) => position && `position: ${position};`}
    ${({top}) => top && `top: ${top};`}
    ${({right}) => right && `right: ${right};`}
    ${({bottom}) => bottom && `bottom: ${bottom};`}
    ${({left}) => left && `left: ${left};`}
`

export const HideableCss = css<HideableCssProps>`
    ${({deviceWidths: deviceWidth}) => `
        ${
            deviceWidth && !deviceWidth.includes('Desktop')
                ? `
            @media (min-width: ${BREAKPOINT_DESKTOP_MIN}) {
                display: none;
            }
        `
                : ''
        }
        ${
            deviceWidth && !deviceWidth.includes('Tablet')
                ? `
            @media (min-width: ${BREAKPOINT_TABLET_MIN}) and (max-width: ${BREAKPOINT_TABLET_MAX}) {
                display: none;
            }
        `
                : ''
        }
        ${
            deviceWidth && !deviceWidth.includes('Phone')
                ? `
            @media (min-width: ${BREAKPOINT_PHONE_MIN}) and (max-width: ${BREAKPOINT_PHONE_MAX}) {
                display: none;
            }
        `
                : ''
        }
        ${
            deviceWidth && !deviceWidth.includes('Small')
                ? `
            @media (max-width: ${BREAKPOINT_SMALL_MAX}) {
                display: none;
            }
        `
                : ''
        }
    `}
`
