import {motion, Transition} from 'framer-motion'
import React, {useCallback, useContext, useState} from 'react'
import styled from 'styled-components'
import {SelectionModeContext} from '../AdminPhotosList/AdminPhotosList'

export const TRANSITION: Transition = {
    duration: 0.3,
    easings: 'cubic-bezier(0.25, 1, 0.25, 1)',
}

export const TranslateIn: React.FC<{distance: number}> = ({children, distance}) => (
    <motion.div
        style={{willChange: 'transform'}}
        variants={{open: {y: 0}, closed: {y: distance}}}
        transition={TRANSITION}
    >
        {children}
    </motion.div>
)

const fadeIn = {
    variants: {
        closed: {opacity: 0},
        open: {opacity: 1},
    },
    initial: 'closed',
    animate: 'open',
    exit: 'closed',
    transition: TRANSITION,
}

const ContentWrapper = styled(motion.div).attrs(() => fadeIn)<{padding?: boolean}>`
    position: absolute;
    left: 0;
    right: 0;
    padding: ${({padding = true}) => (padding ? 8 : 0)}px;
`

export const TopContentWrapper = styled(ContentWrapper)<{rightAligned?: boolean}>`
    top: 0;
    left: ${({rightAligned}) => (rightAligned ? 'unset' : 0)};
`

export const BottomContentWrapper = styled(ContentWrapper)`
    bottom: 0;
`

export const useSelectionMode = (idStr?: string) => {
    const context = useContext(SelectionModeContext)
    if (!context) throw new Error('`useSelectionMode` used outside of a `SelectionModeContext`')

    return {
        ...context,
        selectable: context.selectionMode !== null,
        selected: idStr ? context.isPhotoSelected(idStr) : false,
    }
}

export const useSinglePhotoSelection = (idStr: string) => {
    const context = useContext(SelectionModeContext)
    if (!context) throw new Error('`useSelectionMode` used outside of a `SelectionModeContext`')

    return {
        setSingleSelectedPhoto: context.setSingleSelectedPhoto,
        selected: context.isSinglePhotoSelected(idStr),
    }
}

export type Hovering<T extends string> = Record<T, boolean>
export type SetHovering<T extends string> = (name: T, hovering: boolean) => void

export function useHovering<T extends string>(elementNames: T[]) {
    const [hovering, setState] = useState(() =>
        elementNames.reduce((initialState, name) => ({...initialState, [name]: false}), {} as Hovering<T>),
    )

    const setHovering = useCallback<SetHovering<T>>((name, hovering) => {
        setState((state) => ({
            ...state,
            [name]: hovering,
        }))
    }, [])

    return {
        hovering,
        setHovering,
    }
}
