import {gql, MutationUpdaterFn, useMutation} from '@apollo/client'
import produce from 'immer'
import {useAlert} from 'react-alert'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {FULL_ORG_CHART_DATA, updateDirectReportsOf} from '../useFullOrgChartData'
import {FullOrgChartData} from '../__types__/FullOrgChartData'
import {REPORTING_LINE_DATA} from './ReportingLineTab'
import {ReportingLineData, ReportingLineDataVariables} from './__types__/ReportingLineData'
import {ResetPrimaryManager, ResetPrimaryManagerVariables} from './__types__/ResetPrimaryManager'
import {UpdatePrimaryManager, UpdatePrimaryManagerVariables} from './__types__/UpdatePrimaryManager'

const UPDATE_PRIMARY_MANAGER = gql`
    mutation UpdatePrimaryManager($organisationIdStr: String!, $profileIdStr: String!, $managerIdStr: String) {
        updatePrimaryManager(
            organisationIdStr: $organisationIdStr
            profileIdStr: $profileIdStr
            managerIdStr: $managerIdStr
        ) {
            idStr
            managerIdStr
            managerEdited
            originalManagerIdStr
        }
    }
`

const RESET_PRIMARY_MANAGER = gql`
    mutation ResetPrimaryManager($organisationIdStr: String!, $profileIdStr: String!) {
        resetManager(organisationIdStr: $organisationIdStr, profileIdStr: $profileIdStr) {
            idStr
            managerIdStr
            managerEdited
        }
    }
`

const isUpdate = (data: UpdatePrimaryManager | ResetPrimaryManager): data is UpdatePrimaryManager => {
    return (data as UpdatePrimaryManager).updatePrimaryManager !== undefined
}

function update(profileIdStr: string, organisationIdStr: string) {
    const mutationUpdateFn: MutationUpdaterFn = (cache, {data}) => {
        if (!data) return
        const result = isUpdate(data as UpdatePrimaryManager | ResetPrimaryManager)
            ? data.updatePrimaryManager
            : data.resetManager
        const reportingLineData = cache.readQuery<ReportingLineData, ReportingLineDataVariables>({
            query: REPORTING_LINE_DATA,
            variables: {profileIdStr: profileIdStr!, organisationIdStr},
        })

        if (!reportingLineData || !data) return
        cache.writeQuery<ReportingLineData, ReportingLineDataVariables>({
            query: REPORTING_LINE_DATA,
            variables: {profileIdStr: profileIdStr!, organisationIdStr},
            data: {
                ...reportingLineData,
                profile: {
                    ...reportingLineData.profile,
                    ...result,
                },
            },
        })

        const orgChartData = cache.readQuery<FullOrgChartData>({
            query: FULL_ORG_CHART_DATA,
            variables: {idStr: organisationIdStr},
        })
        if (!orgChartData) return

        cache.writeQuery<FullOrgChartData>({
            query: FULL_ORG_CHART_DATA,
            data: produce(orgChartData, (draftState) => {
                draftState.organisation.profiles.forEach((profile) => {
                    if (profile.idStr === profileIdStr) {
                        profile.reportsTo = result.managerIdStr
                        return
                    }
                    updateDirectReportsOf(profile, profileIdStr, result.managerIdStr)
                })
            }),
        })
    }

    return mutationUpdateFn
}

export const useEditPrimaryManager = (profileIdStr: string) => {
    const organisationIdStr = useOrganisationIdStr()
    const alert = useAlert()

    const [updatePrimaryManager] = useMutation<UpdatePrimaryManager, UpdatePrimaryManagerVariables>(
        UPDATE_PRIMARY_MANAGER,
        {
            update: update(profileIdStr, organisationIdStr),
            onCompleted: () => {
                alert.success('Successfully updated primary manager')
            },
        },
    )

    const [resetPrimaryManager] = useMutation<ResetPrimaryManager, ResetPrimaryManagerVariables>(
        RESET_PRIMARY_MANAGER,
        {
            update: update(profileIdStr, organisationIdStr),
            onCompleted: () => {
                alert.success('Successfully reset primary manager')
            },
        },
    )

    const updateManager = async (managerIdStr: string | null, originalManagerIdStr: string | null) => {
        updatePrimaryManager({
            variables: {
                organisationIdStr,
                profileIdStr,
                managerIdStr,
            },
            optimisticResponse: {
                updatePrimaryManager: {
                    __typename: 'ProfileObject',
                    idStr: profileIdStr,
                    managerIdStr,
                    managerEdited: true,
                    originalManagerIdStr,
                },
            },
        })
    }

    const resetManager = async (managerIdStr: string | null) => {
        resetPrimaryManager({
            variables: {
                organisationIdStr,
                profileIdStr,
            },
            optimisticResponse: {
                resetManager: {
                    __typename: 'ProfileObject',
                    idStr: profileIdStr,
                    managerIdStr,
                    managerEdited: false,
                },
            },
        })
    }

    return {updateManager, resetManager}
}
