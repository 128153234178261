export function loadScript(src: string) {
    const selector = `script[src="${src}"]`
    return new Promise<void>((resolve, rejects) => {
        const scripts = document.querySelectorAll(selector)
        if (scripts.length) return resolve()

        const scriptElem = document.createElement('script')
        scriptElem.src = src
        scriptElem.async = true
        scriptElem.onload = () => resolve()
        scriptElem.onerror = rejects

        document.head.appendChild(scriptElem)
    }).catch((err) => {
        const scripts = document.querySelectorAll(selector)
        scripts.forEach((e) => e.remove())
        throw err
    })
}
