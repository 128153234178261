import React, {useReducer} from 'react'
import {PhotoCollectionProps} from './index'

type ProfilesReducerInterface = string[]

enum SelectedProfileAction {
    SET_ALL,
    TOGGLE_PROFILE,
}

interface SetFieldsAction {
    type: SelectedProfileAction.SET_ALL
    profiles: string[]
}

interface ToggleFieldAction {
    type: SelectedProfileAction.TOGGLE_PROFILE
    profileIdStr: string
}

const reducer: React.Reducer<ProfilesReducerInterface, SetFieldsAction | ToggleFieldAction> = (state, action) => {
    switch (action.type) {
        case SelectedProfileAction.SET_ALL:
            return action.profiles
        case SelectedProfileAction.TOGGLE_PROFILE:
            return state.includes(action.profileIdStr)
                ? state.filter((s) => s !== action.profileIdStr)
                : [...state, action.profileIdStr]
        default:
            return state
    }
}

export function useProfileSelect(profiles: PhotoCollectionProps['profiles'], filter: string) {
    const [checkedProfiles, dispatchCheckedProfiles] = useReducer(reducer, [])
    const checkedAll = profiles.length === checkedProfiles.length

    const toggleAll = () => {
        dispatchCheckedProfiles({
            type: SelectedProfileAction.SET_ALL,
            profiles: checkedAll ? [] : profiles.map((profile) => profile.idStr),
        })
    }

    const toggle = (profileIdStr: string) => {
        dispatchCheckedProfiles({
            type: SelectedProfileAction.TOGGLE_PROFILE,
            profileIdStr,
        })
    }

    return {
        checkedAll,
        checkedProfiles,
        toggleAll,
        toggle,
        profiles,
    }
}

export type ProfileSelectState = ReturnType<typeof useProfileSelect>
