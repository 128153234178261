import {from} from 'fromfrom'
import {
    Accordion,
    AccordionData,
    colors,
    Form,
    SidebarLayout,
    Spacer,
    Typography,
    useAnalyticsEventTracker,
    useTheme,
} from 'nf-ui'
import React from 'react'
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {ModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {OrganisationSwitcher} from '~/components/OrganisationSwitcher'
import {PanelNavigatorProvider} from '~/components/PanelNavigator'
import {UnstyledLink} from '~/components/UnstyledLink'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {ActionBarSizer, UserlandActionBarContext} from './ActionBar'
import {EditProfileModal} from './EditProfileModal'
import {EditTabOption} from './EditProfileModal/EditProfile'
import {FullOrgChart} from './FullOrgChart'
import {useCycleMatchingProfiles} from './FullOrgChart/ActionBar/useCycleMatchingProfiles'
import {ScenarioIndicator} from './FullOrgChart/ScenarioIndicator'
import {OrgChartDataProvider, useOrgChartData} from './FullOrgChart/useOrgChartData'
import {FullProfileSidePanel} from './FullProfileSidePanel'
import {GroupMessageModal} from './GroupMessageModal'
import {ProfileGroupProvider} from './ProfileGroupContext'
import {ReportingLines} from './ReportingLines/ReportingLines'
import {useFilterCategory} from './useFilterCategory'
import {UserlandModal} from './UserlandRoot'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;
    position: relative;
`

const ActionBarContainer = styled.div`
    flex: 1;
    position: relative;
    overflow-x: hidden;
`

const OrgChartSearch = () => {
    const {pathname} = useLocation()!
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const [search, setSearch] = useQueryParam<string>('search')

    const isReportingLines = pathname.includes('reporting-lines')

    return (
        <Form.Group name="search">
            <Form.SearchInput
                width="100%"
                highlighted={!!search}
                value={search || ''}
                onFocus={() =>
                    trackAnalyticsEvent('orgCharts', {
                        page: isReportingLines ? 'interactive' : 'full-view',
                        component: 'text_input',
                        type: 'input',
                        action: 'search',
                        name: 'enter_search',
                    })
                }
                onChange={setSearch}
            />
        </Form.Group>
    )
}

const ReportingLinesLink = styled(UnstyledLink)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const ReportingLinesButton = () => {
    const {pathname} = useLocation()!
    const theme = useTheme()
    const organisationIdStr = useOrganisationIdStr()
    const trackEvent = useAnalyticsEventTracker()

    const isReportingLines = pathname.includes('reporting-lines')
    const to = `/${organisationIdStr}/org-chart/reporting-lines`

    const onClickAnalytics = () => {
        trackEvent('orgCharts', {
            page: isReportingLines ? 'interactive' : 'full-view',
            component: 'button',
            type: 'click',
            action: 'view',
            name: 'interactive_org_chart',
        })
    }

    const color = isReportingLines ? theme.primary.color : colors.black

    return (
        <ReportingLinesLink to={to} onClick={onClickAnalytics}>
            <Typography.Paragraph bold={isReportingLines} style={{color}} bottomMargin={false}>
                Reporting lines
            </Typography.Paragraph>
        </ReportingLinesLink>
    )
}

const MenuWrapper = styled.div`
    padding-right: 16px;
`

const OrgChartMenuItems = () => {
    const orgIdStr = useOrganisationIdStr()
    const {pathname} = useLocation()!
    const {push} = useHistory()
    const {homeItems} = useFilterCategory(orgIdStr)
    const {categoryProfileCount} = useOrgChartData()
    const [categoryIdStr, setCategoryIdStr] = useQueryParam<string>('categoryId')
    const {setMatchIndex} = useCycleMatchingProfiles()
    const trackEvent = useAnalyticsEventTracker()

    const isReportingLines = pathname.includes('reporting-lines')

    const categoryLabel = ({label, idStr}: {label: string; idStr: string}) => {
        const onItemClick = () => {
            if (isReportingLines) push(`/${orgIdStr}/org-chart/full`)
            setCategoryIdStr(idStr)
            setMatchIndex(null)
            trackEvent('orgCharts', {
                page: isReportingLines ? 'interactive' : 'full-view',
                component: 'link',
                type: 'click',
                action: 'view',
                name: 'browse_category',
            })
        }

        return (
            <span style={{display: 'block'}} onClick={onItemClick}>
                {label}
            </span>
        )
    }

    const selectedKey = isReportingLines ? 'reporting-lines' : categoryIdStr || ''

    const allStaff: AccordionData = {id: '', label: categoryLabel({label: 'All People', idStr: ''})}

    const homeItemRoutes: AccordionData[] = from(homeItems)
        .sortBy((item) => item.priority)
        .map((item) => {
            return {
                id: item.sourceIdStr!,
                label: item.label,
                children: from(item.childCategories!)
                    .sortBy((c) => c.priority)
                    .map(({idStr, label}) => ({
                        id: idStr,
                        label: categoryLabel({label, idStr}),
                        hoverLabel: categoryProfileCount(idStr),
                    }))
                    .filter((item) => item.hoverLabel > 0)
                    .toArray(),
            }
        })
        .toArray()

    const links = [allStaff, ...homeItemRoutes]

    return (
        <MenuWrapper>
            <Spacer height={24} />
            <Accordion data={links} selectedKey={selectedKey} onChange={() => false} />
            <Spacer height={8} />
            <ReportingLinesButton />
        </MenuWrapper>
    )
}

export const OrgChartRoot: React.FC = () => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const {path} = useRouteMatch()!
    const [profileId] = useQueryParam<string>('profileId')
    const [, setEditProfileId] = useQueryParam<string | null>('editProfileId')
    const [, setEditProfileActiveTab] = useQueryParam<EditTabOption | null>('editProfileActiveTab')
    const [modal, setModal] = useQueryParam<UserlandModal>('modal')
    const {currentOrganisation, loading, orgChartEnabled} = useCurrentOrganisation()
    const editableProfile = currentOrganisation?.editableProfile || null

    if (!currentOrganisation || loading) return null

    if (!orgChartEnabled) return <Redirect to={`/${currentOrganisation.idStr || ''}`} />

    const onCloseModal = (modal: UserlandModal) => {
        setEditProfileId(null)
        setEditProfileActiveTab(null)
        setModal(modal)
    }

    const editProfile = () => {
        if (!editableProfile?.idStr) return

        trackAnalyticsEvent('click_edit_profile', {from: 'org_switcher'})
        setEditProfileId(editableProfile?.idStr)
        setModal('editProfile')
    }

    const actionRequired = !!editableProfile && editableProfile.profilePictureActionRequired

    return (
        <OrgChartDataProvider>
            <ScenarioIndicator />
            <SidebarLayout
                logoLink={`/${currentOrganisation.idStr}`}
                topContent={<OrgChartSearch />}
                middleContent={<OrgChartMenuItems />}
                bottomContent={<OrganisationSwitcher actionRequired={actionRequired} onClickEdit={editProfile} />}
            >
                <Container>
                    <ProfileGroupProvider>
                        <ModalNavigator modal={modal} setModal={onCloseModal}>
                            <EditProfileModal key="editProfile" onDoneEditing={() => onCloseModal(null)} />
                            <GroupMessageModal key="groupMessage" />
                        </ModalNavigator>
                        <UserlandActionBarContext>
                            <Switch>
                                <Route path={`${path}/full`} exact>
                                    <PanelNavigatorProvider>
                                        <ActionBarContainer>
                                            <ActionBarSizer />
                                        </ActionBarContainer>
                                        <FullOrgChart />
                                    </PanelNavigatorProvider>
                                </Route>
                                <Route path={`${path}/reporting-lines`} exact>
                                    <ActionBarContainer>
                                        <ActionBarSizer>
                                            <ReportingLines />
                                        </ActionBarSizer>
                                    </ActionBarContainer>
                                </Route>
                            </Switch>

                            <FullProfileSidePanel profileId={profileId} cache={false} />
                        </UserlandActionBarContext>
                    </ProfileGroupProvider>
                </Container>
            </SidebarLayout>
        </OrgChartDataProvider>
    )
}
