import React from 'react'
import {DragDropContext, Droppable, DropResult, ResponderProvided} from 'react-beautiful-dnd'

type DraggableListType = {
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void
    droppableId: string
}
export const DraggableList: React.FC<DraggableListType> = ({children, onDragEnd, droppableId}) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={droppableId}>
                {({droppableProps, innerRef, placeholder}) => (
                    <div ref={innerRef} {...droppableProps}>
                        {children}
                        {placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}
