import {gql, useMutation} from '@apollo/client'
import {Button, colors, Lightbox} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {CURRENT_USER} from '../useCurrentUser'
import {useOrganisationIdStr} from '../useOrganisationIdStr'
import {CurrentUserData} from '../__types__/CurrentUserData'
import {NUMBER_OF_STEPS, WelcomeCarousel} from './WelcomeCarousel'
import {SetViewedUserland, SetViewedUserlandVariables} from './__types__/SetViewedUserland'

const SET_VIEWED_USERLAND = gql`
    mutation SetViewedUserland($organisationIdStr: String!, $lastStep: String!) {
        setViewedUserland(organisationIdStr: $organisationIdStr, lastStep: $lastStep)
    }
`

const CloseButtonWrapper = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 5;
`

export type WelcomeLightboxProps = {
    open: boolean
    onClose: () => void
}

const CloseButton = styled.div`
    border-radius: 3px;
    transition: 0.15s background-color ease-in-out;
    :hover {
        cursor: pointer;
        background-color: ${colors.lightGray};
    }
`

export const WelcomeLightbox = ({showWelcomeLightbox}: {showWelcomeLightbox: boolean}) => {
    const organisationIdStr = useOrganisationIdStr()
    const [step, setStep] = useState<number>(1)
    const lastStep = useRef(1)
    const [open, setOpen] = useState<boolean>(showWelcomeLightbox)

    const [setViewedUserland] = useMutation<SetViewedUserland, SetViewedUserlandVariables>(SET_VIEWED_USERLAND, {
        update: (cache) => {
            const currentUser = cache.readQuery<CurrentUserData>({query: CURRENT_USER})
            if (!currentUser || !currentUser.me) return

            cache.writeQuery<CurrentUserData>({
                query: CURRENT_USER,
                data: {
                    me: {
                        ...currentUser.me,
                        hasViewedUserland: true,
                    },
                },
            })
        },
    })

    const onClose = () => {
        setOpen(false)
        setViewedUserland({
            variables: {
                organisationIdStr,
                lastStep: `${lastStep.current}`,
            },
        })
    }

    useEffect(() => {
        if (step > lastStep.current) lastStep.current = step
    }, [step])

    const nextStep = () => {
        if (step === NUMBER_OF_STEPS) {
            onClose()
        } else {
            setStep((prevStep) => prevStep + 1)
        }
    }

    return (
        <Lightbox onClose={onClose} open={open} width={560} padding={0}>
            <CloseButtonWrapper>
                <CloseButton onClick={onClose}>
                    <Button.Icon icon={SvgClose} size={24} />
                </CloseButton>
            </CloseButtonWrapper>
            <WelcomeCarousel step={step} nextStep={nextStep} setStep={setStep} />
        </Lightbox>
    )
}
