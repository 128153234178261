import React, {PureComponent, createRef, HTMLAttributes} from 'react'
import styled from 'styled-components'
import {castArray, isReactElement} from './util'

const promiseDelay = (timeout: number) => new Promise((resolve) => setTimeout(resolve, timeout))

const sizeTransitionDuration = 250
const fadeTransitionDuration = 200

interface ContainerProps {
    visible: boolean
    width: number | 'auto'
    height: number | 'auto'
}

const Container = styled.div.attrs<ContainerProps, {style: HTMLAttributes<HTMLDivElement>['style']}>(
    ({width, height, visible}) => ({
        style: {
            width,
            height,
            opacity: visible ? 1 : 0,
        },
    }),
)`
    overflow: hidden;
    will-change: width, height, opacity;
    transition: ${sizeTransitionDuration}ms width ease-in-out, ${sizeTransitionDuration}ms height ease-in-out,
        ${fadeTransitionDuration}ms opacity ease-in-out;
`

const Measurer = styled.div`
    display: inline-block;
`

interface Props {
    onTransitionEnd: () => void
    children: React.ReactNode
    updateKey: string
}

interface State {
    useStateChild?: boolean
    child?: React.ReactNode | null
    visible?: boolean
    applyDimensions?: boolean
    width?: number | string
    height?: number | string
}

export class FadeBetween extends PureComponent<Props, State> {
    measurer = createRef<HTMLDivElement>()

    asyncSetState = (state: State) => {
        return new Promise<void>((resolve) => {
            this.setState(state, () => resolve())
        })
    }

    getChild = (props: Props): React.ReactNode | null => {
        const children = castArray(props.children)
        return children.find((child) => !!child) || null
    }

    state = {
        useStateChild: false,
        child: null,
        visible: true,
        applyDimensions: false,
        width: 'auto',
        height: 'auto',
    }

    componentDidMount() {
        this.setDimensions()
    }

    setDimensions = async () => {
        return new Promise<void>((resolve) => {
            requestAnimationFrame(() => {
                if (!this.measurer.current) return

                this.setState(
                    {
                        width: this.measurer.current.clientWidth,
                        height: this.measurer.current.clientHeight,
                    },
                    () => resolve(),
                )
            })
        })
    }

    waitForFadeTransition = () => {
        return promiseDelay(fadeTransitionDuration)
    }

    waitForSizeTransition = () => {
        return promiseDelay(sizeTransitionDuration)
    }

    fadeToNewComponent = async (newChild: React.ReactNode | null) => {
        const {onTransitionEnd} = this.props

        await this.asyncSetState({
            useStateChild: true,
            child: this.getChild(this.props),
        })

        // Measure the current component, in case its dimensions
        // have changed since the last measurement
        await this.setDimensions()

        // Fade out the current component
        await this.asyncSetState({
            visible: false,

            // Disable applying dimensions in case the component
            // changes size while its mounted
            applyDimensions: true,
        })

        // Wait for fading to complete
        await this.waitForFadeTransition()

        // Mount the new component
        await this.asyncSetState({
            child: newChild,
        })

        // Measure the new component and animate the container's size
        // to the new component's size
        await this.setDimensions()

        // Wait for the size transition to complete
        await this.waitForSizeTransition()

        // Fade in the new component
        await this.asyncSetState({
            visible: true,
            applyDimensions: false,
            useStateChild: false,
        })

        if (onTransitionEnd) onTransitionEnd()
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const previousChild = this.getChild(this.props)
        const nextChild = this.getChild(nextProps)

        // Prevent a transitioned update if the updateKey is different. This allows us to recycle views. For example, if
        // a view using this component is re-used with new props, and we want to render FadeBetween's child as if the
        // parent component was just mounted, we provide a different updateKey
        const updateKeyChanged = nextProps.updateKey !== this.props.updateKey

        const previousChildKey = isReactElement(previousChild) ? previousChild.key : null
        const childKey = isReactElement(nextChild) ? nextChild.key : null

        if (!updateKeyChanged && previousChildKey !== childKey) {
            this.fadeToNewComponent(nextChild)
        }
    }

    render() {
        const {visible, width, height, applyDimensions, useStateChild, child} = this.state

        return (
            <Container
                // @ts-ignore
                visible={visible}
                width={applyDimensions ? width : 'auto'}
                height={applyDimensions ? height : 'auto'}
            >
                <div
                    style={{
                        width: applyDimensions ? window.innerWidth : undefined,
                        height: applyDimensions ? window.innerHeight : undefined,
                        display: 'flex',
                    }}
                >
                    <Measurer ref={this.measurer}>{useStateChild ? child : this.props.children}</Measurer>
                </div>
            </Container>
        )
    }
}
