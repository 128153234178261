import {ReactNode, ReactElement, useContext, createContext, useRef, useEffect, useLayoutEffect, Ref} from 'react'
import ResizeObserver from 'resize-observer-polyfill'

const DEFAULT_CANVAS = document.createElement('canvas')

/** Returns a promise that resolves after `ms` milliseconds. */
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const measureTextWidth = (
    text: string,
    {
        fontFamily = 'halyard-display',
        fontSize = 16,
        fontWeight = 400,
        fontStyle = 'normal',
        canvas = DEFAULT_CANVAS,
    } = {},
) => {
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('Canvas context is null')

    ctx.font = `${fontWeight} ${fontStyle} ${fontSize}px '${fontFamily}'`

    return Math.ceil(ctx.measureText(text).width)
}

/**
 * Omit keys from a Record.
 * This returns a new object.
 * @param object
 * @param keys
 */
export function omit<T>(object: Record<string, T>, keys: string[]) {
    const newObject = {...object}

    for (const key of keys) {
        delete newObject[key]
    }

    return newObject
}

export const isHexColor = (hex: string) => {
    const regexp = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
    if (regexp.test(hex)) return true
    return false
}

export function castArray<E>(value: E | E[]) {
    if (Array.isArray(value)) return value
    return [value]
}

/**
 * A custom TS type check which will return true if the `ReactNode` passed to it
 * is a `ReactElement`.
 *
 * This can be useful for passing only `ReactElements` to `React.cloneElement`, or
 * for picking the key off the element.
 *
 * @param element
 */
export function isReactElement(element: ReactNode): element is ReactElement {
    return Boolean(element && typeof element === 'object' && 'key' in element)
}

export const useAnalyticsEventTracker = () => {
    const context = useContext(AnalyticsContext)

    let platform = 'unknown'
    if (context) platform = context.platform

    const trackEvent = (event: string, properties?: Record<string, string | number | boolean | Record<any, any>>) => {
        if (!window.analytics) return
        window.analytics.track(event, {platform, ...properties})
    }

    return trackEvent
}

export const AnalyticsContext = createContext<{platform: string}>({platform: 'Web'})

/*
 * Easing used in most transitions. This is using the cubic bezier function with [0.455, 0.03, 0.515, 0.955] as inputs
 */
export const easeInOutQuad = [0.455, 0.03, 0.515, 0.955]

/*
 * Easing used in most transitions. This is using the cubic bezier function with [0.455, 0.03, 0.515, 0.955] as inputs
 */
export const easeInOutQuadCSS = `cubic-bezier(0.455, 0.03, 0.515, 0.955)`

export function useOnResize<E extends HTMLElement>(elementRef: React.RefObject<E>, callback: () => void) {
    const callbackRef = useRef<() => void>(callback)

    useEffect(() => {
        callbackRef.current = callback
    }, [callback])

    useLayoutEffect(() => {
        if (!elementRef.current) return

        // Use ResizeObserver to listen for changes
        const resizeObserver = new ResizeObserver(([element]) => {
            callbackRef.current()
        })

        resizeObserver.observe(elementRef.current)

        return () => resizeObserver.disconnect()
    }, [elementRef])
}

export const mergeRefs = <T>(...refs: Ref<T>[]) => (value: T | null) => {
    refs.forEach((ref) => {
        setRef(ref, value)
    })
}

export function setRef<T>(ref?: Ref<T>, value?: T | null) {
    if (!ref) return
    if (typeof ref === 'function') {
        ref(value ?? null)
    } else if (ref) {
        //@ts-ignore it's not readonly
        ref.current = value ?? null
    }
}

export function createOrFindElement(id: string) {
    let container = document.getElementById(id) as HTMLDivElement | null
    if (!container) {
        container = document.createElement('div')
        container.id = id
        document.body.appendChild(container)
    }

    return container
}

export const warnOrThrow = (message: string) => {
    if (process.env.NODE_ENV === 'development') {
        throw new Error(message)
    } else {
        console.warn(message)
    }
}
