import {ApolloClient, ApolloProvider} from '@apollo/client'
import {InMemoryCache} from '@apollo/client/cache'
import {RetryLink} from '@apollo/client/link/retry'
import {createUploadLink} from 'apollo-upload-client'
import {usePrevious} from 'nf-ui'
import React, {FC, useEffect, useRef} from 'react'
import {useMemoOne} from 'use-memo-one'
import {Token} from '~/components/TokenContext'
import {customFetch} from './apollo/CustomFetch'
import {cachePolicyDataEditGetFields} from './apollo/queries/DataEditField'
import {cachePolicyDataEditGetValues} from './apollo/queries/DataEditValue'
import {cachePolicyProfileLines} from './apollo/queries/ProfileLine'
import {useAnalyticsUserId} from './components/useIdentifyUser'
import {useWebSignout} from './components/useSignout'

const retryLink = new RetryLink()

//This is a bit of a hack to make sure it can be provided the token value from the token provider up the tree
export const ProvideApollo: FC = ({children}) => {
    const analyticsId = useAnalyticsUserId()
    const {token, setToken} = Token.useContainer()
    const {webSignOut: signOut} = useWebSignout()

    const customFetchRef = useRef(customFetch({token, setToken, signOut, analyticsId}))
    useEffect(() => {
        customFetchRef.current = customFetch({token, setToken, signOut, analyticsId})
    }, [token, setToken, signOut, analyticsId])

    const client = useMemoOne(() => {
        return new ApolloClient({
            link: retryLink.concat(
                createUploadLink({
                    uri: `${process.env.API_URL}/graphql`,
                    fetch: (url, options) => customFetchRef.current(url, options),
                }),
            ),
            cache: new InMemoryCache({
                typePolicies: {
                    OrganisationObject: {
                        fields: {
                            appFeatures: {
                                merge(existing, incoming, {mergeObjects}) {
                                    return mergeObjects(existing, incoming)
                                },
                            },
                        },
                    },
                    ExtraDataObject: {
                        keyFields: false,
                    },
                    AppFeatures: {
                        keyFields: false,
                    },
                    SmartGroup: {
                        keyFields: false,
                    },
                    AvailableFieldObject: {
                        keyFields: false,
                    },
                    ImageFile: {
                        keyFields: false,
                    },
                    CookieObject: {
                        keyFields: ['name', 'domain'],
                    },
                    SignInMethod: {
                        keyFields: ['type', 'name'],
                    },
                    SmartGroupProfile: {
                        keyFields: ['profileIdStr', 'secondaryLine'],
                    },
                    AvailableFieldTypeObject: {
                        keyFields: ['name', 'named'],
                    },
                    ProfileLineObject: {
                        keyFields: false,
                    },
                    FieldRule: {
                        keyFields: false,
                    },
                    DataEditField: {
                        keyFields: ['idStr'],
                    },
                    DataEditValue: {
                        keyFields: ['fieldOrParentCategoryIdStr', 'profileIdStr'],
                    },
                    ProfileLine: {
                        keyFields: ['fieldOrCategoryIdStrs'],
                    },
                    Query: {
                        fields: {
                            dataEditValues: cachePolicyDataEditGetValues,
                            dataEditFields: cachePolicyDataEditGetFields,
                            profileLines: cachePolicyProfileLines,
                        },
                    },
                    HomeItemObject: {
                        fields: {
                            childCategories: {
                                merge(existing = [], incoming: any[]) {
                                    return incoming
                                },
                            },
                        },
                    },
                    ResponseData: {
                        keyFields: ['responseIdStr'],
                    },
                    DraftProfile: {
                        keyFields: ['responseIdStr', 'name'],
                    },
                    UserDiagnostic: {
                        keyFields: ['group', 'diagnostic'],
                    },
                },
                dataIdFromObject(object) {
                    switch (object.__typename) {
                        default:
                            return object.idStr ? `${object.__typename}:${object.idStr}` : object.__typename
                    }
                },
            }),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'cache-and-network',
                    nextFetchPolicy: 'cache-first',
                },
                query: {
                    fetchPolicy: 'cache-first',
                },
            },
        })
    }, [])

    const previousToken = usePrevious(token)
    useEffect(() => {
        //Clears the store on sign out. Can't do it in useSignout or it'll cause a cirular dependency.
        if (!token && previousToken && client) {
            client.clearStore()
        }
    }, [token, previousToken, client])

    return <ApolloProvider client={client}>{children}</ApolloProvider>
}
