import {Button, Panel, Typography} from 'nf-ui'
import SvgFilter from 'nf-ui/Icons/Filter'
import pluralize from 'pluralize'
import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {OptionType, SortList} from '~/components/SortList'
import {PhotoFilter} from '~/components/usePhotoFilter'
import {useProfileFilter} from '~/components/useProfileFilter'
import {useProfileSort} from '~/components/useProfileSort'

interface HeaderProps {
    count: number
    profileFilter: ReturnType<typeof useProfileFilter>
    profileSort: ReturnType<typeof useProfileSort>
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: -7px;
    margin-right: 22px;
`

const FiltersOptions: OptionType<PhotoFilter>[] = [
    {title: 'All', filter: 'ALL'},
    {title: 'Missing Photos', filter: 'UNMATCHED'},
    {title: 'Have Photos', filter: 'MATCHED'},
]

const SortOptions: OptionType<PhotoFilter>[] = [
    {title: 'Unmatched', sort: 'UNMATCHED'},
    {title: 'Date added (newest first)', sort: 'DATE_DESC'},
    {title: 'Alphabetical (A-Z)', sort: 'NAME_ASC'},
]

const Heading: React.FC<HeaderProps> = ({count, profileFilter, profileSort}) => {
    const [open, setOpen] = useState(false)
    const isOptionChecked = (option: OptionType<PhotoFilter>) =>
        option.filter === profileFilter.filter || option.sort === profileSort.sort
    const buttonRef = useRef<HTMLButtonElement>(null)

    return (
        <Container>
            <Typography.Label>
                {count} {pluralize('person', count)} selected
            </Typography.Label>
            <Button variant="tertiary" ref={buttonRef} onClick={() => setOpen((o) => !o)}>
                <Button.Icon icon={SvgFilter} /> Sort
            </Button>
            <Panel open={open} onClose={() => setOpen(false)} targetRef={buttonRef}>
                <SortList
                    heading="Filter People"
                    options={FiltersOptions}
                    onClick={(row) => {
                        if (row.filter) {
                            profileFilter.setFilter(row.filter)
                        }
                        setOpen(false)
                    }}
                    isOptionChecked={isOptionChecked}
                />
                <SortList
                    heading="Sort People"
                    options={SortOptions}
                    onClick={(row) => {
                        if (row.sort) {
                            profileSort.setSort(row.sort)
                        }
                        setOpen(false)
                    }}
                    isOptionChecked={isOptionChecked}
                />
            </Panel>
        </Container>
    )
}

export {Heading}
