import React from 'react'
import styled from 'styled-components'
import {Typography} from '../Typography'
import {colors} from '../theme'

const cardIllustrations = {
    WeightLifterWoman: {
        file: require('./illustrations/weight_lifter_woman.png'),
        width: 153,
        height: 164,
        right: 10,
        bottom: 22,
    },
    WomanWithDeer: {
        file: require('./illustrations/woman_with_deer.png'),
        width: 105,
        height: 157,
        right: 21,
        bottom: 14,
    },
}

type CardIllustrationVariant = keyof typeof cardIllustrations

type IllustrationCardProps = {
    illustration: CardIllustrationVariant
    text: string
    textWidth: number
    style?: React.CSSProperties
}

const ContentContainer = styled.div<{width: number; height: number}>`
    height: ${({height}) => height}px;
    width: ${({width}) => width}px;
    position: relative;
`

const CardContainer = styled.div<{width: number}>`
    border-radius: 3px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
    width: ${({width}) => width}px;
    position: absolute;
    bottom: 0px;
    background-color: ${colors.white};
`

const TextContainer = styled.div<{width: number}>`
    width: ${({width}) => width}px;
    margin: 24px 0px 24px 24px;
`

const Illustration = styled.img<{right: number; bottom: number}>`
    position: absolute;
    right: ${(props) => props.right}px;
    bottom: ${(props) => props.bottom}px;
`

const IllustrationCard: React.FC<IllustrationCardProps> = ({illustration, text, textWidth, style}) => {
    const cardIllustration = cardIllustrations[illustration]

    const textContainerLeftMargin = 24

    const contentContainerHeight = cardIllustration.height + cardIllustration.bottom
    const contentContainerWidth = textContainerLeftMargin + textWidth + cardIllustration.width + cardIllustration.right

    return (
        <ContentContainer height={contentContainerHeight} width={contentContainerWidth} style={style}>
            <CardContainer width={contentContainerWidth}>
                <TextContainer width={textWidth}>
                    <Typography.Paragraph bottomMargin={false}>{text}</Typography.Paragraph>
                </TextContainer>
            </CardContainer>
            <Illustration
                srcSet={`${cardIllustration.file} 2x`}
                alt=""
                right={cardIllustration.right}
                bottom={cardIllustration.bottom}
            />
        </ContentContainer>
    )
}

export {IllustrationCard}
