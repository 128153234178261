import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {IOSView, AndroidView} from 'react-device-detect'
import {playground} from '~/pages/Playground'
import {Form} from './Form'

export const Complete = ({
    directoryName,
    organisationIdStr,
    masterDirectoryIdStr,
}: {
    directoryName: string
    organisationIdStr: string
    masterDirectoryIdStr: string
}) => {
    const history = useHistory()
    useEffect(() => {
        if (window.innerWidth > 809) {
            history.push(`/${masterDirectoryIdStr || organisationIdStr}`)
        } else {
            window.scrollTo(0, 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Form
            directoryName={directoryName}
            heading="Great! Now download the app"
            subHeading={[
                `Click below to get the Names & Faces mobile app to see the other ${directoryName} families and to manage your profile.`,
                'Additional parents whose email addresses you included in your profile will also be able to sign into the app.',
            ]}
            progress={0}
        >
            <IOSView>
                <a href="https://apps.apple.com/us/app/names-faces/id949875464">
                    <img
                        src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                        alt="Download on the App Store"
                    ></img>
                </a>
            </IOSView>
            <AndroidView>
                <a href="http://play.google.com/store/apps/details?id=za.co.polymorph.namesfaces">
                    <img
                        width="250"
                        src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                        alt="Get it on Google Play"
                    ></img>
                </a>
            </AndroidView>
        </Form>
    )
}

playground.push({
    path: 'src/components/CommunityBuild/Complete.tsx',
    component: Complete,
    props: {directoryName: 'Wetpups Grade 3'},
    propOptions: {
        onNext: () => {},
    },
})
