type ProfileSearchFields = {
    fullName: string
    profileLineValues: string[]
}

const findMatch = (query: string, value: string) => {
    const index = value.toLowerCase().indexOf(query.toLowerCase())
    if (index === -1) return null

    return {
        index,
        value,
        length: query.length,
    }
}

const findNameMatch = (query: string, fullName: ProfileSearchFields['fullName']) => {
    const match = findMatch(query, fullName)
    if (!match) return null

    return {
        name: true,
        ...match,
    }
}

const findProfileLineMatch = (query: string, profileLineValues: ProfileSearchFields['profileLineValues']) => {
    for (const value of profileLineValues) {
        const match = findMatch(query, value)
        if (match) return {name: false, ...match}
    }

    return null
}

export type SearchMatch = {
    index: number
    length: number
    value: string
    name?: boolean
}

export type SearchResult<T> = T & {
    match: SearchMatch
}

export type MaybeSearchResult<T> = T & {
    match?: SearchMatch
}

export function searchProfiles<T extends ProfileSearchFields>(query: string, profiles: T[]) {
    // console.log('searchProfiles', profiles.map(profile => profile.profileLineValues))
    return profiles.reduce((searchResults, profile) => {
        let match = findNameMatch(query, profile.fullName)
        if (!match) match = findProfileLineMatch(query, profile.profileLineValues)

        if (match) {
            return [
                ...searchResults,
                {
                    ...profile,
                    match,
                },
            ]
        }

        return searchResults
    }, [] as SearchResult<T>[])
}
