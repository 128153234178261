import React, {Key, ReactElement} from 'react'
import {AccordionItem, AccordionItemProps} from './AccordionItem'
import {AccordionData, IdMap, ValueMap} from './types'
import {from} from 'fromfrom'

/**
 * Minimal object type for the processIdMap function
 */
export type IdRecurse = Pick<AccordionData, 'id'> & {
    children?: IdRecurse[]
}

/**
 * Generates a map with the id as the key and it's values being itself and all it's descendents in the tree
 * Descendent order is guarenteed to be in order of descendents
 *
 * @export
 * @param {IdRecurse[]} data
 * @returns
 */
export function processIdMap(data: IdRecurse[], idMap: IdMap = {}) {
    data.forEach(({id, children = []}) => {
        if (!children.length) {
            idMap[id] = [id]
            return
        }

        const childMap = processIdMap(children, idMap)
        const values = from(children)
            .flatMap((c) => childMap[c.id])
            .toArray()

        idMap[id] = [id, ...values]
    })

    return idMap
}

export function processValueMap<T>(data: AccordionData<T>[], valueMap: ValueMap<T> = {}, unselectableDefault = false) {
    data.forEach(({children = [], unselectable = unselectableDefault, id, data}) => {
        if (children.length) {
            processValueMap(children, valueMap)
        }
        valueMap[id] = {
            selectable: !unselectable,
            data,
            hasChildren: children.length > 0,
        }
    })

    return valueMap
}

/**
 * Finds the first valid selection for the accordion item
 *
 * @export
 * @template T
 * @param {(Key | null | undefined)} selectedKey
 * @param {IdMap} idMap
 * @param {ValueMap<T>} valueMap
 * @returns the first valid child or null if no valid selection was found
 */
export function findFirstValidSelectableKey<T = any>(
    selectedKey: Key | null | undefined,
    idMap: IdMap,
    valueMap: ValueMap<T>,
) {
    if (!selectedKey) return selectedKey
    if (!idMap[selectedKey]) return null
    return idMap[selectedKey].find((k) => valueMap[k] && valueMap[k].selectable) || null
}

/**
 * Type that describes the current state of the render.
 */
export type RenderChildrenState = {
    idMap: IdMap
    selectedKey?: Key | null
    expandedIds: Key[]
}

/**
 * Recrusive function that will render all child elements
 *
 * @export
 * @param {(AccordionData[] | undefined)} accordionData
 * @param {RenderChildrenState} renderState state of the current render, should be passed down the recrusion but not consumed by the AccordianItem
 * @param {Partial<AccordionItemProps>} itemProps properties to forward to the AccordianItem
 * @returns {(ReactElement | null)}
 */
export function renderChildren(
    accordionData: AccordionData[] | undefined,
    renderState: RenderChildrenState,
    itemProps: Partial<AccordionItemProps> & Pick<AccordionItemProps, 'onSelect'>,
    indentation = 0,
): ReactElement | null {
    const {expandedIds, idMap, selectedKey} = renderState
    if (!accordionData || !accordionData.length) return null
    return (
        <>
            {accordionData.map((data) => {
                const selected = selectedKey === data.id
                const hasChildren = data.children != null && !!data.children.length
                const expanded =
                    hasChildren && idMap[data.id] && expandedIds.some((eid) => idMap[data.id].includes(eid))
                const childIndentation = indentation + (data.icon ? 48 : 16)
                const accordianChildren = expanded
                    ? renderChildren(data.children, renderState, itemProps, childIndentation)
                    : null

                return (
                    <AccordionItem
                        key={data.id}
                        {...data}
                        expanded={expanded}
                        selected={selected}
                        hasChildren={hasChildren}
                        indentation={indentation}
                        disabled={data.disabled}
                        {...itemProps}
                    >
                        {accordianChildren}
                    </AccordionItem>
                )
            })}
        </>
    )
}
