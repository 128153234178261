import * as React from 'react'
const SvgSignOut = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgSignOut(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M4.738 0l.083.009a.558.558 0 01.417.52c0 .28-.251.53-.5.53h-2.8v13.882h2.8l.083.009a.557.557 0 01.417.52c0 .279-.251.53-.5.53h-2.5l-.131-.006c-.693-.067-1.269-.662-1.269-1.354V1.36l.007-.13C.915.554 1.532 0 2.238 0h2.5zm6.68 4.295c.206-.203.505-.182.694.035l2.9 3.32.059.073a.474.474 0 01-.05.616l-2.909 3.33-.067.065c-.187.155-.44.154-.594 0-.272-.21-.29-.529-.087-.764L13.5 8.53h-8.76l-.1-.008c-.29-.041-.5-.263-.5-.522 0-.288.26-.53.6-.53h8.76l-2.135-2.441-.06-.081a.479.479 0 01.114-.653z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgSignOut
