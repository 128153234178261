import React, {ReactElement, FC} from 'react'
import styled from 'styled-components'
import {Spacer} from './Spacer'
import {colors} from './theme'
import {SquareLogo} from './SquareLogo/SquareLogo'
import {useHistory} from 'react-router-dom'
import {playground} from '~/pages/Playground'

export const SIDEBAR_WIDTH = 248

const Container = styled.div`
    padding-left: ${SIDEBAR_WIDTH}px;
    position: relative;
    min-height: 100vh;
`

const Sidebar = styled.div`
    z-index: 1;
    background-color: ${colors.lightGray};
    width: ${SIDEBAR_WIDTH}px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 32px;
    padding-right: 28px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`

const MiddleContent = styled.div`
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -16px;
`

const ContentContainer = styled.div`
    position: relative;
    min-height: 100vh;
    width: 100%;
`

type SidebarLayoutProps = {
    logo?: boolean
    logoLink?: string
    topContent?: ReactElement
    bottomContent?: ReactElement
    middleContent?: ReactElement
}

const SidebarLayout: FC<SidebarLayoutProps> = ({
    children,
    logo = true,
    logoLink,
    topContent,
    middleContent,
    bottomContent,
}) => {
    const {push} = useHistory()

    return (
        <Container>
            <Sidebar>
                {logo && (
                    <>
                        <SquareLogo
                            style={{cursor: logoLink ? 'pointer' : 'unset'}}
                            onClick={() => logoLink && push(logoLink)}
                        />
                        <Spacer height={24} />
                    </>
                )}
                {topContent}
                <MiddleContent>{middleContent}</MiddleContent>
                <Spacer height={16} />
                {bottomContent}
            </Sidebar>
            <ContentContainer>{children}</ContentContainer>
        </Container>
    )
}

export {SidebarLayout}

playground.push({
    path: 'ui/SidebarLayout.tsx',
    component: SidebarLayout,
    props: {
        children: ['MAIN CONTENT'],
        logo: true,
        topContent: 'TOP CONTENT',
        middleContent: 'MIDDLE CONTENT',
        bottomContent: 'BOTTOM CONTENT',
    },
    propOptions: {
        content: {
            get: (props: any) => (props.children || ['MAIN CONTENT'])[0],
            set: (props: any, value: string) => ({...props, children: [value]}),
        },
        logo: [false, true],
        topContent: {
            get: (props: any) => props.topContent,
            set: (props: any, value: string) => ({...props, topContent: value}),
        },
        middleContent: {
            get: (props: any) => props.middleContent,
            set: (props: any, value: string) => ({...props, middleContent: value}),
        },
        bottomContent: {
            get: (props: any) => props.bottomContent,
            set: (props: any, value: string) => ({...props, bottomContent: value}),
        },
    },
})
