import {HTMLAttributes, useRef} from 'react'
import {NonUndefined} from 'utility-types'

export type InputOnChange = NonUndefined<HTMLAttributes<HTMLInputElement>['onChange']>

export const useFileInput = (onChangeCallback: (files: File[]) => void) => {
    const ref = useRef<HTMLInputElement>(null)
    const onChange: InputOnChange = (event) => {
        const files = event.currentTarget.files
        if (!files) return
        onChangeCallback(Array.from(files))
        if (ref.current) ref.current.value = ''
    }
    const openPicker = () => {
        if (ref.current) ref.current.click()
    }
    // We allow you to imperatively set 'accept' to make sure
    // the accept prop is set before openPicker gets fired.
    const setAccept = (accept: string) => {
        if (ref.current) ref.current.setAttribute('accept', accept)
    }
    return {
        inputProps: {ref, onChange, hidden: true, type: 'file'},
        openPicker,
        setAccept,
    }
}
