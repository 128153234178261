import * as React from 'react'
const SvgClose = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgClose(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M10.785 5.134c-.363-.298-.88-.296-1.2.024L7.988 6.755 6.385 5.152c-.313-.312-.882-.312-1.213.02l-.066.074c-.264.337-.243.848.047 1.138l1.603 1.603-1.598 1.598c-.342.342-.32.912.044 1.276l.07.062a.891.891 0 001.143-.081l1.598-1.598 1.603 1.603c.312.313.881.312 1.213-.019l.066-.074c.263-.337.243-.849-.047-1.139L9.245 8.012l1.597-1.597c.331-.331.332-.9.019-1.213l-.076-.068z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgClose
