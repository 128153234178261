import * as React from 'react'
const SvgRotateRight = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgRotateRight(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M7.17.83a7.35 7.35 0 00-5.106 2.106C.827 4.172-.001 6.006-.001 8c0 1.987.858 3.857 2.107 5.106 1.235 1.236 3.07 2.064 5.065 2.064a.53.53 0 100-1.06c-1.7 0-3.271-.708-4.316-1.754A6.293 6.293 0 011.06 8c0-1.698.707-3.268 1.753-4.315A6.296 6.296 0 017.17 1.89c1.7 0 3.27.708 4.315 1.754.829.83 1.483 2.036 1.708 3.342l.134.779-1.26-1.009a.53.53 0 00-.663.828l2.075 1.66a.53.53 0 00.706-.04l1.66-1.66a.53.53 0 00-.75-.749l-.745.746-.088-.588c-.239-1.587-1.018-3.05-2.028-4.059C10.998 1.658 9.164.83 7.17.83z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgRotateRight
