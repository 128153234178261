import React from 'react'
import styled from 'styled-components'
import {motion} from 'framer-motion'
import {ROW_HEIGHT, BORDER_WIDTH, TRANSITION} from './constants'
import {FadePresence} from '../FadePresence'
import {CellHoverState} from './utils/useCellHover'
import {TableScrollState} from './utils/useTableScroll'

const Container = styled(motion.div).attrs(() => ({
    transition: TRANSITION,
    initial: false,
}))`
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(74, 144, 226, 0.3);
    pointer-events: none;
    height: ${ROW_HEIGHT}px;
`

export const CellHighlight: React.FC<{activeCell: CellHoverState['cell']; tableScroll: TableScrollState}> = ({
    activeCell,
    tableScroll,
}) => {
    return (
        <FadePresence>
            {activeCell && !activeCell.header && (
                <Container
                    animate={{
                        translateX: activeCell.offset + BORDER_WIDTH * 2,
                        translateY: activeCell.top + BORDER_WIDTH,
                        width: activeCell.size - BORDER_WIDTH,
                    }}
                    style={{top: tableScroll.inverse.top, left: tableScroll.inverse.left}}
                />
            )}
        </FadePresence>
    )
}
