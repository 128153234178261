import * as React from 'react'
const SvgSwitcher = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgSwitcher(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M8.375 2.604l2.49 2.491a.53.53 0 11-.75.75L8 3.728 5.885 5.844a.529.529 0 11-.75-.749l2.49-2.49a.53.53 0 01.75 0zm0 10.792l2.49-2.491a.53.53 0 10-.75-.75L8 12.272l-2.115-2.115a.529.529 0 10-.75.749l2.49 2.49a.53.53 0 00.75 0z"
                fill={props.color ? props.color : '#101010'}
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgSwitcher
