import {Icon, Typography} from 'nf-ui'
import SvgOpenLink from 'nf-ui/Icons/OpenLink'
import React from 'react'
import {Container} from './DirectoryViewSwitcher'
import {useCurrentUser} from '../useCurrentUser'

export const ManagementViewSwitcher = () => {
    const {me} = useCurrentUser()
    if (!me?.isSuperAdmin) return null

    return (
        <Container to="/management">
            <Icon icon={SvgOpenLink} />
            <Typography.Paragraph bottomMargin={false}>View management</Typography.Paragraph>
        </Container>
    )
}
