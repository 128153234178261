import React from 'react'
import {Redirect} from 'react-router-dom'
import {SignInMethodType} from '~/globalTypes'
import {RedirectToVerificationEmailSent} from '../VerificationEmailSent'
import {SignInOption} from './../Login'
import {LoginPassword} from '././LoginPassword'
import {SignInMagicLink} from './LoginMagicLink'
import {LoginOTP} from './LoginOTP'
import {OAuthRedirect} from './OAuthRedirect'
import {RequestSetPassword} from './RequestSetPassword'

export const HandleSignInOption: React.FC<{
    option: SignInOption
    email: string
    onClickBack: () => void
}> = ({option, email, onClickBack}) => {
    if (option.type === SignInMethodType.VERIFY_SIGN_IN) {
        return <RedirectToVerificationEmailSent email={email} />
    }

    if (option.type === SignInMethodType.OAUTH) {
        if (option.name === 'ADP') return <Redirect to="/login/adp" />
        return <OAuthRedirect config={option.public_config} onError={onClickBack} />
    }

    if (option.type === SignInMethodType.NF_AUTH_PASSWORD) {
        return <LoginPassword email={email} onClickBack={onClickBack} />
    }

    if (option.type === SignInMethodType.NF_AUTH_MAGIC_LINK) {
        return <SignInMagicLink email={email} onClickBack={onClickBack} />
    }

    if (option.type === SignInMethodType.SET_PASSWORD) {
        return <RequestSetPassword email={email} onClickBack={onClickBack} />
    }

    if (option.type === SignInMethodType.OTP) {
        return <LoginOTP email={email} onClickBack={onClickBack} />
    }

    return null
}
