import {useQuery} from '@apollo/client'
import {useMemo} from 'react'
import {useQueryParam} from 'use-query-params'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {Attendee} from '~/objectTypes'
import {MEETINGS_THIS_WEEK} from './useUpcomingMeetings'
import {
    MeetingsThisWeek,
    MeetingsThisWeekVariables,
    MeetingsThisWeek_meetingsThisWeek,
} from './__types__/MeetingsThisWeek'

const getDataFor = (
    meetingProfileId: string,
    meetings: MeetingsThisWeek_meetingsThisWeek[],
): Partial<Attendee> | null => {
    const attendees = meetings.flatMap((meeting) => meeting.attendees)
    return attendees.find((attendee) => attendee.email === meetingProfileId) || null
}

export const useAttendeeData = () => {
    const organisationIdStr = useOrganisationIdStr()
    const {data} = useQuery<MeetingsThisWeek, MeetingsThisWeekVariables>(MEETINGS_THIS_WEEK, {
        variables: {organisationIdStr},
        fetchPolicy: 'cache-only',
    })

    const meetings = useMemo(() => data?.meetingsThisWeek || [], [data])
    const [meetingProfileId] = useQueryParam<string>('meetingProfileId')

    const attendeeData = useMemo(() => (meetingProfileId ? getDataFor(meetingProfileId, meetings) : null), [
        meetingProfileId,
        meetings,
    ])

    return {attendeeData, profileId: meetingProfileId}
}
