import {Button, colors, Spacer, Typography} from 'nf-ui'
import SvgAdd from 'nf-ui/Icons/Add'
import React from 'react'
import styled from 'styled-components'

const FlexContainer = styled.div`
    display: flex;
`

const EmailPreviewContainer = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
`

const EmailMessageContainer = styled.div`
    box-sizing: border-box;
    width: 400px;
    height: 400px;
    background: ${colors.white};
    border: 1px solid ${colors.darkGray};
    border-radius: 3px;
`

const EmailBody = styled.div`
    margin: 16px;
`

const EmailBtnContainer = styled(FlexContainer)`
    justify-content: center;
`

const EmailButton = styled(Button)`
    cursor: not-allowed;
    pointer-events: none;
`

const EmailTemplate: React.FC = () => (
    <EmailPreviewContainer>
        <Typography.Label>Email preview</Typography.Label>
        <Spacer height={16} />
        <EmailMessageContainer>
            <EmailBody>
                <Typography.Paragraph>
                    to: brittney@company.com <br />
                    From: photos@namesandfaces.com <br />
                    Subject: ACTION REQUIRED: Update your N&amp;F profile.
                </Typography.Paragraph>

                <Spacer height={24} />
                <Typography.Paragraph>Dear Brittney,</Typography.Paragraph>
                <Spacer height={8} />
                <Typography.Paragraph>
                    ACME Corporation is adopting Names &amp; Faces as the official company employee directory. We need a
                    head and shoulders photo of you.
                </Typography.Paragraph>
                <Spacer height={8} />
                <EmailBtnContainer>
                    <EmailButton variant="secondary">
                        Click here to upload now <Button.Icon icon={SvgAdd} />
                    </EmailButton>
                </EmailBtnContainer>
                <Spacer height={16} />
                <Typography.Paragraph>
                    This will take less than 1 minute. Thank you! <br />
                    Kelly Knight
                </Typography.Paragraph>
            </EmailBody>
        </EmailMessageContainer>
    </EmailPreviewContainer>
)

export {EmailTemplate}
