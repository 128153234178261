import {gql, useMutation} from '@apollo/client'
import {useAlert} from 'react-alert'
import {DATA_SOURCES} from '~/pages/Adminland/Integrations'
import {getGraphQLErrorMessage} from '~/util'
import {useOrganisationIdStr} from '../useOrganisationIdStr'
import {DataSourceCardDelete, DataSourceCardDeleteVariables} from './__types__/DataSourceCardDelete'

const DELETE_DATA_SOURCE = gql`
    mutation DataSourceCardDelete($dataSourceIdStr: String!) {
        deleteDataSource(dataSourceIdStr: $dataSourceIdStr)
    }
`

export const useDeleteDataSource = () => {
    const alert = useAlert()
    const organisationIdStr = useOrganisationIdStr()

    return useMutation<DataSourceCardDelete, DataSourceCardDeleteVariables>(DELETE_DATA_SOURCE, {
        onError: (error) => alert.error(getGraphQLErrorMessage(error)),
        onCompleted: () => alert.success('Data source successfully removed.'),
        refetchQueries: [{query: DATA_SOURCES, variables: {organisationIdStr}}],
        awaitRefetchQueries: true,
    })
}
