import {gql, useApolloClient, useQuery} from '@apollo/client'
import {Button, CARD_WIDTH, HeaderLayout, Icon, List, Panel, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgCaret from 'nf-ui/Icons/Caret'
import SvgFields from 'nf-ui/Icons/Fields'
import SvgOwner from 'nf-ui/Icons/Owner'
import React, {FC, useCallback, useRef, useState} from 'react'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {AdditionalFieldsCard} from '~/components/Integrations/AdditionalFieldsCard'
import {DataSourceCard, DATA_SOURCE} from '~/components/Integrations/DataSourceCard'
import {ModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {PageLoading} from '~/components/PageLoading'
import {PanelLayout} from '~/components/PanelLayout'
import {PanelNavigatorProvider, usePanelNavigator} from '~/components/PanelNavigator'
import {useCurrentUser} from '~/components/useCurrentUser'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {getGraphQLErrorMessage} from '~/util'
import {AddDataSourceModal} from './AddDataSourceModal'
import {useFullScreenTable} from './ConfigureDataSource/useFullScreenTable'
import {ConfigureDataSourceModal, DataSourceData} from './ConfigureDataSourceModal'
import {AddAdditionalDataModal} from './Integrations/AddAdditionalDataModal'
import {DownloadDataButton} from './Integrations/DownloadDataButton'
import {ManageData} from './Integrations/ManageData/ManageData'
import {ViewDataModal} from './Integrations/ViewDataModal'
import {
    DataSources,
    DataSourcesVariables,
    DataSources_organisation,
    DataSources_organisation_datasources,
} from './__types__/DataSources'
import {LatestDatasetData, LatestDatasetDataVariables} from './__types__/LatestDatasetData'

const IntegrationsList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, ${CARD_WIDTH}px);
    grid-gap: 40px;
`

export type DataSource = {
    organisation: Omit<DataSources_organisation, 'additionalFields'>
}

export const DATA_SOURCES = gql`
    query DataSources($organisationIdStr: String!) {
        organisation(idStr: $organisationIdStr) {
            idStr
            datasources {
                ...DataSourceCard_DataSource
            }
            additionalFields {
                idStr
                name
                type
                helpText
            }
        }
    }

    ${DATA_SOURCE}
`

const LATEST_DATASET_DATA = gql`
    query LatestDatasetData($dataSourceIdStr: String!) {
        dataSource(dataSourceIdStr: $dataSourceIdStr) {
            latestData
        }
    }
`

function useFetchLatestData(dataSourceIdStr?: string) {
    const apolloClient = useApolloClient()
    const [loading, setLoading] = useState(false)

    const fetchLatestData = useCallback(async () => {
        if (loading) return
        if (!dataSourceIdStr) throw new Error('No dataSourceId')
        setLoading(true)
        try {
            const result = await apolloClient.query<LatestDatasetData, LatestDatasetDataVariables>({
                query: LATEST_DATASET_DATA,
                variables: {
                    dataSourceIdStr,
                },
            })

            return result.data.dataSource
        } catch (err) {
            //TODO : Add some alerts
            console.error(err)
            throw err
        } finally {
            setLoading(false)
        }
    }, [apolloClient, dataSourceIdStr, loading])

    return [fetchLatestData, loading] as const
}

const DataSource: FC<{
    dataSource: DataSources_organisation_datasources
    configureDataSource: (dataSource: DataSourceData) => void
    additional: boolean
}> = ({dataSource, configureDataSource, additional}) => {
    const [fetchLatestData, editLoading] = useFetchLatestData(dataSource?.idStr)

    const handleReconfigure = useCallback(async () => {
        if (!dataSource) {
            //This isn't a user error but a developer error so hard exception
            throw new Error(`Can't reconfigure datasource that has no datasource`)
        }
        const result = await fetchLatestData()

        if (result) {
            configureDataSource({
                data: result.latestData,
                dataSourceIdStr: dataSource.idStr,
                type: dataSource.type,
                fileType: dataSource.fileType,
            })
        }
    }, [dataSource, fetchLatestData, configureDataSource])

    return (
        <div key={dataSource.idStr}>
            <DataSourceCard
                editLoading={editLoading}
                dataSource={dataSource}
                onReconfigure={handleReconfigure}
                additional={additional}
            />
        </div>
    )
}

const Integrations: FC = () => {
    const organisationIdStr = useOrganisationIdStr()
    const {me} = useCurrentUser()
    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const {data, error} = useQuery<DataSources, DataSourcesVariables>(DATA_SOURCES, {
        variables: {organisationIdStr},
    })
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const dataSources = data?.organisation.datasources

    const hasMultipleDataSources = dataSources && dataSources.length > 1

    const additionalFields = data?.organisation.additionalFields || []
    const hasAdditionalFields = additionalFields.length > 0

    const downloadDataEnabled = currentOrganisation?.appFeatures?.downloadData

    const [dataSourceData, setDataSourceData] = useState<DataSourceData | null>(null)
    const addDataRef = useRef<HTMLButtonElement>(null)
    const {openPanel, panelProps, closePanel} = usePanelNavigator(organisationIdStr)

    const configureDataSource = (data: DataSourceData) => {
        setDataSourceData(data)
        setModal('configureDataSource')
    }

    const [modal, setModal] = useQueryParam<string>('modal')
    const {width, height} = useFullScreenTable({offsetHeight: 102, useFullWidth: true})

    const addDataPanelRows = [
        {
            icon: SvgFields,
            label: 'Add additional fields',
            onClick: () => {
                trackAnalyticsEvent('select_add_additional_data')
                setModal('addAdditionalData')
            },
        },
    ]
    if (!hasMultipleDataSources) {
        addDataPanelRows.unshift({
            icon: SvgOwner,
            label: 'Add profiles',
            onClick: () => {
                trackAnalyticsEvent('select_add_profiles')
                setModal('addDataSource')
            },
        })
    }

    if (currentOrganisation?.appFeatures?.inAppData) {
        return (
            <>
                <Spacer height={64} />
                <Spacer height={16} />
                <ManageData width={width} height={height} />
            </>
        )
    }

    if (error) {
        return <Typography.Heading data-testid="error">Error: {getGraphQLErrorMessage(error)}</Typography.Heading>
    }

    if (!data) return <PageLoading />

    return (
        <HeaderLayout
            heading="Connect data"
            subheading="Connect and manage the source(s) of data that populate your directory."
            rightContent={
                <>
                    {dataSources && (
                        <>
                            <Button
                                variant="secondary"
                                data-testid="viewData"
                                onClick={() => setModal('viewData')}
                                onClickAnalyticsEvent="select_your_data"
                            >
                                View data
                            </Button>
                            <Spacer width={16} />
                        </>
                    )}
                    <Button
                        variant="primary"
                        ref={addDataRef}
                        onClick={() => openPanel('addData', {itemId: organisationIdStr})}
                        onClickAnalyticsEvent="select_add_data"
                    >
                        Add data
                        <Button.Icon icon={SvgCaret} />
                    </Button>
                    {(me?.isSuperAdmin || downloadDataEnabled) && (
                        <>
                            <Spacer width={16} />
                            <DownloadDataButton />
                        </>
                    )}
                    <Panel targetRef={addDataRef} {...panelProps}>
                        <PanelLayout key="addData">
                            <List
                                rows={addDataPanelRows}
                                onClick={(row) => {
                                    row.onClick()
                                    closePanel()
                                }}
                                renderRow={(row) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            height: '100%',
                                        }}
                                    >
                                        {row.label} <Icon icon={row.icon} />
                                    </div>
                                )}
                                width={304}
                            />
                        </PanelLayout>
                    </Panel>
                </>
            }
        >
            <Spacer height={16} />
            <PanelNavigatorProvider>
                <IntegrationsList>
                    {dataSources && (
                        <div>
                            <Typography.Label>Profiles</Typography.Label>
                            <Spacer height={8}></Spacer>
                            {dataSources.map((dataSource, index) => (
                                <React.Fragment key={index}>
                                    <DataSource
                                        configureDataSource={configureDataSource}
                                        dataSource={dataSource}
                                        additional={index !== 0}
                                    />
                                    <Spacer height={16} />
                                </React.Fragment>
                            ))}
                        </div>
                    )}
                    {hasAdditionalFields && (
                        <div>
                            <Typography.Label>Additional fields</Typography.Label>
                            <Spacer height={8}></Spacer>
                            <AdditionalFieldsCard additionalFields={additionalFields} />
                        </div>
                    )}
                </IntegrationsList>
            </PanelNavigatorProvider>

            <ModalNavigator modal={modal} setModal={(modal) => setModal(modal || '')}>
                <AddDataSourceModal
                    key="addDataSource"
                    onPick={configureDataSource}
                    addEnabled={data.organisation.datasources.length < 2}
                />
                <ConfigureDataSourceModal
                    key="configureDataSource"
                    dataSourceData={dataSourceData!}
                    primary={false}
                    organisationIdStr={organisationIdStr}
                />
                <AddAdditionalDataModal key="addAdditionalData" />
                <ViewDataModal key="viewData" hasAdditionalFields={hasAdditionalFields} />
            </ModalNavigator>
        </HeaderLayout>
    )
}

const IntegrationsPage: FC = () => {
    return (
        <PanelNavigatorProvider>
            <Integrations />
        </PanelNavigatorProvider>
    )
}

export {IntegrationsPage as Integrations}
