import React, {useContext, ReactElement} from 'react'
import styled from 'styled-components'
import {Typography} from '../Typography'
import {colors} from '../theme'
import {StepperContext} from '.'
import {Icon} from '../Icon'
import {TooltipTarget} from '../Tooltip'
import SvgCheck from '../Icons/Check'

export interface StepProps {
    children?: StepElementType[] | null
    label: string
    currentStepIndex?: number
}

type StepElementType = React.FunctionComponentElement<StepProps>

const Container = styled.div`
    position: relative;
`

enum StepState {
    INACTIVE,
    ACTIVE,
    COMPLETED,
}

const Circle = styled.div<{state: StepState}>`
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border: 2px solid ${({state}) => (state === StepState.INACTIVE ? colors.darkGray : colors.primary[100])};
    border-radius: 50%;
    background-color: ${({state}) => (state === StepState.COMPLETED ? colors.primary[100] : colors.white)};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    transition-property: border-color, background-color;
`

const Label = styled(Typography.Meta)`
    position: absolute;
    white-space: nowrap;
    top: 100%;
    display: block;
    transform: translateX(-50%);
    left: 50%;
    margin-top: 8px;
`

const SubSteps = styled.div`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const SubStepCircle = styled.div<{state: StepState}>`
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    border: 2px solid ${({state}) => (state === StepState.ACTIVE ? colors.primary[100] : colors.darkGray)};
    border-radius: 50%;
    background-color: ${({state}) => (state === StepState.ACTIVE ? colors.primary[100] : colors.darkGray)};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    transition-property: border-color, background-color;
`

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;

    :hover {
        cursor: pointer;
    }
`

export const Step: React.FC<StepProps> = ({label, currentStepIndex: currentSubStepIndex = 0, children}) => {
    const {currentStepIndex, index, completed} = useContext(StepperContext)

    let state = StepState.INACTIVE
    if (currentStepIndex === index) state = StepState.ACTIVE
    if (currentStepIndex > index || completed) {
        state = StepState.COMPLETED
    }

    return (
        <Container>
            <Circle state={state}>
                <Icon icon={SvgCheck} tint={colors.white} />
            </Circle>
            <Label>{label}</Label>
            <SubSteps>
                {state === StepState.ACTIVE &&
                    children &&
                    React.Children.map<ReactElement, StepElementType>(children, (child, index) => {
                        let childState = StepState.INACTIVE
                        if (currentSubStepIndex === index) childState = StepState.ACTIVE
                        else if (currentSubStepIndex > index) childState = StepState.COMPLETED

                        return (
                            <TooltipTarget position="bottom" title={child.props.label}>
                                <IconContainer>
                                    {childState === StepState.COMPLETED ? (
                                        <Icon icon={SvgCheck} tint={colors.primary[100]} />
                                    ) : (
                                        <SubStepCircle data-testid="step-circle" state={childState} />
                                    )}
                                </IconContainer>
                            </TooltipTarget>
                        )
                    })}
            </SubSteps>
        </Container>
    )
}
