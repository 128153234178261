import * as React from 'react'
const SvgUsersRegular = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgUsersRegular(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M8 1a3.023 3.023 0 00-3.02 3.02l.002.153.006.101.057.536-.485-.234a2.606 2.606 0 00-3.73 2.35c0 .49.132.956.38 1.357l.112.168.19.259-.271.171a2.662 2.662 0 00-1.235 2.07L0 11.135v2.016a.53.53 0 001.051.095l.009-.095v-2.016c0-.837.638-1.524 1.454-1.597l.146-.007h.383l.144-.012a2.79 2.79 0 00.495 0l.126-.015.342-.049v5.356a.53.53 0 001.051.095l.009-.095v-4.98c0-1.54 1.252-2.79 2.79-2.79a2.786 2.786 0 012.785 2.62l.005.17v4.98a.53.53 0 001.051.095l.009-.095V9.455l.342.049c.087.012.167.02.25.024l.226.001.23-.005.038.005.404.002a1.6 1.6 0 011.593 1.458l.007.146v2.016a.53.53 0 001.051.095l.009-.095v-2.016c0-.863-.413-1.655-1.091-2.152l-.15-.102-.27-.171.189-.26c.319-.435.492-.963.492-1.524a2.604 2.604 0 00-3.553-2.427l-.177.077-.485.234.057-.536a2.27 2.27 0 00.008-.254A3.023 3.023 0 008 1zM3.44 5.38a1.546 1.546 0 010 3.091c-.855 0-1.55-.692-1.55-1.545 0-.854.694-1.546 1.55-1.546zm9.13 0a1.546 1.546 0 010 3.091c-.855 0-1.55-.692-1.55-1.545 0-.854.694-1.546 1.55-1.546zM8 2.06c1.084 0 1.96.877 1.96 1.96 0 1.084-.875 1.96-1.96 1.96a1.958 1.958 0 01-1.96-1.96c0-1.083.876-1.96 1.96-1.96z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgUsersRegular
