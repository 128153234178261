import {Button, colors} from 'nf-ui'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'
import React, {useContext} from 'react'
import styled from 'styled-components'
import {FooterPropsContext} from '.'

export const FOOTER_HEIGHT = 72

const FooterSpacer = styled.div`
    height: ${FOOTER_HEIGHT}px;
    width: 100%;
`

const Container = styled.div<{color?: string}>`
    height: ${FOOTER_HEIGHT}px;
    background: ${({color}) => color ?? colors.white};
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
`

const InnerContainer = styled.div<{isOnboarding: boolean; back: boolean; stretch: boolean}>`
    display: flex;
    width: ${({isOnboarding}) => (isOnboarding ? '960px' : '100%')};
    margin: 0 ${({isOnboarding}) => (isOnboarding ? 'auto' : '32px')};
    justify-content: ${({back, stretch}) => (back || stretch ? 'space-between' : 'flex-end')};
    align-items: center;
`

const ChildrenContainer = styled.div`
    display: flex;
    align-items: center;
`
export type ModalFooterProps = {
    isOnboarding?: boolean
    back?: boolean
    onClickBack?: () => void
    color?: string
    stretch?: boolean
}

const Footer: React.FC<ModalFooterProps> = (props) => {
    const defaultProps = useContext(FooterPropsContext)
    const {children, isOnboarding = false, back = false, onClickBack, color, stretch = false} = {
        ...defaultProps,
        ...props,
    }

    return (
        <>
            <FooterSpacer />
            <Container color={color}>
                <InnerContainer isOnboarding={isOnboarding} back={back} stretch={stretch}>
                    {back && (
                        <Button variant="tertiary" onClick={onClickBack}>
                            <Button.Icon icon={SvgArrowLeft} /> Go Back
                        </Button>
                    )}
                    {!stretch && <ChildrenContainer>{children}</ChildrenContainer>}
                    {stretch && children}
                </InnerContainer>
            </Container>
        </>
    )
}

export {Footer}
