import {Row} from './useRows'
import {ROW_HEIGHT} from '../constants'
import {getHiddenRowsBefore} from './getHiddenRowsBefore'
import {Style} from './useAnimateGrid'

type cell = {
    top: number
    header: boolean
    isHeader: boolean
    data: Record<string, string>
    size: number
    offset: number
    style: Style
    firstVisible: boolean
    lastVisible: boolean
    index: number
}

export const useRowHighlight = ({rows, highlightRows}: {rows: Row[]; highlightRows: number[]}) => {
    if (rows.length === 0 || highlightRows.length === 0) {
        return {cells: null}
    }

    const cells: cell[] = []

    highlightRows.forEach((highlightRow) => {
        if (highlightRow === 0) return
        const visibleRowIndex = highlightRow - getHiddenRowsBefore(highlightRow, rows)

        const cell = {
            ...rows[highlightRow],
            top: visibleRowIndex * ROW_HEIGHT,
            header: visibleRowIndex === 0,
        }
        cells.push(cell)
    })

    return {cells: cells}
}

export type RowHighlightState = ReturnType<typeof useRowHighlight>
