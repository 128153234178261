import {AnimatePresence, motion} from 'framer-motion'
import {easeInOutQuad} from 'nf-ui'
import React from 'react'
import {FULL_PROFILE_WIDTH} from '../FullProfile/FullProfile'
import {AttendeeProfile} from './AttendeeProfile'
import {useAttendeeData} from './useAttendeeData'

export const profilesGridTransition = {
    duration: 0.3,
    ease: easeInOutQuad,
}

export const ProfileSidePanel = () => {
    const {profileId, attendeeData} = useAttendeeData()
    if (profileId && !attendeeData) return null
    return (
        <>
            {profileId && <div style={{width: FULL_PROFILE_WIDTH}} />}
            <AnimatePresence exitBeforeEnter initial={false}>
                {profileId && (
                    <motion.div
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={{
                            open: {x: 0},
                            closed: {x: FULL_PROFILE_WIDTH},
                        }}
                        transition={profilesGridTransition}
                        style={{
                            zIndex: 1,
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            bottom: 0,
                        }}
                    >
                        {profileId && attendeeData && <AttendeeProfile data={attendeeData} />}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}
