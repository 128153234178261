import React from 'react'
import {Checkbox, CheckboxProps} from '../Checkbox'
import {useField, useFormikContext} from 'formik'
import {useGroupContext} from '../Group'

const FormikCheckboxOption: React.FC<CheckboxProps & {option: string}> = ({label, option, multiple}) => {
    const {name} = useGroupContext()
    const [field] = useField<string[]>(name)
    const {setFieldValue} = useFormikContext<Record<string, string[]>>()

    const current = field.value
    const checked = current.includes(option)

    const toggleField = () => {
        let updated: string[]

        if (checked) {
            updated = multiple ? current.filter((item) => item !== option) : []
        } else {
            updated = multiple ? [...current, option] : [option]
        }

        setFieldValue(name, updated)
    }

    return <Checkbox name={option} id={option} checked={checked} onCheck={() => toggleField()} label={label} />
}

export {FormikCheckboxOption}
