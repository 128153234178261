import React from 'react'
import styled from 'styled-components'
import {colors} from '../theme'
import {Typography} from '../Typography'
import {AlertComponentPropsWithStyle, AlertType} from 'react-alert'

interface AlertProps {
    variant: AlertType
}

const Container = styled.div<AlertProps>`
    display: inline-block;
    border-radius: 3px;
    height: 56px;
    background-color: ${(props) => (props.variant === 'error' ? colors.red : colors.green[100])};
    cursor: pointer;
    p {
        padding: 16px;
    }
`
export const Alert: React.FC<AlertComponentPropsWithStyle> = ({message, style, options, close}) => {
    return (
        <Container variant={options.type || 'info'} style={style} onClick={close}>
            <Typography.Paragraph>{message}</Typography.Paragraph>
        </Container>
    )
}
