import {Button, colors, Spacer, Typography} from 'nf-ui'
import React, {FC} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {Action} from '../useConfigureDataSource'

const HeadingContainer = styled.div`
    width: 304px;
    background: ${colors.primary[100]};
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    padding: 16px;
    box-sizing: border-box;
`

const ConfirmationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 -16px;
`

export const HeadingPanel: FC<{
    onClickCancel: () => void
    dispatch: React.Dispatch<Action>
}> = ({onClickCancel, dispatch}) => {
    const alert = useAlert()

    return (
        <HeadingContainer>
            <Typography.Label color="white">Does this row contain your headings?</Typography.Label>
            <Spacer height={16} />
            <Typography.Paragraph maxWidth={272} color="white">
                If not, you’ll need to add headings to the first row of your data before continuing.
            </Typography.Paragraph>

            <ConfirmationContainer>
                <Button
                    variant="tertiary"
                    color={colors.white}
                    onClick={() => {
                        onClickCancel()
                        alert.error('Please include a heading row in your data')
                    }}
                    onClickAnalyticsEvent="select_headings"
                    onClickAnalyticsData={{option: 'No'}}
                >
                    No, it doesn't
                </Button>
                <Button
                    variant="tertiary"
                    color={colors.white}
                    onClick={() => {
                        dispatch({type: 'NEXT_FIELD'})
                    }}
                    onClickAnalyticsEvent="select_headings"
                    onClickAnalyticsData={{option: 'Yes'}}
                >
                    Yes, it does
                </Button>
            </ConfirmationContainer>
        </HeadingContainer>
    )
}
