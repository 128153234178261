import {gql} from '@apollo/client'
import React from 'react'
import styled from 'styled-components'
import {useOrganisationIdStr} from '../useOrganisationIdStr'
import ArrowLeft from './arrowLeft.svg'
import Ellipsis from './ellipsis.svg'
import {PhonePreview, PhonePreviewProps} from './PhonePreview'
import ProfileOverlay from './profileOverlay.svg'
import {SampleProfile_organisation_sampleProfile} from './__types__/SampleProfile'
import {LineItems} from '../ProfileView/LineItems'
import {orderPhotos, useOrganisationContext} from '~/components/OrganisationContext'
import {ProfileLine} from '../OrganisationContext'

const Container = styled.div`
    font-family: 'halyard-display';
    padding-bottom: 18px;
`

const NavBar = styled.div`
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
`

const Photo = styled.div`
    width: 100%;
    height: 316px;
    background-size: cover;
    background-position: center;
    background-color: #d8d8d8;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

export const SAMPLE_PROFILE = gql`
    query SampleProfile($organisationIdStr: String!) {
        organisation(idStr: $organisationIdStr) {
            idStr
            sampleProfile {
                idStr
                fullName
                photos {
                    idStr
                    profileUrl
                    priority
                }
            }
            messageOptions
        }
    }
`

export const PhonePreviewProfile: React.FC<{
    profile: SampleProfile_organisation_sampleProfile
    hoveringId?: string
    profileLines: ProfileLine[]
} & PhonePreviewProps> = ({profile, hoveringId, profileLines, ...rest}) => {
    const organisationIdStr = useOrganisationIdStr()
    const [dataEditValues] = useOrganisationContext.useDataEditValuesByProfileIdStr()
    const [fields] = useOrganisationContext.useDataEditFields()

    return (
        <PhonePreview
            navBar={
                <NavBar>
                    <ArrowLeft />
                    <Ellipsis />
                </NavBar>
            }
            {...rest}
        >
            <Container>
                <Photo style={{backgroundImage: `url('${orderPhotos(profile.photos)[0]?.profileUrl}')`}}>
                    <ProfileOverlay />
                </Photo>
                <div style={{height: 37}} />
                <LineItems
                    organisationIdStr={organisationIdStr}
                    profileIdStr={profile.idStr}
                    profileLines={profileLines.map((line) => ({
                        ...line,
                        highlight: hoveringId === line.fieldOrCategoryIdStrs.join(';'),
                    }))}
                    fields={fields.map((field) => {
                        const unorderedValues = (dataEditValues[profile.idStr] || [])
                            .filter((value) => value.fieldOrParentCategoryIdStr === field.idStr)
                            .map((value) => value.value)
                        const values = field.availableValues
                            .map((value, index) => ({value, index, valueIdStr: field.availableValueIdStrs[index]}))
                            .filter((v) => unorderedValues.includes(v.value))
                        return {
                            ...field,
                            values: values.length ? values.map((v) => v.value) : ['Placeholder text'],
                            valueIdStrs: values.map((v) => v.valueIdStr),
                        }
                    })}
                ></LineItems>
            </Container>
        </PhonePreview>
    )
}
