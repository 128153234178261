import {gql, useQuery} from '@apollo/client'
import {useQueryAllHomeItemData} from '~/apollo/queries/AllHomeItemData'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {HomeItemType} from '~/objectTypes'
import {AllStaffData, AllStaffDataVariables} from './__types__/AllStaffData'

export const ALL_STAFF_QUERY = gql`
    query AllStaffData($idStr: String!) {
        profiles(organisationIdStr: $idStr) {
            idStr
            firstName
            lastName
            fullName
            photos {
                idStr
                thumbUrl
                priority
            }
        }
        organisation(idStr: $idStr) {
            idStr
            profilesCount
            appFeatures {
                masterDirectory
            }
        }
    }
`

export function useAllStaffData() {
    const orgIdStr = useOrganisationIdStr()
    return useQuery<AllStaffData, AllStaffDataVariables>(ALL_STAFF_QUERY, {
        variables: {
            idStr: orgIdStr,
        },
    })
}

export function useAllHomeItem() {
    const orgIdStr = useOrganisationIdStr()
    const [{data}] = useQueryAllHomeItemData(orgIdStr)
    const homeItem = data?.organisation?.homeItems?.find((item) => item.type === HomeItemType.ALL)
    const label = homeItem?.label
    return {label}
}
