import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    width: 960px;
    margin: 0 auto;
`

const Body: React.FC<{style?: React.CSSProperties}> = ({children, style}) => (
    <Container style={style}>{children}</Container>
)

export {Body, Container}
