import {Panel} from 'nf-ui'
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {playground} from '~/pages/Playground'
import {formatDateDDMMYYYY, toStringAndPad} from '~/util/date'
import {Column, Row, scrollIfNeeded} from '../Layout'
import {Heading3} from '..'
import {StyledInput} from './VisibleInput'

const initDenseArray = (size: number) => {
    const array = new Array(size)
    for (let index = 0; index < array.length; index++) {
        array[index] = index
    }
    return array
}

const Calendar = ({
    stringValue,
    setStringValue,
    inputPosition,
    setInputPosition,
}: {
    stringValue: string
    setStringValue: (value: string) => void
    inputPosition: number
    setInputPosition: (number: number) => void
}) => {
    const selectedYearRef = useRef<HTMLDivElement>(null)
    const calendarRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (inputPosition === 6) {
            scrollIfNeeded(selectedYearRef.current, {
                scrollContainer: calendarRef.current,
                finishTimeoutMilliseconds: 0,
            })
        }
    }, [inputPosition, stringValue])

    if (inputPosition === 0) {
        const dayValue = stringValue.slice(0, 2)
        return (
            <Column ref={calendarRef} height="170px" width="230px">
                <Row height="10px"></Row>
                {initDenseArray(5).map((row) => (
                    <Row key={row} height="30px" centerHorizontal>
                        <Column width="10px"></Column>
                        {initDenseArray(7).map((col) => {
                            const dayCell = (1 + col + 7 * row).toString()
                            const dayCellPadded = toStringAndPad(dayCell, 2)
                            const isSelected = dayCellPadded === dayValue
                            const isValid = dayCell < 32
                            return (
                                <Column
                                    key={col}
                                    width="30px"
                                    centerVertical
                                    borderRadius="3px"
                                    backgroundColor={isSelected ? 'rgb(60, 142, 255)' : 'white'}
                                    onClick={() => {
                                        if (!isValid) return
                                        setStringValue(`${dayCellPadded}${stringValue.slice(2, 10)}`)
                                        setInputPosition(3)
                                    }}
                                >
                                    {isValid ? (
                                        <Heading3
                                            fontSize="14px"
                                            centerHorizontal
                                            cursor="pointer"
                                            color={isSelected ? 'white' : undefined}
                                        >
                                            {dayCell}
                                        </Heading3>
                                    ) : (
                                        ''
                                    )}
                                </Column>
                            )
                        })}
                        <Column width="10px"></Column>
                    </Row>
                ))}
                <Row height="10px"></Row>
            </Column>
        )
    }
    if (inputPosition === 3) {
        const monthValue = stringValue.slice(3, 5)
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
        return (
            <Column ref={calendarRef} height="170px" width="230px">
                <Row height="10px"></Row>
                {initDenseArray(3).map((row) => (
                    <Row key={row} height="30px">
                        <Column width="10px"></Column>
                        {initDenseArray(4).map((col) => {
                            const monthCell = 1 + col + 4 * row
                            const monthCellPadded = toStringAndPad(monthCell, 2)
                            const monthCellString = months[col + 4 * row]
                            const isSelected = monthCellPadded === monthValue
                            return (
                                <Column
                                    key={col}
                                    width="40px"
                                    centerVertical
                                    borderRadius="3px"
                                    backgroundColor={isSelected ? 'rgb(60, 142, 255)' : 'white'}
                                    onClick={() => {
                                        setStringValue(
                                            `${stringValue.slice(0, 3)}${monthCellPadded}${stringValue.slice(5, 10)}`,
                                        )
                                        setInputPosition(6)
                                    }}
                                >
                                    <Heading3
                                        fontSize="14px"
                                        centerHorizontal
                                        cursor="pointer"
                                        color={isSelected ? 'white' : undefined}
                                    >
                                        {monthCellString}
                                    </Heading3>
                                </Column>
                            )
                        })}
                        <Column width="10px"></Column>
                    </Row>
                ))}
                <Row height="10px"></Row>
            </Column>
        )
    }
    const yearValue = stringValue.slice(6, 10)
    return (
        <Column ref={calendarRef} height="170px" width="230px" overflow="auto">
            <Row height="10px"></Row>
            {initDenseArray(20).map((row) => (
                <Row key={row} height="30px">
                    <Column width="10px"></Column>
                    {initDenseArray(5).map((col) => {
                        const yearCell = new Date().getFullYear() - col - 5 * row
                        const yearCellPadded = toStringAndPad(yearCell, 4)
                        const isSelected = yearCellPadded === yearValue
                        return (
                            <Column
                                key={col}
                                width="40px"
                                height="30px"
                                centerVertical
                                borderRadius="3px"
                                backgroundColor={isSelected ? 'rgb(60, 142, 255)' : 'white'}
                                onClick={() => {
                                    setStringValue(`${stringValue.slice(0, 6)}${yearCellPadded}`)
                                }}
                            >
                                <Heading3
                                    ref={isSelected ? selectedYearRef : null}
                                    fontSize="14px"
                                    centerHorizontal
                                    cursor="pointer"
                                    color={isSelected ? 'white' : undefined}
                                >
                                    {yearCellPadded}
                                </Heading3>
                            </Column>
                        )
                    })}
                    <Column width="10px"></Column>
                </Row>
            ))}
            <Row height="10px"></Row>
        </Column>
    )
}

type DatePickerProps = {
    placeholder: string
    value: Date | undefined
    onChange: (value: Date | undefined) => void
    onDoneEditing: () => void
    width?: string
}

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
    ({placeholder, value, onChange, onDoneEditing, width}: DatePickerProps, ref) => {
        const [stringValue, setStringValue] = useState<string>(value ? formatDateDDMMYYYY(value) : 'dd/mm/yyyy')
        const [inputPosition, setInputPosition] = useState<number>(0)
        const [hasFocus, setHasFocus] = useState<boolean>(false)
        const [regainFocus, setRegainFocus] = useState<boolean>(false)
        const [scrollingComplete, setScrollingComplete] = useState<boolean>(false)
        const innerRef = useRef<HTMLInputElement>(null)
        useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => innerRef.current)

        useEffect(() => {
            setStringValue(value ? formatDateDDMMYYYY(value) : 'dd/mm/yyyy')
        }, [value])

        const scrollIntoView = () => {
            scrollIfNeeded(innerRef.current, {
                startTimeoutMilliseconds: 400,
                align: 'bottom',
                marginBottom: 190,
                onScrollComplete: () => setScrollingComplete(true),
            })
        }

        const setSelection = (position: number) => {
            const element = innerRef.current
            if (!element) return
            if (position < 2) {
                element.selectionStart = 0
                element.selectionEnd = 2
                return
            }
            if (position < 5) {
                element.selectionStart = 3
                element.selectionEnd = 5
                return
            }
            element.selectionStart = 6
            element.selectionEnd = 10
        }

        useEffect(() => {
            setSelection(inputPosition)
            inputPosition === 10 && onDoneEditing()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [inputPosition])

        useEffect(() => {
            if (hasFocus) {
                if (regainFocus) {
                    setRegainFocus(false)
                    setScrollingComplete(true)
                    return
                }
                scrollIntoView()

                if (inputPosition === 0) {
                    setSelection(inputPosition)
                } else {
                    setInputPosition(0)
                }
            } else {
                if (regainFocus) {
                    innerRef.current?.focus()
                } else {
                    setScrollingComplete(false)
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hasFocus])

        useEffect(() => {
            if (regainFocus && !hasFocus) {
                innerRef.current?.focus()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [regainFocus])

        useEffect(() => {
            if (/^[0-9]{2}[/][0-9]{2}[/][0-9]{4}$/.test(stringValue)) {
                onChange(new Date(`${stringValue.slice(6, 10)}/${stringValue.slice(3, 5)}/${stringValue.slice(0, 2)}`))
            } else if (stringValue === 'dd/mm/yyyy') {
                onChange(undefined)
            }
            inputPosition === 10 && onDoneEditing()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [stringValue])

        const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (
                ['ArrowLeft', 'ArrowRight', 'Enter', 'ArrowUp', 'ArrowDown', 'Backspace'].includes(event.key) ||
                /^[0-9]$/.test(event.key)
            )
                event.preventDefault()
        }

        const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (
                ['ArrowLeft', 'ArrowRight', 'Enter', 'ArrowUp', 'ArrowDown', 'Backspace'].includes(event.key) ||
                /^[0-9]$/.test(event.key)
            )
                event.preventDefault()
        }

        const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (
                ['ArrowLeft', 'ArrowRight', 'Enter', 'ArrowUp', 'ArrowDown', 'Backspace'].includes(event.key) ||
                /^[0-9]$/.test(event.key)
            )
                event.preventDefault()

            if (event.key === 'ArrowLeft') {
                setInputPosition(inputPosition > 5 ? 3 : 0)
            }
            if (event.key === 'ArrowRight') {
                setInputPosition(inputPosition < 3 ? 3 : 6)
            }
            if (event.key === 'Enter') {
                if (inputPosition < 3) {
                    setInputPosition(3)
                } else if (inputPosition < 6) {
                    setInputPosition(6)
                } else {
                    setInputPosition(10)
                }
            }
            if (event.key === 'ArrowUp') {
                if (inputPosition < 3) {
                    const component = parseInt(stringValue.slice(0, 2))
                    if (isNaN(component)) {
                        setStringValue(`${toStringAndPad(new Date().getDate(), 2)}/${stringValue.slice(3, 10)}`)
                    } else if (component > 30) {
                        setStringValue(`01/${stringValue.slice(3, 10)}`)
                    } else {
                        setStringValue(`${toStringAndPad(component + 1, 2)}/${stringValue.slice(3, 10)}`)
                    }
                    setInputPosition(0)
                } else if (inputPosition < 6) {
                    const component = parseInt(stringValue.slice(3, 5))
                    if (isNaN(component)) {
                        setStringValue(
                            `${stringValue.slice(0, 2)}/${toStringAndPad(
                                new Date().getMonth() + 1,
                                2,
                            )}/${stringValue.slice(6, 10)}`,
                        )
                    } else if (component > 11) {
                        setStringValue(`${stringValue.slice(0, 2)}/01/${stringValue.slice(6, 10)}`)
                    } else {
                        setStringValue(
                            `${stringValue.slice(0, 2)}/${toStringAndPad(component + 1, 2)}/${stringValue.slice(
                                6,
                                10,
                            )}`,
                        )
                    }
                    setInputPosition(3)
                } else {
                    const component = parseInt(stringValue.slice(6, 10))
                    if (isNaN(component)) {
                        setStringValue(`${stringValue.slice(0, 5)}/${new Date().getFullYear()}`)
                    } else {
                        setStringValue(`${stringValue.slice(0, 5)}/${toStringAndPad(component + 1, 4)}`)
                    }
                    setInputPosition(6)
                }
                setTimeout(setSelection, 1)
            }
            if (event.key === 'ArrowDown') {
                if (inputPosition < 3) {
                    const component = parseInt(stringValue.slice(0, 2))
                    if (isNaN(component)) {
                        setStringValue(`${toStringAndPad(new Date().getDate(), 2)}/${stringValue.slice(3, 10)}`)
                    } else if (component < 2) {
                        setStringValue(`31/${stringValue.slice(3, 10)}`)
                    } else {
                        setStringValue(`${toStringAndPad(component - 1, 2)}/${stringValue.slice(3, 10)}`)
                    }
                    setInputPosition(0)
                } else if (inputPosition < 6) {
                    const component = parseInt(stringValue.slice(3, 5))
                    if (isNaN(component)) {
                        setStringValue(
                            `${stringValue.slice(0, 2)}/${toStringAndPad(
                                new Date().getMonth() + 1,
                                2,
                            )}/${stringValue.slice(6, 10)}`,
                        )
                    } else if (component < 2) {
                        setStringValue(`${stringValue.slice(0, 2)}/12/${stringValue.slice(6, 10)}`)
                    } else {
                        setStringValue(
                            `${stringValue.slice(0, 2)}/${toStringAndPad(component - 1, 2)}/${stringValue.slice(
                                6,
                                10,
                            )}`,
                        )
                    }
                    setInputPosition(3)
                } else {
                    const component = parseInt(stringValue.slice(6, 10))
                    if (isNaN(component)) {
                        setStringValue(`${stringValue.slice(0, 5)}/${new Date().getFullYear()}`)
                    } else {
                        setStringValue(`${stringValue.slice(0, 5)}/${toStringAndPad(component - 1, 4)}`)
                    }
                    setInputPosition(6)
                }
                setTimeout(setSelection, 1)
            }
            if (event.key === 'Backspace') {
                if (inputPosition === 0) {
                    setStringValue(`dd${stringValue.slice(2, 10)}`)
                    setTimeout(setSelection, 1)
                } else if (inputPosition === 3) {
                    setStringValue(`${stringValue.slice(0, 3)}mm${stringValue.slice(5, 10)}`)
                    setTimeout(() => setInputPosition(0), 1)
                } else {
                    setStringValue(`${stringValue.slice(0, 6)}yyyy`)
                    setTimeout(() => setInputPosition(3), 1)
                }
            }
            if (/^[0-9]$/.test(event.key)) {
                const digit = parseInt(event.key)
                if (inputPosition === 0) {
                    if (digit < 4 && stringValue.slice(1, 2) !== ' ') {
                        setStringValue(`${digit} ${stringValue.slice(2, 10)}`)
                        setTimeout(setSelection, 1)
                    } else if (stringValue.slice(1, 2) !== ' ') {
                        setStringValue(`0${digit}${stringValue.slice(2, 10)}`)
                        setTimeout(() => setInputPosition(3), 1)
                    } else if (stringValue.slice(0, 2) === '3 ' && digit > 1) {
                        setStringValue(`3 ${stringValue.slice(2, 10)}`)
                        setTimeout(setSelection, 1)
                    } else if (stringValue.slice(0, 2) === '0 ' && digit < 1) {
                        setStringValue(`0 ${stringValue.slice(2, 10)}`)
                        setTimeout(setSelection, 1)
                    } else {
                        setStringValue(`${stringValue.slice(0, 1)}${digit}${stringValue.slice(2, 10)}`)
                        setTimeout(() => setInputPosition(3), 1)
                    }
                } else if (inputPosition === 3) {
                    if (digit < 2 && stringValue.slice(4, 5) !== ' ') {
                        setStringValue(`${stringValue.slice(0, 3)}${digit} ${stringValue.slice(5, 10)}`)
                        setTimeout(setSelection, 1)
                    } else if (stringValue.slice(4, 5) !== ' ') {
                        setStringValue(`${stringValue.slice(0, 3)}0${digit}${stringValue.slice(5, 10)}`)
                        setTimeout(() => setInputPosition(6), 1)
                    } else if (stringValue.slice(3, 5) === '1 ' && digit > 2) {
                        setStringValue(`${stringValue.slice(0, 3)}1 ${stringValue.slice(5, 10)}`)
                        setTimeout(setSelection, 1)
                    } else if (stringValue.slice(3, 5) === '0 ' && digit < 1) {
                        setStringValue(`${stringValue.slice(0, 3)}0 ${stringValue.slice(5, 10)}`)
                        setTimeout(setSelection, 1)
                    } else {
                        setStringValue(`${stringValue.slice(0, 4)}${digit}${stringValue.slice(5, 10)}`)
                        setTimeout(() => setInputPosition(6), 1)
                    }
                } else {
                    if (stringValue.slice(7, 8) === ' ') {
                        setStringValue(`${stringValue.slice(0, 7)}${digit}  `)
                        setTimeout(setSelection, 1)
                    } else if (stringValue.slice(8, 9) === ' ') {
                        setStringValue(`${stringValue.slice(0, 8)}${digit} `)
                        setTimeout(setSelection, 1)
                    } else if (stringValue.slice(9, 10) === ' ') {
                        setStringValue(`${stringValue.slice(0, 9)}${digit}`)
                        setTimeout(() => setInputPosition(10), 1)
                    } else if (digit > 0 && digit < 3) {
                        setStringValue(`${stringValue.slice(0, 6)}${digit}   `)
                        setTimeout(setSelection, 1)
                    }
                }
            }
        }

        return (
            <span>
                <StyledInput
                    ref={innerRef}
                    type="text"
                    pattern="[0-9]"
                    inputMode="numeric"
                    maxWidth="422px"
                    width={width}
                    placeholder={placeholder}
                    value={hasFocus || stringValue !== 'dd/mm/yyyy' ? stringValue : ''}
                    onKeyDown={handleKeyDown}
                    onKeyPress={handleKeyPress}
                    onKeyUp={handleKeyUp}
                    onFocus={() => setHasFocus(true)}
                    onBlur={() => setHasFocus(false)}
                    onMouseUp={(ev) => {
                        const target = ev.currentTarget
                        setTimeout(() => {
                            const newPosition =
                                (target?.selectionStart || 0) >= 10 && stringValue.slice(0, 2) === 'dd'
                                    ? 0
                                    : (target?.selectionStart || 0) >= 10 && stringValue.slice(3, 5) === 'mm'
                                    ? 3
                                    : (target?.selectionStart || 0) >= 6
                                    ? 6
                                    : (target?.selectionStart || 0) >= 2
                                    ? 3
                                    : 0
                            setInputPosition(newPosition)
                            setSelection(newPosition)
                        }, 1)
                        scrollIntoView()
                    }}
                    onChange={() => {}}
                ></StyledInput>
                <Panel
                    targetRef={innerRef}
                    open={hasFocus && inputPosition < 10 && scrollingComplete}
                    onClose={() => {}}
                >
                    <Calendar
                        stringValue={stringValue}
                        setStringValue={(value) => {
                            setStringValue(value)
                            if (inputPosition >= 6) {
                                setInputPosition(10)
                            } else {
                                setRegainFocus(true)
                            }
                        }}
                        inputPosition={inputPosition}
                        setInputPosition={setInputPosition}
                    />
                </Panel>
            </span>
        )
    },
)

playground.push({
    path: 'src/components/InputFields/DatePicker.tsx',
    component: DatePicker,
    props: {placeholder: 'Placeholder'},
    propOptions: {
        onChange: () => {},
        onEnterPressed: () => {},
    },
})
