import {gql, useMutation} from '@apollo/client'
import {Formik} from 'formik'
import {Button, Form, FormikSwitch, Lightbox, Spacer, Typography} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import React, {useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {getGraphQLErrorMessage} from '~/util'
import {CurrentOrganisation} from './CurrentOrganisationContext'
import {UpdatePhotoApproval, UpdatePhotoApprovalVariables} from './__types__/UpdatePhotoApproval'

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export type PhotoApprovalSettingLightboxProps = {
    open: boolean
    onClose: () => void
}

export const UPDATE_PHOTO_APPROVAL = gql`
    mutation UpdatePhotoApproval($idStr: String!, $photoApproval: Boolean!) {
        updatePhotoApproval(organisationIdStr: $idStr, photoApproval: $photoApproval) {
            idStr
            appFeatures {
                photoApproval
            }
        }
    }
`

export const PhotoApprovalSettingLightbox: React.FC<PhotoApprovalSettingLightboxProps> = ({open, onClose}) => {
    const alert = useAlert()
    const [updatePhotoApproval] = useMutation<UpdatePhotoApproval, UpdatePhotoApprovalVariables>(
        UPDATE_PHOTO_APPROVAL,
        {
            onError: (error) => {
                alert.error(getGraphQLErrorMessage(error))
            },
            onCompleted: () => {
                alert.success(`Organisation settings successfully updated`)
                onClose()
            },
        },
    )
    const {currentOrganisation, loading} = CurrentOrganisation.useContainer()
    const [changed, setChanged] = useState(false)
    const onLightboxClose = () => {
        onClose()
        setChanged(false)
    }

    if (!currentOrganisation || loading) return null

    return (
        <Lightbox onClose={onLightboxClose} open={open} width={560}>
            <FlexWrapper>
                <Typography.Heading>Approval settings</Typography.Heading>
                <Button variant="tertiary" onClick={onLightboxClose}>
                    Close <Button.Icon icon={SvgClose} />
                </Button>
            </FlexWrapper>
            <Spacer height={16} />
            <Formik
                onSubmit={({photoApproval}) =>
                    updatePhotoApproval({variables: {idStr: currentOrganisation.idStr, photoApproval}})
                }
                initialValues={{photoApproval: currentOrganisation.appFeatures.photoApproval}}
            >
                {({handleSubmit, isSubmitting}) => {
                    return (
                        <Form style={{width: 'unset'}} onSubmit={handleSubmit}>
                            <Form.Group name="photoApproval">
                                <Form.Label>
                                    <FlexWrapper>
                                        Require photo changes to be approved by admin
                                        <FormikSwitch onClick={() => setChanged(true)} />
                                    </FlexWrapper>
                                </Form.Label>
                            </Form.Group>
                            <Button type="submit" disabled={!changed} loading={isSubmitting}>
                                Save
                            </Button>
                        </Form>
                    )
                }}
            </Formik>
        </Lightbox>
    )
}
