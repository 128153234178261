import React, {FC} from 'react'
import {Input, InputProps} from '../Input'
import {useField, useFormikContext} from 'formik'
import {useGroupContext} from '../Group'

export type FormikInputProps = Omit<InputProps, 'onChange' | 'value'> & {
    hideError?: boolean
}

export const FormikInput: FC<FormikInputProps> = ({hideError = false, ...rest}) => {
    const {name} = useGroupContext()
    const [field, {error}] = useField(name)
    const {setFieldValue} = useFormikContext<Record<string, string>>()
    const fieldError = hideError ? undefined : error

    return (
        <Input
            {...rest}
            name={name}
            error={fieldError}
            onChange={(value) => setFieldValue(name, value)}
            onBlur={field.onBlur}
            value={field.value}
        />
    )
}
