import {Button, Lightbox, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import React from 'react'
import {Link} from 'react-router-dom'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {getEditIntegrationLink, isIntegration} from '~/components/Integrations/integrations'

type EditPrimaryDataLightboxProps = {
    open: boolean
    onClose: () => void
    additionalProfile: boolean
}

export const EditPrimaryDataLightbox: React.FC<EditPrimaryDataLightboxProps> = ({open, onClose, additionalProfile}) => {
    const {currentOrganisation, hrisProvider} = CurrentOrganisation.useContainer()
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const editDataLink = getEditIntegrationLink(currentOrganisation?.primaryDataSourceType)

    const shouldShowEditLink = !additionalProfile && editDataLink
    const useGenericCopy = additionalProfile || !isIntegration(currentOrganisation?.primaryDataSourceType)

    const heading = useGenericCopy ? 'These fields are restricted' : `Your primary data comes from ${hrisProvider}`

    return (
        <Lightbox onClose={() => onClose()} open={open} width={761}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography.Heading>{heading}</Typography.Heading>
                <Button variant="tertiary" onClick={onClose}>
                    Close <Button.Icon icon={SvgClose} />
                </Button>
            </div>
            <Spacer height={24} />

            {useGenericCopy ? (
                <Typography.Paragraph>
                    Your primary data is not editable through your Names & Faces profile. <br />
                    Contact your company's admin to update this information.
                </Typography.Paragraph>
            ) : (
                <Typography.Paragraph>
                    Your primary data comes from {hrisProvider} and is automatically synced to your Names & Faces
                    profile. <br />
                    To update this information you need to edit it in {hrisProvider}. All updates made to {hrisProvider}
                    take 24 hours to reflect.
                </Typography.Paragraph>
            )}

            <Spacer height={8} />
            {shouldShowEditLink && (
                <Link
                    to={editDataLink!.link}
                    target="_blank"
                    onClick={() => {
                        trackAnalyticsEvent(editDataLink!.analyticsEvent, {from: 'lightbox'})
                    }}
                >
                    <Button variant="primary">{`Edit in ${hrisProvider}`}</Button>
                </Link>
            )}
        </Lightbox>
    )
}
