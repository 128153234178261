import * as React from 'react'
const SvgAdd = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgAdd(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M7.943 4.004c-.468.047-.832.413-.832.866v2.258H4.844C4.402 7.128 4 7.532 4 8l.006.099c.052.425.428.772.838.772l2.267-.001v2.26c0 .484.419.871.933.871l.094-.006a.891.891 0 00.75-.865V8.87h2.268c.442 0 .844-.402.844-.87l-.006-.1c-.052-.424-.428-.772-.838-.772H8.889V4.87c0-.469-.402-.871-.845-.871l-.101.005z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgAdd
