/// <reference types="google.picker" />

import {gql, useApolloClient} from '@apollo/client'
import {useEffect, useState} from 'react'
import {useAlert} from 'react-alert'
import {OAuthProvider} from '~/globalTypes'
import {csvArrayToRecord} from '~/util'
import {asyncRetry, spinWait} from '~/util/async'
import {loadScript} from '~/util/loader'
import {useOAuthFlow} from './useOAuthFlow/useOAuthFlow'
import {GoogleDeveloperKey} from './__types__/GoogleDeveloperKey'
import {UseGoogleSheetsPicker_Data, UseGoogleSheetsPicker_DataVariables} from './__types__/UseGoogleSheetsPicker_Data'

const GOOGLE_DEV_KEY = gql`
    query GoogleDeveloperKey {
        googleDeveloperKey
        googleAppId
    }
`

export type GoogleSheetPickerChangeResult = {
    dataSourceIdStr: string
    dataSetIdStr: string
}

type GoogleSheetPickerOptions = {
    label?: string
    loading?: boolean
    disabled?: boolean
    dataSourceIdStr?: string
}

type PickerOptions = {
    oauthToken: string
    developerKey: string
    appId: string
}

type PickerResponse = {
    action: string
    docs: {id: string}[]
}

function createPicker({oauthToken, developerKey, appId}: PickerOptions) {
    const folderView = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS)
    folderView.setIncludeFolders(true)
    //@ts-ignore
    folderView.setLabel('Google Drive')

    const picker = new google.picker.PickerBuilder()
        .addView(google.picker.ViewId.SPREADSHEETS)
        .addView(folderView)
        .setOAuthToken(oauthToken)
        .setDeveloperKey(developerKey)
        .setAppId(appId)
        .build()

    picker.setVisible(true)

    return new Promise<PickerResponse>((resolve) => {
        picker.setCallback((data: PickerResponse) => {
            if (data.action === 'picked' || data.action === 'cancel') {
                resolve(data)
            }
        })
    })
}

const useLoadGoogleAPI = () => {
    // @ts-ignore
    const [isGoogleLoaded, setIsGoogleLoaded] = useState(() => !!global.gapi)

    const alert = useAlert()

    useEffect(() => {
        if (isGoogleLoaded) return

        const loadGoogle = async () => {
            try {
                //@ts-ignore
                if (!global.gapi) {
                    await asyncRetry(() => loadScript('https://apis.google.com/js/api.js'))
                }
                //Sometimes the script load event is completed before it's parsed, so wait till it shows up
                //@ts-ignore
                await spinWait(() => !!global.gapi)
                //@ts-ignore
                const googleRef = global.google
                if (!googleRef || !googleRef.picker) {
                    //@ts-ignore
                    await global.gapi.load('picker')
                }

                setIsGoogleLoaded(true)
            } catch (err) {
                alert.error('Failed to load sheet picker, refresh the page and try again.')
            }
        }

        loadGoogle()
    }, [isGoogleLoaded, alert])

    return isGoogleLoaded
}

const GOOGLE_SHEET_DATA = gql`
    query UseGoogleSheetsPicker_Data($config: GoogleSheetsData!) {
        googleSheetContents(config: $config)
    }
`

export function useGoogleSheetsPicker({loading: propLoading}: GoogleSheetPickerOptions) {
    const isGoogleLoaded = useLoadGoogleAPI()
    const client = useApolloClient()
    const startAuth = useOAuthFlow({provider: OAuthProvider.GOOGLE})

    const [isProcessing, setIsProcessing] = useState(false)
    const loading = propLoading || !isGoogleLoaded || isProcessing

    const handlePick = async () => {
        try {
            setIsProcessing(true)

            const authResult = await startAuth()
            if (!authResult) {
                setIsProcessing(false)
                return
            }
            const googleDevData = await client.query<GoogleDeveloperKey>({query: GOOGLE_DEV_KEY})

            const pickerResult = await createPicker({
                oauthToken: authResult.token,
                developerKey: googleDevData.data.googleDeveloperKey,
                appId: googleDevData.data.googleAppId,
            })

            if (pickerResult.action === 'picked') {
                const config = {
                    refreshToken: authResult.refreshToken,
                    sheetId: pickerResult.docs[0].id,
                }

                const sheetData = await client.query<UseGoogleSheetsPicker_Data, UseGoogleSheetsPicker_DataVariables>({
                    query: GOOGLE_SHEET_DATA,
                    variables: {config},
                })

                setIsProcessing(false)
                return {
                    data: csvArrayToRecord(sheetData.data.googleSheetContents.data),
                    ...config,
                }
            }

            setIsProcessing(false)
            return null
        } catch (e) {
            setIsProcessing(false)
            throw e
        }
    }
    return {loading, handlePick}
}
