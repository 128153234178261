import {motion} from 'framer-motion'
import styled from 'styled-components'
import {TRANSITION} from '../constants'
import {colors} from '../../theme'

/**
 * Primitive styled component with a background and border, used for highlighting columns and rows.
 */
export const BorderHighlight = styled(motion.div).attrs(() => ({
    transition: TRANSITION,
    initial: false,
}))`
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid ${colors.primary[100]};
    background-color: rgba(74, 144, 226, 0.13);
    pointer-events: none;
    box-sizing: border-box;
    border-radius: 3px;
`
