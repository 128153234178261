import {Form, Spacer} from 'nf-ui'
import React from 'react'
import {PhotoCollectionProps} from './index'
import {Column, Row} from './ListComponents'
import {ProfileSelectState} from './useProfileSelect'

const ProfileRow: React.FC<{
    profile: PhotoCollectionProps['profiles'][0]
    multiselect: ProfileSelectState
}> = React.memo(({profile, multiselect}) => {
    const {checkedProfiles, toggle} = multiselect

    return (
        <Row onClick={() => toggle(profile.idStr)}>
            <Column>
                <Form.Group name="name">
                    <Form.Checkbox checked={checkedProfiles.includes(profile.idStr)} readOnly />
                </Form.Group>
                <Spacer width={16} />
                {profile.fullName}
            </Column>
        </Row>
    )
})

export {ProfileRow}
