import {AnimatePresence, motion} from 'framer-motion'
import {Button, colors, Icon, Spacer, Typography} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import SvgEmail from 'nf-ui/Icons/Email'
import SvgLinkedin from 'nf-ui/Icons/Linkedin'
import React from 'react'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {GuestBadge} from '~/components/Profile'
import {ProfilePicture} from '~/components/ProfilePicture/ProfilePicture'
import {Attendee} from '~/objectTypes'
import {CategoryDetail} from '../FullProfile/CategoryDetail'
import {Detail} from '../FullProfile/Detail'
import {isDetail, ProfileLine} from '../FullProfile/FullProfile'
import {Loading} from '../FullProfile/Loading'
import {SecondaryLine} from '../FullProfile/SecondaryLine'
import {GuestProfileDetails} from './GuestProfileDetails'
import {useAttendeeProfileLinesFor} from './useAttendeeProfileLines'

const OVERLAY_BREAKPOINT = 1204
export const FULL_PROFILE_WIDTH = 404

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(16, 16, 16, 0.3);
    z-index: 49;
    display: none;

    @media (max-width: ${OVERLAY_BREAKPOINT}px) {
        display: block;
    }
`

const Border = styled.div`
    width: 1px;
    height: calc(100% - 64px);
    background-color: ${colors.darkGray};
`

const Container = styled.div`
    width: ${FULL_PROFILE_WIDTH}px;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${colors.white};
    z-index: 50;
    flex-shrink: 0;
    flex-grow: 0;

    @media (max-width: ${OVERLAY_BREAKPOINT}px) {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: ${colors.white};

        ${Border} {
            display: none;
        }
    }
`

const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const PhotoContainer = styled.div`
    position: relative;
    width: 182px;
    height: 273px;
    overflow: hidden;
    border-radius: 3px;
`

const ProfileContentInner = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
`

const Line = styled.div`
    width: 100%;
    border-top: 1px solid #b4c1d0;
`

const StyledLink = styled.a`
    color: inherit;
    text-decoration: none;

    :hover {
        color: inherit;
        text-decoration: underline;
    }
`

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const EmailWrapper: React.FC<{email: string}> = ({email, children}) => {
    return (
        <StyledLink href={`mailto:${email}`} target="_">
            <FlexContainer>{children}</FlexContainer>
        </StyledLink>
    )
}

export const AttendeeProfile: React.FC<{data: Partial<Attendee>}> = ({data}) => {
    const [, setMeetingProfileId] = useQueryParam<string | null>('meetingProfileId')
    const {idStr, email, photoUrl, firstName, lastName, guest, profileIdStr} = data
    const {title, company, location, bio, linkedIn} = data

    const {secondaryLine, profileLines, profileLinesLoading} = useAttendeeProfileLinesFor(profileIdStr)

    const profileLoading = profileIdStr && (profileLinesLoading || !data)

    const fullName = `${firstName} ${lastName}`

    return (
        <>
            <Overlay />
            <Container>
                <Border />
                <AnimatePresence exitBeforeEnter initial={false}>
                    {profileLoading ? (
                        <Content data-testid="spinner" key="loading">
                            <Loading />
                        </Content>
                    ) : (
                        <ProfileContentInner>
                            <Content key={idStr}>
                                <TopContainer>
                                    <PhotoContainer>
                                        {guest && <GuestBadge />}
                                        <ProfilePicture url={photoUrl || ''} fullName={fullName} guest={guest} />
                                    </PhotoContainer>
                                    <Button
                                        data-testid="close_button"
                                        variant="tertiary"
                                        color={colors.black}
                                        style={{
                                            margin: -8,
                                        }}
                                        onClick={() => setMeetingProfileId(null)}
                                    >
                                        Close <Button.Icon icon={SvgClose} />
                                    </Button>
                                </TopContainer>
                                <Spacer height={24} />
                                <Typography.Heading>{fullName}</Typography.Heading>
                                <Spacer height={4} />
                                {secondaryLine ? (
                                    <SecondaryLine secondaryLine={secondaryLine} profileIdStr={profileIdStr} />
                                ) : title ? (
                                    <>
                                        <Typography.Subheading>{title}</Typography.Subheading>
                                    </>
                                ) : (
                                    <EmailWrapper email={email || ''}>
                                        <Typography.Subheading>{email}</Typography.Subheading>
                                        <Icon icon={SvgEmail} />
                                    </EmailWrapper>
                                )}

                                <Spacer height={16} />
                                {linkedIn && (
                                    <>
                                        <StyledLink href={linkedIn} target="_">
                                            <FlexContainer>
                                                <Typography.Paragraph bottomMargin={false}>
                                                    Profile info supplied by Linkedin
                                                </Typography.Paragraph>
                                                <Icon icon={SvgLinkedin} />
                                            </FlexContainer>
                                        </StyledLink>
                                        <Spacer height={16} />
                                    </>
                                )}

                                <Line />
                                <Spacer height={16} />

                                <GuestProfileDetails
                                    email={email}
                                    title={title}
                                    company={company}
                                    location={location}
                                    bio={bio}
                                />

                                {profileLines &&
                                    profileLines.map((line: ProfileLine) =>
                                        isDetail(line) ? (
                                            <Detail key={line.idStr} detail={line} />
                                        ) : (
                                            <CategoryDetail
                                                key={line.idStr}
                                                category={line}
                                                profileIdStr={profileIdStr}
                                            />
                                        ),
                                    )}
                            </Content>
                        </ProfileContentInner>
                    )}
                </AnimatePresence>
            </Container>
        </>
    )
}

const Content = styled(motion.div).attrs(() => ({
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0},
    transition: {duration: 0.25},
}))`
    flex: 1;
    padding: 32px;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
`
