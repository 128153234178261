import {FadePresence, Spacer, Typography} from 'nf-ui'
import React, {FC, useState} from 'react'
import {useAlert} from 'react-alert'
import {useHistory} from 'react-router-dom'
import {useMutationSignInOtp} from '~/apollo/queries/SignInOtp'
import {BackButton} from '~/components/BackButton'
import {OTPInput} from '~/components/OTPInput'
import {Token} from '~/components/TokenContext'
import {getGraphQLErrorMessage} from '~/util'

export type SignInMagicLinkProps = {
    email: string
    onClickBack: () => void
    onComplete?: () => void
}

export const LoginOTP: FC<SignInMagicLinkProps> = ({email, onClickBack, onComplete}) => {
    const [OTPInputDisabled, setOTPInputDisabled] = useState<boolean>(false)

    const useVerifySignIn = () => {
        const alert = useAlert()
        const {setToken} = Token.useContainer()
        const {push} = useHistory()

        const [signInOtpMutation] = useMutationSignInOtp({
            onCompleted: (result) => {
                setToken(result.signInOTP.accessToken)
                onComplete ? onComplete() : push('/')
            },
            onError: (error) => {
                alert.error(getGraphQLErrorMessage(error))
                setOTPInputDisabled(false)
            },
        })

        return signInOtpMutation
    }

    const [signInOTP] = useVerifySignIn()

    const handleSignIn = (otp: string) => {
        signInOTP({
            variables: {
                otp,
                email,
            },
        })
    }

    return (
        <FadePresence exitBeforeEnter>
            <>
                <Typography.Heading>
                    <BackButton onClick={onClickBack} />
                    Check your inbox
                </Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading maxWidth={590}>
                    We’ve emailed you a quick sign in code.
                    <br />
                    Enter it below to continue.
                </Typography.Subheading>
                <Spacer height={32} />
                <OTPInput
                    onSubmit={handleSignIn}
                    email={email}
                    disabled={OTPInputDisabled}
                    setInputDisabled={setOTPInputDisabled}
                />
            </>
        </FadePresence>
    )
}
