import {useApolloClient} from '@apollo/client'
import {CURRENT_USER, useCurrentUser} from '~/components/useCurrentUser'
import {CurrentUserData} from '~/components/__types__/CurrentUserData'

export const useCalendarConnected = () => {
    const {me} = useCurrentUser()
    const client = useApolloClient()

    const setConnected = (connected: boolean) => {
        const currentUser = client.readQuery<CurrentUserData>({query: CURRENT_USER})
        if (!currentUser || !currentUser.me) return

        client.writeQuery<CurrentUserData>({
            query: CURRENT_USER,
            data: {
                me: {
                    ...currentUser.me,
                    calendarConnected: connected,
                },
            },
        })
    }

    return {connected: !!me?.calendarConnected, setConnected}
}
