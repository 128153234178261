import {MutationTuple} from '@apollo/client'
import {useAlert} from 'react-alert'
import {mergeDataEditValues, useMutationUpdateProfile} from '~/apollo/queries/DataEditValue'
import {UpdateProfileValues, UpdateProfileValuesVariables} from '~/apollo/queries/__types__/UpdateProfileValues'
import {EMAIL_REGEX} from '~/components/Primitives/constants'
import {
    DataEditField,
    DataEditValue,
    useOrganisationContext,
    hydrateDataEditFieldTypes,
    convertDataEditValuesByProfileIdStr,
} from '~/components/OrganisationContext'

export type FieldState = {values: string[]; error: string; original: string[]; baseFieldIdStr: string}

export const validateField = (dataEditField: DataEditField, values: string[]) =>
    dataEditField.type === 'firstName' && !values[0]
        ? 'Please enter a first name'
        : dataEditField.type === 'lastName' && !values[0]
        ? 'Please enter a last name'
        : dataEditField.type === 'email' && values[0] && !EMAIL_REGEX.test(values[0])
        ? 'Please enter a valid email address'
        : dataEditField.name === 'Grade' && !values[0]
        ? 'Please enter a grade'
        : dataEditField.name === 'Class' && !values[0]
        ? 'Please enter a class'
        : dataEditField.name === 'Birthday' && !values[0]
        ? 'Please enter a birthday'
        : dataEditField.name === 'Parent 1 First Name' && !values[0]
        ? 'Please enter a first name'
        : dataEditField.name === 'Parent 1 Last Name' && !values[0]
        ? 'Please enter a last name'
        : dataEditField.name === 'Parent 1 Email Address' && !values[0]
        ? 'Please enter an email address'
        : ''

export const useUpdateProfile = (
    organisationIdStr: string,
    onDoneEditing: () => void,
): [
    MutationTuple<UpdateProfileValues, UpdateProfileValuesVariables>,
    (
        fields: {
            dataEditField: DataEditField & {
                masterField: DataEditField
                baseFields: null
            }
            dataEditValues: DataEditValue[]
        }[],
        fieldState: Record<string, FieldState>,
        setFieldState: (value: Record<string, FieldState>) => void,
    ) => Promise<void>,
] => {
    const [updateProfileMutation, updateProfile] = useMutationUpdateProfile()
    const [visibleValues, setVisibleValues] = useOrganisationContext.useDataEditValues()
    const [visibleValuesIndexed, setVisibleValuesIndexed] = useOrganisationContext.useDataEditValuesByProfileIdStr()
    const [hiddenValues, setHiddenValues] = useOrganisationContext.useHiddenValues()
    const [hiddenValuesIndexed, setHiddenValuesIndexed] = useOrganisationContext.useHiddenValuesByProfileIdStr()
    const [dataEditFields, setDataEditFields] = useOrganisationContext.useDataEditFields()

    const alert = useAlert()

    const _updateProfile = async (
        fields: {
            dataEditField: DataEditField & {
                masterField: DataEditField
                baseFields: null
            }
            dataEditValues: DataEditValue[]
        }[],
        fieldState: Record<string, FieldState>,
        setFieldState: (value: Record<string, FieldState>) => void,
    ) => {
        if (!Object.keys(fieldState).length) {
            onDoneEditing()
            return
        }
        const validated = Object.fromEntries(
            fields.map((_field) => {
                const values =
                    fieldState[_field.dataEditField.masterField.idStr]?.values ||
                    _field.dataEditValues.map((value) => value.value)
                const original =
                    fieldState[_field.dataEditField.masterField.idStr]?.original ||
                    _field.dataEditValues.map((value) => value.value)
                const error = validateField(_field.dataEditField, values)
                return [
                    _field.dataEditField.masterField.idStr,
                    {
                        values,
                        original,
                        error,
                        baseFieldIdStr: _field.dataEditField.idStr,
                    },
                ]
            }),
        )
        if (Object.keys(validated).some((key) => validated[key].error)) {
            setFieldState(validated)
            return
        }

        const values = Object.keys(fieldState).flatMap((key) => {
            const obj = fields
                .flatMap((_field) => _field.dataEditValues)
                .find((value) => value.fieldOrParentCategoryIdStr === key)!

            return fieldState[key].values.map((value) => ({
                value: value,
                profileIdStr: obj.profileIdStr,
                masterFieldIdStr: key,
                baseFieldIdStr: fieldState[key].baseFieldIdStr,
            }))
        })

        const {visible, hidden} = mergeDataEditValues(
            visibleValues,
            hiddenValues,
            values.map((value) => ({
                value: value.value,
                profileIdStr: value.profileIdStr,
                fieldOrParentCategoryIdStr: value.masterFieldIdStr,
                organisationIdStr,
                __typename: 'DataEditValue' as 'DataEditValue',
            })),
            true,
        )

        const originalData = {visibleValues, visibleValuesIndexed, hiddenValues, hiddenValuesIndexed, dataEditFields}
        const changingFields = dataEditFields
            .filter(
                (field) =>
                    field.type === 'category' &&
                    values.some(
                        (value) =>
                            value.masterFieldIdStr === field.idStr && !field.availableValues.includes(value.value),
                    ),
            )
            .map((field) => field.idStr)

        let draftDataEditFields = dataEditFields
        if (changingFields.length) {
            draftDataEditFields = draftDataEditFields.map((field) => {
                const newAvailableValues = changingFields.includes(field.idStr)
                    ? values
                          .filter(
                              (value) =>
                                  value.masterFieldIdStr === field.idStr &&
                                  !field.availableValues.includes(value.value),
                          )
                          .map((value) => value.value)
                    : []

                return changingFields.includes(field.idStr)
                    ? {
                          ...field,
                          ...hydrateDataEditFieldTypes({
                              ...field,
                              availableValues: [...field.availableValues, ...newAvailableValues],
                              availableValueLabels: field.availableValueLabels
                                  ? [...field.availableValueLabels, ...newAvailableValues]
                                  : null,
                              availableValueIdStrs: [...field.availableValueIdStrs, ...newAvailableValues],
                          }),
                      }
                    : field
            })
            setDataEditFields(draftDataEditFields)
        }

        setVisibleValues(visible)
        const newVisibleValuesIndexed = convertDataEditValuesByProfileIdStr(visible)
        setVisibleValuesIndexed(newVisibleValuesIndexed)
        setHiddenValues(hidden)
        const newHiddenValuesIndexed = convertDataEditValuesByProfileIdStr(hidden)
        setHiddenValuesIndexed(newHiddenValuesIndexed)

        const updatedFields = await updateProfile({
            dataEditValues: values.map((value) => ({
                value: value.value,
                profileIdStr: value.profileIdStr,
                fieldOrParentCategoryIdStr: value.baseFieldIdStr,
            })),
            removeMissingValues: true,
        }).catch((err) => {
            setVisibleValues(originalData.visibleValues)
            setVisibleValuesIndexed(originalData.visibleValuesIndexed)
            setHiddenValues(originalData.hiddenValues)
            setHiddenValuesIndexed(originalData.hiddenValuesIndexed)
            if (changingFields.length) {
                setDataEditFields(originalData.dataEditFields)
            }
            throw err
        })

        if (changingFields.length) {
            draftDataEditFields = draftDataEditFields.map((field) => {
                const updatedField = updatedFields.find((f) => f.idStr === field.idStr)
                if (!updatedField) return field
                const idStrMap = Object.fromEntries(
                    updatedField.availableValues.map((value, index) => [
                        value,
                        updatedField.availableValueIdStrs[index],
                    ]),
                )
                return {
                    ...field,
                    availableValueIdStrs: field.availableValues.map(
                        (value, index) => idStrMap[value] || field.availableValueIdStrs[index],
                    ),
                }
            })
            setDataEditFields(draftDataEditFields)
        }

        alert.success('Profile successfully updated')
        onDoneEditing()
    }

    return [updateProfileMutation, _updateProfile]
}
