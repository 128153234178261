import React, {useRef, FC, ReactNode} from 'react'
import {areEqual, GridChildComponentProps} from 'react-window'
import styled from 'styled-components'
import {Panel} from '../Panel'
import {colors} from '../theme'
import {BORDER_COLOR, BORDER_WIDTH, CELL_FONT_WEIGHT, CELL_PADDING, HEADER_FONT_WEIGHT, ROW_HEIGHT} from './constants'
import {CellData} from './Table'
import {Column} from './utils/useColumns'
import {Row} from './utils/useRows'
import {useTranslate3d} from './utils/useTranslate3d'
import {paragraphFontStyle} from '../Typography'
import {Icon} from '../Icon'
import Check from '../Icons/Check'
import {FadePresence} from '../FadePresence'

type ContainerProps = {
    clickable: boolean
    header: boolean
    disabledColumn: boolean
}

const Container = styled.div<ContainerProps>`
    position: relative;
    border: ${BORDER_WIDTH}px solid ${BORDER_COLOR};
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 ${CELL_PADDING}px;

    ${paragraphFontStyle}
    font-weight: ${({header}) => (header ? HEADER_FONT_WEIGHT : CELL_FONT_WEIGHT)};
    line-height: ${ROW_HEIGHT}px;
    background-color: ${({header, disabledColumn}) => (header || disabledColumn ? colors.lightGray : colors.white)};
    cursor: ${({clickable}) => (clickable ? 'pointer' : 'default')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const TextOverflow = styled.div`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const IconContainer = styled.div`
    padding: 12px 0px;
`

const DisabledOverlay = styled.div`
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
`

type CellProps = Omit<GridChildComponentProps, 'data'> & {data: CellData}

/**
 * The cell's border should be 0 when it is against the side of a table.
 * So, when the cell is in the first row, top border should be 0, and so on.
 *
 * This function calculates that.
 */
const getBorderWidths = ({
    rows,
    columns,
    columnIndex,
    rowIndex,
}: {
    rows: Row[]
    columns: Column[]
    columnIndex: number
    rowIndex: number
}) => {
    const hideTop = rowIndex === 0
    const hideRight = columns[columnIndex].lastVisible
    const hideBottom = rows[rowIndex].lastVisible
    const hideLeft = columns[columnIndex].firstVisible

    const width = (hidden: boolean) => `${hidden ? 0 : 1}px`
    return `${width(hideTop)} ${width(hideRight)} ${width(hideBottom)} ${width(hideLeft)}`
}

const CellContent = React.memo(
    React.forwardRef<HTMLDivElement, CellProps>(({columnIndex, rowIndex, style, data}, ref) => {
        const {setHoverCell, onClickCell, columns, rows, headerCellsClickable, disableRows, headerIcon} = data

        const column = columns[columnIndex]
        const row = rows[rowIndex]

        const top = Number(style.top || 0)
        const left = Number(style.left || 0)

        const position = useTranslate3d({top, left})

        const clickable = Boolean(onClickCell && !column.disabled && (rowIndex !== 0 || headerCellsClickable))
        const header = rowIndex === 0

        let icon: ReactNode = null
        if (header && headerIcon && headerIcon.column === column.column) {
            icon = <Icon icon={headerIcon.icon} />
        } else if (header && column.disabled) {
            icon = <Icon icon={Check} tint={colors.green[100]} />
        }

        return (
            <Container
                ref={ref}
                header={header}
                disabledColumn={column.disabled}
                style={{
                    ...style,
                    ...position,
                    width: Number(column.size) + BORDER_WIDTH,
                    height: Number(row.size) + BORDER_WIDTH,
                    opacity: column.style.fadedOut || row.style.fadedOut ? 0 : 1,
                    borderWidth: getBorderWidths({rows, columns, columnIndex, rowIndex}),
                }}
                clickable={clickable}
                onMouseEnter={() => {
                    setHoverCell(column.disabled ? undefined : {columnIndex, rowIndex})
                }}
                onClick={() => {
                    clickable && onClickCell && onClickCell({columnIndex, rowIndex, column: column.column})
                }}
            >
                <TextOverflow>{row.data[column.column]}</TextOverflow>

                {disableRows && <FadePresence initial={false}>{rowIndex !== 0 && <DisabledOverlay />}</FadePresence>}
                {icon && <IconContainer>{icon}</IconContainer>}
            </Container>
        )
    }),
    areEqual,
)

// TECHNICAL: Improve the memoisation function to limit re-renders, if we start
// seeing performance issues.
export const Cell: FC<CellProps> = React.memo((props) => {
    const column = props.data.columns[props.columnIndex]
    const row = props.data.rows[props.rowIndex]
    const cellRef = useRef<HTMLDivElement>(null)
    if (!column.style.mounted || !row.style.mounted) return null

    const {data, columnIndex, rowIndex} = props
    const {panel, onClosePanel, activeCell, panelBoundaries, headingPanel} = data

    const panelOpen = !!activeCell && columnIndex === activeCell.columnIndex && rowIndex === activeCell.rowIndex

    return (
        <>
            <CellContent {...props} ref={cellRef} />
            {panel && onClosePanel && (
                <Panel open={panelOpen} targetRef={cellRef} onClose={onClosePanel} boundariesElement={panelBoundaries}>
                    {panel}
                </Panel>
            )}
            {columnIndex === 0 && rowIndex === 0 && headingPanel && (
                <Panel
                    open={true}
                    targetRef={cellRef}
                    onClose={() => {}}
                    boundariesElement={panelBoundaries}
                    overrideColor={colors.primary[100]}
                    positionFixed
                >
                    {headingPanel}
                </Panel>
            )}
        </>
    )
}, areEqual)
