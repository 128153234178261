import {gql, useQuery} from '@apollo/client'
import {
    Button,
    Columns,
    FadePresence,
    Form,
    HeaderLayout,
    MultiColumnList,
    Panel,
    Spacer,
    useAnalyticsEventTracker,
    useMultiselect,
    useSort,
} from 'nf-ui'
import SvgCaret from 'nf-ui/Icons/Caret'
import SvgCog from 'nf-ui/Icons/Cog'
import React, {FC, useMemo, useRef, useState} from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {ModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {PageLoading} from '~/components/PageLoading'
import {useSetSelectedItems} from '~/components/SelectionList/SelectionState'
import {useCurrentUser} from '~/components/useCurrentUser'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {InviteStatus, UserRole} from '~/globalTypes'
import {getGraphQLErrorMessage} from '~/util'
import {AddGuestsModal, ADD_GUESTS_MODAL_DATA} from './Users/AddGuestsModal'
import {AutoInviteLightbox} from './Users/AutoInviteLightbox'
import {InviteUsersModal, INVITE_USERS_MODAL_DATA} from './Users/InviteUsersModal'
import {MultiselectBar} from './Users/MultiselectBar'
import {SettingsPanel} from './Users/SettingsPanel'
import {USER_INVITE_DATA} from './Users/useInviteUsersMutation'
import {UserActions} from './Users/UserActions'
import {USER_REVOKE_DATA} from './Users/useRevokeMutation'
import {USER_ROLE_DATA} from './Users/useRoleMutation'
import {UsersPageData, UsersPageDataVariables, UsersPageData_organisation_users} from './__types__/UsersPageData'

const UsersLayout = styled(HeaderLayout)`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100vh;
    padding-bottom: 0;
`

//TODO : This needs to be moved out to it's own hook it's refrenced a lot
export const USERS_DATA = gql`
    query UsersPageData($idStr: String!) {
        organisation(idStr: $idStr) {
            autoInvite
            users {
                idStr
                firstName
                lastName
                email
                role
                inviteStatus
                profiles {
                    idStr
                }
                ...UserRevokeData
            }
            ...AddGuestsModalData
            ...InviteUsersModalData
            ...UserRolesData
            ...UserInviteData
        }
    }
    ${INVITE_USERS_MODAL_DATA}
    ${ADD_GUESTS_MODAL_DATA}
    ${USER_ROLE_DATA}
    ${USER_REVOKE_DATA}
    ${USER_INVITE_DATA}
`
/**
 * Gets the refetch query for users data
 *
 * @export
 * @param {string} organisationIdStr
 * @returns
 */
export function getUserDataQuery(organisationIdStr: string) {
    return {
        query: USERS_DATA,
        variables: {
            idStr: organisationIdStr,
        },
    }
}

const useUserManagementModal = () => {
    const [modal, setModal] = useQueryParam<'add-guests' | 'invite-users' | null>('modal')
    return {modal, setModal}
}

const InviteUserLink: FC<{user: UsersPageData_organisation_users}> = ({user}) => {
    const {setModal} = useUserManagementModal()
    const {setSelectedIdStrs} = useSetSelectedItems()
    const userIdStrs = [user.idStr]

    if (user.isRevoked) return <>{inviteStatusMap[user.inviteStatus || InviteStatus.PENDING]}</>
    if (user.inviteStatus && user.inviteStatus !== InviteStatus.PENDING)
        return <>{inviteStatusMap[user.inviteStatus]}</>

    return (
        <Button
            variant="link"
            color="black"
            onClick={(event) => {
                event.stopPropagation()
                setSelectedIdStrs(userIdStrs)
                setModal('invite-users')
            }}
        >
            Invite user
        </Button>
    )
}

const inviteStatusMap = {
    [InviteStatus.PENDING]: '-',
    [InviteStatus.INVITED]: 'Email invite sent',
    [InviteStatus.ACTIVE]: 'Active',
}

const columns: Columns<UsersPageData_organisation_users> = [
    {
        name: 'Email address',
        width: 220,
        value: (user) => user.email || '',
        highlight: true,
    },
    {
        name: 'First name',
        width: 140,
        value: (user) => user.firstName,
        highlight: true,
    },
    {
        name: 'Last name',
        width: 140,
        value: (user) => user.lastName,
        highlight: true,
    },
    {
        name: 'Role',
        width: 120,
        value: (user) =>
            user.role === UserRole.AdminGuest
                ? 'Admin, Guest'
                : user.role === UserRole.AdminUser
                ? 'Admin, User'
                : user.role,
    },
    {
        name: 'Directory access',
        width: 180,
        value: (user) => (user.isRevoked ? 'No' : 'Yes'),
    },
    {
        name: 'Status',
        width: 120,
        value: (user) => inviteStatusMap[user.inviteStatus || InviteStatus.PENDING],
        hoverLabel: (user) => <InviteUserLink user={user} />,
    },
]

function searchUsers(users: UsersPageData_organisation_users[], searchTerm: string) {
    return users.filter(
        (user) =>
            user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email?.toLowerCase().includes(searchTerm.toLowerCase()),
    )
}

export const Users: FC = () => {
    const organisationIdStr = useOrganisationIdStr()
    const {me} = useCurrentUser()

    const {modal, setModal} = useUserManagementModal()
    const [search, setSearch] = useState('')

    const {data, loading, error} = useQuery<UsersPageData, UsersPageDataVariables>(USERS_DATA, {
        variables: {
            idStr: organisationIdStr,
        },
    })

    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const settingsRef = useRef<HTMLDivElement>(null)
    const [settingsPanelOpen, setSettingsPanelOpen] = useState(false)

    const allUsers = useMemo(() => data?.organisation.users || [], [data?.organisation.users])

    const searchedUsers = useMemo(() => searchUsers(allUsers, search), [allUsers, search])
    const users = search ? searchedUsers : allUsers

    const disabledIds = me?.idStr && allUsers.map((user) => user.idStr).includes(me?.idStr) ? [me?.idStr] : []

    const multiselect = useMultiselect({
        users: allUsers,
        getItemId: (item) => item.idStr,
        disabledIds,
    })
    const selectionMode = multiselect.selected.length > 0
    const selectedUsers = allUsers.filter((user) => multiselect.selected.includes(user.idStr))

    const sort = useSort({
        rows: users,
        columns,
        sortableColumns: ['First name', 'Last name', 'Email address', 'Role', 'Directory access', 'Status'],
        defaultSort: {column: 'Directory access', ascending: false},
    })

    const [autoInviteLightboxMode, setAutoInviteLightboxMode] = useState<'enable' | 'disable' | false>(false)
    const openAutoInviteLightbox = () => {
        setAutoInviteLightboxMode(data?.organisation.autoInvite ? 'disable' : 'enable')
    }

    if (error) return <div>Error: {getGraphQLErrorMessage(error)}</div>
    if (!data || !me || loading) return <PageLoading />

    return (
        <UsersLayout
            heading="Manage users"
            subheading="Manage and invite users to your directory"
            rightContent={
                <>
                    <ModalNavigator modal={modal || null} setModal={setModal}>
                        <AddGuestsModal key="add-guests" data={data.organisation} />
                        <InviteUsersModal key="invite-users" data={data.organisation} />
                    </ModalNavigator>
                    <Form.Group name="search" style={{flex: 1, maxWidth: 304, minWidth: 0}}>
                        <Form.SearchInput
                            width="100%"
                            onChange={setSearch}
                            onFocus={() => trackAnalyticsEvent('search_users')}
                            value={search}
                        />
                    </Form.Group>
                    <Spacer width={16} />
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setModal('add-guests')
                        }}
                        onClickAnalyticsEvent="select_add_guests"
                    >
                        Add guests
                    </Button>
                    <Spacer width={16} />
                    <Button
                        variant="primary"
                        onClick={() => {
                            setModal('invite-users')
                        }}
                        onClickAnalyticsEvent="select_invite_users"
                        disabled={selectionMode}
                    >
                        Invite
                    </Button>
                    <div ref={settingsRef}>
                        <Button variant="tertiary" onClick={() => setSettingsPanelOpen(true)}>
                            <div style={{marginRight: '-4px'}}>
                                <Button.Icon icon={SvgCog} size={24} />
                            </div>
                            <Button.Icon icon={SvgCaret} />
                        </Button>
                    </div>
                </>
            }
        >
            <AutoInviteLightbox
                open={!!autoInviteLightboxMode}
                onClose={() => setAutoInviteLightboxMode(false)}
                autoInvite={data.organisation.autoInvite}
            />
            <Spacer height={16} />
            <div style={{flex: 1}}>
                <AutoSizer>
                    {({width, height}) => (
                        <MultiColumnList
                            width={width}
                            maxHeight={height}
                            minHeight={600}
                            rows={users}
                            columns={columns}
                            multiselect={multiselect}
                            sort={sort}
                            disabledSelector={(user) => !!user.isRevoked}
                            highlightValue={search}
                            actions={{
                                button: {
                                    icon: SvgCaret,
                                    iconPosition: 'right',
                                    label: 'Actions',
                                },
                                visible: (user) => user.idStr !== me.idStr,
                                panel: (user, onClose) => {
                                    return (
                                        <UserActions
                                            isRevoked={user.isRevoked ?? false}
                                            userId={user.idStr}
                                            revokeOnly={!!user.isRevoked}
                                            isAdmin={[UserRole.AdminGuest, UserRole.AdminUser].includes(user.role)}
                                            isGuest={[UserRole.AdminGuest, UserRole.Guest].includes(user.role)}
                                            hasProfiles={!!user.profiles.length}
                                            isPending={user.inviteStatus === InviteStatus.PENDING}
                                            onClose={onClose}
                                        />
                                    )
                                },
                            }}
                        />
                    )}
                </AutoSizer>
            </div>
            <Panel
                open={settingsPanelOpen}
                onClose={() => setSettingsPanelOpen(false)}
                targetRef={settingsRef}
                placement="bottom-end"
                boundariesElement="scrollParent"
            >
                <SettingsPanel
                    onClose={() => setSettingsPanelOpen(false)}
                    autoInvite={data.organisation.autoInvite}
                    autoInviteOnClick={openAutoInviteLightbox}
                />
            </Panel>

            <FadePresence>
                {selectionMode && (
                    <>
                        <Spacer height={72} />
                        <MultiselectBar selectedUsers={selectedUsers} onClose={() => multiselect.setSelected([])} />
                    </>
                )}
            </FadePresence>
        </UsersLayout>
    )
}
