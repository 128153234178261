import Bugsnag, {Client} from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React, {FC} from 'react'

let bugsnagClient: Client | undefined

const NoOp: FC = ({children}) => <>{children}</>

const validEnv = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'

if (validEnv && process.env.BUGSNAG_API_KEY) {
    bugsnagClient = Bugsnag.start({
        apiKey: process.env.BUGSNAG_API_KEY,
        releaseStage: process.env.BUGSNAG_RELEASE_STAGE,
        enabledReleaseStages: ['production', 'staging'],
        plugins: [new BugsnagPluginReact()],
    })
}
const BugSnagErrorBoundary = bugsnagClient?.getPlugin('react')?.createErrorBoundary(React) || NoOp

export {BugSnagErrorBoundary, bugsnagClient}
