const loadHubSpot = () => {
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    script.src = '//js.hs-scripts.com/4647943.js'
    script.id = 'hs-script-loader'

    var first = document.getElementsByTagName('script')[0]
    first.parentNode.insertBefore(script, first)
}

// Commment out to deactivate hubspot
loadHubSpot()
