import {gql, useMutation} from '@apollo/client'
import {Formik, FormikHelpers} from 'formik'
import {Button, Form, formSpacing, Spacer} from 'nf-ui'
import React, {Fragment} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import * as yup from 'yup'
import {
    AdditionalDataField,
    AdditionalDataFormValues,
    getFieldsForSubmit,
    setupAdditionalDataForm,
} from '~/components/AdditionalDataFormFields'
import {ModalLayout} from '~/components/ModalLayout'
import {getGraphQLErrorMessage} from '~/util'
import {FULL_PROFILE_DATA} from '../FullProfile/FullProfile'
import {HideableFieldSwitch} from './useHiddenFields'
import {EditProfileData_profile} from './__types__/EditProfileData'
import {UpdateProfile, UpdateProfileVariables} from './__types__/UpdateProfile'

export type AdditionalFields = EditProfileData_profile['additionalFields']

const Container = styled.div`
    ${formSpacing}
`

export const UPDATE_PROFILE = gql`
    mutation UpdateProfile($profileIdStr: String!, $additionalFields: [DataCollectionFormField!]!) {
        editAdditionalFields(profileIdStr: $profileIdStr, fields: $additionalFields)
    }
`

const useSubmitForm = () => {
    const alert = useAlert()

    const [editProfileId] = useQueryParam<string>('editProfileId')
    if (!editProfileId) throw new Error('Missing editProfileId')

    const [updateProfile] = useMutation<UpdateProfile, UpdateProfileVariables>(UPDATE_PROFILE, {
        refetchQueries: [{query: FULL_PROFILE_DATA, variables: {idStr: editProfileId}}],
        awaitRefetchQueries: true,
        onError: (error) => {
            alert.error(getGraphQLErrorMessage(error))
            throw error
        },
    })

    return async function saveProfile(
        {
            additionalFields: additionalFieldValues,
        }: {
            additionalFields: AdditionalDataFormValues
        },
        actions: FormikHelpers<{
            additionalFields: AdditionalDataFormValues
        }>,
    ) {
        const additionalFields = getFieldsForSubmit(additionalFieldValues)

        await updateProfile({
            variables: {
                profileIdStr: editProfileId,
                additionalFields,
            },
        })

        alert.success('Profile successfully updated')
        actions.resetForm({values: {additionalFields: additionalFieldValues}})
    }
}

export const AdditionalFieldsTab = ({additionalFields}: {additionalFields: AdditionalFields}) => {
    const [editProfileId] = useQueryParam<string>('editProfileId')
    if (!editProfileId) throw new Error('Missing editProfileId')

    const submitForm = useSubmitForm()
    const additionalDataForm = setupAdditionalDataForm(additionalFields)

    return (
        <Formik
            initialValues={{additionalFields: additionalDataForm.formValues}}
            validationSchema={yup.object({additionalFields: yup.object(additionalDataForm.formSchema)})}
            onSubmit={submitForm}
        >
            {({handleSubmit, isSubmitting, dirty}) => (
                <form onSubmit={handleSubmit}>
                    <Container>
                        {additionalFields.map((field) => {
                            return (
                                <Fragment key={field.idStr}>
                                    <HideableFieldSwitch field={field} />
                                    <Form.Group name={`additionalFields.${field.idStr}`} key={field.idStr}>
                                        <AdditionalDataField field={field} />
                                    </Form.Group>
                                    <Spacer height={24} />
                                </Fragment>
                            )
                        })}
                    </Container>
                    <ModalLayout.Footer>
                        <Button disabled={!dirty} loading={isSubmitting} variant="primary" type="submit">
                            Save
                        </Button>
                    </ModalLayout.Footer>
                </form>
            )}
        </Formik>
    )
}
