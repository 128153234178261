import * as React from 'react'
const SvgCheck = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgCheck(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M10.592 4.848L6.808 9.001l-1.39-1.63c-.326-.384-.863-.463-1.23-.192l-.089.076c-.416.303-.486.891-.225 1.35l2.116 2.512c.246.229.476.346.71.352.322.006.56-.09.804-.307l4.512-4.992c.352-.396.352-1.01-.004-1.378-.476-.369-1.065-.344-1.42.056z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgCheck
