import {gql, useMutation} from '@apollo/client'
import produce from 'immer'
import {useQueryParam} from 'use-query-params'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {FullOrgChartData, FullOrgChartData_organisation_profiles} from '../../__types__/FullOrgChartData'
import {AddOpenPosition, AddOpenPositionVariables} from './__types__/AddOpenPosition'
import {FULL_ORG_CHART_DATA} from '../../useFullOrgChartData'

const ADD_OPEN_POSITION = gql`
    mutation AddOpenPosition(
        $managerIdStr: String!
        $organisationIdStr: String!
        $title: OpenPositionField!
        $openPositionJobLink: String
    ) {
        addOpenPosition(
            managerIdStr: $managerIdStr
            organisationIdStr: $organisationIdStr
            title: $title
            openPositionJobLink: $openPositionJobLink
        ) {
            idStr
            fullName
            secondaryLine
            photos {
                idStr
                profileUrl
                signedProfileUrl
                tinyUrl
                signedTinyUrl
                priority
            }
            reportsTo
            directReports
            additionalManagers
            additionalReports
            openPosition
        }
    }
`

export const useAddOpenPosition = () => {
    const organisationIdStr = useOrganisationIdStr()
    const [, setProfileId] = useQueryParam<string>('profileId')
    const [addOpenPosition, {loading}] = useMutation<AddOpenPosition, AddOpenPositionVariables>(ADD_OPEN_POSITION, {
        onCompleted: (data) => {
            setProfileId(data.addOpenPosition.idStr)
        },
        update: (cache, {data}) => {
            const orgCharQuery = cache.readQuery<FullOrgChartData>({
                query: FULL_ORG_CHART_DATA,
                variables: {idStr: organisationIdStr},
            })
            if (!orgCharQuery || !data) return

            cache.writeQuery<FullOrgChartData>({
                query: FULL_ORG_CHART_DATA,
                data: produce(orgCharQuery, (draftState) => {
                    const openPosition = data.addOpenPosition
                    const manager = draftState.organisation.profiles.find(
                        (profile) => profile.idStr === openPosition.reportsTo,
                    )
                    manager?.directReports.push(openPosition.idStr)
                    draftState.organisation.profiles.push(openPosition as FullOrgChartData_organisation_profiles)
                }),
            })
        },
    })

    return {addOpenPosition, loading}
}
