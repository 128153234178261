import React, {FC} from 'react'
import styled from 'styled-components'
import {FadePresence} from '../FadePresence'
import {BORDER_WIDTH} from './constants'
import {BorderHighlight} from './utils/BorderHighlight'
import {CellHoverState} from './utils/useCellHover'
import {TableScrollState} from './utils/useTableScroll'

const Container = styled(BorderHighlight)`
    bottom: 0;
`

export type ColumnHighlightProps = {
    activeCell: CellHoverState['cell']
    tableScroll: TableScrollState
    tableContentHeight: number
    tableHeight: number
}

export const ColumnHighlight: FC<ColumnHighlightProps> = ({
    activeCell,
    tableScroll,
    tableContentHeight,
    tableHeight,
}) => {
    const scrollTop = tableScroll.position.top.get()
    const noBorderTop = scrollTop > 0
    const noBorderBottom = tableContentHeight >= tableHeight + scrollTop + BORDER_WIDTH * 2
    return (
        <FadePresence>
            {activeCell && (
                <Container
                    animate={{translateX: activeCell.offset, width: activeCell.size + BORDER_WIDTH * 2}}
                    style={{
                        left: tableScroll.inverse.left,
                        borderTop: noBorderTop ? 'none' : undefined,
                        borderTopLeftRadius: noBorderTop ? 0 : undefined,
                        borderTopRightRadius: noBorderTop ? 0 : undefined,

                        borderBottom: noBorderBottom ? 'none' : undefined,
                        borderBottomLeftRadius: noBorderBottom ? 0 : undefined,
                        borderBottomRightRadius: noBorderBottom ? 0 : undefined,
                    }}
                />
            )}
        </FadePresence>
    )
}
