import * as React from 'react'
const SvgLink = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgLink(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M3.449 6.942a.461.461 0 01.56-.007l.068.06.079.074c.187.202.192.44.024.626l-.07.067-1.166 1.24-.132.137a2.817 2.817 0 00.01 3.783l.132.137.133.135c1.016.947 2.659.936 3.76-.007l.14-.128 1.239-1.211.07-.064a.461.461 0 01.56-.007l.068.06.079.074c.187.202.192.44.024.626l-.07.067-1.167 1.24-.17.154c-1.63 1.396-4.002 1.332-5.475-.16a4.017 4.017 0 01-.291-5.296l.138-.165.149-.16 1.238-1.211.07-.064zm6.625-1.82c.252-.15.535-.128.706.057a.604.604 0 01.06.72l-.06.077-4.839 4.834-.071.064c-.208.154-.502.123-.76-.086-.152-.169-.163-.467-.032-.68l.064-.086 4.84-4.835.092-.064zM8.388 2.01a3.89 3.89 0 015.234.002l.156.15.164.164a3.828 3.828 0 010 5.296l-.157.157-1.148 1.224-.09.062c-.248.147-.53.123-.701-.061a.604.604 0 01-.06-.72l.06-.077 1.227-1.2.13-.151c.918-1.139.868-2.838-.12-3.906a2.976 2.976 0 00-4.006-.14l-.142.129L7.79 4.159l-.09.062c-.248.147-.53.123-.701-.061a.604.604 0 01-.06-.72L7 3.362l1.227-1.198.162-.155z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgLink
