import {gql, useQuery} from '@apollo/client'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {FieldType} from '~/objectTypes'
import {OrganisationPrimaryFields, OrganisationPrimaryFieldsVariables} from './__types__/OrganisationPrimaryFields'

export const ORGANISATION_FIELDS = gql`
    query OrganisationPrimaryFields($idStr: String!) {
        organisation(idStr: $idStr) {
            idStr
            primaryFields {
                idStr
                label
                type
                childCategories {
                    idStr
                    label
                }
            }
        }
    }
`

export const useOrganisationTitleField = () => {
    const organisationIdStr = useOrganisationIdStr()

    const {data, loading} = useQuery<OrganisationPrimaryFields, OrganisationPrimaryFieldsVariables>(
        ORGANISATION_FIELDS,
        {
            variables: {
                idStr: organisationIdStr,
            },
            fetchPolicy: 'cache-first',
        },
    )
    const titleField = data?.organisation.primaryFields.find(
        (field) => field.type === FieldType.CATEGORY && field.label === 'Title',
    )

    return {titleField, loading}
}
