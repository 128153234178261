import {gql, QueryResult, useMutation, useQuery} from '@apollo/client'
import {omit, sortBy, uniqBy} from 'lodash'
import {useEffect, useState} from 'react'
import {useAlert} from 'react-alert'
import {useQueryParam} from 'use-query-params'
import {ImportOptionsInput} from '~/objectTypes'
import {getGraphQLErrorMessage} from '~/util'
import {
    FieldsData,
    FieldsDataVariables,
    FieldsData_dataSource_importConfig_fieldRules,
    FieldsData_dataSource_importConfig_lastImport_fieldRules,
} from './__types__/FieldsData'
import {IndexData, IndexDataVariables} from './__types__/IndexData'
import {UpdateImportConfig, UpdateImportConfigVariables} from './__types__/UpdateImportConfig'

export const FIELDS_DATA = gql`
    query FieldsData($dataSourceIdStr: String!) {
        dataSource(dataSourceIdStr: $dataSourceIdStr) {
            importConfig {
                fieldRules {
                    fieldName
                    fieldType
                    isKeyField
                    expression
                }
                filterRules
                lastImport {
                    fieldRules {
                        fieldName
                        fieldType
                        isKeyField
                        expression
                    }
                    filterRules
                    startedDate
                    datasetCompletedDate
                    directoryCompletedDate
                }
                updatedAt
            }
        }
    }
`

export const INDEX_DATA = gql`
    query IndexData($dataSourceIdStr: String!) {
        dataSource(dataSourceIdStr: $dataSourceIdStr) {
            dataIndex
        }
    }
`

export const UPDATE_IMPORT_CONFIG = gql`
    mutation UpdateImportConfig($importConfig: UpdateImportConfigInput!, $dataSourceIdStr: String!) {
        updateImportConfig(importConfig: $importConfig, dataSourceIdStr: $dataSourceIdStr)
    }
`

export const fieldTypes: Record<string, string> = {
    category: 'Category',
    text: 'Text',
    date: 'Date',
    mobileNumber: 'Mobile Number',
    landlineNumber: 'Landline Number',
    email: 'Email',
    firstName: 'First Name',
    lastName: 'Last Name',
}

interface SearchResult {
    expression: string
    path: {normal: string; highlighted: string}[]
    partialPreview: string
    fullPreview: string
}

export type ManageFieldsState = {
    modal?: string
    fieldRules?: FieldsData_dataSource_importConfig_fieldRules[]
    indexData?: any
    loading: {
        fieldRules: boolean
        indexData: boolean
        dataSourceSyncing: boolean
    }
    defineField?: {
        showingSearch: boolean
        searchString?: string
        searchResults?: SearchResult[]
        editingFieldRule?: string
        deletingFieldRule?: string
        draftFieldRule?: Partial<FieldsData_dataSource_importConfig_fieldRules>
    }
    lastImport?: {
        fieldRules?: FieldsData_dataSource_importConfig_lastImport_fieldRules[]
        startedDate?: Date
        datasetCompletedDate?: Date
        directoryCompletedDate?: Date
        options?: ImportOptionsInput
    }
    lastConfigUpdate?: Date
    footerAction?: {
        message: string
        button: string
        handler: () => Promise<void>
        busy: boolean
    }
    onStartImport: (importOptions: ImportOptionsInput) => Promise<void>
}

const hasFieldRuleDifference = (
    left: FieldsData_dataSource_importConfig_fieldRules[],
    right: FieldsData_dataSource_importConfig_lastImport_fieldRules[],
) => {
    return (
        left.length !== right.length ||
        !left.every((l) =>
            right.some(
                (r) => l.fieldName === r.fieldName && l.fieldType === r.fieldType && l.expression === r.expression,
            ),
        )
    )
}

const datePlusMilliseconds = (millis: number, date?: Date) =>
    new Date((((date || new Date()) as unknown) as number) - (-millis || 0))

const calculateFooterAction = (state: ManageFieldsState): ManageFieldsState => {
    const datasetIsStale =
        !state.loading?.fieldRules &&
        (!state.lastImport?.datasetCompletedDate ||
            state.lastImport.datasetCompletedDate < datePlusMilliseconds(-86400000))

    const lastCompletedDate = [state.lastImport?.datasetCompletedDate, state.lastImport?.directoryCompletedDate]
        .filter((date) => date)
        .map((date) => date as Date)
        .reduce<Date | undefined>((previous, date) => (!previous || date > previous ? date : previous), undefined)

    const busy = !!(
        state.lastImport?.startedDate &&
        state.lastImport?.startedDate > datePlusMilliseconds(-900000) &&
        (!lastCompletedDate || lastCompletedDate < state.lastImport?.startedDate)
    )

    if (
        state.modal !== 'defineField' &&
        (!state.lastImport?.directoryCompletedDate ||
            (state.lastConfigUpdate && state.lastImport?.directoryCompletedDate < state.lastConfigUpdate))
    ) {
        if (hasFieldRuleDifference(state.fieldRules || [], state.lastImport?.fieldRules || []))
            return {
                ...state,
                footerAction: {
                    message: busy
                        ? `Updating your directory${datasetIsStale ? ' and dataset' : ''}.`
                        : `Fields have been changed since the last directory update${
                              datasetIsStale ? ' and the dataset is older than a day' : ''
                          }. Would you like to update now?`,
                    button: `Update`,
                    handler: () =>
                        state.onStartImport({
                            processDataset: datasetIsStale,
                            processDirectory: true,
                            processUsers: false,
                            processPhotos: false,
                        }),
                    busy,
                },
            }
    }

    if ((state.modal === 'defineField' || state.modal === 'manageFields') && datasetIsStale) {
        return {
            ...state,
            footerAction: {
                message: busy
                    ? 'Updating your dataset.'
                    : 'The dataset is older than a day and expression searches may be stale. Would you like to update now?',
                button: 'Update',
                handler: () =>
                    state.onStartImport({
                        processDataset: true,
                        processDirectory: false,
                        processUsers: false,
                        processPhotos: false,
                    }),
                busy,
            },
        }
    }

    return {...state, footerAction: undefined}
}

const calculateSearchResult = (state: ManageFieldsState): ManageFieldsState => {
    if (state.defineField?.searchString === undefined || state.defineField.searchString.length < 3)
        return {...state, defineField: {showingSearch: true, ...state.defineField, searchResults: []}}

    const search = state.defineField.searchString
    const searchResults = sortBy(
        uniqBy<{path: string; preview: string}>(
            Object.keys(state.indexData || {})
                .flatMap((key) => {
                    const value: {path: string[]; values: string[]}[] = state.indexData[key]
                    return value.map(({path, values}) => ({
                        path: path.join('.'),
                        preview: values.join('; '),
                    }))
                })
                .filter(({path}) =>
                    search
                        .toLowerCase()
                        .split(' ')
                        .every((searchPart) => path.toLowerCase().includes(searchPart)),
                ),
            ({path}) => path,
        ),
        ({path}) => path,
    ).map(({path, preview}) => {
        const indexes = sortBy(
            search
                .toLowerCase()
                .split(' ')
                .map((searchPart) => ({index: path.toLowerCase().indexOf(searchPart), searchPart})),
            (index) => index.index,
        )
        return {
            expression: path,
            path: [
                ...indexes.map(({index, searchPart}, i) => ({
                    normal: path
                        .slice(i > 0 ? indexes[i - 1].index + indexes[i - 1].searchPart.length : 0, index)
                        .toLowerCase(),
                    highlighted: path.slice(index, index + searchPart.length).toLowerCase(),
                })),
                {
                    normal: path
                        .slice(indexes[indexes.length - 1].index + indexes[indexes.length - 1].searchPart.length)
                        .toLowerCase(),
                    highlighted: '',
                },
            ],
            partialPreview: preview.length <= 100 ? preview : `${preview.slice(0, 100)} ...`,
            fullPreview: preview,
        }
    })

    return {...state, defineField: {...state.defineField, searchResults}}
}

const refetchFieldsData = async (
    setState: (state: React.SetStateAction<ManageFieldsState>) => void,
    fieldsData: QueryResult<FieldsData, FieldsDataVariables>,
) => {
    if (fieldsData.loading) {
        setTimeout(() => refetchFieldsData(setState, fieldsData), 1000)
        return
    }

    setState((s) => ({
        ...s,
        loading: {
            ...s.loading,
            fieldsData: true,
        },
    }))
    await fieldsData.refetch()
    setState((s) => ({
        ...s,
        loading: {
            ...s.loading,
            fieldsData: false,
        },
    }))
}

const refetchIndexData = async (
    setState: (state: React.SetStateAction<ManageFieldsState>) => void,
    indexData: QueryResult<IndexData, IndexDataVariables>,
) => {
    if (indexData.loading) {
        setTimeout(() => refetchIndexData(setState, indexData), 1000)
        return
    }

    setState((s) => ({
        ...s,
        loading: {
            ...s.loading,
            indexData: true,
        },
    }))
    await indexData.refetch()
    setState((s) => ({
        ...s,
        loading: {
            ...s.loading,
            indexData: false,
        },
    }))
}

export const useManageFields = (
    dataSourceIdStr: string | undefined,
    dataSourceSyncing: boolean,
    onStartImport: (importOptions: ImportOptionsInput) => Promise<void>,
) => {
    const alert = useAlert()
    const [state, setState] = useState<ManageFieldsState>({
        loading: {
            fieldRules: true,
            indexData: true,
            dataSourceSyncing: false,
        },
        onStartImport: async (importOptions: ImportOptionsInput) => {
            setState((s) =>
                calculateFooterAction({
                    ...s,
                    lastImport: {...s.lastImport, startedDate: new Date(), options: importOptions},
                }),
            )
            await onStartImport(importOptions)
        },
    })

    const [modal, setModal] = useQueryParam<string>('modal')
    useEffect(() => setState((s) => ({...s, modal})), [modal])

    const fieldsData = useQuery<FieldsData, FieldsDataVariables>(FIELDS_DATA, {
        variables: {
            dataSourceIdStr: dataSourceIdStr || '00000000-0000-0000-0000-000000000000',
        },
    })
    useEffect(
        () =>
            setState((s) =>
                calculateFooterAction({
                    ...s,
                    fieldRules: fieldsData?.data?.dataSource?.importConfig?.fieldRules,
                    loading: {
                        ...s.loading,
                        fieldRules: fieldsData.loading,
                    },
                    lastImport: fieldsData?.data?.dataSource?.importConfig?.lastImport
                        ? {
                              fieldRules: fieldsData?.data?.dataSource?.importConfig?.lastImport?.fieldRules || [],
                              startedDate: fieldsData?.data?.dataSource?.importConfig?.lastImport?.startedDate
                                  ? new Date(fieldsData?.data?.dataSource?.importConfig?.lastImport?.startedDate)
                                  : undefined,
                              datasetCompletedDate: fieldsData?.data?.dataSource?.importConfig?.lastImport
                                  ?.datasetCompletedDate
                                  ? new Date(
                                        fieldsData?.data?.dataSource?.importConfig?.lastImport?.datasetCompletedDate,
                                    )
                                  : undefined,
                              directoryCompletedDate: fieldsData?.data?.dataSource?.importConfig?.lastImport
                                  ?.directoryCompletedDate
                                  ? new Date(
                                        fieldsData?.data?.dataSource?.importConfig?.lastImport?.directoryCompletedDate,
                                    )
                                  : undefined,
                              options: s.lastImport?.options,
                          }
                        : undefined,
                    lastConfigUpdate:
                        fieldsData?.data?.dataSource?.importConfig?.updatedAt &&
                        new Date(fieldsData?.data?.dataSource?.importConfig?.updatedAt),
                }),
            ),
        [fieldsData],
    )

    const indexData = useQuery<IndexData, IndexDataVariables>(INDEX_DATA, {
        variables: {
            dataSourceIdStr: dataSourceIdStr || '00000000-0000-0000-0000-000000000000',
        },
    })
    useEffect(
        () =>
            setState((s) =>
                calculateSearchResult({
                    ...s,
                    loading: {
                        ...s.loading,
                        indexData: indexData.loading,
                    },
                    indexData: indexData.data?.dataSource?.dataIndex,
                }),
            ),
        [indexData],
    )

    useEffect(() => {
        if (dataSourceSyncing && !state.loading.dataSourceSyncing) {
            setState((s) => ({...s, loading: {...s.loading, dataSourceSyncing: true}}))
        }

        if (!dataSourceSyncing && state.loading.dataSourceSyncing) {
            if (state.lastImport?.options?.processDataset) {
                refetchIndexData(setState, indexData)
            }
            refetchFieldsData(setState, fieldsData)
            setState((s) => ({...s, loading: {...s.loading, dataSourceSyncing: false}}))
        }
    }, [dataSourceSyncing])

    const [updateImportConfig, updateImportConfigState] = useMutation<UpdateImportConfig, UpdateImportConfigVariables>(
        UPDATE_IMPORT_CONFIG,
        {
            onError: (error) => alert.error(getGraphQLErrorMessage(error)),
            onCompleted: () => {
                setState((s) => calculateFooterAction({...s, lastConfigUpdate: new Date()}))
            },
        },
    )

    useEffect(() => setState(calculateFooterAction), [modal])

    const setSearchString = (searchString: string) =>
        setState((s) =>
            calculateSearchResult({...s, defineField: {showingSearch: true, ...s.defineField, searchString}}),
        )

    const startFieldRuleEdit = (fieldRule: string | undefined) =>
        setState((s) =>
            calculateSearchResult({
                ...s,
                defineField: {
                    ...s.defineField,
                    editingFieldRule: fieldRule,
                    draftFieldRule: s.fieldRules?.find((fr) => fr.fieldName === fieldRule) || {},
                    searchString: s.fieldRules?.find((fr) => fr.fieldName === fieldRule)?.expression || '',
                    showingSearch: !fieldRule,
                },
            }),
        )

    const startFieldRuleDelete = (fieldRule: string | undefined) =>
        setState((s) =>
            calculateSearchResult({
                ...s,
                defineField: {
                    showingSearch: true,
                    ...s.defineField,
                    deletingFieldRule: fieldRule,
                },
            }),
        )

    const startFieldSearch = () =>
        setState((s) =>
            calculateSearchResult({
                ...s,
                defineField: {
                    ...s.defineField,
                    searchString: s.defineField?.draftFieldRule?.expression,
                    showingSearch: true,
                },
            }),
        )

    const completeFieldSearch = () =>
        setState((s) => ({
            ...s,
            defineField: {
                ...s.defineField,
                showingSearch: false,
            },
        }))

    const updateDraftFieldRule = (draftFieldRule: Partial<FieldsData_dataSource_importConfig_fieldRules>) => {
        setState((s) => ({
            ...s,
            defineField: {
                showingSearch: false,
                ...s.defineField,
                draftFieldRule: {...s.defineField?.draftFieldRule, ...draftFieldRule},
            },
        }))
    }

    const completeFieldRuleEdit = () => {
        const fieldRules = [
            ...(state.fieldRules || []).map((fieldRule) =>
                fieldRule.fieldName === state.defineField?.editingFieldRule
                    ? {...fieldRule, ...state.defineField.draftFieldRule}
                    : fieldRule,
            ),
            ...(state.defineField?.editingFieldRule
                ? []
                : [state.defineField?.draftFieldRule as FieldsData_dataSource_importConfig_fieldRules]),
        ]
        return updateImportConfig({
            variables: {
                dataSourceIdStr: dataSourceIdStr || '00000000-0000-0000-0000-000000000000',
                importConfig: {
                    fieldRules: fieldRules.map((fieldRule) => ({
                        ...omit(fieldRule, ['__typename']),
                        isKeyField: false,
                    })),
                },
            },
        }).then(() => {
            if (updateImportConfigState.error) throw new Error(updateImportConfigState.error.message)
            setState((s) => calculateFooterAction({...s, fieldRules}))
            alert.success('Field has been updated.')
        })
    }

    const completeFieldRuleDelete = () => {
        const fieldRules = (state.fieldRules || []).filter(
            (fieldRule) => fieldRule.fieldName !== state.defineField?.deletingFieldRule,
        )
        return updateImportConfig({
            variables: {
                dataSourceIdStr: dataSourceIdStr || '00000000-0000-0000-0000-000000000000',
                importConfig: {fieldRules: fieldRules.map((fieldRule) => omit(fieldRule, ['__typename']))},
            },
        }).then(() => {
            if (updateImportConfigState.error) throw new Error(updateImportConfigState.error.message)
            setState((s) =>
                calculateFooterAction({
                    ...s,
                    fieldRules,
                    defineField: {showingSearch: false, ...s.defineField, deletingFieldRule: undefined},
                }),
            )
            alert.success('Field has been deleted.')
        })
    }

    const cancelFieldRuleDelete = () => {
        setState((s) => ({
            ...s,
            defineField: {showingSearch: false, ...s.defineField, deletingFieldRule: undefined},
        }))
    }

    return {
        state,
        actions: {
            setModal,
            setSearchString,
            startFieldRuleEdit,
            startFieldRuleDelete,
            startFieldSearch,
            completeFieldSearch,
            updateDraftFieldRule,
            completeFieldRuleEdit,
            completeFieldRuleDelete,
            cancelFieldRuleDelete,
        },
    }
}

export default useManageFields
