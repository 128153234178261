import {gql, useMutation} from '@apollo/client'
import {cloneDeep} from '@apollo/client/utilities'
import {Form, useAnalyticsEventTracker} from 'nf-ui'
import {Label} from 'nf-ui/Form/Label'
import React, {useCallback, useContext, useMemo, useRef, useState} from 'react'
import {useAlert} from 'react-alert'
import {getGraphQLErrorMessage} from '~/util'
import {FULL_PROFILE_DATA} from '../FullProfile/FullProfile'
import {FullProfileData} from '../FullProfile/__types__/FullProfileData'
import {FormItemHeader, HiddenText} from './PrimaryFields'
import {
    EditProfileData_profile_additionalFields,
    EditProfileData_profile_primaryFields,
} from './__types__/EditProfileData'
import {UpdateProfileHiddenFields, UpdateProfileHiddenFieldsVariables} from './__types__/UpdateProfileHiddenFields'

const UPDATE_HIDDEN_FIELDS = gql`
    mutation UpdateProfileHiddenFields($profileIdStr: String!, $hiddenFields: [String!]!) {
        updateHiddenFields(profileIdStr: $profileIdStr, hiddenFields: $hiddenFields) {
            idStr
            label
            value
            type
            extraData {
                hiddenFromProfile
                fieldIdStr
                hideable
                childCategories {
                    idStr
                    label
                }
                dateMask
            }
        }
    }
`

type HideableField = EditProfileData_profile_primaryFields | EditProfileData_profile_additionalFields

export function isPrimaryField(field: HideableField): field is EditProfileData_profile_primaryFields {
    return Object.prototype.hasOwnProperty.call(field, 'label')
}

const getFieldLabel = (field: HideableField) => {
    if (isPrimaryField(field)) {
        return field.label
    }

    return field.name
}

const getHelpText = (field: HideableField) => {
    return !isPrimaryField(field) ? field.helpText : ''
}

const HiddenFieldContext = React.createContext<{
    hiddenIdStrs: string[]
    setInitialHiddenFields: (fields: HideableField[], profileIdStr: string) => void
    isHidden: (field: HideableField) => boolean
    toggleVisibility: (field: HideableField) => Promise<void>
    loading: boolean
} | null>(null)

export const useHiddenFields = () => {
    const hiddenFieldContext = useContext(HiddenFieldContext)
    if (!hiddenFieldContext) throw new Error('Cannot use hiddenFieldContext outside of the <HiddenFieldProvider />')

    return hiddenFieldContext
}

export const HiddenFieldProvider: React.FC = ({children}) => {
    const profileIdStr = useRef<string | null>(null)
    const rollback = useRef<string[] | null>(null)

    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const alert = useAlert()
    const [hiddenIdStrs, setFieldIdStrs] = useState<string[]>([])

    const [updateHiddenFields, {loading}] = useMutation<UpdateProfileHiddenFields, UpdateProfileHiddenFieldsVariables>(
        UPDATE_HIDDEN_FIELDS,
        {
            update: (cache, {data}) => {
                if (!data || !data.updateHiddenFields) return

                const fullProfileData = cache.readQuery<FullProfileData>({
                    query: FULL_PROFILE_DATA,
                    variables: {
                        idStr: profileIdStr.current,
                    },
                })

                if (!fullProfileData) return

                const clonedData = cloneDeep(fullProfileData)
                clonedData.profile.profileLines = data.updateHiddenFields.filter(
                    (profileLineObject) => !profileLineObject.extraData.hiddenFromProfile,
                )
                cache.writeQuery<FullProfileData>({
                    query: FULL_PROFILE_DATA,
                    data: clonedData,
                })
            },
            onError: (error) => {
                alert.error(getGraphQLErrorMessage(error))
                if (rollback.current) {
                    setFieldIdStrs(rollback.current)
                    rollback.current = null
                }
                throw error
            },
        },
    )

    const setInitialHiddenFields = useCallback((fields: HideableField[], editProfileIdStr: string) => {
        if (profileIdStr.current === editProfileIdStr) return

        const hiddenFields = fields
            .filter((field) => field.extraData?.hiddenFromProfile && field.extraData?.fieldIdStr)
            .map((field) => field.extraData!.fieldIdStr)

        setFieldIdStrs(hiddenFields)
        profileIdStr.current = editProfileIdStr
    }, [])

    const isHidden = useCallback(
        (field: HideableField) => {
            const fieldIdStr = field.extraData?.fieldIdStr
            return !!fieldIdStr && hiddenIdStrs.includes(fieldIdStr)
        },
        [hiddenIdStrs],
    )

    const toggleVisibility = useCallback(
        async (field: HideableField) => {
            if (!field.extraData?.fieldIdStr || !profileIdStr.current) return

            const label = getFieldLabel(field)
            const hidden = isHidden(field)

            rollback.current = hiddenIdStrs
            let newHiddenIdStrs: string[]

            if (hidden) {
                newHiddenIdStrs = hiddenIdStrs.filter((idStr) => idStr !== field.extraData!.fieldIdStr)
            } else {
                newHiddenIdStrs = [...hiddenIdStrs, field.extraData.fieldIdStr]
            }
            setFieldIdStrs(newHiddenIdStrs)

            await updateHiddenFields({
                variables: {
                    profileIdStr: profileIdStr.current,
                    hiddenFields: newHiddenIdStrs,
                },
            })
            trackAnalyticsEvent('edit_profile_toggle_visible', {
                fieldType: field.type!,
                visible: !hidden,
            })

            const pastParticiple = hidden ? 'marked as visible' : 'hidden'
            alert.success(`${label} successfully ${pastParticiple}`)
        },
        [alert, hiddenIdStrs, isHidden, trackAnalyticsEvent, updateHiddenFields],
    )

    const contextValue = useMemo(
        () => ({
            hiddenIdStrs,
            setInitialHiddenFields,
            isHidden,
            toggleVisibility,
            loading,
        }),
        [hiddenIdStrs, setInitialHiddenFields, isHidden, toggleVisibility, loading],
    )

    return <HiddenFieldContext.Provider value={contextValue}>{children}</HiddenFieldContext.Provider>
}

export const HideableFieldSwitch = ({field}: {field: HideableField}) => {
    const {isHidden, toggleVisibility, loading} = useHiddenFields()

    const hidden = isHidden(field)
    const hideable = field.extraData?.hideable
    const label = getFieldLabel(field)
    const helpText = getHelpText(field)

    return (
        <FormItemHeader>
            <Label disabled={hidden} helpText={helpText}>
                {label}
                {hideable && <HiddenText style={{opacity: hidden ? 1 : 0}}> (Hidden on profile)</HiddenText>}
            </Label>
            {hideable && (
                <Form.Group name="hiddenFromProfile">
                    <Form.Switch checked={!hidden} onChange={() => toggleVisibility(field)} disabled={loading} />
                </Form.Group>
            )}
        </FormItemHeader>
    )
}
