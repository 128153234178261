import {Typography} from 'nf-ui'
import React, {useRef} from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {ListChildComponentProps, VariableSizeList} from 'react-window'
import styled from 'styled-components'
import {StyledVariableSizeList} from '~/components/HorizontalProfilesList'
import {useScrollBooster} from '~/components/HorizontalProfilesList/useScrollbooster'
import {PHOTO_HEIGHT, PHOTO_WIDTH} from '~/components/Photo/Photo'
import {MemoizedProfile} from '~/components/Profile'
import {InsightPanel} from '../Insights/InsightPanel'
import {MeetingsThisWeek_meetingsThisWeek} from './__types__/MeetingsThisWeek'
import {LinesWithValuesByProfile, getThumbLinesByProfile} from '~/components/ProfilesGrid/ProfilesGrid'
import {useOrganisationContext} from '~/components/OrganisationContext'

const SCROLLBAR_HEIGHT = 16
const GAP = 2
const PERSONAL_TASK_WIDTH = PHOTO_WIDTH * 2 + GAP
const PADDING = 32

const PersonalTaskContainer = styled.div`
    display: flex;
    align-items: flex-end;
    height: 59px;
    width: ${PERSONAL_TASK_WIDTH}px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 3px;
    background: repeating-linear-gradient(
        -45deg,
        ${({theme}) => theme.primary.color},
        ${({theme}) => theme.primary.color} 1px,
        ${({theme}) => theme.primary.color}D9 1px,
        ${({theme}) => theme.primary.color}D9 4px
    );
`

const PersonalTaskText = styled(Typography.Tiny)`
    color: ${({theme}) => theme.primary.textColor};
`

type Profile = {
    idStr: string
    fullName: string
    secondaryLine: string
    meetingProfilePhotoUrl: string | null
    meetingGuest: boolean
}

const Attendee: React.FC<ListChildComponentProps> = ({data, index, style}) => {
    const {profiles, lines} = data
    const profile = profiles[index]
    return (
        <MemoizedProfile
            meetingProfile
            style={{...style, left: Number(style.left) + PADDING}}
            profile={profile}
            lines={lines[profile.idStr]}
            meetingProfilePhotoUrl={profile.meetingProfilePhotoUrl}
            meetingGuest={profile.meetingGuest}
        />
    )
}

const AttendeeList = ({
    width,
    listRef,
    profiles,
    lines,
}: {
    width: number
    listRef: React.RefObject<VariableSizeList>
    profiles: Profile[]
    lines: LinesWithValuesByProfile
}) => {
    const lastScrollTo = useRef(0)
    const {scrollBooster} = useScrollBooster({variableSizeListRef: listRef})

    const scrollTo = (x: number) => {
        scrollBooster.current?.scrollTo({x})
        lastScrollTo.current = x
    }

    return (
        <StyledVariableSizeList
            ref={listRef}
            className="list"
            height={PHOTO_HEIGHT + SCROLLBAR_HEIGHT}
            itemCount={profiles.length}
            itemData={{profiles, lines, scrollTo}}
            itemSize={() => PHOTO_WIDTH + GAP}
            layout="horizontal"
            width={width + PADDING * 2}
            overscanCount={20}
            padding={PADDING}
        >
            {Attendee}
        </StyledVariableSizeList>
    )
}

const meetingDuration = (start: string, end: string, allDay: boolean) => {
    if (allDay) return 'All day'

    const TIME_DIVIDEND = 1000 * 60

    const startDate = new Date(start)
    const endDate = new Date(end)
    const duration = (endDate.getTime() - startDate.getTime()) / TIME_DIVIDEND

    return `${duration} minutes`
}

const dayOfWeekFor = (date: Date) => {
    const DAYS: Record<number, string> = {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    }

    return DAYS[date.getDay()]
}

const meetingTime = (dateTime: string, allDay: boolean) => {
    const date = new Date(dateTime)
    const time = date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})
    const today = new Date().getDate()

    const timeSlot = allDay ? '' : ` - ${time}`

    const isToday = today === date.getDate()
    const isTomorrow = today + 1 === date.getDate()

    if (isToday) return `Today${timeSlot}`
    if (isTomorrow) return `Tomorrow${timeSlot}`

    return `${dayOfWeekFor(date)}${timeSlot}`
}

export const Meeting: React.FC<{
    meeting: MeetingsThisWeek_meetingsThisWeek
    firstMeeting?: boolean
}> = ({meeting, firstMeeting = false}) => {
    const [dataEditValues] = useOrganisationContext.useDataEditValues()
    const [dataEditFields] = useOrganisationContext.useDataEditFields()
    const [profileLines] = useOrganisationContext.useProfileLines()

    const listRef = useRef<VariableSizeList>(null)

    const {name, startTime, endTime, allDay, attendees} = meeting

    const duration = meetingDuration(startTime, endTime, allDay)

    const profiles = attendees
        .map((attendee) => {
            return {
                idStr: attendee.email,
                firstName: attendee.firstName || '',
                lastName: attendee.lastName || '',
                fullName: `${attendee.firstName} ${attendee.lastName}`,
                secondaryLine: attendee.email,
                meetingProfilePhotoUrl: attendee.photoUrl,
                meetingGuest: attendee.guest,
            }
        })
        .sort((x) => (x.meetingGuest ? -1 : 1))

    const meetingLabel = firstMeeting ? (
        <span>
            Your next meeting: {name} - {meetingTime(startTime, allDay)} 📅
        </span>
    ) : (
        <span>
            {meetingTime(startTime, allDay)} | {name} - {duration}
        </span>
    )

    const lines = getThumbLinesByProfile(
        {dataEditValues, dataEditFields, profileLines, profiles},
        {
            numberOfLines: 2,
        },
    )

    return (
        <div>
            <InsightPanel label={meetingLabel} startExpanded={firstMeeting}>
                {attendees.length ? (
                    <AutoSizer disableHeight>
                        {({width}) => (
                            <AttendeeList listRef={listRef} profiles={profiles} lines={lines} width={width} />
                        )}
                    </AutoSizer>
                ) : (
                    <PersonalTaskContainer>
                        <PersonalTaskText bold>Personal Task</PersonalTaskText>
                    </PersonalTaskContainer>
                )}
            </InsightPanel>
        </div>
    )
}
