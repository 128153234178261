import * as React from 'react'
const SvgEditThin = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgEditThin(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M12.928.674C12.5.208 11.725.177 11.243.588l-.099.095-9.859 9.84c-.156.121-.236.248-.325.5l-.046.138-.577 2.964c-.088.479.015.907.301 1.19.28.276.688.414 1.111.38l.159-.022 2.904-.603c.26-.043.462-.131.617-.268l9.909-9.894c.44-.479.47-1.244.096-1.752l-.086-.105-2.42-2.377zM1.674 12.624l.203-1.076 2.518 2.57-1.018.22-2.188.437.485-2.151zm.838-1.89l7.261-7.326 2.839 2.789-7.369 7.33-2.731-2.794zm7.997-8.036l1.48-1.515 1.368 1.346.099.094 1.37 1.346-1.48 1.517-2.837-2.788z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgEditThin
