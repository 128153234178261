import {gql, useQuery} from '@apollo/client'
import {AnalyticsContext, NFThemeProvider} from 'nf-ui'
import React, {FC, useEffect} from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router'
import {PageLoading} from './components/PageLoading'
import {useIdentifyUser} from './components/useIdentifyUser'
import {useOrganisationIdStr} from './components/useOrganisationIdStr'
import {UserSettings} from './components/UserSettingsContext'
import {AdminlandRoot} from './pages/Adminland/AdminlandRoot'
import {UserlandRoot} from './pages/Userland/UserlandRoot'
import {useDailySession} from './useDailySession'
import {useSetCloudfrontCookies} from './useSetCloudfrontCookies'
import {OrganisationIdRoutesData} from './__types__/OrganisationIdRoutesData'
import {useQueryDataEditGetFields} from './apollo/queries/DataEditField'
import {useQueryDataEditGetValues} from './apollo/queries/DataEditValue'
import {useQueryProfileLines} from './apollo/queries/ProfileLine'
import {
    OrganisationContextProvider,
    convertDataEditValuesByProfileIdStr,
    hydrateDataEditFieldTypes,
    hydrateProfileLineTypes,
    useOrganisationContext,
} from './components/OrganisationContext'
import {useMutationLogSessionActivity} from './apollo/queries/LogSessionActivity'

const ORGANISTION_ID_DATA = gql`
    query OrganisationIdRoutesData {
        organisations {
            idStr
            name
            accentColor
        }
    }
`

export type OrganisationIdRootProps = {
    organisationId: string
    userIdStr?: string
}

const OrganisationRoot: FC<{accentColor?: string; orgIdStr: string}> = ({accentColor, orgIdStr}) => {
    const {path} = useRouteMatch()!

    const [fieldsData, dataEditFields] = useQueryDataEditGetFields(orgIdStr)
    const dataEditValues = useQueryDataEditGetValues(orgIdStr)
    const [linesData, profileLines] = useQueryProfileLines(orgIdStr)
    const [, setDataEditValues] = useOrganisationContext.useDataEditValues()
    const [, setDataEditFields] = useOrganisationContext.useDataEditFields()
    const [, setDataEditValuesByProfileIdStr] = useOrganisationContext.useDataEditValuesByProfileIdStr()
    const [, setProfileLines] = useOrganisationContext.useProfileLines()
    const [, setRefreshing] = useOrganisationContext.useOrganisationRefreshing()

    useEffect(() => {
        const _dataEditValues = (dataEditValues.data?.dataEditValues || []).filter(
            (value) => value.value !== null && value.organisationIdStr === orgIdStr,
        )
        setDataEditValues(_dataEditValues)
        setDataEditValuesByProfileIdStr(convertDataEditValuesByProfileIdStr(_dataEditValues))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataEditValues])

    useEffect(() => {
        setDataEditFields(dataEditFields.map(hydrateDataEditFieldTypes))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataEditFields])

    useEffect(() => {
        setProfileLines(profileLines.map(hydrateProfileLineTypes))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileLines])

    useEffect(() => {
        setRefreshing({
            error: fieldsData.error || dataEditValues.error || linesData.error,
            loading: fieldsData.loading || dataEditValues.loading || linesData.loading,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldsData, dataEditValues, linesData])

    return (
        <Switch>
            <Route path={`${path}/admin`}>
                <AnalyticsContext.Provider value={{platform: 'WebAdminland'}}>
                    <AdminlandRoot />
                </AnalyticsContext.Provider>
            </Route>
            <Route default>
                <AnalyticsContext.Provider value={{platform: 'WebUserland'}}>
                    <NFThemeProvider primaryColor={accentColor}>
                        <UserlandRoot />
                    </NFThemeProvider>
                </AnalyticsContext.Provider>
            </Route>
        </Switch>
    )
}

export const OrganisationIdRoot: FC<OrganisationIdRootProps> = ({organisationId, userIdStr}) => {
    const {data, loading} = useQuery<OrganisationIdRoutesData>(ORGANISTION_ID_DATA)
    const {userSettings, setUserSettings} = UserSettings.useContainer()
    const currentOrganisationIdStr = useOrganisationIdStr()

    useSetCloudfrontCookies()
    useIdentifyUser()
    useDailySession()

    const [, callLogSessionActivity] = useMutationLogSessionActivity()

    useEffect(() => {
        if (userSettings.currentOrganisation !== organisationId) {
            setUserSettings({
                ...userSettings,
                currentOrganisation: organisationId,
            })
        }
    }, [organisationId, userSettings, setUserSettings])

    useEffect(() => {
        userIdStr && callLogSessionActivity({userIdStr, platform: 'web'})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userIdStr])

    if (!data || loading) return <PageLoading />
    if (currentOrganisationIdStr !== organisationId) return <PageLoading />

    if (!data.organisations.length) {
        return <Redirect to="/" />
    }

    if (!data.organisations.some((o) => o.idStr === organisationId)) {
        return <Redirect to={`/${data.organisations[0].idStr}`} />
    }

    return (
        <OrganisationContextProvider>
            <div
                onClick={() => {
                    userIdStr && callLogSessionActivity({userIdStr, platform: 'web'})
                }}
            >
                <OrganisationRoot
                    orgIdStr={organisationId}
                    accentColor={
                        data.organisations.find((organisation) => organisation.idStr === organisationId)?.accentColor
                    }
                />
            </div>
        </OrganisationContextProvider>
    )
}
