import React, {useState, useEffect} from 'react'
import ResizeObserver from 'resize-observer-polyfill'

function createRefs<T>(count: number) {
    const refs = []
    for (let index = 0; index < count; index++) {
        refs.push(React.createRef<T>())
    }
    return refs
}

export function useRefs<T>(elementsOrCount: any | any[] | number) {
    let elementCount: number
    if (typeof elementsOrCount === 'number') elementCount = elementsOrCount
    else elementCount = Array.isArray(elementsOrCount) ? elementsOrCount.length : 1

    const [refs, setRefs] = useState(createRefs<T>(elementCount))

    useEffect(() => {
        if (elementCount > refs.length) {
            // Element count has increased. We need more refs.
            const extraRefs = createRefs<T>(elementCount - refs.length)
            setRefs((refs) => [...refs, ...extraRefs])
        } else if (elementCount < refs.length) {
            // Element count has decreased. We can remove some refs.
            setRefs((refs) => refs.slice(0, elementCount))
        }
    }, [elementCount, refs.length])

    return refs
}

const DEFAULT_RECT: Omit<DOMRectReadOnly, 'toJSON'> = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
}

export const useMeasureMultiple = (refs: React.RefObject<HTMLElement | undefined | null>[]) => {
    const [widths, setWidths] = useState(refs.map(() => DEFAULT_RECT))

    useEffect(() => {
        const measure: ResizeObserverCallback = (entries) => {
            setWidths((draftWidths) => {
                for (const entry of entries) {
                    const elementIndex = refs.findIndex((ref) => ref.current === entry.target)
                    draftWidths[elementIndex] = entry.contentRect
                }
                return [...draftWidths]
            })
        }

        const resizeObserver = new ResizeObserver(measure)
        for (const ref of refs) {
            if (ref.current) resizeObserver.observe(ref.current)
        }

        return () => resizeObserver.disconnect()
    }, refs) //eslint-disable-line react-hooks/exhaustive-deps

    return widths
}
