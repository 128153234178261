import {useState} from 'react'

export type PhotoFilter = 'UNMATCHED' | 'MATCHED' | 'ALL'
export type PhotoFilterWithPending = PhotoFilter | 'PENDING_APPROVAL'

export function usePhotoFilter<PhotoType extends {profile?: any; pendingProfile?: any}>(
    photos: PhotoType[],
    initialFilter: PhotoFilterWithPending = 'ALL',
) {
    const [filter, setFilter] = useState<PhotoFilterWithPending>(initialFilter)

    let filteredPhotos = photos
    if (filter === 'UNMATCHED') filteredPhotos = photos.filter((photo) => !photo.profile)
    else if (filter === 'MATCHED') filteredPhotos = photos.filter((photo) => !!photo.profile)
    else if (filter === 'PENDING_APPROVAL') filteredPhotos = photos.filter((photo) => !!photo.pendingProfile)

    return {
        photos: filteredPhotos,
        setFilter,
        filter,
    }
}
