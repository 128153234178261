import {gql, useMutation} from '@apollo/client'
import isMobile from 'ismobilejs'
import {Button, Form, Spacer, Typography} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React, {FC, FormEvent} from 'react'
import {useAlert} from 'react-alert'
import {Redirect, useHistory} from 'react-router-dom'
import {useQueryParam} from 'use-query-params'
import {PageLoading} from '~/components/PageLoading'
import {PasswordInput} from '~/components/PasswordInput'
import {Token} from '~/components/TokenContext'
import {WelcomeLaout} from '~/components/WelcomeLayout'
import {getGraphQLErrorMessage} from '~/util'
import {usePasswordUpdate} from '../usePasswordUpdate'
import {useWelcomeDetails} from './useWelcomeDetails'
import {SetPassword, SetPasswordVariables} from './__types__/SetPassword'

export const SET_PASSWORD = gql`
    mutation SetPassword($token: String!, $password: String!) {
        setPassword(token: $token, password: $password) {
            accessToken
        }
    }
`

const useSetPassword = () => {
    const {setToken} = Token.useContainer()
    const {push} = useHistory()

    return useMutation<SetPassword, SetPasswordVariables>(SET_PASSWORD, {
        onCompleted: (result) => {
            setToken(result.setPassword.accessToken)
            if (isMobile(window.navigator).phone) {
                push(`/mobilesetpasswordsuccess`)
            } else {
                push('/')
            }
        },
        onError: () => {
            push('/login')
        },
    })
}

export const WelcomeUser: FC = () => {
    const [token] = useQueryParam<string>('token')
    const {name, company, loading: welcomeLoading, error: welcomeError} = useWelcomeDetails()

    const alert = useAlert()
    const passwordState = usePasswordUpdate()
    const [setPassword, {loading}] = useSetPassword()
    const onSubmit = async (event: FormEvent) => {
        try {
            event.preventDefault()
            await setPassword({variables: {token: token || '', password: passwordState.password}})
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    if (welcomeLoading) return <PageLoading />
    if (welcomeError) return <Redirect to={'/login'} />

    return (
        <WelcomeLaout>
            <Typography.Heading>Hi {name}</Typography.Heading>
            <Spacer height={16} />
            <Typography.Subheading maxWidth={415}>
                Join {company}’s visual people directory by setting a password for your account
            </Typography.Subheading>
            <Spacer height={32} />
            <Form onSubmit={(event) => onSubmit(event)}>
                <PasswordInput autoFocus label passwordState={passwordState} />
                <Spacer height={24} />
                <Button disabled={!passwordState.valid} type="submit" loading={loading}>
                    Confirm password
                    <Button.Icon icon={SvgArrowRight} />
                </Button>
            </Form>
            <Spacer height={64} />
        </WelcomeLaout>
    )
}
