import styled, {css} from 'styled-components'
import {CascadeHover} from './CascadeHover'
import {colors} from './theme'

export const CARD_WIDTH = 392

export type CardProps = {
    borderColor?: string | null
    disabled?: boolean
}

const Card = styled(CascadeHover).attrs(() => ({role: 'button', tabIndex: 0}))<CardProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    box-sizing: border-box;
    width: ${CARD_WIDTH}px;
    height: 164px;
    border: 1px solid ${(props) => (props.borderColor ? props.borderColor : colors.darkGray)};
    border-radius: 3px;
    padding: 24px;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    transition: 0.25s border ease-in-out;

    ${(props) =>
        props.disabled
            ? css`
                  opacity: 50%;
                  pointer-events: none;
              `
            : css`
                  :focus,
                  :hover {
                      border: 1px solid ${colors.primary[100]};
                  }
              `}
`

export {Card}
