import {Button, colors, Panel, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgCheckSquare from 'nf-ui/Icons/CheckSquare'
import SvgFilter from 'nf-ui/Icons/Filter'
import pluralize from 'pluralize'
import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {useSelectionMode} from '../Photo/utils'
import {OptionType, SortList} from '../SortList'
import {PhotoFilter, PhotoFilterWithPending, usePhotoFilter} from '../usePhotoFilter'
import {usePhotoSort} from '../usePhotoSort'

const PhotoCountContainer = styled.div`
    display: flex;
    align-items: center;
`

const CheckboxEmptyIcon = styled.div`
    box-sizing: border-box;
    display: flex;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    border: 1px solid ${colors.darkGray};
`

const SelectAllButton: React.FC<{onClick: React.MouseEventHandler<HTMLSpanElement>; allSelected: boolean}> = ({
    onClick,
    allSelected,
}) => {
    return (
        <span style={{cursor: 'pointer'}} onClick={onClick}>
            {allSelected ? <SvgCheckSquare /> : <CheckboxEmptyIcon />}
        </span>
    )
}

interface HeaderProps {
    count: number
    photoFilter: ReturnType<typeof usePhotoFilter>
    photoSort: ReturnType<typeof usePhotoSort>
}

const HeaderComponent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
`

const FiltersOptions: OptionType<PhotoFilterWithPending>[] = [
    {title: 'View all', filter: 'ALL', analyticsEvent: 'filter_view_all'},
    {title: 'View unmatched', filter: 'UNMATCHED', analyticsEvent: 'filter_view_unmatched'},
    {title: 'View matched', filter: 'MATCHED', analyticsEvent: 'filter_view_matched'},
    {title: 'View pending approval', filter: 'PENDING_APPROVAL', analyticsEvent: 'filter_view_pending_approval'},
]

const SortOptions: OptionType<PhotoFilter>[] = [
    {title: 'Unmatched', sort: 'UNMATCHED', analyticsEvent: 'sort_unmatched'},
    {title: 'Date added (oldest first)', sort: 'DATE_ASC', analyticsEvent: 'sort_date_added'},
    {title: 'Alphabetical (A-Z)', sort: 'NAME_ASC', analyticsEvent: 'sort_date_added_alphabetical'},
]

const Header: React.FC<HeaderProps> = ({count, photoFilter, photoSort}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const [open, setOpen] = useState(false)
    const buttonRef = useRef<HTMLButtonElement>(null)
    const {selectionMode, allSelected, setAllSelected} = useSelectionMode()

    const isOptionChecked = (option: OptionType<PhotoFilterWithPending>) =>
        option.filter === photoFilter.filter || option.sort === photoSort.sort

    return (
        <HeaderComponent>
            <PhotoCountContainer>
                {selectionMode && (
                    <>
                        <SelectAllButton
                            allSelected={allSelected}
                            onClick={(event) => {
                                event.stopPropagation()
                                setAllSelected(!allSelected)
                            }}
                        />
                        <Spacer width={16} />
                    </>
                )}
                <Typography.Label>
                    {count} {pluralize('photo', count)}
                </Typography.Label>
            </PhotoCountContainer>
            <Typography.Label>
                <Button variant="tertiary" ref={buttonRef} onClick={() => setOpen((o) => !o)}>
                    <Button.Icon icon={SvgFilter} /> Sort
                </Button>
            </Typography.Label>
            <Panel targetRef={buttonRef} open={open} onClose={() => setOpen(false)}>
                <SortList<PhotoFilterWithPending>
                    heading="Filter People"
                    options={FiltersOptions}
                    onClick={(row) => {
                        if (row.filter) {
                            row.analyticsEvent &&
                                trackAnalyticsEvent('select_photos_sort', {option: row.analyticsEvent})
                            photoFilter.setFilter(row.filter)
                        }
                        setOpen(false)
                    }}
                    isOptionChecked={isOptionChecked}
                />
                <SortList
                    heading="Sort People"
                    options={SortOptions}
                    onClick={(row) => {
                        if (row.sort) {
                            row.analyticsEvent &&
                                trackAnalyticsEvent('select_photos_sort', {option: row.analyticsEvent})
                            photoSort.setSort(row.sort)
                        }
                        setOpen(false)
                    }}
                    isOptionChecked={isOptionChecked}
                />
            </Panel>
        </HeaderComponent>
    )
}

export {Header}
