import {gql, useQuery} from '@apollo/client'
import {AnimatePresence, motion} from 'framer-motion'
import {Button, colors, Icon, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgAlertRed from 'nf-ui/Icons/AlertRed'
import SvgClose from 'nf-ui/Icons/Close'
import SvgEdit from 'nf-ui/Icons/Edit'
import React, {useState} from 'react'
import {matchPath, useLocation} from 'react-router'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {ProfileLineType} from '~/globalTypes'
import {getGraphQLErrorMessage} from '~/util'
import {useTrackProfileView} from '../Home/useViewedProfiles'
import {UserlandModal} from '../UserlandRoot'
import {Loading} from './Loading'
import {Photo} from './Photo'
import {useDeleteOpenPosition} from './useDeleteOpenPosition'
import {
    FullProfileData,
    FullProfileData_profile,
    FullProfileData_profile_profileLines,
} from './__types__/FullProfileData'
import {LineItems} from '~/components/ProfileView/LineItems'
import {ThumbStrip, ThumbStripImage} from '~/components/ProfilePicture/ProfilePicture'
import {orderPhotos, useOrganisationContext} from '~/components/OrganisationContext'

export const FULL_PROFILE_WIDTH = 404

const Border = styled.div`
    width: 1px;
    height: calc(100% - 64px);
    background-color: ${colors.darkGray};
`

const OVERLAY_BREAKPOINT = 1204

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(16, 16, 16, 0.3);
    z-index: 49;
    display: none;

    @media (max-width: ${OVERLAY_BREAKPOINT}px) {
        display: block;
    }
`

const Container = styled.div`
    width: ${FULL_PROFILE_WIDTH}px;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${colors.white};
    z-index: 50;
    flex-shrink: 0;
    flex-grow: 0;

    @media (max-width: ${OVERLAY_BREAKPOINT}px) {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: ${colors.white};

        ${Border} {
            display: none;
        }
    }
`

const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 24px;
`

const TopRightContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const PhotoContainer = styled.div`
    position: relative;
`
const EditProfileButton = styled.div`
    display: flex;
    justify-content: center;

    button {
        overflow: visible;
    }
`

const AlertContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(calc(50% + 0.5px), calc(-50% - 1px));
    background-color: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
`

const ProfileContentInner = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`

const OpenPositionActions = styled.div`
    padding: 16px;
`

const OpenPositionDescription = styled.div`
    background-color: ${colors.lightGray};
    border: 1px solid ${colors.darkGray};
    padding: 16px;
    border-radius: 3px;
`

export const FULL_PROFILE_DATA = gql`
    query FullProfileData($idStr: String!) {
        profile(idStr: $idStr) {
            idStr
            firstName
            lastName
            fullName
            canEdit
            reportsTo
            openPosition
            openPositionJobLink
            photos {
                idStr
                thumbUrl
                profileUrl
                priority
            }
            profileLines {
                idStr
                label
                value
                type
                extraData {
                    childCategories {
                        idStr
                        label
                    }
                    dateMask
                }
            }
        }
    }
`

export type FullProfileProps = {
    profileId: string
    cache?: boolean
}

export type ProfileLine = FullProfileData_profile_profileLines

export function isDetail(line: ProfileLine) {
    return line.type !== ProfileLineType.CATEGORY
}

const OpenPositionSection = ({profile}: {profile: FullProfileData_profile}) => {
    const organisationIdStr = useOrganisationIdStr()
    const {deleteOpenPosition, loading} = useDeleteOpenPosition(profile)

    return (
        <OpenPositionActions>
            <Button
                variant="tertiary"
                color={colors.red}
                loading={loading}
                onClick={() => deleteOpenPosition({variables: {idStr: profile.idStr, organisationIdStr}})}
                onClickAnalyticsEvent="orgCharts"
                onClickAnalyticsData={{
                    page: 'full-view',
                    component: 'button',
                    type: 'click',
                    action: 'delete',
                    name: 'open_position',
                }}
            >
                Delete position
            </Button>
            <Spacer height={8} />
            <OpenPositionDescription>
                <Typography.Paragraph bottomMargin={false}>
                    Open positions are visible to all users.
                </Typography.Paragraph>
            </OpenPositionDescription>
        </OpenPositionActions>
    )
}

const ProfileLineItems = ({organisationIdStr, profileIdStr}: {organisationIdStr: string; profileIdStr: string}) => {
    const [profileLines] = useOrganisationContext.useProfileLines()
    const [fields] = useOrganisationContext.useDataEditFields()
    const [dataEditValues] = useOrganisationContext.useDataEditValuesByProfileIdStr()

    // console.log('profileLines', profileLines, 'fields', fields, 'dataEditValues', dataEditValues)
    return (
        <LineItems
            organisationIdStr={organisationIdStr}
            profileIdStr={profileIdStr}
            profileLines={profileLines}
            fields={fields.map((field) => {
                if (field.type !== 'category') {
                    const values = (dataEditValues[profileIdStr] || [])
                        .filter((value) => value.fieldOrParentCategoryIdStr === field.idStr)
                        .map((value) => value.value)
                    return {...field, values, valueIdStrs: values.map((v) => undefined)}
                }
                const unorderedValues = (dataEditValues[profileIdStr] || [])
                    .filter((value) => value.fieldOrParentCategoryIdStr === field.idStr)
                    .map((value) => value.value)
                const values = field.availableValues
                    .map((value, index) => ({value, index, valueIdStr: field.availableValueIdStrs[index]}))
                    .filter((v) => unorderedValues.includes(v.value))
                // console.log('field', field, 'unorderedValues', unorderedValues, 'values', values)
                return {
                    ...field,
                    values: values.map((v) => v.value),
                    valueIdStrs: values.map((v) => v.valueIdStr),
                }
            })}
        ></LineItems>
    )
}

export const FullProfile: React.FC<FullProfileProps> = ({profileId, cache = true}) => {
    const cachePolicy = cache ? {} : ({fetchPolicy: 'no-cache'} as const)
    const {data, error, loading} = useQuery<FullProfileData>(FULL_PROFILE_DATA, {
        variables: {idStr: profileId},
        ...cachePolicy,
    })

    const [, setProfileId] = useQueryParam<string | null>('profileId')
    const [, setEditProfileId] = useQueryParam<string | null>('editProfileId')
    const [, setModal] = useQueryParam<UserlandModal>('modal')

    const {currentOrganisation, loading: organisationLoading, isAdmin} = useCurrentOrganisation()
    const editableProfile = currentOrganisation?.editableProfile

    const orderedPhotos = orderPhotos(data?.profile?.photos || [])
    const photo1 = orderedPhotos[0]
    const photo2 = orderedPhotos[1]
    const [selectedImage, setSelectedImage] = useState((photo1?.priority || 1) - 1)

    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const handleEditProfile = (idStr: string) => {
        trackAnalyticsEvent('click_edit_profile', {from: 'profile'})
        setEditProfileId(idStr)
        setModal('editProfile')
    }

    const location = useLocation()
    const match = matchPath(location.pathname, {path: '/:id/org-chart/reporting-lines', exact: false, strict: false})
    useTrackProfileView(data?.profile)

    if (error) return <Typography.Heading>Error: {getGraphQLErrorMessage(error)}</Typography.Heading>

    const canEditProfile = data?.profile.canEdit || false

    const actionRequired = !!editableProfile && editableProfile.profilePictureActionRequired
    const showOpenPositionSection = data?.profile.openPosition && isAdmin

    return (
        <>
            {!match && <Overlay />}
            <Container>
                <Border style={match ? {display: 'block'} : undefined} />
                <AnimatePresence exitBeforeEnter initial={false}>
                    {loading || organisationLoading || !data ? (
                        <Content data-testid="spinner" key="loading">
                            <Loading />
                        </Content>
                    ) : (
                        <ProfileContentInner>
                            <Content key={profileId}>
                                <TopContainer>
                                    <PhotoContainer>
                                        <Photo photo={orderedPhotos[selectedImage]} fullName={data.profile.fullName} />
                                        {photo1 && photo2 && (
                                            <ThumbStrip>
                                                <ThumbStripImage
                                                    url={photo1.profileUrl}
                                                    selected={selectedImage === 0 ? 'selected' : 'unselected'}
                                                    onClick={() => setSelectedImage(0)}
                                                />
                                                <ThumbStripImage
                                                    url={photo2.profileUrl}
                                                    selected={selectedImage === 1 ? 'selected' : 'unselected'}
                                                    onClick={() => setSelectedImage(1)}
                                                />
                                            </ThumbStrip>
                                        )}
                                    </PhotoContainer>
                                    {!match && (
                                        <TopRightContainer>
                                            <Button
                                                data-testid="close_button"
                                                variant="tertiary"
                                                color={colors.black}
                                                style={{
                                                    margin: '-8px -8px 0px',
                                                }}
                                                onClick={() => setProfileId(null)}
                                                onClickAnalyticsEvent="select_close_profile"
                                                onClickAnalyticsData={{profileId}}
                                            >
                                                Close <Button.Icon icon={SvgClose} />
                                            </Button>
                                            {canEditProfile && (
                                                <EditProfileButton>
                                                    <Button
                                                        variant="secondary"
                                                        onClick={() => handleEditProfile(data.profile.idStr)}
                                                    >
                                                        Edit <Button.Icon icon={SvgEdit} />
                                                        {actionRequired && (
                                                            <AlertContainer>
                                                                <Icon icon={SvgAlertRed} />
                                                            </AlertContainer>
                                                        )}
                                                    </Button>
                                                </EditProfileButton>
                                            )}{' '}
                                        </TopRightContainer>
                                    )}
                                </TopContainer>
                                <Spacer height={24} />
                                {currentOrganisation?.idStr && (
                                    <ProfileLineItems
                                        organisationIdStr={currentOrganisation!.idStr}
                                        profileIdStr={profileId}
                                    ></ProfileLineItems>
                                )}
                            </Content>
                            {showOpenPositionSection && <OpenPositionSection profile={data.profile} />}
                        </ProfileContentInner>
                    )}
                </AnimatePresence>
            </Container>
        </>
    )
}

const Content = styled(motion.div).attrs(() => ({
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0},
    transition: {duration: 0.25},
}))`
    flex: 1;
    padding: 32px 0px;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
`
