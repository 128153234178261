import {AnimatePresence, motion} from 'framer-motion'
import React, {useRef, useState} from 'react'
import {useDisplayOptions} from './useDisplayOptions'
import {useOnFilterChange} from './useOnFilterChange'

const EXPAND_DURATION = 400

export const useCollapsibleState = (profileIdStr: string) => {
    const {toggleCollapsedProfile, isCollapsed} = useDisplayOptions()
    const [expanded, setExpanded] = useState(!isCollapsed(profileIdStr))

    const expandProfile = () => {
        if (!expanded) setExpanded(true)
    }

    useOnFilterChange(expandProfile)

    const toggleCollapsible = () => {
        toggleCollapsedProfile(profileIdStr)

        setExpanded((state) => {
            return !state
        })
    }

    return {
        expanded,
        toggleCollapsible,
    }
}

export const Collapsible: React.FC<{
    animation?: boolean
    expanded: boolean
    onAnimationComplete?: () => void
}> = ({children, expanded, onAnimationComplete, animation}) => {
    const initialState = useRef(expanded)
    const initialAnimationComplete = useRef(false)

    if (!animation) {
        if (expanded) return <>{children}</>
        return null
    }

    const motionProps = {
        initial: 'collapsed',
        animate: 'open',
        exit: 'collapsed',
        transition: {duration: EXPAND_DURATION / 1000, ease: 'easeInOut'},
    }

    const animationTriggered = expanded !== initialState.current || initialAnimationComplete.current

    return (
        <AnimatePresence initial={false}>
            {expanded && (
                <motion.div
                    onAnimationComplete={() => {
                        if (!animationTriggered) {
                            initialAnimationComplete.current = true
                            return
                        }
                        onAnimationComplete?.()
                    }}
                    variants={{
                        open: {opacity: 1, height: 'auto'},
                        collapsed: {opacity: 0, height: 0},
                    }}
                    {...motionProps}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    )
}
