import {List} from 'nf-ui'
import React from 'react'
import {Header} from './ProfileListHeader'
import {ProfileRow} from './ProfileRow'
import {ProfileSelectState} from './useProfileSelect'

function ProfilesList<ProfileType extends {idStr: string; fullName: string}>({
    profileSelection,
    responded,
}: {
    profileSelection: ProfileSelectState<ProfileType>
    responded?: (profile: ProfileType) => boolean
}) {
    return (
        <List
            width={400}
            heading={<Header multiselect={profileSelection} />}
            rows={profileSelection.profiles}
            renderRow={(profile) => {
                return (
                    <ProfileRow<ProfileType>
                        multiselect={profileSelection}
                        profile={profile}
                        responded={responded?.(profile) || false}
                    />
                )
            }}
        />
    )
}

export {ProfilesList}
