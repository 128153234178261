import {Spinner} from 'nf-ui'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

export const Loading: React.FC = () => {
    return (
        <Container>
            <Spinner size={32} />
        </Container>
    )
}
