import {Formik, FormikHelpers} from 'formik'
import {Button, Form, FormikInput, Spacer, useAnalyticsEventTracker} from 'nf-ui'
import {Label} from 'nf-ui/Form/Label'
import React, {Fragment} from 'react'
import {useQueryParam} from 'use-query-params'
import {ProfileLineType} from '~/globalTypes'
import {PrimaryFields} from './PrimaryFieldsTab'
import {EditProfileData_profile, EditProfileData_profile_primaryFields} from './__types__/EditProfileData'
import * as yup from 'yup'
import {gql, useMutation} from '@apollo/client'
import {ModalLayout} from '~/components/ModalLayout'
import {OpenPositionField} from '~/objectTypes'
import {UpdateOpenPosition, UpdateOpenPositionVariables} from './__types__/UpdateOpenPosition'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {useAlert} from 'react-alert'
import {getGraphQLErrorMessage} from '~/util'
import {FULL_PROFILE_DATA} from '../FullProfile/FullProfile'
import {FullOrgChartData} from '../__types__/FullOrgChartData'
import {FULL_ORG_CHART_DATA} from '../useFullOrgChartData'
import produce from 'immer'
import {FormikCreateOrSelect} from 'nf-ui/Form/Formik/CreateOrSelect'

export const UPDATE_OPEN_POSITION = gql`
    mutation UpdateOpenPosition(
        $organisationIdStr: String!
        $idStr: String!
        $fields: [OpenPositionField!]!
        $openPositionJobLink: String
    ) {
        updateOpenPosition(
            fields: $fields
            organisationIdStr: $organisationIdStr
            idStr: $idStr
            openPositionJobLink: $openPositionJobLink
        ) {
            idStr
            fullName
            secondaryLine
            openPositionJobLink
        }
    }
`

const useUpdateOpenPosition = () => {
    const alert = useAlert()
    const [profileIdStr] = useQueryParam<string>('profileId')
    const organisationIdStr = useOrganisationIdStr()
    const trackEvent = useAnalyticsEventTracker()

    const [update] = useMutation<UpdateOpenPosition, UpdateOpenPositionVariables>(UPDATE_OPEN_POSITION, {
        onCompleted: () => {
            alert.success('Successfully updated open position')
        },
        onError: (error) => {
            alert.error(getGraphQLErrorMessage(error))
            throw error
        },
        refetchQueries: [{query: FULL_PROFILE_DATA, variables: {idStr: profileIdStr}}],
        awaitRefetchQueries: true,
        update: (cache, {data}) => {
            const orgCharQuery = cache.readQuery<FullOrgChartData>({
                query: FULL_ORG_CHART_DATA,
                variables: {idStr: organisationIdStr},
            })
            if (!orgCharQuery || !data) return

            cache.writeQuery<FullOrgChartData>({
                query: FULL_ORG_CHART_DATA,
                data: produce(orgCharQuery, (draftState) => {
                    const openPosition = draftState.organisation.profiles.find(
                        (profile) => profile.idStr === profileIdStr,
                    )
                    if (openPosition) openPosition.secondaryLine = data.updateOpenPosition.secondaryLine
                }),
            })
        },
    })

    const submitForm = (fields: EditProfileData_profile_primaryFields[]) => async (
        values: OpenPositionPayload,
        actions: FormikHelpers<OpenPositionPayload>,
    ) => {
        const openPositionFields = Object.keys(values.fields).map((fieldIdStr) => {
            const field = fields.find((field) => field.extraData.fieldIdStr === fieldIdStr)
            const formValue = values.fields[fieldIdStr] || ''

            const existingOption = field?.extraData.options?.find((option) => option.idStr === formValue)
            const variables: {categoryIdStr: string} | {value: string} = existingOption
                ? {categoryIdStr: formValue}
                : {value: formValue}

            return {
                fieldIdStr,
                ...variables,
            } as OpenPositionField
        })
        const openPositionJobLink = values.openPositionJobLink

        await update({
            variables: {
                idStr: profileIdStr!,
                organisationIdStr,
                fields: openPositionFields,
                openPositionJobLink,
            },
        })

        trackEvent('orgCharts', {
            page: 'edit-profile',
            component: 'button',
            type: 'click',
            action: 'edit',
            name: 'open_position',
        })

        actions.resetForm({values})
    }

    return submitForm
}

type OpenPositionPayload = {
    fields: Record<string, string>
    openPositionJobLink: string
}

export const OpenPositionFields = ({
    fields,
    openPositionJobLink,
}: {
    fields: PrimaryFields
    openPositionJobLink: EditProfileData_profile['openPositionJobLink']
}) => {
    const [editProfileId] = useQueryParam<string>('editProfileId')
    const submit = useUpdateOpenPosition()
    if (!editProfileId) throw new Error('Missing editProfileId')

    const categories = fields.filter((field) => field.type === ProfileLineType.CATEGORY)

    const submitForm = submit(categories)

    const intitialValues = {
        fields: categories.reduce((values, current) => {
            const category = current.extraData.options?.find((options) => options.label === current.value)
            values[current.extraData.fieldIdStr] = category?.idStr || ''
            return values
        }, {} as Record<string, string>),
        openPositionJobLink: openPositionJobLink || '',
    }

    const formSchema = categories.reduce((schema, current) => {
        schema[current.extraData.fieldIdStr] = yup.string().label('this')
        return schema
    }, {} as Record<string, yup.StringSchema>)

    return (
        <>
            <Formik
                onSubmit={submitForm}
                initialValues={intitialValues}
                validationSchema={yup.object({
                    fields: yup.object(formSchema),
                    openPositionJobLink: yup.string(),
                })}
            >
                {({handleSubmit, isSubmitting, dirty}) => (
                    <>
                        <form onSubmit={handleSubmit}>
                            {categories.map((category: EditProfileData_profile_primaryFields) => {
                                const options = category.extraData.options?.map((option) => ({
                                    label: option.label,
                                    value: option.idStr,
                                }))
                                return (
                                    <Fragment key={category.idStr}>
                                        <div>
                                            <Form.Group name={`fields.${category.extraData.fieldIdStr}`}>
                                                <Label>{category.label}</Label>
                                                <FormikCreateOrSelect options={options} label={category.label} />
                                            </Form.Group>
                                        </div>
                                        <Spacer height={24} />
                                    </Fragment>
                                )
                            })}
                            <Form.Group name="openPositionJobLink">
                                <Label>Job posting (Optional)</Label>
                                <FormikInput placeholder="www.yourcompanyjobboard.com" />
                            </Form.Group>
                            <Spacer height={24} />
                            <ModalLayout.Footer>
                                <Button disabled={!dirty} loading={isSubmitting} variant="primary" type="submit">
                                    Save
                                </Button>
                            </ModalLayout.Footer>
                        </form>
                    </>
                )}
            </Formik>
        </>
    )
}
