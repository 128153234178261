import {useApolloClient} from '@apollo/client'
import {OAuthProvider} from '~/globalTypes'
import {
    createAuthWindow,
    OAuthCancelledType,
    OAuthFailureType,
    OAuthSuccessType,
    waitForOAuthMessage,
} from './authWindow'
import {generateOAuthCredentials} from './generateOAuthCredentials'
import {getOAuthUrl} from './getOAuthUrl'
import {useCleanupOnUnmount} from './useCleanupOnUnmount'

type Options = {
    provider: OAuthProvider
}

export const useOAuthFlow = ({provider}: Options) => {
    const client = useApolloClient()
    const cleanupRef = useCleanupOnUnmount()

    const startAuth = async () => {
        const oAuthUrl = await getOAuthUrl(client, provider)

        const authWindow = createAuthWindow(oAuthUrl)

        if (!authWindow) throw new Error('Could not create auth window')

        const oAuthResponse = await waitForOAuthMessage(authWindow, cleanupRef)

        if (oAuthResponse.type === OAuthSuccessType) {
            return generateOAuthCredentials(client, oAuthResponse, provider)
        } else if (oAuthResponse.type === OAuthFailureType || oAuthResponse.type === OAuthCancelledType) {
            return
        }

        throw new Error('OAuth Failed')
    }

    return startAuth
}
