import {gql, useMutation} from '@apollo/client'
import {Button, Spacer, Typography} from 'nf-ui'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {StringParam, useQueryParams} from 'use-query-params'
import {PageLoading} from '~/components/PageLoading'
import {Token} from '~/components/TokenContext'
import {WizardLayout} from '~/components/WizardLayout'
import {getGraphQLErrorMessage} from '~/util'
import {oAuthRedirectURI} from './Onboarding/Login/OAuthRedirect'
import {HandleOAuthCode as HandleOAuthCodeResponse, HandleOAuthCodeVariables} from './__types__/HandleOAuthCode'

const HANDLE_OAUTH_CODE = gql`
    mutation HandleOAuthCode($authCode: String!, $clientId: String!, $redirectUri: String!) {
        oAuthSignIn(authCode: $authCode, clientId: $clientId, redirectUri: $redirectUri) {
            accessToken
            subId
        }
    }
`

export const HandleOAuthCode: React.FC = () => {
    const [error, setError] = useState<string | undefined>()
    const [{code, state, error: oAuthError, error_description: oAuthErrorDescription}] = useQueryParams({
        code: StringParam,
        state: StringParam,
        error: StringParam,
        error_description: StringParam,
    })
    const {setToken} = Token.useContainer()
    const {replace} = useHistory()
    const [oAuthSignIn] = useMutation<HandleOAuthCodeResponse, HandleOAuthCodeVariables>(HANDLE_OAUTH_CODE, {
        onError: (error) => setError(getGraphQLErrorMessage(error)),
        onCompleted: (result) => {
            setToken(result.oAuthSignIn.accessToken)
            replace('/')
        },
    })

    useEffect(() => {
        try {
            if (oAuthError) {
                const errorDescription = oAuthErrorDescription || 'Unknown OAuth error'
                throw new Error(`${errorDescription} (${oAuthError}). Please contact support.`)
            }

            if (!code) throw new Error('Missing code value')
            if (!state) throw new Error('Missing state value')

            const storedState = localStorage.getItem('oAuthState')
            if (state !== storedState) {
                throw new Error('Invalid state value')
            }

            const clientId = localStorage.getItem('oAuthClientId')
            if (!clientId) throw new Error('Missing clientId value')

            localStorage.removeItem('oAuthState')
            localStorage.removeItem('oAuthClientId')

            oAuthSignIn({
                variables: {
                    authCode: code,
                    redirectUri: oAuthRedirectURI,
                    clientId,
                },
            })
        } catch (e) {
            setError(e.message)
        }
    }, [code, state, oAuthSignIn, oAuthError, oAuthErrorDescription])

    if (error) return <OAuthError error={error} />

    return <PageLoading />
}

const OAuthError: React.FC<{error: string}> = ({error}) => {
    const {replace} = useHistory()

    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <Typography.Heading>Something went wrong</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading maxWidth={461}>{error}</Typography.Subheading>
                <Spacer height={32} />
                <Button onClick={() => replace('/login')}>Return to Login</Button>
            </WizardLayout.Body>
        </WizardLayout>
    )
}
