import {
    ActionBar,
    colors,
    Form,
    HeaderLayout,
    hexToRGBObject,
    NFThemeProvider,
    paragraphFontStyle,
    Spacer,
    Typography,
    useTheme,
} from 'nf-ui'
import {luminosityContrastRatio, MIN_RATIO} from 'nf-ui/colorContrast'
import {ColorPicker} from 'nf-ui/Form/ColorPicker'
import React, {FC, useState} from 'react'
import styled from 'styled-components'

const ActionBarTest: FC<{randomColor: string}> = ({randomColor}) => {
    const theme = useTheme()
    const ratio = luminosityContrastRatio(hexToRGBObject(randomColor), hexToRGBObject(colors.white)).toFixed(2)

    return (
        <ActionBar static>
            <Typography.Paragraph bottomMargin={false} color={theme.primary.textColor}>
                Color Contrast of white text against {randomColor} background is {ratio}: 1
            </Typography.Paragraph>
        </ActionBar>
    )
}

const randomColors = [...Array(100)].map(() => {
    return '#000000'.replace(/0/g, () => {
        return (~~(Math.random() * 16)).toString(16)
    })
})

const RandomBackgroundColor: FC<{randomColor: string; threshold: number}> = ({randomColor, threshold}) => {
    return (
        <NFThemeProvider primaryColor={randomColor} threshold={threshold}>
            <ActionBarTest randomColor={randomColor} />
        </NFThemeProvider>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`
const StyledInput = styled.input.attrs((props) => ({
    type: 'number',
    step: '0.1',
    min: '1',
    max: '21',
}))`
    height: 40px;
    border-radius: 3px;
    border: 1px solid ${colors.darkGray};
    outline: 0;
    color: ${colors.black};
    box-sizing: border-box;
    transition: 0.2s border-color ease-in-out;
    padding: 0 16px;
    display: block;
    ${paragraphFontStyle}
`
export const ColorConstrast: FC = () => {
    const [color, setColor] = useState('#101010')
    const [threshold, setThreshold] = useState<number>(MIN_RATIO)

    return (
        <HeaderLayout heading="Manage Color Contrast" subheading="Use the color picker to view color contrast">
            <Container>
                <Form>
                    <Form.Group name="threshold">
                        <Form.Label>Contrast Ratio</Form.Label>
                        <StyledInput
                            value={threshold}
                            onChange={(event) => setThreshold(parseFloat(event.target.value))}
                        />
                    </Form.Group>
                    <Form.Group name="color">
                        <Form.Label>Accent color</Form.Label>
                        <ColorPicker
                            color={color}
                            value={color}
                            onChange={(value) => setColor(value)}
                            threshold={threshold}
                        />
                    </Form.Group>
                </Form>
                <Spacer width={16} />
            </Container>
            <Spacer height={8} />
            <NFThemeProvider primaryColor={color} threshold={threshold}>
                <ActionBarTest randomColor={color} />
            </NFThemeProvider>
            <Spacer height={16} />
            <Typography.Heading>List of 100 Colors with Contrast Ratios</Typography.Heading>
            <Spacer height={16} />

            {randomColors.map((randomColor) => {
                return (
                    <div>
                        <RandomBackgroundColor randomColor={randomColor} threshold={threshold} />
                        <Spacer height={8} />
                    </div>
                )
            })}
        </HeaderLayout>
    )
}
