import React from 'react'
import styled, {css} from 'styled-components'
import {colors} from '../theme'
import {Typography} from '../Typography'
import {TooltipWindowPassthroughProps} from '.'

export type TooltipPosition = 'left' | 'right' | 'top' | 'bottom'

const maxWidth = css<{maxWidth?: number; size?: TooltipWindowPassthroughProps['size']}>`
    ${(props) =>
        typeof props.maxWidth === 'number'
            ? css`
                  max-width: ${props.maxWidth}px;
                  height: auto;
                  padding: ${props.size === 'small' ? 8 : 16}px;
              `
            : css`
                  max-width: unset;
                  white-space: nowrap;
                  height: ${props.size === 'small' ? 28 : 40}px;
              `}
`

const tooltipBackgroundColor = (color: TooltipWindowPassthroughProps['color']) => {
    return color === 'primary' ? colors.primary[100] : colors.darkGray
}

export const TOOLTIP_OFFSET = 8 + 5

const Container = styled.div<Pick<TooltipWindowPassthroughProps, 'position' | 'maxWidth' | 'color' | 'size'>>`
    position: relative;
    border-radius: 2px;
    background-color: ${(props) => tooltipBackgroundColor(props.color)};
    transition: 0.3s all ease-in-out;
    display: flex;
    align-items: center;
    padding: 0 ${(props) => (props.size === 'small' ? 8 : 16)}px;
    flex-shrink: 0;

    ${maxWidth}

    ${(props) => {
        if (props.position === 'left') {
            return css`
                right: ${TOOLTIP_OFFSET}px;
            `
        } else if (props.position === 'right') {
            return css`
                left: ${TOOLTIP_OFFSET}px;
            `
        } else if (props.position === 'bottom') {
            return css`
                top: ${TOOLTIP_OFFSET}px;
            `
        } else if (props.position === 'top') {
            return css`
                bottom: ${TOOLTIP_OFFSET}px;
            `
        }
        return ''
    }}
        
    p {
        font-size: ${(props) => (props.size === 'small' ? 12 : 16)}px;
        letter-spacing: 0px;
        text-align: left;
        color: ${(props) => (props.color === 'primary' ? colors.white : colors.black)};
    }
`

const Containers: Record<TooltipPosition, typeof Container> = {
    right: styled(Container)`
        &:before {
            content: '';
            position: absolute;
            right: 100%;
            top: calc(50% - 4px);
            border-top: 4px solid transparent;
            border-right: 5px solid ${(props) => tooltipBackgroundColor(props.color)};
            border-bottom: 4px solid transparent;
        }
    `,
    left: styled(Container)`
        &:after {
            content: '';
            position: absolute;
            left: 100%;
            top: calc(50% - 4px);
            border-top: 4px solid transparent;
            border-left: 5px solid ${(props) => tooltipBackgroundColor(props.color)};
            border-bottom: 4px solid transparent;
        }
    `,
    bottom: styled(Container)`
        &:after {
            content: '';
            position: absolute;
            left: calc(50% - 4px);
            top: -4px;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            border-bottom: 5px solid ${colors.darkGray};
        }
    `,
    top: styled(Container)`
        &:after {
            content: '';
            position: absolute;
            left: calc(50% - 4px);
            bottom: -4px;
            border-left: 4px solid transparent;
            border-top: 5px solid ${(props) => tooltipBackgroundColor(props.color)};
            border-right: 4px solid transparent;
        }
    `,
}

const TooltipText = styled(Typography.Paragraph)<{maxWidth?: number}>`
    line-height: ${(props) => (typeof props.maxWidth === 'number' ? 13 : 40)}px;
`

export const Tooltip: React.FC<TooltipWindowPassthroughProps> = ({
    position,
    title,
    maxWidth,
    color,
    size = 'small',
}) => {
    const TooltipComponent = Containers[position]
    const textArray = title.split('\n')

    return (
        <TooltipComponent position={position} maxWidth={maxWidth} color={color} size={size}>
            <div>
                {textArray.map((line) =>
                    line.length ? (
                        <TooltipText maxWidth={maxWidth} bottomMargin={false}>
                            {line}
                        </TooltipText>
                    ) : (
                        <br />
                    ),
                )}
            </div>
        </TooltipComponent>
    )
}
