import {from} from 'fromfrom'
import {useState} from 'react'

export type ProfileSort = 'UNMATCHED' | 'DATE_DESC' | 'NAME_ASC' | 'DATE_ASC'

export function useProfileSort<ProfileType extends {photo?: any; fullName: string; createdAt: number}>(
    profiles: ProfileType[],
    initialSort: ProfileSort = 'DATE_DESC',
) {
    const [sort, setSort] = useState(initialSort)
    let sortedProfiles = from(profiles)
        .sortBy((profile) => profile.fullName.toLowerCase())
        .toArray()

    if (sort === 'UNMATCHED') {
        sortedProfiles = from(sortedProfiles)
            .sortBy((profile) => (profile.photo ? 1 : 0))
            .toArray()
    }
    if (sort === 'DATE_DESC') {
        sortedProfiles = from(sortedProfiles)
            .sortByDescending((profile) => profile.createdAt)
            .toArray()
    }
    if (sort === 'DATE_ASC') {
        sortedProfiles = from(sortedProfiles)
            .sortBy((profile) => profile.createdAt)
            .toArray()
    }

    return {
        profiles: sortedProfiles,
        setSort,
        sort,
    }
}
