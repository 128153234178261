import {Button, Form, Spacer, Typography} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import pluralize from 'pluralize'
import React, {useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {ModalLayout} from '~/components/ModalLayout/index'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {searchProfiles, useSendPhotoCollectionEmails} from '~/components/PhotoManagementCommon'
import {useProfileFilter} from '~/components/useProfileFilter'
import {useProfileSort} from '~/components/useProfileSort'
import {getGraphQLErrorMessage} from '~/util'
import {EmailTemplate} from './EmailTemplate'
import {Heading} from './Heading'
import {ProfilesList} from './ProfilesList'
import {useProfileSelect} from './useProfileSelect'

const FlexContainer = styled.div`
    display: flex;
`

const SearchContainer = styled.div`
    width: 268px;
`

const TabsContainer = styled.div`
    width: 560px;
`

type Profile = {
    idStr: string
    fullName: string
    photo?: any
    createdAt: number
}

export type PhotoCollectionProps = {
    profiles: Profile[]
    organisationIdStr: string
    isOnboarding: boolean
}

const PhotoCollection: React.FC<PhotoCollectionProps> = ({profiles, organisationIdStr, isOnboarding}) => {
    const {onClose} = useModalNavigator()

    const [sendEmails, {loading}] = useSendPhotoCollectionEmails()
    const profileSort = useProfileSort(profiles)
    const profileFilter = useProfileFilter(profileSort.profiles)
    const [profileSearch, setProfileSearch] = useState('')
    const searchedProfiles = searchProfiles(profileFilter.profiles, profileSearch)

    const profileSelection = useProfileSelect(searchedProfiles, profileFilter.filter)
    const checkedProfiles = profiles
        .filter((profile) => profileSelection.checkedProfiles.includes(profile.idStr))
        .map((profile) => profile.idStr)

    const alert = useAlert()
    return (
        <ModalLayout>
            <ModalLayout.Navigation />
            <ModalLayout.Body>
                <Typography.Heading>Collect photos</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading maxWidth={725}>
                    Select people below and we'll send them a short email from photos@namesandfaces.com asking them to
                    upload a photo for their profile.
                </Typography.Subheading>
                <Spacer height={32} />
                <SearchContainer>
                    <Form.Group name="search">
                        <Form.SearchInput value={profileSearch} onChange={(value) => setProfileSearch(value)} />
                    </Form.Group>
                </SearchContainer>
                <Spacer height={32} />
                <FlexContainer>
                    <TabsContainer>
                        <Heading
                            count={profileSelection.checkedProfiles.length}
                            profileFilter={profileFilter}
                            profileSort={profileSort}
                        />
                        <ProfilesList profileSelection={profileSelection} />
                    </TabsContainer>
                    <Spacer width={160} />
                    <EmailTemplate />
                </FlexContainer>
                <Spacer height={64} />
            </ModalLayout.Body>

            <ModalLayout.Footer isOnboarding={isOnboarding} back={false}>
                <Button
                    loading={loading}
                    disabled={checkedProfiles.length === 0}
                    onClick={async () => {
                        try {
                            await sendEmails({
                                variables: {
                                    organisationIdStr,
                                    profileIdStrs: checkedProfiles,
                                },
                            })
                            alert.success(
                                `${checkedProfiles.length} photo ${pluralize(
                                    'request',
                                    checkedProfiles.length,
                                    false,
                                )} successfully sent`,
                            )
                            onClose()
                        } catch (error) {
                            alert.error(getGraphQLErrorMessage(error))
                        }
                    }}
                    onClickAnalyticsEvent={'send_collect_photos_email'}
                >
                    {`Send ${checkedProfiles.length > 0 ? pluralize('email', checkedProfiles.length, true) : `emails`}`}
                    <Button.Icon icon={SvgArrowRight} />
                </Button>
            </ModalLayout.Footer>
        </ModalLayout>
    )
}

export {PhotoCollection, FlexContainer}
