import {Button, colors, Icon, List, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgDelete from 'nf-ui/Icons/Delete'
import SvgFlag from 'nf-ui/Icons/Flag'
import SvgOpenLink from 'nf-ui/Icons/OpenLink'
import React, {useState} from 'react'
import styled from 'styled-components'
import {PanelLayout, PanelLayoutButtons} from '~/components/PanelLayout'
import {PANEL_WIDTH} from '~/constants'

const OptionRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export type ScenarioOptionsProps = {
    onClose: () => void
    onViewClick: () => void
    onUpdateClick: () => void
    onDeleteClick: () => void
    onDeleteLoading: boolean
}

export const ScenarioOptions: React.FC<ScenarioOptionsProps> = ({
    onClose,
    onUpdateClick,
    onViewClick,
    onDeleteClick,
    onDeleteLoading,
}) => {
    const trackEvent = useAnalyticsEventTracker()
    const [deletePanel, setDeletePanel] = useState(false)

    const options = [
        {
            label: 'View scenario',
            icon: SvgOpenLink,
            onClick: () => {
                onViewClick()
                trackEvent('scenarios', {
                    page: 'adminland-scenarios',
                    component: 'button',
                    type: 'click',
                    action: 'view',
                    name: 'scenario_option',
                })
                onClose()
            },
        },
        {
            label: 'Edit name',
            icon: SvgFlag,
            onClick: () => {
                onUpdateClick()
                trackEvent('scenarios', {
                    page: 'adminland-scenarios',
                    component: 'button',
                    type: 'click',
                    action: 'edit_name',
                    name: 'scenario_option',
                })
                onClose()
            },
        },
        {
            label: 'Delete scenario',
            icon: SvgDelete,
            onClick: () => {
                trackEvent('scenarios', {
                    page: 'adminland-scenarios',
                    component: 'button',
                    type: 'click',
                    action: 'delete',
                    name: 'scenario_option',
                })
                setDeletePanel(true)
            },
        },
    ]

    if (deletePanel)
        return (
            <PanelLayout>
                <Typography.Label>Delete this scenario</Typography.Label>
                <Typography.Paragraph>Are you sure? This action is irreversible.</Typography.Paragraph>
                <PanelLayoutButtons>
                    <Button variant="tertiary" onClick={onClose}>
                        No, keep
                    </Button>
                    <Button color={colors.red} variant="tertiary" loading={onDeleteLoading} onClick={onDeleteClick}>
                        Yes, delete
                    </Button>
                </PanelLayoutButtons>
            </PanelLayout>
        )

    return (
        <PanelLayout width={PANEL_WIDTH}>
            <Typography.Label>Options</Typography.Label>
            <List
                width={PANEL_WIDTH}
                rows={options}
                renderRow={(option) => (
                    <OptionRow onClick={() => option.onClick()}>
                        <div>{option.label}</div>
                        <Icon size={16} icon={option.icon} />
                    </OptionRow>
                )}
            ></List>
        </PanelLayout>
    )
}
