import {List, Spinner} from 'nf-ui'
import React, {FC} from 'react'
import styled from 'styled-components'
import {PanelLayout} from '~/components/PanelLayout'
import {PANEL_WIDTH} from '~/constants'
import {useDisconnectGoogleCalendar} from './useGoogleCalender'

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

type MeetingsSettingsPanelProps = {
    onClose: () => void
}

export const MeetingsSettingsPanel: FC<MeetingsSettingsPanelProps> = ({onClose}) => {
    const {disconnectCalendar, loading} = useDisconnectGoogleCalendar()
    const settings = [
        {
            label: 'Disconnect calendar',
            onClick: async () => {
                await disconnectCalendar()
                onClose()
            },
        },
    ]

    return (
        <PanelLayout width={PANEL_WIDTH}>
            <List
                width={PANEL_WIDTH}
                rows={settings}
                renderRow={(setting) =>
                    loading ? (
                        <ActionRow>
                            <Spinner />
                        </ActionRow>
                    ) : (
                        <ActionRow
                            onClick={() => {
                                setting.onClick()
                            }}
                        >
                            <div>{setting.label}</div>
                        </ActionRow>
                    )
                }
            ></List>
        </PanelLayout>
    )
}
