import {gql, useQuery} from '@apollo/client'
import {useQueryParam} from 'use-query-params'
import {WelcomeDetails, WelcomeDetailsVariables} from './__types__/WelcomeDetails'

const WELCOME_DETAILS_QUERY = gql`
    query WelcomeDetails($userIdStr: String) {
        welcomeUserDetails(userIdStr: $userIdStr) {
            firstName
            organisationName
        }
    }
`

export function useWelcomeDetails() {
    const [userIdStr] = useQueryParam<string>('userId')
    const {data, loading, error} = useQuery<WelcomeDetails, WelcomeDetailsVariables>(WELCOME_DETAILS_QUERY, {
        variables: {userIdStr},
    })

    const name = data?.welcomeUserDetails.firstName || ''
    const company = data?.welcomeUserDetails.organisationName || 'your company'

    return {name, company, loading, error}
}
