import * as React from 'react'
const SvgChevronUp = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgChevronUp(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M8.375 6.38l2.49 2.49a.53.53 0 11-.75.75L8 7.504 5.885 9.62a.529.529 0 11-.75-.75l2.49-2.49a.53.53 0 01.75 0z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgChevronUp
