import {gql, useMutation, useQuery} from '@apollo/client'
import {Button, Form, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import pluralize from 'pluralize'
import React, {FC, useEffect, useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {ModalLayout} from '~/components/ModalLayout'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {PageLoading} from '~/components/PageLoading'
import {searchProfiles} from '~/components/PhotoManagementCommon'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {useProfileSort} from '~/components/useProfileSort'
import {DataCollectionStatus} from '~/globalTypes'
import {getGraphQLErrorMessage} from '~/util'
import {EmailTemplate} from './CollectStep/EmailTemplate'
import {ProfileListHeading} from './CollectStep/ProfileListHeading'
import {ProfilesList} from './CollectStep/ProfilesList'
import {useProfileFilter} from './CollectStep/useProfileFilter'
import {useProfileSelect} from './CollectStep/useProfileSelect'
import {
    CollectStepData,
    CollectStepDataVariables,
    CollectStepData_organisation_profiles,
} from './__types__/CollectStepData'
import {SendCollectionEmails, SendCollectionEmailsVariables} from './__types__/SendCollectionEmails'

export const COLLECT_STEP_DATA = gql`
    query CollectStepData($organisationIdStr: String!) {
        organisation(idStr: $organisationIdStr) {
            idStr
            profiles {
                idStr
                fullName
                createdAt
                dataCollectionStatus
            }
        }
    }
`
export const SEND_COLLECTION_EMAIL = gql`
    mutation SendCollectionEmails($profileIdStrs: [String!]!, $organisationIdStr: String!) {
        sendDataCollectionEmailBatch(profileIdStrs: $profileIdStrs, organisationIdStr: $organisationIdStr)
    }
`

export type Profile = CollectStepData_organisation_profiles

const SearchContainer = styled.div`
    width: 268px;
`

const FlexContainer = styled.div`
    display: flex;
`

const TabsContainer = styled.div`
    width: 560px;
`

export const CollectStep: FC = () => {
    const [profileSearch, setProfileSearch] = useState('')
    const organisationIdStr = useOrganisationIdStr()
    const {onClose} = useModalNavigator()
    const alert = useAlert()
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const {data, error, loading} = useQuery<CollectStepData, CollectStepDataVariables>(COLLECT_STEP_DATA, {
        variables: {
            organisationIdStr,
        },
    })

    const profileSort = useProfileSort(data?.organisation.profiles || [])

    const profileFilter = useProfileFilter(profileSort.profiles)
    const searchedProfiles = searchProfiles(profileFilter.profiles, profileSearch)

    const [defaultSelectedProfile, setDefaultSelectedProfile] = useQueryParam<string>('defaultSelectedProfile')
    const [sendEmails, {loading: sendingEmails}] = useMutation<SendCollectionEmails, SendCollectionEmailsVariables>(
        SEND_COLLECTION_EMAIL,
    )

    useEffect(() => {
        return () => setDefaultSelectedProfile('')
    }, [setDefaultSelectedProfile])

    const profileSelection = useProfileSelect<Profile>(
        searchedProfiles,
        defaultSelectedProfile ? [defaultSelectedProfile] : undefined,
    )

    const sendCollectionEmails = async () => {
        try {
            await sendEmails({variables: {organisationIdStr, profileIdStrs: profileSelection.checkedProfiles}})
            alert.success('Collection emails sent')
            trackAnalyticsEvent('send_data_collection_emails')
            onClose()
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    if (error) {
        return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>
    }

    if (!data || loading) return <PageLoading />

    return (
        <>
            <ModalLayout.Body>
                <Typography.Heading>Collect responses</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading maxWidth={725}>
                    Select people below to send a short email and have them fill in their data, or select skip to add
                    the data yourself
                </Typography.Subheading>
                <Spacer height={32} />
                <SearchContainer>
                    <Form.Group name="search">
                        <Form.SearchInput value={profileSearch} onChange={(value) => setProfileSearch(value)} />
                    </Form.Group>
                </SearchContainer>
                <Spacer height={32} />
                <FlexContainer>
                    <TabsContainer>
                        <ProfileListHeading
                            count={profileSelection.checkedProfiles.length}
                            profileFilter={profileFilter}
                            profileSort={profileSort}
                        />
                        <ProfilesList<Profile>
                            profileSelection={profileSelection}
                            responded={(profile) => profile.dataCollectionStatus === DataCollectionStatus.RESPONDED_ALL}
                        />
                    </TabsContainer>
                    <Spacer width={160} />
                    <EmailTemplate />
                </FlexContainer>
                <Spacer height={64} />
            </ModalLayout.Body>
            <ModalLayout.Footer>
                <Button variant="secondary" onClickAnalyticsEvent="click_skip_collect_responses" onClick={onClose}>
                    Skip
                </Button>
                {profileSelection.checkedProfiles.length > 0 && (
                    <>
                        <Spacer width={8} />
                        <Button loading={sendingEmails} variant="primary" onClick={sendCollectionEmails}>
                            Send&nbsp;{profileSelection.checkedProfiles.length}&nbsp;
                            {pluralize('email', profileSelection.checkedProfiles.length)}
                        </Button>
                    </>
                )}
            </ModalLayout.Footer>
        </>
    )
}
