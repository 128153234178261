import {Button} from 'nf-ui'
import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {playground} from '~/pages/Playground'
import {Column, Row} from '../Primitives/Layout'
import {DidntGetCodeButton, ErrorRows, ErrorState, hideError} from '../Primitives'
import {TextInputElement, VisibleInput} from '../Primitives/InputFields/VisibleInput'
import {FormLayout} from '../Primitives/Forms'

const NON_DIGIT = /[^0-9]/g
const codePart = (digitIndex: number, part: string, code: string) => {
    const newDigits = part.replace(NON_DIGIT, '')
    return `${code.slice(0, digitIndex)}${newDigits}${code.slice(digitIndex + 1)}`.slice(0, 6)
}

export const CheckYourEmail = ({
    code,
    setCode,
    errorState,
    setErrorState,
    onNext,
    onDidntGetCode,
    questionsPanelContent,
}: {
    code: string
    setCode: (value: string) => void
    errorState: ErrorState
    setErrorState: (value: ErrorState) => void
    onNext: () => Promise<void>
    onDidntGetCode: () => void
    questionsPanelContent?: ReactNode
}) => {
    const [loading, setLoading] = useState<boolean>(false)

    const refs = [
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
        useRef<TextInputElement>(null),
    ]

    const validateCode = () => {
        if (code.length < 6) {
            setErrorState({
                ...errorState,
                code: {message: 'Enter a 6 digit code', visible: true},
            })
            return false
        }
        return true
    }

    const validateAndNext = async () => {
        if (!validateCode()) return
        setLoading(true)
        try {
            await onNext()
        } finally {
            setLoading(false)
        }
    }

    const handleKeyDown = (digitIndex: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'ArrowRight') {
            event.preventDefault()
        }
        if (event.key === 'ArrowLeft') {
            event.preventDefault()
        }
        if (event.key === 'Backspace') {
            event.preventDefault()
        }
    }

    const handleKeyPress = (digitIndex: number, event: React.KeyboardEvent<HTMLInputElement>, code: string) => {
        if (event.key === 'Backspace') {
            event.preventDefault()
        }
        if (digitIndex === 5 && event.key === 'Enter') {
            validateAndNext()
        }
    }

    const handleKeyUp = (digitIndex: number, event: React.KeyboardEvent<HTMLInputElement>, code: string) => {
        if (
            event.key === 'ArrowRight' &&
            refs[digitIndex].current?.selectionStart === 0 &&
            refs[digitIndex].current?.value?.length === 1
        ) {
            refs[digitIndex].current!.selectionStart = 1
        } else if (
            event.key === 'ArrowRight' &&
            digitIndex < 5 &&
            (refs[digitIndex].current?.selectionStart !== 0 || refs[digitIndex].current?.value?.length === 0)
        ) {
            refs[digitIndex + 1].current && (refs[digitIndex + 1].current!.selectionStart = 0)
            refs[digitIndex + 1].current && (refs[digitIndex + 1].current!.selectionEnd = 0)
            refs[digitIndex + 1].current?.focus()
        }

        if (
            event.key === 'ArrowLeft' &&
            refs[digitIndex].current?.selectionStart === 1 &&
            refs[digitIndex].current?.value?.length === 1
        ) {
            refs[digitIndex].current!.selectionStart = 0
            refs[digitIndex].current!.selectionEnd = 0
        } else if (event.key === 'ArrowLeft' && digitIndex > 0 && refs[digitIndex].current?.selectionStart === 0) {
            refs[digitIndex - 1].current &&
                (refs[digitIndex - 1].current!.selectionStart = refs[digitIndex - 1].current!.value.length)
            refs[digitIndex - 1].current?.focus()
        }

        if (
            event.key === 'Backspace' &&
            refs[digitIndex].current?.selectionEnd === 1 &&
            refs[digitIndex].current?.value?.length === 1
        ) {
            hideError('code', errorState, setErrorState)
            setCode(codePart(digitIndex, '', code))
            setTimeout(() => {
                refs[digitIndex].current!.selectionStart = 0
                refs[digitIndex].current!.selectionEnd = 0
            }, 1)
        } else if (event.key === 'Backspace' && digitIndex > 0 && refs[digitIndex].current?.selectionStart === 0) {
            hideError('code', errorState, setErrorState)
            setCode(codePart(digitIndex - 1, '', code))
            setTimeout(() => {
                refs[digitIndex - 1].current && (refs[digitIndex - 1].current!.selectionStart = 0)
                refs[digitIndex - 1].current && (refs[digitIndex - 1].current!.selectionEnd = 0)
                refs[digitIndex - 1].current?.focus()
            }, 1)
        }

        if (/^[0-9]$/.test(event.key)) {
            refs[Math.min(5, code.length)].current?.focus()
        }

        if (event.ctrlKey && event.key === 'KeyV') {
            refs[Math.min(5, code.length)].current?.focus()
        }
    }

    useEffect(() => {
        if (code.length === 0) {
            refs[0].current?.focus()
        }
        if (code.length === 6) {
            validateAndNext()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, refs[0]])

    return (
        <FormLayout
            heading="Check your email"
            subHeading="Enter the six digit code we've just sent you."
            progress={0}
            questionsPanelContent={questionsPanelContent}
            onNext={validateAndNext}
        >
            <Column width="100%">
                <Row width="100%" alignLeft>
                    <Column>
                        <Row>
                            {refs.map((ref, index) => (
                                <Row key={`input-${index}`} grow={0} shrink={1}>
                                    <VisibleInput
                                        ref={ref}
                                        type="text"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        textAlign="center"
                                        width="100%"
                                        maxWidth="49px"
                                        padding="14px 0px"
                                        value={code.slice(index, index + 1)}
                                        scrollMarginBottom={58}
                                        onChange={(ev) => {
                                            hideError('code', errorState, setErrorState)
                                            setCode(codePart(index, ev.target.value, code))
                                        }}
                                        onKeyDown={(ev) => handleKeyDown(index, ev)}
                                        onKeyPress={(ev) => handleKeyPress(index, ev, code)}
                                        onKeyUp={(ev) => handleKeyUp(index, ev, code)}
                                    ></VisibleInput>
                                    {index < 5 && <Column grow={0} shrink={1} width="10px" />}
                                </Row>
                            ))}
                        </Row>
                        <ErrorRows errorRecord={errorState.code}></ErrorRows>
                    </Column>
                    <Column grow={0} shrink={1} width="10px" deviceWidths={['Desktop', 'Tablet']} />
                    <Column grow={0} shrink={1} deviceWidths={['Desktop', 'Tablet']}>
                        <Button
                            style={{padding: '0px 24px', borderRadius: '5px', height: '48px'}}
                            onClick={validateAndNext}
                            loading={loading}
                        >
                            Next
                        </Button>
                    </Column>
                </Row>
                <Row height="10px" deviceWidths={['Phone', 'Small']}></Row>
                <Row deviceWidths={['Phone', 'Small']}>
                    <Button
                        style={{
                            padding: '0px 24px',
                            borderRadius: '5px',
                            height: '48px',
                            maxWidth: '345px',
                            width: '100%',
                        }}
                        onClick={validateAndNext}
                        loading={loading}
                    >
                        Next
                    </Button>
                    <Column></Column>
                </Row>
                <Row height="20px"></Row>
                <Row width="100%">
                    <Column width="330px" shrink={1} grow={0} deviceWidths={['Desktop', 'Tablet']}></Column>
                    <Column width="240px" shrink={1} grow={0} deviceWidths={['Phone', 'Small']}></Column>
                    <Column shrink={0}>
                        <DidntGetCodeButton onClick={onDidntGetCode}>Didn't get the code?</DidntGetCodeButton>
                    </Column>
                    <Column shrink={1} grow={1}></Column>
                </Row>
            </Column>
        </FormLayout>
    )
}

playground.push({
    path: 'src/components/Primitives/CheckYourEmail.tsx',
    component: CheckYourEmail,
    props: {directoryName: 'Webpups Grade 3', code: '', errorState: {}},
    propOptions: {
        code: {
            get: (props: any) => props.code || '',
        },
        setCode: ({props, args}: {props: any; args: any[]}) => ({...props, code: args[0]}),
        errorState: {
            get: (props: any) => JSON.stringify(props.errorState),
        },
        setErrorState: ({props, args}: {props: any; args: any[]}) => ({...props, errorState: args[0]}),
        onNext: () => {},
        onDidntGetCode: () => {},
    },
})
