import React, {InputHTMLAttributes} from 'react'
import styled from 'styled-components'
import {useGroupContext} from './Group'
import {colors} from '../theme'
import {Typography} from '../Typography'
import {Spacer} from '../Spacer'

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    labelYes?: string
    labelNo?: string
    fullWidth?: boolean
    invertValue?: boolean
    onClick?: () => void
}

const Container = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`

const SwitchContainer = styled.div<{checked: boolean}>`
    width: 90px;
    height: 40px;
    border-radius: 3px;
    vertical-align: middle;
    position: relative;
    box-sizing: border-box;
    padding: 2px;
    cursor: pointer;
    background-color: ${({checked}) => (checked ? colors.green[100] : colors.darkGray)};
    transition: 0.25s box-shadow ease-in-out, 0.25s background-color ease-in-out;

    &:hover {
        box-shadow: 0 0 0 3px ${({checked}) => (checked ? colors.green[35] : colors.lightGray)};
        background-color: ${({checked}) => (checked ? colors.darkGreen : colors.darkGray2)};
    }

    &:focus-within {
        box-shadow: 0 0 0 3px ${({checked}) => (checked ? colors.green[35] : colors.lightGray)};
    }
`

const SwitchLabel = styled.span<{checked: boolean}>`
    font-family: 'halyard-display';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: ${() => colors.white};
    position: absolute;
    top: calc(50% - 24px / 2);
    left: 11px;
    transform: translate3d(${({checked}) => (checked ? 0 : 46)}px, 0, 0);
`

const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: -2px;
    left: -2px;
    opacity: 0;
    margin: 0;
    cursor: pointer;
`

const Circle = styled.div<{checked: boolean}>`
    position: absolute;
    top: calc(50% - 36px / 2);
    width: 41px;
    height: 36px;
    background: ${colors.white};
    border-radius: 2px;
    transform: translate3d(${({checked}) => (checked ? 45 : 0)}px, 0, 0);
    transition: 0.25s transform ease-in-out;
`

export const Switch = React.forwardRef<HTMLLabelElement, SwitchProps>(
    ({label, labelYes, labelNo, checked = false, type, fullWidth = false, disabled, ...props}, ref) => {
        const {name} = useGroupContext()
        return (
            <Container ref={ref} style={{pointerEvents: disabled ? 'none' : 'initial'}}>
                <SwitchContainer checked={checked} style={{opacity: disabled ? 0.5 : 1}}>
                    <SwitchLabel checked={checked}>{checked ? labelYes || 'Yes' : labelNo || 'No'}</SwitchLabel>
                    <HiddenCheckbox name={name} checked={checked} disabled={disabled} {...props} />
                    <Circle checked={checked} />
                </SwitchContainer>
                {label && (
                    <>
                        <Spacer width={16} />
                        <Typography.Label>{label}</Typography.Label>
                    </>
                )}
            </Container>
        )
    },
)
