import {useMemo} from 'react'
import {useQueryParam} from 'use-query-params'
import {Profile} from '../../useFullOrgChartData'
import {useFullOrgChart} from '../FullOrgChartContext'
import {matchesSearchTerm, matchesCategory} from '../profileSearch'
import {useOrgChartData} from '../useOrgChartData'

export const useHighlightProfile = (profile: Profile) => {
    const {categoryProfiles} = useOrgChartData()
    const [categoryIdStr] = useQueryParam<string>('categoryId')
    const {searchTerm} = useFullOrgChart()

    const [matchesSearch, categoryMatch] = useMemo(() => {
        return [matchesSearchTerm(profile, searchTerm), matchesCategory({profile, categoryIdStr, categoryProfiles})]
    }, [categoryIdStr, profile, searchTerm, categoryProfiles])

    const highlighted = categoryIdStr && searchTerm ? matchesSearch && categoryMatch : matchesSearch || categoryMatch

    return {
        highlighted,
        profilesFiltered: !!(searchTerm || categoryIdStr),
    }
}
