import {gql, useMutation} from '@apollo/client'
import {Button} from 'nf-ui'
import React from 'react'
import {useAlert} from 'react-alert'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {getGraphQLErrorMessage} from '~/util'
import {DownloadOrganisationData, DownloadOrganisationDataVariables} from './__types__/DownloadOrganisationData'

const DOWNLOAD_DATA = gql`
    mutation DownloadOrganisationData($organisationIdStr: String!) {
        dataExport(organisationIdStr: $organisationIdStr)
    }
`

export const DownloadDataButton = () => {
    const alert = useAlert()
    const organisationIdStr = useOrganisationIdStr()

    const [downloadData, {loading}] = useMutation<DownloadOrganisationData, DownloadOrganisationDataVariables>(
        DOWNLOAD_DATA,
        {
            variables: {organisationIdStr},
            onError: (error) => {
                alert.error(getGraphQLErrorMessage(error))
                throw error
            },
        },
    )

    return (
        <Button
            loading={loading}
            variant="primary"
            onClick={async () => {
                const result = await downloadData()
                const url = result.data?.dataExport
                if (url) window.open(url, '_blank')
            }}
        >
            Download data
        </Button>
    )
}
