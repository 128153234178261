import {Form, Icon, List, Panel, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgCrop from 'nf-ui/Icons/Crop'
import SvgDelete from 'nf-ui/Icons/Delete'
import SvgLink from 'nf-ui/Icons/Link'
import SvgUnlink from 'nf-ui/Icons/Unlink'
import SvgUser from 'nf-ui/Icons/User'
import React, {useRef, useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {PanelLayout, PanelLayoutMenuItem} from '~/components/PanelLayout'
import {PanelHeader, usePanelNavigator, usePanelStore} from '~/components/PanelNavigator'
import {Photo as BasePhoto} from '~/components/Photo/Photo'
import {
    DeletePhotoMutationUpdater,
    searchProfiles,
    sortProfiles,
    useApprovePhotos,
    useDeletePhoto,
    useLinkPhoto,
    useUnlinkPhoto,
} from '~/components/PhotoManagementCommon'
import {PANEL_WIDTH} from '~/constants'
import {PhotoCroppingState} from '~/pages/Adminland/PhotoCroppingModal'
import {getGraphQLErrorMessage} from '~/util'
import {useSinglePhotoSelection} from '../Photo/utils'
import {ProfileListPhoto} from '../ProfileListPhoto'
import {Photo, Profile} from './AdminPhotosList'
import {DeletePanel} from './DeletePanel'
import {orderPhotos} from '~/components/OrganisationContext'

interface PhotoProps {
    photo: Photo
    profiles: Profile[]
    search: string
    deletePhotoUpdate: DeletePhotoMutationUpdater
    startCropAndRotatePhotos: (state: PhotoCroppingState) => void
    style?: React.CSSProperties
}

const ProfileContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: -16px;
    padding: 12px 16px;
    height: 32px;
`

const FullNameContainer = styled(Typography.Paragraph)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
`

const MatchPanel: React.FC<{
    profiles: Profile[]
    photoIdStr: string
    linkPhoto: ReturnType<typeof useLinkPhoto>[0]
}> = ({profiles, photoIdStr, linkPhoto}) => {
    const panelStore = usePanelStore()
    const closePanel = panelStore.useStore((state) => state.closePanel)

    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const [profileSearch, setProfileSearch] = useState('')
    const filteredProfiles = searchProfiles(sortProfiles(profiles), profileSearch)

    const alert = useAlert()

    return (
        <PanelLayout>
            <PanelHeader panel="match">Match this photo to</PanelHeader>
            <Form.Group name="search">
                <Form.SearchInput onChange={setProfileSearch} autoFocus value={profileSearch} />
            </Form.Group>
            <List
                rows={filteredProfiles}
                renderRow={(row) => (
                    <ProfileContainer>
                        <FullNameContainer bottomMargin={false}>{row.fullName}</FullNameContainer>
                        {row.photos?.[0] && <ProfileListPhoto photo={orderPhotos(row.photos)[0]} check={false} />}
                    </ProfileContainer>
                )}
                width={PANEL_WIDTH}
                onClick={async (profile) => {
                    try {
                        closePanel()

                        trackAnalyticsEvent('select_match_photo')
                        await linkPhoto({
                            variables: {
                                profileIdStr: profile.idStr,
                                photoIdStr,
                            },
                        })
                    } catch (error) {
                        alert.error(getGraphQLErrorMessage(error))
                    }
                }}
            />
        </PanelLayout>
    )
}

const AdminPhoto: React.FC<PhotoProps> = ({
    photo,
    profiles,
    deletePhotoUpdate,
    startCropAndRotatePhotos,
    search,
    style,
}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const {openPanel, closePanel, panelProps, panel} = usePanelNavigator(photo.idStr)

    const [unlinkPhoto, {loading: unlinkLoading}] = useUnlinkPhoto()

    const [deletePhoto, {loading: deleteLoading}] = useDeletePhoto({photoIdStr: photo.idStr, update: deletePhotoUpdate})

    const [linkPhoto, {loading: linkLoading}] = useLinkPhoto()

    const [approvePhotos, {loading: approveLoading}] = useApprovePhotos({photoIdStrs: [photo.idStr]})

    const targetRef = useRef<HTMLButtonElement>(null)

    const {selected, setSingleSelectedPhoto} = useSinglePhotoSelection(photo.idStr)

    const showSpinner = deleteLoading || unlinkLoading || linkLoading || approveLoading

    const alert = useAlert()

    const options = []

    if (photo.profile) {
        options.push({
            id: 'unmatch',
            label: 'Unmatch this photo',
            onClick: async () => {
                try {
                    closePanel()

                    await unlinkPhoto({
                        variables: {
                            photoIdStr: photo.idStr,
                        },
                    })
                } catch (error) {
                    alert.error(getGraphQLErrorMessage(error))
                }
            },
            icon: <Icon icon={SvgUnlink} />,
            analyticsEvent: 'select_unmatch_photo',
        })
        options.push({
            id: 'delete',
            label: 'Delete this photo',
            icon: <Icon icon={SvgDelete} />,
            analyticsEvent: 'select_delete_photo',
        })
    } else if (photo.pendingProfile) {
        options.push({
            id: 'approve',
            label: 'Approve this photo',
            onClick: async () => {
                try {
                    closePanel()
                    await approvePhotos()
                } catch (error) {
                    alert.error(getGraphQLErrorMessage(error))
                }
            },
            icon: <Icon icon={SvgUser} />,
            analyticsEvent: 'select_approve_photo',
        })
        options.push({
            id: 'reject',
            label: 'Reject and delete this photo',
            icon: <Icon icon={SvgDelete} />,
            analyticsEvent: 'select_reject_delete_photo',
        })
    } else {
        options.push({
            id: 'match',
            label: 'Match this photo',
            icon: <Icon icon={SvgLink} />,
            analyticsEvent: 'select_match_photo',
        })
        options.push({
            id: 'delete',
            label: 'Delete this photo',
            icon: <Icon icon={SvgDelete} />,
            analyticsEvent: 'select_delete_photo',
        })
    }

    if (startCropAndRotatePhotos) {
        options.push({
            id: 'crop-photo',
            label: 'Crop & Rotate',
            icon: <Icon icon={SvgCrop} />,
            onClick: () => {
                closePanel()
                startCropAndRotatePhotos({
                    currentIndex: 0,
                    selection: [{photoIdStr: photo.idStr, originalUrl: photo.originalUrl}],
                })
            },
            analyticsEvent: 'select_crop_and_rotate',
        })
    }

    const optionsPanel = (
        <PanelLayout key="options">
            <PanelHeader panel="options">What would you like to do?</PanelHeader>
            <List
                rows={options}
                renderRow={(row) => (
                    <PanelLayoutMenuItem>
                        {row.label}
                        {row.icon}
                    </PanelLayoutMenuItem>
                )}
                width={PANEL_WIDTH}
                onClick={(item) => {
                    trackAnalyticsEvent(item.analyticsEvent)

                    if (item.onClick) {
                        item.onClick()
                    } else {
                        openPanel(item.id, {
                            itemId: photo.idStr,
                        })
                    }
                }}
            />
        </PanelLayout>
    )

    const matchPanel = <MatchPanel linkPhoto={linkPhoto} key="match" photoIdStr={photo.idStr} profiles={profiles} />

    const deletePanel = <DeletePanel deletePhoto={deletePhoto} key="delete" />

    let panelElement = null
    if (panel === 'options') panelElement = optionsPanel
    else if (panel === 'match') panelElement = matchPanel
    else if (panel === 'delete') panelElement = deletePanel
    else if (panel === 'reject') panelElement = deletePanel

    return (
        <>
            <div style={style}>
                <BasePhoto
                    ref={targetRef}
                    photo={photo}
                    showSpinner={showSpinner}
                    onClick={() => {
                        openPanel('options', {
                            resetHistory: true,
                            itemId: photo.idStr,
                        })
                        setSingleSelectedPhoto(photo.idStr)
                    }}
                    onClickMatch={() => {
                        openPanel('match', {
                            resetHistory: true,
                            itemId: photo.idStr,
                        })
                    }}
                    onClickApprove={approvePhotos}
                    search={search}
                    selected={selected}
                />
            </div>
            <Panel targetRef={targetRef} {...panelProps}>
                {panelElement}
            </Panel>
        </>
    )
}

export {AdminPhoto}
