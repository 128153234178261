import {colors, FileInput, Icon, Spinner, Typography} from 'nf-ui'
import {ButtonContainer} from 'nf-ui/Button/Button'
import SvgAdd from 'nf-ui/Icons/Add'
import React, {InputHTMLAttributes, useMemo} from 'react'
import styled from 'styled-components'
import {validImageMimeTypes} from '~/util/validImages'

const HEIGHT = 100
const LOGO_PADDING = 24
const BUTTON_HEIGHT = 40
const LOGO_HOVER_SCALE = (HEIGHT - LOGO_PADDING * 2 - BUTTON_HEIGHT) / (HEIGHT - LOGO_PADDING * 2)

const Container = styled.div`
    width: 240px;
    height: ${HEIGHT}px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
`

const NoLogoContainer = styled(Container)`
    background-color: ${colors.primary[10]};
    border: 1px dashed ${colors.primary[100]};
`

const UploadNew = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    ${ButtonContainer} {
        width: 100%;
    }
`

const LogoPreview = styled.div`
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: ${LOGO_PADDING}px;
    left: ${LOGO_PADDING}px;
    bottom: ${LOGO_PADDING}px;
    right: ${LOGO_PADDING}px;
`

const LogoContainer = styled(Container)`
    background-color: ${colors.white};
    padding: 32px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);

    ${UploadNew} {
        opacity: 0;
        transition: 0.25s opacity ease-in-out;
    }

    ${LogoPreview} {
        transition: 0.25s transform ease-in-out;
        transform-origin: top;
    }

    :hover {
        ${LogoPreview} {
            transform: scale3d(${LOGO_HOVER_SCALE}, ${LOGO_HOVER_SCALE}, 1);
        }
        ${UploadNew} {
            opacity: 1;
        }
    }
`

const Input = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    outline: 0;
    opacity: 0;
`

interface LogoFileInputProps extends InputHTMLAttributes<HTMLInputElement> {
    logo: File | string | null
    loading?: boolean
}

export const LogoFileInput: React.FC<LogoFileInputProps> = ({logo, loading = false, ...props}) => {
    const logoUrl = useMemo(() => {
        if (!logo) return ''
        if (typeof logo === 'string') return logo
        return window.URL.createObjectURL(logo)
    }, [logo])

    if (loading) {
        return (
            <NoLogoContainer>
                <Spinner size={12} />
            </NoLogoContainer>
        )
    }

    if (logo) {
        return (
            <LogoContainer>
                <LogoPreview style={{backgroundImage: `url('${logoUrl}')`}} />
                <UploadNew>
                    <FileInput buttonProps={{variant: 'secondary'}} accept={validImageMimeTypes} {...props}>
                        Upload New <FileInput.Icon icon={SvgAdd} />
                    </FileInput>
                </UploadNew>
            </LogoContainer>
        )
    }

    return (
        <NoLogoContainer>
            <Icon icon={SvgAdd} tint={colors.primary[100]} />
            <Typography.Label color="primary">Upload logo</Typography.Label>
            <Input type="file" accept={validImageMimeTypes} {...props} />
        </NoLogoContainer>
    )
}
