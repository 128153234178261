import * as React from 'react'
const SvgAdditionalDataRegular = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
    function SvgAdditionalDataRegular(props, svgRef) {
        return (
            <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
                <path
                    d="M8.51 1.072C8.333.304 7.497-.154 6.65.048L6.501.09 1.009 2.03C.43 2.218.06 2.67.007 3.222L0 3.361v9.925c0 .61.396 1.144.958 1.34l.133.04 5.591 1.291c.849.188 1.653-.265 1.902-1.022l.038-.137.079-.34 1.588.516c.96.29 1.927-.312 2.013-1.218l.006-.132v-.808l1.775.486c.95.256 1.833-.333 1.911-1.232l.006-.13V3.92c0-.927-.84-1.57-1.78-1.393l-.136.031-1.776.487v-.81c0-.932-.923-1.59-1.881-1.384l-.137.035-1.703.518-.077-.333zM7.385.985v13.98l-6.266-1.446V3.156L7.385.986zm3.692.83v12.23l-2.1-.648h-.362V2.464h.362l2.1-.649zm3.804 1.702v8.827l-2.573-.698v-7.43l2.573-.7z"
                    fill="#101010"
                    fillRule="evenodd"
                />
            </svg>
        )
    },
)
export default SvgAdditionalDataRegular
