import * as React from 'react'
const SvgCaret = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgCaret(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M4.817 6.062a.98.98 0 000 1.386l2.49 2.49a.979.979 0 001.386 0l2.49-2.49a.98.98 0 000-1.386l-.092-.082a.98.98 0 00-1.294.082L8 7.858 6.203 6.062a.98.98 0 00-1.386 0z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgCaret
