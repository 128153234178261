import {colors, Icon, List, Spacer, Typography} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React from 'react'
import styled from 'styled-components'
import {BackButton} from '~/components/BackButton'
import {useOpenAnalytics} from '~/components/useOpenAnalytics'
import {SignInMethodIcon, SignInMethodType} from '~/globalTypes'
import {SignInOption} from '../Login'
import {Login_SignInOptions_signInOptions} from '../__types__/Login_SignInOptions'

const Option = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const OptionIcon = styled.img`
    width: 22px;
    height: 22px;
    display: block;
`

const Icons: Record<SignInMethodIcon, string> = {
    [SignInMethodIcon.OKTA]: require('./OKTA.png'),
    [SignInMethodIcon.AZURE]: require('./AZURE.png'),
    [SignInMethodIcon.ADP]: require('./ADP.png'),
}

type AvailableSignInOptions =
    | SignInMethodType.NF_AUTH_MAGIC_LINK
    | SignInMethodType.NF_AUTH_PASSWORD
    | SignInMethodType.OAUTH
    | SignInMethodType.SET_PASSWORD
    | SignInMethodType.OTP

const SIGN_IN_OPTIONS: Record<
    AvailableSignInOptions,
    {
        label: (option: Login_SignInOptions_signInOptions) => string
        event: (option: Login_SignInOptions_signInOptions) => string
    }
> = {
    [SignInMethodType.NF_AUTH_MAGIC_LINK]: {
        label: () => 'Sign in instantly with a Magic Link',
        event: () => 'tap_sign_in_email',
    },
    [SignInMethodType.NF_AUTH_PASSWORD]: {
        label: () => 'Sign in with your password',
        event: () => 'tap_sign_in_password',
    },
    [SignInMethodType.SET_PASSWORD]: {
        label: () => 'Set password and sign in',
        event: () => 'tap_set_password',
    },
    [SignInMethodType.OTP]: {
        label: () => 'Sign in instantly with email code',
        event: () => 'tap_sign_in_otp',
    },
    [SignInMethodType.OAUTH]: {
        label: (option: Login_SignInOptions_signInOptions) => `Sign in with ${option.name}`,
        event: (option: Login_SignInOptions_signInOptions) => {
            if (option.icon === SignInMethodIcon.AZURE) return 'tap_sign_in_active_directory'
            if (option.icon === SignInMethodIcon.OKTA) return 'tap_sign_in_okta'
            return 'untracked_sign_in_option'
        },
    },
}

const getAvailableOptions = (options: Login_SignInOptions_signInOptions[]): AvailableOption[] => {
    return options
        .filter((option) => Object.keys(SIGN_IN_OPTIONS).includes(option.type))
        .map((option) => {
            const signInOption = SIGN_IN_OPTIONS[option.type as AvailableSignInOptions]

            return {
                label: signInOption.label(option),
                event: signInOption.event(option),
                icon: option.icon ? Icons[option.icon] : null,
                option,
            }
        })
}

type AvailableOption = {
    label: string
    event: string
    icon: string | null
    option: SignInOption
}

export const SignInOptions: React.FC<{
    options: SignInOption[]
    onClickBack: () => void
    onClickOption: (option: SignInOption) => void
}> = ({options, onClickBack, onClickOption}) => {
    const {trackEvent} = useOpenAnalytics()
    const availableOptions = getAvailableOptions(options)

    const onClick = ({option, event}: AvailableOption) => {
        trackEvent('access', event)

        onClickOption(option)
    }

    return (
        <>
            <Typography.Heading>
                <BackButton onClick={onClickBack} />
                Hi
            </Typography.Heading>
            <Spacer height={32} />
            <List
                rows={availableOptions}
                width={304}
                onClick={onClick}
                renderRow={(option) => {
                    return (
                        <Option>
                            {option.label}
                            {option.icon ? (
                                <OptionIcon src={option.icon} />
                            ) : (
                                <Icon icon={SvgArrowRight} tint={colors.primary[100]} />
                            )}
                        </Option>
                    )
                }}
            />
        </>
    )
}
