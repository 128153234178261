import React, {useState} from 'react'
import {
    Button,
    Columns,
    Form,
    HeaderLayout,
    Icon,
    MultiColumnList,
    Spacer,
    TooltipTarget,
    Typography,
    useAnalyticsEventTracker,
} from 'nf-ui'
import styled from 'styled-components'
import AutoSizer from 'react-virtualized-auto-sizer'
import SvgCaret from 'nf-ui/Icons/Caret'
import {ScenarioOptions} from './Scenarios/ScenarioOptions'
import {CreateScenarioLightbox} from './Scenarios/CreateScenarioLightbox'
import {PageLoading} from '~/components/PageLoading'
import {gql, useQuery} from '@apollo/client'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {ScenariosPageData, ScenariosPageDataVariables, ScenariosPageData_scenarios} from './__types__/ScenariosPageData'
import {UpdateScenarioLightbox} from './Scenarios/UpdateScenarioLightbox'
import {useHistory} from 'react-router-dom'
import {useDeleteScenario} from './Scenarios/useDeleteScenario'
import dateFormat from 'dateformat'
import SvgAlert from 'nf-ui/Icons/Alert'

export const SCENARIOS_PAGE_DATA = gql`
    query ScenariosPageData($organisationIdStr: String!) {
        scenarios(organisationIdStr: $organisationIdStr) {
            idStr
            name
            owner {
                idStr
                fullName
            }
            createdAt
            updatedAt
        }
    }
`

const Layout = styled(HeaderLayout)`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100vh;
    padding-bottom: 0;
`

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const BottomNotice = styled(FlexContainer)`
    padding-bottom: 24px;
`

const IndicatorIcon = styled(Icon)`
    transform: translateY(10px);
`

const columns: Columns<ScenariosPageData_scenarios> = [
    {
        name: 'Name',
        width: 220,
        value: (scenario) => scenario.name,
        highlight: true,
        path: (scenario) => `/${scenario.idStr}/org-chart/full`,
        linkType: 'a',
    },
    {
        name: 'Owner',
        width: 140,
        value: (scenario) => scenario.owner?.fullName || '',
        highlight: true,
    },
    {
        name: 'Created',
        width: 200,
        value: (scenario) => dateFormat(scenario.createdAt, 'd mmmm yyyy'),
        highlight: true,
    },
    {
        name: 'Last updated',
        width: 200,
        value: (scenario) => dateFormat(scenario.updatedAt, 'd mmmm yyyy'),
        highlight: true,
    },
]

export const Scenarios: React.FC = () => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const {push} = useHistory()
    const organisationIdStr = useOrganisationIdStr()

    const [search, setSearch] = useState('')
    const [lightbox, setLightbox] = useState<'createScenario' | 'updateScenario' | null>(null)
    const [selectedScenario, setSelectedScenario] = useState<ScenariosPageData_scenarios | null>(null)

    const {deleteScenario, deleteScenarioLoading} = useDeleteScenario()

    const {data, loading} = useQuery<ScenariosPageData, ScenariosPageDataVariables>(SCENARIOS_PAGE_DATA, {
        variables: {organisationIdStr},
    })

    if (!data || loading) return <PageLoading />

    const scenarios = data.scenarios

    return (
        <Layout
            heading={
                <FlexContainer>
                    <Typography.Heading>Scenarios</Typography.Heading>
                    <Spacer width={8} />
                    <TooltipTarget title="Only Admins are able to access scenarios" position="right" maxWidth={220}>
                        <IndicatorIcon icon={SvgAlert} />
                    </TooltipTarget>
                </FlexContainer>
            }
            subheading="Manage the different scenarios of your org chart. (Admin only)"
            rightContent={
                <>
                    <Form.Group name="search" style={{flex: 1, maxWidth: 304, minWidth: 0}}>
                        <Form.SearchInput
                            width="100%"
                            onChange={setSearch}
                            onFocus={() => trackAnalyticsEvent('search_scenarios')}
                            value={search}
                        />
                    </Form.Group>
                    <Spacer width={16} />
                    <Button
                        variant="primary"
                        onClick={() => setLightbox('createScenario')}
                        onClickAnalyticsEvent={'scenarios'}
                        onClickAnalyticsData={{
                            page: 'adminland-scenarios',
                            component: 'button',
                            type: 'click',
                            action: 'open',
                            name: 'create_scenario_lightbox',
                        }}
                    >
                        Create new scenario
                    </Button>
                    <CreateScenarioLightbox
                        open={lightbox === 'createScenario'}
                        onClose={() => setLightbox(null)}
                        organisationIdStr={organisationIdStr}
                    />
                    {selectedScenario && (
                        <UpdateScenarioLightbox
                            open={lightbox === 'updateScenario'}
                            onClose={() => setLightbox(null)}
                            scenario={selectedScenario}
                        />
                    )}
                </>
            }
        >
            <Spacer height={16} />
            <div style={{flex: 1}}>
                <AutoSizer>
                    {({width, height}) => (
                        <MultiColumnList
                            width={width}
                            maxHeight={height}
                            minHeight={600}
                            rows={scenarios}
                            columns={columns}
                            highlightValue={search}
                            actions={{
                                button: {
                                    icon: SvgCaret,
                                    iconPosition: 'right',
                                    label: 'Options',
                                },
                                visible: () => true,
                                panel: (scenario, onClose) => {
                                    return (
                                        <ScenarioOptions
                                            onViewClick={() => push(`/${scenario.idStr}/org-chart/full`)}
                                            onUpdateClick={() => {
                                                setSelectedScenario(scenario)
                                                setLightbox('updateScenario')
                                            }}
                                            onDeleteClick={() => deleteScenario(scenario.idStr)}
                                            onDeleteLoading={deleteScenarioLoading}
                                            onClose={onClose}
                                        />
                                    )
                                },
                            }}
                        />
                    )}
                </AutoSizer>
            </div>
            <BottomNotice>
                <Icon icon={SvgAlert} />
                <Spacer width={16} />
                <Typography.Paragraph bottomMargin={false}>
                    Only Admins are able to access scenarios
                </Typography.Paragraph>
            </BottomNotice>
        </Layout>
    )
}
