import {gql, useQuery} from '@apollo/client'
import produce from 'immer'
import {useState, useReducer, Reducer, Dispatch} from 'react'
import {createContainer} from 'unstated-next'
import {FieldType} from '~/globalTypes'
import {SelectedColumn} from '~/objectTypes'
import {DataSourceData, getCurrentIndex} from './ConfigureDataSourceModal'
import {GetAvailableFieldTypes} from './__types__/GetAvailableFieldTypes'

const GET_AVAILABLE_FIELDS = gql`
    query GetAvailableFieldTypes {
        availableFieldTypes {
            name
            type
            named
        }
    }
`

export type Step = 'column headings' | 'finish' | string

/** Props to be passed to the Step components. */
export type StepProps = {
    state: State
    dispatch: Dispatch<Action>
    onClickCancel?: () => void
}

/** Top level steps that will appear in the stepper. */
export type TopLevelStepLabels = [string, Step[]][]

export type StepField = {
    idStr: string
    name: string
    required: boolean
}

export type Action =
    | {
          type: 'SET_SHEET'
          dataSourceIdStr?: string
          dataSetIdStr?: string
      }
    | {
          type: 'SET_STEP_FIELDS'
          stepFields: StepField[]
      }
    | {
          type: 'FIRST_FIELD'
      }
    | {
          type: 'NEXT_FIELD'
      }
    | {
          type: 'PREVIOUS_FIELD'
      }
    | {
          type: 'SKIP_FIELD'
      }
    | {
          type: 'SET_TOP_LEVEL_STEPS'
          topLevelStepLabels: TopLevelStepLabels
      }
    | {
          type: 'SET_FIELD_COLUMN_MAPPING'
          column: string
          stepField: StepField
      }
    | {
          type: 'SET_COMPLETED'
      }
    | {
          type: 'SET_SELECTED_COLUMN'
          column: string
          fieldName?: string
          fieldType?: FieldType
      }

export type State = {
    dataSourceData: DataSourceData
    allSteps: StepField[]
    topLevelStepLabels: TopLevelStepLabels
    currentColumn: StepField | null
    mappedColumns: Record<string, string>
    selectedColumns: Record<string, SelectedColumn>
    completed: boolean
    primary: boolean
}

/**
 * Returns the top-level step index.
 */
const getTopLevelStepIndex = (
    topLevelStepLabels: TopLevelStepLabels,
    currentColumn: StepField | null,
    completed: boolean,
) => {
    if (!currentColumn) {
        return 0
    }
    if (completed) {
        return topLevelStepLabels.length - 1
    }
    return topLevelStepLabels.findIndex(
        (step) => step[1].filter((substep) => currentColumn.name.includes(substep)).length > 0,
    )
}

const reducer: Reducer<State, Action> = (state, action) => {
    return produce(state, (draftState) => {
        switch (action.type) {
            case 'SET_STEP_FIELDS': {
                draftState.allSteps = action.stepFields
                break
            }

            case 'FIRST_FIELD': {
                draftState.currentColumn = draftState.allSteps[0]
                draftState.mappedColumns = {}
                draftState.completed = false
                break
            }

            case 'NEXT_FIELD': {
                //on column heading step
                if (!draftState.currentColumn) {
                    draftState.currentColumn = draftState.allSteps[0]
                } else {
                    const stepIndex = getCurrentIndex(draftState.allSteps, draftState.currentColumn)
                    const nextStep = draftState.allSteps[stepIndex + 1]
                    if (nextStep) {
                        draftState.currentColumn = nextStep
                        draftState.completed = false
                    } else {
                        draftState.completed = true
                    }
                }
                break
            }

            case 'PREVIOUS_FIELD': {
                const stepIndex = getCurrentIndex(draftState.allSteps, draftState.currentColumn)
                const previousStep = draftState.allSteps[stepIndex - 1]
                if (previousStep) {
                    draftState.currentColumn = previousStep
                    delete draftState.mappedColumns[previousStep.idStr]
                } else {
                    draftState.currentColumn = null //null is the pre-step 'column headings'
                }
                draftState.completed = false
                break
            }

            case 'SKIP_FIELD': {
                const stepIndex = getCurrentIndex(draftState.allSteps, draftState.currentColumn)
                const nextStep = draftState.allSteps[stepIndex + 2]
                if (nextStep) {
                    draftState.currentColumn = nextStep
                    draftState.completed = false
                } else {
                    draftState.completed = true
                }
                break
            }
            case 'SET_TOP_LEVEL_STEPS': {
                draftState.topLevelStepLabels = action.topLevelStepLabels
                break
            }
            case 'SET_FIELD_COLUMN_MAPPING': {
                draftState.mappedColumns[action.stepField.idStr] = action.column?.trim()
                break
            }
            case 'SET_COMPLETED': {
                draftState.completed = true
                break
            }
            case 'SET_SELECTED_COLUMN': {
                const columnName = action.column?.trim()
                const fieldName = action.fieldName || columnName
                const type = action.fieldType || null

                draftState.selectedColumns[columnName] = {columnName, fieldName, type}
                break
            }
        }
    })
}

const useAvailableFieldTypes = () => {
    const {data} = useQuery<GetAvailableFieldTypes>(GET_AVAILABLE_FIELDS, {fetchPolicy: 'cache-first'})
    const allFieldTypes = data?.availableFieldTypes || []

    return {
        namedFieldTypes: allFieldTypes.filter((fieldType) => fieldType.named),
        unnamedFieldTypes: allFieldTypes.filter((fieldType) => !fieldType.named),
    }
}

const useDataSourceState = (
    initialState: {dataSourceData?: DataSourceData; primary: boolean; organisationIdStr: string} | undefined,
) => {
    const availableFieldTypes = useAvailableFieldTypes()

    const [organisationIdStr] = useState(initialState!.organisationIdStr)
    const [cancel, setCancel] = useState(false)
    const [state, dispatch] = useReducer(reducer, {
        dataSourceData: initialState?.dataSourceData!,
        allSteps: [],
        currentColumn: null,
        topLevelStepLabels: [
            ['CONNECT', []],
            ['IDENTIFY', ['column headings']],
            ['FINISH', ['finish']],
        ],
        mappedColumns: {},
        selectedColumns: {},
        completed: false,
        primary: !!initialState?.primary,
    })

    const topLevelStepIndex = getTopLevelStepIndex(state.topLevelStepLabels, state.currentColumn, state.completed)

    return {
        organisationIdStr,
        state,
        dispatch,
        cancel,
        setCancel,
        topLevelStepIndex,
        availableFieldTypes,
        primary: initialState?.primary!,
    }
}

const DataSourceStateContainer = createContainer(useDataSourceState)
export const useConfigureDataSource = DataSourceStateContainer.useContainer
export const DataSourceStateProvider = DataSourceStateContainer.Provider
