import {blendWhite, Theme} from 'nf-ui'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div<{size: number}>`
    height: ${(props) => `${props.size}px`};
    width: ${(props) => `${props.size}px`};
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`

const TextContainer = styled.div<{size: number}>`
    text-align: center;
    font-weight: 400;
    font-size: ${(props) => `${props.size / 2}px`};
    font-family: 'halyard-display';
    line-height: 10px;
    margin-top: -2px;
`

export const getProfileInitials = (fullName: string) => {
    const splitFullName = fullName.split(' ')

    const fistNameInitial = splitFullName?.[0].charAt(0)
    const lastNameInitial = splitFullName?.[splitFullName.length - 1].charAt(0)

    return `${fistNameInitial}${lastNameInitial}`.toUpperCase()
}

export const InitialsText: React.FC<{fullName: string; theme: Theme; size?: number}> = ({
    fullName,
    theme,
    size = 24,
}) => {
    return (
        <Container style={{backgroundColor: blendWhite(theme.primary.color, 0.35)}} size={size}>
            <TextContainer size={size}>{getProfileInitials(fullName)}</TextContainer>
        </Container>
    )
}
