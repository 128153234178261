import {Typography} from 'nf-ui'
import React, {FC} from 'react'
import {Redirect} from 'react-router'
import {CurrentOrganisation} from './components/CurrentOrganisationContext'
import {PageLoading} from './components/PageLoading'
import {useCurrentUser} from './components/useCurrentUser'
import {RoleType} from './globalTypes'
import {getGraphQLErrorMessage} from './util'

export const AuthenticatedRoot: FC = () => {
    const {me, loading, error: currentUserError} = useCurrentUser()
    const {currentOrganisation, error: currentOrganisationError} = CurrentOrganisation.useContainer()

    if (loading) return <PageLoading />

    const error = currentUserError || currentOrganisationError
    if (error) return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>

    if (me?.isSuperAdmin) return <Redirect to={`/management`} />

    if (!currentOrganisation) throw new Error('No organisation')

    const isManager = currentOrganisation.currentUserRoles.includes(RoleType.Manage)
    return <Redirect to={`/${currentOrganisation.idStr}/${isManager ? 'admin' : ''}`} />
}
