import {AnimatePresence, motion} from 'framer-motion'
import {Boundary} from 'popper.js'
import React, {FC, RefObject, useCallback, useEffect, useRef, useState} from 'react'
import {Popper, PopperProps} from 'react-popper'
import styled, {css} from 'styled-components'
import {useOnClickOutside} from '../hooks'
import {Portal} from '../Portal'
import {createOrFindElement, isReactElement} from '../util'
import ArrowSvg from './arrow.svg'
import {customFlipModifier} from './customFlipModifier'

const Container = styled(motion.div)`
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background-color: #fff;
    max-height: 38vh;
    z-index: 100;
    display: flex;

    &[data-placement*='bottom'] {
        margin-top: 8px;
    }

    &[data-placement*='top'] {
        margin-bottom: 8px;
    }
`

const ChildScroll = styled.div`
    flex: 1;
    overflow: auto;
`

Container.defaultProps = {
    variants: {
        open: {
            opacity: 1,
            transition: {
                duration: 0.3,
            },
        },
        closed: {
            opacity: 0,
            transition: {
                duration: 0.3,
            },
        },
    },
    initial: 'closed',
    animate: 'open',
    exit: 'closed',
}

const ArrowContainer = styled.div<{shift?: boolean}>`
    position: absolute;

    &[data-placement*='bottom'] {
        top: -10px;
    }

    &[data-placement*='top'] {
        bottom: -10px;
        transform: rotate(180deg);
    }

    &[data-placement$='start'] {
        left: ${({shift}) => (shift ? '10px' : '2px')} !important;
    }

    &[data-placement$='end'] {
        left: unset !important;
        right: ${({shift}) => (shift ? '10px' : '2px')} !important;
    }
`

/**
 * This is the svg of the arrow, use this to target styling if you have to
 * Use with caution
 *
 */
export const PanelArrow = styled(ArrowSvg)
    .withConfig({
        shouldForwardProp: (prop) => prop !== 'overrideColor',
    })
    .attrs({
        viewBox: '0 0 20 10',
    })<{overrideColor: 'string'}>`
    width: 20px;
    height: 10px;
    display: block;
    ${({overrideColor}) =>
        overrideColor &&
        css`
            [data-override='fill'] {
                fill: ${overrideColor};
            }
        `}
`

export type PanelProps = {
    className?: string
    targetRef: RefObject<HTMLElement | undefined | null>
    open: boolean
    onClose: () => void
    positionFixed?: boolean
    boundariesElement?: Boundary | Element
    closeOnClick?: boolean
    onExitComplete?: () => void
    overrideColor?: string
    noPortal?: boolean
    noChildScroll?: boolean
    placement?: PopperProps['placement']
    onPositionChanged?: (position: {left: string; top: string}) => void
}

export const Panel: FC<PanelProps> = ({
    targetRef,
    onClose,
    open,
    children,
    className,
    positionFixed,
    boundariesElement = 'viewport',
    closeOnClick,
    onExitComplete = () => {},
    overrideColor,
    noPortal = false,
    noChildScroll = false,
    placement = 'bottom-start',
    onPositionChanged,
}) => {
    const portalRef = useRef<HTMLDivElement | null>(null)
    const scheduleUpdateRef = useRef<{(): void} | null>(null)
    const [internalOpen, setInternalOpen] = useState(open)
    const onClickOutside = useCallback(() => {
        if (open) {
            onClose()
        }
    }, [onClose, open])

    useOnClickOutside([portalRef, targetRef], onClickOutside)
    const childKey = isReactElement(children) ? children.key : ''
    const [targetElement, setTargetElement] = useState<HTMLElement | null | undefined>(targetRef.current)

    useEffect(() => {
        if (open && !internalOpen) {
            setInternalOpen(true)
        }
    }, [open, internalOpen])
    /*
        This ensures that panel does not soley depend on user input for it to mount
    */
    useEffect(() => {
        if (!targetElement) {
            setTargetElement(targetRef.current)
        }
    }, [targetElement, targetRef])

    useEffect(() => {
        if (!open) return
        const intervalId = setInterval(() => {
            scheduleUpdateRef.current?.()
            //Made it 8 ms so it's done twice a frame, the schedulare will handle the overlap
        }, 8)
        return () => clearInterval(intervalId)
    }, [open])

    const [position, setPosition] = useState<{left: string; top: string} | undefined>()

    useEffect(() => {
        position && onPositionChanged?.(position)
    }, [position, onPositionChanged])

    if (!targetElement || !internalOpen) return null

    const shiftArrow = targetElement.clientWidth > 20
    //portalContainer is safe to express like this because the reference doesn't change after initial render
    //createOrFindElement will fetch the same reference from the dom and null is always equal to null
    //if the need arises to change to something else that will break reference checking
    //then make sure it's in a useMemo or useCallback or you'll introduce a hard to debug errors
    const portalContainer = noPortal ? null : createOrFindElement('panel-portal')

    return (
        <Portal container={portalContainer} ref={portalRef}>
            <Popper
                referenceElement={targetElement}
                positionFixed={positionFixed}
                modifiers={{
                    hide: {
                        enabled: false,
                    },
                    flip: {
                        fn: customFlipModifier,
                        boundariesElement,
                    },
                    preventOverflow: {
                        enabled: false,
                    },
                }}
                placement={placement}
            >
                {({ref, style, arrowProps, placement, scheduleUpdate}) => {
                    const [left, top] = style.transform?.split('(')?.[1]?.split(',') || []
                    if ((left && left !== position?.left) || (top && top !== position?.top)) {
                        setPosition({left, top})
                    }
                    scheduleUpdateRef.current = scheduleUpdate
                    return (
                        <AnimatePresence
                            onExitComplete={() => {
                                if (!open) {
                                    setInternalOpen(false)
                                    onExitComplete()
                                }
                            }}
                        >
                            {open && (
                                <Container
                                    key={childKey || ''}
                                    className={className}
                                    ref={ref}
                                    style={style}
                                    data-placement={placement}
                                    data-testid="panel"
                                    onClick={() => {
                                        if (closeOnClick) {
                                            onClose()
                                        }
                                    }}
                                >
                                    <ArrowContainer
                                        shift={shiftArrow}
                                        ref={arrowProps.ref}
                                        style={arrowProps.style}
                                        data-placement={placement}
                                    >
                                        <PanelArrow overrideColor={overrideColor} />
                                    </ArrowContainer>
                                    {noChildScroll ? <div>{children}</div> : <ChildScroll>{children}</ChildScroll>}
                                </Container>
                            )}
                        </AnimatePresence>
                    )
                }}
            </Popper>
        </Portal>
    )
}
