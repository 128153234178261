import {DropResult} from 'react-beautiful-dnd'
import {HasPriority, reorderPriorityItems} from '~/util/priority'

type DraggableItem<T> = HasPriority & {
    idStr: string
    __typename: T
    priority: number
    label?: string
    visible?: boolean
    parentIdStr?: string
}

export const onDragEndItemList = <T extends string>(
    dragCallback: (
        reorderedItems: {
            idStr: string
            priority: number
            __typename: T
            label?: string
            visible?: boolean
            parentIdStr?: string
        }[],
    ) => void,
) => {
    return (result: DropResult, draggableItems: DraggableItem<T>[]) => {
        if (!result.destination) {
            return
        }
        const {source, destination} = result

        if (source.droppableId !== destination.droppableId) return

        const reorderedPriorityItems = reorderPriorityItems(
            draggableItems,
            result.source.index,
            result.destination.index,
        )
        dragCallback(
            reorderedPriorityItems.map((item) => {
                return {
                    idStr: item.idStr,
                    priority: item.priority,
                    __typename: item.__typename,
                    parentIdStr: item.parentIdStr,
                }
            }),
        )
    }
}
