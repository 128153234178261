import styled from 'styled-components'
import {HideableCss} from './css'
import {HideableCssProps} from './types'

export const Flex = styled.div<
    {
        direction?: 'row' | 'column'
        gap?: string
        width?: string
        maxWidth?: string
        minWidth?: string
        height?: string
        maxHeight?: string
        minHeight?: string
        overflow?: string
        overflowX?: string
        overflowY?: string
        backgroundColor?: string
        justifyContent?: string
        alignItems?: string
        wrap?: boolean
    } & HideableCssProps
>`
    display: flex;
    flex-direction: ${({direction}) => direction || 'row'};
    gap: ${({gap}) => gap || '0px'};
    width: ${({width}) => width || '100%'};
    max-width: ${({maxWidth}) => maxWidth || 'none'};
    min-width: ${({minWidth}) => minWidth || '0'};
    height: ${({height}) => height || 'auto'};
    max-height: ${({maxHeight}) => maxHeight || 'none'};
    min-height: ${({minHeight}) => minHeight || '0'};
    overflow: ${({overflow}) => overflow || 'visible'};
    overflow-x: ${({overflowX}) => overflowX || 'visible'};
    overflow-y: ${({overflowY}) => overflowY || 'visible'};
    background-color: ${({backgroundColor}) => backgroundColor || 'transparent'};
    justify-content: ${({justifyContent}) => justifyContent || 'flex-start'};
    align-items: ${({alignItems}) => alignItems || 'stretch'};
    flex-wrap: ${({wrap}) => (wrap ? 'wrap' : 'nowrap')};
    ${HideableCss}
`
