import {useEffect, useRef} from 'react'
import {useQueryParam} from 'use-query-params'
import {FullOrgChartData_organisation_profiles} from '../../__types__/FullOrgChartData'
import {useFullOrgChart} from '../FullOrgChartContext'
import {filteredResult} from '../profileSearch'
import {useOrgChartData} from '../useOrgChartData'
import {useCycleMatchingProfiles} from './useCycleMatchingProfiles'

const SCROLL_DELAY = 500

export const useMatchingProfiles = (profiles: FullOrgChartData_organisation_profiles[]) => {
    const {categoryProfiles} = useOrgChartData()
    const [searchTerm] = useQueryParam<string>('search')
    const {matchIndex, setMatchIndex} = useCycleMatchingProfiles()
    const [categoryIdStr] = useQueryParam<string>('categoryId')
    const scrollTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
    const {scrollToProfile} = useFullOrgChart()

    const matchedResults = filteredResult({
        profiles,
        searchTerm: searchTerm?.toLowerCase(),
        categoryIdStr,
        categoryProfiles,
    })
    const matchedCount = matchedResults.length

    useEffect(() => {
        if (scrollTimeout.current) clearTimeout(scrollTimeout.current)
        if (!searchTerm && !categoryIdStr) return

        const matchedResult = matchedResults[matchIndex - 1]
        if (!matchedResult) {
            setMatchIndex(null)
            return
        }

        scrollTimeout.current = setTimeout(() => {
            scrollToProfile(matchedResult.idStr)
        }, SCROLL_DELAY)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, categoryIdStr, matchIndex])

    return {
        matchedCount,
        matchedResults,
        profilesFiltered: !!(searchTerm || categoryIdStr),
    }
}
