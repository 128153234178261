import {PROFILE_PICTURE} from './ProfileThumbnail'

export const PROFILE_ROW_HEIGHT = 56
export const PROFILE_ROW_PADDING = 11
export const GUTTER = 4
export const BRACKET_OFFSET = 6
export const BRACKET_WIDTH = 6
export const INDENT = 85
export const LINE_WIDTH = 1
export const REPORTS_COUNT_HEIGHT = 18

export const DIRECT_LINE_OFFSET = PROFILE_ROW_PADDING + PROFILE_PICTURE / 2 + LINE_WIDTH
export const MIN_PROFILES_FOR_BRACKET = 4
