import React, {LabelHTMLAttributes} from 'react'
import styled from 'styled-components'
import {useGroupContext} from './Group'
import {Typography, ColorVariant} from '../Typography'
import {Spacer} from '../Spacer'

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
    error?: string
    helpText?: string | null
    disabled?: boolean | undefined | null
}

const StyledLabel = styled.label`
    padding-bottom: 8px;
    display: block;
    transition: all o.3s ease-in-out;
`

const Label: React.FC<LabelProps> = ({children, helpText, disabled, error, ...rest}) => {
    const {name} = useGroupContext()

    // Nesting <Typography.Label /> inside <label /> is a workaround here
    // to get the <label /> html element's features. Ideally we would be able
    // to tell <Typography.Label /> to render a <label /> instead of a <span />,
    // but I ran into some issues with changing the component's types to inherit from
    // <label /> html types instead of from <span /> types.
    let variant: ColorVariant = undefined
    if (error) {
        variant = 'error'
    } else if (disabled) {
        variant = 'disabled'
    }

    return (
        <StyledLabel htmlFor={name} {...rest}>
            <Typography.Label variant={variant}>{children}</Typography.Label>
            {helpText && (
                <>
                    <Spacer height={4} />
                    <Typography.Paragraph bottomMargin={false}>{helpText}</Typography.Paragraph>
                </>
            )}
        </StyledLabel>
    )
}

export {Label}
