// TODO: JSDoc all the things
import React, {ReactNode, useMemo, useRef, useState} from 'react'
import {VariableSizeGrid} from 'react-window'
import styled from 'styled-components'
import {colors} from '../theme'
import {Borders} from './Borders'
import {Cell} from './Cell'
import {CellHighlight} from './CellHighlight'
import {ColumnHighlight} from './ColumnHighlight'
import {BORDER_WIDTH, transition} from './constants'
import {RowHighlight, RowsHighlight} from './RowHighlight'
import {HeaderHighlight} from './HeaderHighlight'
import {TableTooltip} from './TableTooltip'
import {getTableDimensions} from './utils/getTableDimensions'
import {CellHoverState, useCellHover} from './utils/useCellHover'
import {Column, getTooltipTitle, useColumns} from './utils/useColumns'
import {useRowHighlight} from './utils/useRowHighlight'
import {Row, useRows} from './utils/useRows'
import {useTableScroll} from './utils/useTableScroll'
import {PanelProps} from '../Panel'
import {IconType} from 'nf-ui'

export type Data = Record<string, string>[]

export type CellData = {
    setHoverCell: CellHoverState['setCell']
    columns: Column[]
    rows: Row[]
} & Pick<
    TableProps,
    | 'onClickCell'
    | 'panel'
    | 'activeCell'
    | 'onClosePanel'
    | 'headerCellsClickable'
    | 'panelBoundaries'
    | 'disableRows'
    | 'headingPanel'
    | 'openHeadingPanel'
    | 'headerIcon'
>

type HoverHighlight = 'row' | 'column' | 'cell' | 'header'

export type TableProps = {
    /**
     * The data to be displayed in the table.
     */
    data: Data

    /**
     * An array of column keys to hide from the table.
     * Columns animate in and out when this prop changes.
     */
    hiddenColumns?: string[]

    /**
     * An array of row indexes to hide from the table.
     * Rows animate in and out when this prop changes.
     */
    hiddenRows?: number[]

    /**
     * The maximum width that the table can grow to.
     * Without this prop the table will grow infinitely with its content.
     */
    maxWidth?: number

    /**
     * The maximum height that the table can grow to.
     * Without this prop the table will grow infinitely with its content.
     */
    maxHeight?: number

    /**
     * Cell onClick callback.
     * This is not fired when clicking on cells in a disabled column.
     */
    onClickCell?: ({columnIndex, rowIndex, column}: {columnIndex: number; rowIndex: number; column: string}) => void

    /**
     * Whether `onClickCell` should fire when a user clicks on a header cell.
     */
    headerCellsClickable?: boolean

    /**
     * The type(s) of highlighting to display when hovering over a cell.
     * The overlays don't activate when hovering over a disabled column.
     */
    hoverHighlight?: HoverHighlight[]

    /**
     * An array of index's to highlight
     */
    highlightRows?: number[]

    /**
     * The tooltip to display when hovering over a column.
     * Can be a static string, or a function which takes in the column key and returns the tooltip content.
     */
    columnTooltip?: string | (({column}: {column: string}) => string | null)

    /**
     * The tooltip to display when hovering over a cell.
     * Can be a static string, or a function which takes in the column key and returns the tooltip content.
     */
    cellTooltip?: string | (({column}: {column: string}) => string | null)

    /**
     * An array of column keys.
     * These columns will have a disabled overlay displayed over them.
     */
    disabledColumns?: string[]

    /**
     * The entire table will be disabled expect for the heading
     */
    disableRows?: boolean

    /**
     * The currently clicked-on cell, to display a panel on.
     */
    activeCell?: {columnIndex: number; rowIndex: number}

    /**
     * The panel to be displayed on the `activeCell`.
     */
    panel?: ReactNode

    /**
     * The panel to be displayed on the heading.
     */
    headingPanel?: ReactNode

    /**
     * To close the panel.
     */
    openHeadingPanel?: boolean

    /**
     * Boundry element for the panel
     *
     * @type {PanelProps["boundariesElement"]}
     */
    panelBoundaries?: PanelProps['boundariesElement']

    /**
     * Called when the panel is closed by clicking outisde of the panel.
     */
    onClosePanel?: () => void

    headerIcon?: {column: string; icon: IconType}
}

const Container = styled.div`
    position: relative;
    transition: ${transition('width', 'height')};
    will-change: width, height;
`

const Grid = styled(VariableSizeGrid)`
    border: ${BORDER_WIDTH}px solid ${colors.darkGray};
    border-radius: 3px;
    box-sizing: border-box;
    transition: ${transition('width', 'height')};

    > div {
        overflow: hidden;
        position: relative;
        transition: ${transition('width', 'height')};
    }
`

// We define these outside of the function definition,
// so that they aren't created on every render.
// This prevents an infinite loop.
const EMPTY_STRING_ARRAY: string[] = []
const EMPTY_NUMBER_ARRAY: number[] = []

export const Table = React.forwardRef<HTMLDivElement, TableProps>(
    (
        {
            data,
            hiddenColumns = EMPTY_STRING_ARRAY,
            hiddenRows = EMPTY_NUMBER_ARRAY,
            maxWidth = 1500,
            maxHeight = 150,
            onClickCell,
            hoverHighlight = [],
            columnTooltip,
            cellTooltip,
            disabledColumns = EMPTY_STRING_ARRAY,
            disableRows,
            activeCell,
            panel,
            headingPanel,
            openHeadingPanel,
            onClosePanel,
            headerCellsClickable = false,
            highlightRows = [],
            panelBoundaries,
            headerIcon,
        },
        ref,
    ) => {
        const gridRef = useRef<VariableSizeGrid>(null)

        const {columns, columnWidth} = useColumns({data, hiddenColumns, disabledColumns, gridRef})
        const {rows, rowHeight} = useRows({data, hiddenRows, gridRef})

        const tableScroll = useTableScroll()

        const {width, height, tableContentHeight, tableContentWidth} = getTableDimensions({
            maxWidth,
            maxHeight,
            columns,
            rows,
        })

        const cellHover = useCellHover({columns, rows, activeCell})

        const rowsToHighlight = useRowHighlight({rows, highlightRows})

        const columnTooltipTitle = getTooltipTitle(columnTooltip, cellHover.cell)
        const cellTooltipTitle = getTooltipTitle(cellTooltip, cellHover.cell)
        /**
         * The data passed to each Cell component.
         */
        const cellData = useMemo<CellData>(
            () => ({
                rows,
                columns,
                setHoverCell: cellHover.setCell,
                onClickCell,
                activeCell,
                panel,
                onClosePanel,
                headerCellsClickable,
                panelBoundaries,
                disableRows,
                headingPanel,
                openHeadingPanel,
                headerIcon,
            }),
            [
                rows,
                columns,
                cellHover.setCell,
                onClickCell,
                onClosePanel,
                headerCellsClickable,
                activeCell,
                panel,
                panelBoundaries,
                disableRows,
                headingPanel,
                openHeadingPanel,
                headerIcon,
            ],
        )

        const [visibleColumnRange, setVisibleColumnRange] = useState<[number, number]>([-1, -1])
        const [visibleRowRange, setVisibleRowRange] = useState<[number, number]>([-1, -1])

        /** The columns currently visible in the scroll area of the table. */
        const mountedVisibleColumns = columns.slice(...visibleColumnRange).filter((column) => column.style.mounted)

        /** The rows currently visible in the scroll area of the table. */
        const mountedVisibleRows = rows.slice(...visibleRowRange).filter((row) => row.style.mounted)

        return (
            <Container
                style={{
                    width,
                    height,
                    // A temporary hack to hide the table's outer borders
                    // if there are no columns in the table.
                    opacity: width === BORDER_WIDTH * 3 ? 0 : 1,
                }}
                ref={ref}
            >
                <Container
                    style={{
                        overflow: 'hidden',
                    }}
                    onMouseLeave={() => cellHover.setCell(undefined)}
                >
                    <Grid
                        ref={gridRef}
                        columnCount={columns.length}
                        columnWidth={columnWidth}
                        height={height}
                        rowCount={rows.length}
                        rowHeight={rowHeight}
                        width={width}
                        itemData={cellData}
                        onScroll={(event) => tableScroll.onScroll(event)}
                        onItemsRendered={(indexes) => {
                            setVisibleRowRange([indexes.visibleRowStartIndex, indexes.visibleRowStopIndex + 1])
                            setVisibleColumnRange([
                                indexes.overscanColumnStartIndex,
                                indexes.visibleColumnStopIndex + 1,
                            ])
                        }}
                        overscanColumnCount={5}
                        overscanRowCount={5}
                    >
                        {Cell}
                    </Grid>
                    <Borders position="top" items={mountedVisibleColumns} style={{x: tableScroll.inverse.left}} />
                    <Borders position="bottom" items={mountedVisibleColumns} style={{x: tableScroll.inverse.left}} />
                    <Borders position="left" items={mountedVisibleRows} style={{y: tableScroll.inverse.top}} />
                    <Borders position="right" items={mountedVisibleRows} style={{y: tableScroll.inverse.top}} />
                    {hoverHighlight.includes('column') && (
                        <ColumnHighlight
                            activeCell={cellHover.cell}
                            tableScroll={tableScroll}
                            tableContentHeight={tableContentHeight}
                            tableHeight={height}
                        />
                    )}
                    {hoverHighlight.includes('row') && (
                        <>
                            <RowHighlight
                                activeCell={cellHover.cell}
                                tableScroll={tableScroll}
                                tableContentWidth={tableContentWidth}
                                tableWidth={width}
                            />
                            <RowsHighlight
                                activeCells={rowsToHighlight.cells}
                                tableScroll={tableScroll}
                                tableContentWidth={tableContentWidth}
                                tableWidth={width}
                            />
                        </>
                    )}
                    {hoverHighlight.includes('header') && (
                        <HeaderHighlight
                            activeCell={cellHover.cell}
                            tableScroll={tableScroll}
                            tableContentWidth={tableContentWidth}
                            tableWidth={width}
                        />
                    )}
                    {hoverHighlight.includes('cell') && (
                        <>
                            <CellHighlight activeCell={cellHover.cell} tableScroll={tableScroll} />
                            {activeCell && (
                                <CellHighlight
                                    activeCell={{
                                        top: rows[activeCell.rowIndex].offset,
                                        header: rows[activeCell.rowIndex].isHeader,
                                        ...columns[activeCell.columnIndex],
                                    }}
                                    tableScroll={tableScroll}
                                />
                            )}
                        </>
                    )}
                </Container>
                {columnTooltipTitle && (
                    <TableTooltip
                        attachTo="column"
                        title={columnTooltipTitle}
                        tableScroll={tableScroll}
                        activeCell={cellHover.cell}
                    />
                )}
                {cellTooltipTitle && (
                    <TableTooltip
                        attachTo="cell"
                        title={cellTooltipTitle}
                        tableScroll={tableScroll}
                        activeCell={cellHover.cell}
                    />
                )}
            </Container>
        )
    },
)
