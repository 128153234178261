import Logo from './logo.svg'
import styled from 'styled-components'
import {playground} from '~/pages/Playground'

export const SquareLogo = styled(Logo)`
    width: 48px;
    height: 48px;
`

playground.push({
    path: 'ui/SquareLogo/SquareLogo.tsx',
    component: SquareLogo,
    props: {},
    propOptions: {},
})
