import {
    HiddenFileInput,
    highlightMatchedText,
    Icon,
    List,
    Panel,
    Spacer,
    Spinner,
    TooltipTarget,
    Typography,
    useAnalyticsEventTracker,
} from 'nf-ui'
import SvgChevronDown from 'nf-ui/Icons/ChevronDown'
import SvgDownload from 'nf-ui/Icons/Download'
import SvgImage from 'nf-ui/Icons/Image'
import SvgLink from 'nf-ui/Icons/Link'
import SvgSend from 'nf-ui/Icons/Send'
import SvgUpload from 'nf-ui/Icons/Upload'
import React, {RefObject, useRef, useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {PanelLayout, PanelLayoutMenuItem} from '~/components/PanelLayout'
import {useLinkPhoto, useUnlinkPhoto, useUploadPhotoQueue} from '~/components/PhotoManagementCommon'
import {PANEL_WIDTH} from '~/constants'
import {getGraphQLErrorMessage} from '~/util'
import {ModalNavigator} from '../ModalLayout/ModalNavigator'
import {ProfileListPhoto} from '../ProfileListPhoto'
import {useCurrentUser} from '../useCurrentUser'
import {useOrganisationIdStr} from '../useOrganisationIdStr'
import {ChoosePhotoModal} from './ChoosePhotoModal'
import {useDownloadPhoto} from './useDownloadPhoto'
import {orderPhotos} from '~/components/OrganisationContext'

const ListContainer = styled.div<{hasSpinnerOrPhoto?: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${({hasSpinnerOrPhoto}) => (hasSpinnerOrPhoto ? '12px' : '16px')} 16px;
`

const ListLayoutContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 16px;
    flex: 1;
    min-width: 0;
`

const ProfileNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    min-width: 0;
`

const SpinnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
`

type Profile = {
    idStr: string
    photos: {idStr: string; thumbUrl: string; priority?: number | null}[] | null
    photoRequested: boolean
    createdAt: number
    fullName: string
}

export type AdminProfileProps = {
    profile: Profile
    open: boolean
    uploadPhotos: ReturnType<typeof useUploadPhotoQueue>['addItems']
    search: string
    onClose: () => void
    loading: boolean
    wrapperRef: RefObject<HTMLDivElement>
}

const AdminProfile: React.FC<AdminProfileProps> = ({
    profile,
    open,
    uploadPhotos,
    search,
    onClose,
    loading,
    wrapperRef,
}) => {
    const [unlinkPhoto, unlinkPhotoStatus] = useUnlinkPhoto()
    const {downloadPhoto} = useDownloadPhoto()
    const {me} = useCurrentUser()
    const alert = useAlert()
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const fileInputRef = useRef<HTMLInputElement>(null)
    const [modal, setModal] = useState<'choosePhoto' | null>(null)
    const [linkPhoto, linkPhotoStatus] = useLinkPhoto()
    const organisationIdStr = useOrganisationIdStr()
    const showSpinner = linkPhotoStatus.loading || unlinkPhotoStatus.loading || loading

    const superAdminOptions = me?.isSuperAdmin
        ? [
              {
                  id: 'download',
                  label: 'Download this photo',
                  onClick: async () => {
                      onClose()

                      try {
                          if (!profile.photos?.[0]) return

                          await downloadPhoto(profile)
                      } catch (error) {
                          alert.error(getGraphQLErrorMessage(error))
                      }
                  },
                  icon: <Icon icon={SvgDownload} />,
              },
          ]
        : []

    return (
        <>
            <ModalNavigator modal={modal} setModal={setModal}>
                <ChoosePhotoModal
                    key="choosePhoto"
                    organisationIdStr={organisationIdStr}
                    profile={profile}
                    onChoosePhoto={async (photoIdStr) => {
                        setModal(null)
                        try {
                            await linkPhoto({
                                variables: {
                                    photoIdStr,
                                    profileIdStr: profile.idStr,
                                },
                            })
                        } catch (error) {
                            alert.error(getGraphQLErrorMessage(error))
                        }
                    }}
                    onUploadFile={(file) => {
                        setModal(null)
                        uploadPhotos([{file, profileIdStr: profile.idStr}])
                    }}
                />
            </ModalNavigator>
            <ListContainer hasSpinnerOrPhoto={!!profile.photos?.[0] || showSpinner}>
                <ListLayoutContainer>
                    <ProfileNameContainer>
                        {showSpinner ? (
                            <>
                                <SpinnerContainer>
                                    <Spinner />
                                </SpinnerContainer>
                                <Spacer width={8} />
                            </>
                        ) : (
                            <>
                                {profile.photos?.[0] && (
                                    <>
                                        <ProfileListPhoto photo={orderPhotos(profile.photos)[0]} />{' '}
                                        <Spacer width={16} />
                                    </>
                                )}
                            </>
                        )}

                        <Typography.Paragraph
                            bottomMargin={false}
                            style={{
                                flex: 1,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textAlign: 'left',
                            }}
                        >
                            {highlightMatchedText(profile.fullName, search)}
                        </Typography.Paragraph>
                    </ProfileNameContainer>
                    {profile.photoRequested && (
                        <TooltipTarget
                            position="right"
                            title="You've requested a photo for this user. As soon as it is uploaded it will be linked."
                            maxWidth={193}
                            offset={6}
                        >
                            <Icon icon={SvgSend} />
                        </TooltipTarget>
                    )}
                    <HiddenFileInput
                        ref={fileInputRef}
                        onChange={async (event) => {
                            if (!event.target.files) return
                            uploadPhotos([{file: event.target.files[0], profileIdStr: profile.idStr}])
                        }}
                    />
                </ListLayoutContainer>
                <Icon icon={SvgChevronDown} />
                <Panel targetRef={wrapperRef} open={open} onClose={onClose}>
                    <div>
                        <PanelLayout>
                            <Typography.Label>
                                {profile.photos?.[0] ? 'Match to a photo' : 'What would you like to do?'}
                            </Typography.Label>
                            <List
                                width={PANEL_WIDTH}
                                rows={
                                    profile.photos?.[0]
                                        ? [
                                              {
                                                  id: 'unmatch',
                                                  label: 'Unmatch this photo',
                                                  onClick: async () => {
                                                      onClose()

                                                      try {
                                                          if (!profile.photos?.[0]) return

                                                          trackAnalyticsEvent('select_unmatch_photo')
                                                          await unlinkPhoto({
                                                              variables: {
                                                                  photoIdStr: orderPhotos(profile.photos)[0].idStr,
                                                              },
                                                          })
                                                      } catch (error) {
                                                          alert.error(getGraphQLErrorMessage(error))
                                                      }
                                                  },
                                                  icon: <Icon icon={SvgLink} />,
                                              },
                                              ...superAdminOptions,
                                          ]
                                        : [
                                              {
                                                  id: 'choose',
                                                  label: 'Match to existing photo',
                                                  onClick: () => {
                                                      setModal('choosePhoto')
                                                      trackAnalyticsEvent('select_match_to_existing_photo')
                                                      onClose()
                                                  },
                                                  icon: <Icon icon={SvgImage} />,
                                              },
                                              {
                                                  id: 'upload',
                                                  label: 'Upload a new photo',
                                                  onClick: () => {
                                                      if (fileInputRef.current) fileInputRef.current.click()
                                                      trackAnalyticsEvent('select_upload_new_photo')
                                                  },
                                                  icon: <Icon icon={SvgUpload} />,
                                              },
                                          ]
                                }
                                renderRow={(row) => (
                                    <PanelLayoutMenuItem>
                                        {row.label}
                                        {row.icon}
                                    </PanelLayoutMenuItem>
                                )}
                                onClick={(item) => {
                                    item.onClick()
                                }}
                            />
                        </PanelLayout>
                    </div>
                </Panel>
            </ListContainer>
        </>
    )
}

export {AdminProfile}
