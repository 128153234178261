import {groupBy, orderBy, uniq} from 'lodash'
import {useEffect, useState} from 'react'
import {useMutationDataEditUpdateFields} from '~/apollo/queries/DataEditField'
import {
    SortField,
    useMutationDataEditAddNewProfiles,
    useMutationDataEditDeleteProfiles,
    useMutationDataEditUpdateValues,
    useQueryDataEditHiddenValues,
} from '~/apollo/queries/DataEditValue'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {
    DataEditField,
    DataEditValue,
    convertDataEditValuesByProfileIdStr,
    useOrganisationContext,
} from '~/components/OrganisationContext'
import {DataEditFieldUpsert, DataEditValueUpsert} from '~/objectTypes'
import {arrayify} from '~/util/array'
import {parseDateYYYYMMDD} from '~/util/date'

export type CellValue = {
    fieldOrParentCategoryIdStr: string
    profileIdStr: string
    values: string[]
    isHidden?: boolean
    loading?: boolean
    invalid?: boolean
}

export type Field = {
    idStr: string
    type: string
    subTypes: string[]
    name: string
    availableValues: string[]
    loading?: boolean
    userCanEdit?: boolean
}

export type Profile = {
    idStr: string
    index: number
    cellValues: CellValue[] // Needs to match order of fields
}

const toDict = <T extends {idStr: string}>(items: T[]) => Object.fromEntries(items.map((item) => [item.idStr, item]))
const fromDataEditField = (field: DataEditField): Field => ({
    idStr: field.idStr,
    type: field.type,
    subTypes: field.subType?.split(';') || [],
    name: field.name,
    availableValues: field.availableValues,
    userCanEdit: field.userCanEdit === null ? undefined : field.userCanEdit,
})
const patchDataEditFields = (dataEditFields: DataEditField[], fields: Field[]): DataEditField[] => {
    const fieldsDict = toDict(fields)
    const dataEditFieldsDict = toDict(dataEditFields)

    return [
        ...dataEditFields.map((f) => {
            const field = fieldsDict[f.idStr]
            return {
                ...f,
                idStr: field?.idStr,
                name: field?.name,
                type: field?.type as DataEditField['type'],
                subType: field?.subTypes?.join(';'),
                availableValues: field?.availableValues,
                userCanEdit: field?.userCanEdit === undefined ? null : field?.userCanEdit,
            }
        }),
        ...fields
            .filter((field) => !dataEditFieldsDict[field.idStr])
            .map((field) => ({
                idStr: field.idStr,
                name: field.name,
                type: field.type as DataEditField['type'],
                subType: field.subTypes?.join(';'),
                availableValues: field.availableValues,
                __typename: 'DataEditField' as 'DataEditField',
                organisationIdStr: dataEditFields[0]?.organisationIdStr,
                availableValueIdStrs: field.availableValues,
                availableValueLabels: field.availableValues,
                baseFields: null,
                userCanEdit: field?.userCanEdit === undefined ? null : field?.userCanEdit,
            })),
    ]
}
const toDataEditFieldUpsert = (organisationIdStr: string) => (
    field: Omit<Field, 'idStr'> & {idStr?: string | null},
): DataEditFieldUpsert => {
    return {
        idStr: field.idStr || null,
        name: field.name,
        type: field.type as DataEditField['type'],
        subType: field.subTypes?.join(';'),
        availableValues: field.availableValues,
        organisationIdStr: organisationIdStr,
        usersCanCreateValues: null,
        userCanEdit: field.userCanEdit === undefined ? null : field.userCanEdit,
    }
}
const fromDataEditValues = (
    visibleValuesIndexed: Record<string, {fieldOrParentCategoryIdStr: string; profileIdStr: string; value: string}[]>,
    hiddenValuesIndexed: Record<string, {fieldOrParentCategoryIdStr: string; profileIdStr: string; value: string}[]>,
    fields: Field[],
): Profile[] =>
    Object.values(visibleValuesIndexed).map((profile, index) => ({
        idStr: profile[0].profileIdStr,
        index,
        cellValues: fields.map((field) => {
            const hiddenValues =
                hiddenValuesIndexed[profile[0].profileIdStr]
                    ?.filter((value) => value.fieldOrParentCategoryIdStr === field.idStr)
                    ?.map((value) => value.value) || []
            const values = [
                ...profile
                    .filter((value) => value.fieldOrParentCategoryIdStr === field.idStr)
                    .map((value) => value.value),
                ...hiddenValues,
            ].filter((value) => value)
            return {
                fieldOrParentCategoryIdStr: field.idStr,
                profileIdStr: profile[0].profileIdStr,
                values,
                isHidden: !!hiddenValues.length,
                loading: false,
                invalid: field.type === 'date' && !!values.length && !parseDateYYYYMMDD(values[0]),
            }
        }),
    }))
const toDataEditValues = (organisationIdStr: string) => (profiles: Profile[]): DataEditValue[] =>
    profiles.flatMap((profile) =>
        profile.cellValues
            .filter((cellValue) => !cellValue.isHidden)
            .flatMap((cellValue) =>
                (cellValue.values.length ? cellValue.values : []).map((str) => ({
                    fieldOrParentCategoryIdStr: cellValue.fieldOrParentCategoryIdStr,
                    profileIdStr: cellValue.profileIdStr,
                    value: str,
                    organisationIdStr,
                    __typename: 'DataEditValue',
                })),
            ),
    )
const toDataEditValueUpserts = (cellValues: CellValue[]): DataEditValueUpsert[] =>
    cellValues.flatMap((cellValue) =>
        (cellValue.values.length ? cellValue.values : ['']).map((str) => ({
            fieldOrParentCategoryIdStr: cellValue.fieldOrParentCategoryIdStr,
            profileIdStr: cellValue.profileIdStr,
            value: str,
            organisationIdStr: null,
        })),
    )

const patchProfilesWithFieldOrderUpdate = (
    profilesByIdStr: Record<string, Profile>,
    fieldsUpdate: Field[],
): Record<string, Profile> =>
    Object.fromEntries(
        Object.keys(profilesByIdStr).map((idStr, index) => [
            idStr,
            {
                idStr,
                index,
                cellValues: fieldsUpdate.map(
                    (field) =>
                        profilesByIdStr[idStr].cellValues.find(
                            (cellValue) => cellValue.fieldOrParentCategoryIdStr === field.idStr,
                        ) || {
                            fieldOrParentCategoryIdStr: field.idStr,
                            profileIdStr: idStr,
                            values: [],
                            isHidden: false,
                            loading: false,
                        },
                ),
            },
        ]),
    )

export const useData = () => {
    const [fieldsByIdStr, setFieldsByIdStr] = useState<Record<string, Field>>({})
    const [fields, setFields] = useState<Field[]>([])
    const [profilesByIdStr, setProfilesByIdStr] = useState<Record<string, Profile>>({})
    const [profiles, setProfiles] = useState<Profile[]>([])
    const [sortField, setSortField] = useState<SortField | undefined>()
    const [loading, setLoading] = useState<boolean>(true)

    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const [, setVisibleValues] = useOrganisationContext.useDataEditValues()
    const [visibleValuesIndexed, setVisibleValuesIndexed] = useOrganisationContext.useDataEditValuesByProfileIdStr()
    const [dataEditFields, setDataEditFields] = useOrganisationContext.useDataEditFields()
    const hiddenValuesQuery = useQueryDataEditHiddenValues(currentOrganisation!.idStr)

    const [, callUpdateFields] = useMutationDataEditUpdateFields(currentOrganisation!.idStr)
    const [, callUpdateValues] = useMutationDataEditUpdateValues(currentOrganisation!.idStr)
    const [, callAddNewProfiles] = useMutationDataEditAddNewProfiles(currentOrganisation!.idStr)
    const [, callDeleteProfiles] = useMutationDataEditDeleteProfiles(currentOrganisation!.idStr)

    useEffect(() => {
        // console.log('useEffect hiddenValuesQuery.loading', hiddenValuesQuery.loading, 'dataEditFields', dataEditFields)
        const fields = dataEditFields.map(fromDataEditField)
        setFields(fields)
        setFieldsByIdStr(toDict(fields))

        const hiddenValuesIndexed = convertDataEditValuesByProfileIdStr(hiddenValuesQuery.data?.dataEditValues || [])
        updateLocalValues(fromDataEditValues(visibleValuesIndexed, hiddenValuesIndexed || {}, fields), {
            skipContext: true,
        })
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hiddenValuesQuery.loading])

    const updateLocalValues = (profiles: Profile[], options?: {skipContext?: boolean}) => {
        // console.log('updateLocalValues', profiles, options)
        const dict = toDict(profiles)
        if (!options?.skipContext) {
            const newDataEditValues = toDataEditValues(currentOrganisation!.idStr)(profiles)
            setProfiles(profiles)
            setProfilesByIdStr(dict)
            setVisibleValues(newDataEditValues)
            setVisibleValuesIndexed(convertDataEditValuesByProfileIdStr(newDataEditValues))
        } else {
            setProfiles(profiles)
            setProfilesByIdStr(dict)
        }
        // console.log('updateLocalValues done')
    }

    const updateLocalFields = (fields: Field[], options?: {skipContext?: boolean; skipValues?: boolean}) => {
        // console.log('updateLocalFields fields', fields, 'options', options)
        const dict = toDict(fields)
        if (!options?.skipContext) {
            const newDataEditFields = patchDataEditFields(dataEditFields, fields)
            setFields(fields)
            setFieldsByIdStr(dict)
            setDataEditFields(newDataEditFields)
        } else {
            setFields(fields)
            setFieldsByIdStr(dict)
        }
        if (!options?.skipValues) {
            const hiddenValuesIndexed = convertDataEditValuesByProfileIdStr(
                hiddenValuesQuery.data?.dataEditValues || [],
            )
            updateLocalValues(fromDataEditValues(visibleValuesIndexed, hiddenValuesIndexed || {}, fields), options)
        }
        console.log('updateLocalFields done')
    }

    const _data = {
        loading,
        fieldsByIdStr,
        fields,
        profilesByIdStr,
        profiles,
        sortField,
        setSortField: (field: SortField) => {
            setProfiles(
                sortField === undefined
                    ? profiles
                    : orderBy(
                          profiles,
                          (profile) => {
                              const rawValue = profilesByIdStr[profile.idStr].cellValues?.find(
                                  (value) => value.fieldOrParentCategoryIdStr === sortField.idStr,
                              )?.values?.[0]
                              return (rawValue === undefined || rawValue === '') && sortField.direction !== 'desc'
                                  ? 'ZZZZZZ'
                                  : rawValue === undefined || rawValue === ''
                                  ? ''
                                  : isNaN(parseFloat(rawValue))
                                  ? rawValue.toUpperCase()
                                  : rawValue.padStart(20, '0')
                          },
                          arrayify(sortField.direction),
                      ),
            )
            setSortField(field)
        },
        addFields: async (
            fieldsUpdate: (Omit<Field, 'idStr'> & {idStr?: string | null})[],
            {skipLocal, skipServer}: {skipLocal?: boolean; skipServer?: boolean},
        ) => {
            // console.log('addFields fieldsUpdate', fieldsUpdate, 'skipLocal, skipServer', skipLocal, skipServer)
            try {
                if (!skipLocal && !skipServer) {
                    const newFields = (
                        await callUpdateFields({
                            dataEditFields: fieldsUpdate.map(toDataEditFieldUpsert(currentOrganisation!.idStr)),
                            removeMissingFields: false,
                        })
                    ).fields.map(fromDataEditField)
                    const profilesDict = patchProfilesWithFieldOrderUpdate(profilesByIdStr, newFields)
                    updateLocalValues(profiles.map((profile) => profilesDict[profile.idStr]))
                    updateLocalFields(newFields)
                    return newFields
                } else if (!skipServer) {
                    return (
                        await callUpdateFields({
                            dataEditFields: fieldsUpdate.map(toDataEditFieldUpsert(currentOrganisation!.idStr)),
                            removeMissingFields: false,
                        })
                    ).fields.map(fromDataEditField)
                } else if (!skipLocal) {
                    const profilesDict = patchProfilesWithFieldOrderUpdate(profilesByIdStr, fieldsUpdate as Field[])
                    updateLocalValues(profiles.map((profile) => profilesDict[profile.idStr]))
                    updateLocalFields(fieldsUpdate as Field[])
                    return fieldsUpdate as Field[]
                }
                return []
            } catch (error) {
                return []
            }
        },
        // Fields must exist already
        // Fields can be deleted here
        updateFields: async (
            fieldsUpdate: Field[],
            {
                skipLocal,
                skipServer,
                removeMissingFields,
                skipValues,
            }: {skipLocal?: boolean; skipServer?: boolean; removeMissingFields?: boolean; skipValues?: boolean},
        ) => {
            // console.log(
            //     'updateFields fieldsUpdate',
            //     fieldsUpdate,
            //     'removeMissingFields',
            //     removeMissingFields,
            //     'skipValues',
            //     skipValues,
            //     'skipLocal',
            //     skipLocal,
            //     'skipServer',
            //     skipServer,
            // )
            const newFieldOrder =
                (removeMissingFields || fields.length === fieldsUpdate.length) &&
                fields.map((field) => field.idStr).join(';') !== fieldsUpdate.map((field) => field.idStr).join(';')
                    ? fieldsUpdate.map((field) => field.idStr)
                    : undefined

            const applyLocal = (loading: boolean) => {
                const newFields = fieldsUpdate.map((field) => ({...field, loading}))
                if (removeMissingFields || newFieldOrder) {
                    if (!loading && !skipValues) {
                        const newProfilesByIdStr = patchProfilesWithFieldOrderUpdate(profilesByIdStr, fieldsUpdate)
                        const newProfiles = profiles.map((profile) => newProfilesByIdStr[profile.idStr])
                        updateLocalValues(newProfiles)
                    }
                    updateLocalFields(newFields, {skipContext: loading, skipValues})
                } else {
                    const dict = toDict(newFields)
                    updateLocalFields(
                        fields.map((field) => dict[field.idStr] || field),
                        {skipContext: loading, skipValues},
                    )
                }
            }

            const originalFieldsByIdStr = !skipLocal && !skipServer ? {...fieldsByIdStr} : {}
            const originalFields = !skipLocal && !skipServer ? [...fields] : []
            const originalProfiles = !skipLocal && !skipServer ? [...profiles] : []

            let updated = fields
            if (!skipServer) {
                if (!skipLocal) {
                    applyLocal(true)
                }

                try {
                    updated = (
                        await callUpdateFields({
                            dataEditFields: fieldsUpdate.map(toDataEditFieldUpsert(currentOrganisation!.idStr)),
                            removeMissingFields: removeMissingFields || false,
                        })
                    ).fields.map(fromDataEditField)
                } catch (error) {
                    console.log(error)
                    if (!skipLocal) {
                        setFieldsByIdStr(originalFieldsByIdStr)
                        setFields(originalFields)
                        if (!skipValues) {
                            updateLocalValues(originalProfiles, {skipContext: true})
                        }
                    }
                    return []
                }
            }
            if (!skipLocal) {
                applyLocal(false)
            }
            // console.log('updateFields done')
            return updated
        },
        // Appends to bottom, does not apply sorting
        addNewProfiles: async (
            addProfiles: {fieldIdStr: string; values: string[]; isHidden?: boolean}[][],
            {skipLocal}: {skipLocal?: boolean},
        ): Promise<Profile[]> => {
            // console.log('addNewProfiles addProfiles', addProfiles, 'skipLocal', skipLocal)
            const originalProfiles = [...profiles]
            try {
                const updatedProfiles = await callAddNewProfiles({
                    newProfiles: addProfiles.map((profile) =>
                        profile.flatMap((cellValue) =>
                            cellValue.values.map((value) => ({
                                fieldOrParentCategoryIdStr: cellValue.fieldIdStr,
                                value,
                                organisationIdStr: currentOrganisation!.idStr,
                            })),
                        ),
                    ),
                })
                const serverProfilesDict = groupBy(updatedProfiles, (profile) => profile.profileIdStr)
                const profilesToAdd = Object.fromEntries(
                    Object.keys(serverProfilesDict).map((key, index) => [
                        key,
                        {
                            idStr: key,
                            index,
                            cellValues: fields.map((field) => ({
                                fieldOrParentCategoryIdStr: field.idStr,
                                profileIdStr: key,
                                values: serverProfilesDict[key]
                                    .filter((v) => v.fieldOrParentCategoryIdStr === field.idStr)
                                    .map((v) => v.value),
                            })),
                        },
                    ]),
                )
                const newProfiles = uniq([
                    ...profiles.map(({idStr}) => idStr),
                    ...updatedProfiles.map(({profileIdStr}) => profileIdStr),
                ]).map((idStr) => profilesToAdd[idStr] || profilesByIdStr[idStr])
                if (!skipLocal) {
                    updateLocalValues(newProfiles)
                }
                return newProfiles
            } catch (error) {
                console.log(error)
                if (!skipLocal) {
                    updateLocalValues(originalProfiles, {skipContext: true})
                }
                return []
            }
        },
        deleteProfiles: async (
            profileIdStrs: string[],
            {skipLocal, skipServer}: {skipLocal?: boolean; skipServer?: boolean},
        ) => {
            const originalProfilesByIdStr = !skipLocal && !skipServer ? {...profilesByIdStr} : {}
            const originalProfiles = !skipLocal && !skipServer ? [...profiles] : []

            if (!skipServer) {
                if (!skipLocal) {
                    updateLocalValues(
                        profiles.map((profile) =>
                            !profileIdStrs.includes(profile.idStr) ? profile : {...profile, loading: true},
                        ),
                        {skipContext: true},
                    )
                }
                try {
                    await callDeleteProfiles({profileIdStrs})
                } catch (error) {
                    if (!skipLocal) {
                        setProfilesByIdStr(originalProfilesByIdStr)
                        setProfiles(originalProfiles)
                    }
                    return
                }
            }
            if (!skipLocal) {
                updateLocalValues(profiles.filter((profile) => !profileIdStrs.includes(profile.idStr)))
            }
        },
        // Fields and profiles must exist already.
        // We are also not deleting profiles or fields here.
        // Sorting would need to be applied separately
        updateValues: async (
            newCellValues: CellValue[],
            options?: {
                skipLocal?: boolean
                skipServer?: boolean
                removeMissingValues?: boolean
                profilesByIdStr?: Record<string, Profile>
                profiles?: Profile[]
                fields?: Field[]
            },
        ) => {
            // console.log('updateValues newCellValues', newCellValues, 'options', options)
            const _profilesByIdStr = options?.profilesByIdStr || profilesByIdStr
            const _profiles = options?.profiles || profiles
            const _fields = options?.fields || fields

            const cellValuesIndex: Record<string, CellValue[]> = {}
            if (!options?.skipLocal) {
                newCellValues.forEach((cellValue) => {
                    const key = `${cellValue.profileIdStr}.${cellValue.fieldOrParentCategoryIdStr}`
                    if (cellValuesIndex[key]) {
                        cellValuesIndex[key].push(cellValue)
                    } else {
                        cellValuesIndex[key] = [cellValue]
                    }
                })
            }

            const applyLocal = (loading: boolean) => {
                const newProfilesByIdStr = Object.fromEntries(
                    Object.keys(_profilesByIdStr).map((profileIdStr, profileIndex) => [
                        profileIdStr,
                        {
                            idStr: profileIdStr,
                            index: profileIndex,
                            cellValues: _fields.map((field, fieldIndex) => {
                                const key = `${profileIdStr}.${field.idStr}`
                                const currentCellValue = _profilesByIdStr[profileIdStr]?.cellValues?.[fieldIndex] as
                                    | CellValue
                                    | undefined
                                const newCellValues = !cellValuesIndex[key]
                                    ? currentCellValue?.values || []
                                    : options?.removeMissingValues
                                    ? uniq(cellValuesIndex[key].flatMap((cv) => cv.values))
                                    : uniq([
                                          ...(currentCellValue?.values || []),
                                          ...cellValuesIndex[key].flatMap((cv) => cv.values),
                                      ])
                                return {
                                    fieldOrParentCategoryIdStr: field.idStr,
                                    profileIdStr,
                                    values: newCellValues,
                                    isHidden: currentCellValue?.isHidden,
                                    loading,
                                    invalid:
                                        field.type === 'date' &&
                                        !!newCellValues.length &&
                                        !parseDateYYYYMMDD(newCellValues[0]),
                                }
                            }),
                        },
                    ]),
                )
                updateLocalValues(
                    _profiles.map(({idStr}) => newProfilesByIdStr[idStr]),
                    {skipContext: loading},
                )
            }

            if (!options?.skipServer) {
                const originalProfiles = !options?.skipLocal ? _profiles.map(({idStr}) => _profilesByIdStr[idStr]) : []

                if (!options?.skipLocal) {
                    applyLocal(true)
                }
                try {
                    await callUpdateValues({
                        dataEditValues: toDataEditValueUpserts(newCellValues),
                        removeMissingValues: options?.removeMissingValues || false,
                    })
                } catch (error) {
                    console.log(error)
                    if (!options?.skipLocal) {
                        updateLocalValues(originalProfiles, {skipContext: true})
                    }
                    return
                }
            }

            if (!options?.skipLocal) {
                applyLocal(false)
            }
            // console.log('updateValues done')
        },
    }

    return {
        ..._data,
        updateFieldsAndValues: async (
            fields: Field[],
            cellValues: CellValue[],
            options?: {skipServerValues?: boolean; removeMissingValues?: boolean},
        ) => {
            // console.log('updateFieldsAndValues fields', fields, 'cellValues', cellValues, 'options', options)
            const updatedFields = await _data.updateFields(fields, {})

            await _data.updateValues(cellValues, {
                skipServer: options?.skipServerValues,
                removeMissingValues: options?.removeMissingValues,
                profilesByIdStr,
                profiles,
                fields: updatedFields,
            })

            return updatedFields
        },
        pasteValues: async (startRowIndex: number, startColIndex: number, values: string[][][]) => {
            const fieldsUpdate = fields.slice(startColIndex, startColIndex + values[0].length).map((field, index) => ({
                ...field,
                availableValues:
                    field.type === 'category'
                        ? uniq([...field.availableValues, ...values.flatMap((row) => row[index])])
                        : [],
            }))

            const fieldsToCreate = [...new Array(Math.max(0, startColIndex + values[0].length - fields.length))].map(
                (_, index) => ({
                    name: `Column ${index + 1 + fields.length}`,
                    type: 'text',
                    subTypes: [],
                    organisationIdStr: currentOrganisation!.idStr,
                    availableValues: [],
                }),
            )

            const addFieldsPromise = fieldsToCreate.length
                ? _data.addFields(fieldsToCreate, {skipLocal: true})
                : Promise.resolve([])
            const updateFieldsPromise = fieldsUpdate.some((field) => field.availableValues?.length)
                ? _data.updateFields(fieldsUpdate, {skipLocal: true})
                : Promise.resolve(fieldsUpdate)

            const addedFields = (await addFieldsPromise).filter((field) => !fieldsByIdStr[field.idStr])
            const updatedFields = (await updateFieldsPromise).filter((field) =>
                fieldsUpdate.find((f) => f.idStr === field.idStr),
            )
            const affectedFields = [...updatedFields, ...addedFields]
            const allFields = [
                ...fields.slice(0, startColIndex),
                ...affectedFields,
                ...fields.slice(startColIndex + updatedFields.length),
            ]

            // console.log('addedFields', addedFields, 'updatedFields', updatedFields, 'allFields', allFields)
            const profilesAdjustedForFields = patchProfilesWithFieldOrderUpdate(profilesByIdStr, allFields)

            const profilesToUpdate = profiles
                .map((profile) => profile.idStr)
                .slice(startRowIndex, startRowIndex + values.length)

            const existingAndNewProfiles =
                values.length > profilesToUpdate.length
                    ? await _data.addNewProfiles(
                          values.slice(profilesToUpdate.length).map((row) =>
                              affectedFields.map((field, colIndex) => ({
                                  fieldIdStr: field.idStr,
                                  values: row[colIndex],
                              })),
                          ),
                          {skipLocal: true},
                      )
                    : _data.profiles

            await _data.updateValues(
                profilesToUpdate.flatMap((profileIdStr, rowIndex) =>
                    affectedFields.flatMap((field, colIndex) => ({
                        profileIdStr,
                        fieldOrParentCategoryIdStr: field.idStr,
                        values: values[rowIndex][colIndex],
                    })),
                ),
                {
                    skipLocal: true,
                    removeMissingValues: true,
                    profilesByIdStr: profilesAdjustedForFields,
                    profiles: _data.profiles,
                    fields: allFields,
                },
            )

            updateLocalFields(allFields)
            await _data.updateValues(
                existingAndNewProfiles
                    .slice(startRowIndex, startRowIndex + values.length)
                    .flatMap(({idStr}, rowIndex) =>
                        affectedFields.flatMap((field, colIndex) => ({
                            profileIdStr: idStr,
                            fieldOrParentCategoryIdStr: field.idStr,
                            values: values[rowIndex][colIndex],
                        })),
                    ),
                {
                    skipServer: true,
                    removeMissingValues: true,
                    profilesByIdStr: Object.fromEntries(
                        existingAndNewProfiles.map(({idStr}) => [
                            idStr,
                            profilesAdjustedForFields[idStr] || existingAndNewProfiles.find((p) => p.idStr === idStr),
                        ]),
                    ),
                    profiles: existingAndNewProfiles,
                    fields: allFields,
                },
            )

            return affectedFields
        },
    }
}
