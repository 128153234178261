import useComponentSize from '@rehooks/component-size'
import React, {ReactNode, useRef} from 'react'
import styled from 'styled-components'
import PhoneSVG from './phone.svg'

const PHONE_HEIGHT = 808
const VERTICAL_PADDING = 20

const Container = styled.div<{accentColor: string}>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: calc(100% - ${VERTICAL_PADDING * 2}px);
    margin: ${VERTICAL_PADDING}px 0;
    padding-left: 120px;

    /* Any child SVG element with this attribute will have its fill changed to the provided accentColor. */
    [data-override='fill'] {
        fill: ${(props) => props.accentColor};
    }
`

const InnerContainer = styled.div`
    position: relative;
`

const Content = styled.div`
    position: absolute;
    top: 63px;
    left: 29px;
    right: 29px;
    bottom: 99px;
    display: flex;
    flex-direction: column;
`

const ScrollContent = styled.div`
    overflow-y: auto;
    flex: 1;
    scroll-behavior: smooth;
`

export type PhonePreviewProps = {
    accentColor: string
    navBar?: ReactNode
}

export const PhonePreview: React.FC<PhonePreviewProps> = ({accentColor, navBar, children}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const {height} = useComponentSize(containerRef)

    const scale = Math.min(1, height / PHONE_HEIGHT)

    return (
        <Container accentColor={accentColor} ref={containerRef}>
            <InnerContainer style={{transform: `scale3d(${scale}, ${scale}, 1)`}}>
                <PhoneSVG style={{display: 'block'}} />
                <Content>
                    {navBar}
                    <ScrollContent>{children}</ScrollContent>
                </Content>
            </InnerContainer>
        </Container>
    )
}
