import {motion} from 'framer-motion'
import {MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY} from 'nf-ui'
import React from 'react'
import styled from 'styled-components'

const Container = styled(motion.div)`
    width: 960px;
    margin: 0 auto;
    ${MOBILE_MEDIA_QUERY} {
        margin: 0 24px;
        width: auto;
    }
    ${TABLET_MEDIA_QUERY} {
        margin: 0 32px;
        width: auto;
    }
    box-sizing: border-box;
`

const variants = {
    open: {opacity: 1},
    closed: {opacity: 0},
}

const Body: React.FC<{className?: string}> = ({children, className}) => (
    <Container variants={variants} className={className}>
        {children}
    </Container>
)

export {Body, Container}
