import {Formik} from 'formik'
import {Button, FadePresence, Form, FormikInput, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React, {FC, useState} from 'react'
import {useAlert} from 'react-alert'
import * as yup from 'yup'
import {Illustrations} from '~/components/Illustrations'
import {WizardLayout} from '~/components/WizardLayout'
import IceFishing from '~/illustrations/ice_fishing.png'
import {getGraphQLErrorMessage} from '~/util'
import {useSendResetPasswordEmail} from './Login/useSendResetPasswordEmail'

const emailSchema = yup.object({
    email: yup
        .string()
        .required()
        .email(),
})

export const ForgotPassword: FC = () => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const alert = useAlert()
    const [email, setEmail] = useState<string>('')

    const {sendEmail: forgotPasswordMutation} = useSendResetPasswordEmail()

    const handleSubmit = async (values: {email: string}) => {
        try {
            await forgotPasswordMutation({
                variables: {email: values.email},
            })
            setEmail(values.email)
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <FadePresence exitBeforeEnter itemKey={email ? 'checkInbox' : 'forgotPassword'}>
                    {email ? (
                        <>
                            <Typography.Heading>Check your inbox</Typography.Heading>
                            <Spacer height={16} />
                            <Typography.Subheading maxWidth={520}>
                                We’ve sent a new email to {email}. Please, click the link to reset your password.
                            </Typography.Subheading>
                        </>
                    ) : (
                        <>
                            <Typography.Heading>Enter email address</Typography.Heading>
                            <Spacer height={16} />
                            <Typography.Subheading maxWidth={472}>
                                To reset your password, enter the email address you use to sign in to Names &amp; Faces.
                            </Typography.Subheading>
                            <Spacer height={32} />
                            <Formik
                                isInitialValid={false}
                                onSubmit={(values) => {
                                    trackAnalyticsEvent('select_get_reset_link')
                                    handleSubmit(values)
                                }}
                                validationSchema={emailSchema}
                                initialValues={{email: ''}}
                            >
                                {({handleSubmit, isValid, isSubmitting}) => {
                                    return (
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group name="email">
                                                <FormikInput autoFocus type="email" hideError />
                                            </Form.Group>
                                            <Button type="submit" disabled={!isValid} loading={isSubmitting}>
                                                Get reset link
                                                <Button.Icon icon={SvgArrowRight} />
                                            </Button>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </>
                    )}
                </FadePresence>
            </WizardLayout.Body>
            <Illustrations RightIllutration={IceFishing} />
        </WizardLayout>
    )
}
