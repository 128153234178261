import styled, {css} from 'styled-components'
import {Typography} from '../Typography'
import {motion, Variants} from 'framer-motion'

export const HoverLabel = styled(Typography.Paragraph)`
    opacity: 0;
    transition: 0.3s opacity ease-in-out;
`

HoverLabel.defaultProps = {
    bottomMargin: false,
}

export const showSidebarLabelOnHover = css`
    :hover ${HoverLabel} {
        opacity: 1;
    }
`
export const listVariants: Variants = {
    open: {
        height: 'auto',
        transition: {
            duration: 0.3,
        },
    },
    collapsed: {
        height: 0,
        transition: {
            duration: 0.3,
        },
    },
}

export const hideVariants: Variants = {
    open: {
        opacity: 1,
        transition: {
            duration: 0.3,
        },
    },
    collapsed: {
        opacity: 0,
        transition: {
            duration: 0.3,
        },
    },
}

export const ListContainer = styled(motion.div)`
    position: relative;
    overflow: hidden;
    ${showSidebarLabelOnHover}
`

ListContainer.defaultProps = {
    variants: listVariants,
    initial: 'collapsed',
    animate: 'open',
    exit: 'collapsed',
}
