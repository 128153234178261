import {ApolloClient, gql} from '@apollo/client'
import {OAuthProvider} from '~/globalTypes'
import {OAuthSuccessMessage} from './authWindow'
import {GenerateOAuthCredentials, GenerateOAuthCredentialsVariables} from './__types__/GenerateOAuthCredentials'

export const GENERATE_OAUTH_CREDENTIALS = gql`
    query GenerateOAuthCredentials($oAuthProvider: OAuthProvider!, $code: String!) {
        generateOAuthCredentials(oAuthProvider: $oAuthProvider, code: $code) {
            token
            refreshToken
        }
    }
`

export const generateOAuthCredentials = async (
    client: ApolloClient<object>,
    oAuthResponse: OAuthSuccessMessage,
    oAuthProvider: OAuthProvider,
) => {
    const result = await client.mutate<GenerateOAuthCredentials, GenerateOAuthCredentialsVariables>({
        mutation: GENERATE_OAUTH_CREDENTIALS,
        variables: {
            oAuthProvider,
            code: oAuthResponse.code,
        },
    })

    return result.data!.generateOAuthCredentials
}
