import {MutationResult} from '@apollo/client'
import {List} from 'nf-ui'
import React, {useState} from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {useUploadPhotoQueue} from '~/components/PhotoManagementCommon'
import {useProfileFilter} from '~/components/useProfileFilter'
import {useProfileSort} from '../useProfileSort'
import {UploadPhoto} from '../__types__/UploadPhoto'
import {AdminProfile, AdminProfileProps} from './AdminProfile'
import {Header} from './Header'

export type UploadStatusType = MutationResult<UploadPhoto>

const AdminProfilesList: React.FC<{
    profiles: AdminProfileProps['profile'][]
    search: string
    profilesLoading: string[]
    uploadPhotos: ReturnType<typeof useUploadPhotoQueue>['addItems']
}> = ({profiles, search, uploadPhotos, profilesLoading}) => {
    const [selectedProfileIdStr, setSelectedProfileIdStr] = useState<string | null>(null)
    const profileSort = useProfileSort(profiles)
    const profileFilter = useProfileFilter(profileSort.profiles)

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Header count={profileFilter.profiles.length} profileFilter={profileFilter} profileSort={profileSort} />
            <div style={{flex: 1}}>
                <AutoSizer>
                    {({width, height}) => (
                        <List
                            rows={profileFilter.profiles}
                            image
                            firstChildBorder
                            renderRow={(profile, wrapperRef) => (
                                <AdminProfile
                                    key={profile.idStr}
                                    profile={profile}
                                    search={search}
                                    open={profile.idStr === selectedProfileIdStr}
                                    uploadPhotos={uploadPhotos}
                                    loading={profilesLoading.includes(profile.idStr)}
                                    wrapperRef={wrapperRef}
                                    onClose={() => {
                                        setSelectedProfileIdStr(null)
                                    }}
                                />
                            )}
                            onClick={(profile) => {
                                setSelectedProfileIdStr(profile.idStr === selectedProfileIdStr ? null : profile.idStr)
                            }}
                            width={width}
                            maxHeight={height}
                        />
                    )}
                </AutoSizer>
            </div>
        </div>
    )
}

export {AdminProfilesList}
