import React, {ReactElement} from 'react'
import {Tab, TabProps} from './Tab'
import styled from 'styled-components'
import {colors} from '../theme'
import {motion} from 'framer-motion'
import {useMeasureMultiple, useRefs} from './useMeasureMultiple'
import {FadePresence} from '../FadePresence'
import {Spacer} from '../Spacer'

type TabElement = React.FunctionComponentElement<TabProps>

interface TabsProps {
    children: TabElement | TabElement[]
    activeTabIndex: number
    onChangeActiveTabIndex: (activeTabIndex: number) => void

    /**
     * Whether the Tabs component should fill its parent. This is effectively
     * `display: block` instead of `inline-block`.
     *
     * This does not affect individual tab items but rather extends the bottom border
     * of the tabs component to fill its parent.
     */
    fullWidth?: boolean
}

interface TabsType extends React.FC<TabsProps> {
    Tab: typeof Tab
}

const Container = styled.div<{fullWidth: boolean}>`
    display: ${({fullWidth}) => (fullWidth ? 'flex' : 'inline-flex')};
    flex-direction: row;
    align-items: flex-start;
    position: relative;
`

const Border = styled.div`
    border-bottom: 1px solid ${colors.darkGray};
`

const ActiveBorder = styled(motion.div)`
    position: relative;
    margin: 0;
    padding: 0;
    margin-top: -2px;
    border-bottom: 2px solid ${({theme}) => theme.primary.color};
`

const TAB_SPACING = 32 as const

const Tabs: TabsType = ({children, activeTabIndex, onChangeActiveTabIndex, fullWidth = false}) => {
    const refs = useRefs<HTMLDivElement>(children)
    const tabRects = useMeasureMultiple(refs)
    const tabCount = React.Children.toArray(children).length

    return (
        <div style={{display: 'inline-block', minWidth: 0}}>
            <div style={{overflowX: 'auto', paddingBottom: 16, marginBottom: -16}}>
                <Container fullWidth={fullWidth}>
                    {React.Children.map<ReactElement, TabElement>(children, (tab, index) => (
                        <>
                            <div
                                style={{flexShrink: 0}}
                                ref={refs[index]}
                                key={index}
                                onClick={() => activeTabIndex !== index && onChangeActiveTabIndex(index)}
                            >
                                {React.cloneElement(tab, {
                                    selected: index === activeTabIndex,
                                })}
                            </div>
                            {tabCount - 1 !== index && <Spacer width={TAB_SPACING} />}
                        </>
                    ))}
                </Container>
                <FadePresence>
                    {activeTabIndex > -1 && (
                        <ActiveBorder
                            animate={{
                                width: tabRects[activeTabIndex].width,
                                translateX: tabRects
                                    .slice(0, activeTabIndex)
                                    .reduce((sum, tabRect) => sum + tabRect.width + TAB_SPACING, 0),
                            }}
                            transition={{type: 'spring', stiffness: 350, damping: 350}}
                            initial={false}
                        />
                    )}
                </FadePresence>
            </div>
            <Border />
        </div>
    )
}

Tabs.Tab = Tab

export {Tabs}
