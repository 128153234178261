import * as React from 'react'
const SvgUnlink = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgUnlink(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M.96.176C.678-.054.357-.054.17.147a.581.581 0 00-.063.685l.055.072 7.115 7.099L5.27 9.965a.577.577 0 000 .766c.162.174.493.194.695.055l.07-.06 1.911-1.98 7.124 7.108c.181.195.579.195.76 0a.583.583 0 00.063-.687l-.055-.072L.96.176zM4.23 7.03c-.165-.178-.438-.198-.674-.058l-.087.06-1.112 1.186C.781 9.72.781 12.15 2.364 13.658a3.767 3.767 0 005.22.147l.156-.15 1.19-1.163a.578.578 0 000-.767c-.165-.178-.438-.198-.674-.058l-.087.06-1.11 1.183a2.888 2.888 0 01-4.03-.01c-.962-1.04-1.004-2.691-.116-3.793l.127-.147 1.19-1.163a.578.578 0 000-.767zm9.407-4.688C12.204.891 9.905.841 8.334 2.187l-.165.15-.914.973.698.745.988-.966c1.025-.996 2.618-1.08 3.651-.249l.137.12.13.13a2.74 2.74 0 01.138 3.809l-.128.133-.953 1.015.728.713.993-.97a3.89 3.89 0 00.285-5.124l-.137-.165-.148-.159zM10.76 5.3c-.252-.205-.534-.228-.73-.083l-.07.062-.385.383.772.754.382-.38a.6.6 0 00.122-.575l-.038-.087-.053-.074z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgUnlink
