import {colors} from 'nf-ui'
import React from 'react'
import styled from 'styled-components'
import {DIRECT_LINE_OFFSET, GUTTER, INDENT, LINE_WIDTH, PROFILE_ROW_HEIGHT, REPORTS_COUNT_HEIGHT} from './constants'

const VerticalLine = styled.div.attrs({className: 'org-chart-line'})<{
    verticalHeight: number | null
    top?: number
    left?: number
    additional?: boolean
}>`
    position: absolute;
    height: ${(props) => (props.verticalHeight ? props.verticalHeight + LINE_WIDTH : 0)}px;
    top: ${(props) => (props.top ? props.top : 0)}px;
    left: ${(props) => (props.left ? props.left : -(INDENT - DIRECT_LINE_OFFSET))}px;
    transform: translateX(-50%);
    border-left: ${LINE_WIDTH}px;
    border-left-style: ${({additional}) => (additional ? 'dashed' : 'solid')};
    border-left-color: ${({additional}) => (additional ? '#A0A6AA' : colors.black)};
    border-bottom-left-radius: 3px;
    z-index: -3;
    transition: opacity 0.3s ease-in-out, border-color 0.3s ease-in-out;
`

export const GridVerticalLines = ({
    gridContainerHeight,
    siblings,
}: {
    gridContainerHeight: number
    siblings?: boolean
}) => {
    const topLineOffset = PROFILE_ROW_HEIGHT + REPORTS_COUNT_HEIGHT
    const topLineHeight = GUTTER + gridContainerHeight / 2 - REPORTS_COUNT_HEIGHT

    const bottomLineOffset = PROFILE_ROW_HEIGHT + GUTTER + gridContainerHeight / 2
    const bottomLineHeight = gridContainerHeight / 2 + GUTTER
    return (
        <>
            <VerticalLine verticalHeight={topLineHeight} left={DIRECT_LINE_OFFSET} top={topLineOffset} />
            {siblings && (
                <VerticalLine verticalHeight={bottomLineHeight} left={DIRECT_LINE_OFFSET} top={bottomLineOffset} />
            )}
        </>
    )
}

export type RowVerticalLine = {
    visible: boolean
    additional: boolean
}

export const BrickVerticalLines = ({
    grid,
    containerHeight,
    isFirstChild,
    topLine,
    bottomLine,
}: {
    grid?: number
    containerHeight: number
    isFirstChild?: boolean
    topLine?: RowVerticalLine
    bottomLine?: RowVerticalLine
}) => {
    if (grid) return null

    const verticalOffset = isFirstChild ? REPORTS_COUNT_HEIGHT - GUTTER : 0
    const bottomLineHeight = containerHeight - PROFILE_ROW_HEIGHT / 2

    return (
        <>
            {topLine?.visible && (
                <VerticalLine
                    verticalHeight={PROFILE_ROW_HEIGHT / 2 - verticalOffset}
                    additional={topLine?.additional}
                    top={verticalOffset}
                />
            )}

            {bottomLine?.visible && (
                <VerticalLine
                    verticalHeight={bottomLineHeight}
                    top={PROFILE_ROW_HEIGHT / 2}
                    additional={bottomLine?.additional}
                />
            )}
        </>
    )
}
