import {gql} from '@apollo/client'
import {Button, Spacer, Typography} from 'nf-ui'
import React, {FC} from 'react'
import {ModalLayout} from '~/components/ModalLayout'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {SelectionList} from '~/components/SelectionList/SelectionList'
import {useSelectionContext, useSetSelectedItems, SelectionProvider} from '~/components/SelectionList/SelectionState'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {InviteStatus} from '~/globalTypes'
import {useInviteUsersMutation} from './useInviteUsersMutation'
import {InviteUsersModalData} from './__types__/InviteUsersModalData'

export const INVITE_USERS_MODAL_DATA = gql`
    fragment InviteUsersModalData on OrganisationObject {
        idStr
        users {
            idStr
            email
            inviteStatus
            isRevoked
        }
    }
`

const InviteFooter = ({onClose}: {onClose: () => void}) => {
    const {selection} = useSelectionContext()

    const organisationIdStr = useOrganisationIdStr()
    const {inviteUsers} = useInviteUsersMutation()

    const onClick = () => {
        inviteUsers({
            organisationIdStr,
            userIdStrs: selection,
        })
        onClose()
    }
    return (
        <ModalLayout.Footer>
            <Button disabled={selection.length === 0} variant="primary" onClick={onClick}>
                Send emails
            </Button>
        </ModalLayout.Footer>
    )
}

export const InviteUsersModal: FC<{data: InviteUsersModalData}> = ({data}) => {
    const {onClose} = useModalNavigator()
    const {setSelectedIdStrs} = useSetSelectedItems()

    const onCloseModal = () => {
        onClose()
        setSelectedIdStrs(undefined)
    }

    const users = data.users
        .filter((user) => !!user.email && user.inviteStatus === InviteStatus.PENDING)
        .map((user) => ({
            idStr: user.idStr,
            label: user.email || '',
            disabled: !!user.isRevoked,
            directoryAccess: !user.isRevoked,
        }))

    return (
        <ModalLayout>
            <ModalLayout.Navigation onClose={onCloseModal} />

            <SelectionProvider initialState={{availableItems: users}}>
                <ModalLayout.Body>
                    <Typography.Heading>Invite users</Typography.Heading>
                    <Spacer height={16} />
                    <Typography.Subheading maxWidth={725}>
                        Invite users who have directory access with a welcome email
                    </Typography.Subheading>
                    <Spacer height={32} />
                    <SelectionList
                        items={users}
                        rowLabel="Email address"
                        disabledMessage="No directory access"
                        sortOptions={[
                            {
                                key: 'DIRECTORY_ACCESS_ENABLED',
                                label: 'Directory access enabled',
                                sort: (a, b) =>
                                    a.directoryAccess === b.directoryAccess ? 0 : a.directoryAccess ? -1 : 1,
                            },
                            {
                                key: 'DIRECTORY_ACCESS_DISABLED',
                                label: 'Directory access disabled',
                                sort: (a, b) =>
                                    a.directoryAccess === b.directoryAccess ? 0 : a.directoryAccess ? 1 : -1,
                            },
                        ]}
                    />
                    <Spacer height={64} />
                </ModalLayout.Body>
                <InviteFooter onClose={onCloseModal} />
            </SelectionProvider>
        </ModalLayout>
    )
}
