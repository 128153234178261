import {gql, useQuery} from '@apollo/client'
import {uniqBy} from 'lodash'
import {useCallback, useMemo} from 'react'
import {useQueryParam} from 'use-query-params'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {
    FullOrgChartData,
    FullOrgChartDataVariables,
    FullOrgChartData_organisation_profiles,
} from './__types__/FullOrgChartData'

export type Profile = FullOrgChartData_organisation_profiles

export const FULL_ORG_CHART_DATA = gql`
    query FullOrgChartData($idStr: String!) {
        organisation(idStr: $idStr) {
            idStr
            name
            profiles(includeOpenPositions: true) {
                idStr
                firstName
                lastName
                fullName
                secondaryLine
                reportsTo
                directReports
                additionalManagers
                additionalReports
                openPosition
                openPositionJobLink
                originalManagerIdStr
                photos {
                    idStr
                    profileUrl
                    signedProfileUrl
                    tinyUrl
                    signedTinyUrl
                    priority
                }
                profileLines {
                    idStr
                    value
                    label
                    type
                    extraData {
                        fieldIdStr
                        childCategories {
                            idStr
                        }
                    }
                }
            }
            logo {
                idStr
                height
                file {
                    idStr
                    signedUrl
                }
            }
            fields {
                idStr
                label
                type
                visible
                hideable
                priority
            }
        }
    }
`

function getReportingLine(profile: Profile, getProfileFromIdStr: (profileIdStr: string) => Profile | null): Profile[] {
    const allDirectReports = profile.directReports
        .map((idStr) => getProfileFromIdStr(idStr))
        .filter((profile) => !!profile) as Profile[]

    let withoutDirectReports = allDirectReports.filter(
        (profile) => profile.directReports.length + profile.additionalReports.length === 0,
    ) as Profile[]

    if (withoutDirectReports.length > 3) {
        withoutDirectReports = withoutDirectReports.sort((a, b) =>
            a!.fullName.toLocaleLowerCase().localeCompare(b!.fullName.toLocaleLowerCase()),
        ) as Profile[]
    }

    const withDirectReports = allDirectReports
        .filter((profile) => profile.directReports.length + profile.additionalReports.length > 0)
        .sort((a, b) => {
            const aReportCounts = a!.additionalReports.length + a!.directReports.length
            const bReportCounts = b!.additionalReports.length + b!.directReports.length
            return aReportCounts - bReportCounts
        }) as Profile[]

    const additionalReports = profile.additionalReports
        .map((idStr) => getProfileFromIdStr(idStr))
        .filter((profile) => !!profile) as Profile[]

    const reportingLines = [
        ...[...withoutDirectReports, ...withDirectReports].flatMap((directReport) => {
            return getReportingLine(directReport, getProfileFromIdStr)
        }),
        ...additionalReports,
    ]

    return [profile, ...reportingLines]
}

function getTopLevelProfiles(profiles: Profile[], selectedTopLevelProfile: Profile | null) {
    if (selectedTopLevelProfile) {
        return [[selectedTopLevelProfile], []]
    }

    const allTopLevelProfiles: Profile[] = []
    const orphanedProfiles: Profile[] = []

    for (const profile of profiles) {
        if (!profile.reportsTo) {
            if (profile.directReports.length || profile.additionalReports.length) {
                allTopLevelProfiles.push(profile)
            } else {
                orphanedProfiles.push(profile)
            }
        }
    }

    return [
        allTopLevelProfiles.sort((a, b) => (a.directReports.length > b.directReports.length ? -1 : 1)),
        orphanedProfiles,
    ]
}

export const updateDirectReportsOf = (profile: Profile, editedProfileIdStr: string, newManagerIdStr: string) => {
    const isNewManager = profile.idStr === newManagerIdStr
    const isCurrentManager = profile.directReports.includes(editedProfileIdStr)
    if (!isNewManager && !isCurrentManager) return

    if (isCurrentManager) {
        profile.directReports = profile.directReports.filter((idStr) => idStr !== editedProfileIdStr)
    } else if (isNewManager) {
        profile.directReports = [...profile.directReports, editedProfileIdStr]
    }
}

export const useFullOrgChartData = () => {
    const organisationIdStr = useOrganisationIdStr()

    const {data, error, loading} = useQuery<FullOrgChartData, FullOrgChartDataVariables>(FULL_ORG_CHART_DATA, {
        variables: {
            idStr: organisationIdStr,
        },
    })

    const profileIdStrMap = useMemo(() => {
        const profiles = data?.organisation.profiles || []
        const profileIdStrMap: Record<string, Profile> = {}

        for (const profile of profiles) {
            profileIdStrMap[profile.idStr] = profile
        }

        return profileIdStrMap
    }, [data])

    const getProfileFromIdStr = useCallback(
        (profileIdStr: string) => {
            return profileIdStrMap[profileIdStr] || null
        },
        [profileIdStrMap],
    )

    const [profileId] = useQueryParam<string>('topLevelProfile')
    const selectedTopLevelProfile = profileId ? getProfileFromIdStr(profileId) : null

    const [topLevelProfiles, orphanedProfiles] = useMemo(() => {
        if (!data?.organisation.profiles) return [[], []]
        const profiles = data.organisation.profiles

        return getTopLevelProfiles(profiles, selectedTopLevelProfile)
    }, [data?.organisation.profiles, selectedTopLevelProfile])

    const {uniqueProfiles, visibleProfiles} = useMemo(() => {
        const visibleProfiles = topLevelProfiles.flatMap((profile) => getReportingLine(profile, getProfileFromIdStr))
        const uniqueProfiles = uniqBy(visibleProfiles, 'idStr')

        return {uniqueProfiles, visibleProfiles}
    }, [getProfileFromIdStr, topLevelProfiles])

    const {categoryProfileCount, categoryProfiles} = useMemo(() => {
        const categoryProfiles: Record<string, string[]> = {}

        for (const profile of uniqueProfiles) {
            profile.profileLines.forEach((line) => {
                const categories = line.extraData.childCategories
                categories?.forEach((category) => {
                    const current = categoryProfiles[category.idStr] || []
                    if (!current.includes(profile.idStr)) {
                        current.push(profile.idStr)
                    }
                    categoryProfiles[category.idStr] = current
                })
            })
        }

        const categoryProfileCount = (categoryIdStr: string) => categoryProfiles[categoryIdStr]?.length || 0

        return {categoryProfileCount, categoryProfiles}
    }, [uniqueProfiles])

    const branchView = !!selectedTopLevelProfile

    return {
        data,
        loading,
        error,
        branchView,
        topLevelProfiles,
        orphanedProfiles,
        uniqueProfiles,
        visibleProfiles,
        getProfileFromIdStr,
        categoryProfileCount,
        categoryProfiles,
    }
}
