import {Icon, IconContainer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgOpenLink from 'nf-ui/Icons/OpenLink'
import React, {FC} from 'react'
import styled from 'styled-components'
import {redirectToLegacy} from '~/util/legacy'
import {SystemAccessOption} from '../../globalTypes'
import {useSystemAcessCheck} from '../useSystemAcessCheck'

const Container = styled.div`
    display: flex;
    margin-bottom: 16px;
    margin-left: 16px;
    align-items: center;
    cursor: pointer;
    ${IconContainer} {
        margin-right: 16px;
    }
`

export const LegacyViewSwitcher: FC = () => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const {systemAccessCheck} = useSystemAcessCheck()

    if (!systemAccessCheck || !systemAccessCheck.includes(SystemAccessOption.LEGACY)) return null

    return (
        <Container
            onClick={() => {
                trackAnalyticsEvent('select_switch_account')
                redirectToLegacy()
            }}
        >
            <Icon icon={SvgOpenLink} />
            <Typography.Paragraph bottomMargin={false}>Switch Account</Typography.Paragraph>
        </Container>
    )
}
