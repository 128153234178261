import {useState} from 'react'

export type ProfileFilter = 'UNMATCHED' | 'MATCHED' | 'ALL'

export function useProfileFilter<ProfileType extends {photo?: any; idStr: string; fullName: string; createdAt: number}>(
    profiles: ProfileType[],
    initialFilter: ProfileFilter = 'ALL',
) {
    const [filter, setFilter] = useState<ProfileFilter>(initialFilter)

    let filteredProfiles = profiles
    if (filter === 'UNMATCHED') filteredProfiles = profiles.filter((profile) => !profile.photo)
    else if (filter === 'MATCHED') filteredProfiles = profiles.filter((profile) => !!profile.photo)

    return {
        profiles: filteredProfiles,
        setFilter,
        filter,
    }
}
