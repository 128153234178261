import {useState} from 'react'

//Don't attempt to use yup even when using schema.validate({abortEarly:false}) some validations will not show up

export const hasUpper = (v = '') => /[A-Z]/.test(v)
export const hasLower = (v = '') => /[a-z]/.test(v)
export const hasSpecial = (v = '') => /\W/.test(v)
export const hasLength = (v = '') => v.trim().length >= 8

export type PasswordState = {
    password: string
    setPassword: React.Dispatch<React.SetStateAction<string>>
    upperValid: boolean
    lowerValid: boolean
    specialValid: boolean
    lengthValid: boolean
    valid: boolean
}

export function usePasswordUpdate(): PasswordState {
    const [password, setPassword] = useState('')
    const upperValid = hasUpper(password)
    const lowerValid = hasLower(password)
    const specialValid = hasSpecial(password)
    const lengthValid = hasLength(password)
    const valid = upperValid && lowerValid && specialValid && lengthValid

    return {
        password,
        setPassword,
        upperValid,
        lowerValid,
        specialValid,
        lengthValid,
        valid,
    }
}
