import styled from 'styled-components'

const BaseButton = styled.button.attrs(() => ({
    type: 'button',
}))`
    outline: 0;
    transition: 0.25s box-shadow ease-in-out;
    border: 0;
    cursor: pointer;
    padding: 0;
    -webkit-appearance: none;
    background-color: transparent;
`

export {BaseButton}
