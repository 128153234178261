import {gql, MutationFunctionOptions, useMutation} from '@apollo/client'
import {Button, colors, Spacer} from 'nf-ui'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {getGraphQLErrorMessage} from '~/util'
import {SendOTPEmail, SendOTPEmailVariables} from './__types__/SendOTPEmail'

const INPUT_SIZE = 40

const InputContainer = styled.div`
    display: flex;
`

const ResendEmailButton = styled(Button)`
    color: ${colors.black};
    :hover {
        color: ${colors.black};
    }
`

const Input = styled.input<{focused: boolean; disabled: boolean}>`
    width: ${INPUT_SIZE}px;
    height: ${INPUT_SIZE}px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    border: ${(props) => {
        if (props.disabled) {
            return `1px solid ${colors.darkGray}`
        } else if (props.focused) {
            return `1px solid ${colors.primary[100]}`
        }
        return '1px solid #101010'
    }};
    border-radius: 3px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
    :focus {
        outline: none;
    }
`

function useKeyPress(targetKey: string, onKeyPress: () => void) {
    function downHandler(event: KeyboardEvent) {
        if (event.key === targetKey) {
            event.preventDefault()
            onKeyPress()
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler)
        return () => {
            window.removeEventListener('keydown', downHandler)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

const useFocusInput = (focused: boolean) => {
    const input = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (focused) input.current?.focus()
    }, [focused])

    return input
}

const SingleInput = ({
    index,
    setFocusedInput,
    focused,
    value,
    focusInput,
    setInputValue,
    handlePaste,
    disabled,
}: {
    index: number
    value: string
    setFocusedInput: React.Dispatch<React.SetStateAction<number>>
    focused: boolean
    focusInput: (index: number) => void
    setInputValue: (index: number, value: string) => void
    handlePaste: (value: string) => void
    disabled: boolean
}) => {
    const input = useFocusInput(focused)
    const setListener = useRef(false)

    useEffect(() => {
        if (input.current && !setListener.current) {
            input.current.addEventListener('paste', (event) => {
                //@ts-ignore
                const paste = (event.clipboardData || window.clipboardData).getData('text')
                handlePaste(paste)
                event.preventDefault()
            })
            setListener.current = true
        }
    }, [handlePaste, input])

    const onBackSpace = useCallback(() => {
        if (focused) {
            if (value.length) {
                setInputValue(index, '')
            } else if (index !== 0) {
                setFocusedInput((current) => current - 1)
            }
        }
    }, [focused, index, setFocusedInput, setInputValue, value])

    useKeyPress('Backspace', onBackSpace)

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Input
                ref={input}
                value={value}
                autoFocus={index === 0}
                autoComplete="off"
                type="number"
                maxLength={1}
                focused={focused}
                onFocus={() => focusInput(index)}
                onChange={({target: {value}}) => {
                    setInputValue(index, value)
                }}
                disabled={disabled}
            />
        </div>
    )
}

const RESET_COUNTDOWN = 60

const SEND_OTP_MUTATION = gql`
    mutation SendOTPEmail($email: String!) {
        sendOTPEmail(email: $email)
    }
`

const useSendOTPEmail = (email: string) => {
    const alert = useAlert()
    const resend = useRef(false)
    const [sendOTPEmail] = useMutation<SendOTPEmail, SendOTPEmailVariables>(SEND_OTP_MUTATION, {
        variables: {email},
        onCompleted: () => {
            if (!resend.current) {
                resend.current = true
            } else {
                alert.success('Successfully resent your email code.')
            }
        },
        onError: (error) => alert.error(getGraphQLErrorMessage(error)),
    })

    return [sendOTPEmail]
}

const ResendButton = ({
    resend,
}: {
    resend: (options?: MutationFunctionOptions<SendOTPEmail, SendOTPEmailVariables> | undefined) => void
}) => {
    const [disabled, setDisabled] = useState(false)
    const [seconds, setSeconds] = useState<number>(RESET_COUNTDOWN)

    useEffect(() => {
        if (!disabled) return

        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000)
        } else {
            setDisabled(false)
        }
    }, [seconds, disabled])

    const handleResend = () => {
        resend()
        setDisabled(true)
        setSeconds(RESET_COUNTDOWN)
    }

    return (
        <ResendEmailButton disabled={disabled} variant="link" onClick={handleResend}>
            {disabled ? `Didn't recieve an email? Resend in ${seconds} seconds` : 'Resend email code'}
        </ResendEmailButton>
    )
}

const emptyArrayOfLength = (length: number) => Array.from({length}).fill('') as string[]

export const OTPInput = ({
    length = 6,
    onSubmit,
    email,
    disabled,
    setInputDisabled,
}: {
    onSubmit?: (pin: string) => void
    length?: number
    email: string
    disabled: boolean
    setInputDisabled: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const [focusedInput, setFocusedInput] = useState(0)
    const [values, setValues] = useState<string[]>(emptyArrayOfLength(length))
    const [sendOTPEmail] = useSendOTPEmail(email)

    useEffect(() => {
        sendOTPEmail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isFocused = (index: number) => focusedInput === index
    const isLastInput = (index: number) => length - 1 === index

    const focusInput = (index: number) => {
        setFocusedInput((current) => {
            if (current !== index) return index
            return current
        })
    }

    const handlePaste = (string: string) => {
        if (string && string.trim().length !== length) return
        const values = string.trim().split('')
        setValues(values)
        handleSubmit(values)
        setFocusedInput(length - 1)
    }

    const handleSubmit = (values: string[]) => {
        const otp = values.filter((value) => value.length).join('')
        if (otp.length === length) {
            if (otp) {
                setInputDisabled(true)
                onSubmit?.(otp)
            }
        }
    }

    const setInputValue = (index: number, value: string) => {
        if (value.length > 1) return
        setValues((current) => {
            const updated = [...current]
            updated[index] = value

            handleSubmit(updated)
            return updated
        })
        if (value.length && !isLastInput(index)) setFocusedInput((current) => current + 1)
    }

    return (
        <>
            <InputContainer>
                {values.map((value, index) => {
                    return (
                        <>
                            <SingleInput
                                value={value}
                                key={`${index}_${focusedInput}_${value}`}
                                index={index}
                                setFocusedInput={setFocusedInput}
                                focused={isFocused(index)}
                                focusInput={focusInput}
                                setInputValue={setInputValue}
                                handlePaste={handlePaste}
                                disabled={disabled}
                            />
                            {index !== length - 1 && <Spacer width={8} />}
                        </>
                    )
                })}
            </InputContainer>
            <Spacer height={32} />
            <ResendButton
                resend={() => {
                    sendOTPEmail()
                    setValues(emptyArrayOfLength(length))
                    setFocusedInput(0)
                }}
            />
        </>
    )
}
