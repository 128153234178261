import {gql, useMutation} from '@apollo/client'
import {
    Button,
    Columns,
    Form,
    highlightMatchedText,
    List,
    MultiColumnList,
    Panel,
    Spacer,
    Typography,
    useAnalyticsEventTracker,
    useSort,
} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import pluralize from 'pluralize'
import React, {FC, useCallback, useRef, useState} from 'react'
import {useAlert} from 'react-alert'
import AutoSizer from 'react-virtualized-auto-sizer'
import * as yup from 'yup'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {ModalLayout} from '~/components/ModalLayout'
import {FOOTER_HEIGHT} from '~/components/ModalLayout/Footer'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {PanelLayout, PanelLayoutButtons} from '~/components/PanelLayout'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {NAVIGATION_HEIGHT} from '~/components/WizardLayout/Navigation'
import {USERS_DATA} from '../Users'
import {UsersPageData, UsersPageDataVariables} from '../__types__/UsersPageData'
import {AddGuestsModalData, AddGuestsModalData_profiles} from './__types__/AddGuestsModalData'
import {
    AddGuestsModal_AddAndInvite,
    AddGuestsModal_AddAndInviteVariables,
} from './__types__/AddGuestsModal_AddAndInvite'

export const ADD_GUESTS_MODAL_DATA = gql`
    fragment AddGuestsModalData on OrganisationObject {
        idStr
        autoInvite
        profiles {
            idStr
            fullName
            firstName
            lastName
            email
            hasLinkedUser
        }
        users {
            idStr
            email
        }
    }
`

const ADD_AND_INVITE_USERS = gql`
    mutation AddGuestsModal_AddAndInvite($organisationIdStr: String!, $userEmails: [String!]!) {
        addAndInviteUsers(organisationIdStr: $organisationIdStr, userEmails: $userEmails) {
            idStr
            firstName
            lastName
            email
            role
            inviteStatus
            isRevoked
        }
    }
`

type NewUser =
    | {
          type: 'profile'
          profile: AddGuestsModalData_profiles
      }
    | {
          type: 'email'
          email: string
      }

const newUserEmails = (newUsers: NewUser[]) => {
    return newUsers.map((user) => {
        return user.type === 'email' ? user.email : user.profile.email
    })
}

const emailValidator = yup
    .string()
    .email()
    .required()

const columns: Columns<NewUser> = [
    {
        name: 'Email address',
        width: 200,
        value: (user) => (user.type === 'profile' ? user.profile.email : user.email),
    },
    {
        name: 'First name',
        width: 140,
        value: (user) => (user.type === 'profile' ? user.profile.firstName : ''),
    },
    {
        name: 'Last name',
        width: 140,
        value: (user) => (user.type === 'profile' ? user.profile.lastName : ''),
    },
]

function useSearchProfiles(profiles: AddGuestsModalData['profiles'], users: AddGuestsModalData['users']) {
    const {success} = useAlert()
    const [query, setQuery] = useState('')
    const lowercaseQuery = query.toLowerCase()
    const [newUsers, setNewUsers] = useState<NewUser[]>([])
    const isValidEmail = emailValidator.isValidSync(query)
    const userEmails = users.map((u) => u.email?.toLowerCase())
    const addUser = useCallback(
        (user: NewUser) => {
            if (user.type === 'email' && users.some((u) => u.email?.toLowerCase() === user.email.toLowerCase())) {
                success(`User with e-mail ${user.email} is already invited`)
                return
            }
            setNewUsers((newUsers) => [...newUsers, user])
        },
        [setNewUsers, users, success],
    )

    const removeNewUser = useCallback(
        (newUser: NewUser) => {
            setNewUsers((newUsers) => newUsers.filter((nU) => nU !== newUser))
        },
        [setNewUsers],
    )

    const unselectedProfiles = profiles
        .filter((profile) => {
            // Exclude profiles that already have a linked user
            if (profile.hasLinkedUser) return false

            if (userEmails.includes(profile.email.toLowerCase())) return false
            // Exclude profiles that have already been selected
            return !newUsers.find((newUser) => {
                return newUser.type === 'profile' && newUser.profile.idStr === profile.idStr
            })
        })
        .sort((a, b) => a.firstName.localeCompare(b.firstName))

    const searchProfiles =
        query.length === 0
            ? unselectedProfiles
            : unselectedProfiles.filter((profile) => {
                  return profile.fullName.toLowerCase().includes(lowercaseQuery)
              })

    return {
        query,
        setQuery,
        newUsers,
        addUser,
        removeNewUser,
        lowercaseQuery,
        unselectedProfiles,
        searchProfiles,
        isValidEmail,
    }
}

type UseSearchProfilesState = ReturnType<typeof useSearchProfiles>
type PanelInviteProps = UseSearchProfilesState & {onClose?: () => void}

const PANEL_WIDTH = 304

const PanelProfileList: FC<UseSearchProfilesState> = ({query, searchProfiles, addUser, setQuery}) => {
    return (
        <List
            width={PANEL_WIDTH}
            rows={searchProfiles}
            renderRow={({fullName}) => highlightMatchedText(fullName, query, 500)}
            onClick={(profile) => {
                addUser({
                    type: 'profile',
                    profile,
                })

                setQuery('')
            }}
        />
    )
}

const PanelNotFound: FC = () => (
    <PanelLayout width={PANEL_WIDTH}>
        <Typography.Label>No result</Typography.Label>
        <Typography.Paragraph>
            Type a full email address to invite someone outside of your directory.
        </Typography.Paragraph>
    </PanelLayout>
)

const PanelInvite: FC<PanelInviteProps> = ({query, setQuery, addUser, onClose}) => (
    <PanelLayout width={PANEL_WIDTH}>
        <Typography.Label>Invite {query}</Typography.Label>
        <Typography.Paragraph>
            This user doesn't exist in your organisation. Would you like to invite them?
        </Typography.Paragraph>
        <PanelLayoutButtons>
            <Button variant="tertiary" onClick={() => setQuery('')} onClickAnalyticsEvent="select_no_invite">
                No, don't invite
            </Button>
            <Button
                variant="tertiary"
                onClick={() => {
                    addUser({email: query, type: 'email'})
                    setQuery('')
                    onClose && onClose()
                }}
                onClickAnalyticsEvent="select_yes_invite"
            >
                Yes, invite
            </Button>
        </PanelLayoutButtons>
    </PanelLayout>
)

export const AddGuestsModal: FC<{data: AddGuestsModalData}> = ({data}) => {
    const alert = useAlert()
    const {hrisProvider} = CurrentOrganisation.useContainer()
    const organisationIdStr = useOrganisationIdStr()
    const inputRef = useRef<HTMLInputElement>(null)
    const [panelOpen, setPanelOpen] = useState(false)
    const {onClose} = useModalNavigator()
    const searchProfileState = useSearchProfiles(data.profiles, data.users)
    const {query, setQuery, newUsers, searchProfiles, isValidEmail, removeNewUser} = searchProfileState
    const {autoInvite} = data

    const [addAndInviteUsers, {loading: inviteUsersLoading}] = useMutation<
        AddGuestsModal_AddAndInvite,
        AddGuestsModal_AddAndInviteVariables
    >(ADD_AND_INVITE_USERS, {
        update: (cache, result) => {
            const data = cache.readQuery<UsersPageData, UsersPageDataVariables>({
                query: USERS_DATA,
                variables: {idStr: organisationIdStr},
            })

            if (!data || !result.data) return

            cache.writeQuery<UsersPageData, UsersPageDataVariables>({
                query: USERS_DATA,
                variables: {idStr: organisationIdStr},
                data: {
                    ...data,
                    organisation: {
                        ...data.organisation,
                        users: [
                            ...data.organisation.users,
                            ...result.data.addAndInviteUsers.map((user) => ({...user, profiles: []})),
                        ],
                    },
                },
            })
        },
    })

    const sort = useSort({
        rows: newUsers,
        columns,
    })

    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const inviteNewUsers = async (newUsers: NewUser[]) => {
        try {
            await addAndInviteUsers({
                variables: {
                    userEmails: newUserEmails(newUsers),
                    organisationIdStr,
                },
                refetchQueries: [
                    {
                        query: USERS_DATA,
                        variables: {
                            idStr: organisationIdStr,
                        },
                    },
                ],
                awaitRefetchQueries: true,
            })
            alert.success(`${newUsers.length + pluralize(' user', newUsers.length)} successfully invited.`)
            onClose()
        } catch (err) {
            alert.error(`Failed to invite ${pluralize('user', newUsers.length)}`)
        }
    }

    function setSyncInviteUserPanel() {
        if (isValidEmail && autoInvite && query.length !== 0) setPanelOpen(true)
    }

    const autoInvitePanel = isValidEmail && query.length !== 0 && (
        <PanelInvite
            key="invite"
            {...searchProfileState}
            onClose={() => {
                setPanelOpen(false)
            }}
        />
    )

    const manualSyncPanel =
        searchProfiles.length === 0 ? (
            isValidEmail ? (
                <PanelInvite key="invite" {...searchProfileState} />
            ) : (
                <PanelNotFound key="notfound" />
            )
        ) : (
            <PanelProfileList key="profile" {...searchProfileState} />
        )

    return (
        <ModalLayout>
            <ModalLayout.Navigation />
            <ModalLayout.Body
                style={{
                    height: `calc(100% - ${FOOTER_HEIGHT + NAVIGATION_HEIGHT}px)`,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography.Heading>Add guests</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading maxWidth={560}>
                    Grant directory viewing access to someone outside of your {hrisProvider} data by entering their
                    email.
                </Typography.Subheading>
                <Spacer height={32} />
                <Form.Group name="search">
                    <div ref={inputRef}>
                        <Form.SearchInput
                            value={query}
                            onChange={(value) => {
                                setQuery(value)
                                setSyncInviteUserPanel()
                            }}
                            onFocus={() => {
                                if (!autoInvite) setPanelOpen(true)
                                setSyncInviteUserPanel()
                            }}
                            onBlur={() => setPanelOpen(false)}
                        />
                    </div>
                </Form.Group>
                <Panel targetRef={inputRef} open={panelOpen} onClose={() => setPanelOpen(false)}>
                    {autoInvite ? autoInvitePanel : manualSyncPanel}
                </Panel>
                <Spacer height={32} />
                {newUsers.length > 0 && (
                    <div style={{flex: 1}}>
                        <AutoSizer>
                            {({width, height}) => (
                                <MultiColumnList
                                    columns={columns}
                                    rows={newUsers}
                                    width={width}
                                    maxHeight={height}
                                    sort={sort}
                                    actions={{
                                        button: {
                                            icon: SvgClose,
                                            label: 'Remove',
                                        },
                                        onClick: (newUser) => {
                                            removeNewUser(newUser)
                                            trackAnalyticsEvent('select_remove')
                                        },
                                    }}
                                />
                            )}
                        </AutoSizer>
                    </div>
                )}
            </ModalLayout.Body>
            <ModalLayout.Footer back={false}>
                {newUsers.length > 0 && (
                    <Typography.Paragraph style={{margin: '32px'}}>
                        {newUsers.length} {pluralize('user', newUsers.length)} added
                    </Typography.Paragraph>
                )}
                <Button
                    disabled={newUsers.length === 0}
                    onClick={() => inviteNewUsers(newUsers)}
                    loading={inviteUsersLoading}
                    onClickAnalyticsEvent="confirm_invite"
                >
                    Invite
                </Button>
            </ModalLayout.Footer>
        </ModalLayout>
    )
}
