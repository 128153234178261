import {Spacer, Typography} from 'nf-ui'
import React, {FC} from 'react'
import {PageLoading} from '~/components/PageLoading'
import {WelcomeLaout} from '~/components/WelcomeLayout'
import {ADPSignInButton} from './ADPSignInButton'
import {useWelcomeDetails} from './useWelcomeDetails'

export const WelcomeAdp: FC = () => {
    const {name, company, loading} = useWelcomeDetails()
    if (loading) return <PageLoading />

    return (
        <WelcomeLaout>
            <Typography.Heading>Hi {name}</Typography.Heading>
            <Spacer height={16} />
            <Typography.Subheading maxWidth={415}>
                Join {company}’s visual people directory by signing in with your ADP credentials
            </Typography.Subheading>
            <Spacer height={32} />
            <ADPSignInButton />
            <Spacer height={64} />
        </WelcomeLaout>
    )
}
