import React, {useRef} from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {ListChildComponentProps, VariableSizeList} from 'react-window'
import {MemoizedProfile} from '~/components/Profile'
import {profilesFor} from '../SmartGroup'
import {
    HomeData_organisation_categoryChangesThisMonth,
    HomeData_organisation_smartGroups,
    HomeData_organisation_smartGroups_profiles_profile,
} from '../__types__/HomeData'
import {InsightPanel} from './InsightPanel'
import {useScrollBooster} from '~/components/HorizontalProfilesList/useScrollbooster'
import {StyledVariableSizeList} from '~/components/HorizontalProfilesList'
import {PHOTO_HEIGHT, PHOTO_WIDTH} from '~/components/Photo/Photo'
import styled, {useTheme} from 'styled-components'
import {hex, hsl} from 'color-convert'
import {easeInOutQuadCSS, Icon, Spacer, Typography} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import {useRelativeRoute} from '~/components/useRelativeRoute'
import pluralize from 'pluralize'
import {getThumbLinesByProfile} from '~/components/ProfilesGrid/ProfilesGrid'
import {OrganisationDataWithTypes, useOrganisationContext} from '~/components/OrganisationContext'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'

const SCROLLBAR_HEIGHT = 16
const GAP = 2
const PADDING = 24

const LIMIT = 14
const VIEW_ALL_INDEX = LIMIT - 1
const VIEW_ALL_WIDTH = PHOTO_WIDTH * 2 + GAP

type SmartGroupProfile = HomeData_organisation_smartGroups_profiles_profile
type SmartGroup = HomeData_organisation_smartGroups | HomeData_organisation_categoryChangesThisMonth

const hoverColor = (hexColor: string) => {
    const [h, s, l] = hex.hsl(hexColor)
    return '#' + hsl.hex([h, s, l * 0.85])
}

const ViewAllButton = styled.div`
    height: ${PHOTO_HEIGHT}px;
    width: ${VIEW_ALL_WIDTH}px;
    background-color: ${({theme}) => theme.primary.color};
    display: flex;
    position: absolute;
    cursor: pointer;
    justify-content: center;
    border-radius: 3px;
    align-items: center;
    transition: background 300ms ${easeInOutQuadCSS};

    :hover {
        background: ${({theme}) => hoverColor(theme.primary.color)};
    }
`

const viewAllButtonLabel = (smartGroup: HomeData_organisation_smartGroups) => {
    const LABELS: Record<string, string> = {
        upcomingBirthdays: 'Birthdays',
        workAnniversary: 'Work Anniversaries',
        newJoiners: 'New Joiners',
    }

    const label = LABELS[smartGroup.idStr]
    return label || 'profiles'
}

const Profile: React.FC<ListChildComponentProps> = ({data, index, style}) => {
    const [dataEditValues] = useOrganisationContext.useDataEditValues()
    const [dataEditFields] = useOrganisationContext.useDataEditFields()
    const [profileLines] = useOrganisationContext.useProfileLines()
    const {currentOrganisation} = useCurrentOrganisation()

    const theme = useTheme()
    const {pushRelative} = useRelativeRoute()
    const {profiles, smartGroup} = data
    const label = viewAllButtonLabel(smartGroup)

    if (index === LIMIT - 1)
        return (
            <ViewAllButton
                style={{left: Number(style.left) + PADDING}}
                onClick={() => pushRelative(`/insights/${smartGroup.idStr}`, {keepSearch: true})}
            >
                <Typography.Label color={theme.primary.textColor}> View all {label}</Typography.Label>
                <Spacer width={16} />

                <Icon icon={SvgArrowRight} tint={theme.primary.textColorHex} />
            </ViewAllButton>
        )

    const profile = profiles[index]
    const linesByProfile = getThumbLinesByProfile(
        {...data.data, dataEditValues, dataEditFields, profileLines},
        {
            numberOfLines: 2,
            removeProfileLineGaps: currentOrganisation?.appFeatures?.masterDirectory,
        },
    )

    return (
        <MemoizedProfile
            style={{...style, left: Number(style.left) + PADDING}}
            profile={profile}
            lines={linesByProfile[profile.idStr]}
        />
    )
}

const itemSize = (index: number) => (index === VIEW_ALL_INDEX ? VIEW_ALL_WIDTH + GAP : PHOTO_WIDTH + GAP)

const ProfileList = ({
    width,
    listRef,
    profiles,
    smartGroup,
    data,
}: {
    width: number
    listRef: React.RefObject<VariableSizeList>
    profiles: SmartGroupProfile[]
    smartGroup: HomeData_organisation_smartGroups
    data: Omit<OrganisationDataWithTypes, 'profiles'>
}) => {
    const lastScrollTo = useRef(0)
    const {scrollBooster} = useScrollBooster({variableSizeListRef: listRef})

    const scrollTo = (x: number) => {
        scrollBooster.current?.scrollTo({x})
        lastScrollTo.current = x
    }

    return (
        <StyledVariableSizeList
            ref={listRef}
            className="list"
            height={PHOTO_HEIGHT + SCROLLBAR_HEIGHT}
            itemCount={profiles.length}
            itemData={{profiles, scrollTo, smartGroup, data}}
            itemSize={itemSize}
            layout="horizontal"
            width={width + PADDING * 2}
            overscanCount={20}
            padding={PADDING + GAP}
        >
            {Profile}
        </StyledVariableSizeList>
    )
}

const smartGroupInsightLabel = (smartGroup: SmartGroup) => {
    if (smartGroup.weeklyCount === null) return smartGroup.label

    if (smartGroup.weeklyCount === 0) {
        switch (smartGroup.idStr) {
            case 'upcomingBirthdays':
                return `No birthdays this week, but see who's celebrating theirs soon  🎈`
            case 'workAnniversary':
                return `No work anniversaries this week, but see who's celebrating theirs soon  🎂`
            case 'newJoiners':
                return `No new joiners this week, but see the most recent people to have joined  🎉`
            default:
                return smartGroup.label
        }
    }

    switch (smartGroup.idStr) {
        case 'upcomingBirthdays':
            return `${smartGroup.weeklyCount} Upcoming ${pluralize('birthday', smartGroup.weeklyCount)}  🎈`
        case 'workAnniversary':
            return `${smartGroup.weeklyCount} Upcoming work ${pluralize('anniversary', smartGroup.weeklyCount)}  🎂`
        case 'newJoiners':
            return `${smartGroup.weeklyCount} Recent ${pluralize('joiner', smartGroup.weeklyCount)}  🎉`
        default:
            return smartGroup.label
    }
}

export const SmartGroupInsight: React.FC<{
    smartGroup: HomeData_organisation_smartGroups
    startExpanded?: boolean
    data: Omit<OrganisationDataWithTypes, 'profiles'>
}> = ({data, smartGroup, startExpanded = false}) => {
    const listRef = useRef<VariableSizeList>(null)
    const profiles = profilesFor(smartGroup)

    return (
        <InsightPanel label={smartGroupInsightLabel(smartGroup)} startExpanded={startExpanded}>
            <AutoSizer disableHeight>
                {({width}) => (
                    <ProfileList
                        listRef={listRef}
                        profiles={profiles.slice(0, LIMIT)}
                        data={data}
                        width={width}
                        smartGroup={smartGroup}
                    />
                )}
            </AutoSizer>
        </InsightPanel>
    )
}
