import styled from 'styled-components'
import {HideableCss, PositionCss} from './css'
import {HideableCssProps, PositionCssProps} from './types'

export const Row = styled.div<
    {
        position?: string
        height?: string
        width?: string
        maxWidth?: string
        grow?: number
        shrink?: number
        overflow?: string
        wrapLine?: boolean
        backgroundColor?: string
        borderRadius?: string
        padding?: string
        centerHorizontal?: boolean
        alignLeft?: boolean
        cursor?: string
    } & HideableCssProps &
        PositionCssProps
>`
    display: flex;
    flex-direction: row;
    justify-content: ${({alignLeft, centerHorizontal}) =>
        alignLeft ? 'flex-start' : centerHorizontal ? 'center' : 'space-between'};
    ${({backgroundColor}) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}
    ${({borderRadius}) => (borderRadius ? `border-radius: ${borderRadius};` : '')}
    ${({padding}) => (padding ? `padding: ${padding};` : '')}
    ${({wrapLine}) => wrapLine && `flex-wrap: wrap;`}
    ${({height}) => (height ? `height: ${height};` : '')}
    ${({width}) => (width ? `width: ${width};` : '')}
    ${({maxWidth}) => (maxWidth ? `max-width: ${maxWidth};` : '')}
    ${({grow}) => (grow !== undefined ? `flex-grow: ${grow};` : '')}
    ${({shrink}) => (shrink !== undefined ? `flex-shrink: ${shrink};` : '')}
    ${({overflow}) => (overflow !== undefined ? `overflow: ${overflow};` : '')}
    ${({cursor}) => (cursor !== undefined ? `cursor: ${cursor};` : '')}
    ${HideableCss}
    ${PositionCss}
`
