import {
    maxRowsFor,
    GUTTER,
    HEADER_HEIGHT,
    LINE_SIZE,
    MAX_PAGE_HEIGHT,
    Orientation,
    PAGE_PADDING,
    PROFILE_ROW_HEIGHT,
} from './constants'
import {getGridDimensions, ProfileRow} from './reportingLine'

export type VerticalLine = {
    page: number
    indentation: number
    start: number
    end: number
}

const isGrid = (row: ProfileRow) => {
    return row.profiles.length > 1
}

type RowData = {
    page: number
    row: number
    gridRows: number | null
}

const rowDataById = (id: string, pages: ProfileRow[][], orientation: Orientation, start = 1): RowData => {
    let currentPage = start
    let row = 0
    let found = false
    let gridRows = null

    for (let index = start - 1; index < pages.length; index++) {
        row = 0
        currentPage = index + 1
        const page = pages[index]
        const maxRows = maxRowsFor({orientation, page: index + 1})

        for (let index = 0; index < page.length; index++) {
            const profileRow = page[index]

            if (isGrid(profileRow)) {
                row += getGridDimensions({gridSize: profileRow.profiles.length, maxRows}).rows
            } else {
                row += 1
            }

            if (profileRow.id === id) {
                if (isGrid(profileRow)) {
                    gridRows = getGridDimensions({gridSize: profileRow.profiles.length, maxRows}).rows
                }
                found = true
                break
            }
        }

        if (found) {
            break
        }
    }

    return {page: currentPage, row, gridRows}
}

const ROW_HEIGHT = PROFILE_ROW_HEIGHT + GUTTER
const getHeaderOffset = (page: number) => (page === 1 ? HEADER_HEIGHT : 0)
const heightOfRow = ({row, page, includeHeader}: {row: number; page: number; includeHeader: boolean}) => {
    const headerOffset = includeHeader ? getHeaderOffset(page) : 0
    return PAGE_PADDING + ROW_HEIGHT * row + headerOffset
}

const buildMultiPageLines = ({
    pageDiff,
    indentation,
    from,
    to,
    orientation,
    endRowOffset,
    includeHeader,
}: {
    pageDiff: number
    indentation: number
    from: RowData
    to: RowData
    orientation: Orientation
    endRowOffset: number
    includeHeader: boolean
}): VerticalLine[] => {
    const isFirstPage = (index: number) => index === 0
    const isLastPage = (index: number) => index === pageDiff
    const MAX_LINE_HEIGHT = MAX_PAGE_HEIGHT[orientation]

    return Array.from({length: pageDiff + 1}).map((_, index) => {
        if (isFirstPage(index)) {
            return {
                page: from.page + index,
                indentation,
                start: heightOfRow({row: from.row - 1, page: from.page, includeHeader}) + PROFILE_ROW_HEIGHT,
                end: MAX_LINE_HEIGHT,
            }
        } else if (isLastPage(index)) {
            return {
                page: from.page + index,
                indentation,
                start: PAGE_PADDING,
                end:
                    heightOfRow({row: to.row - 1, page: to.page, includeHeader}) +
                    PROFILE_ROW_HEIGHT -
                    endRowOffset +
                    LINE_SIZE,
            }
        } else {
            return {
                page: from.page + index,
                indentation,
                start: PAGE_PADDING,
                end: MAX_LINE_HEIGHT,
            }
        }
    })
}

const linesFor = ({
    from,
    to,
    indentation = 0,
    orientation,
    includeHeader,
    fromChildRow,
}: {
    from: RowData
    to: RowData
    indentation: number
    orientation: Orientation
    includeHeader: boolean
    fromChildRow?: boolean
}) => {
    const rowOffsetFor = (row: RowData) => (row.gridRows ? (ROW_HEIGHT * row.gridRows) / 2 : PROFILE_ROW_HEIGHT / 2)

    const pageDiff = to.page - from.page
    const startRowOffset = fromChildRow ? rowOffsetFor(from) : 0
    const endRowOffset = rowOffsetFor(to)
    const onSamePage = pageDiff === 0

    if (onSamePage) {
        return [
            {
                page: from.page,
                indentation,
                start:
                    heightOfRow({row: from.row - 1, page: from.page, includeHeader}) +
                    PROFILE_ROW_HEIGHT -
                    startRowOffset,
                end:
                    heightOfRow({row: to.row - 1, page: to.page, includeHeader}) -
                    endRowOffset +
                    PROFILE_ROW_HEIGHT +
                    LINE_SIZE,
            },
        ]
    }

    return buildMultiPageLines({
        from,
        to,
        indentation,
        pageDiff,
        orientation,
        endRowOffset,
        includeHeader,
    })
}

export const getVerticalLines = (
    reportingLine: ProfileRow[],
    paginatedReportingLine: ProfileRow[][],
    orientation: Orientation,
    includeHeader: boolean,
): {solidLines: VerticalLine[]; dottedLines: VerticalLine[]} => {
    const solidLines = []
    const dottedLines = []

    let currentPage = 1

    for (let index = 0; index < reportingLine.length; index++) {
        const profileRow = reportingLine[index]
        let lastChildPage: RowData | null = null

        if (profileRow.lastChildId) {
            const rowPage = rowDataById(profileRow.id, paginatedReportingLine, orientation, currentPage)
            lastChildPage = rowDataById(profileRow.lastChildId, paginatedReportingLine, orientation, currentPage)

            solidLines.push(
                ...linesFor({
                    from: rowPage,
                    to: lastChildPage,
                    indentation: profileRow.indentation,
                    orientation,
                    includeHeader,
                }),
            )
            currentPage = rowPage.page
        }

        if (profileRow.lastAdditionalChildId) {
            const rowPage = rowDataById(profileRow.id, paginatedReportingLine, orientation, currentPage)
            const lastAdditionalChildPage = rowDataById(
                profileRow.lastAdditionalChildId,
                paginatedReportingLine,
                orientation,
                currentPage,
            )

            dottedLines.push(
                ...linesFor({
                    from: lastChildPage || rowPage,
                    to: lastAdditionalChildPage,
                    indentation: profileRow.indentation,
                    orientation,
                    includeHeader,
                    fromChildRow: !!lastChildPage,
                }),
            )

            currentPage = rowPage.page
        }
    }

    return {solidLines, dottedLines}
}

export const linesForPage = (page: number, lines: VerticalLine[]) => {
    return lines.filter((line) => line.page === page)
}
