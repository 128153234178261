import React from 'react'
import {useDropdownContext} from '.'
import {InputLabel} from '../Form/Input'
import {WhiteButton} from '../WhiteButton'
import {IconType} from '../Icon'
import SvgCaret from '../Icons/Caret'

const Button: React.FC<{
    onClick?: () => void
    error?: string
    selected?: boolean
    disabled?: boolean
    icon?: IconType
}> = ({children, onClick, error, selected, disabled, icon = SvgCaret}) => {
    const dropdownContext = useDropdownContext()
    const {targetRef, setOpen, open, buttonTarget} = dropdownContext || {}

    return (
        <>
            <WhiteButton
                type="button"
                onClick={() => {
                    if (disabled) return
                    if (onClick) {
                        onClick()
                    } else {
                        setOpen && setOpen(!open)
                    }
                }}
                error={!!error}
                selected={!!selected}
                disabled={!!disabled}
                ref={buttonTarget ? (targetRef as any) : undefined}
                iconRef={!buttonTarget ? (targetRef as any) : undefined}
                icon={icon}
            >
                {children}
            </WhiteButton>
            {error && <InputLabel color="red">{error}</InputLabel>}
        </>
    )
}

export {Button}
