import React, {useState} from 'react'
import {from} from 'fromfrom'
import {createContext, useContext} from 'react'
import {
    DataEditGetFields_dataEditFields,
    DataEditGetFields_dataEditFields_baseFields,
} from '~/apollo/queries/__types__/DataEditGetFields'
import {DataEditGetValues_dataEditValues} from '~/apollo/queries/__types__/DataEditGetValues'
import {ProfileLines_profileLines} from '~/apollo/queries/__types__/ProfileLines'
import {ApolloError} from '@apollo/client'
import {MaybeSearchResult} from '~/util/search'
import {orderBy} from 'lodash'

export type TProfile = {
    idStr: string
    firstName: string
    lastName: string
    fullName: string
    secondaryLine?: string | null
    photos?:
        | {
              idStr: string
              thumbUrl: string
              priority?: number | null
          }[]
        | null
    directReports?: string[]
}

export interface OrganisationDataWithTypes {
    profiles: MaybeSearchResult<TProfile>[]
    organisation?: {
        appFeatures?: {
            masterDirectory?: boolean
        }
    }
    error?: ApolloError
    loading?: boolean
}

export type FieldType =
    | 'text'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'mobileNumber'
    | 'landlineNumber'
    | 'category'
    | 'date'
    | 'slack'
    | 'msTeams'
    | 'skype'
    | 'url'

export type FieldSubType = string | null

export type ProfileLine = ProfileLines_profileLines & {fieldBehaviour: FieldBehaviour}
export type DataEditField = DataEditGetFields_dataEditFields & {
    type: FieldType
    subType: FieldSubType
    baseFields: (DataEditGetFields_dataEditFields_baseFields & {type: FieldType; subType: FieldSubType})[] | null
}
export type DataEditValue = DataEditGetValues_dataEditValues

export type FieldBehaviour =
    | 'text'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'mobileNumber'
    | 'landlineNumber'
    | 'Category'
    | 'date'
    | 'slack'
    | 'msTeams'
    | 'skype'
    | 'url'

export type OrganisationContextType = {
    dataEditValues: DataEditValue[]
    dataEditValuesByProfileIdStr: Record<string, DataEditValue[]>
    hiddenValues: DataEditValue[]
    hiddenValuesByProfileIdStr: Record<string, DataEditValue[]>
    dataEditFields: DataEditField[]
    profileLines: ProfileLine[]
}

const OrganisationContext = {
    dataEditValues: createContext<{value: DataEditValue[]; setValue: (value: DataEditValue[]) => void} | undefined>(
        undefined,
    ),
    dataEditValuesByProfileIdStr: createContext<
        {value: Record<string, DataEditValue[]>; setValue: (value: Record<string, DataEditValue[]>) => void} | undefined
    >(undefined),
    hiddenValues: createContext<{value: DataEditValue[]; setValue: (value: DataEditValue[]) => void} | undefined>(
        undefined,
    ),
    hiddenValuesByProfileIdStr: createContext<
        {value: Record<string, DataEditValue[]>; setValue: (value: Record<string, DataEditValue[]>) => void} | undefined
    >(undefined),
    dataEditFields: createContext<{value: DataEditField[]; setValue: (value: DataEditField[]) => void} | undefined>(
        undefined,
    ),
    profileLines: createContext<{value: ProfileLine[]; setValue: (value: ProfileLine[]) => void} | undefined>(
        undefined,
    ),
    organisationRefreshing: createContext<
        | {
              value: {loading: boolean; error?: ApolloError} | undefined
              setValue: (value: {loading: boolean; error?: ApolloError} | undefined) => void
          }
        | undefined
    >(undefined),
}

export const OrganisationContextProvider: React.FC = ({children}) => {
    const [dataEditValues, setDataEditValues] = useState<DataEditValue[]>([])
    const [dataEditValuesByProfileIdStr, setDataEditValuesByProfileIdStr] = useState<Record<string, DataEditValue[]>>(
        {},
    )
    const [hiddenValues, setHiddenValues] = useState<DataEditValue[]>([])
    const [hiddenValuesByProfileIdStr, setHiddenValuesByProfileIdStr] = useState<Record<string, DataEditValue[]>>({})
    const [dataEditFields, setDataEditFields] = useState<DataEditField[]>([])
    const [profileLines, setProfileLines] = useState<ProfileLine[]>([])
    const [refreshing, setRefreshing] = useState<{loading: boolean; error?: ApolloError} | undefined>()
    return (
        <OrganisationContext.dataEditValues.Provider value={{value: dataEditValues, setValue: setDataEditValues}}>
            <OrganisationContext.dataEditValuesByProfileIdStr.Provider
                value={{value: dataEditValuesByProfileIdStr, setValue: setDataEditValuesByProfileIdStr}}
            >
                <OrganisationContext.hiddenValues.Provider value={{value: hiddenValues, setValue: setHiddenValues}}>
                    <OrganisationContext.hiddenValuesByProfileIdStr.Provider
                        value={{value: hiddenValuesByProfileIdStr, setValue: setHiddenValuesByProfileIdStr}}
                    >
                        <OrganisationContext.dataEditFields.Provider
                            value={{value: dataEditFields, setValue: setDataEditFields}}
                        >
                            <OrganisationContext.profileLines.Provider
                                value={{value: profileLines, setValue: setProfileLines}}
                            >
                                <OrganisationContext.organisationRefreshing.Provider
                                    value={{
                                        value: refreshing,
                                        setValue: setRefreshing,
                                    }}
                                >
                                    {children}
                                </OrganisationContext.organisationRefreshing.Provider>
                            </OrganisationContext.profileLines.Provider>
                        </OrganisationContext.dataEditFields.Provider>
                    </OrganisationContext.hiddenValuesByProfileIdStr.Provider>
                </OrganisationContext.hiddenValues.Provider>
            </OrganisationContext.dataEditValuesByProfileIdStr.Provider>
        </OrganisationContext.dataEditValues.Provider>
    )
}

export const useOrganisationContext = {
    useDataEditValues: () => {
        const {value, setValue} = useContext(OrganisationContext.dataEditValues)!
        return [value, setValue] as [DataEditValue[], (value: DataEditValue[]) => void]
    },
    useDataEditValuesByProfileIdStr: () => {
        const {value, setValue} = useContext(OrganisationContext.dataEditValuesByProfileIdStr)!
        return [value, setValue] as [Record<string, DataEditValue[]>, (value: Record<string, DataEditValue[]>) => void]
    },
    useHiddenValues: () => {
        const {value, setValue} = useContext(OrganisationContext.hiddenValues)!
        return [value, setValue] as [DataEditValue[], (value: DataEditValue[]) => void]
    },
    useHiddenValuesByProfileIdStr: () => {
        const {value, setValue} = useContext(OrganisationContext.hiddenValuesByProfileIdStr)!
        return [value, setValue] as [Record<string, DataEditValue[]>, (value: Record<string, DataEditValue[]>) => void]
    },
    useDataEditFields: () => {
        const {value, setValue} = useContext(OrganisationContext.dataEditFields)!
        return [value, setValue] as [DataEditField[], (value: DataEditField[]) => void]
    },
    useProfileLines: () => {
        const {value, setValue} = useContext(OrganisationContext.profileLines)!
        return [value, setValue] as [ProfileLine[], (value: ProfileLine[]) => void]
    },
    useOrganisationRefreshing: () => {
        const {value, setValue} = useContext(OrganisationContext.organisationRefreshing)!
        return [value, setValue] as [
            {loading: boolean; error?: ApolloError} | undefined,
            (value: {loading: boolean; error?: ApolloError} | undefined) => void,
        ]
    },
}

export const hydrateProfileLineTypes = (profileLine: ProfileLines_profileLines) => ({
    ...profileLine,
    fieldBehaviour: profileLine.fieldBehaviour as FieldBehaviour,
})

const applyDataEditFieldOrder = (dataEditField: {
    type: string
    subType: string | null
    availableValues: string[]
    availableValueIdStrs: string[]
    availableValueLabels: string[] | null
}) => {
    if (dataEditField.type === 'category' && dataEditField.subType?.split(';')?.includes('orderCustom')) {
        return dataEditField
    }
    const availableValues = dataEditField.availableValues || []
    const ordered = orderBy(
        availableValues.map((value, index) => dataEditField.availableValueLabels?.[index] || value),
        (value) => value,
    )
    const indexes = ordered.map((item) => availableValues.indexOf(item))
    return {
        ...dataEditField,
        availableValues: ordered,
        availableValueIdStrs: indexes.map((index) => dataEditField.availableValueIdStrs?.[index]),
        availableValueLabels: dataEditField.availableValueLabels
            ? indexes.map((index) => dataEditField.availableValueLabels![index])
            : null,
    }
}

export const hydrateDataEditFieldTypes = (dataEditField: DataEditGetFields_dataEditFields) => ({
    ...dataEditField,
    ...applyDataEditFieldOrder(dataEditField),
    type: dataEditField.type as FieldType,
    subType: dataEditField.subType as FieldSubType,
    baseFields:
        dataEditField.baseFields?.map((field) => ({
            ...field,
            ...applyDataEditFieldOrder(field),
            type: field.type as FieldType,
            subType: field.subType as FieldSubType,
        })) || null,
})

export const orderPhotos = <T extends {priority?: number | null}>(
    photos: T[] | null | undefined,
    day: number = Math.floor(new Date().valueOf() / 86400000),
): T[] => {
    const withPriority = from(photos || [])
        .sortBy((photo) => photo.priority)
        .take(2)
        .toArray()
        .map((photo, index) => ({...photo, priority: index + 1}))

    if (!withPriority?.length) return withPriority
    const index = day % withPriority.length
    return [withPriority[index], ...withPriority.slice(0, index), ...withPriority.slice(index + 1)]
}

export const convertDataEditValuesByProfileIdStr = (values: DataEditValue[]) => {
    const result: Record<string, DataEditValue[]> = {}

    values.forEach((value) => {
        const key = value.profileIdStr
        if (result[key]) {
            result[key].push(value)
        } else {
            result[key] = [value]
        }
    })

    return result
}

export const convertDataEditValuesIndexed = (values: DataEditValue[]) => {
    const result: Record<string, DataEditValue[]> = {}

    values.forEach((value) => {
        const key = `${value.profileIdStr}.${value.fieldOrParentCategoryIdStr}`
        if (result[key]) {
            result[key].push(value)
        } else {
            result[key] = [value]
        }
    })

    return result
}
