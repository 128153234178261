import * as React from 'react'
const SvgUsersBold = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgUsersBold(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M8.3 7.641a2.493 2.493 0 00-2.49 2.49v4.98a.83.83 0 11-1.66 0V10.101a2.828 2.828 0 01-.69.017.741.741 0 01-.14.013h-.36a1.3 1.3 0 00-1.3 1.304v2.016a.83.83 0 11-1.66 0v-2.016c0-1.055.55-1.981 1.38-2.507a2.906 2.906 0 013.61-4.322c-.01-.094-.01-.189-.01-.286C4.98 2.487 6.47 1 8.3 1c1.83 0 3.32 1.487 3.32 3.32 0 .097 0 .192-.01.286a2.905 2.905 0 013.61 4.322 2.963 2.963 0 011.38 2.507v2.016a.83.83 0 11-1.66 0v-2.016a1.3 1.3 0 00-1.3-1.304h-.36c-.05 0-.1-.005-.14-.013a2.864 2.864 0 01-.69-.017v5.01a.83.83 0 11-1.66 0v-4.98c0-1.376-1.11-2.49-2.49-2.49zm0-1.661c-.92 0-1.66-.743-1.66-1.66 0-.916.74-1.66 1.66-1.66.92 0 1.66.744 1.66 1.66 0 .917-.74 1.66-1.66 1.66zm-4.56 0c-.69 0-1.25.558-1.25 1.246A1.246 1.246 0 103.74 5.98zm9.13 0c-.69 0-1.25.558-1.25 1.246a1.246 1.246 0 101.25-1.246z"
                fill="#3C8EFF"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgUsersBold
