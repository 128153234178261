import {gql, useQuery} from '@apollo/client'
import {Formik} from 'formik'
import {Button, Form, FormikInput, Spacer, Typography} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React, {useEffect, useState} from 'react'
import {useAlert} from 'react-alert'
import * as Yup from 'yup'
import {TokenValidResult} from '~/globalTypes'
import {getGraphQLErrorMessage} from '~/util'
import {PageLoading} from './PageLoading'
import {WizardLayout} from './WizardLayout'
import {IsTokenValid, IsTokenValidVariables} from './__types__/IsTokenValid'

const IS_TOKEN_VALID = gql`
    query IsTokenValid($token: String!) {
        isTokenValid(token: $token)
    }
`

type OnResendComplete = {onResendComplete: () => void}

const emailSchema = Yup.object({
    email: Yup.string()
        .required()
        .email(),
})

const InvalidToken: React.FC<ValidateTokenProps['invalid'] & OnResendComplete> = ({
    label,
    onResend,
    onResendComplete,
}) => {
    const [loading, setLoading] = useState(false)
    const alert = useAlert()

    return (
        <>
            <Typography.Heading>That link isn't working</Typography.Heading>
            <Spacer height={16} />
            <Typography.Subheading maxWidth={472}>{label}</Typography.Subheading>
            <Spacer height={32} />
            <Formik
                isInitialValid={false}
                onSubmit={async ({email}) => {
                    setLoading(true)
                    try {
                        await onResend(email)
                        onResendComplete()
                    } catch (error) {
                        alert.error(getGraphQLErrorMessage(error))
                    }
                    setLoading(false)
                }}
                validationSchema={emailSchema}
                initialValues={{email: ''}}
            >
                {({handleSubmit, isValid}) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group name="email">
                            <FormikInput autoFocus type="email" hideError />
                        </Form.Group>
                        <Button type="submit" disabled={!isValid} loading={loading}>
                            Request new link
                            <Button.Icon icon={SvgArrowRight} />
                        </Button>
                    </Form>
                )}
            </Formik>
        </>
    )
}

const ExpiredToken: React.FC<ValidateTokenProps['expired'] & OnResendComplete> = ({
    label,
    onResend,
    onResendComplete,
}) => {
    const [loading, setLoading] = useState(false)
    const alert = useAlert()

    return (
        <>
            <Typography.Heading>That link isn’t working</Typography.Heading>
            <Spacer height={16} />
            <Typography.Subheading maxWidth={461}>{label}</Typography.Subheading>
            <Spacer height={32} />
            <Button
                onClick={async () => {
                    setLoading(true)
                    try {
                        await onResend()
                        onResendComplete()
                    } catch (error) {
                        alert.error(getGraphQLErrorMessage(error))
                    }
                    setLoading(false)
                }}
                loading={loading}
            >
                Request a new one
            </Button>
        </>
    )
}

const CheckInbox: React.FC<ValidateTokenProps['checkInbox']> = ({label}) => (
    <>
        <Typography.Heading>Check your inbox</Typography.Heading>
        <Spacer height={16} />
        <Typography.Subheading maxWidth={520}>{label}</Typography.Subheading>
    </>
)

type ValidateTokenProps = {
    token: string
    expired: {
        label: string
        onResend: () => Promise<any>
    }
    checkInbox: {
        label: string
    }
    invalid: {
        label: string
        onResend: (email: string) => Promise<any>
    }
    valid: {
        process: () => Promise<any> | void
    }
}

const ProcessToken: React.FC<ValidateTokenProps['valid']> = ({process}) => {
    useEffect(() => {
        process()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <PageLoading />
}

export const ValidateToken: React.FC<ValidateTokenProps> = ({token, expired, checkInbox, invalid, valid}) => {
    const {data, error, loading} = useQuery<IsTokenValid, IsTokenValidVariables>(IS_TOKEN_VALID, {
        variables: {token},
        fetchPolicy: 'network-only',
    })

    const [resent, setResent] = useState(false)
    const onResendComplete = () => setResent(true)

    if (error) {
        return (
            <div>
                <Typography.Heading>Error</Typography.Heading>
                <Typography.Paragraph>{getGraphQLErrorMessage(error)}</Typography.Paragraph>
            </div>
        )
    }

    if (loading || !data) {
        return <PageLoading />
    }

    let content = null

    if (data.isTokenValid === TokenValidResult.Expired) {
        content = <ExpiredToken {...expired} onResendComplete={onResendComplete} />
    }

    if (data.isTokenValid === TokenValidResult.Invalid) {
        content = <InvalidToken {...invalid} onResendComplete={onResendComplete} />
    }

    if (data.isTokenValid === TokenValidResult.Valid) {
        return <ProcessToken {...valid} />
    }

    if (resent) {
        content = <CheckInbox {...checkInbox} />
    }

    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>{content}</WizardLayout.Body>
        </WizardLayout>
    )
}
