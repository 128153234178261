import {v4} from 'uuid'
import {getCollapsedProfileCount} from '../FullOrgChart/getCollapsedProfileCount'
import {getProfileReports} from '../FullOrgChart/getProfileReports'
import {FullOrgChartData_organisation_profiles} from '../__types__/FullOrgChartData'
import {maxRowsFor, GRID_COLUMNS, Orientation} from './constants'

type Profile = FullOrgChartData_organisation_profiles

export type ProfileRow = {
    id: string
    indentation: number
    profiles: Profile[]
    reportsCount: number
    lastChildId: string | null
    lastAdditionalChildId: string | null
    additional?: boolean
    parentIdStr?: string
    collapsedCount?: number
}

export const getGridDimensions = ({gridSize, maxRows}: {gridSize: number; maxRows: number}) => {
    let rowCount = Math.ceil(gridSize / GRID_COLUMNS)
    let columns = GRID_COLUMNS

    while (rowCount > maxRows) {
        columns++
        rowCount = Math.ceil(gridSize / columns)
    }

    return {
        rows: rowCount,
        columns,
    }
}

const getRowFor = ({
    profiles,
    rowIndentation,
    parentIdStr,
    additional,
}: {
    profiles: Profile[]
    rowIndentation: number
    parentIdStr?: string
    additional?: boolean
}) => {
    if (!profiles.length) return []
    const id = v4()

    return [
        {
            id,
            indentation: rowIndentation,
            profiles,
            reportsCount: 0,
            lastChildId: null,
            lastAdditionalChildId: null,
            parentIdStr: parentIdStr || undefined,
            additional: additional ?? false,
        },
    ]
}

const reportingLineFor = (
    profile: Profile,
    getProfileFromIdStr: (idStr: string) => Profile | null,
    isCollapsed: (idStr: string) => boolean,
    profileIndentation = 0,
): ProfileRow[] => {
    const {
        profilesWithReports,
        profilesForGrid,
        singleProfiles,
        additionalManagerProfiles,
        additionalReports,
        reportsCount,
    } = getProfileReports(profile, getProfileFromIdStr)
    const profileRows: ProfileRow[] = []
    const lastRowId = () => profileRows[profileRows.length - 1]?.id || null
    const rowIndentation = profileIndentation + 1

    if (isCollapsed(profile.idStr)) {
        const collapsedCount = getCollapsedProfileCount(profile, getProfileFromIdStr)

        return [
            {
                id: v4(),
                indentation: profileIndentation,
                profiles: [profile],
                reportsCount: 0,
                lastChildId: null,
                lastAdditionalChildId: null,
                collapsedCount,
            },
        ]
    }

    profileRows.push(...getRowFor({profiles: profilesForGrid, rowIndentation}))

    for (const profile of singleProfiles) {
        profileRows.push(...getRowFor({profiles: [profile], rowIndentation}))
    }

    for (const profile of additionalManagerProfiles) {
        profileRows.push(...getRowFor({profiles: [profile], rowIndentation, parentIdStr: profile.idStr}))
    }

    let lastChildId = lastRowId()

    for (const profile of profilesWithReports) {
        const reportingLine = reportingLineFor(profile, getProfileFromIdStr, isCollapsed, rowIndentation)

        const directReport = reportingLine[0]
        lastChildId = directReport.id

        profileRows.push(...reportingLine)
    }

    for (let index = 0; index < additionalReports.length; index++) {
        const additionalReport = additionalReports[index]
        profileRows.push(
            ...getRowFor({
                profiles: [additionalReport],
                rowIndentation,
                parentIdStr: profile.idStr,
                additional: true,
            }),
        )
    }

    let lastAdditionalChildId = null
    if (additionalReports.length) lastAdditionalChildId = lastRowId()

    return [
        {
            id: v4(),
            indentation: profileIndentation,
            profiles: [profile],
            reportsCount,
            lastChildId,
            lastAdditionalChildId,
        },
        ...profileRows,
    ]
}

export const getFlatReportingLine = (
    profiles: Profile[],
    getProfileFromIdStr: (idStr: string) => Profile | null,
    isCollapsed: (idStr: string) => boolean,
): ProfileRow[] => {
    return profiles.flatMap((profile) => reportingLineFor(profile, getProfileFromIdStr, isCollapsed))
}

export const paginateProfileRows = (rows: ProfileRow[], orientation: Orientation) => {
    const chunkedArray: ProfileRow[][] = []
    let current: ProfileRow[] = []
    let currentRows = 0

    const resetChunk = () => {
        if (current.length) chunkedArray.push(current)
        current = []
        currentRows = 0
    }

    for (let index = 0; index < rows.length; index++) {
        const element = rows[index]
        const page = chunkedArray.length + 1
        const maxRows = maxRowsFor({orientation, page})

        if (element.profiles.length === 1) {
            currentRows++
            current.push(element)
        } else {
            const {rows} = getGridDimensions({
                gridSize: element.profiles.length,
                maxRows,
            })

            if (currentRows + rows > maxRows) {
                resetChunk()
            }

            currentRows += rows
            current.push(element)
        }

        if (currentRows === maxRows) {
            resetChunk()
        }
    }

    if (current.length) {
        resetChunk()
    }

    return chunkedArray
}
