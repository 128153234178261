import {Formik} from 'formik'
import {
    Button,
    Form,
    FormikDropdownSelect,
    FormikInput,
    Lightbox,
    Spacer,
    Typography,
    useAnalyticsEventTracker,
} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {useOrgChartData} from '../useOrgChartData'
import {useAddOpenPosition} from './useAddOpenPosition'
import {useOrganisationTitleField} from './useOrganisationTitleField'
import {OrganisationPrimaryFields_organisation_primaryFields} from './__types__/OrganisationPrimaryFields'

const LightboxHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const buildOpenPositionTitle = (title: string, titleField: OrganisationPrimaryFields_organisation_primaryFields) => {
    const existingOption = titleField?.childCategories.find(
        (category) => category.label.toLowerCase() === title.toLowerCase(),
    )
    const valueOrCategory: {categoryIdStr: string} | {value: string} = existingOption
        ? {categoryIdStr: existingOption.idStr}
        : {value: title}

    const titlePayload = {
        fieldIdStr: titleField.idStr,
        ...valueOrCategory,
    }

    return {title: titlePayload}
}

export const AddOpenPositionLightbox: React.FC<{
    open: boolean
    onClose: () => void
    managerIdStr: string
}> = ({open, onClose, managerIdStr}) => {
    const organisationIdStr = useOrganisationIdStr()
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const {titleField, loading} = useOrganisationTitleField()
    const {addOpenPosition, loading: submitting} = useAddOpenPosition()
    const {visibleProfiles} = useOrgChartData()

    const onClick = async ({
        title,
        openPositionJobLink,
        manager,
    }: {
        title: string
        openPositionJobLink: string
        manager: string
    }) => {
        if (!titleField || loading) return

        const titlePayload = buildOpenPositionTitle(title, titleField)

        await addOpenPosition({
            variables: {
                managerIdStr: manager,
                organisationIdStr,
                openPositionJobLink,
                ...titlePayload,
            },
        })
        trackAnalyticsEvent('orgCharts', {
            page: 'full-view',
            component: 'button',
            type: 'click',
            action: 'add',
            name: 'open_position',
        })
        onClose()
    }

    const managers = visibleProfiles
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
        .map((profile) => {
            return {label: profile.fullName, value: profile.idStr}
        })

    return (
        <Lightbox open={open} width={549}>
            <LightboxHeader>
                <Typography.Heading>Add open position</Typography.Heading>
                <Button variant="tertiary" onClick={onClose}>
                    Close <Button.Icon icon={SvgClose} />
                </Button>
            </LightboxHeader>
            <Spacer height={24} />
            <Formik
                onSubmit={(values) => {
                    onClick(values)
                }}
                validationSchema={Yup.object({title: Yup.string().required(), manager: Yup.string().required()})}
                isInitialValid={false}
                initialValues={{title: '', openPositionJobLink: '', manager: managerIdStr}}
            >
                {({handleSubmit, isValid}) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            {titleField && (
                                <Form.Group name="title">
                                    <Form.Label>Title</Form.Label>
                                    <FormikInput width={300} autoFocus type="text" hideError />
                                </Form.Group>
                            )}
                            <Form.Group name="manager">
                                <Form.Label>Manager</Form.Label>
                                <FormikDropdownSelect options={managers} />
                            </Form.Group>
                            <Form.Group name="openPositionJobLink">
                                <Form.Label>Link to job posting (Optional)</Form.Label>
                                <FormikInput
                                    width={300}
                                    type="text"
                                    clearButton
                                    placeholder="www.yourcompanyjobboard.com"
                                />
                            </Form.Group>

                            <Spacer height={16} />
                            <Button loading={submitting} type="submit" disabled={submitting || !isValid}>
                                Save
                            </Button>
                        </Form>
                    )
                }}
            </Formik>
        </Lightbox>
    )
}
