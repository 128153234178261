import {Image, Text, View} from '@react-pdf/renderer'
import React from 'react'

const parseStyles = (styles: string) =>
    styles
        .split(';')
        .filter((style) => style.split(':')[0] && style.split(':')[1])
        .map((style) => [
            style
                .split(':')[0]
                .trim()
                .replace(/-./g, (c) => c.substr(1).toUpperCase()),
            style.split(':')[1].trim(),
        ])
        .reduce(
            (styleObj, style) => ({
                ...styleObj,
                [style[0]]: style[1],
            }),
            {},
        )

function defaultTemplateLiteralFunction(props: {}, strings: TemplateStringsArray, ...values: any[]) {
    return strings.reduce((acc, v, i) => {
        acc += v
        if (!values[i]) {
            acc += ''
            return acc
        }

        acc += typeof values[i] === 'function' ? values[i](props) : values[i]
        return acc
    }, '')
}

function JsStyleFrom(props: {}, strings: TemplateStringsArray, ...values: any[]) {
    const tempStyles = defaultTemplateLiteralFunction(props, strings, ...values)
    const styles = tempStyles.trim().replace(/\n/gi, '')
    if (!styles) return {}

    return parseStyles(styles)
}

type Props = any

export const styled = {
    View: (strings: TemplateStringsArray, ...values: any[]): React.FC<{style?: {}} & Props> => {
        return ({children, style: styleProp = {}, ...props}) => {
            const style = JsStyleFrom(props, strings, ...values)

            return (
                <View {...props} style={{...style, ...styleProp}}>
                    {children}
                </View>
            )
        }
    },
    Text: (strings: TemplateStringsArray, ...values: any[]): React.FC<{style?: {}} & Props> => {
        return ({children, ...props}) => {
            const style = JsStyleFrom(props, strings, ...values)

            return (
                <Text {...props} style={style}>
                    {children}
                </Text>
            )
        }
    },
    Image: (strings: TemplateStringsArray, ...values: any[]): React.FC<{src?: string; style?: {}} & Props> => {
        return ({children, src, ...props}) => {
            const style = JsStyleFrom(props, strings, ...values)

            return (
                <Image {...props} style={style} src={src}>
                    {children}
                </Image>
            )
        }
    },
}
