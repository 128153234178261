import * as React from 'react'
const SvgPhotosBold = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgPhotosBold(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M0 4c0-1.325 1.06-2.4 2.41-2.4h11.18C14.94 1.6 16 2.675 16 4v8c0 1.326-1.06 2.4-2.41 2.4H2.41A2.393 2.393 0 010 12V4zm2.41-.8c-.482 0-.771.359-.771.8v8c0 .442.289.8.77.8H13.59c.482 0 .771-.358.771-.8V4c0-.441-.289-.8-.77-.8H2.41zM3.952 8c0 2.21 1.831 4 4.048 4s3.952-1.79 3.952-4S10.217 4 8 4C5.783 4 3.952 5.79 3.952 8zM8 10.4A2.393 2.393 0 015.59 8c0-1.325 1.06-2.4 2.41-2.4s2.41 1.075 2.41 2.4S9.35 10.4 8 10.4z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgPhotosBold
