import {Spinner} from 'nf-ui'
import React, {FC} from 'react'
import styled, {css} from 'styled-components'

const OpactiyStyle = css`
    opacity: 15%;
    pointer-events: none;
`

const FullHeightStyle = css`
    width: 100%;
    height: 100%;
`

const OpacityContainer = styled.div<Pick<LoadingContainerProps, 'fullHeight' | 'loading'>>`
    transition: opacity 0.3s;
    ${(props) => props.loading && OpactiyStyle}
    ${(props) => props.fullHeight && FullHeightStyle}
`
const RelativeContainer = styled.div<Pick<LoadingContainerProps, 'fullHeight'>>`
    position: relative;
    ${(props) => props.fullHeight && FullHeightStyle}
`

const CenterSpinner = styled(Spinner)`
    position: absolute;
    /* Seems like translate has no effect here */
    top: calc(50% - 24px);
    left: calc(50% - 24px);
`

export type LoadingContainerProps = {
    fullHeight?: boolean
    loading?: boolean
    className?: string
}

export const LoadingContainer: FC<LoadingContainerProps> = ({loading, children, className, fullHeight}) => {
    return (
        <RelativeContainer className={className} fullHeight={fullHeight}>
            <OpacityContainer loading={loading} fullHeight={fullHeight}>
                {children}
            </OpacityContainer>
            {loading && <CenterSpinner size={32} />}
        </RelativeContainer>
    )
}
