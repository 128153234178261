import {from} from 'fromfrom'
import {Button, colors, Icon, List, Panel, Typography, useAnalyticsEventTracker, useTheme} from 'nf-ui'
import SvgCheck from 'nf-ui/Icons/Check'
import SvgFilter from 'nf-ui/Icons/Filter'
import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {PANEL_WIDTH} from '~/constants'
import {SortMode, UserSettings} from './UserSettingsContext'

const SortOption = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

type Option = {label: string; sortMode: SortMode; analyticsEvent: string}

export const UserlandSortButton: React.FC = () => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const [open, setOpen] = useState(false)
    const theme = useTheme()

    const {userSettings, setUserSettings} = UserSettings.useContainer()
    const buttonRef = useRef<HTMLButtonElement>(null)

    return (
        <>
            <Button
                ref={buttonRef}
                color={theme.primary.textColorHex}
                variant="tertiary"
                onClick={() => setOpen(!open)}
            >
                <Button.Icon icon={SvgFilter} /> Sort
            </Button>
            <Panel open={open} onClose={() => setOpen(false)} targetRef={buttonRef}>
                <List<Option>
                    heading={<Typography.Label>Sort by</Typography.Label>}
                    rows={[
                        {
                            label: 'First name',
                            sortMode: 'firstName',
                            analyticsEvent: 'tick_sort_first_name',
                        },
                        {
                            label: 'Last name',
                            sortMode: 'lastName',
                            analyticsEvent: 'tick_sort_last_name',
                        },
                    ]}
                    renderRow={({label, sortMode}) => (
                        <SortOption>
                            <Typography.Paragraph bottomMargin={false}>{label}</Typography.Paragraph>
                            {userSettings.sortMode === sortMode && <Icon icon={SvgCheck} tint={colors.green[100]} />}
                        </SortOption>
                    )}
                    onClick={({sortMode, analyticsEvent}) => {
                        trackAnalyticsEvent(analyticsEvent)

                        setUserSettings({
                            sortMode,
                        })
                        setOpen(false)
                    }}
                    width={PANEL_WIDTH}
                />
            </Panel>
        </>
    )
}

/**
 * Sorts an array of profiles according to the `sortMode` stored in `UserSettings`.
 */
export function useSortProfiles<Profile extends {firstName: string; lastName: string}>(profiles: Profile[]) {
    const {
        userSettings: {sortMode},
    } = UserSettings.useContainer()

    // console.log('useSortProfiles', sortMode, profiles.map(profile => profile[sortMode]))

    return from(profiles)
        .sortBy((profile) => profile[sortMode].toLowerCase())
        .toArray()
}
