import React, {FC} from 'react'
import {Switch, useRouteMatch} from 'react-router'
import {Route} from 'react-router-dom'
import {DirectoryRoot} from './DirectoryRoot'
import {OrgChartDataProvider} from './FullOrgChart/useOrgChartData'
import {OrgChartRoot} from './OrgChartRoot'
import {PrintOrgChart} from './PrintOrgChart'

export type UserlandModal = 'editProfile' | 'groupMessage' | null

export const UserlandRoot: React.FC = () => {
    const {path} = useRouteMatch()!

    return (
        <Switch>
            <Route path={`${path}/print-org-chart`}>
                <OrgChartDataProvider>
                    <PrintOrgChart />
                </OrgChartDataProvider>
            </Route>
            <Route path={`${path}/org-chart`}>
                <OrgChartRoot />
            </Route>
            <Route default>
                <DirectoryRoot />
            </Route>
        </Switch>
    )
}
