import {BORDER_WIDTH} from '../constants'
import {Column} from './useColumns'
import {Row} from './useRows'

export const getTableDimensions = ({
    maxWidth,
    maxHeight,
    columns,
    rows,
}: {
    maxWidth?: number
    maxHeight?: number
    columns: Column[]
    rows: Row[]
}) => {
    const BORDER_SIZE = BORDER_WIDTH * 2

    const tableContentWidth =
        columns.reduce((sum, column) => sum + (column.style.collapsed ? 0 : column.size), 0) + BORDER_SIZE * 1.5

    const tableContentHeight =
        rows.reduce((sum, row) => sum + (row.style.collapsed ? 0 : row.size), 0) + BORDER_SIZE * 1.5

    return {
        width: maxWidth && maxWidth <= tableContentWidth ? maxWidth : tableContentWidth,
        height: maxHeight && maxHeight <= tableContentHeight ? maxHeight : tableContentHeight,
        tableContentHeight,
        tableContentWidth,
    }
}
