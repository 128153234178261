import React from 'react'
import {TooltipWindowPassthroughProps} from '.'
import {useToolTipContainer} from './TooltipContext'
import {Portal} from './Portal'
import {Popper} from 'react-popper'
import {Tooltip} from './Tooltip'

export interface WindowProps extends TooltipWindowPassthroughProps {
    targetElement: HTMLElement | null
}

const TooltipWindow: React.FC<WindowProps> = ({position, title, targetElement, maxWidth, offset = 0, size}) => {
    const tooltipContainer = useToolTipContainer()

    if (!targetElement) return null

    return (
        <Portal container={tooltipContainer.current}>
            <Popper
                placement={position}
                referenceElement={targetElement}
                modifiers={{
                    offset: {offset},
                    preventOverflow: {enabled: false},
                }}
            >
                {({ref, style, placement}) => (
                    <div ref={ref} style={style} data-placement={placement}>
                        <Tooltip position={position} title={title} maxWidth={maxWidth} size={size} />
                    </div>
                )}
            </Popper>
        </Portal>
    )
}

export {TooltipWindow}
