import React, {useCallback, useContext, useEffect, useMemo, useRef} from 'react'
import {useQueryParam} from 'use-query-params'

const FullOrgChartContext = React.createContext<{
    setHoveringIdStr: (idStr: string | null) => void
    scrollToProfile: (idStr: string) => void
    scrollToTop: () => void
    searchTerm?: string | null
    scrollContainer: React.RefObject<HTMLDivElement>
} | null>(null)

export const useFullOrgChart = () => {
    const profileGroupContext = useContext(FullOrgChartContext)
    if (!profileGroupContext)
        throw new Error('Cannot use org chart hover context outside of the <FullOrgChartProvider />')

    return profileGroupContext
}

export const FullOrgChartProvider: React.FC<{
    onHover: (idStr: string | null, prevIdStr: string | null) => void
    scrollToTop: () => void
    addFade: () => void
    removeFade: () => void
    orgChartContainer: React.RefObject<HTMLDivElement>
}> = ({onHover, scrollToTop, addFade, removeFade, children, orgChartContainer}) => {
    const [searchTerm] = useQueryParam<string>('search')
    const [categoryIdStr] = useQueryParam<string>('categoryId')
    const scrollContainer = useRef<HTMLDivElement>(null)
    const hoveringIdStr = useRef<string | null>(null)

    useEffect(() => {
        if (!orgChartContainer.current) return

        if (searchTerm || categoryIdStr) {
            addFade()
        } else {
            removeFade()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addFade, removeFade, searchTerm, categoryIdStr, orgChartContainer.current])

    const setHoveringIdStr = useCallback(
        (idStr: string | null) => {
            const current = hoveringIdStr.current
            onHover(idStr, current)
            hoveringIdStr.current = idStr
        },
        [onHover],
    )

    const scrollToProfile = useCallback((idStr) => {
        if (!scrollContainer.current) return
        const element = document.getElementById(`profile_${idStr}`)
        if (!element) return
        const elementTop = element.getBoundingClientRect().top
        const offset = 20

        scrollContainer.current.scrollBy({top: elementTop - offset, behavior: 'smooth'})
    }, [])

    const contextValue = useMemo(
        () => ({
            setHoveringIdStr,
            scrollToTop,
            searchTerm: searchTerm?.toLowerCase(),
            scrollContainer,
            scrollToProfile,
        }),
        [setHoveringIdStr, scrollToTop, searchTerm, scrollContainer, scrollToProfile],
    )

    return <FullOrgChartContext.Provider value={contextValue}>{children}</FullOrgChartContext.Provider>
}
