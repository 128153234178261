import dateFormat from 'dateformat'
import {Icon, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgCall from 'nf-ui/Icons/Call'
import SvgEmail from 'nf-ui/Icons/Email'
import SvgOpenLink from 'nf-ui/Icons/OpenLink'
import SvgSkype from 'nf-ui/Icons/Skype'
import SvgTeams from 'nf-ui/Icons/Teams'
import React from 'react'
import styled, {css} from 'styled-components'
import {FieldType} from '~/globalTypes'
import {ProfileLine} from './FullProfile'

type FieldConfig = {
    formatter?: (detail: ProfileLine) => string
    link?: (detail: ProfileLine) => string
    icon?: React.ComponentType<React.SVGAttributes<SVGSVGElement>>
    target?: string
}

function dateFormatter({value, extraData}: ProfileLine) {
    const date = new Date(value)

    const invalidDate = isNaN(date.getDate())
    if (invalidDate) return value

    return dateFormat(date, extraData.dateMask || 'd mmm yyyy')
}

export const FIELD_CONFIG: Record<string, FieldConfig | undefined> = {
    [FieldType.DATE]: {
        formatter: (field) => dateFormatter(field),
    },
    [FieldType.MOBILE_NUMBER]: {
        link: ({value}) => `tel:${value}`,
        icon: SvgCall,
    },
    [FieldType.LANDLINE_NUMBER]: {
        link: ({value}) => `tel:${value}`,
        icon: SvgCall,
    },
    [FieldType.EMAIL]: {
        link: ({value}) => `mailto:${value}`,
        icon: SvgEmail,
    },
    [FieldType.SKYPE]: {
        formatter: () => 'Chat on Skype',
        link: ({value}) => `skype:${value}`,
        icon: SvgSkype,
    },
    [FieldType.MS_TEAMS]: {
        formatter: ({value}) => `@${value.split('%#%')[1]}`,
        link: ({value}) => value.split('%#%')[0],
        target: '_blank',
        icon: SvgTeams,
    },
    [FieldType.URL]: {
        formatter: ({value}) => value.replace(/^https?:\/\//gi, ''),
        link: ({value}) => value,
        icon: SvgOpenLink,
    },
}

export const detailStyle = css`
    a {
        color: inherit;
        text-decoration: none;
    }
    a:hover {
        color: inherit;
        text-decoration: underline;
    }
`

const Value = styled.div`
    ${detailStyle}
`

export const ValueIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Detail: React.FC<{detail: ProfileLine; target?: string}> = ({detail, target: targetProp}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const {formatter, link, icon, target} = FIELD_CONFIG[detail.type] || {}

    const value = formatter ? formatter(detail) : detail.value

    const valueIcon = (
        <ValueIconContainer>
            <Typography.Paragraph bottomMargin={false}>{value}</Typography.Paragraph>
            {icon && <Icon icon={icon} />}
        </ValueIconContainer>
    )

    if (!value) return null

    return (
        <>
            <Typography.Label block>{detail.label}</Typography.Label>
            <Value>
                {link ? (
                    <a
                        href={link(detail)}
                        target={targetProp || target || ''}
                        onClick={() => trackAnalyticsEvent('select_action', {label: detail.label})}
                    >
                        {valueIcon}
                    </a>
                ) : (
                    valueIcon
                )}
            </Value>
            <Spacer height={16} />
        </>
    )
}
