import {useEffect, useRef} from 'react'

type CleanupFunction = () => void
export type CleanupRef = ReturnType<typeof useCleanupOnUnmount>

export const useCleanupOnUnmount = () => {
    const cleanupRef = useRef<CleanupFunction | undefined>()

    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            if (cleanupRef.current) cleanupRef.current()
        }
    }, [])

    return cleanupRef
}
