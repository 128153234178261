import {Button, colors, Spacer, Typography} from 'nf-ui'
import SvgAdd from 'nf-ui/Icons/Add'
import React from 'react'
import styled from 'styled-components'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {useCurrentUser} from '~/components/useCurrentUser'

const FlexContainer = styled.div`
    display: flex;
`

const EmailPreviewContainer = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
`

const EmailMessageContainer = styled.div`
    box-sizing: border-box;
    width: 400px;
    background: ${colors.white};
    border: 1px solid ${colors.darkGray};
    border-radius: 3px;
`

const EmailBody = styled.div`
    margin: 16px;
`

const EmailBtnContainer = styled(FlexContainer)`
    justify-content: center;
`

const EmailButton = styled(Button)`
    cursor: not-allowed;
    pointer-events: none;
`

const EmailTemplate: React.FC = () => {
    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const {me} = useCurrentUser()

    return (
        <EmailPreviewContainer>
            <Typography.Label>Email preview</Typography.Label>
            <Spacer height={16} />
            <EmailMessageContainer>
                <EmailBody>
                    <Typography.Paragraph>
                        to: brittney@company.com <br />
                        Reply-To: {me?.email} <br />
                        Subject: Action Required - Please complete your details
                    </Typography.Paragraph>

                    <Spacer height={24} />
                    <Typography.Paragraph>Hi Brittney,</Typography.Paragraph>
                    <Spacer height={8} />
                    <Typography.Paragraph>
                        We need you to add a little more information to your profile to improve the{' '}
                        {currentOrganisation?.name} company Names & Faces index.
                    </Typography.Paragraph>
                    <Spacer height={8} />
                    <EmailBtnContainer>
                        <EmailButton variant="secondary">
                            Update your profile <Button.Icon icon={SvgAdd} />
                        </EmailButton>
                    </EmailBtnContainer>
                    <Spacer height={16} />
                    <Typography.Paragraph>
                        Please do this soon since it may otherwise impact the accuracy and helpfulness of Names & Faces
                        for others. Thanks! Please reply to this email if you have any questions.
                        <Spacer height={8} />
                        {me?.firstName}
                        <br />
                        __
                        <br />
                        {me?.firstName} {me?.lastName} <br />
                        {me?.email}
                    </Typography.Paragraph>
                </EmailBody>
            </EmailMessageContainer>
        </EmailPreviewContainer>
    )
}

export {EmailTemplate}
