import {AnimatePresence, motion, Variants} from 'framer-motion'
import React, {useRef} from 'react'
import styled from 'styled-components'
import {useOnClickOutside} from './hooks'
import {Space} from './Spacer'
import {colors, elevation} from './theme'

export const LightboxContainer = styled(motion.div)`
    position: fixed;
    z-index: 10001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(16, 16, 16, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const LighboxContent = styled(motion.div)<{padding?: Space; width?: number}>`
    /* This postion being relative is a hack to get contents being positioned absolute to stay inside this container  */
    position: relative;
    background-color: ${colors.white};
    width: ${({width = 1132}) => `${width}px`};
    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);
    overflow: auto;
    ${elevation};
    padding: ${(props) => props.padding}px;
    box-sizing: border-box;
    border-radius: 3px;
`

const duration = 0.3
export const LightboxContainerVariants: Variants = {
    open: {
        opacity: 1,
        transition: {
            duration,
        },
    },
    close: {
        opacity: 0,
        transition: {
            duration,
        },
    },
}

export const LightboxContentVariants: Variants = {
    open: {
        opacity: 1,
        y: 0,
        transition: {
            duration,
        },
    },
    close: {
        opacity: 0,
        y: 20,
        transition: {
            duration,
        },
    },
}

export type LightboxProps = {
    onClose?: () => void
    open: boolean
    padding?: Space
    width?: number
    otherInsideRefs?: React.MutableRefObject<HTMLDivElement | null>[]
}

const Lightbox: React.FC<LightboxProps> = ({children, onClose, open, padding = 32, width, otherInsideRefs}) => {
    const contentRef = useRef<HTMLDivElement | null>(null)
    useOnClickOutside(otherInsideRefs ? [contentRef, ...otherInsideRefs] : contentRef, () => {
        if (onClose && open) onClose()
    })

    return (
        <AnimatePresence>
            {open && (
                <LightboxContainer
                    key="lightbox-container"
                    variants={LightboxContainerVariants}
                    initial="close"
                    animate="open"
                    exit="close"
                >
                    <LighboxContent
                        width={width}
                        ref={contentRef}
                        key="lightbox-content"
                        variants={LightboxContentVariants}
                        padding={padding}
                    >
                        {children}
                    </LighboxContent>
                </LightboxContainer>
            )}
        </AnimatePresence>
    )
}

export {Lightbox}
