// TODO: See if we can get dot notation (PanelLayout.Buttons) to work...
// TODO: Move to UI lib?

import {FormContainer, IconContainer, Typography} from 'nf-ui'
import {GroupContainer} from 'nf-ui/Form/Group'
import {Container as InputContainer} from 'nf-ui/Form/Input'
import React from 'react'
import styled from 'styled-components'

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
`

const MenuItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        flex: 1;
    }

    ${IconContainer} {
        margin-left: 16px;
    }
`

const MenuItem: React.FC = ({children}) => {
    return (
        <MenuItemContainer>
            {React.Children.map(children, (child) => {
                if (typeof child === 'string') return <span>{child}</span>
                return child
            })}
        </MenuItemContainer>
    )
}

const PanelLayout = styled.div<{width?: number}>`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.width ? props.width : 'initial')}px;

    > ${GroupContainer} {
        margin-bottom: 16px;

        ${InputContainer} {
            margin: 0 16px;
            width: auto;
        }
    }

    ${FormContainer} {
        padding: 0 16px;
        box-sizing: border-box;

        ${GroupContainer} {
            margin-bottom: 16px;

            ${InputContainer} {
                width: auto;
            }

            ${Typography.Label} {
                font-weight: 300;
            }
        }

        ${Buttons} {
            margin: 0 -16px;
        }
    }

    > ${Typography.Label} {
        padding: 16px;
    }

    > ${Typography.Paragraph} {
        padding: 0 16px;
    }
`

export {Buttons as PanelLayoutButtons, PanelLayout, MenuItem as PanelLayoutMenuItem}
