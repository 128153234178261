import {useQueryParam} from 'use-query-params'
import {useCycleMatchingProfiles} from './ActionBar/useCycleMatchingProfiles'

export const useResetFilters = () => {
    const {setMatchIndex} = useCycleMatchingProfiles()
    const [, setProfileId] = useQueryParam<string>('profileId')
    const [, setTopProfileId] = useQueryParam<string>('topLevelProfile')
    const [, setSearch] = useQueryParam<string>('search')
    const [, setCategory] = useQueryParam<string>('categoryId')

    const resetFilters = () => {
        setProfileId('')
        setTopProfileId('')
        setSearch('')
        setCategory('')
        setMatchIndex(null)
    }

    return {resetFilters}
}
