import {colors, Spacer, Typography} from 'nf-ui'
import React, {useState} from 'react'
import styled from 'styled-components'
import {PHOTO_HEIGHT} from '~/components/Photo/Photo'
import {Collapsible} from '../FullOrgChart/Collapsible'
import {ExpandControlCaret} from '../FullOrgChart/CollapsibleControls'

const InsightPanelContainer = styled.div`
    background-color: ${colors.lightGray};
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
    :hover {
        background-color: #edeff0;
    }
`

const PanelLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    :hover {
        cursor: pointer;
    }
`

const CollapsibleWrapper = styled.div`
    overflow: hidden;
`

const PanelContent = styled.div`
    height: ${PHOTO_HEIGHT}px;
    padding: 0 24px;
`

export const InsightPanel: React.FC<{label: string | JSX.Element; startExpanded?: boolean}> = ({
    label,
    startExpanded = false,
    children,
}) => {
    const [expanded, setExpanded] = useState(startExpanded)
    const toggle = () => setExpanded(!expanded)

    return (
        <InsightPanelContainer>
            <PanelLabel onClick={toggle}>
                <Typography.Paragraph bottomMargin={false}>{label}</Typography.Paragraph>
                <ExpandControlCaret expanded={expanded} toggle={toggle} />
            </PanelLabel>
            <CollapsibleWrapper>
                <Collapsible animation={true} expanded={expanded}>
                    <PanelContent>{children}</PanelContent>
                    <Spacer height={24} />
                </Collapsible>
            </CollapsibleWrapper>
        </InsightPanelContainer>
    )
}
