import {useEffect, useRef} from 'react'
import {useQueryParam} from 'use-query-params'

export const useOnFilterChange = (onChange: () => void) => {
    const [searchTerm] = useQueryParam<string>('search')
    const [categoryIdStr] = useQueryParam<string>('categoryId')
    const previousFilter = useRef<string | null>(null)

    useEffect(() => {
        const currentFilter = `${searchTerm || ''}-${categoryIdStr || ''}`
        if (currentFilter !== previousFilter.current && currentFilter !== '-') {
            onChange()
            previousFilter.current = currentFilter
        }
    }, [onChange, searchTerm, categoryIdStr])
}
