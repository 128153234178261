import {Button, FadePresence, Icon, Spacer} from 'nf-ui'
import SvgEdit from 'nf-ui/Icons/Edit'
import SvgProfile from 'nf-ui/Icons/Profile'
import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {LayoutItem} from '~/components/LayoutItem'
import {useRelativeRoute} from '~/components/useRelativeRoute'

const WithIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const ProfilePictureField = () => {
    const {pushRelative} = useRelativeRoute()
    const [hovering, setHovering] = useState(false)

    const handleMouseEnter = useCallback(() => {
        setHovering(true)
    }, [setHovering])

    const handleMouseLeave = useCallback(() => {
        setHovering(false)
    }, [setHovering])

    const onClickEdit = () => {
        pushRelative(`/edit/profilePicture`)
    }

    return (
        <LayoutItem
            isDraggable
            fixedHeight
            hasBorder
            hasBottomMargin
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <WithIconContainer>
                <Icon icon={SvgProfile} />
                <Spacer width={16} />
                Profile picture
            </WithIconContainer>
            <FadePresence>
                {hovering && (
                    <>
                        <Button variant="tertiary" onClick={onClickEdit}>
                            <Button.Icon icon={SvgEdit} /> Edit
                        </Button>
                        <Spacer width={8} />
                    </>
                )}
            </FadePresence>
        </LayoutItem>
    )
}
