import {useAlert} from 'react-alert'

export enum EMAIL_CLIENT {
    DEFAULT,
    GMAIL,
    OUTLOOK,
    YAHOO,
}

export const useBroadcastMessage = (emailAddresses: string[]) => {
    const alert = useAlert()
    const constructMailLink = (client: EMAIL_CLIENT) => {
        switch (client) {
            case EMAIL_CLIENT.DEFAULT:
                return `mailto:${emailAddresses.join()}`
            case EMAIL_CLIENT.GMAIL:
                return `https://mail.google.com/mail/?view=cm&fs=1&to=${emailAddresses.join()}`
            case EMAIL_CLIENT.OUTLOOK:
                return `https://outlook.live.com/mail/0/deeplink/compose?to=${emailAddresses.join()}`
            case EMAIL_CLIENT.YAHOO:
                return `https://compose.mail.yahoo.com/?to=${emailAddresses.join()}`
            default:
                return `mailto:${emailAddresses.join()}`
        }
    }

    const composeInEmailClient = async (client: EMAIL_CLIENT) => {
        const messageLink = constructMailLink(client)
        window.open(messageLink, '_blank')
    }

    const copyAsText = async () => {
        await navigator.clipboard.writeText(emailAddresses.join())
        alert.success(`${emailAddresses.length} email addresses copied`)
    }

    return {
        composeInEmailClient,
        copyAsText,
    }
}
