import {motion, useAnimation} from 'framer-motion'
import {colors, easeInOutQuadCSS, Typography} from 'nf-ui'
import React, {FC, useEffect, useRef} from 'react'
import styled, {css} from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {profilesGridTransition} from '~/pages/Userland/FullProfileSidePanel'

export const LETTERS = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ']

const RolodexContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    width: 64px;
    height: auto;
    padding-right: 48px;
    margin: 32px 0;
`

const LetterContainer = styled(motion.div)`
    display: flex;
    height: 24px;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
`

const hideLine = css`
    transform: scale(0);
`

const lineHover = css`
    ${LetterContainer}:hover & {
        background-color: ${colors.black};
        transform: scale(1);
    }
`

const Line = styled.div<{fixed: boolean; selected: boolean}>(
    ({fixed, selected}) => css`
        background-color: ${colors.darkGray};
        height: 1px;
        transition-duration: 300ms;
        transition-timing-function: ${easeInOutQuadCSS};
        transition-property: width, background-color, transform;
        width: 32px;
        transform: scale(0.5);
        transform-origin: right;
        margin-left: 8px;

        ${(fixed || selected) && hideLine}
        ${!selected && lineHover}
    `,
)

const LetterText = styled(Typography.Paragraph)<{fixed: boolean; selected: boolean}>(
    ({fixed, selected}) => css`
        opacity: ${fixed || selected ? 1 : 0};
        transform: translateX(${fixed || selected ? 40 : 16}px);

        transition-duration: 300ms;
        transition-timing-function: ${easeInOutQuadCSS};
        transition-property: opacity, transform;

        ${!selected &&
            css`
                ${LetterContainer}:hover & {
                    transform: translateX(0px);
                    opacity: 1;
                }
            `}
    `,
)

const Letter: FC<{letter: string; fixed: boolean; onClick?: () => void; selected: boolean}> = ({
    letter,
    fixed,
    onClick,
    selected,
}) => {
    return (
        <LetterContainer data-testid={letter} onClick={onClick}>
            <LetterText fixed={fixed} selected={selected} bold={selected} bottomMargin={false}>
                {letter}
            </LetterText>
            <Line fixed={fixed} selected={selected} />
        </LetterContainer>
    )
}

const useAnimateOnProfilePresence = () => {
    const [profileId] = useQueryParam<string>('profileId')
    const animate = useAnimation()

    const profilePresence = !!profileId

    const isInitial = useRef(true)
    useEffect(() => {
        // Do nothing on first render (similar to `initial={false}` on an AnimatePresence)
        if (isInitial.current) {
            isInitial.current = false
            return
        }

        if (profilePresence) {
            animate.set({x: 404})
            animate.start({x: [404, 0]})
        } else {
            animate.set({x: -404})
            animate.start({x: [-404, 0]})
        }
    }, [profilePresence, animate])

    return {
        transition: profilesGridTransition,
        animate,
    }
}

export const Rolodex: FC<{onChange: (letter: string) => void; value: string | null}> = ({value, onChange}) => {
    const animationProps = useAnimateOnProfilePresence()

    return (
        <RolodexContainer {...animationProps}>
            {LETTERS.map((letter, index) => {
                const firstOrLastLetter = [0, LETTERS.length - 1].includes(index)

                return (
                    <Letter
                        key={letter}
                        letter={letter}
                        fixed={firstOrLastLetter}
                        selected={value === letter}
                        onClick={() => onChange(letter)}
                    />
                )
            })}
        </RolodexContainer>
    )
}
