import {useEffect, useState} from 'react'
import {getIndentation, Orientation, Profile} from './constants'
import {getFlatReportingLine, paginateProfileRows, ProfileRow} from './reportingLine'
import {getVerticalLines, VerticalLine} from './getVerticalLines'
import {useDisplayOptions} from '../FullOrgChart/useDisplayOptions'

const getHighestOffset = (reportingLine: ProfileRow[]) => {
    return reportingLine.reduce((prev, current) => {
        return prev.indentation > current.indentation ? prev : current
    }).indentation
}

export const useBuildPdfData = ({
    profiles,
    getProfileFromIdStr,
    orientation,
    includeHeader = true,
}: {
    profiles: Profile[]
    getProfileFromIdStr: (idStr: string) => Profile | null
    orientation: Orientation
    includeHeader?: boolean
}) => {
    const {isCollapsed} = useDisplayOptions()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<{
        paginatedProfileRows: ProfileRow[][]
        solidLines: VerticalLine[]
        dottedLines: VerticalLine[]
        highestOffset: number
    } | null>(null)

    useEffect(() => {
        if (!profiles.length) return

        const reportingLine = getFlatReportingLine(profiles, getProfileFromIdStr, isCollapsed)
        const highestOffset = getHighestOffset(reportingLine)
        const paginatedProfileRows = paginateProfileRows(reportingLine, orientation)
        const {solidLines, dottedLines} = getVerticalLines(
            reportingLine,
            paginatedProfileRows,
            orientation,
            includeHeader,
        )

        setData({paginatedProfileRows, solidLines, dottedLines, highestOffset})
        setLoading(false)
    }, [getProfileFromIdStr, includeHeader, orientation, profiles, isCollapsed])

    return {
        loading,
        paginatedProfileRows: data?.paginatedProfileRows || null,
        solidLines: data?.solidLines || null,
        dottedLines: data?.dottedLines || null,
        indentationWidth: getIndentation(data?.highestOffset || 0),
    }
}
