import {gql} from '@apollo/client'
import {HomeItemFragment} from '~/apollo/queries/HomeItemFragment'
import {PhonePreviewHomeFragment} from '~/components/PhonePreview/PhonePreviewHome'

export const DESIGN_HOME = gql`
    query DesignHomeData($organisationIdStr: String!) {
        organisation(idStr: $organisationIdStr) {
            idStr
            accentColor
            ...HomeItemData
            ...PhonePreviewHomeData
            logo {
                idStr
                file {
                    idStr
                    url
                }
            }
        }
    }
    ${HomeItemFragment}
    ${PhonePreviewHomeFragment}
`
