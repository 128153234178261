import {gql, useQuery} from '@apollo/client'
import {Typography} from 'nf-ui'
import React from 'react'
import {PageLoading} from '~/components/PageLoading'
import {getGraphQLErrorMessage} from '~/util'
import {OAuthRedirect} from './OAuthRedirect'
import {ADPSignInOption} from './__types__/ADPSignInOption'

const ADP_SIGNIN_OPTION = gql`
    query ADPSignInOption {
        adpSignInOption {
            type
            name
            icon
            public_config {
                authorize_uri
                client_id
                response_type
                scope
                identity_provider
            }
        }
    }
`

export const ADPLogin: React.FC = () => {
    const {data, error, loading} = useQuery<ADPSignInOption>(ADP_SIGNIN_OPTION)

    if (!data || loading) return <PageLoading />
    if (error) return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>

    return <OAuthRedirect config={data.adpSignInOption.public_config} />
}
