import {MOBILE_MEDIA_QUERY, SquareLogo, TABLET_MEDIA_QUERY} from 'nf-ui'
import React, {ReactNode} from 'react'
import styled from 'styled-components'

interface WizardNavigationProps {
    rightContent?: ReactNode
}

export const NAVIGATION_HEIGHT = 120
const LOGO_PADDING = 32
const LOGO_PADDING_MOBILE = 24

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    position: relative;
    height: ${NAVIGATION_HEIGHT}px;
    ${MOBILE_MEDIA_QUERY} {
        height: ${NAVIGATION_HEIGHT - 16 * 2}px;
    }
    ${TABLET_MEDIA_QUERY} {
        height: ${NAVIGATION_HEIGHT - 8 * 2}px;
    }
`

const RightContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 28px;
`

const LogoContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding: ${LOGO_PADDING}px;
    ${MOBILE_MEDIA_QUERY} {
        padding: ${LOGO_PADDING_MOBILE}px;
    }

    a {
        display: block;
    }
`

const Logo: React.FC = () => {
    return (
        <LogoContainer>
            <a href="/">
                <SquareLogo />
            </a>
        </LogoContainer>
    )
}

const Navigation: React.FC<WizardNavigationProps> = ({rightContent}) => {
    return (
        <Container>
            <Logo />
            {rightContent && <RightContainer>{rightContent}</RightContainer>}
        </Container>
    )
}

export {Navigation, Container}
