import {Theme} from 'nf-ui'
import {createContainer} from 'unstated-next'
import {FullOrgChartData_organisation} from '../__types__/FullOrgChartData'
import {Orientation, Profile} from './constants'

type PrintState = {
    orientation: Orientation
    theme: Theme
    organisation: FullOrgChartData_organisation
    indentationWidth: number
    getProfileFromIdStr: (idStr: string) => Profile | null
}

const usePrintState = (initialState?: PrintState) => {
    const {orientation = 'landscape', theme, organisation, indentationWidth, getProfileFromIdStr} = initialState || {}

    return {
        orientation,
        theme: theme as Theme,
        organisation: organisation as FullOrgChartData_organisation,
        indentationWidth: indentationWidth as number,
        getProfileFromIdStr: getProfileFromIdStr!,
    }
}

export const PrintContext = createContainer(usePrintState)
export const usePrintContext = PrintContext.useContainer
