import {gql, useMutation} from '@apollo/client'
import {Button, FadePresence, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import React, {FC, useEffect, useMemo} from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {useQueryParam} from 'use-query-params'
import {PageLoading} from '~/components/PageLoading'
import {Token} from '~/components/TokenContext'
import {UnstyledLink} from '~/components/UnstyledLink'
import {WizardLayout} from '~/components/WizardLayout'
import {SignInMagicLink, SignInMagicLinkVariables} from './__types__/SignInMagicLink'

const TOKEN_MUTATION = gql`
    mutation SignInMagicLink($token: String!) {
        signInMagicLink(token: $token) {
            accessToken
        }
    }
`

const SignInError: FC = () => {
    const {push} = useHistory()
    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <Typography.Heading>That link isn't working</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading>
                    Magic Links expire after one use or 24 hours. Please, sign in again.
                </Typography.Subheading>
                <Spacer height={32} />
                <UnstyledLink to="/login">
                    <Button variant="primary" onClick={() => push('login')}>
                        Return home
                    </Button>
                </UnstyledLink>
            </WizardLayout.Body>
        </WizardLayout>
    )
}

export const SignInMagicCallback: FC = () => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const [token] = useQueryParam<string>('t')

    const {setToken} = Token.useContainer()
    const [signInMutation, {loading, called, error}] = useMutation<SignInMagicLink, SignInMagicLinkVariables>(
        TOKEN_MUTATION,
        {
            onCompleted: (result) => {
                trackAnalyticsEvent('sign_in')
                setToken(result.signInMagicLink.accessToken)
            },
        },
    )

    useEffect(() => {
        if (token) signInMutation({variables: {token}})
    }, [signInMutation, token])

    const content = useMemo(() => {
        if (loading) return 'loading'
        if (called) return 'redirect'
        return ''
    }, [called, loading])

    if (error || !token) {
        return <SignInError />
    }

    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <FadePresence exitBeforeEnter itemKey={content}>
                    {content === 'loading' ? <PageLoading /> : content === 'redirect' ? <Redirect to={`/`} /> : null}
                </FadePresence>
            </WizardLayout.Body>
        </WizardLayout>
    )
}
