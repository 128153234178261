import {gql} from '@apollo/client'

//Do not move either of these queries to their components are a hard to detect cicular refence occurs

export const FieldListFragment = gql`
    fragment FieldListData on OrganisationObject {
        fields {
            idStr
            label
            type
            visible
            hideable
            priority
        }
    }
`

export const DESIGN_PROFILE = gql`
    query DesignProfileData($organisationIdStr: String!) {
        organisation(idStr: $organisationIdStr) {
            idStr
            accentColor
            userRestrictions {
                canEditProfilePicture
            }
        }
    }
`
