import React from 'react'
import styled from 'styled-components'
import {Typography} from '../Typography'
import {colors} from '../theme'
import {FadePresence} from '../FadePresence'
import {Icon, IconType} from '../Icon'
import {Spacer} from '../Spacer'

const Container = styled.button`
    height: 34px;
    display: flex;
    flex-flow: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    outline: 0;
    position: relative;
    background-color: transparent;
    padding: 0;
    padding-bottom: 4px;
`

const Count = styled.div`
    position: absolute;
    top: -4px;
    right: -8px;
    background: ${({theme}) => theme.primary.color};
    border-radius: 8px;
    height: 16px;
    width: 16px;
    line-height: 15px;
    color: ${colors.white};
    font-family: 'halyard-display';
    font-weight: 500;
    font-size: 10px;
`

export type TabProps = {
    icon?: IconType
    children: string
    count?: number

    /**
     * @private
     */
    selected?: boolean
}

export const Tab: React.FC<TabProps> = ({children, count, icon, selected = false}) => {
    return (
        <Container>
            {icon && (
                <>
                    <Icon icon={icon} />
                    <Spacer width={8} />
                </>
            )}
            {selected ? (
                <Typography.Label>{children}</Typography.Label>
            ) : (
                <Typography.Paragraph bottomMargin={false}>{children}</Typography.Paragraph>
            )}
            <FadePresence>{count != null && <Count>{count}</Count>}</FadePresence>
        </Container>
    )
}
