import {Button, colors, Icon, List, Panel, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgContextualMenu from 'nf-ui/Icons/ContextualMenu'
import React, {useRef, useState} from 'react'
import styled, {css} from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {PANEL_WIDTH} from '~/constants'
import {RoleType} from '~/objectTypes'
import {EditTabOption} from '../EditProfileModal/EditProfile'
import {UserlandModal} from '../UserlandRoot'
import {FullOrgChartData_organisation_profiles} from '../__types__/FullOrgChartData'
import {useOpenPosition} from './OpenPositions/useOpenPosition'
import {useFullOrgChart} from './FullOrgChartContext'
import {useResetFilters} from './useResetFilters'

const RightContent = styled.div`
    display: contents;
    cursor: pointer;
    align-items: center;

    span {
        margin-right: 8px;
    }
`

const AdminIndicator = styled(Typography.Tiny)`
    text-decoration: underline;
`

const MenuIconWrapper = styled.div`
    display: flex;
    margin-right: -5px;
`

const FlexItem = styled.div<{border?: boolean}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: ${({border = false}) => (border ? css`1px solid ${colors.darkGray}` : 'none')};
    margin: -16px;
    padding: 16px;
`

const ContextMenuButton = styled(Button)`
    height: auto;
`

const ContextMenuIcon = styled(Icon)`
    border: 2px solid ${colors.transparent};
    border-radius: 3px;
    transition: border-color 0.3s ease-in-out;

    :hover {
        border-color: ${colors.primary[35]};
    }
`

type Action = {
    label: string
    onClick: () => Promise<void>
    admin: boolean
    hoverAttributes?: {
        onMouseEnter: () => void
        onMouseLeave: () => void
    }
}

const ContextualMenu = ({
    profile,
    setTopLevelProfile,
    canShowBranch,
    isTopLevel,
}: {
    profile: FullOrgChartData_organisation_profiles
    setTopLevelProfile: (idStr: string) => void
    canShowBranch: boolean
    isTopLevel: boolean
}) => {
    const {resetFilters} = useResetFilters()
    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const {setHoveringIdStr} = useFullOrgChart()
    const {addOpenPositionUnder} = useOpenPosition()
    const [menuOpen, setMenuOpen] = useState(false)
    const menuRef = useRef(null)

    const [, setEditProfileId] = useQueryParam<string | null>('editProfileId')
    const [, setModal] = useQueryParam<UserlandModal>('modal')
    const [, setEditProfileActiveTab] = useQueryParam<EditTabOption>('editProfileActiveTab')

    const isAdmin = currentOrganisation?.currentUserRoles.includes(RoleType.Manage)

    const [hover, setHover] = useState(false)

    let branchName = `${profile.firstName}'${profile.firstName.endsWith('s') ? '' : 's'}`
    if (profile.openPosition) {
        branchName = 'this'
    }

    const ACTIONS: Action[] = [
        {
            name: 'go_back',
            label: 'Go back',
            onClick: async () => {
                resetFilters()
                trackAnalyticsEvent('orgCharts', {
                    page: 'full-view',
                    component: 'button',
                    type: 'click',
                    action: 'back',
                    name: 'sub_org_chart_branch',
                })
            },
            admin: false,
        },
        {
            name: 'sub_org_chart_branch',
            label: `Show ${branchName} branch only`,
            onClick: async () => {
                resetFilters()
                setTopLevelProfile(profile.idStr)
                trackAnalyticsEvent('orgCharts', {
                    page: 'full-view',
                    component: 'button',
                    type: 'click',
                    action: 'select',
                    name: 'sub_org_chart_branch',
                })
            },
            hoverAttributes: {
                onMouseEnter: () => {
                    setHoveringIdStr(profile.idStr)
                },
                onMouseLeave: () => {
                    setHoveringIdStr(null)
                },
            },
            admin: false,
        },
        {
            name: 'add_open_position',
            label: 'Add open position below',
            onClick: async () => addOpenPositionUnder(profile.idStr),
            admin: true,
        },
        {
            name: 'edit_reporting_line',
            label: 'Edit reporting line',
            onClick: async () => {
                trackAnalyticsEvent('click_edit_reporting_line', {from: 'orgchart_context_menu'})
                setEditProfileId(profile.idStr)
                setEditProfileActiveTab('reportingLine')
                setModal('editProfile')
            },
            admin: true,
        },
    ]
        .filter((action) => (action.admin ? isAdmin : true))
        .filter((action) => (canShowBranch ? true : action.name !== 'sub_org_chart_branch'))
        .filter((action) => (isTopLevel ? true : action.name !== 'go_back'))

    if (ACTIONS.length === 0) return null

    return (
        <>
            <Spacer width={8} />
            <ContextMenuButton
                style={{flexShrink: 0, marginBottom: '-3px'}}
                variant="link"
                onClick={() => setMenuOpen((menu) => !menu)}
            >
                <MenuIconWrapper ref={menuRef}>
                    <ContextMenuIcon
                        icon={SvgContextualMenu}
                        size={24}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        tint={hover ? colors.darkBlue : colors.primary[100]}
                    />
                    <div style={{width: '5px'}} />
                </MenuIconWrapper>
            </ContextMenuButton>
            <Panel
                placement="bottom-end"
                positionFixed
                targetRef={menuRef}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >
                <List
                    rows={ACTIONS}
                    renderRow={(action) => {
                        return (
                            <FlexItem
                                onClick={async () => {
                                    await action.onClick()
                                    setMenuOpen(false)
                                }}
                                {...action.hoverAttributes}
                            >
                                <Typography.Paragraph bottomMargin={false}>{action.label}</Typography.Paragraph>
                                {action.admin && <AdminIndicator>Admin only</AdminIndicator>}
                            </FlexItem>
                        )
                    }}
                    width={PANEL_WIDTH}
                    heading={<Typography.Label>Options</Typography.Label>}
                    variant="dropdown"
                />
            </Panel>
        </>
    )
}

export const ProfileRowAction = React.memo(
    ({profile, canShowBranch}: {profile: FullOrgChartData_organisation_profiles; canShowBranch: boolean}) => {
        const [topLevelProfileIdStr, setTopLevelProfileIdStr] = useQueryParam<string>('topLevelProfile')
        const {setHoveringIdStr, scrollToTop} = useFullOrgChart()

        const isTopLevel = topLevelProfileIdStr === profile.idStr

        const setTopLevelProfile = (idStr: string) => {
            scrollToTop()
            setHoveringIdStr(null)
            setTopLevelProfileIdStr(idStr)
        }

        return (
            <RightContent>
                <ContextualMenu
                    profile={profile}
                    isTopLevel={isTopLevel}
                    setTopLevelProfile={setTopLevelProfile}
                    canShowBranch={canShowBranch}
                />
            </RightContent>
        )
    },
)
