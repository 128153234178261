import dateFormat from 'dateformat'
import React from 'react'
import {BooleanParam, useQueryParam} from 'use-query-params'
import {HEADER_HEIGHT} from './constants'
import {styled} from './syledPdf'
import {usePrintContext} from './usePrintContext'

const HeaderContainer = styled.View`
    height: ${HEADER_HEIGHT}px;
    width: 100%;
`

const Container = styled.View`
    flex: 1;
    justify-content: space-between;
`

const BottomSpacer = styled.View`
    height: 18px;
`

const Name = styled.Text`
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    font-family: halyard;
`

const TitleContainer = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
`

const Title = styled.Text`
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    font-family: publico-banner;
`

const Logo = styled.Image`
    height: 25px;
`

export const PageHeader = () => {
    const {organisation} = usePrintContext()
    const [titleParam] = useQueryParam<string>('title')
    const [logoParam] = useQueryParam('logo', BooleanParam)
    const title = titleParam || `${organisation.name} organizational chart`
    const includeLogo = logoParam !== undefined ? logoParam : true
    const imageSrc = organisation?.logo?.file.signedUrl

    return (
        <HeaderContainer>
            <Container>
                <Name>
                    {organisation?.name} {'    '} {dateFormat(new Date(), 'mmmm yyyy')}
                </Name>

                <TitleContainer>
                    <Title>{title}</Title>
                    {includeLogo && imageSrc && <Logo src={imageSrc} />}
                </TitleContainer>
            </Container>
            <BottomSpacer />
        </HeaderContainer>
    )
}
