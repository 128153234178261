import React from 'react'
import {SignUp} from './SignUp'
import {CheckYourEmail} from '../Primitives/CheckYourEmail'
import {PupilDetails} from './PupilDetails'
import {ParentDetails} from './ParentDetails'
import {Region, UploadFamilyPhoto} from './UploadFamilyPhoto'
import {Consent} from './Consent'
import {Column, Row} from '../Primitives/Layout'
import {Spinner} from 'nf-ui'
import {ErrorState} from '../Primitives'
import {FormFieldConfig, FormState, mergeFormState} from '../Primitives/Forms'
import {useAlert} from 'react-alert'
import {Complete} from './Complete'
import {playground} from '~/pages/Playground'
import {omit} from 'lodash'
import {QuestionsPanelContent} from './Form'

export type Page =
    | 'loading'
    | 'sign-up'
    | 'check-your-mail'
    | 'resume-submission'
    | 'pupil-details'
    | 'parent-details'
    | 'upload-family-photo'
    | 'upload-household2-photo'
    | 'consent'
    | 'complete'

export type PageState = {
    errorState: ErrorState
    formState: FormState
    directoryName?: string
    organisationIdStr?: string
    masterDirectoryIdStr?: string
    emailAddress?: string
    code?: string
    responseIdStr?: string
    originalFileHousehold1?: File
    thumbRegionHousehold1?: Region
    originalFileHousehold2?: File
    thumbRegionHousehold2?: Region
}

const SpinnerPage = () => (
    <Column height="100%" width="100%" centerVertical>
        <Row width="100%" centerHorizontal>
            <Spinner size={24}></Spinner>
        </Row>
    </Column>
)

export const CommunityBuildFlow = ({
    page,
    setPage,
    pageState,
    setPageState,
    formFields,
    requestOtp,
    signInOtp,
    updateDraftProfile,
    uploadPhotoHousehold1,
    uploadPhotoHousehold2,
    giveConsent,
}: {
    page: Page
    setPage: (value: Page) => void
    pageState: PageState
    setPageState: (value: PageState) => void
    formFields: FormFieldConfig[]
    requestOtp: (emailAddress: string) => Promise<void>
    signInOtp: (otp: string, email: string) => Promise<boolean>
    updateDraftProfile: (
        responseIdStr: string,
        email: string,
        draftProfile: {name: string; value: string}[],
    ) => Promise<void>
    uploadPhotoHousehold1: (
        responseIdStr: string,
        email: string,
        originalFile: File,
        thumbRegion?: {left: number; top: number; width: number; height: number},
    ) => Promise<void>
    uploadPhotoHousehold2: (
        responseIdStr: string,
        email: string,
        originalFile: File,
        thumbRegion?: {left: number; top: number; width: number; height: number},
    ) => Promise<void>
    giveConsent: (responseIdStr: string, email: string) => Promise<void>
}) => {
    const alert = useAlert()

    if (page === 'sign-up') {
        return (
            <SignUp
                directoryName={pageState.directoryName || ''}
                email={pageState.emailAddress || ''}
                setEmail={(value) => setPageState({...pageState, emailAddress: value})}
                onNext={async () => {
                    if (!pageState.emailAddress) return
                    await requestOtp(pageState.emailAddress)
                    setPageState({...pageState, errorState: {}, code: ''})
                    setPage('check-your-mail')
                }}
                errorState={pageState.errorState}
                setErrorState={(value) => setPageState({...pageState, errorState: value})}
            />
        )
    }

    if (page === 'check-your-mail') {
        return (
            <CheckYourEmail
                questionsPanelContent={
                    <QuestionsPanelContent directoryName={pageState.directoryName || ''}></QuestionsPanelContent>
                }
                code={pageState.code || ''}
                setCode={(value) => setPageState({...pageState, code: value})}
                onNext={async () => {
                    if (!pageState.code || !pageState.emailAddress) return
                    if (!(await signInOtp(pageState.code, pageState.emailAddress))) {
                        setPageState({...pageState, errorState: {code: {visible: true, message: 'Invalid code'}}})
                    } else {
                        setPageState({
                            ...pageState,
                            errorState: {},
                            formState: {'Parent 1 Email Address': pageState.emailAddress, ...pageState.formState},
                        })
                        setPage('resume-submission')
                    }
                }}
                onDidntGetCode={async () => {
                    if (!pageState.emailAddress) return
                    await requestOtp(pageState.emailAddress)
                    alert.success('We have emailed you a new code.')
                }}
                errorState={pageState.errorState}
                setErrorState={(value) => setPageState({...pageState, errorState: value})}
            />
        )
    }

    if (page === 'pupil-details') {
        return (
            <PupilDetails
                directoryName={pageState.directoryName || ''}
                formFields={formFields}
                formState={pageState.formState}
                setFormState={(value) =>
                    setPageState({...pageState, formState: mergeFormState(pageState.formState, value)})
                }
                onBack={() => {
                    setPageState({...pageState, errorState: {}})
                    setPage('sign-up')
                }}
                onNext={async () => {
                    if (!pageState.responseIdStr || !pageState.emailAddress) return
                    await updateDraftProfile(
                        pageState.responseIdStr,
                        pageState.emailAddress,
                        Object.keys(pageState.formState).map((key) => ({
                            name: key,
                            value: pageState.formState[key],
                        })),
                    )
                    setPageState({...pageState, errorState: {}})
                    setPage('parent-details')
                }}
                errorState={pageState.errorState}
                setErrorState={(value) => setPageState({...pageState, errorState: value})}
            />
        )
    }

    if (page === 'parent-details') {
        return (
            <ParentDetails
                directoryName={pageState.directoryName || ''}
                formState={pageState.formState}
                setFormState={(value) =>
                    setPageState({...pageState, formState: mergeFormState(pageState.formState, value)})
                }
                onBack={() => {
                    setPageState({...pageState, errorState: {}})
                    setPage('pupil-details')
                }}
                onNext={async () => {
                    if (!pageState.responseIdStr || !pageState.emailAddress) return
                    await updateDraftProfile(
                        pageState.responseIdStr,
                        pageState.emailAddress,
                        Object.keys(pageState.formState).map((key) => ({
                            name: key,
                            value: pageState.formState[key],
                        })),
                    )
                    setPageState({...pageState, errorState: {}})
                    setPage('upload-family-photo')
                }}
                errorState={pageState.errorState}
                setErrorState={(value) => setPageState({...pageState, errorState: value})}
            />
        )
    }

    if (page === 'upload-family-photo') {
        return (
            <UploadFamilyPhoto
                heading="Household 1: Upload a family photo"
                directoryName={pageState.directoryName || ''}
                pupilFirstName={pageState.formState['Student First Name'] || ''}
                originalFile={pageState.originalFileHousehold1}
                setOriginalFile={(value) => {
                    if (!value) {
                        setPageState(omit(pageState, 'originalFileHousehold1', 'thumbRegionHousehold1'))
                    } else {
                        setPageState({...pageState, originalFileHousehold1: value})
                    }
                }}
                thumbRegion={pageState.thumbRegionHousehold1}
                setThumbRegion={(value) => {
                    if (!value) {
                        setPageState(omit(pageState, 'thumbRegionHousehold1'))
                    } else {
                        setPageState({...pageState, thumbRegionHousehold1: value})
                    }
                }}
                onBack={() => {
                    setPageState({...pageState, errorState: {}})
                    setPage('parent-details')
                }}
                onNext={async () => {
                    if (pageState.responseIdStr && pageState.emailAddress && pageState.originalFileHousehold1) {
                        await uploadPhotoHousehold1(
                            pageState.responseIdStr,
                            pageState.emailAddress,
                            pageState.originalFileHousehold1,
                            pageState.thumbRegionHousehold1,
                        )
                    }
                    setPageState({...pageState, errorState: {}})
                    if (pageState.formState['Parent 3 First Name']) setPage('upload-household2-photo')
                    else setPage('consent')
                }}
            />
        )
    }

    if (page === 'upload-household2-photo') {
        return (
            <UploadFamilyPhoto
                heading="Household 2: Upload a family photo"
                directoryName={pageState.directoryName || ''}
                pupilFirstName={pageState.formState['Student First Name'] || ''}
                originalFile={pageState.originalFileHousehold2}
                setOriginalFile={(value) => {
                    if (!value) {
                        setPageState(omit(pageState, 'originalFileHousehold2'))
                    } else {
                        setPageState({...pageState, originalFileHousehold2: value})
                    }
                }}
                thumbRegion={pageState.thumbRegionHousehold2}
                setThumbRegion={(value) => {
                    if (!value) {
                        setPageState(omit(pageState, 'thumbRegionHousehold2'))
                    } else {
                        setPageState({...pageState, thumbRegionHousehold2: value})
                    }
                }}
                onBack={() => {
                    setPageState({...pageState, errorState: {}})
                    setPage('upload-family-photo')
                }}
                onNext={async () => {
                    if (pageState.responseIdStr && pageState.emailAddress && pageState.originalFileHousehold2) {
                        await uploadPhotoHousehold2(
                            pageState.responseIdStr,
                            pageState.emailAddress,
                            pageState.originalFileHousehold2,
                            pageState.thumbRegionHousehold2,
                        )
                    }
                    setPageState({...pageState, errorState: {}})
                    setPage('consent')
                }}
            />
        )
    }

    if (page === 'consent') {
        return (
            <Consent
                directoryName={pageState.directoryName || ''}
                onNext={async () => {
                    if (!pageState.responseIdStr || !pageState.emailAddress) return
                    await giveConsent(pageState.responseIdStr, pageState.emailAddress)
                    setPageState({...pageState, errorState: {}})
                    setPage('complete')
                }}
            ></Consent>
        )
    }
    if (page === 'complete') {
        return (
            <Complete
                directoryName={pageState.directoryName || ''}
                organisationIdStr={pageState.organisationIdStr || ''}
                masterDirectoryIdStr={pageState.masterDirectoryIdStr || ''}
            ></Complete>
        )
    }
    return <SpinnerPage></SpinnerPage>
}

playground.push({
    path: 'src/components/CommunityBuild/CommunityBuildFlow.tsx',
    component: CommunityBuildFlow,
    props: {
        page: 'sign-up',
        pageState: {
            directoryName: 'Wetpups Grade 3',
            formState: {},
            errorState: {},
        },
        formFields: [
            {
                name: 'Grade',
                availableValues: ['Grade 1'],
                canCreateValue: false,
            },
            {name: 'Class', availableValues: ['Mrs. Balls', 'Col. Mustard'], canCreateValue: true},
        ],
    },
    propOptions: {
        page: {
            get: (props: any) => JSON.stringify(props.page),
        },
        setPage: ({props, args}: {props: any; args: any[]}) => {
            return args[0] === 'resume-submission'
                ? {...props, page: 'pupil-details', pageState: {...props.pageState, responseIdStr: 'responseIdStr'}}
                : {...props, page: args[0]}
        },
        pageState: {
            get: (props: any) => JSON.stringify(props.pageState),
        },
        setPageState: ({props, args}: {props: any; args: any[]}) => ({...props, pageState: args[0]}),
        requestOtp: () => {},
        signInOtp: () => {},
        updateDraftProfile: () => {},
        uploadPhotoHousehold1: () => {},
        uploadPhotoHousehold2: () => {},
        giveConsent: () => {},
    },
})
