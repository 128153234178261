import * as React from 'react'
const SvgUser = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgUser(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" ref={svgRef} {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.7 5.6C2.7 2.671 5.072.3 8 .3s5.3 2.372 5.3 5.3c0 1.74-.845 3.34-2.235 4.328l-.41.292.451.222.202.105c1.659.914 2.792 2.78 2.792 4.653a.5.5 0 11-1 0c0-1.996-1.588-3.913-3.495-4.193-.49-.07-1.007-.107-1.605-.107-.6 0-1.118.037-1.596.107-1.908.28-3.504 2.2-3.504 4.193a.5.5 0 11-1 0c0-1.95 1.227-3.89 3.003-4.758l.455-.222-.414-.292-.196-.145A5.283 5.283 0 012.7 5.599zm9.6 0a4.301 4.301 0 00-8.6 0 4.301 4.301 0 008.6 0z"
                fill="#101010"
            />
        </svg>
    )
})
export default SvgUser
