import {colors, Dropdown, List, Typography, Spacer, Icon} from 'nf-ui'
import SvgCheckCircle from 'nf-ui/Icons/CheckCircle'
import SvgSwitcher from 'nf-ui/Icons/Switcher'
import React, {useState} from 'react'
import styled from 'styled-components'
import {FieldType, SelectedColumn} from '~/objectTypes'

const ListHeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
`
const SvgCheckCircleGreen = styled(SvgCheckCircle)`
    circle {
        fill: ${colors.green[100]};
    }
`

type Option = {
    label: string
    name?: string
    type?: FieldType
}

type FieldOptions = {
    namedOptions: Option[]
    unnamedOptions: Option[]
    allOptions: Option[]
}

export const FieldTypeSelect = ({
    options,
    disabled,
    selectedColumn,
    onSelectOption,
}: {
    options: FieldOptions
    disabled: boolean
    selectedColumn?: SelectedColumn
    onSelectOption: (option: Option) => void
}) => {
    const {namedOptions, unnamedOptions, allOptions} = options
    const [openPanel, setOpenPanel] = useState(false)

    const isSelected = (option: Option) => {
        if (!selectedColumn) return false
        return selectedColumn.fieldName === option.name || selectedColumn.type === option.type
    }

    const selectedOption = allOptions.find((option) => isSelected(option))

    return (
        <>
            <div style={{width: 304}}>
                <Dropdown buttonTarget noPortal positionFixed open={openPanel} onClose={() => setOpenPanel(false)}>
                    <Dropdown.Button disabled={disabled} icon={SvgSwitcher} onClick={() => setOpenPanel(true)}>
                        {selectedOption?.label || 'Select field type...'}
                    </Dropdown.Button>
                    <Dropdown.Menu>
                        <List
                            width={304}
                            heading={
                                <ListHeadingContainer>
                                    <Typography.Label color="primary">Popular field types</Typography.Label>
                                </ListHeadingContainer>
                            }
                            rows={namedOptions}
                            renderRow={(option) => <>{option.label}</>}
                            onClick={onSelectOption}
                        />
                        <List
                            width={304}
                            heading={
                                <ListHeadingContainer>
                                    <Typography.Label color="primary">Other field types</Typography.Label>
                                </ListHeadingContainer>
                            }
                            rows={unnamedOptions}
                            renderRow={(option) => <>{option.label}</>}
                            onClick={onSelectOption}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            {!!selectedOption && (
                <IconContainer>
                    <Spacer width={40} />
                    <Icon icon={SvgCheckCircleGreen} />
                </IconContainer>
            )}
        </>
    )
}
