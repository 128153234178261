import {gql, useQuery} from '@apollo/client'
import {Button, Form, Icon, List, Panel, Spacer, Typography, useTheme} from 'nf-ui'
import SvgCaret from 'nf-ui/Icons/Caret'
import SvgCopy from 'nf-ui/Icons/Copy'
import SvgEmail from 'nf-ui/Icons/Email'
import SvgGmail from 'nf-ui/Icons/Gmail'
import SvgOutlook from 'nf-ui/Icons/Outlook'
import SvgYahoo from 'nf-ui/Icons/Yahoo'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {ModalLayout} from '~/components/ModalLayout'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {PageLoading} from '~/components/PageLoading'
import {PanelLayout} from '~/components/PanelLayout'
import {PanelHeader, usePanelNavigator} from '~/components/PanelNavigator'
import {searchProfiles} from '~/components/PhotoManagementCommon'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {useProfileSelect} from '~/pages/Adminland/Integrations/AddAdditionalDataModal/CollectStep/useProfileSelect'
import {getGraphQLErrorMessage} from '~/util'
import {EMAIL_CLIENT, useBroadcastMessage} from '~/util/emailGroup'
import {useProfileGroupContext} from '../ProfileGroupContext'
import {ProfileList} from './ProfileList'
import {
    GroupMessageData,
    GroupMessageDataVariables,
    GroupMessageData_organisation_profiles,
} from './__types__/GroupMessageData'

const GROUP_MESSAGE_QUERY = gql`
    query GroupMessageData($idStr: String!, $profileIdStrs: [String!]) {
        organisation(idStr: $idStr) {
            idStr
            profiles(profileIdStrs: $profileIdStrs) {
                idStr
                firstName
                lastName
                fullName
                email
                photos {
                    idStr
                    profileUrl
                    tinyUrl
                    priority
                }
            }
        }
    }
`

export type GroupMessageProfile = GroupMessageData_organisation_profiles

const FlexContainer = styled.div`
    display: flex;
`

const TabsContainer = styled.div`
    width: 560px;
`

const SearchContainer = styled.div`
    width: 268px;
`

export const GroupProfileSelection = ({
    profiles,
    profileIdStrs,
}: {
    profiles: GroupMessageData_organisation_profiles[]
    profileIdStrs: string[]
}) => {
    const theme = useTheme()

    const [profileSearchTerm, setProfileSearchTerm] = useState('')
    const searchedProfiles = searchProfiles(profiles, profileSearchTerm)

    const enabledProfiles = profiles.filter((profile) => profile.email)
    const enabledProfileIdStrs = enabledProfiles.map((profile) => profile.idStr)
    const disabledProfileIdStrs = profileIdStrs.filter((idStr) => !enabledProfileIdStrs.includes(idStr))
    const profileSelection = useProfileSelect<GroupMessageProfile>(enabledProfiles, enabledProfileIdStrs)

    const emailClientsRef = useRef<HTMLButtonElement>(null)
    const {openPanel, panelProps, closePanel} = usePanelNavigator('emailClients')

    const getEmailAddresses = useCallback(() => {
        return profileSelection.profiles
            .filter((p) => profileSelection.checkedProfiles.includes(p.idStr) && p.email)
            .map((p) => p.email)
    }, [profileSelection.profiles, profileSelection.checkedProfiles])

    const {composeInEmailClient, copyAsText} = useBroadcastMessage(getEmailAddresses())
    const composeDisabled = getEmailAddresses().length < 1
    const emailClientsPanelRows = [
        {
            icon: SvgGmail,
            label: 'Gmail',
            onClick: () => {
                composeInEmailClient(EMAIL_CLIENT.GMAIL)
                closePanel()
            },
        },
        {
            icon: SvgOutlook,
            label: 'Outlook',
            onClick: () => {
                composeInEmailClient(EMAIL_CLIENT.OUTLOOK)
                closePanel()
            },
        },
        {
            icon: SvgYahoo,
            label: 'Yahoo',
            onClick: () => {
                composeInEmailClient(EMAIL_CLIENT.YAHOO)
                closePanel()
            },
        },
        {
            icon: SvgEmail,
            label: 'Default email app',
            onClick: () => {
                composeInEmailClient(EMAIL_CLIENT.DEFAULT)
                closePanel()
            },
        },
        {
            icon: SvgCopy,
            label: 'Copy email addresses',
            onClick: async () => {
                copyAsText()
                closePanel()
            },
        },
    ]

    return (
        <>
            <Typography.Heading>Select recipients</Typography.Heading>
            <Spacer height={16} />
            <SearchContainer>
                <Form.Group name="search">
                    <Form.SearchInput value={profileSearchTerm} onChange={(value) => setProfileSearchTerm(value)} />
                </Form.Group>
            </SearchContainer>
            <Spacer height={32} />
            <FlexContainer>
                <TabsContainer>
                    <ProfileList
                        profiles={searchedProfiles}
                        profileSelection={profileSelection}
                        disabledProfileIdStrs={disabledProfileIdStrs}
                    />
                </TabsContainer>
                <Spacer width={160} />
            </FlexContainer>
            <ModalLayout.Footer back={false} color={theme.primary.color}>
                <Button
                    variant="secondary"
                    ref={emailClientsRef}
                    organisationTheme={theme}
                    onClickAnalyticsEvent="select_email_clients"
                    onClick={() => openPanel('emailClients', {itemId: 'emailClients'})}
                    disabled={composeDisabled}
                >
                    Compose email
                    <Button.Icon icon={SvgCaret} />
                </Button>
                <Panel targetRef={emailClientsRef} {...panelProps}>
                    <PanelLayout key="emailClients">
                        <PanelHeader panel="emailClients">Compose using</PanelHeader>
                        <List
                            rows={emailClientsPanelRows}
                            onClick={(row) => {
                                row.onClick()
                            }}
                            renderRow={(row) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        height: '100%',
                                    }}
                                >
                                    {row.label} <Icon icon={row.icon} />
                                </div>
                            )}
                            width={304}
                        />
                    </PanelLayout>
                </Panel>
            </ModalLayout.Footer>
        </>
    )
}

export const GroupMessageModalBody: React.FC = () => {
    const {profileIdStrs} = useProfileGroupContext()
    const {onClose} = useModalNavigator()

    const orgIdStr = useOrganisationIdStr()
    const {data, loading, error} = useQuery<GroupMessageData, GroupMessageDataVariables>(GROUP_MESSAGE_QUERY, {
        variables: {
            idStr: orgIdStr,
            profileIdStrs,
        },
    })

    useEffect(() => {
        if (!profileIdStrs.length) onClose()
    }, [profileIdStrs, onClose])

    const profiles = data?.organisation.profiles.filter((profile) => profileIdStrs.includes(profile.idStr)) || []

    if (loading) return <PageLoading />
    if (error) return <Typography.Heading>Error: {getGraphQLErrorMessage(error)}</Typography.Heading>

    return <GroupProfileSelection profiles={profiles} profileIdStrs={profileIdStrs} />
}
