import React, {useContext, useMemo, useState, useImperativeHandle, ReactNode} from 'react'

export type ExpandedIndex = number | null

type CollapsibleGroupContext = {
    expandedIndex: ExpandedIndex
    setExpandedIndex: (key: ExpandedIndex) => void
}

const CollapsibleGroupContext = React.createContext<CollapsibleGroupContext | null>(null)

export const useCollapsibleGroupContext = () => {
    const context = useContext(CollapsibleGroupContext)
    if (!context) throw new Error('Cannot use useCollapsibleGroupContext outside of a <CollapsibleGroup />')
    return context
}

export type CollapsibleGroupMethods = {
    setExpandedIndex: (index: number) => void
}

export const CollapsibleGroup = React.forwardRef<CollapsibleGroupMethods, {children: ReactNode}>(({children}, ref) => {
    const [expandedIndex, setExpandedIndex] = useState<ExpandedIndex>(0)

    useImperativeHandle(ref, () => ({setExpandedIndex: (index) => setExpandedIndex(index)}))

    const context = useMemo<CollapsibleGroupContext>(
        () => ({
            setExpandedIndex,
            expandedIndex,
        }),
        [expandedIndex],
    )

    return <CollapsibleGroupContext.Provider value={context}>{children}</CollapsibleGroupContext.Provider>
})
