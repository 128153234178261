import {gql, useMutation, useQuery} from '@apollo/client'
import {
    Button,
    colors,
    Dropdown,
    LighboxContent,
    LightboxContainer,
    LightboxContainerVariants,
    LightboxContentVariants,
    List,
    Spacer,
    Typography,
} from 'nf-ui'
import SvgChevronDown from 'nf-ui/Icons/ChevronDown'
import SvgClose from 'nf-ui/Icons/Close'
import pluralize from 'pluralize'
import React, {FC, useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {getGraphQLErrorMessage} from '~/util'
import {getPhotosDataQuery} from './Photos'
import {MatchPhotosByFieldCount, MatchPhotosByFieldCountVariables} from './__types__/MatchPhotosByFieldCount'
import {PhotoMatchAllModal_match, PhotoMatchAllModal_matchVariables} from './__types__/PhotoMatchAllModal_match'
import {PhotosData} from './__types__/PhotosData'

const MATCH_COUNT_QUERY = gql`
    query MatchPhotosByFieldCount($fieldIdStr: String!) {
        matchPhotosByFieldCount(fieldIdStr: $fieldIdStr)
    }
`

const RightContainer = styled.div`
    position: absolute;
    right: 16px;
    top: 32px;
`

const TextContainer = styled.div`
    padding: 16px;
    border-radius: 3px;
    background: ${colors.lightGray};
    border: solid 1px ${colors.darkGray};
`

export type Field = PhotosData['organisation']['fields'][0]

export type PhotoMatcAllhModalProps = {
    fields: Field[]
}

const DropdownContainer = styled.div`
    width: 302px;
`

const MATCH_PHOTOS = gql`
    mutation PhotoMatchAllModal_match($fieldIdStr: String!) {
        matchPhotosByField(fieldIdStr: $fieldIdStr)
    }
`

export const PhotoMatchAllModal: FC<PhotoMatcAllhModalProps> = ({fields}) => {
    const {onClose} = useModalNavigator()
    const [openPanel, setOpenPanel] = useState(false)
    const [selectedField, setSelectedField] = useState<Field | null>(null)
    const {data: photoData} = useQuery<MatchPhotosByFieldCount, MatchPhotosByFieldCountVariables>(MATCH_COUNT_QUERY, {
        variables: {
            fieldIdStr: selectedField?.idStr!,
        },
        skip: !selectedField,
    })
    const matchCount = photoData?.matchPhotosByFieldCount ?? 0

    const alert = useAlert()
    const organisationIdStr = useOrganisationIdStr()
    const [matchPhotos, matchPhotosStatus] = useMutation<PhotoMatchAllModal_match, PhotoMatchAllModal_matchVariables>(
        MATCH_PHOTOS,
        {
            awaitRefetchQueries: true,
            refetchQueries: [getPhotosDataQuery(organisationIdStr)],
            onError: (error) => {
                alert.error(getGraphQLErrorMessage(error))
            },
            onCompleted: () => {
                alert.success(`${matchCount} ${pluralize('photo', matchCount)} matched successfully`)
                onClose()
            },
        },
    )

    return (
        <LightboxContainer variants={LightboxContainerVariants} initial="close" animate="open" exit="close">
            <LighboxContent variants={LightboxContentVariants} width={560} padding={32}>
                <Typography.Heading>Match all photos</Typography.Heading>
                <RightContainer>
                    <Button variant="tertiary" onClick={onClose}>
                        Close
                        <Button.Icon icon={SvgClose} />
                    </Button>
                </RightContainer>
                <Spacer height={32} />
                <Typography.Paragraph>
                    Select the field in your data that matches the filenames of your photos.
                </Typography.Paragraph>
                <TextContainer>
                    <Typography.Paragraph bottomMargin={false}>
                        We recommend using people’s email addresses as the filenames of their photos. For example:
                    </Typography.Paragraph>
                    <Typography.Paragraph bottomMargin={false}>jack@company.com.jpg</Typography.Paragraph>
                </TextContainer>
                <Spacer height={32} />
                <DropdownContainer>
                    <Typography.Label>Select your field</Typography.Label>
                    <Spacer height={8} />
                    <Dropdown buttonTarget noPortal positionFixed open={openPanel} onClose={() => setOpenPanel(false)}>
                        <Dropdown.Button icon={SvgChevronDown} onClick={() => setOpenPanel(true)}>
                            {selectedField?.label || ''}
                        </Dropdown.Button>
                        <Dropdown.Menu>
                            <List
                                width={304}
                                rows={fields}
                                renderRow={(v) => v.label}
                                onClick={(value) => {
                                    setSelectedField(value)
                                }}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </DropdownContainer>
                <Spacer height={24} />
                <Typography.Paragraph bottomMargin={false}>
                    You are matching <Typography.Label>{matchCount}</Typography.Label> {pluralize('photo', matchCount)}
                </Typography.Paragraph>
                <Spacer height={24} />
                <Button
                    variant="primary"
                    disabled={!selectedField || matchCount === 0}
                    loading={matchPhotosStatus.loading}
                    onClick={() => {
                        if (!selectedField) return
                        matchPhotos({variables: {fieldIdStr: selectedField.idStr}})
                    }}
                    onClickAnalyticsEvent={'select_match_your_field'}
                    onClickAnalyticsData={{option: selectedField?.label || ''}}
                >
                    Match Photos
                </Button>
            </LighboxContent>
        </LightboxContainer>
    )
}
