import {Typography} from 'nf-ui'
import React from 'react'
import {useQueryCategoryData} from '~/apollo/queries/CategoryData'
import {PageLoading} from '~/components/PageLoading'
import {UserlandActionBar} from './ActionBar'
import {CategoryChildren} from './CategoryChildren'

export const Category = ({categoryIdStr}: {categoryIdStr: string}) => {
    const [{data: categoryData, error, loading}] = useQueryCategoryData(categoryIdStr)

    if (error) return <Typography.Heading>Error</Typography.Heading>
    if (!categoryData || loading)
        return (
            <>
                <PageLoading />
                <UserlandActionBar loading />
            </>
        )

    return <CategoryChildren category={categoryData.category} />
}
