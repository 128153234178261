import React, {useCallback, useContext, useRef, useState} from 'react'
import {PanelProps} from '../Panel'
import {Button} from './Button'
import {Menu} from './Menu'

type DropdownProps = PanelProps & {
    buttonTarget?: boolean
}

export type DropdownContextType = {
    setOpen: (o: boolean) => any
} & Omit<DropdownProps, 'onClose'>

const DropdownContext = React.createContext<DropdownContextType | null>(null)

export const useDropdownContext = () => {
    const value = useContext(DropdownContext)
    return value
}

export const useDropdownContextOrFail = () => {
    const value = useContext(DropdownContext)
    if (!value) throw new Error('Your Dropdown.X components must be wrapped in a <Dropdown /> component.')
    return value
}

interface DropdownComponent extends React.FC<Partial<DropdownProps>> {
    Button: typeof Button
    Menu: typeof Menu
}

const Dropdown: DropdownComponent = ({open: controlledOpen, onClose, children, closeOnClick = true, ...rest}) => {
    const [uncontrolledOpen, setuncontrolledOpen] = useState(false)

    const targetRef = useRef<HTMLElement | null>(null)

    const setOpen = useCallback(
        (o: boolean) => {
            if (typeof controlledOpen !== 'boolean') {
                setuncontrolledOpen(o)
            }

            if (onClose && o === false) {
                onClose()
            }
        },
        [setuncontrolledOpen, onClose, controlledOpen],
    )

    const open = typeof controlledOpen === 'boolean' ? controlledOpen : uncontrolledOpen

    return (
        <DropdownContext.Provider
            value={{
                targetRef,
                setOpen,
                open,
                closeOnClick,
                ...rest,
            }}
        >
            {children}
        </DropdownContext.Provider>
    )
}

Dropdown.Button = Button
Dropdown.Menu = Menu

export {Dropdown}
