import dateFormat from 'dateformat'

export const formatValue = (line: {fieldBehaviour: string; hideYear?: boolean}, value: string) => {
    if (line.fieldBehaviour === 'date') {
        const date = value ? new Date(value) : undefined
        return !date || isNaN((date as unknown) as number)
            ? value
            : line.hideYear || value.length < 8
            ? dateFormat(date, 'd mmmm')
            : dateFormat(date, 'd mmmm yyyy')
    }
    return value
}
