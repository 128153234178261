import styled from 'styled-components'
import {HideableCss} from './css'
import {HideableCssProps} from './types'

export const Divider = styled.div<{color?: string} & HideableCssProps>`
    width: 100%;
    height: 1px;
    background-color: ${({color}) => color || '#e5e5e5'};
    ${HideableCss}
`
