import {gql, useMutation} from '@apollo/client'
import {Button, colors, Icon, Spacer, Typography} from 'nf-ui'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'
import React, {useEffect} from 'react'
import {useAlert} from 'react-alert'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {PageLoading} from '~/components/PageLoading'
import {WizardLayout} from '~/components/WizardLayout'
import {ResendVerificationEmail, ResendVerificationEmailVariables} from './__types__/ResendVerificationEmail'

const RESEND_VERIFICATION_EMAIL = gql`
    mutation ResendVerificationEmail($email: String!) {
        resendVerificationEmail(email: $email)
    }
`

export const useResendVerificationEmail = () => {
    const alert = useAlert()

    return useMutation<ResendVerificationEmail, ResendVerificationEmailVariables>(RESEND_VERIFICATION_EMAIL, {
        onCompleted: () => alert.success('Email sent'),
    })
}

const HeadingContainer = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
`

const BackButton = styled(Icon)`
    margin-top: 10px;
    cursor: pointer;
`

type VerificationEmailSentProps = {
    onClickBack: () => void
    email: string
}

export const VerificationEmailSentContent: React.FC<VerificationEmailSentProps> = ({onClickBack, email}) => {
    const [resendVerificationEmail] = useResendVerificationEmail()

    return (
        <>
            <HeadingContainer>
                <BackButton icon={SvgArrowLeft} tint={colors.primary[100]} onClick={onClickBack} />
                <Spacer width={16} />
                <Typography.Heading>Check your inbox</Typography.Heading>
            </HeadingContainer>
            <Spacer height={16} />
            <Typography.Subheading maxWidth={520}>
                We've sent an email to {email}.<br />
                Please click the link to verify your account.
            </Typography.Subheading>
            <Spacer height={8} />
            <WizardLayout.Footer>
                <Button variant="tertiary" onClick={() => resendVerificationEmail({variables: {email}})}>
                    Resend link
                </Button>
            </WizardLayout.Footer>
        </>
    )
}

const FallbackToLogin: React.FC = () => {
    const {push} = useHistory()

    useEffect(() => {
        push('/login')
    }, [push])

    return <PageLoading />
}

export const VerificationEmailSent: React.FC = () => {
    const [email] = useQueryParam<string>('email')
    const {push} = useHistory()
    const onClickBack = () => push('/login')

    if (!email) return <FallbackToLogin />

    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <VerificationEmailSentContent onClickBack={onClickBack} email={email} />
            </WizardLayout.Body>
        </WizardLayout>
    )
}

export const RedirectToVerificationEmailSent: React.FC<{email: string}> = ({email}) => {
    const {push} = useHistory()

    useEffect(() => {
        push(`/verification_email?email=${email}`)
    }, [push, email])

    return <PageLoading />
}
