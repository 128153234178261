import {Button, colors, Form, Icon, List, Panel, Spacer, Typography} from 'nf-ui'
import SvgCheck from 'nf-ui/Icons/Check'
import SvgFilter from 'nf-ui/Icons/Filter'
import pluralize from 'pluralize'
import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {PANEL_WIDTH} from '~/constants'
import {Column, Row} from '~/pages/Adminland/PhotoCollection/ListComponents'
import {useSelectionContext, useSetSelectedItems} from './SelectionState'

const COLUMN_WIDTH = 360

const SearchContainer = styled.div`
    width: 304px;
`

const FlexContainer = styled.div`
    display: flex;
    width: ${COLUMN_WIDTH}px;
    flex-direction: column;
`

const ListHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`

const LabelContainer = styled.div<{$disabled: boolean | undefined}>`
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${({$disabled}) => ($disabled ? colors.darkGray2 : undefined)};
`

const SortOptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const ListRow = ({item, disabledMessage}: {item: BaseSelectionItem; disabledMessage?: string}) => {
    const {toggleItem, selection} = useSelectionContext()

    const disabled = !!item.disabled
    const labelMessage = disabledMessage && disabled ? `(${disabledMessage})` : ''

    return (
        <Row
            data-testid="list-row"
            onClick={() => {
                if (item.disabled) return
                toggleItem(item.idStr)
            }}
        >
            <Column style={{maxWidth: '100%'}}>
                <Form.Group name="name">
                    <Form.Checkbox disabled={disabled} checked={selection.includes(item.idStr)} readOnly />
                </Form.Group>
                <Spacer width={16} />

                <LabelContainer $disabled={disabled}>
                    {item.label} {labelMessage}
                </LabelContainer>
            </Column>
        </Row>
    )
}

const ListHeader = ({label}: {label: string}) => {
    const {toggleAll, allSelected} = useSelectionContext()
    return (
        <Row>
            <Column>
                <Form.Group name="name">
                    <Form.Checkbox checked={allSelected} onChange={toggleAll} bold label={label} />
                </Form.Group>
            </Column>
        </Row>
    )
}

export const ListItems = ({
    items,
    rowLabel,
    disabledMessage,
}: {
    items: BaseSelectionItem[]
    rowLabel: string
    disabledMessage?: string
}) => {
    return (
        <List
            width={COLUMN_WIDTH}
            heading={<ListHeader label={rowLabel} />}
            rows={items}
            renderRow={(item) => {
                return <ListRow item={item} disabledMessage={disabledMessage} />
            }}
        />
    )
}

const DEFAULT_SORT_OPTIONS = [
    {
        label: 'Alphabetical (A-Z)',
        key: 'ASC',
        sort: (a: BaseSelectionItem, b: BaseSelectionItem) => a.label!.localeCompare(b.label || ''),
    },
    {
        label: 'Alphabetical (Z-A)',
        key: 'DESC',
        sort: (a: BaseSelectionItem, b: BaseSelectionItem) => b.label!.localeCompare(a.label || ''),
    },
]

const allSortOptions = <SelectionItem extends BaseSelectionItem>(sortOptions: SortOption<SelectionItem>[]) => [
    ...DEFAULT_SORT_OPTIONS,
    ...sortOptions,
]

function SortPanel<SelectionItem extends BaseSelectionItem>({
    open,
    setOpen,
    buttonRef,
    rowLabel,
    sortOptions,
}: {
    open: boolean
    setOpen: (state: boolean) => void
    buttonRef: React.RefObject<HTMLButtonElement>
    rowLabel: string
    sortOptions: SortOption<SelectionItem>[]
}) {
    const {sort, setSort} = useSelectionContext()

    const options: SortOption<SelectionItem>[] = allSortOptions(sortOptions)

    const isOptionChecked = (option: string) => option === sort

    const onClickOption = (option: SortOption<SelectionItem>) => {
        setSort(option.key)
        setOpen(false)
    }

    return (
        <Panel open={open} onClose={() => setOpen(false)} targetRef={buttonRef}>
            <List
                width={PANEL_WIDTH}
                heading={
                    <SortOptionContainer>
                        <Typography.Label>Sort by {rowLabel.toLowerCase()}</Typography.Label>
                    </SortOptionContainer>
                }
                rows={options}
                renderRow={(option) => {
                    return (
                        <SortOptionContainer>
                            <Typography.Paragraph bottomMargin={false}>{option.label}</Typography.Paragraph>
                            {isOptionChecked(option.key) && <Icon icon={SvgCheck} tint={colors.green[100]} />}
                        </SortOptionContainer>
                    )
                }}
                onClick={onClickOption}
                variant="dropdown"
            />
        </Panel>
    )
}

function SelectionHeading<SelectionItem extends BaseSelectionItem>({
    count,
    rowLabel,
    sortOptions,
}: {
    count: number
    rowLabel: string
    sortOptions: SortOption<SelectionItem>[]
}) {
    const [open, setOpen] = useState(false)
    const buttonRef = useRef<HTMLButtonElement>(null)

    return (
        <ListHeaderContainer>
            <Typography.Label>
                {count} {pluralize('person', count)} selected
            </Typography.Label>
            <Button variant="tertiary" ref={buttonRef} onClick={() => setOpen((o) => !o)}>
                <Button.Icon icon={SvgFilter} /> Sort
            </Button>
            <SortPanel
                buttonRef={buttonRef}
                open={open}
                setOpen={setOpen}
                rowLabel={rowLabel}
                sortOptions={sortOptions}
            />
        </ListHeaderContainer>
    )
}

type SortOption<SelectionItem extends BaseSelectionItem> = {
    key: string
    label: string
    sort: (a: SelectionItem, b: SelectionItem) => number
}

export type BaseSelectionItem = {
    idStr: string
    label: string
    disabled?: boolean
}

export type SelectionState = {
    selection: string[]
}

type SelectionListProps<SelectionItem extends BaseSelectionItem> = {
    items: SelectionItem[]
    rowLabel: string
    disabledMessage?: string
    sortOptions?: SortOption<SelectionItem>[]
}

function getSortMethod<SelectionItem extends BaseSelectionItem>(
    sort: string,
    sortOptions: SortOption<SelectionItem>[],
) {
    const options = allSortOptions(sortOptions)
    const selected = options.find((option) => option.key === sort)

    return selected?.sort
}

export function SelectionList<SelectionItem extends BaseSelectionItem>({
    items,
    rowLabel,
    disabledMessage,
    sortOptions = [],
}: SelectionListProps<SelectionItem>) {
    const {sort, selection} = useSelectionContext()
    const {selectedIdStrs} = useSetSelectedItems()

    const [searchTerm, setSearchTerm] = useState('')

    const filteredItems =
        searchTerm.length > 0
            ? items.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()))
            : items

    const sortedItems = [
        ...filteredItems.filter((item) => selectedIdStrs?.includes(item.idStr)).sort(getSortMethod(sort, sortOptions)),
        ...filteredItems.filter((item) => !selectedIdStrs?.includes(item.idStr)).sort(getSortMethod(sort, sortOptions)),
    ]

    return (
        <>
            <SearchContainer>
                <Form.Group name="search">
                    <Form.SearchInput value={searchTerm} onChange={(value) => setSearchTerm(value)} />
                </Form.Group>
            </SearchContainer>
            <Spacer height={32} />
            <FlexContainer>
                <SelectionHeading count={selection.length} rowLabel={rowLabel} sortOptions={sortOptions} />
                <ListItems items={sortedItems} rowLabel={rowLabel} disabledMessage={disabledMessage} />
            </FlexContainer>
        </>
    )
}
