import {gql, useMutation} from '@apollo/client'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {ValidateToken} from '~/components/ValidateToken'
import {ResetPassword_ResendEmail, ResetPassword_ResendEmailVariables} from './__types__/ResetPassword_ResendEmail'
import {ResetPassword_SendEmail, ResetPassword_SendEmailVariables} from './__types__/ResetPassword_SendEmail'

const SEND_EMAIL = gql`
    mutation ResetPassword_SendEmail($email: String!) {
        sendResetPasswordEmail(email: $email)
    }
`

const RESEND_EMAIL = gql`
    mutation ResetPassword_ResendEmail($token: String!) {
        resendResetPasswordEmail(token: $token)
    }
`

export const ResetPassword: React.FC<{token: string}> = ({token}) => {
    const [sendEmail] = useMutation<ResetPassword_SendEmail, ResetPassword_SendEmailVariables>(SEND_EMAIL)
    const [resendEmail] = useMutation<ResetPassword_ResendEmail, ResetPassword_ResendEmailVariables>(RESEND_EMAIL)
    const history = useHistory()

    return (
        <ValidateToken
            token={token}
            expired={{
                label: 'Password reset emails expire after 24 hours. Click below to request a new one.',
                onResend: async () => resendEmail({variables: {token: decodeURIComponent(token)}}),
            }}
            checkInbox={{
                label:
                    'We have sent a new email to the associated email address. Please, click the link to set a new password.',
            }}
            invalid={{
                label: 'You need to set your password. Enter the email address you used to sign up to Names & Faces.',
                onResend: async (email) => sendEmail({variables: {email}}),
            }}
            valid={{
                process: () => history.replace(`/setpassword/${token}`),
            }}
        />
    )
}
