import React from 'react'
import {Panel} from '../Panel'
import {useDropdownContextOrFail} from '.'
import styled from 'styled-components'

const MenuPanel = styled(Panel)`
    max-height: 308px;
`

export const Menu: React.FC = ({children}) => {
    const {setOpen, ...panelProps} = useDropdownContextOrFail()

    return (
        <MenuPanel boundariesElement="window" {...panelProps} onClose={() => setOpen(false)}>
            {children}
        </MenuPanel>
    )
}
