import {motion} from 'framer-motion'
import {Button, colors, easeInOutQuad, Spacer, Typography} from 'nf-ui'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import SvgClose from 'nf-ui/Icons/Close'
import React from 'react'
import styled from 'styled-components'
import {WizardLayout} from '~/components/WizardLayout'

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
`

const CancelOverlay = styled(motion.div)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${colors.white};
`

CancelOverlay.defaultProps = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0},
    transition: {duration: 0.3, ease: easeInOutQuad},
}

const CancelIntegrationsModal: React.FC<{
    onClickComplete: () => void
    onClickCancel: () => void
}> = ({onClickComplete, onClickCancel}) => {
    return (
        <CancelOverlay>
            <WizardLayout>
                <WizardLayout.Navigation
                    rightContent={
                        <Button variant="tertiary" onClick={onClickCancel}>
                            Cancel <Button.Icon icon={SvgClose} />
                        </Button>
                    }
                />
                <WizardLayout.Body>
                    <Typography.Heading>You haven't completed your integration</Typography.Heading>
                    <Spacer height={16} />
                    <Typography.Subheading maxWidth={641}>
                        If you decide to cancel all your progress on this integration will be lost and you will need to
                        start over.
                    </Typography.Subheading>
                    <Spacer height={32} />
                    <ButtonContainer>
                        <Button
                            variant="secondary"
                            onClick={onClickComplete}
                            onClickAnalyticsEvent="select_complete_integration"
                        >
                            <Button.Icon icon={SvgArrowLeft} />
                            Complete Integration
                        </Button>
                        <Spacer width={24} />
                        <Button variant="secondary" onClick={onClickCancel} onClickAnalyticsEvent="select_cancel">
                            Cancel
                            <Button.Icon icon={SvgArrowRight} />
                        </Button>
                    </ButtonContainer>
                </WizardLayout.Body>
            </WizardLayout>
        </CancelOverlay>
    )
}

export {CancelIntegrationsModal}
