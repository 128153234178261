import {gql, useQuery} from '@apollo/client'
import {ChildCategoryData, ChildCategoryDataVariables} from './__types__/ChildCategoryData'

const CHILD_CATEGORY_DATA = gql`
    query ChildCategoryData($childCategoryIdStr: String!) {
        childCategory(idStr: $childCategoryIdStr) {
            idStr
            label
            profilesCount
            profiles {
                idStr
                firstName
                lastName
                fullName
                photos {
                    idStr
                    thumbUrl
                    priority
                }
            }
        }
    }
`

export const useQueryChildCategoryData = (childCategoryIdStr: string) => {
    return [
        useQuery<ChildCategoryData, ChildCategoryDataVariables>(CHILD_CATEGORY_DATA, {
            variables: {
                childCategoryIdStr,
            },
        }),
    ]
}
