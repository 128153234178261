import {colors, Typography} from 'nf-ui'
import React, {HTMLAttributes} from 'react'
import styled from 'styled-components'

const Button = styled.button`
    border: 0;
    border-radius: 16px;
    height: 32px;
    background-color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    outline: none;
    cursor: pointer;
    transition: 0.3s box-shadow ease-in-out;

    :focus,
    :hover {
        box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.4);
    }
`

type ActionButtonProps = Pick<HTMLAttributes<HTMLButtonElement>, 'onClick'> & {buttonText: string}

const ActionButton = React.forwardRef<HTMLButtonElement, ActionButtonProps>(({buttonText, ...rest}, ref) => {
    return (
        <Button type="button" {...rest} ref={ref}>
            <Typography.Tiny bold color="primary">
                {buttonText}
            </Typography.Tiny>
        </Button>
    )
})

export {ActionButton}
