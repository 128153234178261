import {ApolloError, gql, MutationHookOptions, MutationTuple, useMutation} from '@apollo/client'
import {useAlert} from 'react-alert'
import {getGraphQLErrorMessage} from '~/util'
import {LogSessionActivity, LogSessionActivityVariables} from './__types__/LogSessionActivity'

const LOG_SESSION_ACTIVITY = gql`
    mutation LogSessionActivity($userIdStr: String!, $platform: String!) {
        logSessionActivity(userIdStr: $userIdStr, platform: $platform)
    }
`

let lastCall: Date | undefined

export const useMutationLogSessionActivity = (
    options?: MutationHookOptions<LogSessionActivity, LogSessionActivityVariables>,
): [
    MutationTuple<LogSessionActivity, LogSessionActivityVariables>,
    (variables: {userIdStr: string; platform: string}) => Promise<void>,
] => {
    const alert = useAlert()
    const mutation = useMutation<LogSessionActivity, LogSessionActivityVariables>(LOG_SESSION_ACTIVITY, options)
    const callMutation = async (variables: {userIdStr: string; platform: string}) => {
        if (lastCall && lastCall.valueOf() > new Date().valueOf() - 15000) return
        lastCall = new Date()
        try {
            await mutation[0]({variables})
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}
