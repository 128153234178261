import {gql, useQuery} from '@apollo/client'
import {useEffect, useState} from 'react'
import {bugsnagClient} from '~/bugSnag'
import {CurrentOrganisation} from './CurrentOrganisationContext'
import {Token} from './TokenContext'
import {IdentifyUserData} from './__types__/IdentifyUserData'

const IDENTIFY_USER_DATA = gql`
    query IdentifyUserData {
        me {
            idStr
            email
            firstName
            lastName
            subId
        }
    }
`

export function useAnalyticsUserId() {
    const [analyticsId, setAnalyticsId] = useState<string | null>(null)
    useEffect(() => {
        const timerId = setInterval(() => {
            const userId = window.analytics?.user?.().id() ?? null
            if (userId !== analyticsId) setAnalyticsId(userId)
        }, 100)

        return () => clearInterval(timerId)
    }, [analyticsId])

    return analyticsId
}

export const useIdentifyUser = () => {
    const {token} = Token.useContainer()
    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const {data, refetch} = useQuery<IdentifyUserData>(IDENTIFY_USER_DATA, {
        fetchPolicy: 'network-only',
        skip: !token,
    })
    const userAnalyticsId = useAnalyticsUserId()

    useEffect(() => {
        if (token) refetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    const user = data?.me

    useEffect(() => {
        window.analytics?.ready(() => {
            // Disable mixpanel geo-location data tracking
            if (window?.mixpanel?.get_config('ip')) window.mixpanel.set_config({ip: !window.mixpanel?.get_config('ip')})

            if (token && user && currentOrganisation) {
                // Migrate to using Sub ID if we're currently using
                // their Self Build User ID
                if (window.analytics?.user().id() === user.idStr) {
                    window.analytics?.alias(user.idStr)
                }

                window.analytics?.identify(user.idStr, {
                    name: `${user.firstName} ${user.lastName}`,
                    email: user.email,
                    idStr: user.idStr,
                    subjectId: user.subId,
                    organisationId: currentOrganisation.idStr,
                    currentOrganisation: currentOrganisation.name,
                })
            }
        })
    }, [user, token, currentOrganisation])

    useEffect(() => {
        if (!bugsnagClient) return
        if (user) {
            bugsnagClient.setUser(user.idStr, user.email || undefined, user.firstName)
            bugsnagClient.addMetadata('user', 'subscriberId', user.subId)
        } else if (userAnalyticsId) {
            bugsnagClient.setUser(userAnalyticsId)
            bugsnagClient.addMetadata('user', 'userAnalyticsId', userAnalyticsId)
        }
    }, [user, userAnalyticsId])
}
