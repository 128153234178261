import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import {BaseButton} from './BaseButton'
import {Icon} from './Icon'
import {Typography} from './Typography'
import {motion, Variants} from 'framer-motion'
import {easeInOutQuad} from './util'
import SvgClose from './Icons/Close'
import {useTheme} from './NFThemeProvider'

export type PillProps = {
    remove?: () => void
} & Omit<JSX.IntrinsicElements['div'], 'ref'>

const transition = {
    duration: 0.3,
    ease: easeInOutQuad,
}

const widthVariants: Variants = {
    open: {
        width: 'auto',
        transition,
    },
    close: {
        width: 0,
        transition,
    },
}

const opacityVariants: Variants = {
    open: {
        opacity: 1,
        transition: {duration: 0, delay: 0.3},
    },
    close: {
        opacity: 0,
        transition: {duration: 0},
    },
}

const PillFade: React.FC = ({children}) => (
    <motion.div style={{flexShrink: 0}} initial="close" animate="open" exit="close" variants={widthVariants}>
        <motion.div variants={opacityVariants}>{children}</motion.div>
    </motion.div>
)

export const Pill: React.FC<PillProps> = ({children, remove, ...rest}) => {
    const theme = useTheme()
    const [hoveringOnRemove, setHoveringOnRemove] = useState(false)

    const color = theme.primary.textColor

    return (
        <PillFade>
            <PillContainer hoveringOnRemove={hoveringOnRemove} {...rest}>
                <Typography.Label color={color}>{children}</Typography.Label>
                {remove && (
                    <RemoveButton
                        onClick={remove}
                        data-testid="removeButton"
                        onMouseOver={() => setHoveringOnRemove(true)}
                        onMouseLeave={() => setHoveringOnRemove(false)}
                    >
                        <Icon icon={SvgClose} tint={color} />
                    </RemoveButton>
                )}
            </PillContainer>
        </PillFade>
    )
}

export const PillContainer = styled.div<{hoveringOnRemove: boolean}>`
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    padding: 4px 16px;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    margin: 4px 0;
    white-space: nowrap;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.3s opacity ease-in-out;
    cursor: default;
    ${(props) =>
        props.hoveringOnRemove
            ? css`
                  background-color: rgba(255, 255, 255, 0.5);
                  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
              `
            : css`
                  :hover {
                      opacity: 0.85;
                  }
              `}
`

const RemoveButton = styled(BaseButton)`
    margin-left: 6px;
    margin-right: -6px;

    :focus {
        box-shadow: none;
    }
`
