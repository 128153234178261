import {gql, useMutation} from '@apollo/client'
import {CURRENT_USER} from '../useCurrentUser'
import {CurrentUserData} from '../__types__/CurrentUserData'
import {SetViewedOrgChartPanel, SetViewedOrgChartPanelVariables} from './__types__/SetViewedOrgChartPanel'

const SET_VIEWED_ORG_CHART_PANEL = gql`
    mutation SetViewedOrgChartPanel($organisationIdStr: String!) {
        setViewedOrgChartPanel(organisationIdStr: $organisationIdStr)
    }
`

export function useViewedOrgChartPanel() {
    const [setViewedOrgChartPanel] = useMutation<SetViewedOrgChartPanel, SetViewedOrgChartPanelVariables>(
        SET_VIEWED_ORG_CHART_PANEL,
        {
            update: (cache) => {
                const currentUser = cache.readQuery<CurrentUserData>({query: CURRENT_USER})
                if (!currentUser || !currentUser.me) return

                cache.writeQuery<CurrentUserData>({
                    query: CURRENT_USER,
                    data: {
                        me: {
                            ...currentUser.me,
                            hasViewedOrgChartPanel: true,
                        },
                    },
                })
            },
        },
    )

    return {setViewedOrgChartPanel}
}
