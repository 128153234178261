import React, {useMemo, useContext} from 'react'
import styled from 'styled-components'

export interface GroupContextValue {
    name: string
}

const GroupContext = React.createContext<GroupContextValue | undefined>(undefined)

export const useGroupContext = (): GroupContextValue => {
    const value = useContext(GroupContext)

    if (!value) return {name: ''}
    return value
}

export const GroupContainer = styled.div``

export type GroupProps = {
    name: string
} & Omit<JSX.IntrinsicElements['div'], 'ref'> //Not forwarding ref, so don't accept it

export const Group = React.forwardRef<HTMLDivElement, GroupProps>(({name = '', children, ...rest}, ref) => {
    const context = useMemo(
        () => ({
            name,
        }),
        [name],
    )

    return (
        <GroupContainer ref={ref} {...rest}>
            <GroupContext.Provider value={context}>{children}</GroupContext.Provider>
        </GroupContainer>
    )
})
