import {gql, useMutation} from '@apollo/client'
import {cloneDeep} from '@apollo/client/utilities'
import pluralize from 'pluralize'
import {useCallback} from 'react'
import {useAlert} from 'react-alert'
import {USERS_DATA} from '../Users'
import {UsersPageData, UsersPageDataVariables} from '../__types__/UsersPageData'
import {RemoveOrganisationUsers, RemoveOrganisationUsersVariables} from './__types__/RemoveOrganisationUsers'

const REMOVE_ORGANISATION_USERS_MUTATION = gql`
    mutation RemoveOrganisationUsers($organisationIdStr: String!, $userIdStrs: [String!]!) {
        removeOrganisationUsers(organisationIdStr: $organisationIdStr, userIdStrs: $userIdStrs)
    }
`

export function useRemoveOrganisationUsersMutation() {
    const {success, error} = useAlert()

    const [mutation, status] = useMutation<RemoveOrganisationUsers, RemoveOrganisationUsersVariables>(
        REMOVE_ORGANISATION_USERS_MUTATION,
        {
            onCompleted: ({removeOrganisationUsers}) =>
                success(
                    `${removeOrganisationUsers} ${pluralize('user', removeOrganisationUsers)} successfully removed`,
                ),
            onError: () => error('Failed to remove users'),
        },
    )

    const removeOrganisationUsers = useCallback(
        async (variables: RemoveOrganisationUsersVariables) => {
            await mutation({
                variables,
                update: (cache) => {
                    const result = cache.readQuery<UsersPageData, UsersPageDataVariables>({
                        query: USERS_DATA,
                        variables: {idStr: variables.organisationIdStr},
                    })

                    if (!result) return

                    const clonedResult = cloneDeep(result)
                    clonedResult.organisation.users = clonedResult.organisation.users.filter(
                        (u) => !variables.userIdStrs.includes(u.idStr),
                    )

                    cache.writeQuery({
                        query: USERS_DATA,
                        data: clonedResult,
                    })
                },
            })
        },
        [mutation],
    )

    return {
        removeOrganisationUsers,
        removeOrganisationUsersStatus: status,
    }
}
