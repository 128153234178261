import {motion} from 'framer-motion'
import {colors, easeInOutQuad} from 'nf-ui'
import React from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {Body} from './Body'
import {Footer, ModalFooterProps} from './Footer'
import {Navigation} from './Navigation'

const animationProps = {
    transition: {duration: 0.3, ease: easeInOutQuad},
    initial: {opacity: 0, y: 25},
    animate: {opacity: 1, y: 0},
    exit: {opacity: 0, y: 25},
}

const DisableBodyScroll = createGlobalStyle`
    body {
        overflow: hidden;
    }
`

const ModalLayoutContainer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
`

const Background = styled(motion.div)`
    background-color: ${colors.white};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
`

export type ScrollProps = {
    noscroll?: boolean
}

const ScrollContainer = styled(motion.div)<ScrollProps>`
    position: relative;
    overflow-y: ${({noscroll}) => (noscroll ? 'hidden' : 'auto')};
    width: 100%;
    height: 100vh;
    z-index: 2;
`

export interface ModalLayoutProps {
    scrollProps?: ScrollProps
    footerProps?: ModalFooterProps
    testId?: string
}

interface ModalLayoutFC extends React.FC<ModalLayoutProps> {
    Navigation: typeof Navigation
    Body: typeof Body
    Footer: typeof Footer
}

export const FooterPropsContext = React.createContext<ModalFooterProps>({})

const ModalLayout: ModalLayoutFC = ({children, footerProps = {}, scrollProps, testId}) => {
    return (
        <ModalLayoutContainer onClick={(event) => event.stopPropagation()} data-testid={testId}>
            <Background {...animationProps} />
            <ScrollContainer {...animationProps} {...scrollProps}>
                <DisableBodyScroll />
                <FooterPropsContext.Provider value={footerProps}>{children}</FooterPropsContext.Provider>
            </ScrollContainer>
        </ModalLayoutContainer>
    )
}

ModalLayout.Navigation = Navigation
ModalLayout.Body = Body
ModalLayout.Footer = Footer

export {ModalLayout}
