import {Spacer} from 'nf-ui'
import React from 'react'
import {MeetingsThisWeek_meetingsThisWeek} from '../Meetings/__types__/MeetingsThisWeek'
import {Meeting} from './Meeting'

export const MeetingsList = ({meetings}: {meetings: MeetingsThisWeek_meetingsThisWeek[]}) => {
    return (
        <>
            {meetings.map((meeting, index) => {
                return (
                    <React.Fragment key={index}>
                        <Meeting meeting={meeting} firstMeeting={index === 0} />
                        <Spacer height={8} />
                    </React.Fragment>
                )
            })}
        </>
    )
}
