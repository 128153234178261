import {AnimatePresence, motion} from 'framer-motion'
import {Button, Icon, Spacer, Typography} from 'nf-ui'
import SvgEllipseDark from 'nf-ui/Icons/EllipseDark'
import SvgEllipseLight from 'nf-ui/Icons/EllipseLight'
import React from 'react'
import styled from 'styled-components'
import {MEDIA_PANEL_HEIGHT, STEP_CONTENT} from './CarouselSteps'

const CONTENT_PANEL_HEIGHT = 264

export const NUMBER_OF_STEPS = Object.keys(STEP_CONTENT).length

const MediaPanel = styled.div`
    height: ${MEDIA_PANEL_HEIGHT}px;
`

const MediaInner = styled.div`
    padding: 8px 8px 0 8px;
    height: ${MEDIA_PANEL_HEIGHT - 8}px;
`

const MediaOverlay = styled.div`
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: ${MEDIA_PANEL_HEIGHT}px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 87.84%, rgba(0, 0, 0, 0.1) 100%);
`

const ContentPanel = styled.div`
    height: ${CONTENT_PANEL_HEIGHT}px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`

const Content = styled.div`
    padding: 16px 32px 0px 32px;
`

const ControlContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px 32px 32px 32px;
`

const IndicatorContainer = styled.div`
    display: flex;
    align-items: flex-end;
`

const DotIcon = styled(Icon)`
    :hover {
        cursor: pointer;
    }
`

const CarouselIndicator = ({step, setStep}: {step: number; setStep: (step: number) => void}) => {
    return (
        <IndicatorContainer>
            {Array.from({length: NUMBER_OF_STEPS}, (_, index) => {
                const isActive = step === index + 1
                return (
                    <React.Fragment key={index}>
                        <DotIcon
                            onClick={() => setStep(index + 1)}
                            size={8}
                            icon={isActive ? SvgEllipseDark : SvgEllipseLight}
                        />
                        {index !== NUMBER_OF_STEPS - 1 && <Spacer width={8} />}
                    </React.Fragment>
                )
            })}
        </IndicatorContainer>
    )
}

export const MeetingsCarousel = ({
    step,
    nextStep,
    setStep,
}: {
    step: number
    nextStep: () => void
    setStep: (step: number) => void
}) => {
    const showOverlay = STEP_CONTENT[step].overlay !== false

    const animateProps = {
        initial: {opacity: 0},
        animate: {opacity: 1},
        exit: {opacity: 0},
    }

    return (
        <>
            <MediaPanel>
                <MediaInner>
                    <AnimatePresence exitBeforeEnter>
                        <motion.div key={step} {...animateProps} style={{height: '100%'}}>
                            {STEP_CONTENT[step]?.media || null}
                        </motion.div>
                    </AnimatePresence>
                </MediaInner>
            </MediaPanel>
            {showOverlay && <MediaOverlay />}
            <ContentPanel>
                <Content>
                    <AnimatePresence exitBeforeEnter>
                        <motion.div key={step} {...animateProps}>
                            <Typography.Heading>{STEP_CONTENT[step].title}</Typography.Heading>
                            <Spacer height={16} />
                            <Typography.Subheading style={{marginBottom: 0}}>
                                {STEP_CONTENT[step].content}
                            </Typography.Subheading>
                        </motion.div>
                    </AnimatePresence>
                </Content>

                <ControlContainer>
                    <CarouselIndicator step={step} setStep={setStep} />
                    <Button onClick={nextStep}>{STEP_CONTENT[step].buttonLabel}</Button>
                </ControlContainer>
            </ContentPanel>
        </>
    )
}
