import {colors, hexToRGB, paragraphFontStyle} from 'nf-ui'
import styled, {css} from 'styled-components'

export type DraggableStyleProps = {
    isDragging?: boolean
    isExpanded?: boolean
}
const disableAlpha = 0.6

export type LayoutItemStyleProps = {
    disabled?: boolean
    isDraggable?: boolean
    cursor?: 'initial' | 'pointer'
    isExpanded?: boolean
    isColumn?: boolean
    fixedHeight?: boolean
    minHeight?: boolean
    minChildrenHeight?: boolean
    fullWidth?: boolean
    hasBorder?: boolean
    hasBottomMargin?: boolean
} & DraggableStyleProps

export const LayoutItem = styled.div<LayoutItemStyleProps>`
    display: flex;
    flex-direction: ${(props) => (props.isColumn ? 'column' : 'row')};
    align-items: ${(props) => (props.isColumn ? 'flex-start' : 'center')};
    justify-content: space-between;

    ${paragraphFontStyle}

    ${(props) => (props.cursor ? `cursor: ${props.cursor};` : '')}
    ${(props) => (props.minChildrenHeight ? '& > * { min-height: 56px; };' : '')}
    ${(props) => (props.minHeight ? 'min-height: 56px;' : '')}
    ${(props) => (props.fixedHeight ? 'height: 56px;' : '')}
    ${(props) => (props.fullWidth ? 'width: 100%;' : '')}

    ${(props) =>
        props.hasBorder
            ? `
        padding: 0 16px;
        box-sizing: border-box;
        border: 1px solid ${colors.darkGray};
        border-radius: 3px;
        background-color: ${colors.white};
    `
            : ''}

    ${(props) => (props.hasBottomMargin ? 'margin-bottom: 8px;' : '')}

    flex: 1;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${(props) =>
        props.disabled &&
        css`
            background-color: ${hexToRGB(colors.white, disableAlpha)};
            border-color: ${hexToRGB(colors.darkGray, disableAlpha)};
            color: ${hexToRGB(colors.darkGray, disableAlpha)};
        `}
    ${(props) =>
        props.isDraggable &&
        css<DraggableStyleProps>`
            transition-duration: 0.3s;
            transition-property: background-color, border, box-shadow;
            transition-timing-function: ease-in-out;

            :hover {
                background-color: ${({isExpanded}) => (isExpanded ? colors.white : colors.lightGray)};
            }

            ${({isDragging, isExpanded}) =>
                isDragging &&
                css`
                    background-color: ${isExpanded ? colors.white : colors.lightGray};
                    border-color: ${colors.primary[100]};
                    box-shadow: 0 0 0 1px inset ${colors.primary[100]};
                `}
        `}

    :hover > .hide-on-hover { display: none };
    :not(:hover) > .reveal-on-hover { display: none };
`

export type BoxSideProps = {
    margin?: string
    padding?: string
    borderColor?: string
}

export type FlexContainerProps = {
    width?: string
    height?: string
    color?: string
    backgroundColor?: string
    fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
    cursor?: 'pointer'
    top?: BoxSideProps
    right?: BoxSideProps
    bottom?: BoxSideProps
    left?: BoxSideProps
} & BoxSideProps

const boxSideCss = (props: BoxSideProps, side?: string) => `
    ${props.margin ? `margin${side ? `-${side}` : ''}: ${props.margin};` : ''}
    ${props.padding ? `padding${side ? `-${side}` : ''}: ${props.padding};` : ''}
    ${props.borderColor ? `border${side ? `-${side}` : ''}: 1px solid ${props.borderColor};` : ''}
`

const flexContainerCss = (props: FlexContainerProps) => `
    font-size: 16px;
    line-height: 24px;
    font-family: 'halyard-display';
    display: flex;
    box-sizing: border-box;
    width: ${props.width || '100%'};
    ${props.height ? `height: ${props.height};` : ''}
    ${props.color ? `color: ${props.color};` : ''}
    ${props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''}
    ${props.fontWeight ? `font-weight: ${props.fontWeight};` : ''}
    ${props.cursor ? `cursor: ${props.cursor};` : ''}
    ${boxSideCss(props)}
    ${props.top ? boxSideCss(props.top, 'top') : ''}
    ${props.right ? boxSideCss(props.right, 'right') : ''}
    ${props.bottom ? boxSideCss(props.bottom, 'bottom') : ''}
    ${props.left ? boxSideCss(props.left, 'left') : ''}
`

export const FlexRow = styled.div<
    {
        horizontalAlign?: 'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly'
        verticalAlign?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'
        hover?: FlexContainerProps
        focus?: FlexContainerProps
    } & FlexContainerProps
>`
    flex-direction: row;
    justify-content: ${({horizontalAlign}) => horizontalAlign || 'flex-start'};
    align-items: ${({verticalAlign}) => verticalAlign || 'center'};
    ${(props) => (props.borderColor ? `border-radius: 3px;` : '')}
    ${(props) => flexContainerCss(props)}
    ${({hover}) => (hover ? `:hover { ${flexContainerCss(hover)} }` : '')}
    ${({focus}) => (focus ? `:focus { ${flexContainerCss(focus)} }` : '')}
`

export const FlexColumn = styled.div<
    {
        verticalAlign?: 'flex-start' | 'center' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly'
        horizontalAlign?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'
        hover?: FlexContainerProps
        focus?: FlexContainerProps
    } & FlexContainerProps
>`
    flex-direction: column;
    align-items: ${({horizontalAlign}) => horizontalAlign || 'center'};
    justify-content: ${({verticalAlign}) => verticalAlign || 'flex-start'};
    ${(props) => (props.borderColor ? `border-radius: 3px;` : '')}
    ${(props) => flexContainerCss(props)}
    ${({hover}) => (hover ? `:hover { ${flexContainerCss(hover)} }` : '')}
    ${({focus}) => (focus ? `:focus { ${flexContainerCss(focus)} }` : '')}
`
