import * as React from 'react'
const SvgCall = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgCall(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M4.424.621C4.08.081 3.414-.16 2.834.116a4.891 4.891 0 00-2.3 2.211C-.098 3.566-.187 5.084.364 6.84c.701 2.242 2.376 4.292 4.69 6.143a24.852 24.852 0 002.531 1.767l.431.26c.333.194.608.345.903.5l.093.042.069.027c.315.104.694.205 1.163.288a7.37 7.37 0 002.1.094c.87-.098 1.64-.39 2.259-.913.723-.617 1.195-1.516 1.378-2.7a1.329 1.329 0 00-.69-1.363 61.186 61.186 0 01-2.421-1.428 1.369 1.369 0 00-1.706.213l-.09.104-1.27 1.618-.217-.105c-1.714-.828-3.445-2.271-4.8-4.473l-.21-.354-.12-.21.665-.59.586-.512c.119-.107.22-.2.309-.286.393-.387.458-.97.214-1.45l-.068-.118L4.423.621zm-1.254.525l.245-.126L5.35 4.112l-.255.243-.587.522-.36.31-.28.248a1.294 1.294 0 00-.24 1.606c1.525 2.692 3.565 4.41 5.586 5.358a1.253 1.253 0 001.448-.286l.089-.103 1.16-1.467.067.053.178-.178c.018-.018.034-.014.097.059l.034.04.027-.042c.675.417 1.407.853 2.07 1.23l.574.32-.036.21c-.157.915-.5 1.56-1.001 1.988-.44.374-1.018.592-1.702.67a6.249 6.249 0 01-1.754-.078 6.968 6.968 0 01-1.063-.268l-.254-.132A23.706 23.706 0 015.72 12.14c-2.16-1.722-3.712-3.622-4.34-5.624-.47-1.491-.398-2.714.099-3.696a3.863 3.863 0 011.48-1.557l.21-.116z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgCall
