import React, {FC, useMemo, useContext} from 'react'
import {ThemeProvider, ThemeContext} from 'styled-components'
import {colors} from './theme'
import {getTextColorForBackground, MIN_RATIO} from './colorContrast'

export type Theme = {
    primary: {
        color: string
        textColor: 'black' | 'white'
        textColorHex: string
    }
}

export const defaultTheme: Theme = {
    primary: {
        color: colors.primary[100],
        textColor: 'white',
        textColorHex: colors.white,
    },
}

export const useTheme = () => {
    const theme = useContext(ThemeContext)
    if (!theme) throw new Error('`useTheme` called outside of a `ThemeContext` provider')

    return theme
}

export const NFThemeProvider: FC<{primaryColor?: string; threshold?: number}> = ({
    children,
    primaryColor,
    threshold = MIN_RATIO,
}) => {
    const contextValue = useMemo(() => {
        if (primaryColor) {
            const textColor = getTextColorForBackground(primaryColor, threshold)
            return {
                primary: {
                    color: primaryColor,
                    textColor: textColor,
                    textColorHex: textColor === 'white' ? colors.white : colors.black,
                },
            } as Theme
        }
        return defaultTheme
    }, [primaryColor, threshold])

    return (
        <ThemeProvider theme={contextValue}>
            <>{children}</>
        </ThemeProvider>
    )
}
