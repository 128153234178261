import {Color as ColorType} from 'csstype'
import {css} from 'styled-components'
import Color from 'color'

export function hexToRGBObject(hex: string) {
    //TODO : #fff is a valid color
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    if (!result) {
        throw new Error('Invalid color')
    }

    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)

    return {r, g, b}
}

function componentToHex(c: number) {
    const hex = Math.floor(c).toString(16)
    return hex.length === 1 ? '0' + hex : hex
}

function rgbToHex(rgb: Record<'r' | 'g' | 'b', number>) {
    return '#' + componentToHex(rgb.r) + componentToHex(rgb.g) + componentToHex(rgb.b)
}

/**
 * Thanks, StackOverflow!
 *
 * https://stackoverflow.com/a/28056903/10791562
 */
export const hexToRGB = (hex: string, alpha?: string | number) => {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    }
}

export const blendWhite = (sourceHex: string, alpha: number) => {
    const sourceRGB = Color(sourceHex)
        .rgb()
        .object()
    const backgroundRGB = {r: 255, g: 255, b: 255}

    const resultRGB = {
        r: (1 - alpha) * backgroundRGB.r + alpha * sourceRGB.r,
        g: (1 - alpha) * backgroundRGB.g + alpha * sourceRGB.g,
        b: (1 - alpha) * backgroundRGB.b + alpha * sourceRGB.b,
    }

    return rgbToHex(resultRGB)
}

type PrimaryShade = 6 | 10 | 25 | 35 | 50 | 100

const primary: Record<PrimaryShade, ColorType> = {
    6: blendWhite('#3C8EFF', 0.06),
    10: blendWhite('#3C8EFF', 0.1),
    25: blendWhite('#3C8EFF', 0.25),
    35: blendWhite('#3C8EFF', 0.35),
    50: blendWhite('#3C8EFF', 0.5),
    100: '#3C8EFF',
}

type GreenShade = 35 | 100

const green: Record<GreenShade, ColorType> = {
    35: blendWhite('#6DD3A9', 0.35),
    100: '#6DD3A9',
}

export const colors = {
    primary,
    green,
    white: '#FFFFFF',
    transparent: 'transparent',
    darkBlue: '#2D71CF',
    darkGreen: '#5CBC94',
    caribbeanGreen: '#00CA88',
    lightGray: '#F4F6F7',
    darkGray: '#D5DBDF',
    darkGray2: '#BDC3C7',
    red: '#FE636D',
    black: '#101010',
    warning: `#ffdf80`,
}

export const elevation = css`
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
`
