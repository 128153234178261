import {gql, useApolloClient} from '@apollo/client'
import {ParseCSV, ParseCSVVariables} from './__types__/ParseCSV'

const PARSE_CSV = gql`
    query ParseCSV($file: Upload!) {
        parseCsvOrExcel(file: $file)
    }
`

export const useParseCsv = () => {
    const client = useApolloClient()

    return async function(file: File) {
        const parseFileResponse = await client.query<ParseCSV, ParseCSVVariables>({
            query: PARSE_CSV,
            variables: {file},
            fetchPolicy: 'network-only',
        })

        return parseFileResponse.data.parseCsvOrExcel as Record<string, string>[]
    }
}
