import {gql, useQuery} from '@apollo/client'
import {Spacer, Typography} from 'nf-ui'
import React from 'react'
import styled from 'styled-components'
import {PageLoading} from '~/components/PageLoading'
import {ProfilesGridSizer} from '~/components/ProfilesGridLayout/ProfilesGridSizer'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {getGraphQLErrorMessage} from '~/util'
import {HomeProfilesGrid} from './Home/HomeProfilesGrid'
import {SmartGroups} from './Home/SmartGroups'
import {HomeData, HomeDataVariables} from './__types__/HomeData'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'

const Container = styled.div`
    padding: 0 32px 32px;
`

const Header = styled.div`
    align-items: center;
    justify-content: space-between;
    padding: 32px 0 24px 0;
    position: relative;
`

export const HOME_DATA = gql`
    query HomeData($idStr: String!) {
        profiles(organisationIdStr: $idStr, limit: 50, includeOpenPositions: false) {
            idStr
            firstName
            lastName
            fullName
            photos {
                idStr
                thumbUrl
                priority
            }
        }
        organisation(idStr: $idStr) {
            idStr
            name
            logo {
                idStr
                file {
                    idStr
                    url
                }
            }
            smartGroups(limit: 15) {
                idStr
                label
                weeklyCount
                profiles {
                    profileIdStr
                    secondaryLine
                    profile {
                        idStr
                        firstName
                        lastName
                        fullName
                        photos {
                            idStr
                            thumbUrl
                            priority
                        }
                    }
                }
            }
            categoryChangesThisMonth {
                idStr
                label
                weeklyCount
                profiles {
                    profileIdStr
                    secondaryLine
                    profile {
                        idStr
                        firstName
                        lastName
                        fullName
                        photos {
                            idStr
                            thumbUrl
                            priority
                        }
                    }
                }
            }
        }
    }
`

const Logo = styled.div`
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -36px;
    width: 160px;
    height: 72px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`

export const Home: React.FC = () => {
    const organisationId = useOrganisationIdStr()
    const {data: homeData, error} = useQuery<HomeData, HomeDataVariables>(HOME_DATA, {
        variables: {
            idStr: organisationId,
        },
        fetchPolicy: 'cache-first',
    })
    const currentOrganisation = useCurrentOrganisation()

    if (error) return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>

    if (!homeData) return <PageLoading />
    const _data = {
        organisation: currentOrganisation.currentOrganisation,
        profiles: homeData.profiles,
    }
    return (
        <Container>
            <Header>
                <Typography.Heading maxLines={1}>Welcome to {homeData.organisation.name}</Typography.Heading>
                {homeData.organisation.logo && (
                    <Logo style={{backgroundImage: `url('${homeData.organisation.logo.file.url}')`}} />
                )}
            </Header>

            <ProfilesGridSizer disableHeight>
                {({width}) => (
                    // Margin and padding added for box shadow overflow of the profiles.
                    // Will be removed once a different solution is in place for the home page.
                    <div style={{margin: -3}}>
                        <HomeProfilesGrid width={width} padding={3} data={_data} rowLimit />
                    </div>
                )}
            </ProfilesGridSizer>
            <Spacer height={32} />
            <SmartGroups
                smartGroups={homeData.organisation.smartGroups}
                organisationId={homeData.organisation.idStr}
                data={_data}
            />
        </Container>
    )
}
