import {cloneDeep} from '@apollo/client/utilities'

export type HasPriority = {
    priority: number
}

/**
 * Items with priority aren't guaranteed to follow after each other without gaps e.g. [{priority:1}, {priority:7}]
 * This function moves the items in the array and bumps their priorities
 *
 * @export
 * @template T
 * @param {T[]} input Array that has a priority
 * @param {number} fromIndex index of the item to move
 * @param {number} toIndex final index for the item
 * @returns array with the new item, will mutate the references of the priority objects
 */
export function reorderPriorityItems<T extends HasPriority>(input: T[], fromIndex: number, toIndex: number) {
    const clonedInput = cloneDeep(input)
    const result = [...clonedInput]
    if (fromIndex === toIndex) return result
    //TODO: Broken check, remove when api is fixed
    if (clonedInput.length > 1 && clonedInput.every((elem) => elem.priority === clonedInput[0].priority)) {
        clonedInput.forEach((elem, i) => {
            elem.priority = i + 1
        })
    }

    const forward = fromIndex < toIndex
    const startIndex = forward ? fromIndex : toIndex
    const endIndex = forward ? toIndex : fromIndex

    let subset = clonedInput.slice(startIndex, endIndex + 1)
    if (!forward) {
        subset = subset.reverse()
    }

    const [first, ...rest] = subset
    const firstPriority = first.priority

    rest.reduceRight((p, c) => {
        p.priority = c.priority
        return c
    }, first)

    rest[0].priority = firstPriority

    let resultSubset = [...rest, first]
    if (!forward) {
        resultSubset = resultSubset.reverse()
    }

    result.splice(startIndex, endIndex - startIndex + 1, ...resultSubset)
    return result
}
