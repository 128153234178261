import {Button} from 'nf-ui'
import React, {useEffect, useRef, useState} from 'react'
import {playground} from '~/pages/Playground'
import {Column, Row} from '../Primitives/Layout'

import {Form} from './Form'
import {EMAIL_REGEX, ErrorMessage, ErrorRows, ErrorState, hideError} from '../Primitives'
import {TextInputElement, VisibleInput} from '../Primitives/InputFields/VisibleInput'

export const SignUp = ({
    email,
    setEmail,
    directoryName,
    errorState,
    setErrorState,
    onNext,
}: {
    email: string
    setEmail: (value: string) => void
    directoryName: string
    errorState: ErrorState
    setErrorState: (value: ErrorState) => void
    onNext: () => Promise<void>
}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const inputRefs = [useRef<TextInputElement>(null), useRef<TextInputElement>(null)]

    useEffect(() => {
        if (inputRefs[0].current?.offsetHeight) {
            setTimeout(() => inputRefs[0].current?.focus(), 10)
        } else if (inputRefs[1].current?.offsetHeight) {
            setTimeout(() => inputRefs[1].current?.focus(), 10)
        }

        return () => {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onValidatedNext = async () => {
        if (email === '' || !EMAIL_REGEX.test(email)) {
            setErrorState({
                ...errorState,
                email: {visible: true, message: 'Please enter a valid email address'},
            })
            return
        }
        setLoading(true)
        await onNext()
        setLoading(false)
    }
    return (
        <Form
            directoryName={directoryName}
            heading="Sign up"
            subHeading={`Add your details to the ${directoryName} Names & Faces directory.`}
            progress={0}
            onNext={onValidatedNext}
        >
            <Row width="100%" deviceWidths={['Desktop', 'Tablet']} alignLeft>
                <Column>
                    <VisibleInput
                        ref={inputRefs[0]}
                        type="email"
                        width="329px"
                        placeholder="Email"
                        value={email}
                        hasError={errorState.email?.visible}
                        scrollMarginBottom={70}
                        onChange={(ev) => {
                            hideError('email', errorState, setErrorState)
                            setEmail(ev.target.value)
                        }}
                        onKeyPress={(ev) => ev.key === 'Enter' && onValidatedNext()}
                    ></VisibleInput>
                    <ErrorRows errorRecord={errorState.email}></ErrorRows>
                </Column>
                <Column width="10px" />
                <Button
                    style={{padding: '0px 24px', borderRadius: '5px', height: '48px'}}
                    onClick={onValidatedNext}
                    loading={loading}
                >
                    Next
                </Button>
            </Row>
            <Column width="100%" deviceWidths={['Phone', 'Small']}>
                <VisibleInput
                    ref={inputRefs[1]}
                    type="email"
                    maxWidth="422px"
                    placeholder="Email"
                    value={email}
                    hasError={errorState.email?.visible}
                    scrollMarginBottom={70}
                    onChange={(ev) => {
                        if (errorState.email?.visible) {
                            setErrorState({
                                ...errorState,
                                email: {...errorState.email, visible: false},
                            })
                        }
                        setEmail(ev.target.value)
                    }}
                    onKeyPress={(ev) => ev.key === 'Enter' && onValidatedNext()}
                ></VisibleInput>
                {errorState.email?.visible && errorState.email?.message && (
                    <>
                        <Row height="10px" />
                        <Row>
                            <Column width="14px" grow={0} shrink={0}></Column>
                            <Column grow={0} shrink={1}>
                                <ErrorMessage>{errorState.email?.message || ''}</ErrorMessage>
                            </Column>
                            <Column width="14px" grow={1} shrink={0}></Column>
                        </Row>
                    </>
                )}{' '}
                <Row height="10px"></Row>
                <Button
                    style={{borderRadius: '5px', height: '48px', maxWidth: '450px', width: '100%'}}
                    onClick={onValidatedNext}
                    loading={loading}
                >
                    Next
                </Button>
                <Row height="20px"></Row>
            </Column>
        </Form>
    )
}

playground.push({
    path: 'src/components/CommunityBuild/SignUp.tsx',
    component: SignUp,
    props: {email: '', directoryName: 'Wetpups Grade 3', errorState: {}},
    propOptions: {
        email: {
            get: (props: any) => props.email || '',
        },
        setEmail: ({props, args}: {props: any; args: any[]}) => ({...props, email: args[0]}),
        directoryName: {
            get: (props: any) => props.directoryName || '',
            set: (props: any, value: string) => ({...props, directoryName: value}),
        },
        errorState: {
            get: (props: any) => JSON.stringify(props.errorState),
        },
        setErrorState: ({props, args}: {props: any; args: any[]}) => ({...props, errorState: args[0]}),
        onNext: () => {},
    },
})
