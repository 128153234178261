import {AnimatePresence, motion} from 'framer-motion'
import {Spacer} from 'nf-ui'
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {FixedSizeGrid, GridChildComponentProps} from 'react-window'
import styled from 'styled-components'
import {PhotoSelectionFooter} from '~/components/AdminPhotosList/PhotoSelectionFooter'
import {PhotoProps, PHOTO_HEIGHT, PHOTO_WIDTH} from '~/components/Photo/Photo'
import {
    DeletePhotoMutationUpdater,
    DeletePhotosMutationUpdater,
    useApprovePhotos,
    useDeletePhotos,
    useUnlinkPhotos,
} from '~/components/PhotoManagementCommon'
import {usePhotoFilter} from '~/components/usePhotoFilter'
import {PhotoCroppingState} from '~/pages/Adminland/PhotoCroppingModal'
import {PanelNavigatorProvider} from '../PanelNavigator'
import {usePhotoSort} from '../usePhotoSort'
import {AdminPhoto} from './AdminPhoto'
import {Header} from './Header'

export const PHOTO_GAP = 8

export type Photo = {
    idStr: string
    createdAt: number
} & PhotoProps['photo']

export type Profile = {
    idStr: string
    fullName: string
    photos: {thumbUrl: string; priority?: number | null}[] | null
}

interface AdminPhotoListProps {
    photos: Photo[]
    profiles: Profile[]
    deletePhotoUpdate: DeletePhotoMutationUpdater
    deletePhotosUpdate?: DeletePhotosMutationUpdater
    startCropAndRotatePhotos: (state: PhotoCroppingState) => void
    search: string
    selectable?: boolean
}

interface SelectionModeContextProps {
    selectionMode: boolean | null
    isPhotoSelected: (idStr: string) => boolean
    togglePhotoSelected: (idStr: string) => void
    allSelected: boolean
    setAllSelected: (value: boolean) => void
    isSinglePhotoSelected: (idStr: string) => boolean
    setSingleSelectedPhoto: Dispatch<SetStateAction<string | null>>
}
export const SelectionModeContext = React.createContext<SelectionModeContextProps>({
    selectionMode: null,
    isPhotoSelected: () => false,
    togglePhotoSelected: () => {},
    allSelected: false,
    setAllSelected: () => {},
    setSingleSelectedPhoto: () => {},
    isSinglePhotoSelected: () => false,
})

type ItemData = Pick<
    AdminPhotoListProps,
    'photos' | 'profiles' | 'deletePhotoUpdate' | 'search' | 'startCropAndRotatePhotos'
> & {
    columnCount: number
}

type CellProps = Omit<GridChildComponentProps, 'data'> & {
    data: ItemData
}

const PhotoWrapper = styled.div`
    position: relative;
`

const Cell: React.FC<CellProps> = React.memo(
    ({
        columnIndex,
        rowIndex,
        style,
        data: {photos, profiles, columnCount, deletePhotoUpdate, search, startCropAndRotatePhotos},
    }) => {
        const dataIndex = rowIndex * columnCount + columnIndex
        const photo = photos[dataIndex]

        if (!photo) return null

        return (
            <PhotoWrapper>
                <AdminPhoto
                    key={photo.idStr}
                    photo={photo}
                    profiles={profiles}
                    deletePhotoUpdate={deletePhotoUpdate}
                    startCropAndRotatePhotos={startCropAndRotatePhotos}
                    search={search}
                    style={{...style}}
                />
            </PhotoWrapper>
        )
    },
)

const GridContainer = styled.div`
    flex: 1;
`

const gridStyle = {margin: -4, padding: 4}

const AdminPhotosList: React.FC<AdminPhotoListProps> = ({
    photos,
    profiles,
    deletePhotoUpdate,
    deletePhotosUpdate,
    startCropAndRotatePhotos,
    search,
    selectable = false,
}) => {
    const photoFilter = usePhotoFilter(photos)
    const photoSort = usePhotoSort(photoFilter.photos)

    const [selectedPhotos, setSelectedPhotos] = useState<Array<string>>([])
    const [allPhotosSelected, setAllPhotosSelected] = useState(false)
    const [selectedSinglePhoto, setSingleSelectedPhoto] = useState<string | null>(null)

    const selectionCount = selectedPhotos.length
    const setAllSelected = (allSelected: boolean) => {
        const allPhotoIds = photoSort.photos.map((photo) => photo.idStr)
        allSelected ? setSelectedPhotos(allPhotoIds) : setSelectedPhotos([])
    }

    const selectionMode = !selectable ? null : selectionCount > 0
    const cancelSelection = () => {
        setSelectedPhotos([])
    }

    const isSinglePhotoSelected = (idStr: string) => selectedSinglePhoto === idStr
    const isPhotoSelected = (idStr: string) => selectedPhotos.includes(idStr)
    const togglePhotoSelected = (idStr: string) => {
        if (isPhotoSelected(idStr)) {
            setSelectedPhotos(selectedPhotos.filter((photo) => photo !== idStr))
        } else {
            setSelectedPhotos([...selectedPhotos, idStr])
        }
    }

    useEffect(() => {
        if (selectedPhotos.length < photoSort.photos.length) return setAllPhotosSelected(false)
        if (selectedPhotos.length === photoSort.photos.length) return setAllPhotosSelected(true)
    }, [selectedPhotos, photoSort.photos])

    const selectedMatchedPhotos = photoSort.photos.filter(
        (photo) => photo.profile && selectedPhotos.includes(photo.idStr),
    )

    const selectedPendingPhotos = photoSort.photos.filter(
        (photo) => photo.pendingProfile && selectedPhotos.includes(photo.idStr),
    )
    const selectedPendingPhotosIdStrs = selectedPendingPhotos.map((photo) => photo.idStr)

    const [deletePhotos, {loading: deleteLoading}] = useDeletePhotos({
        photoIdStrs: selectedPhotos,
        update: deletePhotosUpdate,
    })
    const [unlinkPhotos, {loading: unlinkLoading}] = useUnlinkPhotos({photoIdStrs: selectedPhotos})

    const [approvePhotos, {loading: approveLoading}] = useApprovePhotos({photoIdStrs: selectedPendingPhotosIdStrs})

    const ROW_HEIGHT = PHOTO_HEIGHT + PHOTO_GAP

    return (
        <PanelNavigatorProvider>
            <SelectionModeContext.Provider
                value={{
                    selectionMode,
                    isPhotoSelected,
                    togglePhotoSelected,
                    allSelected: allPhotosSelected,
                    setAllSelected,
                    isSinglePhotoSelected,
                    setSingleSelectedPhoto,
                }}
            >
                <Header count={photoFilter.photos.length} photoFilter={photoFilter} photoSort={photoSort} />
                <Spacer height={8} />
                <GridContainer>
                    <AutoSizer>
                        {({width, height}) => {
                            const columnCount = Math.floor(width / PHOTO_WIDTH)
                            const rowCount = Math.ceil(photoSort.photos.length / columnCount)
                            const itemData = {
                                photos: photoSort.photos,
                                profiles,
                                columnCount,
                                deletePhotoUpdate,
                                startCropAndRotatePhotos,
                                search,
                            }
                            return (
                                <FixedSizeGrid
                                    style={gridStyle} // Allows the photo highlighting to overflow
                                    columnCount={columnCount}
                                    columnWidth={PHOTO_WIDTH + PHOTO_GAP}
                                    rowCount={rowCount}
                                    rowHeight={ROW_HEIGHT}
                                    width={width + PHOTO_GAP * 2.5}
                                    height={height}
                                    itemData={itemData}
                                    overscanRowCount={10}
                                >
                                    {Cell}
                                </FixedSizeGrid>
                            )
                        }}
                    </AutoSizer>
                </GridContainer>

                <AnimatePresence>
                    {selectionMode && (
                        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                            <PhotoSelectionFooter
                                photosCount={selectionCount}
                                matchedPhotosCount={selectedMatchedPhotos.length}
                                pendingPhotosCount={selectedPendingPhotosIdStrs.length}
                                deletePhotos={deletePhotos}
                                deleteLoading={deleteLoading}
                                unmatchPhotos={unlinkPhotos}
                                unmatchLoading={unlinkLoading}
                                approvePhotos={approvePhotos}
                                approveLoading={approveLoading}
                                cancelSelection={cancelSelection}
                                startCropAndRotatePhotos={() => {
                                    startCropAndRotatePhotos({
                                        currentIndex: 0,
                                        selection: photoSort.photos
                                            .filter((photo) => selectedPhotos.includes(photo.idStr))
                                            .map((photo) => ({
                                                photoIdStr: photo.idStr,
                                                originalUrl: photo.originalUrl,
                                            })),
                                    })
                                    cancelSelection()
                                }}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            </SelectionModeContext.Provider>
        </PanelNavigatorProvider>
    )
}

export {AdminPhotosList}
