import {Icon} from 'nf-ui'
import SvgCopy from 'nf-ui/Icons/Copy'
import React from 'react'
import styled from 'styled-components'
import {validImageMimeTypes} from '~/util/validImages'
import {Column, Row} from './Layout'

const SelectPhotoFileInput = styled.input.attrs({
    type: 'file',
})`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
`

export const SelectPhotoContainer = styled.span`
    position: relative;
    font-family: 'inter';
    font-size: 16px;
    font-weight: 600;
    color: #3c8eff;
    background-color: rgba(60, 142, 255, 0.1);
    border: 1px dashed #3c8eff;
    padding: 14px 36px;
    border-radius: 5px;
    cursor: pointer;
`

export const SelectPhoto = ({children, onSelectPhoto}: {children: string; onSelectPhoto: (file: File) => void}) => (
    <SelectPhotoContainer>
        <SelectPhotoFileInput
            accept={validImageMimeTypes}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => {
                try {
                    if (!event.target.files) return
                    onSelectPhoto(Array.from(event.target.files)[0])
                } catch (error) {
                    // alert.show(error.message)
                }
                event.target.value = ''
            }}
        />
        <Row>
            {children}
            <Column width="10px" />
            <Column>
                <Row height="3px"></Row>
                <Icon icon={SvgCopy} tint="#3c8eff" />
            </Column>
        </Row>
    </SelectPhotoContainer>
)
