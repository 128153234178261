import {Icon, Spacer, WhiteButton} from 'nf-ui'
import SvgChevronRight from 'nf-ui/Icons/ChevronRight'
import React from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {useOpenAnalytics} from '~/components/useOpenAnalytics'

const ButtonContent = styled.div`
    display: flex;
    align-items: center;
`

const SignInButton = styled(WhiteButton)`
    width: 340px;
    padding-top: 12px;
    padding-bottom: 12px;
`

const AdpLogo = styled.img`
    width: 30px;
    height: auto;
    display: block;
`

export const ADPSignInButton: React.FC = () => {
    const {push} = useHistory()
    const {trackEvent} = useOpenAnalytics()
    const logo = require('./Login/ADP.png')

    const toAdpLogin = () => {
        trackEvent('onboarding', 'click_welcome_login')
        push('/login/adp')
    }

    return (
        <SignInButton height="auto" onClick={toAdpLogin} hoverFill>
            <ButtonContent>
                <AdpLogo src={logo} />
                <Spacer width={16} />
                Sign in with ADP Workforce Now®
                <Spacer width={16} />
                <Spacer width={4} />
                <Icon icon={SvgChevronRight} size={24} />
            </ButtonContent>
        </SignInButton>
    )
}
