import {gql, useLazyQuery} from '@apollo/client'
import {useCallback, useEffect} from 'react'
import {useCookies} from 'react-cookie'
import {CloudfrontCookies} from './__types__/CloudfrontCookies'

export const CLOUDFRONT_COOKIES = gql`
    query CloudfrontCookies {
        cloudfrontCookies {
            name
            value
            expires
            domain
        }
    }
`

const COOKIE_OPTIONS = {
    // Use non-secure cookies in development mode
    // to allow for localhost development
    secure: process.env.NODE_ENV === 'development' ? false : true,
    path: '/sb',
}

export const useSetCloudfrontCookies = () => {
    const [fetchCookies, {data}] = useLazyQuery<CloudfrontCookies>(CLOUDFRONT_COOKIES)

    const [cookies, setCookie] = useCookies(['CloudFront-Expires-At-Sb'])
    const expiresAt = Number(cookies['CloudFront-Expires-At-Sb']) as number | null

    // Fetch cookies if none set, or if current are expired or are about to expire
    useEffect(() => {
        const oneHourFromNow = Date.now() + 60 * 60 * 1000

        if (!expiresAt || expiresAt * 1000 < oneHourFromNow) {
            fetchCookies()
        }
    }, [expiresAt, fetchCookies])

    // Set cookies returned from the API
    useEffect(() => {
        if (!data) return

        for (const cookie of data.cloudfrontCookies) {
            if (cookie.name === 'CloudFront-Expires-At') {
                setCookie('CloudFront-Expires-At-Sb', cookie.value, {
                    ...COOKIE_OPTIONS,
                    path: '/',
                    domain: `.${cookie.domain}`,
                    expires: new Date(cookie.expires),
                })
            } else {
                setCookie(cookie.name, cookie.value, {
                    ...COOKIE_OPTIONS,
                    domain: `.${cookie.domain}`,
                    expires: new Date(cookie.expires),
                })
            }
        }
    }, [data, setCookie])
}

export const COOKIES_TO_CLEAR = [
    'CloudFront-Policy',
    'CloudFront-Signature',
    'CloudFront-Key-Pair-Id',
    'CloudFront-Expires-At',
    'CloudFront-Expires-At-Sb',
]

/**
 * Clear cloudfront cookies.
 *
 * Clears cookies recursively for each domain level,
 * up to the second level domain (e.g. example.com).
 */
export const useClearCloudfrontCookies = () => {
    const [, , removeCookie] = useCookies()

    return useCallback(() => {
        let domain = window.location.hostname

        // Stop clearing when we've reached the TLD
        while (domain.indexOf('.') > -1) {
            for (const cookie of COOKIES_TO_CLEAR) {
                if (cookie === 'CloudFront-Expires-At-Sb') {
                    removeCookie(cookie, {...COOKIE_OPTIONS, path: '/', domain: `.${domain}`})
                } else {
                    removeCookie(cookie, {...COOKIE_OPTIONS, domain: `.${domain}`})
                }
            }

            // Return the subdomain
            domain = domain.substring(domain.indexOf('.') + 1)
        }
    }, [removeCookie])
}
