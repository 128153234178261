import {useAnalyticsEventTracker} from 'nf-ui'
import {useEffect, useRef} from 'react'
import {VariableSizeList} from 'react-window'
import ScrollBooster from '~/scrollbooster'

export const useScrollBooster = ({
    variableSizeListRef,
    analyticsEvent,
    initialScrollOffset = 0,
}: {
    variableSizeListRef: React.RefObject<VariableSizeList>
    analyticsEvent?: {event: string; properties: Record<any, any>}
    initialScrollOffset?: number
}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const scrollBooster = useRef<ScrollBooster>()
    const didFirstDrag = useRef(false)
    const initialised = useRef(false)

    useEffect(() => {
        // @ts-ignore
        const container = variableSizeListRef.current?._outerRef
        if (!container || initialised.current) return

        scrollBooster.current = new ScrollBooster({
            viewport: container,
            content: container.children[0],
            scrollMode: 'native',
            direction: 'horizontal',
            friction: 0.01,
            bounceForce: 0,
            bounce: false,
            initialOffset: {x: -initialScrollOffset},
            onUpdate: (e: any) => {
                if (e.isDragging && !didFirstDrag.current) {
                    didFirstDrag.current = true
                    analyticsEvent && trackAnalyticsEvent(analyticsEvent.event, analyticsEvent.properties)
                }
            },
        })
        initialised.current = true

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variableSizeListRef, analyticsEvent])

    return {
        scrollBooster,
    }
}
