import {gql, useMutation, useQuery} from '@apollo/client'
import {useEffect, useRef} from 'react'
import {useAlert} from 'react-alert'
import {getUserDataQuery} from '~/pages/Adminland/Users'
import {
    DataSourceCardImportDataSource,
    DataSourceCardImportDataSourceVariables,
} from './__types__/DataSourceCardImportDataSource'
import {DataSourceSyncStatus, DataSourceSyncStatusVariables} from './__types__/DataSourceSyncStatus'

const IMPORT_DATA_SOURCE = gql`
    mutation DataSourceCardImportDataSource($dataSourceIdStr: String!, $importOptions: ImportOptionsInput!) {
        importDataSource(dataSourceIdStr: $dataSourceIdStr, importOptions: $importOptions)
    }
`

const FETCH_DATASOURCE_SYNC_STATUS = gql`
    query DataSourceSyncStatus($dataSourceIdStr: String!) {
        dataSource(dataSourceIdStr: $dataSourceIdStr) {
            idStr
            syncing
        }
    }
`

export const useImportDataSource = (organisationIdStr: string) => {
    const alert = useAlert()

    return useMutation<DataSourceCardImportDataSource, DataSourceCardImportDataSourceVariables>(IMPORT_DATA_SOURCE, {
        refetchQueries: [getUserDataQuery(organisationIdStr)],
        onCompleted: () => alert.success('Data import started. Your new data should be available in 5 - 10 mins.'),
    })
}

export const usePollDataSyncStatus = (dataSourceIdStr: string) => {
    const hasFetchedData = useRef(false)

    const {data, error, stopPolling, startPolling} = useQuery<DataSourceSyncStatus, DataSourceSyncStatusVariables>(
        FETCH_DATASOURCE_SYNC_STATUS,
        {
            pollInterval: 0,
            variables: {dataSourceIdStr},
        },
    )

    const dataSourceSyncing = data?.dataSource?.syncing ?? false

    useEffect(() => {
        if (error || dataSourceSyncing === false) {
            stopPolling()
        }
    }, [error, dataSourceSyncing, stopPolling])

    useEffect(() => {
        if (!data || hasFetchedData.current === true) return

        hasFetchedData.current = true

        if (data?.dataSource?.syncing) {
            startPolling(4000)
        }
    }, [data, startPolling])

    return {
        dataSourceSyncing: dataSourceSyncing,
        startPollingDataSyncStatus: startPolling,
        stopPollingDataSyncStatus: stopPolling,
        error,
    }
}
