import * as React from 'react'
const SvgEmail = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgEmail(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M13.811 2H2.19A2.193 2.193 0 00.006 4.033L0 4.189v8.295c0 1.155.9 2.103 2.033 2.184l.156.005H13.81a2.193 2.193 0 002.183-2.032l.006-.157V4.19c0-1.155-.9-2.103-2.033-2.183L13.811 2zm-4.47 7.62l.97-.746 4.626 3.856-.092.206a1.131 1.131 0 01-.9.67l-.134.008H2.19c-.407 0-.773-.218-.973-.56l-.061-.118-.092-.206L5.69 8.874l.971.747a2.176 2.176 0 002.53.108l.151-.109zM1.06 11.354V5.273l3.776 2.935-3.776 3.146zm13.882-6.081v6.08l-3.776-3.145 3.776-2.935zm-1.13-2.214c.398 0 .761.212.962.544l.06.114.1.216-6.24 4.85a1.114 1.114 0 01-1.276.075l-.106-.072-6.243-4.853.098-.216c.163-.356.503-.604.891-.65l.132-.008H13.81z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgEmail
