import {CurrentOrganisation} from './CurrentOrganisationContext'

const useOrganisationIdStr = () => {
    const {currentOrganisation} = CurrentOrganisation.useContainer()

    if (!currentOrganisation) {
        // TODO: Better error message
        throw new Error(`You can only use the 'useOrganisationIdStr' hook when the organisation is available.`)
    }

    return currentOrganisation.idStr
}

export {useOrganisationIdStr}
