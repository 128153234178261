import React, {useEffect, useRef} from 'react'
import {monthNumberOfDays, toStringAndPad} from '~/util/date'
import {Column, Row, scrollIfNeeded} from '../Layout'
import {Heading3} from '..'

const initDenseArray = (size: number) => {
    const array = new Array(size)
    for (let index = 0; index < array.length; index++) {
        array[index] = index
    }
    return array
}

export const Calendar = ({
    yearValue,
    monthValue,
    dayValue,
    setYearValue,
    setMonthValue,
    setDayValue,
    inputPosition,
}: {
    yearValue: string
    monthValue: string
    dayValue: string
    setYearValue: (value: string) => void
    setMonthValue: (value: string) => void
    setDayValue: (value: string) => void
    inputPosition: 'year' | 'month' | 'day' | 'complete'
}) => {
    const selectedYearRef = useRef<HTMLDivElement>(null)
    const calendarRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (inputPosition === 'year') {
            scrollIfNeeded(selectedYearRef.current, {
                scrollContainer: calendarRef.current,
                finishTimeoutMilliseconds: 0,
            })
        }
    }, [inputPosition, yearValue, monthValue, dayValue])

    if (inputPosition === 'year') {
        return (
            <Column ref={calendarRef} height="170px" width="230px" overflow="auto">
                <Row height="10px"></Row>
                {initDenseArray(20).map((row) => (
                    <Row key={row} height="30px">
                        <Column width="10px"></Column>
                        {initDenseArray(5).map((col) => {
                            const yearCell = new Date().getFullYear() - col - 5 * row
                            const yearCellPadded = toStringAndPad(yearCell, 4)
                            const isSelected = yearCellPadded === yearValue
                            return (
                                <Column
                                    key={col}
                                    width="40px"
                                    height="30px"
                                    centerVertical
                                    borderRadius="3px"
                                    backgroundColor={isSelected ? 'rgb(60, 142, 255)' : 'white'}
                                    onClick={() => setYearValue(yearCellPadded)}
                                >
                                    <Heading3
                                        ref={isSelected ? selectedYearRef : null}
                                        fontSize="14px"
                                        centerHorizontal
                                        cursor="pointer"
                                        color={isSelected ? 'white' : undefined}
                                    >
                                        {yearCellPadded}
                                    </Heading3>
                                </Column>
                            )
                        })}
                        <Column width="10px"></Column>
                    </Row>
                ))}
                <Row height="10px"></Row>
            </Column>
        )
    }
    if (inputPosition === 'month') {
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
        return (
            <Column ref={calendarRef} height="170px" width="230px">
                <Row height="10px"></Row>
                {initDenseArray(3).map((row) => (
                    <Row key={row} height="30px">
                        <Column width="10px"></Column>
                        {initDenseArray(4).map((col) => {
                            const monthCell = 1 + col + 4 * row
                            const monthCellPadded = toStringAndPad(monthCell, 2)
                            const monthCellString = months[col + 4 * row]
                            const isSelected = monthCellPadded === monthValue
                            return (
                                <Column
                                    key={col}
                                    width="40px"
                                    centerVertical
                                    borderRadius="3px"
                                    backgroundColor={isSelected ? 'rgb(60, 142, 255)' : 'white'}
                                    onClick={() => setMonthValue(monthCellPadded)}
                                >
                                    <Heading3
                                        fontSize="14px"
                                        centerHorizontal
                                        cursor="pointer"
                                        color={isSelected ? 'white' : undefined}
                                    >
                                        {monthCellString}
                                    </Heading3>
                                </Column>
                            )
                        })}
                        <Column width="10px"></Column>
                    </Row>
                ))}
                <Row height="10px"></Row>
            </Column>
        )
    }
    const numberOfDays =
        monthValue === 'mm' || yearValue === 'yyyy' ? 31 : monthNumberOfDays(parseInt(monthValue), parseInt(yearValue))
    return (
        <Column ref={calendarRef} height="170px" width="230px">
            <Row height="10px"></Row>
            {initDenseArray(5).map((row) => (
                <Row key={row} height="30px" centerHorizontal>
                    <Column width="10px"></Column>
                    {initDenseArray(7).map((col) => {
                        const dayCell = (1 + col + 7 * row).toString()
                        const dayCellPadded = toStringAndPad(dayCell, 2)
                        const isSelected = dayCellPadded === dayValue
                        const isValid = dayCell <= numberOfDays
                        return (
                            <Column
                                key={col}
                                width="30px"
                                centerVertical
                                borderRadius="3px"
                                backgroundColor={isSelected ? 'rgb(60, 142, 255)' : 'white'}
                                onClick={() => {
                                    if (!isValid) return
                                    setDayValue(dayCellPadded)
                                }}
                            >
                                {isValid ? (
                                    <Heading3
                                        fontSize="14px"
                                        centerHorizontal
                                        cursor="pointer"
                                        color={isSelected ? 'white' : undefined}
                                    >
                                        {dayCell}
                                    </Heading3>
                                ) : (
                                    ''
                                )}
                            </Column>
                        )
                    })}
                    <Column width="10px"></Column>
                </Row>
            ))}
            <Row height="10px"></Row>
        </Column>
    )
}
