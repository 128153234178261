import {Button, Spacer, Typography} from 'nf-ui'
import React from 'react'
import {WizardLayout} from './WizardLayout'

export const WizardLayoutError: React.FC<{error: string; onClickRetry?: () => void}> = ({error, onClickRetry}) => {
    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <Typography.Heading>Something went wrong</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading>{error}</Typography.Subheading>
                <Spacer height={32} />
                {onClickRetry && (
                    <Button variant="primary" onClick={onClickRetry}>
                        Try again
                    </Button>
                )}
            </WizardLayout.Body>
        </WizardLayout>
    )
}
