import {Spacer, Table, Typography} from 'nf-ui'
import React, {FC} from 'react'
import {StepProps} from '../../useConfigureDataSource'
import {HeadingPanel} from '../HeadingPanel'
import {useFullScreenTable} from '../useFullScreenTable'

export const ColumnHeadings: FC<StepProps> = ({state, dispatch, onClickCancel}) => {
    const {ref, height, width} = useFullScreenTable()

    return (
        <>
            <Typography.Heading>Each column in your data needs headings</Typography.Heading>
            <Spacer height={16} />
            <Typography.Subheading maxWidth={940}>
                E.g. First name, Last name, Department, Role. The order of your columns doesn’t matter.
            </Typography.Subheading>
            <Spacer height={32} />
            <Table
                ref={ref}
                data={state.dataSourceData.data}
                hoverHighlight={['header']}
                disableRows
                maxWidth={width}
                maxHeight={height}
                headingPanel={<HeadingPanel dispatch={dispatch} onClickCancel={onClickCancel!} />}
                activeCell={{columnIndex: 0, rowIndex: 0}}
            />
        </>
    )
}
