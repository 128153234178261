import {Form} from 'nf-ui'
import React from 'react'
import {Column, Row} from './ListComponents'
import {ProfileSelectState} from './useProfileSelect'

const Header: React.FC<{
    multiselect: ProfileSelectState
}> = ({multiselect}) => {
    const {toggleAll, checkedAll} = multiselect
    return (
        <Row>
            <Column>
                <Form.Group name="name">
                    <Form.Checkbox checked={checkedAll} onChange={toggleAll} bold label="Name" />
                </Form.Group>
            </Column>
        </Row>
    )
}

export {Header}
