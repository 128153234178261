import React, {Suspense} from 'react'
import {Button, Icon, IconProps, IconType, ListItemWrapper, Spinner, Typography, colors} from 'nf-ui'
import {Column, Row} from '~/components/Primitives/Layout'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'
import {Input} from 'nf-ui/Form/Input'
import SvgCheck from 'nf-ui/Icons/Check'
import {PANEL_WIDTH} from '~/constants'
import {Switch} from 'nf-ui/Form/Switch'
import {Draggable, DraggingStyle} from 'react-beautiful-dnd'
import {DraggableList} from '~/pages/Adminland/DraggableList'
import {playground} from '~/pages/Playground'
import {Handle} from '~/pages/Adminland/DraggableItem'

const PATH_SEPARATOR = '|'

export interface MenuState {
    currentMenuPath: string
    menuEntries: MenuEntry[]
}

export type MenuEntry = {
    id: string
    values?: string[]
    loading?: boolean
    disabled?: boolean
    topBorder?: boolean
    label?: string
    actions?: {
        id?: string
        icon?: IconType
        iconSize?: IconProps['size']
    }[]
    behaviours: (
        | 'enter'
        | 'heading'
        | 'single-line-edit'
        | 'switch'
        | 'tertiary-button'
        | 'draggable'
        | 'pick-one'
        | 'pick-many'
        | 'sticky'
    )[]
}

export type MenuRowEventHandlers = {
    onBack?: () => Promise<void>
    onEnter?: (menuEntryId: string) => Promise<void>
    onValuesChanged?: (menuEntryId: string, values: string[]) => Promise<void>
    onTextChanged?: (menuEntryId: string, value: string) => Promise<void>
    onEditCompleted?: (menuEntryId: string) => Promise<void>
    onButtonClicked?: (menuEntryId: string) => Promise<void>
    onOrderChanged?: (menuEntryIds: string[], menuEntryId: string) => Promise<void>
    onAction?: (menuEntryId: string, actionId?: string) => Promise<void>
}

const MenuRow = ({
    menuEntry,
    parentMenuEntry,
    isFirstRow,
    handlers,
    skipVerticalPadding,
    stackHorizontal,
}: {
    menuEntry: MenuEntry
    parentMenuEntry?: MenuEntry
    isFirstRow?: boolean
    handlers: MenuRowEventHandlers
    skipVerticalPadding?: boolean
    stackHorizontal?: boolean
}) => {
    const leaf = menuEntry.id.split(PATH_SEPARATOR).slice(-1)[0]
    const selected = parentMenuEntry?.values?.includes(leaf)
    return (
        <div style={menuEntry.topBorder ? {borderTop: `1px solid ${colors.darkGray}`} : {}}>
            {!!parentMenuEntry?.label && isFirstRow && (
                <ListItemWrapper>
                    <Row padding={skipVerticalPadding ? '0px' : `8px 0px`}>
                        <Row padding="8px 16px 8px 8px" onClick={() => handlers.onBack?.()} style={{cursor: 'pointer'}}>
                            <Column centerVertical>
                                <Icon icon={SvgArrowLeft} tint={colors.primary[100]} />
                            </Column>
                        </Row>
                        {menuEntry.behaviours.includes('heading') && (
                            <Row padding="8px 0px">
                                <Typography.Label>{menuEntry.label}</Typography.Label>
                            </Row>
                        )}
                        <Column grow={1}></Column>
                    </Row>
                </ListItemWrapper>
            )}
            {menuEntry.behaviours.includes('single-line-edit') ? (
                <Column>
                    <Row padding="0px 16px">
                        <Input
                            autoFocus={true}
                            value={menuEntry.values?.[0] || ''}
                            disabled={menuEntry.disabled}
                            onChange={(value) => handlers.onTextChanged?.(menuEntry.id, value)}
                            onKeyUp={(key) => key.code === 'Enter' && handlers.onEditCompleted?.(menuEntry.id)}
                        />
                    </Row>
                </Column>
            ) : menuEntry.behaviours.includes('tertiary-button') ? (
                <ListItemWrapper>
                    <Row
                        padding={skipVerticalPadding ? '0px 8px' : `8px`}
                        width={stackHorizontal ? undefined : `${PANEL_WIDTH}px`}
                    >
                        <Button
                            variant="tertiary"
                            disabled={menuEntry.disabled}
                            loading={menuEntry.loading}
                            onClick={() => handlers.onButtonClicked?.(menuEntry.id)}
                        >
                            {menuEntry.label}
                        </Button>
                        {stackHorizontal ? null : <Column grow={1}></Column>}
                    </Row>
                </ListItemWrapper>
            ) : menuEntry.behaviours.includes('switch') ? (
                <ListItemWrapper clickable>
                    <Row padding={skipVerticalPadding ? '0px 8px' : `8px`} width={`${PANEL_WIDTH}px`}>
                        <Row
                            padding={skipVerticalPadding ? '0px 8px' : `8px`}
                            width="100%"
                            onClick={() => {
                                if (!parentMenuEntry?.values || !handlers.onValuesChanged) return
                                handlers.onValuesChanged(
                                    menuEntry.id,
                                    selected
                                        ? parentMenuEntry.values.filter((v) => v !== leaf)
                                        : [...parentMenuEntry.values, leaf],
                                )
                            }}
                        >
                            <Typography.Paragraph bottomMargin={false}>{menuEntry.label}</Typography.Paragraph>
                            <Column width="16px"></Column>
                            <Column centerVertical>{menuEntry.loading ? <Spinner></Spinner> : undefined}</Column>
                            <Column grow={1}></Column>
                            <Column centerVertical>
                                <Switch
                                    checked={selected}
                                    disabled={menuEntry.disabled || menuEntry.loading}
                                    onChange={() => {}}
                                    onClick={() => {
                                        if (!parentMenuEntry?.values || !handlers.onValuesChanged) {
                                            return
                                        }
                                        handlers.onValuesChanged(
                                            menuEntry.id,
                                            selected
                                                ? parentMenuEntry.values.filter((v) => v !== leaf)
                                                : [...parentMenuEntry.values, leaf],
                                        )
                                    }}
                                />
                            </Column>
                        </Row>
                    </Row>
                </ListItemWrapper>
            ) : !menuEntry.behaviours.includes('heading') ? (
                <ListItemWrapper clickable>
                    <Row padding={skipVerticalPadding ? '0px 8px' : `6px 8px`} width={`${PANEL_WIDTH}px`}>
                        <Row
                            padding={skipVerticalPadding ? '0px 8px' : `8px`}
                            width="100%"
                            onClick={() => {
                                const pickOne = menuEntry.behaviours.includes('pick-one')
                                const pickMany = menuEntry.behaviours.includes('pick-many')
                                const enter = menuEntry.behaviours.includes('enter')
                                if (pickOne && !selected) handlers.onValuesChanged?.(menuEntry.id, [leaf])
                                else if (pickMany && !selected)
                                    handlers.onValuesChanged?.(menuEntry.id, [...(parentMenuEntry?.values || []), leaf])
                                else if (pickMany)
                                    handlers.onValuesChanged?.(
                                        menuEntry.id,

                                        (parentMenuEntry?.values || []).filter((v) => v !== leaf),
                                    )
                                else if (enter) handlers.onEnter?.(menuEntry.id)
                                else if (pickOne) handlers.onValuesChanged?.(menuEntry.id, [])
                            }}
                        >
                            {menuEntry.behaviours.includes('draggable') ? (
                                <>
                                    <Column centerVertical>
                                        <Suspense fallback={null}>
                                            <Handle></Handle>
                                        </Suspense>
                                    </Column>
                                    <Column width="8px"></Column>
                                </>
                            ) : (
                                undefined
                            )}
                            <Typography.Paragraph bottomMargin={false}>{menuEntry.label}</Typography.Paragraph>
                            <Column width="16px"></Column>
                            <Column centerVertical>
                                {menuEntry.loading ? (
                                    <Spinner></Spinner>
                                ) : selected ? (
                                    <SvgCheck className="check"></SvgCheck>
                                ) : (
                                    undefined
                                )}
                            </Column>
                            <Column grow={1}></Column>
                        </Row>
                        {(menuEntry.actions || []).map(
                            (action, index) =>
                                action.icon &&
                                !menuEntry.loading &&
                                (selected || !menuEntry.behaviours.includes('pick-one')) && (
                                    <Row
                                        key={index}
                                        padding={skipVerticalPadding ? '0px 8px' : `8px`}
                                        onClick={() => handlers.onAction?.(menuEntry.id, action.id)}
                                    >
                                        <Column centerVertical>
                                            <Icon size={action.iconSize || 16} icon={action.icon} />
                                        </Column>
                                    </Row>
                                ),
                        )}
                    </Row>
                </ListItemWrapper>
            ) : !parentMenuEntry || !isFirstRow ? (
                <ListItemWrapper clickable>
                    <Row padding={skipVerticalPadding ? '0px 8px' : `8px`} width={`${PANEL_WIDTH}px`}>
                        <Typography.Label>{menuEntry.label}</Typography.Label>
                        <Column grow={1}></Column>
                    </Row>
                </ListItemWrapper>
            ) : (
                undefined
            )}
        </div>
    )
}

export const Menu = ({
    currentParentMenuEntryId,
    menuEntries,
    handlers,
    position,
}: {
    currentParentMenuEntryId: string
    menuEntries: MenuEntry[]
    handlers: MenuRowEventHandlers
    position?: {left: number; top: number}
}) => {
    const parentMenuEntry = menuEntries.find((entry) => entry.id === currentParentMenuEntryId)
    const getParentMenuEntryId = (m: {id: string}) =>
        m.id
            .split(PATH_SEPARATOR)
            .slice(0, -1)
            .join(PATH_SEPARATOR)

    const filtered = menuEntries.filter((m) => getParentMenuEntryId(m) === currentParentMenuEntryId)
    const stickyTopSliceEnd = filtered[0]?.behaviours?.includes('sticky')
        ? filtered.findIndex((entry) => !entry.behaviours.includes('sticky'))
        : 0
    const stickyBottomSliceStart = filtered.slice(-1)[0]?.behaviours?.includes('sticky')
        ? stickyTopSliceEnd +
          filtered.slice(stickyTopSliceEnd).findIndex((entry) => entry.behaviours.includes('sticky'))
        : filtered.length

    const topOffset = 56 * stickyTopSliceEnd
    const bottomOffset = stickyBottomSliceStart === 0 ? 0 : 56
    const nonStickyEntries = filtered.slice(stickyTopSliceEnd, stickyBottomSliceStart)

    return filtered.length ? (
        <div>
            {stickyTopSliceEnd > 0 ? (
                <div style={{background: 'white'}}>
                    {filtered.slice(0, stickyTopSliceEnd).map((menuEntry, index) => (
                        <MenuRow
                            key={index}
                            menuEntry={menuEntry}
                            parentMenuEntry={parentMenuEntry}
                            isFirstRow={index === 0}
                            handlers={handlers}
                        ></MenuRow>
                    ))}
                </div>
            ) : null}
            <div
                style={{overflowY: 'auto', overflowX: 'clip', maxHeight: `calc(38vh - ${topOffset + bottomOffset}px)`}}
            >
                <DraggableList
                    onDragEnd={(result) => {
                        if (!result.destination || !handlers.onOrderChanged || result.reason !== 'DROP') {
                            return
                        }
                        const source = result.source.index
                        const destination = result.destination.index
                        if (source === destination) {
                            return
                        }

                        const newOrder =
                            source > destination
                                ? [
                                      ...nonStickyEntries.slice(0, destination),
                                      ...nonStickyEntries.slice(source, source + 1),
                                      ...nonStickyEntries.slice(destination, source),
                                      ...nonStickyEntries.slice(source + 1),
                                  ]
                                : [
                                      ...nonStickyEntries.slice(0, source),
                                      ...nonStickyEntries.slice(source + 1, destination + 1),
                                      ...nonStickyEntries.slice(source, source + 1),
                                      ...nonStickyEntries.slice(destination + 1),
                                  ]

                        handlers.onOrderChanged(
                            newOrder.filter((entry) => entry.behaviours.includes('draggable')).map((entry) => entry.id),
                            nonStickyEntries.slice(source, source + 1)[0].id,
                        )
                    }}
                    droppableId="Menu"
                >
                    {nonStickyEntries.map((menuEntry, index) => (
                        <Draggable
                            key={menuEntry.id}
                            index={index}
                            draggableId={menuEntry.id}
                            isDragDisabled={!menuEntry.behaviours.includes('draggable')}
                        >
                            {({draggableProps, dragHandleProps, innerRef}, {isDragging}) => {
                                const combinedStyle = {
                                    ...draggableProps.style,
                                    ...(isDragging ? {backgroundColor: colors.lightGray} : {}),
                                    ...(isDragging
                                        ? {
                                              left: `${(draggableProps.style as DraggingStyle).left -
                                                  (position?.left || 0)}px`,
                                              top: `${(draggableProps.style as DraggingStyle).top -
                                                  (position?.top || 0)}px`,
                                          }
                                        : {}),
                                }
                                // isDragging && console.log('combinedStyle', combinedStyle, 'position', position, 'draggableProps.style', draggableProps.style)
                                return (
                                    <div
                                        key={menuEntry.id}
                                        ref={innerRef}
                                        {...draggableProps}
                                        {...dragHandleProps}
                                        style={combinedStyle}
                                    >
                                        <MenuRow
                                            menuEntry={menuEntry}
                                            parentMenuEntry={parentMenuEntry}
                                            isFirstRow={index === 0 && stickyTopSliceEnd === 0}
                                            handlers={handlers}
                                        ></MenuRow>
                                    </div>
                                )
                            }}
                        </Draggable>
                    ))}
                </DraggableList>
            </div>
            {stickyBottomSliceStart < filtered.length ? (
                <Row style={{padding: '8px 0px', background: 'white'}}>
                    {filtered.slice(stickyBottomSliceStart).map((menuEntry, index) => (
                        <MenuRow
                            key={index}
                            menuEntry={menuEntry}
                            parentMenuEntry={parentMenuEntry}
                            isFirstRow={false}
                            handlers={handlers}
                            skipVerticalPadding={true}
                            stackHorizontal={true}
                        ></MenuRow>
                    ))}
                </Row>
            ) : null}
        </div>
    ) : null
}

playground.push({
    path: 'ui/Menu.tsx',
    component: Menu,
    props: {
        currentParentMenuEntryId: '',
        position: {top: 0, left: 0},
        handlers: {
            onBack: () => {},
            onEnter: (menuEntryId: string) => {},
            onValuesChanged: (menuEntryId: string, values: string[]) => {},
            onTextChanged: (menuEntryId: string, value: string) => {},
            onEditCompleted: (menuEntryId: string) => {},
            onButtonClicked: (menuEntryId: string) => {},
            onOrderChanged: (menuEntryIds: string[], menuEntryId: string) => {},
            onAction: (menuEntryId: string, actionId?: string) => {},
        },
        menuEntries: [
            {
                id: 'heading',
                label: 'Menu heading',
                behaviours: ['heading', 'sticky'],
            },
            {
                id: 'line1',
                label: 'Line1',
                behaviours: ['draggable'],
            },
            {
                id: 'line2',
                label: 'Line2',
                behaviours: ['draggable'],
            },
            {
                id: 'line3',
                label: 'Line3',
                behaviours: ['draggable'],
            },
            {
                id: 'line4',
                label: 'Line4',
                behaviours: ['draggable'],
            },
            {
                id: 'line5',
                label: 'Line5',
                behaviours: ['draggable'],
            },
            {
                id: 'Button',
                label: 'OK',
                behaviours: ['tertiary-button', 'sticky'],
            },
            {
                id: 'Button',
                label: 'Cancel',
                behaviours: ['tertiary-button', 'sticky'],
            },
        ],
    },
    propOptions: {
        currentParentMenuEntryId: {
            get: (props: any) => JSON.stringify(props.currentParentMenuEntryId),
        },
        setCurrentParentMenuEntryId: ({props, args}: {props: any; args: any[]}) => {
            return {...props, currentParentMenuEntryId: args[0]}
        },
    },
})
