import React from 'react'
import styled from 'styled-components'
import {ProfilePicture} from '~/components/ProfilePicture/ProfilePicture'

export const Container = styled.div`
    position: relative;
    width: 251px;
    height: 273px;
    overflow: hidden;
    border-radius: 3px;
`

export const Photo = ({photo, fullName}: {photo: {profileUrl: string} | null; fullName: string}) => {
    return (
        <Container>
            <ProfilePicture url={photo?.profileUrl} fullName={fullName} />
        </Container>
    )
}
