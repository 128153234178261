import React, {useCallback, RefObject, useRef, useContext} from 'react'
import styled, {css} from 'styled-components'
import {FixedSizeList, ListChildComponentProps} from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import {colors} from './theme'
import {paragraphFontStyle} from './Typography'

const ROW_HEIGHT = 56
export {ROW_HEIGHT as LIST_ROW_HEIGHT}

export interface ListProps<TItem> extends Partial<ListChildComponentProps> {
    onClick?: (item: TItem) => void
    rows: ReadonlyArray<TItem>
    renderRow(data: TItem, wrapperRef: RefObject<HTMLDivElement>): React.ReactNode
    //TODO : Should be optional
    width: number
    heading?: JSX.Element
    stickyHeading?: JSX.Element
    image?: boolean
    variant?: 'borderless' | 'dropdown'
    onMouseOver?: (item: TItem) => void
    onMouseOut?: (item: TItem) => void
    maxHeight?: number
    rowHeight?: number
    firstChildBorder?: boolean
    padding?: boolean
    itemMinWidth?: number
    minHeight?: number
}

const VirtualizedListContainer = styled(FixedSizeList)`
    outline: 0;
    overflow-x: visible !important;
    overflow-y: auto !important;
    will-change: initial !important;
`

export const ListItemWrapper = styled.div<{borders?: boolean; clickable?: boolean; firstChildBorder?: boolean}>`
    border-top: 1px solid ${colors.darkGray};
    border-width: 1px 0;
    border-radius: 0px;

    ${({firstChildBorder}) =>
        !firstChildBorder &&
        css`
            &:first-child {
                border-top: none;
            }
        `}

    ${({clickable}) =>
        clickable &&
        css`
            &:hover {
                background-color: ${colors.lightGray};
            }
            cursor: pointer;
        `}
    ${({borders}) =>
        borders &&
        css`
            border-radius: 3px;
            border-top: none;
            border-bottom: none;
            & + div {
                border-top: none;
            }
        `}


`

export const ListItem = styled.div<{hasImage?: boolean; padding?: boolean}>`
    ${paragraphFontStyle}
    width: auto;
    min-height: 24px;
    color: ${colors.black};
    letter-spacing: 0px;
    ${({hasImage, padding}) =>
        !hasImage &&
        padding !== false &&
        css`
            padding: 16px;
        `}
`

type ItemData<TItem> = Omit<ListProps<TItem>, 'width'>

type CellProps<TItem> = Omit<ListChildComponentProps, 'data'> & {
    data: ItemData<TItem>
}

const ListCellItem = <TItem extends {}>({
    data: {
        rows,
        renderRow,
        onClick,
        onMouseOver,
        onMouseOut,
        heading,
        stickyHeading,
        image,
        variant,
        firstChildBorder,
        padding,
        itemMinWidth,
    },
    index,
    style,
}: CellProps<TItem>) => {
    const hasHeading = Boolean(heading || stickyHeading)
    const row = hasHeading ? rows[index - 1] : rows[index]
    const borders = variant === 'borderless'

    const onRowMouseOver = useCallback(() => (onMouseOver ? () => onMouseOver(row) : undefined), [row, onMouseOver])
    const onRowMouseOut = useCallback(() => (onMouseOut ? () => onMouseOut(row) : undefined), [row, onMouseOut])
    const wrapperRef = useRef<HTMLDivElement>(null)

    if (stickyHeading && index === 0) return null
    if (heading && index === 0) {
        const borders = variant === 'dropdown'
        return (
            <ListItemWrapper style={style} borders={borders} clickable={false} data-testid="list-heading">
                <ListItem padding={padding}>{heading}</ListItem>
            </ListItemWrapper>
        )
    }

    const itemStyle = {...style}
    if (itemMinWidth) itemStyle.minWidth = itemMinWidth

    return (
        <ListItemWrapper
            style={itemStyle}
            onClick={() => {
                if (onClick) onClick(row)
            }}
            onMouseOver={onRowMouseOver}
            onMouseOut={onRowMouseOut}
            borders={borders}
            clickable={true}
            data-testid="list-data"
            ref={wrapperRef}
            firstChildBorder={firstChildBorder}
        >
            <ListItem padding={padding} hasImage={image}>
                {renderRow(row, wrapperRef)}{' '}
            </ListItem>
        </ListItemWrapper>
    )
}

const EMPTY_OBJ = {}

const InnerElementContext = React.createContext<{minWidth: number; stickyHeading?: JSX.Element}>({minWidth: 0})

const StickyHeading = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.darkGray};
    box-sizing: border-box;
`

const innerElementType = React.forwardRef<HTMLDivElement>(({children, ...rest}, ref) => {
    const {minWidth, stickyHeading} = useContext(InnerElementContext)

    return (
        <div ref={ref} {...rest}>
            {children}
            {stickyHeading && <StickyHeading style={{minWidth}}>{stickyHeading}</StickyHeading>}
        </div>
    )
})

export function List<TItem>({
    width,
    rows,
    renderRow,
    onClick,
    onMouseOver,
    onMouseOut,
    heading,
    image,
    variant,
    maxHeight: propMaxHeight,
    rowHeight: propRowHeight,
    firstChildBorder,
    padding,
    itemMinWidth,
    stickyHeading,
    minHeight,
    ...rest
}: ListProps<TItem>) {
    const hasHeading = Boolean(heading || stickyHeading)
    const rowCount = hasHeading ? rows.length + 1 : rows.length
    const rowHeight = propRowHeight ? propRowHeight : ROW_HEIGHT

    const contentHeight = rowCount * rowHeight

    const data: ItemData<TItem> = {
        rows,
        renderRow,
        onClick,
        onMouseOver,
        onMouseOut,
        heading,
        image,
        variant,
        firstChildBorder,
        padding,
        itemMinWidth,
        stickyHeading,
    }

    let height = propMaxHeight ? Math.min(contentHeight, propMaxHeight) : contentHeight
    if (minHeight) height = Math.max(minHeight, height)

    // If we don't get a maxHeight, let's render a non-virtualised list, since this
    // list won't have any scrolling anyway
    if (!propMaxHeight) {
        return (
            <div style={{width, height: propMaxHeight}}>
                {Array(rowCount)
                    .fill(null)
                    .map((_, index) => {
                        return <ListCellItem key={index} data={data} index={index} style={EMPTY_OBJ} />
                    })}
            </div>
        )
    }

    return (
        <InnerElementContext.Provider value={{minWidth: itemMinWidth || width, stickyHeading}}>
            <div style={{width, height}}>
                <AutoSizer>
                    {({height, width}) => {
                        return (
                            <VirtualizedListContainer
                                height={height}
                                itemCount={rowCount}
                                itemSize={rowHeight}
                                width={width}
                                itemData={data}
                                {...rest}
                                data-testid="virtulized-list"
                                innerElementType={innerElementType}
                            >
                                {ListCellItem}
                            </VirtualizedListContainer>
                        )
                    }}
                </AutoSizer>
            </div>
        </InnerElementContext.Provider>
    )
}
