import dateFormat from 'dateformat'
import {Formik} from 'formik'
import {
    Button,
    Form,
    FormikCheckboxList,
    FormikDropdownSelect,
    FormikInput,
    Lightbox,
    Spacer,
    Typography,
    useAnalyticsEventTracker,
    useTheme,
} from 'nf-ui'
import SvgCaret from 'nf-ui/Icons/Caret'
import SvgClose from 'nf-ui/Icons/Close'
import React, {useRef, useState} from 'react'
import {renderToString} from 'react-dom/server'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import * as yup from 'yup'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {PrintType, usePrintElement} from '../usePrintElement'
import {FullOrgChartData} from '../__types__/FullOrgChartData'
import {CustomizeDetails} from './Actions/CustomizeDetails'
import {orderPhotos} from '~/components/OrganisationContext'

const validationSchema = yup.object({
    title: yup.string(),
    orientation: yup.string(),
    format: yup.string().required(),
    options: yup.array().of(yup.string()),
})

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

const PrintHeaderSmall = styled.span`
    font-weight: 200;
    font-size: 17.4px;
    line-height: 23.2px;
    font-family: 'halyard-display';
`

const PrintHeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const PrintHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
`

const PrintCompanyTitle = styled.span`
    font-weight: 300;
    font-size: 29px;
    line-height: 40px;
    font-family: 'publico-banner';
`

const LightboxHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const PrintHeader = ({data, title, logo}: {data: FullOrgChartData | undefined; title: string; logo: boolean}) => {
    return (
        <PrintHeaderContainer>
            <PrintHeaderRow>
                <PrintHeaderSmall>{data?.organisation.name}</PrintHeaderSmall>
                <Spacer width={8} />
                <PrintHeaderSmall>{dateFormat(new Date(), 'mmmm yyyy')}</PrintHeaderSmall>
            </PrintHeaderRow>
            <Spacer height={8} />
            <Spacer height={4} />
            <Spacer height={4} />
            <PrintHeaderRow style={{justifyContent: 'space-between'}}>
                <PrintCompanyTitle>{title}</PrintCompanyTitle>
                {logo && (
                    <img
                        crossOrigin="anonymous"
                        height="56px"
                        src={data?.organisation.logo?.file.signedUrl}
                        alt="company-logo"
                    />
                )}
            </PrintHeaderRow>
            <Spacer height={16} />
        </PrintHeaderContainer>
    )
}

const getInitialChartTitle = (organisationName?: string) => `${organisationName} organizational chart`

const Export = ({data}: {data: FullOrgChartData | undefined}) => {
    const theme = useTheme()
    const [topLevelProfile] = useQueryParam<string>('topLevelProfile')
    const {printElement} = usePrintElement()
    const location = window.location.origin
    const downloadRef = useRef<HTMLButtonElement>(null)
    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const [lightboxOpen, setLightboxOpen] = useState(false)
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const signedUrlMap: Record<string, string> = {}

    data?.organisation.profiles.forEach((profile) => {
        const orderedPhotos = orderPhotos(profile.photos)
        if (!orderedPhotos[0]?.signedTinyUrl && !orderedPhotos[0]?.signedProfileUrl) return
        signedUrlMap[profile.idStr] = orderedPhotos[0].signedTinyUrl || orderedPhotos[0].signedProfileUrl
    })

    const print = (type: PrintType, title: string, logo: boolean, photos: boolean) => {
        const header = renderToString(<PrintHeader data={data} title={title} logo={logo} />)

        const preProcessElement = (element: HTMLElement) => {
            const wrapper = element.querySelector('#full-org-chart')
            wrapper && wrapper.setAttribute('style', 'padding-top: 0')

            if (!photos) {
                data?.organisation.profiles.forEach(({idStr}) => {
                    const image = element.querySelector(`#profile-image-${idStr}`)
                    image && image.setAttribute('style', 'display: none;')
                })
                return
            }

            const imageElements = element.querySelectorAll('img')

            imageElements.forEach((image) => {
                const profileIdStr = image.getAttribute('data-profile-id')
                const signedUrl = signedUrlMap?.[profileIdStr || ''] || null

                if (signedUrl) {
                    image.setAttribute('crossOrigin', 'anonymous')
                    image.setAttribute('src', signedUrl)
                }
            })
        }

        const downloadName =
            getInitialChartTitle(currentOrganisation?.name) === title
                ? title
                : `Org Chart - ${currentOrganisation?.name} - ${title}`

        printElement({
            elementId: 'full-org-chart',
            downloadName,
            type,
            header,
            preProcessElement,
        })
    }

    const DOWNLOAD_FORMATS = [
        {
            type: 'pdf',
            label: 'PDF (Print)',
            onSubmit: ({
                orgChartTitle,
                logo,
                photos,
                orientation,
            }: {
                orgChartTitle: string
                logo: boolean
                photos: boolean
                orientation: string
            }) => {
                const topLevelProfileParam = topLevelProfile ? `&topLevelProfile=${topLevelProfile}` : ''
                window.open(
                    `${location}/${currentOrganisation?.idStr}/print-org-chart?title=${encodeURIComponent(
                        orgChartTitle,
                    )}&photos=${photos ? 1 : 0}&logo=${logo ? 1 : 0}&orientation=${orientation}${topLevelProfileParam}`,
                    '_blank',
                )
            },
        },
        {
            type: 'png',
            label: 'PNG (Slide show presentation)',
            onSubmit: ({
                type,
                orgChartTitle,
                logo,
                photos,
            }: {
                type: PrintType
                orgChartTitle: string
                logo: boolean
                photos: boolean
            }) => {
                print(type, orgChartTitle, logo, photos)
            },
        },
        {
            type: 'jpeg',
            label: 'JPEG (Slide show presentation)',
            onSubmit: ({
                type,
                orgChartTitle,
                logo,
                photos,
            }: {
                type: PrintType
                orgChartTitle: string
                logo: boolean
                photos: boolean
            }) => {
                print(type, orgChartTitle, logo, photos)
            },
        },
    ]

    const downloadOptions = DOWNLOAD_FORMATS.map((option) => ({
        label: option.label,
        value: option.type,
    }))
    const orientationOptions = [
        {label: 'Portrait', value: 'portrait'},
        {label: 'Landscape', value: 'landscape'},
    ]

    const submitForm = async ({title, format, options, orientation}: yup.InferType<typeof validationSchema>) => {
        const selectedFormat = DOWNLOAD_FORMATS.find((downloadFormat) => {
            return downloadFormat.type === format
        })

        const logo = options.includes('logo')
        const photos = options.includes('photos')
        const orgChartTitle = title || getInitialChartTitle(currentOrganisation?.name)

        if (selectedFormat) {
            selectedFormat.onSubmit?.({
                type: selectedFormat.type as PrintType,
                orgChartTitle,
                logo,
                photos,
                orientation,
            })
            trackAnalyticsEvent('orgCharts', {
                page: 'full-view',
                component: 'button',
                type: 'click',
                action: 'download',
                name: 'export_org_chart',
                format: selectedFormat.type,
                photos: photos,
                logo: logo,
            })
        }
        setLightboxOpen(false)
    }

    return (
        <>
            <Button
                variant="secondary"
                organisationTheme={theme}
                ref={downloadRef}
                onClickAnalyticsEvent="orgCharts"
                onClickAnalyticsData={{
                    page: 'full-view',
                    component: 'button',
                    type: 'click',
                    action: 'open',
                    name: 'export_org_chart',
                }}
                onClick={() => {
                    setLightboxOpen(true)
                }}
            >
                Export org chart
                <Button.Icon icon={SvgCaret} />
            </Button>

            <Lightbox open={lightboxOpen} width={720} key={'OrgCharActionLightBox'}>
                <LightboxHeader>
                    <Typography.Heading>Download org chart</Typography.Heading>
                    <Button
                        variant="tertiary"
                        onClick={() => {
                            setLightboxOpen(false)
                        }}
                        onClickAnalyticsEvent="orgCharts"
                        onClickAnalyticsData={{
                            page: 'full-view',
                            component: 'button',
                            type: 'click',
                            action: 'close',
                            name: 'export_org_chart',
                        }}
                    >
                        Close <Button.Icon icon={SvgClose} />
                    </Button>
                </LightboxHeader>
                <Spacer height={16} />
                <Formik
                    initialValues={{
                        title: '',
                        orientation: 'landscape',
                        format: downloadOptions[0]?.value,
                        options: ['logo', 'photos'],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={submitForm}
                >
                    {({handleSubmit, isSubmitting, isValid, values}) => (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group name="title">
                                <Form.Label>Org chart name</Form.Label>
                                <FormikInput
                                    type="text"
                                    hideError
                                    autoFocus
                                    placeholder={getInitialChartTitle(currentOrganisation?.name)}
                                />
                            </Form.Group>

                            <Form.Group name="format">
                                <Form.Label>Format</Form.Label>
                                <FormikDropdownSelect options={downloadOptions} />
                            </Form.Group>

                            {values.format === 'pdf' && (
                                <Form.Group name="orientation">
                                    <Form.Label>Orientation</Form.Label>
                                    <FormikDropdownSelect options={orientationOptions} />
                                </Form.Group>
                            )}

                            <Form.Label>View options</Form.Label>

                            <Form.Group name="options">
                                <FormikCheckboxList
                                    options={[
                                        {label: 'Include logo', value: 'logo'},
                                        {label: 'Include photos', value: 'photos'},
                                    ]}
                                />
                            </Form.Group>

                            <Button type="submit" disabled={!isValid} loading={isSubmitting}>
                                Download
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Lightbox>
        </>
    )
}

export const OrgChartActions = ({data}: {data: FullOrgChartData | undefined}) => {
    return (
        <ActionsContainer>
            <CustomizeDetails data={data} />
            <Spacer width={8} />
            <Export data={data} />
        </ActionsContainer>
    )
}
