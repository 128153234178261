import * as React from 'react'
const SvgCrop = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgCrop(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M3 0c.249 0 .5.251.5.53v1.96h9.4l.099.007c.289.042.501.264.501.523v9.43h1.9l.099.007c.289.042.501.264.501.523 0 .289-.261.53-.6.53h-1.9v1.96l-.008.085c-.045.25-.288.446-.592.446-.249 0-.5-.252-.5-.531v-1.96H3l-.1-.007c-.288-.042-.5-.263-.5-.523V3.55H.5l-.098-.008C.152 3.502 0 3.304 0 3.02c0-.32.192-.53.5-.53h1.9V.53l.008-.085C2.453.195 2.695 0 3 0zm9.4 3.55H3.5v8.9h8.9v-8.9z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgCrop
