import {motion} from 'framer-motion'
import {colors, Icon, Typography} from 'nf-ui'
import SvgEdit from 'nf-ui/Icons/Edit'
import React from 'react'
import styled from 'styled-components'
import {TRANSITION} from './utils'

const Edit = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: -1px;

    ${Typography.Tiny} {
        margin-left: 3px;
        flex: 1;
        position: relative;
        top: -1px;
    }
`

export const EditButton: React.FC = () => (
    <Edit>
        <Icon icon={SvgEdit} tint={colors.primary[100]} />
        <motion.div
            initial={{x: -16, opacity: 0}}
            animate={{x: 0, opacity: 1}}
            exit={{x: -16, opacity: 0}}
            transition={TRANSITION}
        >
            <Typography.Tiny bold color="primary" maxLines={1}>
                Edit
            </Typography.Tiny>
        </motion.div>
    </Edit>
)
