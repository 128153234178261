import {colors, FadePresence} from 'nf-ui'
import React, {useCallback, useRef, useState} from 'react'
import styled from 'styled-components'
import {ConnectPrimaryDataSourceProvider} from '../Adminland/ConnectPrimaryDataSource/ConnectPrimaryDataSource'
import {AddDetails} from './AddDetails'
import {ConnectData} from './ConnectData'
import {CreateAccountForm} from './CreateAccountForm'
import {LoginOTP} from './Login/LoginOTP'

import NFLogo from './nf-logo.svg'

const ModalContent = styled.div`
    min-height: 500px;
    min-width: 420px;
    padding: 32px 48px 48px 48px;
`

const ModalInner = styled.div`
    border-radius: 8px;
    background-color: ${colors.white};
`
const PageContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;
`

const ModalHeaderContainer = styled.div`
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: 80px;
    background: ${colors.lightGray};
    justify-content: center;
    display: flex;
    align-items: center;
`

const CreateFormModal = styled.div`
    background-image: url('https://cdn.namesandfaces.com/assets/website/video/webapp/onboarding/backgroundimage_createaccount.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    max-width: 1800px;
    margin: 0 auto;
    position: absolute;
    justify-content: center;
    top: 0;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
`

const ModalHeader = () => {
    return (
        <ModalHeaderContainer>
            <NFLogo alt="Names and Faces Logo" />
        </ModalHeaderContainer>
    )
}

export enum CreateAccountStep {
    ACCOUNT = 0,
    OTP = 1,
    DETAILS = 2,
    DATA = 3,
}

const CreateAccountFlow = ({
    step,
    setStep,
    email,
    setEmail,
    organisationIdStr,
    setOrganisationIdStr,
}: {
    step: CreateAccountStep
    setStep: React.Dispatch<React.SetStateAction<CreateAccountStep>>
    email: string
    setEmail: React.Dispatch<React.SetStateAction<string>>
    organisationIdStr?: string
    setOrganisationIdStr: (idStr: string) => void
}) => {
    switch (step) {
        case CreateAccountStep.ACCOUNT:
            return (
                <CreateAccountForm
                    email={email}
                    setEmail={setEmail}
                    onComplete={() => setStep(CreateAccountStep.OTP)}
                />
            )
        case CreateAccountStep.OTP:
            return (
                <LoginOTP
                    email={email}
                    onClickBack={() => setStep(CreateAccountStep.ACCOUNT)}
                    onComplete={() => setStep(CreateAccountStep.DETAILS)}
                />
            )

        case CreateAccountStep.DETAILS:
            return <AddDetails setOrganisationIdStr={setOrganisationIdStr} />

        case CreateAccountStep.DATA:
            return <ConnectData organisationIdStr={organisationIdStr!} />

        default:
            return null
    }
}

export const CreateAccount = ({
    initialStep,
    organisationIdStr,
}: {
    initialStep?: CreateAccountStep
    organisationIdStr?: string
}) => {
    const createdOrganisationIdStr = useRef<string | undefined>(undefined)
    const [email, setEmail] = useState('')
    const [step, setStep] = useState<CreateAccountStep>(initialStep || CreateAccountStep.ACCOUNT)

    const setOrganisationIdStr = useCallback((idStr: string) => {
        if (createdOrganisationIdStr.current?.length) return
        createdOrganisationIdStr.current = idStr
        setStep(CreateAccountStep.DATA)
    }, [])

    return (
        <ConnectPrimaryDataSourceProvider>
            <PageContainer>
                <CreateFormModal>
                    <ModalInner>
                        <ModalHeader />
                        <ModalContent>
                            <FadePresence exitBeforeEnter itemKey={step}>
                                <CreateAccountFlow
                                    step={step}
                                    setStep={setStep}
                                    email={email}
                                    setEmail={setEmail}
                                    organisationIdStr={organisationIdStr || createdOrganisationIdStr.current}
                                    setOrganisationIdStr={setOrganisationIdStr}
                                />
                            </FadePresence>
                        </ModalContent>
                    </ModalInner>
                </CreateFormModal>
            </PageContainer>
        </ConnectPrimaryDataSourceProvider>
    )
}
