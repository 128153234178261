import {AnimatePresence, motion} from 'framer-motion'
import {colors, hexToRGB, highlightMatchedText, Icon, Spinner, Typography} from 'nf-ui'
import SvgCheckCircle from 'nf-ui/Icons/CheckCircle'
import SvgCheckSquare from 'nf-ui/Icons/CheckSquare'
import React from 'react'
import styled from 'styled-components'
import {SelectedOverlay} from '../Profile'
import {EditButton} from './EditButton'
import {ActionButton} from './ActionButton'
import {
    BottomContentWrapper,
    Hovering,
    SetHovering,
    TopContentWrapper,
    TranslateIn,
    useHovering,
    useSelectionMode,
} from './utils'

const SvgCheckCircleGreen = styled(SvgCheckCircle)`
    circle {
        fill: ${colors.green[100]};
    }
`

export const PHOTO_WIDTH = 120
export const PHOTO_HEIGHT = 180

export type PhotoType = {
    idStr: string
    thumbUrl: string
    originalUrl: string
    filename: string
    profile: {fullName: string} | null
    pendingProfile?: {fullName: string} | null
    priority?: number | null
}

export interface PhotoProps {
    photo: PhotoType
    showSpinner?: boolean
    onClick?: () => void
    onClickMatch?: () => void
    onClickApprove?: () => void
    showEditOnHover?: boolean
    search: string
    selected: boolean
}

export const Photo = React.forwardRef<HTMLButtonElement, PhotoProps>((props, ref) => {
    const {photo, onClick, selected} = props
    const {selectionMode, togglePhotoSelected} = useSelectionMode(photo.idStr)

    const onClickSelect = (event: React.MouseEvent) => {
        event.stopPropagation()
        togglePhotoSelected(photo.idStr)
    }

    const {hovering, setHovering} = useHovering(['container', 'matchButton'])

    return (
        <Container
            style={{backgroundImage: `url('${photo.thumbUrl}')`}}
            onClick={selectionMode ? onClickSelect : onClick}
            ref={ref}
            onHoverStart={() => setHovering('container', true)}
            onHoverEnd={() => setHovering('container', false)}
        >
            <TopContent {...props} hovering={hovering} />
            <SelectedOverlay style={{opacity: selected ? 1 : 0}} />
            <BottomContent {...props} hovering={hovering} setHovering={setHovering} />
        </Container>
    )
})

export const Container = styled(motion.button)`
    position: relative;
    border: 0;
    padding: 0;
    outline: 0;
    width: ${PHOTO_WIDTH}px;
    height: ${PHOTO_HEIGHT}px;
    border-radius: 3px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.2s box-shadow ease-in-out;
    text-align: left;
    cursor: pointer;
`

type ContentProps = {
    position: 'top' | 'bottom'
    gradient?: boolean
    height?: number
    matched?: boolean
}

const Content = styled(motion.div)<ContentProps>`
    position: absolute;
    ${(props) => props.position}: 0;
    left: 0;
    right: 0;
    z-index: 3;
    width: 120px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-image: none;
    height: ${(props) => props.height}px;
    justify-content: ${(props) => (props.position === 'top' ? 'flex-start' : 'flex-end')};

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(
            ${(props) => (props.position === 'top' ? 0 : 180)}deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6) 100%
        );
        opacity: ${(props) => (props.gradient ? 1 : 0)};
        transition: 0.3s opacity ease-in-out;
    }
`

const PhotoName: React.FC<{filename: string; search: PhotoProps['search']}> = ({filename, search}) => (
    <Typography.Tiny color="white" maxLines={3}>
        {highlightMatchedText(filename, search)}
    </Typography.Tiny>
)

const CheckboxEmptyIcon = styled.div`
    box-sizing: border-box;
    display: flex;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    border: 2px solid ${hexToRGB(colors.white, 0.5)};
`

const SelectButton: React.FC<{onClick: React.MouseEventHandler<HTMLSpanElement>}> = ({onClick}) => (
    <span onClick={onClick}>
        <CheckboxEmptyIcon />
    </span>
)

const TopContent: React.FC<PhotoProps & {hovering: Hovering<'container' | 'matchButton'>}> = ({
    showSpinner,
    photo,
    search,
    hovering,
}) => {
    const {selectionMode, togglePhotoSelected, selected, selectable} = useSelectionMode(photo.idStr)

    let topContent = null
    const gradient = !photo.profile

    if (showSpinner) {
        topContent = (
            <TopContentWrapper key="spinner">
                <Spinner />
            </TopContentWrapper>
        )
    } else if (selected) {
        topContent = (
            <TopContentWrapper rightAligned={true} key="selected">
                <Icon icon={SvgCheckSquare} />
            </TopContentWrapper>
        )
    } else if ((selectable && hovering.container && !hovering.matchButton) || selectionMode) {
        topContent = (
            <TopContentWrapper rightAligned={true} key="selectButton">
                <SelectButton
                    onClick={(event) => {
                        event.stopPropagation()
                        togglePhotoSelected(photo.idStr)
                    }}
                />
            </TopContentWrapper>
        )
    } else if (photo.profile) {
        topContent = (
            <TopContentWrapper key="greenCheck">
                <Icon icon={SvgCheckCircleGreen} />
            </TopContentWrapper>
        )
    } else if (photo.pendingProfile) {
        topContent = (
            <TopContentWrapper key="photoName">
                <Typography.Tiny color="white" bold maxLines={3}>
                    {highlightMatchedText(photo.pendingProfile.fullName, search)}
                </Typography.Tiny>
            </TopContentWrapper>
        )
    } else {
        topContent = (
            <TopContentWrapper key="photoName">
                <PhotoName filename={photo.filename} search={search} />
            </TopContentWrapper>
        )
    }

    return (
        <Content position="top" gradient={gradient} height={78}>
            <AnimatePresence initial={false}>{topContent}</AnimatePresence>
        </Content>
    )
}

const BottomContent: React.FC<PhotoProps & {
    setHovering: SetHovering<'matchButton'>
    hovering: Hovering<'container'>
}> = ({showSpinner, photo, setHovering, onClick, hovering, onClickMatch, onClickApprove, search}) => {
    const {selectionMode, selected} = useSelectionMode(photo.idStr)

    let bottomContent
    let gradient = !!photo.profile

    if (showSpinner) {
        gradient = false
        bottomContent = null
    } else if (selectionMode || selected) {
        bottomContent = (
            <BottomContentWrapper key="fileOrProfileName">
                <TranslateIn distance={10}>
                    <AnimatePresence initial={false}>
                        {photo.profile ? (
                            <BottomContentWrapper padding={false} key="name">
                                <Typography.Tiny color="green" bold maxLines={3}>
                                    {highlightMatchedText(photo.profile.fullName, search)}
                                </Typography.Tiny>
                            </BottomContentWrapper>
                        ) : photo.pendingProfile ? (
                            <BottomContentWrapper padding={false} key="name">
                                <Typography.Tiny color="white" bold maxLines={3}>
                                    {highlightMatchedText(photo.pendingProfile.fullName, search)}
                                </Typography.Tiny>
                            </BottomContentWrapper>
                        ) : (
                            <BottomContentWrapper padding={false} key="photoname">
                                <PhotoName filename={photo.filename} search={search} />
                            </BottomContentWrapper>
                        )}
                    </AnimatePresence>
                </TranslateIn>
            </BottomContentWrapper>
        )
    } else if (photo.profile) {
        bottomContent = (
            <BottomContentWrapper key="name">
                <TranslateIn distance={10}>
                    <AnimatePresence initial={false}>
                        {hovering.container ? (
                            <BottomContentWrapper padding={false} key="edit">
                                <EditButton />
                            </BottomContentWrapper>
                        ) : (
                            <BottomContentWrapper padding={false} key="name">
                                <Typography.Tiny color="green" bold maxLines={3}>
                                    {highlightMatchedText(photo.profile.fullName, search)}
                                </Typography.Tiny>
                            </BottomContentWrapper>
                        )}
                    </AnimatePresence>
                </TranslateIn>
            </BottomContentWrapper>
        )
    } else if (photo.pendingProfile) {
        bottomContent = (
            <BottomContentWrapper
                key="match"
                onHoverStart={() => setHovering('matchButton', true)}
                onHoverEnd={() => setHovering('matchButton', false)}
            >
                <TranslateIn distance={5}>
                    <ActionButton
                        key="approve"
                        buttonText="Approve"
                        onClick={(event) => {
                            if (onClickApprove) {
                                event.stopPropagation()
                                onClickApprove()
                            } else if (onClick) onClick()
                        }}
                    />
                </TranslateIn>
            </BottomContentWrapper>
        )
    } else {
        bottomContent = (
            <BottomContentWrapper
                key="match"
                onHoverStart={() => setHovering('matchButton', true)}
                onHoverEnd={() => setHovering('matchButton', false)}
            >
                <TranslateIn distance={5}>
                    <ActionButton
                        key="match"
                        buttonText="Match"
                        onClick={(event) => {
                            if (onClickMatch) {
                                event.stopPropagation()
                                onClickMatch()
                            } else if (onClick) onClick()
                        }}
                    />
                </TranslateIn>
            </BottomContentWrapper>
        )
    }

    return (
        <Content position="bottom" gradient={gradient} height={83}>
            <AnimatePresence initial={false}>{bottomContent}</AnimatePresence>
        </Content>
    )
}
