import {gql, useMutation} from '@apollo/client'
import {Button, Form, Lightbox, Spacer, Typography} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import React, {useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {SCENARIOS_PAGE_DATA} from '../Scenarios'
import {ScenariosPageData_scenarios} from '../__types__/ScenariosPageData'
import {UpdateScenarioName, UpdateScenarioNameVariables} from './__types__/UpdateScenarioName'

const UPDATE_SCENARIO_NAME = gql`
    mutation UpdateScenarioName($scenarioIdStr: String!, $name: String!) {
        updateScenarioName(name: $name, scenarioIdStr: $scenarioIdStr)
    }
`

const LightboxHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const UpdateScenarioLightbox: React.FC<{
    open: boolean
    onClose: () => void
    scenario: ScenariosPageData_scenarios
}> = ({open, onClose, scenario}) => {
    const [name, setName] = useState<string>(scenario.name)
    const alert = useAlert()
    const organisationIdStr = useOrganisationIdStr()
    const scenarioIdStr = scenario.idStr

    const [updateScenarioName, {loading}] = useMutation<UpdateScenarioName, UpdateScenarioNameVariables>(
        UPDATE_SCENARIO_NAME,
        {
            onCompleted: () => {
                alert.success('Scenario successfully renamed')
                onClose()
            },
            refetchQueries: [{query: SCENARIOS_PAGE_DATA, variables: {organisationIdStr}}],
            awaitRefetchQueries: true,
        },
    )

    return (
        <Lightbox open={open} onClose={onClose} width={508}>
            <LightboxHeader>
                <Typography.Heading>Edit scenario</Typography.Heading>
                <Button variant="tertiary" onClick={onClose}>
                    Close <Button.Icon icon={SvgClose} />
                </Button>
            </LightboxHeader>
            <Spacer height={16} />
            <Form.Group name="scenarioName">
                <Form.Label>Scenario name</Form.Label>
                <Form.Input width={300} value={name} onChange={setName} />
            </Form.Group>
            <Spacer height={24} />
            <Button
                disabled={!name}
                loading={loading}
                onClick={() => {
                    if (!name) return
                    updateScenarioName({variables: {scenarioIdStr, name}})
                }}
            >
                Save
            </Button>
        </Lightbox>
    )
}
