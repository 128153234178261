import {from} from 'fromfrom'
import {useMemo, useState} from 'react'

export type PhotoSort = 'UNMATCHED' | 'NAME_ASC' | 'DATE_ASC' | 'DATE_DESC'

export function usePhotoSort<
    PhotoType extends {
        profile?: null | {fullName: string}
        pendingProfile?: null | {fullName: string}
        filename: string
        createdAt: number
    }
>(photos: PhotoType[], initialSort: PhotoSort = 'DATE_ASC') {
    const [sort, setSort] = useState(initialSort)

    return useMemo(() => {
        const pendingPhotos = photos.filter((photo) => !!photo.pendingProfile)
        const otherPhotos = photos.filter((photo) => !photo.pendingProfile)

        let sortedPhotos = from(otherPhotos)
            .sortBy((photo) => (photo.profile ? photo.profile.fullName.toLowerCase() : photo.filename))
            .toArray()

        if (sort === 'UNMATCHED') {
            sortedPhotos = from(sortedPhotos)
                .sortBy((photo) => (photo.profile ? 1 : 0))
                .toArray()
        }

        if (sort === 'DATE_DESC') {
            sortedPhotos = from(sortedPhotos)
                .sortByDescending((photo) => photo.createdAt)
                .toArray()
        }

        if (sort === 'DATE_ASC') {
            sortedPhotos = from(sortedPhotos)
                .sortBy((photo) => photo.createdAt)
                .toArray()
        }

        return {
            photos: [...pendingPhotos, ...sortedPhotos],
            setSort,
            sort,
        }
    }, [photos, sort])
}
