import {gql, useMutation, useQuery} from '@apollo/client'
import {Formik} from 'formik'
import {Button, FadePresence, Form, MOBILE_MEDIA_QUERY, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import React, {FC, useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import * as yup from 'yup'
import {
    AdditionalDataFormFields,
    AdditionalDataFormValues,
    getFieldsForSubmit,
    setupAdditionalDataForm,
} from '~/components/AdditionalDataFormFields'
import {PageLoading} from '~/components/PageLoading'
import {WizardLayout} from '~/components/WizardLayout'
import {WizardLayoutError} from '~/components/WizardLayoutError'
import {FormField} from '~/objectTypes'
import {getGraphQLErrorMessage} from '~/util'
import {CollectFormData, CollectFormDataVariables} from './__types__/CollectFormData'

export const COLLECT_FORM_DATA = gql`
    query CollectFormData($token: String!) {
        dataCollectionForm(token: $token) {
            formFields {
                idStr
                name
                value
                type
                helpText
                options {
                    idStr
                    name
                    selected
                }
            }
            organisation
            admin {
                email
                name
            }
            profile {
                name
            }
        }
    }
`

export const SUBMIT_COLLECTION_FORM_DATA = gql`
    mutation SubmitCollectionFormData($token: String!, $fields: [DataCollectionFormField!]!) {
        dataCollectionForm(token: $token, fields: $fields)
    }
`

const ResponsiveBody = styled(WizardLayout.Body)`
    @media all and (max-width: 960px) {
        padding-right: 32px;
        width: 100%;
    }

    ${MOBILE_MEDIA_QUERY} {
        padding-right: 0;
        width: auto;
    }
`

export const CollectAdditionalDataForm: FC<{token: string}> = ({token}) => {
    const {data, loading, error} = useQuery<CollectFormData, CollectFormDataVariables>(COLLECT_FORM_DATA, {
        variables: {token},
    })

    const [submitCollectionForm, {loading: submitting}] = useMutation(SUBMIT_COLLECTION_FORM_DATA)
    const [submitted, setSubmitted] = useState(false)
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const alert = useAlert()

    if (error) return <WizardLayoutError error={error.message} />
    if (loading || !data) return <PageLoading />

    const {
        dataCollectionForm: {admin, formFields, organisation, profile},
    } = data

    const onFormSubmit = async ({additionalFields}: {additionalFields: AdditionalDataFormValues}) => {
        const fields = getFieldsForSubmit(additionalFields)

        try {
            await submitCollectionForm({variables: {token, fields}})
            trackAnalyticsEvent('submit_data_collection_form')
            setSubmitted(true)
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    const {formValues, formSchema} = setupAdditionalDataForm(formFields as FormField[])

    return (
        <Formik
            onSubmit={onFormSubmit}
            initialValues={{additionalFields: formValues}}
            validationSchema={yup.object({additionalFields: yup.object(formSchema)})}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({handleSubmit}) => {
                return (
                    <WizardLayout>
                        <WizardLayout.Navigation />
                        <FadePresence exitBeforeEnter itemKey={submitted ? 1 : 0}>
                            {submitted ? (
                                <ResponsiveBody>
                                    <Typography.Heading>Thank you</Typography.Heading>
                                    <Spacer height={16} />
                                    <Typography.Subheading maxWidth={720}>
                                        We have captured your information. Should you wish to update your profile please
                                        return to this link.
                                    </Typography.Subheading>
                                    <Spacer height={32} />
                                </ResponsiveBody>
                            ) : (
                                <>
                                    <ResponsiveBody>
                                        <Typography.Heading>
                                            Hi {profile.name}, please update your profile
                                        </Typography.Heading>
                                        <Spacer height={16} />
                                        <Typography.Subheading maxWidth={720}>
                                            {organisation} is updating its Names & Faces index. {admin.name} (
                                            {admin.email}) has asked you to complete your profile.
                                        </Typography.Subheading>
                                        <Spacer height={32} />

                                        <Form onSubmit={handleSubmit}>
                                            <AdditionalDataFormFields formFields={formFields as FormField[]} />
                                        </Form>
                                    </ResponsiveBody>
                                    <WizardLayout.Footer>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            onClick={() => handleSubmit()}
                                            loading={submitting}
                                        >
                                            Save
                                        </Button>
                                    </WizardLayout.Footer>
                                </>
                            )}
                        </FadePresence>
                    </WizardLayout>
                )
            }}
        </Formik>
    )
}
