import {sortBy} from 'lodash'
import {colors} from 'nf-ui'
import SvgCall from 'nf-ui/Icons/Call'
import SvgEmail from 'nf-ui/Icons/Email'
import React, {FC, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components'
import {playground} from '~/pages/Playground'
import {FlexColumn, FlexRow} from '../LayoutItem'
import {formatValue} from '~/util/formatting'

const FieldRowLink = styled.a`
    color: inherit;
    text-decoration: none;
    :hover {
        color: inherit;
        text-decoration: underline;
    }
`

const Heading1 = styled.div`
    font-family: 'Publico Banner';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.02em;
    color: #000000;
    a {
        color: inherit;
        text-decoration: none;
    }
    a:hover {
        color: inherit;
    }
`

const Heading2 = styled.div`
    font-family: 'Publico Banner';
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 1.05em;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.3);
    a {
        color: inherit;
        text-decoration: none;
    }
    a:hover {
        color: inherit;
    }
`

const Label = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: rgba(0, 0, 0, 0.3);
    a {
        color: inherit;
        text-decoration: none;
    }
    a:hover {
        color: inherit;
    }
`

const Paragraph = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #000000;
    a {
        color: inherit;
        text-decoration: underline;
    }
    a:hover {
        color: inherit;
    }
`

export const Divider = styled.div`
    width: 100%;
    height: 0.25px;
    background-color: rgba(0, 0, 0, 1);
    margin-top: 28px;
`

const LineHighlightStyle = styled.div<{highlight?: boolean; sideMargin?: string}>`
    width: 100%;
    border-radius: 3px;
    transition: 0.3s background-color ease-in-out, 0.3s border-style ease-in-out, 0.3s border-color ease-in-out;
    ${(props) =>
        props.highlight
            ? css`
                  border: 2px solid ${colors.primary[100]};
                  background-color: rgba(74, 144, 226, 0.1);
                  margin: -4px;
                  padding: 2px;
              `
            : css`
                  border: 0px solid rgba(255, 255, 255, 0);
                  margin: 0px;
              `}
`

const LineHighlighter = ({highlight, children}: {highlight?: boolean; children: (JSX.Element | undefined)[]}) => {
    const container = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!container.current) return

        if (highlight === true) {
            container.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest',
            })
        }
    }, [highlight])

    return (
        <LineHighlightStyle ref={container} highlight={highlight}>
            {children}
        </LineHighlightStyle>
    )
}

type ProfileLine = {
    priority: number
    fieldOrCategoryIdStrs: string[]
    showField?: boolean
    showLabel?: boolean
    sectionLineBelow?: boolean
    fieldStyle: string
    labelStyle?: string
    labelDescription?: string | null
    fieldBehaviour: string
    prefix?: string
    separator?: string
    suffix?: string
    hideYear?: boolean
    highlight?: boolean
}

type Field = {
    idStr: string
    name: string
    type: string
    subType?: string | null
    values: string[]
    valueIdStrs?: (string | undefined)[]
}

const FieldRow: FC<{style: string | undefined; fieldBehaviour?: string; href?: string}> = ({
    style,
    fieldBehaviour,
    href,
    children,
}) => {
    return fieldBehaviour === 'email' ? (
        <FieldRowLink href={`mailto:${href}`}>
            <FlexRow horizontalAlign="space-between" verticalAlign="center">
                {style === 'Heading 1' ? <Heading1>{children}</Heading1> : undefined}
                {style === 'Heading 2' ? <Heading2>{children}</Heading2> : undefined}
                {style === 'Label' ? <Label>{children}</Label> : undefined}
                {style === 'Paragraph' ? <Paragraph>{children}</Paragraph> : undefined}
                <SvgEmail />
            </FlexRow>
        </FieldRowLink>
    ) : fieldBehaviour === 'mobileNumber' || fieldBehaviour === 'landlineNumber' ? (
        <FieldRowLink href={`tel:${href}`}>
            <FlexRow horizontalAlign="space-between" verticalAlign="center">
                {style === 'Heading 1' ? <Heading1>{children}</Heading1> : undefined}
                {style === 'Heading 2' ? <Heading2>{children}</Heading2> : undefined}
                {style === 'Label' ? <Label>{children}</Label> : undefined}
                {style === 'Paragraph' ? <Paragraph>{children}</Paragraph> : undefined}
                <SvgCall />
            </FlexRow>
        </FieldRowLink>
    ) : (
        <FlexRow>
            {style === 'Heading 1' ? <Heading1>{children}</Heading1> : undefined}
            {style === 'Heading 2' ? <Heading2>{children}</Heading2> : undefined}
            {style === 'Label' ? <Label>{children}</Label> : undefined}
            {style === 'Paragraph' ? <Paragraph>{children}</Paragraph> : undefined}
        </FlexRow>
    )
}

const LineItem = ({
    line,
    organisationIdStr,
    profileIdStr,
    values,
    valueIdStrs,
    label,
    paddingTop,
    renderLineBreaks,
}: {
    line: ProfileLine
    organisationIdStr: string
    profileIdStr: string
    values: string[]
    valueIdStrs: (string | undefined)[]
    label: string
    paddingTop: number
    renderLineBreaks?: boolean
}) => {
    return (
        <FlexColumn padding={`${Math.max(0, (paddingTop || 0) - 2)}px 22px 0px`}>
            <FlexRow>
                <LineHighlighter highlight={line.highlight}>
                    {label ? <FieldRow style={line.labelStyle}>{label}</FieldRow> : undefined}
                    {valueIdStrs.filter((valueIdStr) => valueIdStr).length ? (
                        <FieldRow style={line.fieldStyle} fieldBehaviour={line.fieldBehaviour}>
                            {valueIdStrs.map((valueIdStr, index) => (
                                <Link
                                    style={{
                                        marginRight: index < valueIdStrs.length - 1 ? '8px' : '0px',
                                        display: 'inline-block',
                                    }}
                                    key={valueIdStr}
                                    to={`/${organisationIdStr}/childCategory/${valueIdStr}?profileId=${profileIdStr}`}
                                >
                                    {values[index]}
                                </Link>
                            ))}
                        </FieldRow>
                    ) : renderLineBreaks ? (
                        <>
                            {values
                                .flatMap((value) => value.split('\n'))
                                .map((value, index) => (
                                    <FieldRow
                                        key={index}
                                        style={line.fieldStyle}
                                        fieldBehaviour={line.fieldBehaviour}
                                        href={value}
                                    >
                                        {value}
                                    </FieldRow>
                                ))}
                        </>
                    ) : (
                        <FieldRow style={line.fieldStyle} fieldBehaviour={line.fieldBehaviour} href={values.join(' ')}>
                            {values.join(' ')}
                        </FieldRow>
                    )}
                </LineHighlighter>
            </FlexRow>
            {line.sectionLineBelow ? <Divider></Divider> : undefined}
        </FlexColumn>
    )
}

export const LineItems = ({
    profileLines,
    fields,
    organisationIdStr,
    profileIdStr,
}: {
    profileLines: ProfileLine[]
    fields: Field[]
    organisationIdStr: string
    profileIdStr: string
}) => {
    const lines = sortBy(
        profileLines.filter(({showField}) => showField),
        ({priority}) => priority,
    )
        .map((line) => {
            const lineFields = fields.filter((field) => line.fieldOrCategoryIdStrs.includes(field.idStr))
            const label = line.showLabel
                ? line.labelDescription ||
                  `${line.prefix || ''}${line.fieldOrCategoryIdStrs
                      .map((idStr) => lineFields.find((field) => field.idStr === idStr)?.name)
                      .join(line.separator || ' & ')}${line.suffix || ''}`
                : ''

            if (lineFields.length === 1) {
                return {
                    line,
                    label,
                    values: lineFields[0].values.map((value) => formatValue(line, value)).filter((value) => value),
                    valueIdStrs: lineFields[0].valueIdStrs || [],
                    fields: lineFields,
                }
            }

            const valuesJoined = line.fieldOrCategoryIdStrs
                .flatMap((idStr) => lineFields.find((field) => field.idStr === idStr)?.values || [])
                .map((value) => formatValue(line, value))
                .filter((value) => value)
                .join(line.separator || ' ')

            const value = valuesJoined ? `${line.prefix || ''}${valuesJoined}${line.suffix || ''}` : ''

            return {line, label, values: [value].filter((value) => value), valueIdStrs: [], fields: lineFields}
        })
        .filter(({values}) => values.length)

    let h2LabelGapCounter = 0

    return (
        <FlexColumn>
            {lines.map(({line, values, label, valueIdStrs, fields}, index) => {
                const hasSectionLineAbove = index > 0 && lines[index - 1].line.sectionLineBelow
                const isH2LabelGap =
                    index > 0 &&
                    lines[index - 1].line.fieldStyle === 'Heading 2' &&
                    ((line.showLabel && line.labelStyle === 'Label') ||
                        (!line.showLabel && line.fieldStyle === 'Label'))
                const isH1H2Gap =
                    index > 0 &&
                    lines[index - 1].line.fieldStyle === 'Heading 1' &&
                    ((line.showLabel && line.labelStyle === 'Heading 2') ||
                        (!line.showLabel && line.fieldStyle === 'Heading 2'))
                const isH2H2Gap =
                    index > 0 &&
                    lines[index - 1].line.fieldStyle === 'Heading 2' &&
                    ((line.showLabel && line.labelStyle === 'Heading 2') ||
                        (!line.showLabel && line.fieldStyle === 'Heading 2'))
                const isParagraphLabelGap =
                    index > 0 &&
                    lines[index - 1].line.fieldStyle === 'Paragraph' &&
                    ((line.showLabel && line.labelStyle === 'Label') ||
                        (!line.showLabel && line.fieldStyle === 'Label'))
                const paddingTop =
                    index === 0
                        ? 0
                        : hasSectionLineAbove
                        ? 32
                        : isH1H2Gap
                        ? 0
                        : isH2H2Gap
                        ? 0
                        : isH2LabelGap && h2LabelGapCounter === 0
                        ? 24
                        : isH2LabelGap && h2LabelGapCounter > 0
                        ? 12
                        : isParagraphLabelGap
                        ? 16
                        : 32
                if (isH2LabelGap) {
                    h2LabelGapCounter++
                }
                return (
                    <LineItem
                        key={index}
                        organisationIdStr={organisationIdStr}
                        profileIdStr={profileIdStr}
                        line={{
                            ...line,
                            sectionLineBelow: index === lines.length - 1 ? false : line.sectionLineBelow,
                        }}
                        values={values}
                        valueIdStrs={valueIdStrs}
                        label={label}
                        paddingTop={paddingTop}
                        renderLineBreaks={fields.some(
                            (field) =>
                                field.type === 'text' &&
                                field.subType
                                    ?.split(',')
                                    ?.some((pair) => pair.split('=')[0] === 'rows' && parseInt(pair.split('=')[1]) > 1),
                        )}
                    ></LineItem>
                )
            })}
        </FlexColumn>
    )
}

playground.push({
    path: 'src/components/ProfileView/LineItems.tsx',
    component: LineItems,
    props: {
        organisationIdStr: '',
        profileIdStr: '',
        profileLines: [
            {
                priority: 1,
                fieldOrCategoryIdStrs: ['fn', 'ln'],
                showField: true,
                fieldStyle: 'Heading 1',
                fieldBehaviour: 'text',
                separator: ' ',
            },
            {
                priority: 2,
                fieldOrCategoryIdStrs: ['p1fn', 'p2fn'],
                showField: true,
                fieldStyle: 'Heading 2',
                fieldBehaviour: 'text',
            },
            {
                priority: 3,
                fieldOrCategoryIdStrs: ['s'],
                showField: true,
                fieldStyle: 'Paragraph',
                fieldBehaviour: 'text',
                showLabel: true,
                labelStyle: 'Label',
            },
            {
                priority: 4,
                fieldOrCategoryIdStrs: ['g'],
                showField: true,
                fieldStyle: 'Paragraph',
                fieldBehaviour: 'text',
                showLabel: true,
                labelStyle: 'Label',
            },
            {
                priority: 5,
                fieldOrCategoryIdStrs: ['bd'],
                showField: true,
                fieldStyle: 'Paragraph',
                fieldBehaviour: 'date',
                sectionLineBelow: true,
                showLabel: true,
                labelStyle: 'Label',
            },
            {
                priority: 6,
                fieldOrCategoryIdStrs: ['p1fn', 'ln'],
                showField: true,
                fieldStyle: 'Heading 2',
                fieldBehaviour: 'text',
                separator: ' ',
            },
            {
                priority: 7,
                fieldOrCategoryIdStrs: ['p1m'],
                showField: true,
                fieldStyle: 'Paragraph',
                fieldBehaviour: 'mobileNumber',
                showLabel: true,
                labelStyle: 'Label',
                labelDescription: 'Mobile Number',
            },
            {
                priority: 8,
                fieldOrCategoryIdStrs: ['p1e'],
                showField: true,
                fieldStyle: 'Paragraph',
                fieldBehaviour: 'email',
                sectionLineBelow: true,
                showLabel: true,
                labelStyle: 'Label',
                labelDescription: 'Email',
            },
            {
                priority: 9,
                fieldOrCategoryIdStrs: ['p2fn', 'ln'],
                showField: true,
                fieldStyle: 'Heading 2',
                fieldBehaviour: 'text',
                separator: ' ',
            },
            {
                priority: 10,
                fieldOrCategoryIdStrs: ['p2m'],
                showField: true,
                fieldStyle: 'Paragraph',
                fieldBehaviour: 'mobileNumber',
                showLabel: true,
                labelStyle: 'Label',
                labelDescription: 'Mobile Number',
            },
            {
                priority: 11,
                fieldOrCategoryIdStrs: ['p2e'],
                showField: true,
                fieldStyle: 'Paragraph',
                fieldBehaviour: 'email',
                showLabel: true,
                labelStyle: 'Label',
                labelDescription: 'Email',
            },
            {
                priority: 12,
                fieldOrCategoryIdStrs: ['prj'],
                showField: true,
                fieldStyle: 'Paragraph',
                fieldBehaviour: 'Category',
                showLabel: true,
                labelStyle: 'Label',
                labelDescription: 'Projects',
            },
        ],
        fields: [
            {
                idStr: 'fn',
                name: 'First Name',
                values: ['Andy'],
            },
            {
                idStr: 'ln',
                name: 'Last Name',
                values: ['Galatis'],
            },
            {
                idStr: 'p1fn',
                name: 'Parent 1 First Name',
                values: ['Kerry'],
            },
            {
                idStr: 'p2fn',
                name: 'Parent 2 First Name',
                values: ['Paul'],
            },
            {
                idStr: 's',
                name: 'Siblings',
                values: ['Hannah & Jack'],
            },
            {
                idStr: 'g',
                name: 'Grade',
                values: ['Grade 1 - Ms Yeo'],
            },
            {
                idStr: 'bd',
                name: 'Birthday',
                values: ['03-30'],
            },
            {
                idStr: 'p1m',
                name: 'Parent 1 Mobile Number',
                values: ['+44 1234567890'],
            },
            {
                idStr: 'p1e',
                name: 'Parent 1 Email',
                values: ['kerrygalatis@gmail.com'],
            },
            {
                idStr: 'p2m',
                name: 'Parent 2 Mobile Number',
                values: ['+27 823308060'],
            },
            {
                idStr: 'p2e',
                name: 'Parent 2 Email',
                values: ['p.d.galatis@gmail.com'],
            },
            {
                idStr: 'prj',
                name: 'Projects',
                values: ['A', 'B'],
                valueIdStrs: ['a', 'b'],
            },
        ],
    },
    propOptions: {},
})
