import {easeInOutQuadCSS, Form, SidebarLayout, SIDEBAR_WIDTH, Spacer, Tabs, useAnalyticsEventTracker} from 'nf-ui'
import React, {useEffect} from 'react'
import {Redirect, useRouteMatch} from 'react-router-dom'
import styled, {css} from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {ModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {WelcomeLightbox} from '~/components/Onboarding/WelcomeLightbox'
import {OrganisationSwitcher} from '~/components/OrganisationSwitcher'
import {useCurrentUser} from '~/components/useCurrentUser'
import {useRelativeRoute} from '~/components/useRelativeRoute'
import {BrowseSidebar} from './BrowseSidebar'
import {EditProfileModal} from './EditProfileModal'
import {FilterSidebar} from './FilterSidebar'
import {GroupMessageModal} from './GroupMessageModal'
import {ProfileGroupProvider} from './ProfileGroupContext'
import {UserlandModal} from './UserlandRoot'
import {UserlandRouter} from './UserlandRouter'

/**
 * Places main route and Profile page next to each other.
 */
const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;
    position: relative;
`

export const DirectoryRoot: React.FC = () => {
    const [profileId, setProfileId] = useQueryParam<string>('profileId')
    const [, setEditProfileId] = useQueryParam<string | null>('editProfileId')
    const [modal, setModal] = useQueryParam<UserlandModal>('modal')
    const {path} = useRouteMatch()!
    const match = useRouteMatch(`${path}/filter`)
    const {me} = useCurrentUser()
    const isFilterPage = !!match
    const {currentOrganisation, loading} = CurrentOrganisation.useContainer()
    const editableProfile = currentOrganisation?.editableProfile || null

    const showWelcomeLightbox = !me?.hasViewedUserland

    const onCloseModal = (modal: UserlandModal) => {
        setEditProfileId(null)
        setModal(modal)
    }

    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const editProfile = () => {
        if (!editableProfile?.idStr) return

        trackAnalyticsEvent('click_edit_profile', {from: 'org_switcher'})
        setEditProfileId(editableProfile?.idStr)
        setModal('editProfile')
    }

    useEffect(() => {
        if (showWelcomeLightbox && editableProfile) setProfileId(editableProfile?.idStr)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!currentOrganisation || loading) return null

    if (currentOrganisation.scenario) return <Redirect to={`/${currentOrganisation.idStr}/org-chart/full`} />

    const actionRequired = !!editableProfile && editableProfile.profilePictureActionRequired

    return (
        <SidebarLayout
            logoLink={`/${currentOrganisation.idStr}`}
            topContent={<UserlandSidebar />}
            middleContent={<SidebarContent isFilterPage={isFilterPage} />}
            bottomContent={<OrganisationSwitcher actionRequired={actionRequired} onClickEdit={editProfile} />}
        >
            <Container>
                <ProfileGroupProvider>
                    <ModalNavigator modal={modal} setModal={onCloseModal}>
                        <EditProfileModal key="editProfile" onDoneEditing={() => onCloseModal(null)} />
                        <GroupMessageModal key="groupMessage" />
                    </ModalNavigator>
                    <UserlandRouter profileId={profileId} />
                    {showWelcomeLightbox && <WelcomeLightbox showWelcomeLightbox={showWelcomeLightbox} />}
                </ProfileGroupProvider>
            </Container>
        </SidebarLayout>
    )
}

const UserlandSidebar: React.FC = () => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const {pushRelative} = useRelativeRoute()
    const {path} = useRouteMatch()!
    const match = useRouteMatch(`${path}/filter`)
    const isFilterPage = !!match

    const handleActiveTabChange = () => {
        const pathname = isFilterPage ? `/all` : `/filter`
        trackAnalyticsEvent(isFilterPage ? 'select_browse' : 'select_filter')
        pushRelative(pathname)
    }

    const [search, setSearch] = useQueryParam<string>('search')

    return (
        <>
            <Spacer height={4} />
            <Form.Group name="search">
                <Form.SearchInput
                    width="100%"
                    highlighted={!!search}
                    value={search || ''}
                    onFocus={() => trackAnalyticsEvent('enter_search')}
                    onChange={setSearch}
                    onClick={() => {
                        !isFilterPage && !search && pushRelative('/all')
                    }}
                    useTheme={true}
                />
            </Form.Group>
            <Spacer height={24} />
            <Tabs
                /* Derive activeTabIndex from the active route */
                activeTabIndex={isFilterPage ? 1 : 0}
                onChangeActiveTabIndex={handleActiveTabChange}
                fullWidth
            >
                <Tabs.Tab>Browse</Tabs.Tab>
                <Tabs.Tab>Filter</Tabs.Tab>
            </Tabs>
        </>
    )
}

const PADDING = 32

const ContentSliderWrapper = styled.div`
    overflow: hidden;
    display: flex;
    margin: 0 -${PADDING}px;
    padding: 0 ${PADDING}px;
`

const ContentSlider = styled.div<{isFilterPage: boolean}>(
    ({isFilterPage}) => css`
        display: flex;
        transform: ${isFilterPage ? 'translateX(0px)' : `translateX(-${SIDEBAR_WIDTH}px)`};
        transition: transform 300ms ${easeInOutQuadCSS};
    `,
)

const TabContentWrapper = styled.span<{open: boolean}>`
    width: ${SIDEBAR_WIDTH - PADDING * 2}px;
    opacity: ${({open}) => (open ? 1 : 0)};
    transition: opacity 200ms ${easeInOutQuadCSS};
`

const SidebarContent: React.FC<{isFilterPage: boolean}> = ({isFilterPage}) => {
    return (
        <>
            <Spacer height={24} />
            <ContentSliderWrapper>
                <ContentSlider isFilterPage={isFilterPage}>
                    <TabContentWrapper open={isFilterPage}>
                        <FilterSidebar />
                    </TabContentWrapper>
                    <Spacer width={64} />
                    <TabContentWrapper open={!isFilterPage}>
                        <BrowseSidebar />
                    </TabContentWrapper>
                </ContentSlider>
            </ContentSliderWrapper>
        </>
    )
}
