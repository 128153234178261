import ProfilePlaceholder from '!file-loader!./profile-placeholder.svg'
import {CascadeHover, colors, FileInput, Icon, Spacer, Spinner, TooltipTarget} from 'nf-ui'
import SvgAlertRed from 'nf-ui/Icons/AlertRed'
import React, {useRef, useState} from 'react'
import {useAlert} from 'react-alert'
import styled, {css} from 'styled-components'
import {getGraphQLErrorMessage} from '~/util'
import {validImageMimeTypes} from '~/util/validImages'

const ImageContainer = styled(CascadeHover)<{photo: string; disabled: boolean}>`
    background-color: ${colors.lightGray};
    width: 280px;
    height: 343px;
    border-radius: 3px;
    border: 2px dashed ${colors.darkGray};
    transition: 0.25s border-color ease-in-out, 0.25s border-radius ease-in-out;
    cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    ${(props) =>
            props.photo &&
            css`
            border: 0px dashed ${colors.darkGray};
            background-image: url('${props.photo}');
            background-size: cover;
            background-position: center;
            border-radius: 4px;
        `}
        :hover {
        border-color: ${colors.primary[100]};
    }
`

const UploadButtonContainer = styled.div<{photo: string}>`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;

    ${(props) =>
        !props.photo &&
        css`
            background-size: 251px 302px;
            background-image: url('${ProfilePlaceholder}');
            background-position: bottom center;
            background-repeat: no-repeat;
        `}

    button {
        overflow: visible;
    }
`

const AlertContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(calc(50% + 0.5px), calc(-50% - 1px));
    background-color: ${colors.lightGray};
    border-radius: 50%;
    width: 20px;
    height: 20px;
`

export const UploadProfilePhoto = ({
    onFile,
    successMessage,
    currentPhoto = '',
    disabled = false,
    actionRequired = false,
}: {
    currentPhoto?: string | null
    successMessage: string
    onFile: (file: File) => Promise<string | undefined>
    disabled?: boolean
    actionRequired?: boolean
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [uploadedPhoto, setUploadedPhoto] = useState(currentPhoto ?? '')
    const [loading, setLoading] = useState(false)
    const alert = useAlert()

    return (
        <ImageContainer
            disabled={disabled}
            onClick={(event) => {
                event.stopPropagation()

                if (disabled) return
                if (!fileInputRef.current) return
                fileInputRef.current.click()
            }}
            photo={loading ? '' : uploadedPhoto}
        >
            {loading ? (
                <Spinner />
            ) : (
                <UploadButtonContainer photo={loading ? '' : uploadedPhoto}>
                    {!disabled && (
                        <FileInput
                            ref={fileInputRef}
                            accept={validImageMimeTypes}
                            onChange={async (event) => {
                                try {
                                    if (!event.target.files) return
                                    setLoading(true)
                                    const url = await onFile(event.target.files[0])
                                    if (!url) throw new Error('Missing photo upload response URL')
                                    setUploadedPhoto(url)
                                    alert.success(successMessage)
                                } catch (error) {
                                    alert.error(getGraphQLErrorMessage(error))
                                }

                                setLoading(false)
                            }}
                        >
                            {uploadedPhoto ? 'Upload a different profile picture' : 'Select profile picture to upload'}
                            {actionRequired && (
                                <AlertContainer>
                                    <TooltipTarget title="Missing profile picture" position="right" maxWidth={144}>
                                        <Icon icon={SvgAlertRed} />
                                    </TooltipTarget>
                                </AlertContainer>
                            )}
                        </FileInput>
                    )}
                    <Spacer height={40} />
                </UploadButtonContainer>
            )}
        </ImageContainer>
    )
}
