import * as React from 'react'
const SvgTeams = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgTeams(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 19 18" ref={svgRef} {...props}>
            <path
                d="M10.302 1a2.61 2.61 0 00-2.604 2.605c0 .258.04.507.11.744H2.117C1.504 4.349 1 4.853 1 5.465v6.698c0 .612.504 1.116 1.116 1.116h4.112A4.098 4.098 0 0010.302 17c1.905 0 3.439-1.336 3.893-3.102.44.29.939.497 1.503.497a2.796 2.796 0 002.79-2.79v-4.28a.372.372 0 00-.372-.372H9.93v-.772a2.61 2.61 0 002.977-2.576A2.61 2.61 0 0010.302 1zm0 .744c1.032 0 1.86.829 1.86 1.86a1.855 1.855 0 01-2.236 1.82 1.12 1.12 0 00-1.112-1.075h-.215a1.855 1.855 0 011.703-2.605zm5.582.744c-.59 0-1.079.235-1.395.59a1.905 1.905 0 00-.466 1.27c0 .456.15.915.466 1.271.316.356.806.59 1.395.59s1.078-.234 1.394-.59c.317-.356.466-.815.466-1.27 0-.455-.15-.915-.466-1.27-.316-.356-.805-.59-1.394-.59zm0 .745c.403 0 .658.137.838.34.18.202.278.486.278.776 0 .29-.098.574-.278.776-.18.202-.435.34-.838.34-.403 0-.658-.138-.838-.34a1.177 1.177 0 01-.279-.776c0-.29.099-.574.279-.776.18-.203.435-.34.838-.34zM2.116 5.093H8.814c.21 0 .372.162.372.372v.136a.372.372 0 000 .206v1.456a.372.372 0 000 .121v4.779c0 .21-.162.372-.372.372h-2.17a.369.369 0 00-.121 0H2.116a.366.366 0 01-.372-.372V5.465c0-.21.162-.372.372-.372zm1.489 1.488v.745h1.488v3.72h.744v-3.72h1.489V6.58H3.605zM9.93 7.698h3.721v5.209a3.343 3.343 0 01-3.349 3.349 3.342 3.342 0 01-3.327-2.977h1.839c.612 0 1.116-.504 1.116-1.116V7.698zm4.465 0h3.35v3.907a2.04 2.04 0 01-2.047 2.046 2.02 2.02 0 01-1.347-.524c.004-.077.044-.142.044-.22v-5.21z"
                fill="#000"
                fillRule="nonzero"
                stroke="#000"
                strokeWidth={0.3}
            />
        </svg>
    )
})
export default SvgTeams
