import {motion, Variants} from 'framer-motion'
import {blendWhite, Button, colors, FadePresence, Form, Icon, Spacer, useAnalyticsEventTracker} from 'nf-ui'
import SvgAdd from 'nf-ui/Icons/Add'
import SvgChevronDown from 'nf-ui/Icons/ChevronDown'
import SvgEdit from 'nf-ui/Icons/Edit'
import React, {Dispatch, FC, Suspense, useCallback, useState} from 'react'
import {Draggable} from 'react-beautiful-dnd'
import styled from 'styled-components'
import {LayoutItem} from '~/components/LayoutItem'
import {useRelativeRoute} from '~/components/useRelativeRoute'

export const Handle = React.lazy(() => import('./handle.svg'))

export type DragItem = {
    idStr: string
    visible?: boolean
    label?: string
    name?: string
    canExpand?: boolean
}

export type DraggableItemProps = {
    addToHome?: (itemId: string) => void
    toggleVisibility?: (fieldId: string) => void
    item: DragItem
    index: number
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    expanded: string[]
    setExpanded: Dispatch<React.SetStateAction<string[]>>
    onEditClickEvent?: string
    editable?: boolean
    hasSectionLine?: boolean
    canChangeSectionLine?: boolean
    isDragDisabled?: boolean
    onChangeSectionLine?: () => void
}

const LeftContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    min-width: 0;
    height: 100%;

    * {
        flex-shrink: 0;
    }
`

const Label = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;
    margin-top: -1px;
`

const UnsetContainer = styled.div`
    background-color: unset;
    border-color: unset;
    color: unset;
`

const IconPadded = styled(Icon)`
    margin-top: 4px;
`
const CaretContainer = styled.div`
    cursor: pointer;
`
const caretTransition = {
    type: 'spring',
    stiffness: 90,
    damping: 90,
    mass: 0.5,
}

const caretVariants: Variants = {
    open: {
        transition: caretTransition,
        rotateZ: 180,
    },
    closed: {
        transition: caretTransition,
        rotateZ: 0,
    },
}

const SectionLineBar = styled.div<{width: number}>`
    width: ${(props) => `${props.width}px`};
    height: 5px;
    box-sizing: border-box;
    border: 1px solid ${colors.darkGray};
    background-color: ${colors.lightGray};
    margin: 20px 0px;
`
const SectionLineAdd = styled.div`
    line-height: 25px;
    background-color: ${blendWhite(colors.darkBlue, 0.12)};
    border-radius: 3px;
    color: ${colors.darkBlue};
    padding: 2px 16px;
    margin: 8px;
    font-size: 12px;
    cursor: pointer;
`

const SectionLineDelete = styled.div`
    line-height: 25px;
    background-color: ${blendWhite(colors.red, 0.12)};
    border-radius: 3px;
    color: ${colors.red};
    padding: 2px 16px;
    margin: 8px;
    font-size: 12px;
    cursor: pointer;
`

const SectionLineMargin = styled.div`
    height: 8px;
    width: 340px;
`

export const AddToHome = styled(Button)``

export const DraggableItem: FC<DraggableItemProps> = ({
    item,
    index,
    toggleVisibility,
    onMouseEnter,
    onMouseLeave,
    expanded,
    setExpanded,
    addToHome,
    onEditClickEvent,
    editable = true,
    isDragDisabled = false,
    hasSectionLine,
    canChangeSectionLine,
    onChangeSectionLine,
    children,
}) => {
    const {pushRelative} = useRelativeRoute()
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const [hovering, setHovering] = useState(false)
    const handleMouseEnter = useCallback(() => {
        setHovering(() => true)
        onMouseEnter?.()
    }, [setHovering, onMouseEnter])

    const handleMouseLeave = useCallback(() => {
        setHovering(() => false)
        onMouseLeave?.()
    }, [setHovering, onMouseLeave])

    const onEditClick = () => {
        onEditClickEvent && trackAnalyticsEvent(onEditClickEvent)
        pushRelative(`/edit/${item.idStr}`)
    }

    const isExpanded = expanded.includes(item.idStr)

    return (
        <>
            <Draggable draggableId={item.idStr} index={index} isDragDisabled={isDragDisabled}>
                {({draggableProps, dragHandleProps, innerRef}, {isDragging}) => (
                    <LayoutItem
                        isColumn
                        fullWidth
                        {...draggableProps}
                        {...dragHandleProps}
                        ref={innerRef}
                        disabled={item.visible === false}
                    >
                        {!hasSectionLine && !canChangeSectionLine && <Spacer height={4}></Spacer>}
                        <LayoutItem
                            isDraggable
                            isExpanded={isExpanded}
                            fullWidth
                            minHeight
                            hasBorder
                            isColumn
                            isDragging={isDragging}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <LayoutItem
                                minHeight
                                fullWidth
                                cursor="pointer"
                                onClick={() => {
                                    if (item.canExpand) {
                                        if (isExpanded) {
                                            setExpanded(() =>
                                                expanded.filter((expandedItem) => expandedItem !== item.idStr),
                                            )
                                            return
                                        }
                                        setExpanded(() => [...expanded, item.idStr])
                                    }
                                }}
                            >
                                <LeftContainer>
                                    <Suspense fallback={null}>
                                        <Handle />
                                        <Spacer width={8} />
                                    </Suspense>
                                    <Label>{item.label}</Label>
                                </LeftContainer>
                                <FadePresence>
                                    {hovering && editable && (
                                        <>
                                            <Button variant="tertiary" onClick={onEditClick}>
                                                <Button.Icon icon={SvgEdit} /> Edit
                                            </Button>
                                            <Spacer width={8} />
                                        </>
                                    )}
                                </FadePresence>
                                <FadePresence>
                                    {addToHome && hovering && (
                                        <>
                                            <Button variant="tertiary" onClick={() => addToHome(item.idStr)}>
                                                <Button.Icon icon={SvgAdd} /> Display on home
                                            </Button>
                                            <Spacer width={8} />
                                        </>
                                    )}
                                </FadePresence>

                                {item.canExpand && (
                                    <CaretContainer>
                                        <motion.div
                                            initial={false}
                                            animate={isExpanded ? 'open' : 'closed'}
                                            variants={caretVariants}
                                        >
                                            <IconPadded icon={SvgChevronDown} />
                                        </motion.div>
                                    </CaretContainer>
                                )}
                                {toggleVisibility && (
                                    <>
                                        <Spacer width={16} />
                                        <UnsetContainer>
                                            <Form.Group name="switch">
                                                <Spacer height={4}></Spacer>
                                                <Form.Switch
                                                    checked={item.visible}
                                                    onChange={() => toggleVisibility(item.idStr)}
                                                />
                                            </Form.Group>
                                        </UnsetContainer>
                                    </>
                                )}
                            </LayoutItem>
                            {isExpanded && (
                                <LayoutItem cursor="initial" isColumn>
                                    {children}
                                </LayoutItem>
                            )}
                        </LayoutItem>
                        {!hasSectionLine && !canChangeSectionLine && <Spacer height={4}></Spacer>}
                        {!hasSectionLine && canChangeSectionLine && (
                            <LayoutItem cursor="initial">
                                <SectionLineMargin className="hide-on-hover"></SectionLineMargin>
                                <SectionLineBar width={105} className="reveal-on-hover"></SectionLineBar>
                                <SectionLineAdd className="reveal-on-hover" onClick={onChangeSectionLine}>
                                    Add Section Line
                                </SectionLineAdd>
                                <SectionLineBar width={105} className="reveal-on-hover"></SectionLineBar>
                            </LayoutItem>
                        )}
                        {hasSectionLine && canChangeSectionLine && (
                            <LayoutItem cursor="initial">
                                <SectionLineBar width={340} className="hide-on-hover"></SectionLineBar>
                                <SectionLineBar width={99} className="reveal-on-hover"></SectionLineBar>
                                <SectionLineDelete className="reveal-on-hover" onClick={onChangeSectionLine}>
                                    Delete Section Line
                                </SectionLineDelete>
                                <SectionLineBar width={99} className="reveal-on-hover"></SectionLineBar>
                            </LayoutItem>
                        )}
                    </LayoutItem>
                )}
            </Draggable>
        </>
    )
}
