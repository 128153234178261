import React, {useContext} from 'react'
import {ExpandedIndex} from './CollapsibleGroup'

export const CollapsibleContext = React.createContext<ExpandedIndex>(null)

export const useCollapsibleContext = () => {
    const context = useContext(CollapsibleContext)
    if (context == null) throw new Error('Cannot use useCollapsibleContext outside of a <Collapsible />')
    return context
}
