import * as React from 'react'
const SvgRemoveAdmin = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgRemoveAdmin(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M12.95 0h-9.9A2.195 2.195 0 00.855 2.06l-.005.16v9.04c0 .808.398 1.517 1.085 1.909l5.05 2.598c.56.283 1.27.308 1.858.077l.172-.077 4.994-2.517c.652-.348 1.08-1.041 1.135-1.82l.006-.17V2.22c0-1.18-.89-2.134-2.041-2.215L12.95 0zm0 1.08c.616 0 1.128.44 1.193 1.015l.007.125v9.04c0 .391-.216.751-.55.957l-.115.063-4.983 2.51c-.257.152-.608.17-.877.063l-5.103-2.57a1.157 1.157 0 01-.664-.893l-.008-.13V2.22c0-.593.47-1.072 1.07-1.134l.13-.006h9.9zm-2.921 4.108c-.165-.18-.436-.2-.672-.06l-.086.061-1.21 1.314-1.332-1.315c-.181-.198-.577-.198-.758 0a.602.602 0 00-.065.7l.055.072 1.316 1.3-1.306 1.288a.595.595 0 000 .782c.161.176.491.196.697.051l.071-.062L8.06 8.015l1.18 1.286c.284.232.603.232.789.03a.6.6 0 00.065-.699l-.055-.073-1.316-1.3 1.306-1.288a.597.597 0 000-.783z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgRemoveAdmin
