import {motion} from 'framer-motion'
import React, {FC} from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {NotFound} from '../NotFound'
import {UserlandActionBar, UserlandActionBarContext} from './ActionBar'
import {AllStaff} from './AllStaff'
import {Category} from './Category'
import {ChildCategory} from './ChildCategory'
import {FilterStaff} from './FilterStaff'
import {FullProfileSidePanel} from './FullProfileSidePanel'
import {Home} from './Home'
import {Insights} from './Insights'
import {Meetings} from './Meetings'
import {useShowMeetings} from './Meetings/useShowMeetings'
import {SearchResults} from './SearchResults'
import {SmartGroup} from './SmartGroup'
import {Typography} from 'nf-ui'
import {getGraphQLErrorMessage} from '~/util'
import {PageLoading} from '~/components/PageLoading'
import {useOrganisationContext} from '~/components/OrganisationContext'

const RouteContainer = styled(motion.div)`
    flex: 1;
    position: relative;
    overflow-x: hidden;
`

export const UserlandRouter: FC<{
    profileId?: string
}> = ({profileId}) => {
    const {path} = useRouteMatch()!
    const {pathname} = useLocation()
    const [search] = useQueryParam<string>('search')
    const {showMeetings} = useShowMeetings()
    const [refreshing] = useOrganisationContext.useOrganisationRefreshing()

    return (
        <UserlandActionBarContext>
            <RouteContainer>
                {refreshing?.error ? (
                    <Typography.Heading>{getGraphQLErrorMessage(refreshing?.error)}</Typography.Heading>
                ) : refreshing?.loading ? (
                    <>
                        <PageLoading />
                        <UserlandActionBar loading />
                    </>
                ) : search ? (
                    <SearchResults />
                ) : (
                    <Switch>
                        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                        <Route path={`${path}/all`} exact>
                            <AllStaff />
                        </Route>
                        <Route path={`${path}`} exact>
                            {showMeetings ? <Insights /> : <Home />}
                        </Route>
                        <Route path={`${path}/insights`} exact>
                            <Insights />
                        </Route>
                        {showMeetings && (
                            <Route path={`${path}/meetings`} exact>
                                <Meetings />
                            </Route>
                        )}
                        <Route path={`${path}/filter`} exact>
                            <FilterStaff />
                        </Route>
                        <Route path={`${path}/smartGroup/:smartGroupId`} exact>
                            {({match}: {match: {params: Record<string, string>}}) => (
                                <SmartGroup smartGroupId={match!.params.smartGroupId} />
                            )}
                        </Route>
                        <Route path={`${path}/insights/:smartGroupId`} exact>
                            {({match}: {match: {params: Record<string, string>}}) => (
                                <SmartGroup smartGroupId={match!.params.smartGroupId} />
                            )}
                        </Route>
                        <Route path={`${path}/category/:categoryIdStr`} exact>
                            {({match}: {match: {params: Record<string, string>}}) => (
                                <Category categoryIdStr={match!.params.categoryIdStr} />
                            )}
                        </Route>
                        <Route path={`${path}/childCategory/:childCategoryIdStr`} exact>
                            {({match}: {match: {params: Record<string, string>}}) => (
                                <ChildCategory childCategoryIdStr={match!.params.childCategoryIdStr} />
                            )}
                        </Route>
                        <Route path={`${path}/home/childCategory/:childCategoryIdStr`} exact>
                            {({match}: {match: {params: Record<string, string>}}) => (
                                <ChildCategory childCategoryIdStr={match!.params.childCategoryIdStr} />
                            )}
                        </Route>

                        <Route default>
                            <NotFound />
                        </Route>
                    </Switch>
                )}
            </RouteContainer>

            <FullProfileSidePanel profileId={profileId} />
        </UserlandActionBarContext>
    )
}
