import {from} from 'fromfrom'
import {Column} from './MultiColumnList'
import {useState} from 'react'

type UseSortOptions<TItem> = {
    columns: Column<TItem>[]
    rows: TItem[]
    sortableColumns?: string[]
    defaultSort?: {
        column: string
        ascending?: boolean
    }
}

export type Sort<TItem> = {
    sortedRows: TItem[]
    toggleColumn: (columnName: string) => void
    sortAscending: boolean
    isColumnActive: (columnName: string) => boolean
    isColumnSortable: (columnName: string) => boolean
}

export function useSort<TItem>({columns, rows, sortableColumns, defaultSort}: UseSortOptions<TItem>): Sort<TItem> {
    const [activeColumnName, setActiveColumnName] = useState<string | null>(defaultSort?.column ?? columns[0].name)
    const [sortAscending, setSortAscending] = useState(defaultSort?.ascending ?? true)

    const activeColumn = columns.find((c) => c.name === activeColumnName)

    let sortedRows = rows

    if (activeColumn) {
        sortedRows = from(rows)
            .sortBy(activeColumn.sortValue ?? activeColumn.value)
            .toArray()

        sortedRows = sortAscending ? sortedRows : sortedRows.reverse()
    }

    const toggleColumn = (columnName: string) => {
        if (sortableColumns && !sortableColumns.includes(columnName)) return

        if (columnName === activeColumn?.name) {
            setSortAscending(!sortAscending)
        } else {
            setSortAscending(true)
            setActiveColumnName(columnName)
        }
    }

    return {
        sortedRows,
        toggleColumn,
        sortAscending,
        isColumnActive: (columnName) => activeColumn?.name === columnName,
        isColumnSortable: (columnName) => (sortableColumns ? sortableColumns.includes(columnName) : true),
    }
}
