import {Button, colors, Icon, Spacer, Typography} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {getHRISName} from '~/components/Integrations/integrations'
import {ModalLayout} from '~/components/ModalLayout'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {DataSourceFileType, DataSourceType} from '~/globalTypes'
import {isEditDataSource} from '../ConfigureDataSourceModal'
import {useConfigureDataSource} from '../useConfigureDataSource'
import {useAdditionalDataSourceMutations} from './useAdditionalDataSourceMutations'
import {usePrimaryDataSourceMutations} from './usePrimaryDataSourceMutations'

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.5fr 1fr;
    width: 50%;
`
const Cell = styled.div<{borderless: Boolean}>`
    display: flex;
    align-items: center;
    justify-self: start;
    align-self: center;
    border-bottom: ${(props) => (props.borderless ? 'none' : '1px solid')};
    border-color: #d5dbdf;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
`

export const MappingSummary = () => {
    const {onClose} = useModalNavigator()
    const {state, dispatch, primary} = useConfigureDataSource()
    const alert = useAlert()
    const {loading: loadingAdditional, onSubmit: submitAdditionalDataSource} = useAdditionalDataSourceMutations()
    const {connectCsv, connectGoogleSheet, loading: loadingPrimary} = usePrimaryDataSourceMutations()

    const onSubmit = (type: DataSourceType) => {
        if (!primary) return submitAdditionalDataSource()
        return type === DataSourceType.DIRECT_UPLOAD ? connectCsv() : connectGoogleSheet()
    }
    const loading = loadingPrimary || loadingAdditional

    const {dataSourceData, mappedColumns, allSteps, selectedColumns} = state
    const fieldIdStrs = Object.keys(mappedColumns)
    const mappedFields = allSteps.filter((step) => fieldIdStrs.includes(step.idStr))

    const selectedColumnsNames = Object.keys(selectedColumns)

    const fileTypeName =
        dataSourceData.type === DataSourceType.DIRECT_UPLOAD
            ? dataSourceData.fileType
            : getHRISName(dataSourceData.type)

    return (
        <>
            <ModalLayout.Body>
                <Typography.Heading>Preview import</Typography.Heading>
                <Spacer height={16} />
                {primary ? (
                    <>
                        <Typography.Subheading maxWidth={892}>
                            Below are {selectedColumnsNames.length} columns we will match and import into Names & Faces
                        </Typography.Subheading>
                        <Spacer height={32} />
                        <Grid>
                            <Typography.Label>New {fileTypeName} data</Typography.Label>
                            <div />
                            <Typography.Label>Field in Names & Faces</Typography.Label>
                            {selectedColumnsNames.map((column, i) => (
                                <React.Fragment key={i}>
                                    <Cell borderless={i === selectedColumnsNames.length - 1} key={column}>
                                        <Typography.Paragraph bottomMargin={false}>
                                            {selectedColumns[column].columnName}
                                        </Typography.Paragraph>
                                    </Cell>
                                    <Cell borderless={i === selectedColumnsNames.length - 1}>
                                        <Icon icon={SvgArrowRight} tint={colors.primary[100]} />
                                    </Cell>
                                    <Cell borderless={i === selectedColumnsNames.length - 1}>
                                        <Typography.Paragraph bottomMargin={false}>
                                            {selectedColumns[column].fieldName}
                                        </Typography.Paragraph>
                                    </Cell>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </>
                ) : (
                    <>
                        <Typography.Subheading maxWidth={892}>
                            Below are {mappedFields.length} columns we will match and import into Names & Faces
                        </Typography.Subheading>
                        <Spacer height={32} />
                        <Grid>
                            <Typography.Label>New {fileTypeName} data</Typography.Label>
                            <div />
                            <Typography.Label>Field in Names & Faces</Typography.Label>
                            {mappedFields.map((stepField, i) => (
                                <React.Fragment key={i}>
                                    <Cell borderless={i === mappedFields.length - 1} key={stepField.idStr}>
                                        <Typography.Paragraph bottomMargin={false}>
                                            {mappedColumns[stepField.idStr]}
                                        </Typography.Paragraph>
                                    </Cell>
                                    <Cell borderless={i === mappedFields.length - 1}>
                                        <Icon icon={SvgArrowRight} tint={colors.primary[100]} />
                                    </Cell>
                                    <Cell borderless={i === mappedFields.length - 1}>
                                        <Typography.Paragraph bottomMargin={false}>
                                            {stepField.name}
                                        </Typography.Paragraph>
                                    </Cell>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </>
                )}
            </ModalLayout.Body>
            <ModalLayout.Footer back={false}>
                <Button
                    variant={'secondary'}
                    onClick={() => {
                        dispatch({type: 'FIRST_FIELD'})
                    }}
                    onClickAnalyticsEvent="select_start_over"
                >
                    Start over
                </Button>
                <Spacer width={16} />
                <Button
                    onClick={async () => {
                        await onSubmit(dataSourceData.type)
                        const actionCompleted = isEditDataSource(dataSourceData) ? 'updated' : 'connected'

                        if (dataSourceData.type === DataSourceType.GOOGLE_SHEETS) {
                            alert.success(`Google Sheet successfully ${actionCompleted}`)
                        } else if (dataSourceData.type === DataSourceType.DIRECT_UPLOAD) {
                            const {fileType} = dataSourceData
                            const uploadType = fileType === DataSourceFileType.CSV ? 'CSV' : 'XLS'
                            alert.success(`${uploadType} successfully ${actionCompleted}`)
                        }
                        onClose()
                    }}
                    onClickAnalyticsEvent="select_begin_import"
                    loading={loading}
                >
                    Begin import
                </Button>
            </ModalLayout.Footer>
        </>
    )
}
