import {Button, HeaderLayout} from 'nf-ui'
import React, {useEffect, useRef, useState} from 'react'
import {ModalLayout} from '~/components/ModalLayout'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import {useCropAndRotate} from '~/components/PhotoManagementCommon'
import SvgRotateRight from 'nf-ui/Icons/RotateRight'
import SvgClose from 'nf-ui/Icons/Close'
import styled from 'styled-components'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import {getGraphQLErrorMessage} from '~/util'
import {useAlert} from 'react-alert'
import {ApolloError} from '@apollo/client'

const SubHeading = styled.div`
    font-family: 'halyard-display';
    font-weight: 300;
    padding-bottom: 6px;
    margin-bottom: 19px;
    border-bottom: solid #ccc 0.5px;
    display: inline-block;
`
interface PointerEvent {
    pointerId: number
    pressure: number
    pageX: number
    pageY: number
}

interface CropEvent {
    detail: {
        originalEvent: PointerEvent
        action: 'crop' | 'move' | 'zoom' | 'e' | 's' | 'w' | 'n' | 'ne' | 'nw' | 'se' | 'sw' | 'all'
    }
    target?: {
        cropper: {
            pointers: {
                [key: string]: {
                    startX: number
                    startY: number
                    endX?: number
                    endY?: number
                }
            }
        }
    }
}

interface CropResult {
    left: number
    top: number
    width: number
    height: number
}

const getCropResult = (cropper: Cropper) => {
    const cropBox = cropper.getCropBoxData()
    const canvas = cropper.getCanvasData()
    const imageData = cropper.getImageData()
    const ratio = imageData.width / imageData.naturalWidth
    const result = {
        left: Math.round((cropBox.left - canvas.left) / ratio),
        top: Math.round((cropBox.top - canvas.top) / ratio),
        width: Math.round(cropBox.width / ratio),
        height: Math.round(cropBox.height / ratio),
    }
    return result
}

const useCropper = (
    target: string,
    imageRef: React.RefObject<HTMLImageElement>,
    rotation: number,
    setCropResult: (cropResult: CropResult) => void,
) => {
    const cropper = useRef<Cropper | undefined>(undefined)
    return useEffect(() => {
        if (imageRef.current) {
            let cropStart: PointerEvent | undefined
            const aspectRatio = target === 'profile' ? 960 / 1020 : 256 / 384
            const height = target === 'profile' ? 450 : 400
            const options: Cropper.Options<HTMLImageElement> = {
                checkCrossOrigin: false,
                aspectRatio,
                background: false,
                autoCropArea: target === 'profile' ? 1 : 0.8,
                minContainerHeight: height,
                minContainerWidth: height * aspectRatio,
                minCanvasHeight: height,
                minCanvasWidth: height * aspectRatio,
                cropstart: (event: any) => {
                    cropStart = event.detail.originalEvent
                },
                cropmove: (event: any) => {
                    const cropEvent = event as CropEvent
                    const cropMove = event.detail.originalEvent
                    const pointers = cropEvent?.target?.cropper?.pointers
                    if (
                        !cropMove ||
                        !cropStart ||
                        !cropEvent.target ||
                        cropMove.pointerId === cropStart.pointerId ||
                        !pointers ||
                        !pointers[cropStart.pointerId]
                    ) {
                        return
                    }

                    pointers[cropStart.pointerId].endX = cropMove.pageX
                    pointers[cropStart.pointerId].endY = cropMove.pageY
                },
                cropend: (event: any) => {
                    cropStart = undefined
                    setCropResult(getCropResult(event?.target?.cropper))
                },
                ready: (event: any) => {
                    setCropResult(getCropResult(event?.target?.cropper))
                },
            }
            if (!cropper.current) {
                cropper.current = new Cropper(imageRef.current, options)
            } else if (
                cropper.current.getImageData().rotate !== undefined &&
                rotation !== cropper.current.getImageData().rotate
            ) {
                cropper.current.rotateTo(rotation)
                cropper.current.zoomTo(0.1)
                const canvas = cropper.current.getCanvasData()
                const container = cropper.current.getContainerData()
                cropper.current.moveTo((container.width - canvas.width) / 2, (container.height - canvas.height) / 2)
                setCropResult(getCropResult(cropper.current))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageRef, rotation])
}

export interface PhotoCroppingState {
    currentIndex: number
    selection: {
        photoIdStr: string
        originalUrl: string
    }[]
}

const PhotoCroppingModal: React.FC<{
    photoCropping: PhotoCroppingState
    setPhotoCropping: (state: PhotoCroppingState) => void
    setModal: (newValue: string) => void
}> = ({photoCropping, setPhotoCropping, setModal}) => {
    const {onClose} = useModalNavigator()
    const [cropAndRotate, cropAndRotateResult] = useCropAndRotate()
    const imageRefProfile = React.createRef<HTMLImageElement>()
    const imageRefThumb = React.createRef<HTMLImageElement>()
    const cropResultProfile = useRef<CropResult | undefined>(undefined)
    const cropResultThumb = useRef<CropResult | undefined>(undefined)
    const [rotation, setRotation] = useState<number>(0)
    const alert = useAlert()

    useCropper('profile', imageRefProfile, rotation, (cropResult) => (cropResultProfile.current = cropResult))
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCropper('thumb', imageRefThumb, rotation, (cropResult) => (cropResultThumb.current = cropResult))

    return !photoCropping?.selection?.length ? null : (
        <ModalLayout>
            <ModalLayout.Navigation narrow={true} closeable={false}></ModalLayout.Navigation>
            <ModalLayout.Body>
                <HeaderLayout
                    heading="Crop &amp; Rotate"
                    subheading={`Edit the crop of your profile and thumbnail images${
                        photoCropping.selection.length > 1
                            ? ` (${photoCropping.currentIndex + 1} of ${photoCropping.selection.length})`
                            : ''
                    }`}
                    skipPadding={true}
                    rightContent={
                        <Button variant="secondary" onClick={() => setRotation((rotation + 90) % 360)}>
                            Rotate
                            <Button.Icon icon={SvgRotateRight} />
                        </Button>
                    }
                ></HeaderLayout>
                <span style={{display: 'inline-block'}}>
                    <SubHeading>Profile image</SubHeading>
                    <img
                        src={photoCropping.selection[photoCropping.currentIndex].originalUrl}
                        style={{display: 'none'}}
                        ref={imageRefProfile}
                        alt="Source"
                    />
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{display: 'inline-block', verticalAlign: 'top'}}>
                    <SubHeading>Thumbnail image</SubHeading>
                    <img
                        src={photoCropping.selection[photoCropping.currentIndex].originalUrl}
                        style={{display: 'none'}}
                        ref={imageRefThumb}
                        alt="Source"
                    />
                </span>
                <ModalLayout.Footer isOnboarding={false} stretch={true} color="#3c8eff">
                    <span>
                        <Button
                            onClick={() => {
                                onClose()
                            }}
                        >
                            Cancel
                            <Button.Icon icon={SvgClose} />
                        </Button>
                        {photoCropping.currentIndex > 0 && (
                            <>
                                &nbsp;&nbsp;
                                <Button
                                    onClick={() => {
                                        setPhotoCropping({
                                            ...photoCropping,
                                            currentIndex: photoCropping.currentIndex - 1,
                                        })
                                        setModal(`crop-photo-${photoCropping.currentIndex - 1}`)
                                    }}
                                >
                                    <Button.Icon icon={SvgArrowLeft} />
                                    Back
                                </Button>
                            </>
                        )}
                    </span>
                    <span>
                        {photoCropping.currentIndex < photoCropping.selection.length - 1 && (
                            <>
                                <Button
                                    onClick={() => {
                                        setPhotoCropping({
                                            ...photoCropping,
                                            currentIndex: photoCropping.currentIndex + 1,
                                        })
                                        setModal(`crop-photo-${photoCropping.currentIndex + 1}`)
                                    }}
                                >
                                    Skip
                                    <Button.Icon icon={SvgArrowRight} />
                                </Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                        )}
                        <Button
                            loading={cropAndRotateResult.loading}
                            variant="secondary"
                            onClick={async () => {
                                try {
                                    const fetchResult = await cropAndRotate({
                                        variables: {
                                            photoIdStr: photoCropping.selection[photoCropping.currentIndex].photoIdStr,
                                            cropAndRotate: {
                                                rotation: rotation,
                                                profile: cropResultProfile.current,
                                                thumb: cropResultThumb.current,
                                            },
                                        },
                                    })

                                    if (photoCropping.currentIndex < photoCropping.selection.length - 1) {
                                        setPhotoCropping({
                                            selection: photoCropping.selection.map((cropping, index) =>
                                                index === photoCropping.currentIndex
                                                    ? {
                                                          ...cropping,
                                                          originalUrl:
                                                              fetchResult.data?.cropAndRotate.originalUrl ||
                                                              cropping.originalUrl,
                                                      }
                                                    : cropping,
                                            ),
                                            currentIndex: photoCropping.currentIndex + 1,
                                        })
                                        setModal(`crop-photo-${photoCropping.currentIndex + 1}`)
                                    } else {
                                        onClose()
                                    }
                                } catch (error) {
                                    alert.error(getGraphQLErrorMessage(error as ApolloError))
                                }
                            }}
                        >
                            {photoCropping.currentIndex < photoCropping.selection.length - 1
                                ? 'Save & Next'
                                : 'Save & Close'}
                        </Button>
                    </span>
                </ModalLayout.Footer>
            </ModalLayout.Body>
        </ModalLayout>
    )
}

export {PhotoCroppingModal}
