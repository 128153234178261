import {gql, useMutation} from '@apollo/client'
import {Button, Typography} from 'nf-ui'
import React, {useEffect, useState} from 'react'
import {useAlert} from 'react-alert'
import {PanelLayout, PanelLayoutButtons} from '~/components/PanelLayout'
import {PanelHeader} from '~/components/PanelNavigator'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {PANEL_WIDTH} from '~/constants'
import {DESIGN_HOME} from './queries'

const MERGE_CATEGORIES = gql`
    mutation mergeCategories($name: String!, $categoryIdStrs: [String!]!, $orgIdStr: String!) {
        combineCategories(name: $name, categoryIdStrs: $categoryIdStrs, orgIdStr: $orgIdStr) {
            idStr
            label
        }
    }
`

const UNMERGE_CATEGORY = gql`
    mutation removeCombinedCategory($categoryIdStr: String!, $orgIdStr: String!) {
        removeCombinedCategory(categoryIdStr: $categoryIdStr, orgIdStr: $orgIdStr)
    }
`

export function useMergeCategories() {
    const alert = useAlert()
    const organisationIdStr = useOrganisationIdStr()

    const [selectedCategoryIdStrs, setSelectedCategoryIdStrs] = useState<string[]>([])
    const [categoryName, setCategoryName] = useState<string>('')
    const [mergeCategories, {loading}] = useMutation(MERGE_CATEGORIES, {
        onCompleted: () => alert.success('Categories merged'),
        onError: () => alert.error('Error merging categories'),
        refetchQueries: [{query: DESIGN_HOME, variables: {organisationIdStr}}],
        awaitRefetchQueries: true,
    })

    const toggleCategory = (idStr: string) => {
        if (!selectedCategoryIdStrs.includes(idStr)) {
            setSelectedCategoryIdStrs([...selectedCategoryIdStrs, idStr])
        } else {
            const filtered = selectedCategoryIdStrs.filter((categoryIdStr) => categoryIdStr !== idStr)
            setSelectedCategoryIdStrs(filtered)
        }
    }

    const mergeSelectedCategories = async () => {
        await mergeCategories({
            variables: {name: categoryName, categoryIdStrs: selectedCategoryIdStrs, orgIdStr: organisationIdStr},
        })
    }

    return {
        toggleCategory,
        selectedCategoryIdStrs,
        mergeSelectedCategories,
        categoryName,
        setCategoryName,
        loading,
    }
}

export const UnmergeCategoryPanel: React.FC<{
    onClose: () => void
    categoryIdStr: string
    setLoading: (loading: boolean) => void
}> = ({onClose, categoryIdStr, setLoading}) => {
    const alert = useAlert()
    const organisationIdStr = useOrganisationIdStr()

    const [unmergeCategory, {loading}] = useMutation(UNMERGE_CATEGORY, {
        onCompleted: () => {
            onClose()
        },
        onError: () => alert.error('Error restoring category'),
        refetchQueries: [{query: DESIGN_HOME, variables: {organisationIdStr}}],
        awaitRefetchQueries: true,
        variables: {categoryIdStr, orgIdStr: organisationIdStr},
    })

    useEffect(() => {
        setLoading(loading)
    }, [loading, setLoading])

    return (
        <PanelLayout width={PANEL_WIDTH}>
            <PanelHeader panel="delete">Unmerge category.</PanelHeader>
            <Typography.Paragraph>
                Would you like to unmerge this category? This action is irreversible and will take a few minutes.
            </Typography.Paragraph>
            <PanelLayoutButtons>
                <Button variant="tertiary" onClick={() => onClose()}>
                    No, keep
                </Button>
                <Button
                    variant="tertiary"
                    onClick={() => {
                        onClose()
                        unmergeCategory()
                    }}
                >
                    Yes, unmerge
                </Button>
            </PanelLayoutButtons>
        </PanelLayout>
    )
}
