import {gql, useMutation, useQuery} from '@apollo/client'
import {Spacer, Typography} from 'nf-ui'
import React, {FC, useState} from 'react'
import {Helmet} from 'react-helmet'
import styled from 'styled-components'
import {PageLoading} from '~/components/PageLoading'
import {UploadProfilePhoto} from '~/components/UploadProfilePhoto'
import VideoButton from '~/components/VideoButton/index'
import {WizardLayout} from '~/components/WizardLayout/index'
import {getGraphQLErrorMessage} from '~/util'
import {UploadPhotoCollectionPhoto, UploadPhotoCollectionPhotoVariables} from './__types__/UploadPhotoCollectionPhoto'
import {VerifyToken, VerifyTokenVariables} from './__types__/VerifyToken'

const UploadBody = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
`

const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const VERIFY_TOKEN = gql`
    query VerifyToken($token: String!) {
        verifyPhotoCollectionToken(token: $token) {
            firstName
            organisationName
        }
    }
`

const UPLOAD_PHOTO = gql`
    mutation UploadPhotoCollectionPhoto($token: String!, $file: Upload!) {
        uploadPhotoCollectionPhoto(token: $token, file: $file)
    }
`

const ResponsiveBody = styled(WizardLayout.Body)`
    @media all and (max-width: 960px) {
        padding: 0 20px;
        width: 100%;
    }
`

export const PhotoCollectionUpload: FC<{token: string}> = ({token = ''}) => {
    const [uploaded, setUploaded] = useState(false)

    const {data, error, loading} = useQuery<VerifyToken, VerifyTokenVariables>(VERIFY_TOKEN, {
        variables: {
            token: decodeURIComponent(token),
        },
    })

    const [uploadPhoto, uploadPhotoStatus] = useMutation<
        UploadPhotoCollectionPhoto,
        UploadPhotoCollectionPhotoVariables
    >(UPLOAD_PHOTO)

    if (error) return <Typography.Heading>Error: {getGraphQLErrorMessage(error)}</Typography.Heading>
    if (!data || loading) return <PageLoading />

    let heading = `Upload your photo, ${data.verifyPhotoCollectionToken.firstName}`
    if (uploadPhotoStatus.loading) {
        heading = 'Photo uploading'
    } else if (uploaded) {
        heading = 'Photo successfully uploaded'
    }

    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <ResponsiveBody>
                <UploadBody>
                    <Helmet>
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                    </Helmet>
                    <CenterContainer>
                        <Typography.Heading>{heading}</Typography.Heading>
                        <Spacer height={40} />
                        <UploadProfilePhoto
                            onFile={async (file) => {
                                const result = await uploadPhoto({
                                    variables: {
                                        token: decodeURIComponent(token),
                                        file,
                                    },
                                })

                                setUploaded(true)

                                return result.data?.uploadPhotoCollectionPhoto
                            }}
                            successMessage={`Photo successfully sent to ${data.verifyPhotoCollectionToken.organisationName}`}
                        />
                        <Spacer height={24} />
                        <Typography.Tiny maxWidth={280} textAlign="center">
                            {uploaded
                                ? `This is your photo, the photo that will be featured in the ${data.verifyPhotoCollectionToken.organisationName} Names & Faces directory. You're done - you are welcome to go about your day and close this tab.`
                                : 'We recommend a head and shoulders photograph, that clearly shows your face and is at least 2000px wide.'}
                        </Typography.Tiny>
                    </CenterContainer>
                </UploadBody>
                <Spacer height={160} />
            </ResponsiveBody>
            <VideoButton />
        </WizardLayout>
    )
}
