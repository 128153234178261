import {Button, colors, Panel, Spacer, Typography} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import pluralize from 'pluralize'
import React, {useRef} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {PhotoCroppingState} from '~/pages/Adminland/PhotoCroppingModal'
import {getGraphQLErrorMessage} from '~/util'
import {usePanelNavigator} from '../PanelNavigator'
import {DeletePanel} from './DeletePanel'

interface PhotoSelectionFooterProps {
    photosCount: number
    matchedPhotosCount: number
    pendingPhotosCount: number
    deletePhotos: () => void
    deleteLoading: boolean
    unmatchPhotos: () => void
    unmatchLoading: boolean
    approvePhotos: () => void
    approveLoading: boolean
    startCropAndRotatePhotos: () => void
    cancelSelection: () => void
}

const FooterSpacer = styled.div`
    height: 72px;
    width: 100%;
`

const Container = styled.div`
    height: 72px;
    background: ${colors.primary[100]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
`
const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 16px;
`

const RightContainer = styled.div`
    display: flex;
    align-items: center;
    padding-right: 32px;
`

const PhotoSelectionFooter: React.FC<PhotoSelectionFooterProps> = ({
    photosCount,
    matchedPhotosCount,
    pendingPhotosCount,
    deletePhotos,
    deleteLoading,
    unmatchPhotos,
    unmatchLoading,
    approvePhotos,
    approveLoading,
    startCropAndRotatePhotos,
    cancelSelection,
}) => {
    const alert = useAlert()

    const {openPanel, panelProps} = usePanelNavigator('delete')
    const targetRef = useRef<HTMLButtonElement>(null)

    const showUnmatch = matchedPhotosCount > 0
    const showApprove = pendingPhotosCount > 0
    const showCropAndRotate = photosCount > 0

    const onClickApprove = async () => {
        try {
            await approvePhotos()
            alert.success('Successfully approved ' + pendingPhotosCount + ' ' + pluralize('photo', pendingPhotosCount))
            cancelSelection()
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    const onConfirmDelete = async () => {
        try {
            await deletePhotos()
            alert.success('Successfully deleted ' + photosCount + ' ' + pluralize('photo', photosCount))
            cancelSelection()
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    const onClickUnmatch = async () => {
        try {
            await unmatchPhotos()
            alert.success('Successfully unmatched ' + matchedPhotosCount + ' ' + pluralize('photo', matchedPhotosCount))
            cancelSelection()
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    const approveButton = (
        <>
            <Spacer width={16} />
            <Button
                onClick={onClickApprove}
                variant="tertiary"
                transparent={false}
                loading={approveLoading}
                onClickAnalyticsEvent={'multiselect_approve_photo'}
            >
                Approve
            </Button>
        </>
    )

    const unmatchButton = (
        <>
            <Spacer width={16} />
            <Button
                onClick={onClickUnmatch}
                variant="tertiary"
                transparent={false}
                loading={unmatchLoading}
                onClickAnalyticsEvent={'multiselect_unmatch_photo'}
            >
                Unmatch
            </Button>
        </>
    )

    const cropAndRotateButton = (
        <>
            <Spacer width={16} />
            <Button
                onClick={startCropAndRotatePhotos}
                variant="tertiary"
                transparent={false}
                loading={false}
                onClickAnalyticsEvent={'multiselect_crop_and_rotate'}
            >
                Crop &amp; Rotate
            </Button>
        </>
    )

    return (
        <>
            <FooterSpacer />
            <Container>
                <LeftContainer>
                    <Button onClick={cancelSelection} variant="primary" onClickAnalyticsEvent={'multiselect_cancel'}>
                        Cancel
                        <Button.Icon icon={SvgClose} />
                    </Button>
                </LeftContainer>
                <RightContainer>
                    <Typography.Label color="white">{photosCount} Photos Selected</Typography.Label>
                    <Spacer width={16} />
                    <Button
                        ref={targetRef}
                        onClick={() =>
                            openPanel('delete', {
                                itemId: 'delete',
                            })
                        }
                        variant="tertiary"
                        transparent={false}
                        loading={deleteLoading}
                        onClickAnalyticsEvent={'multiselect_delete_photo'}
                    >
                        Delete
                    </Button>
                    {showUnmatch && unmatchButton}
                    {showApprove && approveButton}
                    {showCropAndRotate && cropAndRotateButton}
                </RightContainer>
            </Container>
            <Panel targetRef={targetRef} {...panelProps}>
                <DeletePanel deletePhoto={onConfirmDelete} photoCount={photosCount} key="delete" />
            </Panel>
        </>
    )
}

export {PhotoSelectionFooter}
