import {G, Link, Path, Svg} from '@react-pdf/renderer'
import {blendWhite, colors} from 'nf-ui'
import {getProfileInitials} from 'nf-ui/InitialsText'
import React from 'react'
import {BooleanParam, useQueryParam} from 'use-query-params'
import {getOtherManagersNames} from '../FullOrgChart/getOtherManagersNames'
import {useProfileDetails} from '../FullOrgChart/useProfileDetails'
import {
    Profile,
    PROFILE_PICTURE,
    PROFILE_ROW_HEIGHT,
    PROFILE_ROW_PADDING,
    REPORT_COUNT_OFFSET,
    REPORT_COUNT_SIZE,
    VERTICAL_LINE_OFFSET,
} from './constants'
import {DottedHorizontalLine, HorizontalLine} from './HorizontalLine'
import {Indentation, Spacer} from './Spacer'
import {styled} from './syledPdf'
import {usePrintContext} from './usePrintContext'
import {orderPhotos} from '~/components/OrganisationContext'

const FallbackContainer = styled.View`
    width: ${PROFILE_PICTURE}px;
    height: ${PROFILE_PICTURE}px;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
`

const InitialsText = styled.Text`
    font-size: 8px;
    font-family: halyard-bold;
`

const Photo = styled.Image`
    width: ${PROFILE_PICTURE}px;
    height: ${PROFILE_PICTURE}px;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
`

const BrickContainer = styled.View`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const ReportsCountContainer = styled.View`
    position: absolute;
    background-color: ${colors.white};
    border: 0.5px solid ${colors.darkGray};
    border-top: 0px;
    width: ${REPORT_COUNT_SIZE}px;
    height: ${REPORT_COUNT_SIZE}px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    top: ${PROFILE_ROW_HEIGHT}px;
    justify-content: center;
    align-items: center;
`

const DirectReportsCount = styled.Text`
    font-size: 5px;
    font-family: halyard-bold;
`

const ProfileRow = styled.View`
    height: ${PROFILE_ROW_HEIGHT}px;
    background-color: ${colors.lightGray};
    border-radius: 3px;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    ${(props: {openPosition: boolean}) =>
        props.openPosition &&
        `
        background-color: ${colors.white};
    `}
`

const ProfileRowContent = styled.View`
    flex-direction: row;
    height: ${PROFILE_PICTURE}px;
`

const ProfileTextContainer = styled.View`
    margin-top: -1px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 2px;
`

const PrimaryLine = styled.Text`
    font-size: 8px;
    font-weight: bold;
    font-family: halyard-bold;
`

const SecondaryLine = styled.Text`
    font-size: 6px;
    font-family: halyard;
    overflow: hidden;
    // Removes overflow-y being hidden.
    margin-top: -2px;
    margin-bottom: -2px;
`

const AdditionalManagersContainer = styled.View`
    display: flex;
    flex-direction: row;
`

const ManagersTextContainer = styled.View`
    background-color: white;
    border: 0.5px solid ${colors.darkGray};
    border-radius: 3px;
    height: ${PROFILE_PICTURE}px;
    justify-content: center;
    align-items: center;
    padding: 0 ${PROFILE_ROW_PADDING}px;
    flex-direction: row;
    flex-wrap: nowrap;
`
const OtherManagers = styled.Text`
    font-size: 8px;
    font-family: halyard;
`

const OtherManagersName = styled.Text`
    font-size: 8px;
    font-family: halyard;
    text-decoration: underline;
`

const CollapsedReportsContainer = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const CollapsedReportsCount = styled.Text`
    font-size: 6px;
    font-family: halyard-bold;
    color: ${colors.primary[100]};
`

const CollapsedReportsCaret = () => (
    <Svg style={{width: 16, height: 16}}>
        <G stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <Path
                d="M4.81693398,6.06154876 C4.43438256,6.44528065 4.43438256,7.06512937 4.81693398,7.44768079 L7.30693398,9.93768079 C7.68938648,10.321314 8.31061352,10.321314 8.69322932,9.93751724 L11.183066,7.44768079 C11.5656174,7.06512937 11.5656174,6.44528065 11.183066,6.06154876 L11.0907638,5.98005272 C10.7063634,5.68123389 10.1500835,5.70839924 9.79693398,6.06154876 L8,7.85761478 L6.20309387,6.06157662 C5.82048737,5.67897012 5.19951263,5.67897012 4.81693398,6.06154876 Z"
                fill={colors.primary[100]}
                style={{transform: 'scale(0.5) translateY(8)'}}
            />
        </G>
    </Svg>
)

const FallbackPhoto = ({profile}: {profile: Profile}) => {
    const {theme} = usePrintContext()
    const initials = getProfileInitials(profile.fullName)

    return (
        <FallbackContainer style={{backgroundColor: blendWhite(theme.primary.color, 0.35)}}>
            <InitialsText>{initials}</InitialsText>
        </FallbackContainer>
    )
}

const ProfilePicture = ({profile}: {profile: Profile}) => {
    const orderedPhotos = orderPhotos(profile.photos)
    const imageSrc = orderedPhotos[0]?.signedTinyUrl || orderedPhotos[0]?.signedProfileUrl
    const hasPhoto = !!orderedPhotos[0]
    return (
        <>
            {hasPhoto ? <Photo src={imageSrc} /> : <FallbackPhoto profile={profile} />}
            <Spacer width={PROFILE_ROW_PADDING} />
        </>
    )
}

const OtherManagerNames = ({otherManagers}: {otherManagers: string[]}) => {
    const url = window.location.origin
    return (
        <>
            <OtherManagers>Also reports to </OtherManagers>
            {otherManagers.map((manager, index) => {
                const nameSeparator = index === otherManagers.length - 1 ? 'and' : ','
                return (
                    <React.Fragment key={index}>
                        {index !== 0 && <OtherManagers> {nameSeparator} </OtherManagers>}
                        <OtherManagersName>
                            <Link src={url}>&nbsp;{manager}&nbsp;</Link>
                        </OtherManagersName>
                    </React.Fragment>
                )
            })}
        </>
    )
}

export const ProfileBrick = ({
    profile,
    indentation = 0,
    reportsCount = 0,
    collapsePhoto = false,
    additionalReport = false,
    parentIdStr,
    collapsedCount = 0,
}: {
    profile: Profile
    indentation?: number
    reportsCount?: number
    collapsePhoto?: boolean
    additionalReport?: boolean
    parentIdStr?: string
    collapsedCount?: number
}) => {
    const {indentationWidth, getProfileFromIdStr} = usePrintContext()
    const hasIndentation = indentation > 0
    const hasDirectReports = reportsCount > 0
    const [photosParam] = useQueryParam('photos', BooleanParam)
    const {heading, secondaryLine} = useProfileDetails(profile)

    const otherManagersNames = getOtherManagersNames({
        profile,
        additionalReport,
        parentIdStr,
        getProfileFromIdStr,
    })

    const shouldShowPhoto = !collapsePhoto && photosParam !== false
    const borderStyle = additionalReport ? `1px dashed #A0A6AA` : `0.5px solid ${colors.darkGray}`
    const LineComponent = additionalReport ? DottedHorizontalLine : HorizontalLine

    return (
        <BrickContainer>
            <Indentation indentation={indentation} />

            {hasDirectReports && (
                <ReportsCountContainer
                    style={{
                        left: `${indentation * indentationWidth + REPORT_COUNT_OFFSET}px`,
                    }}
                >
                    <DirectReportsCount>{reportsCount}</DirectReportsCount>
                </ReportsCountContainer>
            )}

            {hasIndentation && (
                <LineComponent
                    top={PROFILE_ROW_HEIGHT / 2}
                    left={(indentation - 1) * indentationWidth + VERTICAL_LINE_OFFSET}
                    width={indentationWidth - VERTICAL_LINE_OFFSET + 1}
                />
            )}

            <ProfileRow style={{border: borderStyle}} openPosition={profile.openPosition}>
                <ProfileRowContent>
                    <Spacer width={PROFILE_ROW_PADDING} />
                    {shouldShowPhoto && <ProfilePicture profile={profile} />}
                    <ProfileTextContainer>
                        <PrimaryLine>{heading}</PrimaryLine>
                        <SecondaryLine>{secondaryLine}</SecondaryLine>
                    </ProfileTextContainer>
                </ProfileRowContent>

                {otherManagersNames && (
                    <AdditionalManagersContainer>
                        <ManagersTextContainer>
                            <OtherManagerNames otherManagers={otherManagersNames}></OtherManagerNames>
                        </ManagersTextContainer>
                        <Spacer width={PROFILE_ROW_PADDING} />
                    </AdditionalManagersContainer>
                )}
                {!!collapsedCount && (
                    <CollapsedReportsContainer>
                        <CollapsedReportsCount>{collapsedCount}</CollapsedReportsCount>
                        <Spacer width={2} />
                        <CollapsedReportsCaret />
                    </CollapsedReportsContainer>
                )}
            </ProfileRow>
        </BrickContainer>
    )
}
