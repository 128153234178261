import {useCallback} from 'react'
import {useHistory, useLocation, useRouteMatch} from 'react-router'
import {stringify} from 'use-query-params'

export type Search = {[key: string]: any}

export type PushOptions = {
    keepSearch?: boolean
    search?: Search
    state?: any
    replace?: boolean
}

function appendSearchObj(currentSearch: string = '', searchObj: Search = {}): string {
    const cleanedSearch = currentSearch.startsWith('?') ? currentSearch.slice(1) : currentSearch
    return [cleanedSearch, stringify(searchObj)].filter((v) => v).join('&')
}

/**
 * Returns the relative path to the current route and a push relative to the current route
 *
 * @export
 * @param {string} [path] Path to be relative to, if not supplied uses the parent route
 * @returns
 */
export function useRelativeRoute(path?: string) {
    const {push, replace: replaceFn} = useHistory()
    const {pathname, search} = useLocation()
    const {url} = useRouteMatch(path || '') || {}

    let relativePath = url && pathname.startsWith(url) ? pathname.slice(url.length) : null
    if (relativePath === '') {
        //Special case
        relativePath = '/'
    }

    const pushRelative = useCallback(
        (path: string, {state, keepSearch, search: searchObj, replace}: PushOptions = {}) => {
            let newPath = ''
            if (url) {
                newPath = !url.endsWith('/') ? url : url.slice(0, url.length - 1)
            }
            newPath += '/'
            newPath += !path.startsWith('/') ? path : path.slice(1)

            const newSearch = appendSearchObj(keepSearch ? search : '', searchObj)
            const navigateFn = replace ? replaceFn : push
            navigateFn({
                pathname: newPath,
                search: newSearch,
                state,
            })
        },
        [push, url, search, replaceFn],
    )

    return {
        relativePath,
        pushRelative,
    }
}
