import {BaseButton, colors, easeInOutQuadCSS, hexToRGB, MatchText, Typography, useAnalyticsEventTracker} from 'nf-ui'
import React, {ButtonHTMLAttributes, HTMLAttributes} from 'react'
import styled, {css} from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {genericMemo} from '~/util/genericMemo'
import {SearchMatch, MaybeSearchResult} from '~/util/search'
import {PHOTO_HEIGHT, PHOTO_WIDTH} from './Photo/Photo'
import {ProfilePicture} from './ProfilePicture/ProfilePicture'
import {LineWithValue} from './ProfilesGrid/ProfilesGrid'
import {orderPhotos} from '~/components/OrganisationContext'
import {TProfile} from './OrganisationContext'

export type ProfileProps = {
    className?: HTMLAttributes<HTMLDivElement>['className']
    profile: MaybeSearchResult<TProfile>
    style?: React.CSSProperties
    highlightedProfileIdStr?: string
    meetingProfile?: boolean
    meetingProfilePhotoUrl?: string
    meetingGuest?: boolean
    lines: LineWithValue[]
} & Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>

const Gradient = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
    z-index: 1;
    opacity: 1;
    transition: opacity 200ms ${easeInOutQuadCSS};
`

const ReverseGradient = styled(Gradient)`
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2) 100%);
    opacity: 0;
`

const FullHeightGradient = styled(Gradient)`
    top: 0;
    opacity: 0.85;
`

const DimmedOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    pointer-events: none;
    transition: 0.3s opacity ease-in-out, 0.3s background-color ease-in-out;
    z-index: 1;
`

const Badge = styled.div`
    position: absolute;
    opacity: 0;
    min-width: 16px;
    min-height: 16px;
    top: 8px;
    right: 8px;
    background-color: ${colors.lightGray};
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 4;
    transition: 0.3s opacity ease-in-out;
`

const GuestBadgeContainer = styled(Badge)`
    opacity: 1;
    top: 8px;
    left: 8px;
    right: unset;
    padding: 1px 5px 2px 5px;
    background-color: #c4c4c4;
`

const Container = styled(BaseButton)<{selected: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 3px;
    background-size: cover;
    background-position: center;
    position: relative;
    background-color: ${colors.darkGray};
    overflow: hidden;

    :hover {
        ${ReverseGradient} {
            opacity: 1;
        }
        ${FullHeightGradient} {
            opacity: 1;
        }

        ${DimmedOverlay} {
            background-color: rgba(255, 255, 255, 0.2);
        }

        ${Badge} {
            opacity: 1;
        }
    }

    ${({selected}) =>
        !selected &&
        css`
            :focus {
                box-shadow: none;
            }
        `}
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
    padding: 8px;
    overflow-wrap: break-word;
`

const CONTENT_PADDING = 16

export const SelectedOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid ${({theme}) => theme.primary.color};
    background-color: ${({theme}) => hexToRGB(theme.primary.color, '0.13')};
    border-radius: 3px;
    z-index: 2;
    pointer-events: none;
    transition: 0.3s opacity ease-in-out;
`

export const GuestBadge = () => {
    return (
        <GuestBadgeContainer>
            <Typography.Tiny bold color="white">
                GUEST
            </Typography.Tiny>
        </GuestBadgeContainer>
    )
}

function highlightText({index, length, value}: SearchMatch) {
    const first = value.substring(0, index)
    const matchText = value.substring(index, index + length)
    const last = value.substring(index + length)

    return (
        <>
            {first}
            <MatchText data-testid="matchText">{matchText}</MatchText>
            {last}
        </>
    )
}

export function Profile({
    profile,
    lines,
    style,
    onClick,
    className,
    highlightedProfileIdStr,
    meetingProfile,
    meetingProfilePhotoUrl,
    meetingGuest,
}: ProfileProps) {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const [profileId, setProfileId] = useQueryParam<string>('profileId')
    const [meetingProfileId, setMeetingProfileId] = useQueryParam<string>('meetingProfileId')
    const photoUrl = meetingProfile ? meetingProfilePhotoUrl : orderPhotos(profile.photos)[0]?.thumbUrl
    const selected = [profileId, meetingProfileId].includes(profile.idStr)

    const count = profile.directReports?.length
    const dimmed = highlightedProfileIdStr && highlightedProfileIdStr !== profile.idStr

    const onProfileClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick?.(event)
        if (meetingProfile) {
            trackAnalyticsEvent('meetings', {
                name: 'openMeetingProfile',
                profileId: profile.idStr,
                guest: !!meetingGuest,
            })
            setMeetingProfileId(profile.idStr)
        } else {
            trackAnalyticsEvent('open_profile', {profileId: profile.idStr})
            // Navigate to FullProfile page if no `onClick`
            setProfileId(profile.idStr)
        }
    }
    // console.log('<Profile>', 'profile', profile, 'lines', lines)

    return (
        <Container
            data-testid={`clickableProfileCard_${profile.idStr}`}
            className={className}
            style={{
                ...style,
                width: PHOTO_WIDTH,
                height: PHOTO_HEIGHT,
            }}
            onClick={onProfileClick}
            selected={selected}
        >
            <ProfilePicture url={photoUrl} fullName={profile.fullName} guest={meetingGuest} />
            <DimmedOverlay data-testid="dimmedOverlay" style={{opacity: dimmed ? 1 : 0}} />
            {count != null && (
                <Badge style={{opacity: dimmed ? undefined : 1}} data-testid="profileBadge">
                    <Typography.Tiny style={{marginTop: '-2px'}}>{count}</Typography.Tiny>
                </Badge>
            )}
            {meetingGuest && <GuestBadge />}
            {photoUrl && <FullHeightGradient />}
            <ReverseGradient />
            <SelectedOverlay data-testid="selectOverlay" style={{opacity: selected ? 1 : 0}} />
            <Content>
                <Typography.Tiny
                    color="white"
                    bold
                    style={{maxWidth: PHOTO_WIDTH - CONTENT_PADDING, maxHeight: '30px', overflow: 'hidden'}}
                >
                    {profile.match?.name ? highlightText(profile.match) : lines?.[0]?.value}
                </Typography.Tiny>
                <Typography.Tiny
                    color="white"
                    style={{maxWidth: PHOTO_WIDTH - CONTENT_PADDING, maxHeight: '30px', overflow: 'hidden'}}
                >
                    {profile.match?.name === false
                        ? highlightText(profile.match)
                        : profile.secondaryLine ?? lines?.[1]?.value}
                </Typography.Tiny>
            </Content>
        </Container>
    )
}

export const MemoizedProfile = genericMemo(Profile)
