import React, {ButtonHTMLAttributes} from 'react'
import styled, {css} from 'styled-components'
import {Icon, IconType} from './Icon'
import {colors} from './theme'
import {paragraphFontStyle} from './Typography'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    disabled?: boolean
    error?: boolean
    selected?: boolean
    height?: string
    hoverFill?: boolean
}

const Button = styled.button<ButtonProps>`
    ${paragraphFontStyle}
    border: 1px solid ${colors.darkGray};
    border-radius: 3px;
    width: 100%;
    outline: 0;
    transition-property: border-color, box-shadow, background-color;
    transition-duration: 0.25s;
    transition-timing-function: ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 16px;
    cursor: pointer;
    height: ${(props) => props.height || '40px'};

    ${({error}) =>
        error &&
        css`
            border-color: ${colors.red};
        `}

    ${({selected}) =>
        selected &&
        css`
            border-color: ${colors.green[100]};
        `}

    ${({disabled, hoverFill}) =>
        disabled
            ? css`
                  color: ${colors.darkGray};
                  cursor: not-allowed;
              `
            : css`
                  color: ${colors.black};
                  background-color: ${colors.white};
                  :focus,
                  :hover {
                      ${hoverFill ? `background-color: ${colors.lightGray}` : `border-color: ${colors.primary[100]}`}
                  }
              `}
`

const ChildrenContainer = styled.span`
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

type WhiteButtonProps = {
    icon?: IconType
    iconRef?: React.Ref<SVGSVGElement>
    hoverFill?: boolean
} & ButtonProps

export const WhiteButton = React.forwardRef<HTMLButtonElement, WhiteButtonProps>(
    ({children, icon, type = 'button', iconRef, hoverFill = false, ...buttonProps}, ref) => {
        return (
            <Button type={type} {...buttonProps} ref={ref} hoverFill={hoverFill}>
                <ChildrenContainer>{children}</ChildrenContainer>
                {icon && (
                    <Icon icon={icon} ref={iconRef} tint={buttonProps.disabled ? colors.darkGray : colors.black} />
                )}
            </Button>
        )
    },
)
