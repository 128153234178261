import * as React from 'react'
const SvgSync = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgSync(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M2.493 9.63l.092.013 2.4.556.09.028c.259.106.417.39.363.677a.534.534 0 01-.647.425l-1.33-.307.537.673.182.216c.685.778 1.563 1.36 2.423 1.663a5.488 5.488 0 004.224-.28c1.372-.686 2.373-1.96 2.782-3.269a.543.543 0 01.705-.347.613.613 0 01.372.748c-.51 1.589-1.688 3.093-3.328 3.914a6.602 6.602 0 01-5.086.328c-1.215-.43-2.445-1.308-3.318-2.491l-.354-.48-.244.799-.034.084a.533.533 0 01-.659.262.613.613 0 01-.373-.749l.659-2.084.035-.087a.533.533 0 01.6-.28zm2.154-7.97a6.585 6.585 0 015.076-.329l.26.1a7.262 7.262 0 013.058 2.393l.354.48.245-.8.035-.084a.532.532 0 01.657-.262c.308.107.475.44.376.743l-.652 2.091-.036.087a.541.541 0 01-.61.278l-2.4-.556-.09-.028a.612.612 0 01-.363-.676.543.543 0 01.659-.425l1.337.308-.547-.676-.184-.217c-.693-.78-1.568-1.36-2.42-1.66a5.513 5.513 0 00-4.233.279c-1.372.685-2.373 1.958-2.783 3.27a.534.534 0 01-.693.347.596.596 0 01-.375-.743c.496-1.582 1.684-3.096 3.33-3.92z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgSync
