import React from 'react'
import styled from 'styled-components'
import {colors} from './theme'
import {Typography} from './Typography'

const InfoBoxWrapper = styled.div`
    display: inline-block;
    max-width: 900px;
    background: ${colors.lightGray};
    border: 1px solid ${colors.darkGray};
    border-radius: 3px;
    p {
        padding: 16px;
    }
`

const Infobox: React.FC = ({children}) => {
    return (
        <InfoBoxWrapper>
            <Typography.Paragraph bottomMargin={false}>{children}</Typography.Paragraph>
        </InfoBoxWrapper>
    )
}

export {Infobox}
