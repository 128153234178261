import React, {createContext, CSSProperties, forwardRef, useContext} from 'react'

/**
 * The `Padding` prop that can be passed to the grid.
 */
export type PaddingProp =
    | number
    | {
          top?: number
          left?: number
          right?: number
          bottom?: number
      }

/**
 * The context used to pass padding to the
 */
export const PaddingContext = createContext<ReturnType<typeof getPaddingObject> | null>(null)

/**
 * Takes in a padding prop value and returns a padding object.
 */
export const getPaddingObject = (padding: PaddingProp) => {
    let paddingObject = null

    if (typeof padding === 'number') {
        paddingObject = {top: padding, left: padding, right: padding, bottom: padding}
    } else {
        paddingObject = {
            top: padding.top || 0,
            left: padding.left || 0,
            right: padding.right || 0,
            bottom: padding.bottom || 0,
        }
    }

    return {
        ...paddingObject,
        horizontal: paddingObject.left + paddingObject.right,
        vertical: paddingObject.top + paddingObject.bottom,
    }
}

/**
 * Consume PaddingContext.
 */
const usePadding = () => {
    const value = useContext(PaddingContext)
    if (!value) throw new Error('usePadding has been used outside of a `PaddingContext`')
    return value
}

/**
 * Overrides `react-window`'s outer div, and adds padding and size to support padding.
 */
export const OuterElement = forwardRef<HTMLDivElement, {style: CSSProperties}>(({style, ...rest}, ref) => {
    const {top, right, bottom, left} = usePadding()

    return (
        <div
            style={{
                ...style,
                width: Number(style.width) + left + right,
                height: Number(style.height) + top + bottom,
                padding: `${top}px ${right}px ${bottom}px ${left}px`,
                boxSizing: 'border-box',
            }}
            ref={ref}
            {...rest}
        />
    )
})

/**
 * Overrides `react-window`'s inner div, and adds width and `position: relative'` to support padding.
 */
export const InnerElement = forwardRef<HTMLDivElement, {style: CSSProperties}>(({style, ...rest}, ref) => {
    return (
        <div
            style={{
                ...style,
                width: Number(style.width),
                position: 'relative',
            }}
            ref={ref}
            {...rest}
        />
    )
})
