import {gql, useMutation} from '@apollo/client'
import {useAlert} from 'react-alert'
import {getGraphQLErrorMessage} from '~/util'
import {SendRestPasswordEmail, SendRestPasswordEmailVariables} from './__types__/SendRestPasswordEmail'

const SEND_RESET_PASSWORD_EMAIL = gql`
    mutation SendRestPasswordEmail($email: String!) {
        sendResetPasswordEmail(email: $email)
    }
`

export const useSendResetPasswordEmail = () => {
    const alert = useAlert()
    const [sendEmail, {loading}] = useMutation<SendRestPasswordEmail, SendRestPasswordEmailVariables>(
        SEND_RESET_PASSWORD_EMAIL,
        {
            onError: (error) => alert.error(getGraphQLErrorMessage(error)),
        },
    )

    return {sendEmail, loading}
}
