import * as React from 'react'
const SvgEllipseLight = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgEllipseLight(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 9 8" fill="none" ref={svgRef} {...props}>
            <circle cx={4.493} cy={4} r={4} fill="#CEE2FF" />
        </svg>
    )
})
export default SvgEllipseLight
