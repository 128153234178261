import React from 'react'
import {LINE_SIZE} from './constants'
import {styled} from './syledPdf'

const HorizontalLineComponent = styled.View`
    position: absolute;
    background-color: black;
    height: ${LINE_SIZE}px;
`

const DottedHorizontalLineComponent = styled.View`
    position: absolute;
    border-bottom: 1px dashed #a0a6aa;
    height: ${LINE_SIZE}px;
`

export const HorizontalLine = ({top, left, width}: {top: number; left: number; width: number}) => {
    return (
        <HorizontalLineComponent
            style={{
                top: `${top}px`,
                left: `${left}px`,
                width: `${width}px`,
            }}
        />
    )
}

export const DottedHorizontalLine = ({top, left, width}: {top: number; left: number; width: number}) => {
    return (
        <DottedHorizontalLineComponent
            style={{
                top: `${top}px`,
                left: `${left}px`,
                width: `${width}px`,
            }}
        />
    )
}
