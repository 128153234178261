import React, {useState, useCallback} from 'react'
import {TooltipWindow} from './TooltipWindow'
import {TooltipPosition} from './Tooltip'

export interface TooltipWindowPassthroughProps {
    position: TooltipPosition
    title: string
    maxWidth?: number
    offset?: number
    color?: 'primary'
    size?: 'small' | 'large'
}

interface TargetProps extends TooltipWindowPassthroughProps {
    children: React.ReactElement
    containerStyle?: React.CSSProperties
    enabled?: boolean
    testId?: string
}

export const TooltipTarget: React.FC<TargetProps> = ({
    enabled = true,
    children,
    containerStyle,
    testId,
    ...tooltipWindowProps
}) => {
    const [isHovering, setIsHovering] = useState(false)
    const [targetElement, setTargetElement] = useState<HTMLElement | null>(null)

    const ref = useCallback((element: HTMLElement) => {
        setTargetElement(element)
    }, [])

    const child = React.Children.only(children)

    return (
        <React.Fragment>
            <div
                data-testid={enabled ? testId : undefined}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                style={{
                    display: 'inline-flex',
                    ...containerStyle,
                }}
            >
                {React.cloneElement(child, {
                    ref,
                })}
            </div>
            {enabled && isHovering && <TooltipWindow {...tooltipWindowProps} targetElement={targetElement} />}
        </React.Fragment>
    )
}
