import {gql, useMutation, useQuery} from '@apollo/client'
import {FieldArray, Formik} from 'formik'
import {AnimatedStack, Button, CollapsibleGroup, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import {CollapsibleGroupMethods} from 'nf-ui/Collapsible/CollapsibleGroup'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React, {useRef} from 'react'
import {useAlert} from 'react-alert'
import * as Yup from 'yup'
import {ModalLayout} from '~/components/ModalLayout'
import {PageLoading} from '~/components/PageLoading'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {AdditionalFieldInput, AdditionalFieldType} from '~/globalTypes'
import {getGraphQLErrorMessage} from '~/util'
import {DATA_SOURCES} from '../../Integrations'
import {CollapsibleField} from './CreateStep/CollapsibleField'
import {Field, isSelectField, Option} from './CreateStep/FieldForm'
import {CreateAdditionalFields, CreateAdditionalFieldsVariables} from './__types__/CreateAdditionalFields'
import {EditAdditionalFields, EditAdditionalFieldsVariables} from './__types__/EditAdditionalFields'
import {FetchAdditionalFields, FetchAdditionalFieldsVariables} from './__types__/FetchAdditionalFields'

const DEFAULT_FIELD: Field = {helpText: '', name: '', type: AdditionalFieldType.TEXT}

export const CREATE_FIELDS = gql`
    mutation CreateAdditionalFields($organisationIdStr: String!, $fields: [AdditionalFieldInput!]!) {
        createAdditionalFields(organisationIdStr: $organisationIdStr, fields: $fields) {
            idStr
        }
    }
`

export const UPDATE_FIELDS = gql`
    mutation EditAdditionalFields($organisationIdStr: String!, $fields: [AdditionalFieldInput!]!) {
        updateAdditionalFieildsForm(organisationIdStr: $organisationIdStr, fields: $fields) {
            idStr
        }
    }
`

export const FETCH_FIELDS = gql`
    query FetchAdditionalFields($organisationIdStr: String!) {
        organisation(idStr: $organisationIdStr) {
            idStr
            additionalFields {
                idStr
                name
                type
                helpText
                options {
                    idStr
                    name
                }
            }
        }
    }
`

const validationSchema = Yup.object({
    fields: Yup.array(
        Yup.object<Field>({
            idStr: Yup.string(),
            name: Yup.string()
                .required()
                .min(1),
            helpText: Yup.string(),
            type: Yup.string().required() as Yup.Schema<AdditionalFieldType>,
            options: Yup.array<Option>().when('type', {
                is: isSelectField,
                then: Yup.array(Yup.object({name: Yup.string().required()}))
                    .required()
                    .min(1),
            }) as any,
        }),
    )
        .required()
        .min(1),
})

export const CreateStep = ({onClickNext}: {onClickNext?: () => void}) => {
    const organisationIdStr = useOrganisationIdStr()
    const alert = useAlert()
    const collapsibleGroupRef = useRef<CollapsibleGroupMethods>(null)
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const {data, error, loading} = useQuery<FetchAdditionalFields, FetchAdditionalFieldsVariables>(FETCH_FIELDS, {
        variables: {organisationIdStr},
        fetchPolicy: 'network-only',
    })

    const [createFields] = useMutation<CreateAdditionalFields, CreateAdditionalFieldsVariables>(CREATE_FIELDS, {
        refetchQueries: [{query: DATA_SOURCES, variables: {organisationIdStr}}],
    })

    const [updateFields] = useMutation<EditAdditionalFields, EditAdditionalFieldsVariables>(UPDATE_FIELDS, {
        refetchQueries: [{query: DATA_SOURCES, variables: {organisationIdStr}}],
    })

    const formatFields = (fields: Field[]): AdditionalFieldInput[] => {
        return fields.map(({idStr, helpText, name, type, options}) => ({
            idStr,
            helpText,
            name,
            type,
            options: options?.map((o) => o.name),
        }))
    }

    const filterNewFields = (fields: Field[]): AdditionalFieldInput[] => {
        return fields
            .filter((field) => !field.idStr)
            .map(({helpText, name, type, options}) => ({helpText, name, type, options: options?.map((o) => o.name)}))
    }

    const onSubmit = async ({fields}: {fields: Field[]}) => {
        const newFields = filterNewFields(fields)
        const allFields = formatFields(fields)

        try {
            if (newFields.length) {
                trackAnalyticsEvent('click_create_additional_data_next_step', {numberOfNewFields: newFields.length})
                await createFields({variables: {fields: newFields, organisationIdStr}})
            }
            await updateFields({variables: {fields: allFields, organisationIdStr}})
            onClickNext?.()
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    if (error) {
        return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>
    }

    if (!data || loading) {
        return <PageLoading />
    }

    let initialValues: Field[] = [DEFAULT_FIELD]
    if (data.organisation.additionalFields.length) {
        initialValues = data.organisation.additionalFields.map((additionalField) => {
            return {
                idStr: additionalField.idStr,
                name: additionalField.name,
                helpText: additionalField.helpText || '',
                type: additionalField.type,
                options: additionalField.options || undefined,
            }
        })
    }

    return (
        <Formik
            initialValues={{fields: initialValues}}
            onSubmit={onSubmit}
            validateOnMount
            validationSchema={validationSchema}
        >
            {({handleSubmit, values, isValid, isSubmitting}) => (
                <FieldArray
                    name="fields"
                    render={(fieldsHelpers) => (
                        <form onSubmit={handleSubmit}>
                            <ModalLayout.Body>
                                <Typography.Heading>Add additional data</Typography.Heading>
                                <Spacer height={16} />
                                <Typography.Subheading maxWidth={725}>
                                    Supplement your data by adding additional fields.
                                </Typography.Subheading>
                                <Spacer height={32} />

                                <div style={{width: 704}}>
                                    <CollapsibleGroup ref={collapsibleGroupRef}>
                                        <AnimatedStack space={16}>
                                            {values.fields.map((field, index) => (
                                                <CollapsibleField
                                                    field={field}
                                                    index={index}
                                                    key={index}
                                                    fieldsHelpers={fieldsHelpers}
                                                />
                                            ))}
                                        </AnimatedStack>
                                    </CollapsibleGroup>
                                </div>

                                <Spacer height={24} />
                                <Button
                                    variant="secondary"
                                    onClickAnalyticsEvent="click_add_new_field"
                                    onClick={() => {
                                        fieldsHelpers.push(DEFAULT_FIELD)
                                        collapsibleGroupRef.current?.setExpandedIndex(values.fields.length)
                                    }}
                                >
                                    Add new field <Button.Icon icon={SvgArrowRight} />
                                </Button>
                                <Spacer height={32} />
                            </ModalLayout.Body>
                            <ModalLayout.Footer>
                                <Button loading={isSubmitting} variant="primary" type="submit" disabled={!isValid}>
                                    Next <Button.Icon icon={SvgArrowRight} />
                                </Button>
                            </ModalLayout.Footer>
                        </form>
                    )}
                />
            )}
        </Formik>
    )
}
