import {useState, SetStateAction, Dispatch, useEffect} from 'react'
import {usePrevious} from './usePrevious'

function wait(ms = 50) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export type UseDelayedSetOptions = {
    waitTime?: number
}

export function useDelayedSet<T>(value: T, setValue: Dispatch<SetStateAction<T>>, options: UseDelayedSetOptions = {}) {
    const {waitTime = 30} = options
    const [delValue, setDelValue] = useState(value)
    const prevValue = usePrevious(value)

    useEffect(() => {
        if (value === prevValue) return
        setDelValue(value)
    }, [value, prevValue])

    useEffect(() => {
        if (delValue === value) return
        let shouldSet = true
        wait(waitTime).then(() => {
            if (!shouldSet) return
            setValue(() => delValue)
        })
        return () => {
            shouldSet = false
        }
    }, [delValue, value, setValue, waitTime])

    return [delValue, setDelValue] as const
}
