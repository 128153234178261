import React from 'react'
import styled from 'styled-components'

export const MatchText = styled.span<{fontWeight?: number | string}>`
    color: ${({theme}) => theme.primary.color};
    font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : 'unset')};
`

export const highlightMatchedText = (content: string, match?: string | null, fontWeight?: number | string) => {
    if (!match || match.trim().length === 0) return <>{content}</>

    const lowerCaseContent = content.toLowerCase()
    const lowerCaseMatch = match.toLowerCase()
    const startIndex = lowerCaseContent.indexOf(lowerCaseMatch)

    if (startIndex === -1) {
        return content
    }

    const first = content.substring(0, startIndex)
    const matchText = content.substring(startIndex, startIndex + lowerCaseMatch.length)
    const last = content.substring(startIndex + lowerCaseMatch.length)

    return (
        <>
            {first}
            <MatchText fontWeight={fontWeight}>{matchText}</MatchText>
            {last}
        </>
    )
}
