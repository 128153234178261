import {ApolloError, FetchResult, gql, MutationTuple, useMutation, useQuery} from '@apollo/client'
import {useAlert} from 'react-alert'
import {DraftProfileInput, RegionInput} from '~/objectTypes'
import {getGraphQLErrorMessage} from '~/util'
import {GiveCommunityBuildConsent, GiveCommunityBuildConsentVariables} from './__types__/GiveCommunityBuildConsent'
import {Responses, ResponsesVariables} from './__types__/Responses'
import {
    UpdateCommunityBuildDraftProfile,
    UpdateCommunityBuildDraftProfileVariables,
} from './__types__/UpdateCommunityBuildDraftProfile'
import {UploadCommunityBuildPhotos, UploadCommunityBuildPhotosVariables} from './__types__/UploadCommunityBuildPhotos'

const RESPONSES = gql`
    query Responses($formIdStr: String!, $emailAddress: String!) {
        responses(formIdStr: $formIdStr, emailAddress: $emailAddress) {
            idStr
            formIdStr
            emailAddresses
            hasGivenConsent
            data {
                responseIdStr
                draftProfile {
                    responseIdStr
                    name
                    value
                }
                originalFileIdStr
                thumbFileIdStr
            }
        }
    }
`

const UPDATE_COMMUNITY_BUILD_DRAFT_PROFILE = gql`
    mutation UpdateCommunityBuildDraftProfile(
        $responseIdStr: String!
        $emailAddress: String!
        $draftProfile: [DraftProfileInput!]!
    ) {
        updateCommunityBuildDraftProfile(
            responseIdStr: $responseIdStr
            emailAddress: $emailAddress
            draftProfile: $draftProfile
        )
    }
`

const UPLOAD_COMMUNITY_BUILD_PHOTOS = gql`
    mutation UploadCommunityBuildPhotos(
        $responseIdStr: String!
        $emailAddress: String!
        $originalFile: Upload!
        $thumbRegion: RegionInput
        $photoIndex: Int
    ) {
        uploadCommunityBuildPhotos(
            responseIdStr: $responseIdStr
            emailAddress: $emailAddress
            originalFile: $originalFile
            thumbRegion: $thumbRegion
            photoIndex: $photoIndex
        )
    }
`

const GIVE_COMMUNITY_BUILD_CONSENT = gql`
    mutation GiveCommunityBuildConsent($responseIdStr: String!, $emailAddress: String!) {
        giveCommunityBuildConsent(responseIdStr: $responseIdStr, emailAddress: $emailAddress)
    }
`

export const useQueryResponses = (formIdStr: string, emailAddress: string) => {
    return [
        useQuery<Responses, ResponsesVariables>(RESPONSES, {
            variables: {
                formIdStr,
                emailAddress,
            },
        }),
    ]
}

export const useMutationUpdateCommunityBuildDraftProfile = (): [
    MutationTuple<UpdateCommunityBuildDraftProfile, UpdateCommunityBuildDraftProfileVariables>,
    (variables: {
        responseIdStr: string
        emailAddress: string
        draftProfile: DraftProfileInput[]
    }) => Promise<FetchResult<UpdateCommunityBuildDraftProfile, Record<string, any>, Record<string, any>>>,
] => {
    const alert = useAlert()
    const mutation = useMutation<UpdateCommunityBuildDraftProfile, UpdateCommunityBuildDraftProfileVariables>(
        UPDATE_COMMUNITY_BUILD_DRAFT_PROFILE,
        {},
    )
    const callMutation = async (variables: {
        responseIdStr: string
        emailAddress: string
        draftProfile: DraftProfileInput[]
    }) => {
        try {
            const result = await mutation[0]({variables})
            return result
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}

export const useMutationUploadCommunityBuildPhotos = (): [
    MutationTuple<UploadCommunityBuildPhotos, UploadCommunityBuildPhotosVariables>,
    (variables: {
        responseIdStr: string
        emailAddress: string
        originalFile: File
        thumbRegion?: RegionInput
        photoIndex?: number
    }) => Promise<FetchResult<UploadCommunityBuildPhotos, Record<string, any>, Record<string, any>>>,
] => {
    const alert = useAlert()
    const mutation = useMutation<UploadCommunityBuildPhotos, UploadCommunityBuildPhotosVariables>(
        UPLOAD_COMMUNITY_BUILD_PHOTOS,
        {},
    )

    const callMutation = async (variables: {
        responseIdStr: string
        emailAddress: string
        originalFile: File
        thumbRegion?: RegionInput
        photoIndex?: number
    }) => {
        try {
            const result = await mutation[0]({variables})
            return result
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}

export const useMutationGiveCommunityBuildConsent = (): [
    MutationTuple<GiveCommunityBuildConsent, GiveCommunityBuildConsentVariables>,
    (variables: {
        responseIdStr: string
        emailAddress: string
    }) => Promise<FetchResult<GiveCommunityBuildConsent, Record<string, any>, Record<string, any>>>,
] => {
    const alert = useAlert()
    const mutation = useMutation<GiveCommunityBuildConsent, GiveCommunityBuildConsentVariables>(
        GIVE_COMMUNITY_BUILD_CONSENT,
        {},
    )
    const callMutation = async (variables: {responseIdStr: string; emailAddress: string}) => {
        try {
            const result = await mutation[0]({variables})
            return result
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}
