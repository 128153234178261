import {FadePresence, Spacer, Typography} from 'nf-ui'
import React, {FC, useEffect} from 'react'
import {BackButton} from '~/components/BackButton'
import {PageLoading} from '~/components/PageLoading'
import {useSendResetPasswordEmail} from './useSendResetPasswordEmail'

export type RequestSetPasswordProps = {
    email: string
    onClickBack: () => void
}

export const RequestSetPassword: FC<RequestSetPasswordProps> = ({email, onClickBack}) => {
    const {sendEmail, loading} = useSendResetPasswordEmail()

    useEffect(() => {
        sendEmail({variables: {email}})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <FadePresence exitBeforeEnter itemKey={loading ? 'loading' : 'email'}>
            {loading ? (
                <PageLoading />
            ) : (
                <>
                    <Typography.Heading>
                        <BackButton onClick={onClickBack} />
                        Check your inbox
                    </Typography.Heading>
                    <Spacer height={16} />
                    <Typography.Subheading maxWidth={520}>
                        We’ve sent a new email to {email}. Please, click the link to set your password.
                    </Typography.Subheading>
                </>
            )}
        </FadePresence>
    )
}
