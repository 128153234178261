import {ACTION_BAR_HEIGHT, Spacer} from 'nf-ui'
import pluralize from 'pluralize'
import React, {useRef} from 'react'
import styled from 'styled-components'
import {useQueryAllHomeItemData} from '~/apollo/queries/AllHomeItemData'
import {CategoryData} from '~/apollo/queries/__types__/CategoryData'
import {HorizontalProfilesList} from '~/components/HorizontalProfilesList'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {ActionBarSizer, UserlandActionBar} from './ActionBar'
import {ChildCategory} from './ChildCategory'

const profileListPadding = 32

const Container = styled.div`
    position: relative;
    height: 100vh;
`

const ScrollContainer = styled.div`
    position: relative;
    padding: 32px;
    padding-bottom: calc(64px + ${ACTION_BAR_HEIGHT}px);
    overflow-y: auto;
    height: 100vh;
    box-sizing: border-box;
`

type Category = CategoryData['category']

export const CategoryChildren = ({category}: {category: Category}) => {
    const ScrollContainerRef = useRef<HTMLDivElement>(null)
    const organisationIdStr = useOrganisationIdStr()
    const [{data: allHomeItemData}] = useQueryAllHomeItemData(organisationIdStr)
    const homeItem = allHomeItemData?.organisation?.homeItems?.find((item) => item.sourceIdStr === category.idStr)
    const label = homeItem?.label || ''

    if (homeItem?.displayMode === 'LIST') {
        return <ChildCategory childCategoryIdStr={category.idStr}></ChildCategory>
    }

    const profileCount = category.profilesCount
    const childCategoryCount = category.childCategories?.length || 0

    const actionBarContent = `${profileCount} ${pluralize('person', profileCount)} in ${label}`

    return (
        <ActionBarSizer>
            <Container>
                <ScrollContainer ref={ScrollContainerRef}>
                    {category.childCategories
                        ?.filter((childCategory) => childCategory.profiles.length)
                        ?.map((childCategory, index) => (
                            <React.Fragment key={childCategory.idStr}>
                                <HorizontalProfilesList
                                    key={childCategory.idStr}
                                    label={childCategory.label}
                                    profiles={childCategory.profiles}
                                    padding={profileListPadding}
                                    childCategoryIdStr={childCategory.idStr}
                                />
                                {index !== childCategoryCount - 1 && (
                                    <Spacer key={'s' + childCategory.idStr} height={32} />
                                )}
                            </React.Fragment>
                        ))}
                </ScrollContainer>
                <UserlandActionBar label={actionBarContent} showGroupMessage={false} />
            </Container>
        </ActionBarSizer>
    )
}
