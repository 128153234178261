import * as React from 'react'
const SvgScenariosRegular = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgScenariosRegular(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" ref={svgRef} {...props}>
            <path
                d="M13 16H6.3c-1.583 0-2.903-1.264-2.995-2.844L3.3 12.98v-.3H3c-1.584 0-2.903-1.264-2.995-2.843L0 9.66V3.02C0 1.42 1.263.097 2.825.005L3-.001h6.6c1.666 0 3.002 1.252 3.095 2.842l.005.178v.3h.3c1.584 0 2.903 1.265 2.995 2.844l.005.176v6.64c0 1.602-1.263 2.924-2.825 3.016L13 16zm-3.4-4.38c1.093 0 1.922-.78 1.995-1.812l.005-.149V3.02c0-1.053-.78-1.881-1.846-1.954L9.6 1.059H3c-1.028 0-1.916.81-1.994 1.815L1 3.02v6.64c0 1.024.841 1.88 1.854 1.955L3 11.62h6.6zm3.4 3.32c1.012 0 1.82-.794 1.894-1.807l.006-.153V6.34c0-1.034-.76-1.877-1.75-1.955L13 4.38h-.3v5.28c0 1.615-1.276 2.924-2.916 3.015l-.184.006H4.4v.3c0 1.033.76 1.876 1.75 1.954l.15.006H13z"
                fill="#101010"
            />
        </svg>
    )
})
export default SvgScenariosRegular
