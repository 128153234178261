import {colors, Panel} from 'nf-ui'
import SvgCog from 'nf-ui/Icons/Cog'
import SvgEditThin from 'nf-ui/Icons/EditThin'
import SvgSettingsRegular from 'nf-ui/Icons/SettingsRegular'
import SvgSwitcher from 'nf-ui/Icons/Switcher'
import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {SortField} from '~/apollo/queries/DataEditValue'
import {PanelLayout} from '~/components/PanelLayout'
import {usePanelNavigator} from '~/components/PanelNavigator'
import {Menu, MenuEntry} from 'nf-ui/Menu'
import SvgDelete from 'nf-ui/Icons/Delete'
import {uniq} from 'lodash'
import {Field} from './useData'
import {convertDatesToYYYYMMDD} from '~/util/date'

const HeaderIcon = styled.div<{columnIsSelected?: boolean}>`
    position: absolute;
    display: none;
    padding: 3px 3px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => (props.columnIsSelected ? '3c8eff' : '#efeff1')};
`

export interface ManageColumnProps {
    hoverColumn: number
    otherMouseGesture: {buttons: number; type: string} | undefined
    columns: {
        idStr: string
        name: string
        type: string
        subTypes: string[]
        isSelected: boolean
        availableValues?: string[]
    }[]
    getCellBounds: (col: number, row: number) => {x: number; y: number; width: number; height: number} | undefined
    updateField: (
        col: number,
        field: Partial<Field>,
        valueMutator?: (values: string[]) => (string | undefined)[],
    ) => Promise<void>
    sortField: SortField | undefined
    setSortField: (value: SortField) => void
    onDeleteColumn: (colIndex: number) => Promise<void>
}

const buildMenu: (column: ManageColumnProps['columns'][0]) => MenuEntry[] = (column) => {
    return [
        {
            id: 'rename',
            label: 'Rename',
            behaviours: ['enter'],
            actions: [{icon: SvgEditThin}],
        },
        {
            id: `rename|heading`,
            behaviours: ['heading'],
            label: 'Rename this column',
        },
        {
            id: `rename|edit`,
            values: [column.name || ''],
            behaviours: ['single-line-edit'],
        },
        {
            id: `rename|submit`,
            label: 'Rename',
            behaviours: ['tertiary-button'],
        },
        {
            id: 'changeFieldType',
            label: 'Change field type',
            values: [column.type],
            behaviours: ['enter'],
            actions: [{icon: SvgSettingsRegular}],
        },
        {
            id: `changeFieldType|heading`,
            label: 'Select a type',
            behaviours: ['heading'],
        },
        {
            id: `changeFieldType|text`,
            label: 'Text',
            values: column?.type === 'text' && column.subTypes.includes('rows=3') ? ['paragraph'] : ['singleLine'],
            behaviours: ['pick-one', 'enter'],
            actions: [{icon: SvgCog, iconSize: 24}],
        },
        {
            id: `changeFieldType|text|heading`,
            label: 'Configure text field',
            behaviours: ['heading'],
        },
        {
            id: `changeFieldType|text|singleLine`,
            label: 'Single Line',
            behaviours: ['pick-one'],
        },
        {
            id: `changeFieldType|text|paragraph`,
            label: 'Paragraph',
            behaviours: ['pick-one'],
        },
        {
            id: `changeFieldType|category`,
            label: 'Category',
            values: [
                ...(column.type === 'category' && column.subTypes.includes('freeText') ? ['freeText'] : []),
                ...(column.type === 'category' && column.subTypes.includes('multipleValues') ? ['multipleValues'] : []),
            ],
            behaviours: ['pick-one', 'enter'],
            actions: [{icon: SvgCog, iconSize: 24}],
        },
        {
            id: `changeFieldType|category|heading`,
            label: 'Configure category field',
            behaviours: ['heading'],
        },
        {
            id: `changeFieldType|category|freeText`,
            label: 'Users can add values',
            behaviours: ['switch'],
        },
        {
            id: `changeFieldType|category|multipleValues`,
            label: 'Users can select multiple values',
            behaviours: ['switch'],
        },
        {
            id: `changeFieldType|category|manageOptions`,
            label: 'Manage options',
            behaviours: ['enter'],
        },
        {
            id: `changeFieldType|category|manageOptions|heading`,
            label: 'Manage category options',
            behaviours: ['heading', 'sticky'],
        },
        ...(column.subTypes.includes('orderCustom')
            ? column.availableValues || []
            : (column.availableValues || []).sort()
        ).flatMap((value) => [
            {
                id: `changeFieldType|category|manageOptions|${value}`,
                label: value,
                behaviours: ['draggable', 'enter'] as MenuEntry['behaviours'],
                actions: [{icon: SvgEditThin}, {id: 'delete', icon: SvgDelete}],
            },
            {
                id: `changeFieldType|category|manageOptions|${value}|heading`,
                label: `Edit ${value}`,
                behaviours: ['heading'] as MenuEntry['behaviours'],
            },
            {
                id: `changeFieldType|category|manageOptions|${value}|edit`,
                values: [value],
                behaviours: ['single-line-edit'] as MenuEntry['behaviours'],
            },
            {
                id: `changeFieldType|category|manageOptions|${value}|submit`,
                label: 'Rename',
                behaviours: ['tertiary-button'] as MenuEntry['behaviours'],
            },
        ]),
        {
            id: `changeFieldType|category|manageOptions|addButton`,
            label: 'Add',
            behaviours: ['tertiary-button', 'sticky', 'enter'],
        },
        {
            id: 'changeFieldType|category|manageOptions|addButton|heading',
            label: 'Add category option',
            behaviours: ['heading'],
        },
        {
            id: `changeFieldType|category|manageOptions|addButton|edit`,
            behaviours: ['single-line-edit'],
        },
        {
            id: `changeFieldType|category|manageOptions|addButton|submit`,
            label: 'Add',
            behaviours: ['tertiary-button'],
        },
        ...(column.subTypes.includes('orderCustom')
            ? [
                  {
                      id: 'changeFieldType|category|manageOptions|resetOrderButton',
                      label: 'Use alphabetic order',
                      behaviours: ['tertiary-button', 'sticky'] as MenuEntry['behaviours'],
                  },
              ]
            : []),
        {
            id: 'changeFieldType|date',
            label: 'Date',
            behaviours: ['pick-one'],
        },
        {
            id: 'changeFieldType|firstName',
            label: 'First name',
            behaviours: ['pick-one'],
        },
        {
            id: 'changeFieldType|lastName',
            label: 'Last name',
            behaviours: ['pick-one'],
        },
        {
            id: 'changeFieldType|email',
            label: 'Email',
            values: column?.type === 'email' && column.subTypes.includes('giveAccess') ? ['giveAccess'] : [],
            behaviours: ['pick-one', 'enter'],
            actions: [{icon: SvgCog, iconSize: 24}],
        },
        {
            id: 'changeFieldType|email|heading',
            label: 'Configure email field',
            behaviours: ['heading'],
        },
        {
            id: 'changeFieldType|email|giveAccess',
            label: 'Give user access',
            behaviours: ['switch'],
        },
        ...(['category', 'text', 'email'].includes(column.type)
            ? [
                  {
                      id: 'settings',
                      label: 'Settings',
                      behaviours: ['enter'] as MenuEntry['behaviours'],
                      actions: [{icon: SvgSettingsRegular}],
                  },
              ]
            : []),
        {
            id: 'deleteColumn',
            label: 'Delete this column',
            behaviours: ['enter'],
            actions: [{icon: SvgDelete}],
        },
        {
            id: `deleteColumn|heading`,
            label: 'Confirm delete?',
            behaviours: ['heading'],
        },
        {
            id: `deleteColumn|submit`,
            label: 'Delete',
            behaviours: ['tertiary-button'],
        },
    ]
}

const patchMenuEntry = (menuEntries: MenuEntry[], patches: Partial<MenuEntry>[]) => {
    return menuEntries.map((entry) => {
        const patch = patches.find((patch) => patch.id === entry.id)
        return patch ? {...entry, ...patch} : entry
    })
}

const deleteMenuEntry = (menuEntries: MenuEntry[], menuEntryIds: string[]) => {
    return menuEntries.filter((entry) => !menuEntryIds.includes(entry.id))
}

export const ManageColumn = ({
    hoverColumn,
    otherMouseGesture,
    columns,
    getCellBounds,
    updateField,
    sortField,
    setSortField,
    onDeleteColumn,
}: ManageColumnProps) => {
    const headerMenuButtonRef = useRef<HTMLDivElement>(null)
    const {openPanel, panelProps, closePanel} = usePanelNavigator('headerMenu')

    const [activeColumn, setActiveColumn] = useState<number>(hoverColumn)
    const [currentParentMenuEntryId, setCurrentParentMenuEntryId] = useState<string>('')
    const [menuEntries, setMenuEntries] = useState<MenuEntry[]>([])
    const [usedSettingsShortcut, setUsedSettingsShortcut] = useState<boolean>(false)

    const moveHeaderButtons = () => {
        const sortElement = document.getElementById('headerSortButton')
        const menuElement = document.getElementById('headerMenuButton')
        if (!menuElement || !sortElement) return
        const column = panelProps.open ? activeColumn : hoverColumn
        if (column < 0) {
            sortElement.style['display'] = 'none'
            menuElement.style['display'] = 'none'
            return
        }
        const bounds = getCellBounds(column, -1)
        if (!bounds) {
            sortElement.style['display'] = 'none'
            menuElement.style['display'] = 'none'
            return
        }

        sortElement.style['top'] = `${bounds.y + 3}px`
        sortElement.style['left'] = `${bounds.x + bounds.width - 300}px`
        sortElement.style['display'] = 'flex'

        menuElement.style['top'] = `${bounds.y + 3}px`
        menuElement.style['left'] = `${bounds.x + bounds.width - 280}px`
        menuElement.style['display'] = 'flex'
    }

    const dismissPopup = () => {
        setActiveColumn(hoverColumn)
        closePanel()
        setMenuEntries([])
        setCurrentParentMenuEntryId('')
    }

    const openMenu = () => {
        openPanel('headerMenu', {itemId: 'headerMenu'})
    }

    const updateFieldSorting = () => {
        const field = columns[activeColumn]
        setSortField({
            idStr: field.idStr,
            direction: sortField?.idStr === field.idStr && sortField?.direction !== 'desc' ? 'desc' : 'asc',
        })
    }

    const lastHoverColumn = useRef(-1)
    useEffect(() => {
        if (hoverColumn === lastHoverColumn.current) return
        lastHoverColumn.current = hoverColumn
        if (!panelProps.open) {
            moveHeaderButtons()
            if (hoverColumn >= 0) {
                setActiveColumn(hoverColumn)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoverColumn, panelProps])

    useEffect(() => {
        moveHeaderButtons()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panelProps])

    useEffect(() => {
        if (hoverColumn < 0 && !panelProps.open) return
        if (otherMouseGesture?.buttons) {
            moveHeaderButtons()
        }
        if (otherMouseGesture?.type === 'wheel') {
            moveHeaderButtons()
            for (let t = 10; t <= 1000; t += 10) setTimeout(() => moveHeaderButtons(), t)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherMouseGesture])

    useEffect(() => {
        setMenuEntries(columns[activeColumn] ? buildMenu(columns[activeColumn]) : [])
        setCurrentParentMenuEntryId('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeColumn])

    const isSelected = columns[activeColumn]?.isSelected

    const gotoParentMenu = async () => {
        if (usedSettingsShortcut) {
            setCurrentParentMenuEntryId(
                currentParentMenuEntryId
                    .split('|')
                    .slice(0, -2)
                    .join('|'),
            )
            setUsedSettingsShortcut(false)
        } else {
            setCurrentParentMenuEntryId(
                currentParentMenuEntryId
                    .split('|')
                    .slice(0, -1)
                    .join('|'),
            )
        }
    }

    const completeRename = async () => {
        const value = menuEntries.find((entry) => entry.id === `${currentParentMenuEntryId}|edit`)?.values?.[0] || ''
        if (!value) return
        if (currentParentMenuEntryId === 'rename') {
            setMenuEntries(patchMenuEntry(menuEntries, [{id: `${currentParentMenuEntryId}|submit`, loading: true}]))
            await updateField(activeColumn, {name: value})
            setMenuEntries(patchMenuEntry(menuEntries, [{id: `${currentParentMenuEntryId}|submit`, loading: false}]))
            await gotoParentMenu()
        }
        if (currentParentMenuEntryId === 'changeFieldType|category|manageOptions|addButton') {
            setMenuEntries(patchMenuEntry(menuEntries, [{id: `${currentParentMenuEntryId}|submit`, loading: true}]))
            const availableValues = [...(columns[activeColumn]?.availableValues || []), value]
            await updateField(activeColumn, {availableValues})
            setMenuEntries(buildMenu({...columns[activeColumn], availableValues}))
            await gotoParentMenu()
        } else if (currentParentMenuEntryId.startsWith('changeFieldType|category|manageOptions|')) {
            setMenuEntries(patchMenuEntry(menuEntries, [{id: `${currentParentMenuEntryId}|submit`, loading: true}]))
            const availableValues = (columns[activeColumn]?.availableValues || []).map((option) =>
                currentParentMenuEntryId.endsWith(`|${option}`) ? value : option,
            )
            await updateField(activeColumn, {availableValues}, (values) =>
                values.map((v) => (currentParentMenuEntryId.endsWith(`|${v}`) ? value : v)),
            )
            setMenuEntries(buildMenu({...columns[activeColumn], availableValues}))
            await gotoParentMenu()
        }
    }

    const patchSubTypes = (
        subTypes: string[],
        {add, remove, toggle}: {add?: string[]; remove?: string[]; toggle?: string[]},
    ) => {
        const toggled = [
            ...subTypes.filter((subType) => !toggle?.includes(subType)),
            ...(toggle || []).filter((t) => !subTypes.includes(t)),
        ]
        return uniq([...toggled.filter((part) => !remove?.includes(part)), ...(add || [])])
    }

    const updateValues = async (menuEntryId: string, values: string[]) => {
        const currentValues = menuEntries.find((entry) => entry.id === menuEntryId)?.values || []
        const updateFunc =
            currentParentMenuEntryId === 'changeFieldType'
                ? () =>
                      updateField(
                          activeColumn,
                          {
                              type: values[0],
                              subTypes: [],
                              availableValues: [],
                          },
                          values.includes('date') ? convertDatesToYYYYMMDD : undefined,
                      )
                : currentParentMenuEntryId === 'changeFieldType|category'
                ? () =>
                      updateField(activeColumn, {
                          subTypes: patchSubTypes(currentValues, {
                              add: [
                                  values.includes('freeText') ? 'freeText' : 'locked',
                                  values.includes('multipleValues') ? 'multipleValues' : 'singleValue',
                              ],
                              remove: [
                                  values.includes('freeText') ? 'locked' : 'freeText',
                                  values.includes('multipleValues') ? 'singleValue' : 'multipleValues',
                              ],
                          }),
                      })
                : currentParentMenuEntryId === 'changeFieldType|text'
                ? () => updateField(activeColumn, {subTypes: [values.includes('paragraph') ? 'rows=3' : 'rows=1']})
                : currentParentMenuEntryId === 'changeFieldType|email'
                ? () =>
                      updateField(activeColumn, {subTypes: [values.includes('giveAccess') ? 'giveAccess' : 'noAccess']})
                : undefined

        if (!updateFunc) return

        setMenuEntries(patchMenuEntry(menuEntries, [{id: menuEntryId, loading: true}]))
        await updateFunc()
        setMenuEntries(
            patchMenuEntry(menuEntries, [
                {id: currentParentMenuEntryId, values},
                {id: menuEntryId, loading: false},
            ]),
        )
    }

    const handleAction = async (menuEntryId: string, actionId?: string) => {
        if (!actionId) {
            setCurrentParentMenuEntryId(menuEntryId)
        } else if (currentParentMenuEntryId === 'changeFieldType|category|manageOptions' && actionId === 'delete') {
            setMenuEntries(patchMenuEntry(menuEntries, [{id: menuEntryId, loading: true}]))
            const optionToDelete = menuEntryId.split('|').slice(-1)[0]
            await updateField(activeColumn, {
                availableValues: (columns[activeColumn]?.availableValues || []).filter(
                    (value) => value !== optionToDelete,
                ),
            })
            setMenuEntries(deleteMenuEntry(menuEntries, [menuEntryId]))
        }
    }

    const resetCategoryOrder = async (menuEntryId: string) => {
        setMenuEntries(patchMenuEntry(menuEntries, [{id: menuEntryId, loading: true}]))
        const availableValues = (columns[activeColumn]?.availableValues || []).sort()
        const subTypes = patchSubTypes(columns[activeColumn].subTypes, {remove: ['orderCustom']})
        await updateField(activeColumn, {availableValues, subTypes})
        setMenuEntries(
            patchMenuEntry(buildMenu({...columns[activeColumn], availableValues, subTypes}), [
                {id: menuEntryId, loading: false},
            ]),
        )
    }

    const handleOrderChanged = async (menuEntryIds: string[], menuEntryId: string) => {
        // console.log('handleOrderChanged', menuEntryIds, menuEntryId)
        const availableValues = menuEntryIds.map((id) => id.split('|').slice(-1)[0])
        const subTypes = patchSubTypes(columns[activeColumn]?.subTypes, {add: ['orderCustom']})
        setMenuEntries(
            patchMenuEntry(buildMenu({...columns[activeColumn], availableValues, subTypes}), [
                {id: menuEntryId, loading: true},
            ]),
        )
        await updateField(activeColumn, {availableValues, subTypes})
        setMenuEntries(
            patchMenuEntry(buildMenu({...columns[activeColumn], availableValues, subTypes}), [
                {id: menuEntryId, loading: false},
            ]),
        )
    }

    const [position, setPosition] = useState<{left: number; top: number} | undefined>()

    return (
        <>
            <HeaderIcon
                id="headerSortButton"
                onClick={updateFieldSorting}
                columnIsSelected={columns[activeColumn]?.isSelected}
            >
                <SvgSwitcher
                    width={20}
                    height={20}
                    color={isSelected ? '#FFFFFF' : colors.primary['100']}
                ></SvgSwitcher>
            </HeaderIcon>
            <HeaderIcon
                id="headerMenuButton"
                ref={headerMenuButtonRef}
                onClick={openMenu}
                columnIsSelected={columns[activeColumn]?.isSelected}
            >
                <SvgCog width={24} height={24} color={isSelected ? '#FFFFFF' : colors.primary['100']}></SvgCog>
            </HeaderIcon>
            <Panel
                targetRef={headerMenuButtonRef}
                {...panelProps}
                onClose={() => dismissPopup()}
                onPositionChanged={({top, left}) => {
                    const newLeft = parseFloat(left.replace('px', ''))
                    const newTop = parseFloat(top.replace('px', ''))
                    if (position?.left !== newLeft || position?.top !== newTop)
                        setPosition({left: newLeft, top: newTop})
                }}
                noChildScroll={true}
            >
                <PanelLayout key="headerMenu">
                    <Menu
                        position={position}
                        currentParentMenuEntryId={currentParentMenuEntryId}
                        menuEntries={menuEntries}
                        handlers={{
                            onEnter: async (id) => {
                                if (id === 'settings') {
                                    setUsedSettingsShortcut(true)
                                    setCurrentParentMenuEntryId(`changeFieldType|${columns[activeColumn].type}`)
                                } else {
                                    if (!id.startsWith('changeFieldType|')) {
                                        setUsedSettingsShortcut(false)
                                    }
                                    setCurrentParentMenuEntryId(id)
                                }
                            },
                            onBack: gotoParentMenu,
                            onTextChanged: async (id, value) =>
                                setMenuEntries(
                                    patchMenuEntry(menuEntries, [
                                        {id, values: [value]},
                                        {id: `${currentParentMenuEntryId}|submit`, disabled: !value},
                                    ]),
                                ),
                            onEditCompleted: () => completeRename(),
                            onButtonClicked: async (menuEntryId) => {
                                if (
                                    menuEntries.find((entry) => entry.id === menuEntryId)?.behaviours?.includes('enter')
                                ) {
                                    setCurrentParentMenuEntryId(menuEntryId)
                                } else if (menuEntryId === 'changeFieldType|category|manageOptions|resetOrderButton') {
                                    await resetCategoryOrder(menuEntryId)
                                } else if (menuEntryId === 'deleteColumn|submit') {
                                    dismissPopup()
                                    await onDeleteColumn(activeColumn)
                                } else {
                                    await completeRename()
                                }
                            },
                            onValuesChanged: updateValues,
                            onAction: handleAction,
                            onOrderChanged: handleOrderChanged,
                        }}
                    ></Menu>
                </PanelLayout>
            </Panel>
        </>
    )
}
