import {gql, useMutation} from '@apollo/client'
import {cloneDeep} from '@apollo/client/utilities'
import {Formik} from 'formik'
import produce from 'immer'
import {Button, Form, FormikSwitch, Lightbox, Spacer, Typography} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import React, {useState} from 'react'
import {useAlert} from 'react-alert'
import styled from 'styled-components'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {getGraphQLErrorMessage} from '~/util'
import {USERS_DATA} from '../Users'
import {UsersPageData, UsersPageDataVariables} from '../__types__/UsersPageData'
import {
    AutoInviteLightbox_DisableAutoInvite,
    AutoInviteLightbox_DisableAutoInviteVariables,
} from './__types__/AutoInviteLightbox_DisableAutoInvite'
import {
    AutoInviteLightbox_EnableAutoInvite,
    AutoInviteLightbox_EnableAutoInviteVariables,
} from './__types__/AutoInviteLightbox_EnableAutoInvite'

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ENABLE_AUTO_INVITE = gql`
    mutation AutoInviteLightbox_EnableAutoInvite($organisationIdStr: String!) {
        updateAutoInvite(organisationIdStr: $organisationIdStr, autoInvite: true) {
            idStr
            autoInvite
        }

        invitePendingUsers(organisationIdStr: $organisationIdStr) {
            idStr
            firstName
            lastName
            email
            role
            inviteStatus
            isRevoked
        }
    }
`

const DISABLE_AUTO_INVITE = gql`
    mutation AutoInviteLightbox_DisableAutoInvite($organisationIdStr: String!) {
        updateAutoInvite(organisationIdStr: $organisationIdStr, autoInvite: false) {
            idStr
            autoInvite
        }
    }
`

const noOp = () => {}

type AutoInviteLightboxProps = {
    open: boolean
    onClose: () => void
    autoInvite: boolean
}

export const AutoInviteLightbox: React.FC<AutoInviteLightboxProps> = ({
    autoInvite: currentAutoInvite,
    open,
    onClose: onCloseProp,
}) => {
    const organisationIdStr = useOrganisationIdStr()
    const alert = useAlert()
    const [changed, setChanged] = useState(false)
    const onLightboxClose = () => {
        onCloseProp()
        setChanged(false)
    }

    const [enableAutoInvite, enableAutoInviteStatus] = useMutation<
        AutoInviteLightbox_EnableAutoInvite,
        AutoInviteLightbox_EnableAutoInviteVariables
    >(ENABLE_AUTO_INVITE, {
        variables: {organisationIdStr},
        onError: (error) => alert.error(getGraphQLErrorMessage(error)),
        onCompleted: () => {
            alert.success('Auto invite turned on')
            onCloseProp()
        },
        update: (cache, {data}) => {
            const usersData = cache.readQuery<UsersPageData, UsersPageDataVariables>({
                query: USERS_DATA,
                variables: {idStr: organisationIdStr},
            })

            if (!data) return

            cache.writeQuery<UsersPageData, UsersPageDataVariables>({
                query: USERS_DATA,
                data: produce(cloneDeep(usersData), (draft) => {
                    if (!draft || !data) return
                    draft.organisation.users = data.invitePendingUsers.map((user) => ({...user, profiles: []}))
                })!,
            })
        },
    })

    const [disableAutoInvite, disableAutoInviteStatus] = useMutation<
        AutoInviteLightbox_DisableAutoInvite,
        AutoInviteLightbox_DisableAutoInviteVariables
    >(DISABLE_AUTO_INVITE, {
        variables: {organisationIdStr},
        onCompleted: () => {
            alert.success('Auto invite turned off')
            onLightboxClose()
        },
    })

    const loading = enableAutoInviteStatus.loading || disableAutoInviteStatus.loading
    const onClose = loading ? noOp : onLightboxClose

    return (
        <Lightbox open={open} onClose={onClose} width={560}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <Typography.Heading>Auto-invite</Typography.Heading>
                <Button variant="tertiary" disabled={loading} onClick={onClose}>
                    Close <Button.Icon icon={SvgClose} />
                </Button>
            </div>
            <Spacer height={16} />
            <Formik
                onSubmit={({autoInvite}) => (autoInvite ? enableAutoInvite() : disableAutoInvite())}
                initialValues={{autoInvite: currentAutoInvite}}
            >
                {({handleSubmit, isSubmitting}) => {
                    return (
                        <Form style={{width: 'unset'}} onSubmit={handleSubmit}>
                            <Form.Group name="autoInvite">
                                <Form.Label>
                                    <FlexWrapper>
                                        <FormikSwitch onClick={() => setChanged(true)} />
                                        <Spacer width={16} />
                                        Automatically send new users an email invitation to your directory
                                    </FlexWrapper>
                                </Form.Label>
                            </Form.Group>
                            <Button type="submit" disabled={!changed} loading={isSubmitting}>
                                Save
                            </Button>
                        </Form>
                    )
                }}
            </Formik>
        </Lightbox>
    )
}
