import {Typography, Spacer, FadePresence, colors} from 'nf-ui'
import React from 'react'
import styled from 'styled-components'
import {PageLoading} from '~/components/PageLoading'
import {ConnectCalendarCard} from '../Meetings/ConnectCalendarCard'
import {MeetingsList} from '../Meetings/MeetingsList'
import {useCalendarConnected} from '../Meetings/useCalendarConnected'
import {useShowMeetings} from '../Meetings/useShowMeetings'
import {useUpcomingMeetings} from '../Meetings/useUpcomingMeetings'
import {InsightPanel} from './InsightPanel'

const SectionHeader = ({label}: {label: string}) => {
    return (
        <>
            <Typography.Label>{label}</Typography.Label>
            <Spacer height={8} />
        </>
    )
}

const ConnectCalendar = () => {
    return (
        <>
            <InsightPanel label="See who's in your next meeting 📅">
                <ConnectCalendarCard />
            </InsightPanel>
        </>
    )
}

const LoadingWrapper = styled.div`
    display: flex;
    height: 200px;
    background-color: ${colors.lightGray};
    position: relative;
    border-radius: 4px;
`

const LoadingContainer = () => {
    return (
        <LoadingWrapper>
            <PageLoading />
        </LoadingWrapper>
    )
}

const MeetingsContent = () => {
    const {setConnected} = useCalendarConnected()
    const {meetingsToday, meetingsThisWeek, error, loading} = useUpcomingMeetings()

    if (error) {
        setConnected(false)
        return null
    }

    const hasMeetingsToday = meetingsToday.length > 0
    const nextMeeting = meetingsThisWeek.length > 0 ? [meetingsThisWeek[0]] : []

    const meetings = hasMeetingsToday ? meetingsToday : nextMeeting
    const sectionHeading =
        hasMeetingsToday || loading ? 'Todays Meetings' : "No more meetings today, see what's coming up next"

    return (
        <>
            <SectionHeader label={sectionHeading} />
            <FadePresence exitBeforeEnter itemKey={loading ? 1 : 0}>
                {loading ? <LoadingContainer /> : <MeetingsList meetings={meetings} />}
            </FadePresence>
        </>
    )
}

const ConnectCalendarContent = () => {
    return (
        <>
            <SectionHeader label="Todays Meetings" />
            <ConnectCalendar />
        </>
    )
}

export const MeetingsPanel = () => {
    const {connected} = useCalendarConnected()
    const {showMeetings} = useShowMeetings()

    if (!showMeetings) return null

    return (
        <>
            <FadePresence exitBeforeEnter itemKey={connected ? 1 : 0}>
                {connected ? <MeetingsContent /> : <ConnectCalendarContent />}
            </FadePresence>
            <Spacer height={8} />
        </>
    )
}
