import React, {ReactElement, useRef} from 'react'
import {useElementSize} from './useElementSize'

type RenderProps = {width: number; height: number}

type Props = {
    disableHeight?: boolean
    children: (props: RenderProps) => ReactElement
}

export const ProfilesGridSizer: React.FC<Props> = ({children, disableHeight = false}) => {
    const ref = useRef<HTMLDivElement>(null)
    const {width, height} = useElementSize(ref)

    return (
        <div style={{width: '100%', height: '100%'}} ref={ref}>
            <div style={{width: 0, height: disableHeight ? 'auto' : 0}}>{children({width, height})}</div>
        </div>
    )
}
