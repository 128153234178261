import {ApolloError, gql, useMutation} from '@apollo/client'
import {Button, colors, Icon, List, Panel, Typography, useAnalyticsEventTracker} from 'nf-ui'
import {useQueryParam} from 'use-query-params'
import Delete from 'nf-ui/Icons/Delete'
import Download from 'nf-ui/Icons/Download'
import EditThin from 'nf-ui/Icons/EditThin'
import OpenLink from 'nf-ui/Icons/OpenLink'
import Return from 'nf-ui/Icons/Return'
import Sync from 'nf-ui/Icons/Sync'
import Upload from 'nf-ui/Icons/Upload'
import pluralize from 'pluralize'
import React, {FC, useRef, useState} from 'react'
import {useAlert} from 'react-alert'
import styled, {css, keyframes} from 'styled-components'
import {PANEL_WIDTH} from '~/constants'
import {DataSourceType} from '~/globalTypes'
import {getGraphQLErrorMessage, isNotFalse} from '~/util'
import {PanelLayout, PanelLayoutButtons} from '../PanelLayout'
import {PanelHeader, usePanelNavigator} from '../PanelNavigator'
import {useCurrentUser} from '../useCurrentUser'
import {useOrganisationIdStr} from '../useOrganisationIdStr'
import {IntegrationCard} from './IntegrationCard'
import {getHRISName, isIntegration} from './integrations'
import ADP from './logos/ADP.png'
import CSV from './logos/CSV.png'
import Workday from './logos/Workday.png'
import GoogleSheets from './logos/GoogleSheets.png'
import Paychex from './logos/Paychex.png'
import Paylocity from './logos/Paylocity.png'
import {useDeleteDataSource} from './useDeleteDataSource'
import {useImportDataSource, usePollDataSyncStatus} from './useImportDataSource'
import {useUploadData} from './useUploadData'
import {DataSourceCard_DataSource} from './__types__/DataSourceCard_DataSource'
import {DownloadRawData, DownloadRawDataVariables} from './__types__/DownloadRawData'
import {useRelativeRoute} from '../useRelativeRoute'
import {CurrentOrganisation} from '../CurrentOrganisationContext'
import {ModalNavigator} from '../ModalLayout/ModalNavigator'
import {ManageFields} from './ManageFields/ManageFields'
import {DefineField} from './ManageFields/DefineField'
import useManageFields from './ManageFields/useManageFields'
import {ImportOptionsInput} from '~/objectTypes'

export const DATA_SOURCE = gql`
    fragment DataSourceCard_DataSource on DataSourceObject {
        idStr
        name
        profileCount
        type
        lastError
        googleSheetUrl
        directUploadFileUrl
        fileType
        latestDatasetUrl
    }
`

export type DataSourceCardProps = Pick<EditOptionsProps, 'dataSource' | 'onReconfigure'> & {
    editLoading?: boolean
    additional: boolean
}

export const DataSourceCard: FC<DataSourceCardProps> = ({
    dataSource,
    onReconfigure,
    editLoading = false,
    additional,
}) => {
    const alert = useAlert()
    const organisationIdStr = useOrganisationIdStr()
    const {panel, openPanel, panelProps, closePanel} = usePanelNavigator(dataSource.idStr)

    const [importDataSource] = useImportDataSource(organisationIdStr)
    const [deleteDataSource, deleteDataSourceStatus] = useDeleteDataSource()
    const uploadData = useUploadData({dataSource, closePanel})

    const {dataSourceSyncing, startPollingDataSyncStatus} = usePollDataSyncStatus(dataSource.idStr)

    const editRef = useRef<HTMLButtonElement>(null)

    const [attemptingSync, setAttemptingSync] = useState(false)

    const resyncDataSource = async (importOptions: ImportOptionsInput) => {
        startPollingDataSyncStatus(4000)
        if (!dataSourceSyncing) {
            setAttemptingSync(true)
            await importDataSource({
                variables: {dataSourceIdStr: dataSource.idStr, importOptions},
            })
            setAttemptingSync(false)
        }
    }

    const {state: manageFields, actions: manageFieldsActions} = useManageFields(
        dataSource.idStr,
        dataSourceSyncing,
        async (importOptions: ImportOptionsInput) => {
            await resyncDataSource(importOptions)
            return
        },
    )

    const [errorResponse, setErrorResponse] = useState('')

    const error = dataSource.lastError || errorResponse

    const cardText = `${dataSource.profileCount}
            ${pluralize('person', dataSource.profileCount)}
            ${dataSource.profileCount === 1 ? 'comes' : 'come'}
            from this source`

    const editPanel = (
        <EditOptions
            key="edit"
            dataSource={dataSource}
            importDataSource={importDataSource}
            onDeleteSelection={() =>
                openPanel('delete', {
                    itemId: dataSource.idStr,
                })
            }
            openFilePicker={uploadData.openPicker}
            onReconfigure={onReconfigure}
            onClose={() => closePanel()}
            additional={additional}
            onManageFields={() => manageFieldsActions.setModal('manageFields')}
        />
    )

    const deletePanel = (
        <DeletePanel
            key="delete"
            onDelete={() => deleteDataSource({variables: {dataSourceIdStr: dataSource.idStr}})}
            onClose={() => closePanel()}
        />
    )

    let logo = GoogleSheets

    switch (dataSource.type) {
        case DataSourceType.ADP:
            logo = ADP
            break
        case DataSourceType.PAYCHEX:
            logo = Paychex
            break
        case DataSourceType.PAYLOCITY:
            logo = Paylocity
            break
        case DataSourceType.DIRECT_UPLOAD:
            logo = CSV
            break
        case DataSourceType.WORKDAY_REPORT:
            logo = Workday
            break
    }
    const label = dataSource.name === dataSource.type ? getHRISName(dataSource.type) : dataSource.name

    return (
        <>
            <IntegrationCard
                logo={logo}
                label={label}
                text={error || cardText}
                topRightContent={
                    <>
                        {dataSource.type !== DataSourceType.DIRECT_UPLOAD && (
                            <SyncButton
                                onClick={async () => {
                                    try {
                                        await resyncDataSource({
                                            processDataset: true,
                                            processDirectory: true,
                                            processUsers: true,
                                            processPhotos: true,
                                        })
                                    } catch (error) {
                                        alert.error(getGraphQLErrorMessage(error as ApolloError))
                                        setErrorResponse(getGraphQLErrorMessage(error as ApolloError))
                                    }
                                }}
                                loading={dataSourceSyncing || attemptingSync}
                            />
                        )}
                        <Button
                            variant="secondary"
                            ref={editRef}
                            onClick={() =>
                                openPanel('edit', {
                                    itemId: dataSource.idStr,
                                })
                            }
                            loading={deleteDataSourceStatus.loading || uploadData.loading || editLoading}
                        >
                            Manage
                        </Button>
                        <Panel targetRef={editRef} {...panelProps}>
                            {panel === 'edit' ? editPanel : deletePanel}
                        </Panel>
                        <input {...uploadData.inputProps} />
                    </>
                }
                onClick={() => {}}
                borderColor={error ? colors.red : undefined}
            />
            <ModalNavigator modal={manageFields.modal} setModal={(modal) => manageFieldsActions.setModal(modal || '')}>
                <ManageFields key="manageFields" state={manageFields} {...manageFieldsActions} />
                <DefineField key="defineField" state={manageFields} {...manageFieldsActions} />
            </ModalNavigator>
        </>
    )
}

const spin = keyframes`
    from {transform: rotate(0);}
    to {transform: rotate(360deg);}
`

const SpinningIcon = styled(Button.Icon)<{isSpinning: boolean}>`
    ${(props: {isSpinning: boolean}) =>
        props.isSpinning &&
        css`
            animation: 2s ${spin} linear infinite;
        `}
`

const SyncButton: React.FC<{onClick: () => void; loading: boolean}> = ({onClick, loading}) => {
    return (
        <Button
            variant="tertiary"
            onClick={async (e) => {
                // Prevent the card's onClick from firing
                e.stopPropagation()
                // Hide the focus state on blur
                e.currentTarget.blur()

                onClick()
            }}
        >
            <SpinningIcon isSpinning={loading} icon={Sync} />
        </Button>
    )
}

type EditOptionsProps = {
    dataSource: DataSourceCard_DataSource
    importDataSource: ReturnType<typeof useImportDataSource>[0]
    onDeleteSelection: () => void
    openFilePicker: () => void
    onReconfigure: () => void
    onClose: () => void
    additional: boolean
    onManageFields: (dataSourceIdStr: string) => void
}

const DOWNLOAD_RAW_DATA = gql`
    mutation DownloadRawData($dataSourceIdStr: String!) {
        downloadLatestRawData(dataSourceIdStr: $dataSourceIdStr)
    }
`

const EditOptions: React.FC<EditOptionsProps> = ({
    dataSource,
    importDataSource,
    onDeleteSelection,
    openFilePicker,
    onReconfigure,
    onClose,
    additional,
    onManageFields,
}) => {
    const currentUser = useCurrentUser()
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const canReconfigure = [DataSourceType.DIRECT_UPLOAD, DataSourceType.GOOGLE_SHEETS].includes(dataSource.type)
    const canDelete = additional

    const alert = useAlert()
    const [downloadRawData, {loading: downloadingRawData}] = useMutation<DownloadRawData, DownloadRawDataVariables>(
        DOWNLOAD_RAW_DATA,
        {
            onError: (error) => {
                alert.error(getGraphQLErrorMessage(error))
                throw error
            },
        },
    )
    const {pushRelative} = useRelativeRoute()

    const organisation = CurrentOrganisation.useContainer()

    return (
        <PanelLayout key="edit">
            <List
                rows={[
                    dataSource.type === DataSourceType.DIRECT_UPLOAD
                        ? {
                              icon: Upload,
                              label: 'Reupload file',
                              onClick: openFilePicker,
                          }
                        : {
                              icon: Sync,
                              label: 'Refresh data',
                              onClick: async () => {
                                  await importDataSource({
                                      variables: {
                                          dataSourceIdStr: dataSource.idStr,
                                          importOptions: {
                                              processDataset: true,
                                              processDirectory: true,
                                              processUsers: true,
                                              processPhotos: true,
                                          },
                                      },
                                  })
                                  onClose()
                              },
                          },
                    Boolean(dataSource.directUploadFileUrl) && {
                        icon: Download,
                        label: 'Download file',
                        onClick: () => {
                            window.open(dataSource.directUploadFileUrl || '', 'blank')
                            onClose()
                        },
                    },
                    Boolean(dataSource.googleSheetUrl) && {
                        icon: OpenLink,
                        label: 'Edit my Google Sheet data',
                        onClick: () => {
                            window.open(dataSource.googleSheetUrl || '', 'blank')
                            onClose()
                        },
                    },
                    canReconfigure && {
                        icon: Return,
                        label: 'Reconfigure data source',
                        onClick: () => {
                            onReconfigure()
                            onClose()
                        },
                    },
                    Boolean(
                        currentUser.me?.isSuperAdmin && !additional && dataSource.type === DataSourceType.GOOGLE_SHEETS,
                    ) && {
                        icon: Return,
                        label: 'Replace data source',
                        onClick: () => {
                            pushRelative('..', {search: {reconnectPrimaryDataSource: 1}})
                            onClose()
                        },
                    },
                    canDelete && {
                        icon: Delete,
                        label: 'Remove data source',
                        onClick: () => onDeleteSelection(),
                    },
                    Boolean(currentUser.me?.isSuperAdmin && dataSource.latestDatasetUrl) && {
                        icon: Download,
                        label: 'Download latest dataset',
                        onClick: () => {
                            window.open(dataSource.latestDatasetUrl!, 'blank')
                            onClose()
                        },
                    },
                    Boolean(currentUser.me?.isSuperAdmin && isIntegration(dataSource.type)) && {
                        icon: Download,
                        label: downloadingRawData ? 'Please wait...' : 'Download latest raw data',
                        onClick: async () => {
                            const result = await downloadRawData({variables: {dataSourceIdStr: dataSource.idStr}})
                            const url = result.data?.downloadLatestRawData
                            if (url) window.open(url, 'blank')
                            onClose()
                        },
                    },
                    Boolean(organisation.currentOrganisation?.appFeatures?.useRulesBasedImport && !additional) && {
                        icon: EditThin,
                        label: 'Manage Fields',
                        onClick: () => {
                            onManageFields(dataSource.idStr)
                            onClose()
                        },
                    },
                ].filter(isNotFalse)}
                onClick={(row) => {
                    trackAnalyticsEvent('select_manage_data', {label: row.label})
                    row.onClick()
                }}
                renderRow={(row) => (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        {row.label} <Icon icon={row.icon} />
                    </div>
                )}
                width={304}
            />
        </PanelLayout>
    )
}

export const DeletePanel: React.FC<{onDelete: () => Promise<unknown>; onClose: () => void}> = ({onDelete, onClose}) => {
    const [loading, setLoading] = useState(false)

    const closeAndDelete = async () => {
        setLoading(true)

        try {
            await onDelete()
            onClose()
            // eslint-disable-next-line no-empty
        } catch (e) {}

        setLoading(false)
    }

    return (
        <PanelLayout width={PANEL_WIDTH}>
            <PanelHeader panel="delete">Remove this data source</PanelHeader>
            <Typography.Paragraph>
                Would you like to remove this data source? This action is irreversible.
            </Typography.Paragraph>
            <PanelLayoutButtons>
                <Button variant="tertiary" onClick={() => onClose()}>
                    No, keep
                </Button>
                <Button color={colors.red} variant="tertiary" onClick={() => closeAndDelete()} loading={loading}>
                    Yes, remove
                </Button>
            </PanelLayoutButtons>
        </PanelLayout>
    )
}
