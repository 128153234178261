import PlayButton from '!file-loader!./play.svg'
import React, {Fragment, useState} from 'react'
import styled from 'styled-components'
import {VideoModal} from '../VideoModal/VideoModal'
import VideoPreview from './preview.mp4'

const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 195px;
    margin: 32px;
`

const Preview = styled.div`
    width: 195px;
    height: 112px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    transform: scale(1.0009);
    will-change: transform;
    transition: 0.3s transform ease-in-out;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    ${Container}:hover & {
        transform: scale(1.05);
    }
`

const Video = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
`

const Play = styled.div`
    width: 56px;
    height: 56px;
    background-image: url(${PlayButton});
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
    margin: 0 auto;
`

const VideoButton: React.FC = () => {
    const [visible, setVisible] = useState(false)
    return (
        <Fragment>
            <Container onClick={() => setVisible(true)}>
                <Preview>
                    <Video autoPlay loop muted>
                        <source src={VideoPreview} type="video/mp4" />
                    </Video>
                    <Play />
                </Preview>
            </Container>
            <VideoModal visible={visible} onClose={() => setVisible(false)} />
        </Fragment>
    )
}

export default VideoButton
