import {gql, useQuery} from '@apollo/client'
import {ListCategories, ListCategoriesVariables} from './__types__/ListCategories'

const LIST_CATEGORIES = gql`
    query ListCategories($orgIdStr: String!) {
        organisation(idStr: $orgIdStr) {
            idStr
            profilesCount
            homeItems {
                idStr
                label
                visible
                priority
                type
                sourceIdStr
                childCategories {
                    idStr
                    label
                    profilesCount
                    priority
                }
            }
        }
    }
`

export const useQueryListCategories = (organisationId: string) => {
    return [
        useQuery<ListCategories, ListCategoriesVariables>(LIST_CATEGORIES, {
            variables: {
                orgIdStr: organisationId,
            },
        }),
    ]
}
