import {gql} from '@apollo/client'
import {from} from 'fromfrom'
import {colors, easeInOutQuadCSS, Spacer} from 'nf-ui'
import React from 'react'
import styled from 'styled-components'
import MenuIcon from './menu.svg'
import {PhonePreview, PhonePreviewProps} from './PhonePreview'
import {PhonePreviewHomeData} from './__types__/PhonePreviewHomeData'

export const PhonePreviewHomeFragment = gql`
    fragment PhonePreviewHomeData on OrganisationObject {
        homeItems(visibility: BOTH) {
            idStr
            label
            priority
            visible
        }
    }
`

type PhonePreviewHomeProps = {
    logo?: string
    hoveringId?: string
} & Pick<PhonePreviewHomeData, 'homeItems'>

const Logo = styled.div<{logo?: string}>`
    background-image: ${(props) => (props.logo ? `url('${props.logo}')` : 'unset')};
    background-color: ${(props) => (props.logo ? 'unset' : colors.lightGray)};
    border-radius: ${(props) => (props.logo ? 0 : 3)}px;
    margin: 0 auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 96px;
`

const StyledMenuIcon = styled(MenuIcon)`
    margin-top: 9px;
    margin-left: 16px;
    margin-bottom: 26px;
`

const CategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const GhostCategory = styled.div`
    background: ${colors.lightGray};
    border-radius: 3px;
    width: 304px;
    height: 45px;
`

const Category = styled.div`
    height: 53px;
    line-height: 53px;
    border-top: 1px solid ${colors.lightGray};
    width: calc(100% - 40px);
    box-sizing: border-box;
    border-radius: 3px;
    position: relative;
`

const CategoryLabel = styled.div`
    font-family: 'halyard-display', sans-serif;
    font-size: 14.72px;
    font-weight: 300;
    letter-spacing: 0.21px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const Highlight = styled.div<{visible: boolean}>`
    position: absolute;
    top: 0;
    left: -8px;
    right: -8px;
    height: 100%;
    background: rgba(74, 144, 226, 0.1);
    border-radius: 3px;
    border: 2px solid rgb(60, 142, 255);
    box-sizing: border-box;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transition: 0.3s opacity ${easeInOutQuadCSS};
`

const arrayOfLength = (length: number) => Array(length).fill(null)

export const PhonePreviewHome: React.FC<PhonePreviewProps & PhonePreviewHomeProps> = ({
    logo,
    homeItems,
    hoveringId,
    ...rest
}) => {
    const filteredHomeItems = from(homeItems)
        .sortBy((homeItem) => homeItem.priority)
        .filter((homeItem) => homeItem.visible)
        .toArray()

    const ghostCategoryCount = filteredHomeItems.length > 1 ? 0 : 4 - filteredHomeItems.length

    return (
        <PhonePreview {...rest}>
            <StyledMenuIcon />
            <Logo logo={logo} />
            <Spacer height={32} />
            <CategoryContainer>
                {filteredHomeItems.map((homeItem) => (
                    <Category key={homeItem.idStr}>
                        <CategoryLabel>{homeItem.label}</CategoryLabel>
                        <Highlight visible={hoveringId === homeItem.idStr} />
                    </Category>
                ))}
                {arrayOfLength(ghostCategoryCount).map((_, i) => (
                    <React.Fragment key={i}>
                        <GhostCategory />
                        <Spacer height={8} />
                    </React.Fragment>
                ))}
            </CategoryContainer>
        </PhonePreview>
    )
}
