import {useState, useRef, useEffect} from 'react'
import {transition, TRANSITION_DURATION} from '../constants'

/**
 * This hook takes in a top and left position, and returns a
 * style prop to be passed to an element.
 *
 * When the top and/or left change, the style prop includes
 * a translate3d to transition the element to the new top/left.
 *
 * This allows us to transition using translate3d instead of
 * top/left, which is great because it's hardware accelerated.
 *
 * Context: https://www.html5rocks.com/en/tutorials/speed/high-performance-animations/
 */
export const useTranslate3d = ({top, left}: {top: number; left: number}) => {
    const [position, setPosition] = useState({
        top,
        left,
        transform: 'none',
        transition: transition('opacity'),
    })

    const lastPosition = useRef({top, left})
    useEffect(() => {
        const status = {finished: false}

        const leftDiff = left - lastPosition.current.left
        const topDiff = top - lastPosition.current.top

        setPosition((position) => ({
            ...position,
            transform: `translate3d(${leftDiff}px, ${topDiff}px, 0)`,
            transition: transition('opacity', 'transform'),
        }))

        const id = setTimeout(() => {
            setPosition({top, left, transform: 'none', transition: transition('opacity')})
            status.finished = true
            lastPosition.current = {top, left}
        }, TRANSITION_DURATION + 300)

        return () => {
            if (!status.finished) {
                setPosition({...lastPosition.current, transform: 'none', transition: transition('opacity')})
            }
            clearTimeout(id)
        }
    }, [top, left])

    return position
}
