import {gql, useMutation} from '@apollo/client'
import {Button, Icon, List, Panel, useAnalyticsEventTracker} from 'nf-ui'
import SvgAdditionalDataRegular from 'nf-ui/Icons/AdditionalDataRegular'
import SvgDelete from 'nf-ui/Icons/Delete'
import SvgEmail from 'nf-ui/Icons/Email'
import SvgOpenLink from 'nf-ui/Icons/OpenLink'
import pluralize from 'pluralize'
import React, {FC, useRef} from 'react'
import {useAlert} from 'react-alert'
import {NumberParam, StringParam, useQueryParams} from 'use-query-params'
import {DATA_SOURCES} from '~/pages/Adminland/Integrations'
import {DataSources_organisation_additionalFields} from '~/pages/Adminland/__types__/DataSources'
import {getGraphQLErrorMessage} from '~/util'
import {PanelLayout} from '../PanelLayout'
import {usePanelNavigator} from '../PanelNavigator'
import {useOrganisationIdStr} from '../useOrganisationIdStr'
import {DeletePanel} from './DataSourceCard'
import {IntegrationCard} from './IntegrationCard'
import {DeleteAllAdditionalFields, DeleteAllAdditionalFieldsVariables} from './__types__/DeleteAllAdditionalFields'

export type AdditionalFieldsCardProps = {
    additionalFields: DataSources_organisation_additionalFields[]
}

const DELETE_ALL_ADDITIONAL_FIELDS = gql`
    mutation DeleteAllAdditionalFields($organisationIdStr: String!) {
        deleteAllAdditionalFields(organisationIdStr: $organisationIdStr)
    }
`

export const AdditionalFieldsCard: FC<AdditionalFieldsCardProps> = ({additionalFields}) => {
    const {panel, openPanel, panelProps, closePanel} = usePanelNavigator('additionalFields')
    const editRef = useRef(null)

    const alert = useAlert()
    const organisationIdStr = useOrganisationIdStr()
    const [deleteAllAdditionalFields] = useMutation<DeleteAllAdditionalFields, DeleteAllAdditionalFieldsVariables>(
        DELETE_ALL_ADDITIONAL_FIELDS,
        {
            refetchQueries: [{query: DATA_SOURCES, variables: {organisationIdStr}}],
            awaitRefetchQueries: true,
            onError: (error) => {
                alert.error(getGraphQLErrorMessage(error))
                throw error
            },
        },
    )

    let panelElement = null
    if (panel === 'options') {
        panelElement = <OptionsPanel key="options" onClose={closePanel} />
    } else if (panel === 'delete') {
        panelElement = (
            <DeletePanel
                onClose={closePanel}
                onDelete={() => deleteAllAdditionalFields({variables: {organisationIdStr}})}
            />
        )
    }

    return (
        <IntegrationCard
            icon={SvgAdditionalDataRegular}
            label="Additional Data"
            text={`${additionalFields.length} new
                             ${pluralize('field', additionalFields.length)}
                             ${additionalFields.length === 1 ? 'comes' : 'come'}
                             from this source`}
            topRightContent={
                <>
                    <Button
                        variant="secondary"
                        ref={editRef}
                        loading={false}
                        onClick={() => openPanel('options', {itemId: 'additionalFields'})}
                    >
                        Manage
                    </Button>
                    <Panel targetRef={editRef} {...panelProps}>
                        {panelElement}
                    </Panel>
                </>
            }
            onClick={() => {}}
        />
    )
}

type OptionsPanelProps = {
    onClose: () => void
}

const OptionsPanel: React.FC<OptionsPanelProps> = ({onClose}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const [, setQuery] = useQueryParams({modal: StringParam, step: NumberParam})
    const {openPanel} = usePanelNavigator('')

    return (
        <PanelLayout>
            <List
                rows={[
                    {
                        icon: SvgOpenLink,
                        label: 'Edit data source',
                        onClick: () => {
                            setQuery({modal: 'addAdditionalData', step: 0})
                            onClose()
                        },
                    },
                    {
                        icon: SvgEmail,
                        label: 'Collect responses',
                        onClick: () => {
                            setQuery({modal: 'addAdditionalData', step: 1})
                            onClose()
                        },
                    },
                    {
                        icon: SvgDelete,
                        label: 'Remove data source',
                        onClick: () => openPanel('delete', {itemId: 'additionalFields'}),
                    },
                ]}
                onClick={(row) => {
                    trackAnalyticsEvent('select_manage_addtional_fields', {label: row.label})
                    row.onClick()
                }}
                renderRow={(row) => (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        {row.label} <Icon icon={row.icon} />
                    </div>
                )}
                width={304}
            />
        </PanelLayout>
    )
}
