import {Profile} from '../useFullOrgChartData'

export const getCollapsedProfileCount = (
    profile: Profile,
    getProfileFromIdStr: (idStr: string) => Profile | null,
): number => {
    const additionalReportsIdStrs = profile.additionalReports
    const directReports = profile.directReports.map((idStr) => getProfileFromIdStr(idStr)!)

    const descedantReportsCount: number = directReports.reduce((acc, curr) => {
        return acc + getCollapsedProfileCount(curr, getProfileFromIdStr)
    }, 0)

    return additionalReportsIdStrs.length + directReports.length + descedantReportsCount
}
