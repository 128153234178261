import React, {FC} from 'react'
import {playground} from '~/pages/Playground'
import {Column, Row} from '../Primitives/Layout'
import {FormLayout, Question} from '../Primitives/Forms'

export const QuestionsPanelContent = ({directoryName}: {directoryName: string}) => (
    <Column>
        <Question heading="What are Names &amp; Faces directories?">
            Names &amp; Faces directories are quick, visual lists of people in specific communities, designed to help
            people get to know one another and get in touch. These lists are private and can only be accessed by
            authorised users.
        </Question>
        <Question heading="Who can access the directory I'm adding my details to?">
            Only the {directoryName} families who add their details to this directory will be given access. School staff
            members who interact regularly with the grade will also be given access.
        </Question>
        <Question heading="How do I access Names &amp; Faces?">
            Once you've completed your details you'll be given login details to access the {directoryName} Names &amp;
            Faces directory through a web browser or through the Names &amp; Faces mobile app.
        </Question>
        <Question heading="Is Names &amp; Faces secure?">
            Yes, Names &amp; Faces is SOC 2 certified and is used by schools and organizations around the world. We
            follow POPIA &amp; GDPR best practices and our systems undergo regular audits and penetration tests to
            ensure we meet international security standards.
        </Question>
        <Question heading="Is Names &amp; Faces a social network?">
            <Row>
                No, Names &amp; Faces is not a social network of any kind. There is no messsaging, no pinging or poking
                and no random streams of consciousness.
            </Row>
            <Row height="20px"></Row>
            <Row>
                It is simply a fast, visual list of your community, designed to help you get to know who's who and get
                in touch when you need to.
            </Row>
        </Question>
        <Question heading="What is the cost of Names &amp; Faces?">
            Names &amp; Faces is free for 90 days. Thereafter it is R100 per user per year. We will send you a reminder
            14 days before the end of your free 90 day period.
        </Question>
        <Question
            heading="Can I create a Names &amp; Faces directory for another community I am part of?"
            bottomBorder={false}
        >
            Yes, anyone can create a Names &amp; Faces directory! Visit us at www.namesandfaces.com to find out more.
        </Question>
    </Column>
)

export const Form: FC<{
    heading: string
    subHeading: string | string[]
    progress?: number | string
    directoryName: string
    onNext?: () => Promise<void>
    onBack?: () => void
}> = ({heading, subHeading, progress, directoryName, onNext, onBack, children}) => (
    <FormLayout
        heading={heading}
        subHeading={subHeading}
        progress={progress}
        onNext={onNext}
        onBack={onBack}
        questionsPanelContent={<QuestionsPanelContent directoryName={directoryName}></QuestionsPanelContent>}
    >
        {children}
    </FormLayout>
)

playground.push({
    path: 'src/components/CommunityBuild/Form.tsx',
    component: Form,
    props: {
        directoryName: 'Webpups Grade 3',
        heading: 'Heading',
        subHeading: 'Sub-heading',
        progress: 1,
        children: ['Form Content'],
    },
    propOptions: {
        formContent: {
            get: (props: any) => (props.children || ['Form Content'])[0],
            set: (props: any, value: string) => ({...props, children: [value]}),
        },
        heading: {
            get: (props: any) => props.heading,
            set: (props: any, value: string) => ({...props, heading: value}),
        },
        subHeading: {
            get: (props: any) => props.subHeading,
            set: (props: any, value: string) => ({...props, subHeading: value}),
        },
        progress: {
            get: (props: any) => props.progress,
            set: (props: any, value: string) => ({...props, progress: value}),
        },
        onNext: () => {},
        onBack: () => {},
    },
})
