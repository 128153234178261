import {Typography} from 'nf-ui'
import pluralize from 'pluralize'
import React, {FC} from 'react'
import {useQueryParam} from 'use-query-params'
import {PageLoading} from '~/components/PageLoading'
import {ProfilesGridLayout} from '~/components/ProfilesGridLayout/ProfilesGridLayout'
import {getGraphQLErrorMessage} from '~/util'
import {searchProfiles} from '~/util/search'
import {UserlandActionBar} from './ActionBar'
import {useAllStaffData} from './useAllStaffData'
import {getThumbLinesByProfile} from '~/components/ProfilesGrid/ProfilesGrid'
import {useOrganisationContext} from '~/components/OrganisationContext'

export const SearchResults: FC = () => {
    const staffData = useAllStaffData()
    const [dataEditValues] = useOrganisationContext.useDataEditValues()
    const [dataEditFields] = useOrganisationContext.useDataEditFields()
    const [profileLines] = useOrganisationContext.useProfileLines()

    const [search] = useQueryParam<string>('search')
    if (!search) return null

    if (staffData.error) return <Typography.Heading>{getGraphQLErrorMessage(staffData.error)}</Typography.Heading>

    if (!staffData.data)
        return (
            <>
                <PageLoading />
                <UserlandActionBar loading />
            </>
        )

    const linesByProfile = getThumbLinesByProfile({
        dataEditValues,
        dataEditFields,
        profileLines,
        ...staffData.data,
    })

    const profiles = searchProfiles(
        search,
        staffData.data.profiles.map((profile) => ({
            ...profile,
            profileLineValues: linesByProfile[profile.idStr].map((line) => line.value),
        })),
    )
    const actionBarContent = `${profiles.length} ${pluralize('person', profiles.length)}`
    const profileIdStrs = profiles.map((profile) => profile.idStr)

    return (
        <>
            <ProfilesGridLayout
                data={{
                    profiles,
                }}
            />
            <UserlandActionBar label={actionBarContent} profileIdStrs={profileIdStrs} />
        </>
    )
}
