import {Form, MOBILE_MEDIA_QUERY, Spacer, Typography} from 'nf-ui'
import React from 'react'
import styled, {css} from 'styled-components'
import {PasswordState} from '~/pages/usePasswordUpdate'
import {TermsCopy} from './TermsCopy'
import {useCurrentUser} from './useCurrentUser'

const ValidationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 304px;
    ${MOBILE_MEDIA_QUERY} {
        width: 100%;
    }
`

const ValidationBlockContainer = styled.div<{disabled?: boolean}>`
    display: flex;
    flex-direction: column;
    transition: 0.3s opacity ease-in-out;
    align-items: center;
    ${({disabled}) =>
        disabled &&
        css`
            opacity: 0.3;
        `}
`

type ValidationBlockProps = {
    label: string
    disabled?: boolean
}

const ValidationBlock: React.FC<ValidationBlockProps> = ({label, disabled = false}) => {
    return (
        <ValidationBlockContainer disabled={disabled}>
            <Typography.Tiny color={disabled ? 'black' : 'green'}>{label}</Typography.Tiny>
        </ValidationBlockContainer>
    )
}

export const PasswordInput: React.FC<{passwordState: PasswordState; autoFocus?: boolean; label?: boolean}> = ({
    passwordState,
    autoFocus = false,
    label = false,
}) => {
    const {me} = useCurrentUser()
    return (
        <>
            <Form.Group name="password">
                {label && <Form.Label>Password</Form.Label>}
                <Form.Input
                    autoFocus={autoFocus}
                    value={passwordState.password}
                    onChange={passwordState.setPassword}
                    type="password"
                />
                <Spacer height={24} />
                <ValidationContainer>
                    <ValidationBlock label="Lowercase" disabled={!passwordState.lowerValid} />
                    <ValidationBlock label="Uppercase" disabled={!passwordState.upperValid} />
                    <ValidationBlock label="Special" disabled={!passwordState.specialValid} />
                    <ValidationBlock label="Characters" disabled={!passwordState.lengthValid} />
                </ValidationContainer>
            </Form.Group>
            {!(me && me.hasAcceptedTerms) && <TermsCopy />}
        </>
    )
}
