import React from 'react'
import {useGroupContext} from '../Group'
import {ColorPicker, ColorPickerProps} from '../ColorPicker'
import {useField} from 'formik'

const FormikColorPicker: React.FC<Omit<ColorPickerProps, 'value'>> = (props) => {
    const {name} = useGroupContext()
    const [field, {error}] = useField(name)

    return <ColorPicker {...props} {...field} name={name} error={error} onChange={props.onChange} />
}

export {FormikColorPicker}
