import {View} from '@react-pdf/renderer'
import React from 'react'
import {usePrintContext} from './usePrintContext'

export const Spacer = ({width, height}: {width?: number; height?: number}) => {
    const widthStyle = width ? `${width}px` : '100%'
    const heightStyle = height ? `${height}px` : '100%'

    return <View style={{width: widthStyle, height: heightStyle}} />
}

export const Indentation = ({indentation}: {indentation: number}) => {
    const {indentationWidth} = usePrintContext()
    return (
        <>
            {Array.from({length: indentation}).map((_, index) => (
                <Spacer width={indentationWidth} key={index} />
            ))}
        </>
    )
}
