import {Spinner} from 'nf-ui'
import React from 'react'
import {Column, Row} from './Layout'

export const SpinnerPage = () => (
    <Column height="100%" width="100%" centerVertical>
        <Row width="100%" centerHorizontal>
            <Spinner size={24}></Spinner>
        </Row>
    </Column>
)
