import {Form, List, Spacer, Theme, Typography, useTheme} from 'nf-ui'
import {InitialsText} from 'nf-ui/InitialsText'
import pluralize from 'pluralize'
import React from 'react'
import styled from 'styled-components'
import {useSortProfiles} from '~/components/SortProfiles'
import {ProfileSelectState} from '~/pages/Adminland/Integrations/AddAdditionalDataModal/CollectStep/useProfileSelect'
import {GroupMessageProfile} from './GroupMessageModalBody'
import {orderPhotos} from '~/components/OrganisationContext'

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-items: left;
    align-items: left;
    height: 100%;
    padding: 16px;
`

const Column = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`

function Header<ProfileType>({multiselect, theme}: {multiselect: ProfileSelectState<ProfileType>; theme: Theme}) {
    const {toggleAll, checkedAll} = multiselect
    return (
        <Row>
            <Column>
                <Form.Group name="name">
                    <Form.Checkbox
                        checked={checkedAll}
                        color={theme.primary.color}
                        onChange={toggleAll}
                        bold
                        label="Name"
                    />
                </Form.Group>
            </Column>
        </Row>
    )
}

const PROFILE_PICTURE = 24

const NameContainer = styled.div<{disabled?: boolean}>`
    flex: 1;
    opacity: ${({disabled}) => (disabled ? 0.2 : 1)};
`

const ProfileThumbnail = styled.div`
    height: ${PROFILE_PICTURE}px;
    width: ${PROFILE_PICTURE}px;
    border-radius: 3px;
    overflow: hidden;
`

const ThumbnailImage = styled.img<{disabled?: boolean}>`
    width: 100%;
    height: auto;
    opacity: ${({disabled}) => (disabled ? 0.2 : 1)};
`

const ProfileRow: React.FC<{
    profile: GroupMessageProfile
    profileSelection: ProfileSelectState<GroupMessageProfile>
    disabled: boolean
}> = ({profile, profileSelection, disabled}) => {
    const theme = useTheme()
    const {checkedProfiles, toggle} = profileSelection
    const checked = checkedProfiles.includes(profile.idStr)

    const nameText = disabled ? `${profile.fullName} (missing email address)` : profile.fullName

    const orderedPhotos = orderPhotos(profile.photos)
    const imageSrc = orderedPhotos[0]?.tinyUrl || orderedPhotos[0]?.profileUrl

    return (
        <Row data-testid={`profile_row_${profile.idStr}`} onClick={() => !disabled && toggle(profile.idStr)}>
            <Column>
                <Form.Group name="name">
                    <Form.Checkbox disabled={disabled} checked={checked} color={theme.primary.color} readOnly />
                </Form.Group>
                <Spacer width={16} />
                {orderedPhotos[0] ? (
                    <ProfileThumbnail>
                        <ThumbnailImage
                            data-profile-id={profile.idStr}
                            src={imageSrc}
                            alt="profile-piture"
                            disabled={disabled}
                        />
                    </ProfileThumbnail>
                ) : (
                    <InitialsText fullName={profile.fullName} theme={theme} />
                )}
            </Column>
            <Spacer width={16} />
            <Column>
                <NameContainer disabled={disabled}>{nameText}</NameContainer>
            </Column>
        </Row>
    )
}

const ProfileListHeading = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: -7px;
    margin-right: 22px;
`

export const ProfileList: React.FC<{
    profiles: GroupMessageProfile[]
    profileSelection: ProfileSelectState<GroupMessageProfile>
    disabledProfileIdStrs: string[]
}> = ({profiles, profileSelection, disabledProfileIdStrs}) => {
    const theme = useTheme()
    const {checkedProfiles} = profileSelection
    const profileCount = checkedProfiles.length

    const sortedPorfiles = useSortProfiles(profiles)

    return (
        <>
            <ProfileListHeading>
                <Typography.Label>
                    {profileCount} {pluralize('person', profileCount)} selected
                </Typography.Label>
            </ProfileListHeading>
            <Spacer height={16} />

            <List
                width={400}
                padding={false}
                heading={<Header multiselect={profileSelection} theme={theme} />}
                rows={sortedPorfiles}
                renderRow={(profile) => {
                    const disabled = disabledProfileIdStrs.includes(profile.idStr)
                    return <ProfileRow profile={profile} profileSelection={profileSelection} disabled={disabled} />
                }}
            />
        </>
    )
}
