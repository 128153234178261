import {NumberParam, useQueryParam} from 'use-query-params'

export const useCycleMatchingProfiles = () => {
    const [matchIndex = 1, setMatchIndex] = useQueryParam('matchIndex', NumberParam)

    const incrementMatchIndex = (matchedCount: number) => {
        if (matchIndex === matchedCount) {
            setMatchIndex(1)
        } else {
            setMatchIndex(matchIndex + 1)
        }
    }

    const decrementMatchIndex = (matchedCount: number) => {
        if (matchIndex === 1) {
            setMatchIndex(matchedCount)
        } else {
            setMatchIndex(matchIndex - 1)
        }
    }

    return {matchIndex, decrementMatchIndex, incrementMatchIndex, setMatchIndex}
}
