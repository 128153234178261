import React, {ReactNode} from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
`

const Left = styled.div`
    flex: 1;
`

const Right = styled.div`
    box-sizing: border-box;
    width: 404px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
    padding: 32px;
    overflow-y: auto;
`

type SidePanelLayoutProps = {
    leftContent?: ReactNode | null
}

export const SidePanelLayout: React.FC<SidePanelLayoutProps> = ({leftContent, children}) => (
    <Container>
        <Left>{leftContent}</Left>
        <Right>{children}</Right>
    </Container>
)
