import * as React from 'react'
const SvgCopy = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgCopy(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" ref={svgRef} {...props}>
            <path
                d="M13 0H6.3C4.717 0 3.397 1.264 3.305 2.844L3.3 3.02v.3H3C1.416 3.32.097 4.585.005 6.164L0 6.34v6.64c0 1.6 1.263 2.922 2.825 3.014L3 16h6.6c1.666 0 3.002-1.25 3.095-2.841l.005-.179v-.3h.3c1.584 0 2.903-1.264 2.995-2.843L16 9.661V3.02C16 1.419 14.737.097 13.175.005L13 0zM9.6 4.38c1.093 0 1.922.78 1.995 1.812l.005.149v6.64c0 1.053-.78 1.881-1.846 1.954l-.154.005H3c-1.028 0-1.916-.808-1.994-1.814L1 12.98V6.34c0-1.023.841-1.878 1.854-1.954L3 4.381h6.6zM13 1.06c1.012 0 1.82.794 1.894 1.807l.006.154v6.64c0 1.034-.76 1.876-1.75 1.954l-.15.005h-.3V6.34c0-1.614-1.276-2.923-2.916-3.014L9.6 3.32H4.4v-.3c0-1.034.76-1.877 1.75-1.955l.15-.005H13z"
                fill="#101010"
            />
        </svg>
    )
})
export default SvgCopy
