import React, {useState} from 'react'
import {Checkbox as CheckboxComponent, InputProps} from './Checkbox'
import {useGroupContext} from '../Group'
import styled from 'styled-components'
import {InputLabel} from '../Input'
import {ParagraphStyle} from '../../Typography'
import {colors} from '../../theme'

export interface CheckboxProps extends InputProps {
    label?: string
    error?: string
    bold?: boolean
    id?: string
    onCheck?: () => void
}

const FormCheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`

const LabelContainer = styled.label`
    ${ParagraphStyle}
    margin-left: 16px;
    margin-bottom: 0;
    cursor: pointer;
`

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({id, label, error, bold, color = colors.primary[100], onCheck, ...props}, ref) => {
        const {name} = useGroupContext()
        const [hover, setHover] = useState(false)
        return (
            <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => onCheck?.()}>
                <FormCheckboxContainer>
                    <CheckboxComponent ref={ref} id={name} name={name} hover={hover} color={color} {...props} />
                    {label && (
                        <LabelContainer
                            htmlFor={id || name}
                            variant={error ? 'error' : undefined}
                            style={{fontWeight: bold ? 500 : 300}}
                        >
                            {label}
                        </LabelContainer>
                    )}
                </FormCheckboxContainer>
                {error && <InputLabel color="red">{error}</InputLabel>}
            </div>
        )
    },
)
