import * as React from 'react'
const SvgCog = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgCog(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.894 3.578c-.406 0-.71.25-.812.61L8.474 6.43 6.24 6.113c-.405-.054-.811.152-.913.502L4.111 9.562c-.203.317-.102.684.101.928L5.734 12l-1.522 1.51c-.203.268-.304.683-.101 1.013l1.725 2.948c.101.306.507.47.811.413l1.827-.298.608 2.225c.102.362.406.611.812.611h4.16c.406 0 .71-.25.812-.61l.71-2.226 1.725.298c.406.057.71-.107.913-.413l1.624-2.948c.203-.33.203-.745-.102-1.013L18.214 12l1.522-1.51c.305-.244.305-.611.203-.928L18.62 6.615c-.101-.35-.507-.556-.812-.502l-2.232.317-.71-2.241a.818.818 0 00-.812-.611h-4.16zm0 4.02l.609-2.336h2.942l.71 2.337a.822.822 0 00.914.603l2.334-.33.71 1.83-1.623 1.702c-.406.33-.406.862 0 1.191l1.623 1.651-1.116 1.88-1.928-.325a.804.804 0 00-.913.6l-.71 2.337h-2.943L9.894 16.4c-.203-.412-.609-.67-1.014-.6l-1.928.325-1.015-1.88 1.623-1.65a.891.891 0 000-1.192L5.836 9.702l.811-1.83 2.233.33c.507.06.811-.198 1.014-.603zm2.131 6.086c-.913 0-1.725-.754-1.725-1.684 0-.93.812-1.685 1.725-1.685s1.623.755 1.623 1.684c0 .93-.71 1.685-1.623 1.685zM8.677 12c0-1.86 1.42-3.37 3.348-3.37 1.826 0 3.348 1.51 3.348 3.37s-1.522 3.37-3.348 3.37c-1.928 0-3.348-1.51-3.348-3.37z"
                fill={props.color ? props.color : '#3C8EFF'}
            />
        </svg>
    )
})
export default SvgCog
