import {InitialsText} from 'nf-ui/InitialsText'
import React from 'react'
import styled, {useTheme} from 'styled-components'
import {Profile} from '../../useFullOrgChartData'
import {orderPhotos} from '~/components/OrganisationContext'

export const PROFILE_PICTURE = 32

const ThumbnailContainer = styled.div`
    height: ${PROFILE_PICTURE}px;
    width: ${PROFILE_PICTURE}px;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ThumbnailImage = styled.img`
    width: 100%;
    height: auto;
`

export const ProfileThumbnail = ({profile}: {profile: Profile}) => {
    const theme = useTheme()

    const isDevelopment = process.env.NODE_ENV === 'development'
    const orderedPhotos = orderPhotos(profile.photos)
    const imageSrc = orderedPhotos[0]?.tinyUrl || orderedPhotos[0]?.profileUrl

    return (
        <div id={`profile-image-${profile.idStr}`} style={{zIndex: 1}}>
            {orderedPhotos[0] ? (
                <ThumbnailContainer>
                    {isDevelopment ? (
                        <ThumbnailImage
                            crossOrigin="anonymous"
                            data-profile-id={profile.idStr}
                            src={imageSrc}
                            alt="profile-piture"
                        />
                    ) : (
                        <ThumbnailImage data-profile-id={profile.idStr} src={imageSrc} alt="profile-piture" />
                    )}
                </ThumbnailContainer>
            ) : (
                <InitialsText fullName={profile.fullName} theme={theme} size={PROFILE_PICTURE} />
            )}
        </div>
    )
}
