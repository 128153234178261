import React from 'react'
import {SignUp} from './SignUp'
import {CheckYourEmail} from '../Primitives/CheckYourEmail'
import {ErrorState, SpinnerPage} from '../Primitives'
import {useAlert} from 'react-alert'
import {playground} from '~/pages/Playground'
import {useHistory} from 'react-router-dom'
import {getGraphQLErrorMessage} from '~/util'
import {ApolloError} from '@apollo/client'

export type Page = 'sign-up' | 'check-your-mail'

export type PageState = {
    errorState: ErrorState
    email?: string
    directoryName?: string
    acceptedTerms?: boolean
    code?: string
}

export const SelfBuildFlow = ({
    page,
    setPage,
    pageState,
    setPageState,
    requestOtp,
    signInOtp,
    onComplete,
}: {
    page: Page
    setPage: (value: Page) => void
    pageState: PageState
    setPageState: (value: PageState) => void
    requestOtp: (emailAddress: string) => Promise<void>
    signInOtp: (otp: string, email: string) => Promise<boolean>
    onComplete: (directoryName: string) => Promise<string | undefined>
}) => {
    const alert = useAlert()
    const history = useHistory()

    if (page === 'sign-up') {
        return (
            <SignUp
                email={pageState.email || ''}
                setEmail={(value) => setPageState({...pageState, email: value})}
                directoryName={pageState.directoryName || ''}
                setDirectoryName={(value) => setPageState({...pageState, directoryName: value})}
                acceptedTerms={!!pageState.acceptedTerms}
                setAcceptedTerms={(value) => setPageState({...pageState, acceptedTerms: value})}
                onNext={async () => {
                    if (!pageState.email) return
                    await requestOtp(pageState.email)
                    setPageState({...pageState, errorState: {}, code: ''})
                    setPage('check-your-mail')
                }}
                errorState={pageState.errorState}
                setErrorState={(value) => setPageState({...pageState, errorState: value})}
            />
        )
    }

    if (page === 'check-your-mail') {
        return (
            <CheckYourEmail
                code={pageState.code || ''}
                setCode={(value) => setPageState({...pageState, code: value})}
                onNext={async () => {
                    if (!pageState.code || !pageState.email) return
                    setPageState({...pageState, errorState: {code: {visible: false, message: ''}}})
                    try {
                        const result = await signInOtp(pageState.code, pageState.email)
                        if (!result) {
                            setPageState({...pageState, errorState: {code: {visible: true, message: 'Invalid code'}}})
                        } else {
                            const organisationIdStr = await onComplete(pageState.directoryName || 'Untitled')
                            organisationIdStr && history.push(`/${organisationIdStr}/admin`)
                        }
                    } catch (err) {
                        alert.error(getGraphQLErrorMessage(err as ApolloError))
                    }
                }}
                onDidntGetCode={async () => {
                    if (!pageState.email) return
                    await requestOtp(pageState.email)
                    alert.success('We have emailed you a new code.')
                }}
                errorState={pageState.errorState}
                setErrorState={(value) => setPageState({...pageState, errorState: value})}
            />
        )
    }

    return <SpinnerPage></SpinnerPage>
}

playground.push({
    path: 'src/components/SelfBuild/SelfBuildFlow.tsx',
    component: SelfBuildFlow,
    props: {
        page: 'sign-up',
        pageState: {
            formState: {},
            errorState: {},
        },
    },
    propOptions: {
        page: {
            get: (props: any) => JSON.stringify(props.page),
        },
        setPage: ({props, args}: {props: any; args: any[]}) => {
            return {...props, page: args[0]}
        },
        pageState: {
            get: (props: any) => JSON.stringify(props.pageState),
        },
        setPageState: ({props, args}: {props: any; args: any[]}) => ({...props, pageState: args[0]}),
        requestOtp: () => {},
        signInOtp: () => {},
        onComplete: () => {},
    },
})
