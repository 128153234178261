import {Column} from './useColumns'
import {Row} from './useRows'
import {useState} from 'react'
import {ROW_HEIGHT} from '../constants'
import {getHiddenRowsBefore} from './getHiddenRowsBefore'
import {TableProps} from '../Table'

/**
 * A hook to calulate data about the currently hovered cell.
 * The data is used to display various pieces of UI. For example, row highlighting.
 * @param columnWidths
 */
export const useCellHover = ({
    columns,
    rows,
    activeCell,
}: {
    columns: Column[]
    rows: Row[]
    activeCell: TableProps['activeCell']
}) => {
    const [hover, setHover] = useState<TableProps['activeCell']>(undefined)

    if (
        !hover ||
        !columns[hover.columnIndex] ||
        !rows[hover.rowIndex] ||
        columns[hover.columnIndex].style.fadedOut ||
        rows[hover.rowIndex].style.fadedOut
    ) {
        return {cell: null, setCell: setHover}
    }

    const cell = activeCell || hover

    const visibleRowIndex = cell.rowIndex - getHiddenRowsBefore(cell.rowIndex, rows)

    const cellObject = {
        ...columns[cell.columnIndex],
        top: visibleRowIndex * ROW_HEIGHT,
        header: visibleRowIndex === 0,
    }

    return {cell: cellObject, setCell: setHover}
}

export type CellHoverState = ReturnType<typeof useCellHover>
