import {ROW_HEIGHT} from '../constants'
import {useMemo, RefObject, useCallback} from 'react'
import {Data} from '../Table'
import {VariableSizeGrid, VariableSizeGridProps} from 'react-window'
import {useAnimateGrid, Style} from './useAnimateGrid'

export type Row = {
    isHeader: boolean
    data: Data[number]
    size: number
    offset: number
    style: Style
    firstVisible: boolean
    lastVisible: boolean
    index: number
}

export const useRows = ({
    data,
    gridRef,
    hiddenRows,
}: {
    data: Data
    gridRef: RefObject<VariableSizeGrid>
    hiddenRows: number[]
}) => {
    const rowData = useMemo(() => {
        const headerRow = Object.keys(data[0]).reduce<Data[number]>((row, column) => ({...row, [column]: column}), {})
        return [headerRow, ...data]
    }, [data])

    const rowIndexes = useMemo(() => rowData.map((d, index) => index), [rowData])

    const {collapsed, styles} = useAnimateGrid({
        items: rowIndexes,
        hiddenItems: hiddenRows,
        // itemSizes: ROW_HEIGHT,
        gridRef,
    })

    const rowHeight = useCallback<VariableSizeGridProps['rowHeight']>(
        (rowIndex) => (collapsed.current[rowIndex] ? 0 : ROW_HEIGHT),
        [collapsed],
    )

    const visibleRowIndexes = rowIndexes.filter((index) => !hiddenRows.includes(index))
    const lastVisibleRowIndex = visibleRowIndexes[visibleRowIndexes.length - 1]
    const firstVisibleRowIndex = visibleRowIndexes[0]

    const rows = useMemo<Row[]>(() => {
        let offset = 0
        return rowData.map((row, index) => {
            offset += index === 0 ? 0 : styles[index - 1].collapsed ? 0 : ROW_HEIGHT

            return {
                isHeader: index === 0,
                data: row,
                size: ROW_HEIGHT,
                offset,
                style: styles[index],
                firstVisible: firstVisibleRowIndex === index,
                lastVisible: lastVisibleRowIndex === index,
                index,
            }
        })
    }, [rowData, styles, lastVisibleRowIndex, firstVisibleRowIndex])

    return {rows, rowHeight}
}
