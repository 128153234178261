import {ApolloError} from '@apollo/client'
import {AnalyticsContext} from 'nf-ui'
import {ReactElement, ReactNode, useContext, useEffect} from 'react'

export const getGraphQLErrorMessage = (error: ApolloError) => {
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        return error.graphQLErrors[0].message
    }

    return error.message
}

export class UnreachableCaseError extends Error {
    constructor(val: never) {
        super(`Unreachable case: ${val}`)
    }
}

export const splitStringToArray = (value?: string | null) => {
    const arrayValues = value?.split(/,|;/) ?? []
    return arrayValues.map((value) => value.trim()).filter((v) => v !== '')
}

export const capitalize = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1)
}

/**
 * A custom TS type check which will return false if an item is null or undefined.
 * Useful for filtering falsey values from an array.
 *
 * For example, if you have an array of type `(string | null)[]`, passing this function
 * to filter will result in an array of type `string[]`.
 *
 * Source: https://github.com/Microsoft/TypeScript/issues/16069#issuecomment-369374214
 *
 * @param input
 */
export function isNotNullOrUndefined<T extends Object>(input: null | undefined | T): input is T {
    return input != null
}

// See above function
export function isNotFalse<T extends Object>(input: false | T): input is T {
    return input !== false
}

/**
 * A custom TS type check which will return true if the `ReactNode` passed to it
 * is a `ReactElement`.
 *
 * This can be useful for passing only `ReactElements` to `React.cloneElement`, or
 * for picking the key off the element.
 *
 * @param element
 */
export function isReactElement(element: ReactNode): element is ReactElement {
    return Boolean(element && typeof element === 'object' && 'key' in element)
}

export const useAnalyticsPageTracker = (page: string) => {
    const {platform} = useContext(AnalyticsContext)
    useEffect(() => {
        if (!window.analytics) return
        window.analytics.ready(() => {
            // Disable geo-location tracking for pages
            if (window?.mixpanel?.get_config('ip')) window.mixpanel.set_config({ip: !window.mixpanel?.get_config('ip')})
            window.analytics.page(platform, page)
        })
    }, [platform, page])
}

export const removeTrailingSlash = (string: string) => string.replace(/\/(\s+)?$/, '')

export const csvArrayToRecord = (data: string[][]) => {
    const [headingRow, ...rows] = data

    return rows.map((row) => {
        return row.reduce((result, value, index) => {
            const heading = headingRow[index]
            return {...result, [heading]: value}
        }, {} as Record<string, string>)
    })
}
