import {gql, useQuery} from '@apollo/client'
import {HomeItemType} from '~/globalTypes'
import {
    ListFilterCategories,
    ListFilterCategoriesVariables,
    ListFilterCategories_organisation_homeItems_childCategories,
} from './__types__/ListFilterCategories'

export const LIST_CATEGORIES = gql`
    query ListFilterCategories($orgIdStr: String!) {
        organisation(idStr: $orgIdStr) {
            idStr
            homeItems {
                idStr
                label
                visible
                priority
                type
                sourceIdStr
                childCategories {
                    idStr
                    label
                    profilesCount
                    priority
                }
            }
        }
    }
`

export type FilterChildCategory = ListFilterCategories_organisation_homeItems_childCategories

export function useFilterCategory(orgIdStr: string) {
    const {data, loading: categoryLoading, error: catError} = useQuery<
        ListFilterCategories,
        ListFilterCategoriesVariables
    >(LIST_CATEGORIES, {
        variables: {
            orgIdStr,
        },
        skip: !orgIdStr,
    })

    const homeItems = data?.organisation.homeItems || []
    const filterableHomeItems = homeItems.filter((homeItem) => homeItem.type === HomeItemType.CATEGORY)

    return {
        homeItems: filterableHomeItems,
        categoryLoading,
        catError,
    }
}
