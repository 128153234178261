import {Form, FormikCheckboxList, FormikDatePicker, FormikDropdownSelect, FormikInput} from 'nf-ui'
import {FormikMobileNumber} from 'nf-ui/Form/Formik/MobileNumber'
import React from 'react'
import * as yup from 'yup'
import {AdditionalFieldType} from '~/globalTypes'
import {FormField} from '~/objectTypes'

export type AdditionalDataFormValues = {[key: string]: string | string[]}
export type AdditionalDataFormSchema = Record<string, yup.MixedSchema>

export type BaseFormField = Omit<FormField, 'extraData'>

const getSchema = (field: BaseFormField) => {
    switch (field.type) {
        case AdditionalFieldType.EMAIL:
            return yup
                .string()
                .email()
                .label('this')
        case AdditionalFieldType.MOBILE_NUMBER:
            return yup
                .string()
                .matches(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/, {
                    message: 'this is not a valid phone number',
                }) //https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number/16702965#16702965
                .label('this')
        default:
            return yup.string().label('this')
    }
}

const getInitialValue = (field: BaseFormField) => {
    let value: string | string[] = field.value || ''

    if (field.options != null) {
        value = field.options.filter((option) => option.selected).map((option) => option.idStr)
    }

    return value
}

export const setupAdditionalDataForm = (fields: BaseFormField[]) => {
    const formValues: AdditionalDataFormValues = {}
    const formSchema: AdditionalDataFormSchema = {}

    fields.forEach((field) => {
        const {idStr} = field

        formValues[idStr] = getInitialValue(field)
        formSchema[idStr] = getSchema(field)
    })

    return {formValues, formSchema}
}

type AdditionalDataFieldProps = {field: BaseFormField}

export const AdditionalDataField = ({field}: AdditionalDataFieldProps) => {
    const options = field.options?.map((option) => ({
        label: option.name,
        value: option.idStr,
    }))

    switch (field.type) {
        case AdditionalFieldType.SELECT_ONE:
            return <FormikDropdownSelect options={options} />
        case AdditionalFieldType.SELECT_MULTIPLE:
            return <FormikCheckboxList options={options} />
        case AdditionalFieldType.DATE:
            return <FormikDatePicker />
        case AdditionalFieldType.MOBILE_NUMBER:
            return <FormikMobileNumber />

        default:
            return <FormikInput />
    }
}

export const getFieldsForSubmit = (formValues: AdditionalDataFormValues) => {
    const idStrs = Object.keys(formValues)

    return idStrs.map((idStr) => {
        const value = formValues[idStr]
        const optionsOrValue = Array.isArray(value) ? 'options' : 'value'

        return {
            idStr,
            ...{[optionsOrValue]: value},
        }
    })
}

export const AdditionalDataFormFields = ({formFields}: {formFields: BaseFormField[]}) => {
    return (
        <>
            {formFields.map((field) => {
                return (
                    <Form.Group name={`additionalFields.${field.idStr}`} key={field.idStr}>
                        <Form.Label helpText={field.helpText}>{field.name}</Form.Label>
                        <AdditionalDataField field={field} />
                    </Form.Group>
                )
            })}
        </>
    )
}
