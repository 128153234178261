import {Button, colors, Form, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {getEditIntegrationLink} from '~/components/Integrations/integrations'
import {PrimaryFields} from './PrimaryFieldsTab'
import {HideableFieldSwitch} from './useHiddenFields'
import {EditProfileData_profile_primaryFields} from './__types__/EditProfileData'

export const FormItemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`

export const HiddenText = styled.span<{color?: string}>`
    transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out;
    color: ${({color}) => color || colors.darkGray};
`

export const PrimaryFieldsPanel = ({
    fields,
    onFieldClick,
    additionalProfile,
}: {
    fields: PrimaryFields
    onFieldClick: (field: PrimaryFields[number]) => void
    additionalProfile: boolean
}) => {
    const [editProfileId] = useQueryParam<string>('editProfileId')
    if (!editProfileId) throw new Error('Missing editProfileId')

    const {currentOrganisation, hrisProvider} = CurrentOrganisation.useContainer()
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const editDataLink = getEditIntegrationLink(currentOrganisation?.primaryDataSourceType)
    const shouldShowEditLink = !additionalProfile && editDataLink

    return (
        <div>
            {fields.map((field: EditProfileData_profile_primaryFields) => {
                return (
                    <Fragment key={field.idStr}>
                        <HideableFieldSwitch field={field} />

                        <div onClick={() => onFieldClick(field)}>
                            <Form.Group name="fieldValue">
                                <Form.Input disabled value={field.value} />
                            </Form.Group>
                        </div>
                        <Spacer height={24} />
                    </Fragment>
                )
            })}
            {additionalProfile ? (
                <Typography.Paragraph>
                    These fields are restricted. Your primary data is not editable through your Names & Faces profile.
                    Contact your company's admin to update this information.
                </Typography.Paragraph>
            ) : (
                <Typography.Paragraph>
                    Your primary data comes from {hrisProvider} and is automatically synced to your Names & Faces
                    profile. <br />
                    To update this information you need to edit it in {hrisProvider}. All updates made to {hrisProvider}{' '}
                    take 24 hours to reflect.
                </Typography.Paragraph>
            )}

            <Spacer height={8} />
            {shouldShowEditLink && (
                <>
                    <Link
                        to={editDataLink!.link}
                        target="_blank"
                        onClick={() => {
                            trackAnalyticsEvent(editDataLink!.analyticsEvent, {from: 'lightbox'})
                        }}
                    >
                        <Button variant="primary">{`Edit in ${hrisProvider}`}</Button>
                    </Link>
                    <Spacer height={32} />
                </>
            )}
        </div>
    )
}
