import {gql, useQuery, useMutation, MutationTuple, FetchResult, ApolloError} from '@apollo/client'
import {Forms, FormsVariables} from './__types__/Forms'
import {useAlert} from 'react-alert'
import {getGraphQLErrorMessage} from '~/util'
import {RequestCommunityBuildOtp, RequestCommunityBuildOtpVariables} from './__types__/RequestCommunityBuildOtp'

const FORMS = gql`
    query Forms($formIdStr: String!, $organisationIdStr: String) {
        forms(formIdStr: $formIdStr, organisationIdStr: $organisationIdStr) {
            formIdStr
            directoryName
            organisationIdStr
            masterDirectoryIdStr
            fields {
                idStr
                name
                availableValues
                subType
            }
        }
    }
`

const REQUEST_COMMUNITY_BUILD_OTP = gql`
    mutation RequestCommunityBuildOtp($formIdStr: String!, $emailAddress: String!) {
        requestCommunityBuildOTP(formIdStr: $formIdStr, emailAddress: $emailAddress)
    }
`

export const useQueryForms = (formIdStr: string, organisationIdStr: string | null) => {
    return [
        useQuery<Forms, FormsVariables>(FORMS, {
            variables: {
                formIdStr,
                organisationIdStr,
            },
        }),
    ]
}

export const useMutationRequestCommunityBuildOtp = (
    formIdStr: string,
): [
    MutationTuple<RequestCommunityBuildOtp, RequestCommunityBuildOtpVariables>,
    (variables: {
        emailAddress: string
    }) => Promise<FetchResult<RequestCommunityBuildOtp, Record<string, any>, Record<string, any>>>,
] => {
    const alert = useAlert()
    const mutation = useMutation<RequestCommunityBuildOtp, RequestCommunityBuildOtpVariables>(
        REQUEST_COMMUNITY_BUILD_OTP,
        {},
    )
    const callMutation = async (variables: {emailAddress: string}) => {
        try {
            const result = await mutation[0]({variables: {formIdStr, ...variables}})
            return result
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error as ApolloError))
            throw error
        }
    }
    return [mutation, callMutation]
}
