import React from 'react'
import {Column, Row} from './Layout'

export const ProgressBars = ({progress}: {progress?: number | string}) => {
    const progressInt = typeof progress === 'string' ? parseInt(progress) || 0 : progress
    return (
        <Row height="6px" width="100%">
            <Column grow={1} backgroundColor={(progressInt || 1) >= 1 ? 'rgb(60, 142, 255)' : 'rgb(235, 235, 235)'} />
            <Column grow={0} width="6px" />
            <Column grow={1} backgroundColor={(progressInt || 1) >= 2 ? 'rgb(60, 142, 255)' : 'rgb(235, 235, 235)'} />
            <Column grow={0} width="6px" />
            <Column grow={1} backgroundColor={(progressInt || 1) >= 3 ? 'rgb(60, 142, 255)' : 'rgb(235, 235, 235)'} />
        </Row>
    )
}
