import styled from 'styled-components'

const HEIGHT = 72
export {HEIGHT as ACTION_BAR_HEIGHT}

export const ActionBar = styled.div<{static?: boolean}>`
    position: ${(props) => (props.static ? 'static' : 'absolute')};
    bottom: ${(props) => (props.static ? '32px' : 'unset')};
    left: ${(props) => (props.static ? '64px' : 'unset')};
    right: ${(props) => (props.static ? '64px' : 'unset')};
    height: ${HEIGHT}px;
    padding: 0 24px;
    box-sizing: border-box;
    background-color: ${({theme}) => theme.primary.color};
    border-radius: 3px;
    z-index: 30;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
