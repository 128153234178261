import {ACTION_BAR_HEIGHT, Typography} from 'nf-ui'
import React, {useRef} from 'react'
import styled from 'styled-components'
import {PageLoading} from '~/components/PageLoading'
import {getGraphQLErrorMessage} from '~/util'
import {ActionBar} from './FullOrgChart/ActionBar'
import {OpenPositionProvider} from './FullOrgChart/OpenPositions/useOpenPosition'
import {FullOrgChartProvider, useFullOrgChart} from './FullOrgChart/FullOrgChartContext'
import {OrphanedProfiles} from './FullOrgChart/OrphanedProfiles'
import {ProfileBrick} from './FullOrgChart/ProfileBrick'
import {DisplayOptionsProvider} from './FullOrgChart/useDisplayOptions'
import {useOrgChartData} from './FullOrgChart/useOrgChartData'

const OuterContainer = styled.div`
    position: absolute;
    height: 100vh;
    width: 100%;
`

const ScrollContainer = styled.div`
    position: relative;
    padding: 0 32px;
    padding-bottom: calc(64px + ${ACTION_BAR_HEIGHT}px);
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    box-sizing: border-box;
`

export const OrgChartProfiles = ({container}: {container: React.RefObject<HTMLDivElement>}) => {
    const {data, loading, error, topLevelProfiles, visibleProfiles, getProfileFromIdStr} = useOrgChartData()
    const {scrollContainer} = useFullOrgChart()

    if (error) return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>
    if (!data || loading) return <PageLoading />

    return (
        <DisplayOptionsProvider organisation={data.organisation}>
            <OuterContainer>
                <ScrollContainer ref={scrollContainer}>
                    <div
                        id="full-org-chart"
                        key={visibleProfiles.length}
                        style={{marginBottom: '32px', paddingTop: '32px'}}
                        ref={container}
                    >
                        {topLevelProfiles.map((profile) => (
                            <ProfileBrick
                                key={profile.idStr}
                                profile={profile}
                                getProfileFromIdStr={getProfileFromIdStr}
                                topLevel={true}
                            />
                        ))}
                        <OrphanedProfiles />
                    </div>
                    <ActionBar />
                </ScrollContainer>
            </OuterContainer>
        </DisplayOptionsProvider>
    )
}

export const FullOrgChart = () => {
    const orgChartContainer = useRef<HTMLDivElement>(null)
    const scrollToTop = () => {
        orgChartContainer &&
            orgChartContainer.current &&
            orgChartContainer.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    }

    const removeFade = () => {
        orgChartContainer.current?.classList.remove('faded-background')
    }

    const addFade = () => {
        orgChartContainer.current?.classList.add('faded-background')
    }

    const removeHoverFade = () => {
        orgChartContainer.current?.classList.remove('hover-fade-background')
    }

    const addHoverFade = () => {
        orgChartContainer.current?.classList.add('hover-fade-background')
    }

    const onProfileHover = (idStr: string | null, prevIdStr: string | null) => {
        if (!idStr && !prevIdStr) return

        if (prevIdStr) {
            removeHoverFade()
            const hoveredItem = document.getElementById(`profile_${prevIdStr}`)
            hoveredItem?.removeAttribute('data-hovered')
        } else {
            addHoverFade()
            const hoveredItem = document.getElementById(`profile_${idStr}`)
            hoveredItem?.setAttribute('data-hovered', 'true')
        }
    }

    return (
        <OpenPositionProvider>
            <FullOrgChartProvider
                onHover={onProfileHover}
                scrollToTop={scrollToTop}
                removeFade={removeFade}
                addFade={addFade}
                orgChartContainer={orgChartContainer}
            >
                <OrgChartProfiles container={orgChartContainer} />
            </FullOrgChartProvider>
        </OpenPositionProvider>
    )
}
