import * as React from 'react'
const SvgCheckSquare = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgCheckCircle(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="m-/-Photo-/-Adminland-/-multiselect-/-matched" transform="translate(-96.000000, -8.000000)">
                    <g id="a-/-Input-/-Checkbox-/-16px-/-Sqaure-/-Active" transform="translate(96.000000, 8.000000)">
                        <rect id="Rectangle" fill="#3C8EFF" x="0" y="0" width="16" height="16" rx="2"></rect>
                        <g id="a-/-Icon-/-16px-/-Check" fill="#FFFFFF">
                            <path
                                d="M10.5922302,4.84817613 L6.80804395,9.00085144 L5.41860484,7.37048248 C5.09164373,6.9868481 4.5551044,6.90811662 4.18756929,7.1794609 L4.09942552,7.25461635 C3.68285462,7.55785425 3.61335965,8.14612915 3.87399632,8.60484969 L5.98974981,11.1174537 C6.23603007,11.3456578 6.46637167,11.4628441 6.70066597,11.4687014 C7.02187295,11.4751274 7.25965851,11.3781638 7.50382512,11.1620763 L12.0157087,6.17031503 C12.3677523,5.77368816 12.3677523,5.15983382 12.0122051,4.79243506 C11.5364844,4.42297783 10.9471641,4.44828394 10.5922302,4.84817613 Z"
                                id="Path"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
})
export default SvgCheckSquare
