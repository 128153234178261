import {gql, useMutation} from '@apollo/client'
import {DownloadPhoto, DownloadPhotoVariables} from './__types__/DownloadPhoto'

const EXTENSIONS: Record<string, string> = {
    'image/png': 'png',
    'image/tiff': 'tiff',
    'image/vnd.wap.wbmp': 'wbmp',
    'image/x-icon': 'ico',
    'image/x-jng': 'jng',
    'image/x-ms-bmp': 'bmp',
    'image/svg+xml': 'svg',
    'image/webp': 'webp',
}

const DOWNLOAD_PHOTO = gql`
    mutation DownloadPhoto($idStr: String!) {
        downloadAdpProfilePhoto(idStr: $idStr) {
            content
            type
        }
    }
`

export const useDownloadPhoto = () => {
    const [downloadMutation] = useMutation<DownloadPhoto, DownloadPhotoVariables>(DOWNLOAD_PHOTO)

    const downloadFile = ({content, type, fileName}: {content: string; type: string; fileName: string}) => {
        const a = document.createElement('a')
        a.href = `data:${type};base64,${content}`
        a.download = `${fileName}.${EXTENSIONS[type]}`
        a.click()
    }

    const downloadPhoto = async (profile: {fullName: string; idStr: string}) => {
        const {data} = await downloadMutation({variables: {idStr: profile.idStr}})
        if (!data) return

        const fileName = profile.fullName
        const {content, type} = data.downloadAdpProfilePhoto
        downloadFile({content, type, fileName})
    }

    return {
        downloadPhoto,
    }
}
