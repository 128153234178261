import React, {FC} from 'react'
import {Label} from '../Label'
import {useGroupContext} from '../Group'
import {FormikErrors, useFormikContext} from 'formik'

export type FormikLabelProps = {
    errors?: FormikErrors<Record<string, string>>
}

export const FormikLabel: FC<FormikLabelProps> = ({children, errors}) => {
    const {name} = useGroupContext()
    const formik = useFormikContext()
    errors = errors ?? formik.errors
    //TODO : Should grab the label from the validation object if available
    return <Label error={errors[name]}>{children ?? name}</Label>
}
