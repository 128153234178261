import {colors, FadePresence, Spacer, Typography} from 'nf-ui'
import React, {useEffect} from 'react'
import styled from 'styled-components'
import {WizardLayout} from './WizardLayout'

const VIMEO_SCRIPT = 'https://player.vimeo.com/api/player.js'

const PageContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: row;
    > * {
        width: 50%;
    }
    @media (max-width: 1170px) {
        flex-direction: column;
        > * {
            width: 100%;
        }
    }
`

const Body = styled.div`
    max-width: 440px;
    padding-left: 120px;
    @media (max-width: 1170px) {
        padding-left: 32px;
        padding-right: 32px;
    }
    @media (max-width: 479px) {
        padding-left: 24px;
        padding-right: 24px;
    }
`

const BlueContainer = styled.div`
    background-color: ${colors.primary[100]};
    display: flex;
`

const BlueContent = styled.div`
    max-width: 560px;
    margin: 0 auto;
    text-align: center;
    padding-right: 32px;
    padding-left: 32px;
`

const WelcomeVideo = () => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = VIMEO_SCRIPT
        script.async = true
        document.body.appendChild(script)
        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <>
            <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                <iframe
                    src="https://player.vimeo.com/video/564168347?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: '4px solid #FFFFFF',
                        boxSizing: 'border-box',
                        filter: 'drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14))',
                        borderRadius: '4px',
                    }}
                    title="Introducing Names &amp;amp; Faces for Adapt"
                ></iframe>
            </div>
        </>
    )
}

export const WelcomeLaout: React.FC = ({children}) => {
    return (
        <PageContainer>
            <WizardLayout>
                <WizardLayout.Navigation />
                <FadePresence exitBeforeEnter>
                    <Body>{children}</Body>
                </FadePresence>
            </WizardLayout>

            <BlueContainer>
                <BlueContent>
                    <Spacer height={136} />
                    <WelcomeVideo />
                    <Spacer height={40} />
                    <Typography.SectionHeading style={{color: 'white'}}>
                        Know your people better
                    </Typography.SectionHeading>
                    <Spacer height={16} />
                    <Typography.Paragraph style={{color: 'white'}}>
                        You’ll never be left guessing who someone is, what they look like or what they do. Names & Faces
                        takes all of this information and displays it as detailed, individual profiles that are quick to
                        access and easy to digest.
                    </Typography.Paragraph>
                    <Spacer height={40} />
                </BlueContent>
            </BlueContainer>
        </PageContainer>
    )
}
