import styled from 'styled-components'

export type Space = 0 | 4 | 8 | 16 | 24 | 32 | 40 | 48 | 56 | 64 | 72 | 136 | 160

const Spacer = styled.div<{height?: Space; width?: Space}>`
    height: ${(props) => props.height || 0}px;
    width: ${(props) => props.width || 0}px;
    flex-shrink: 0;
`

export {Spacer}
