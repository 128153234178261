import {Button, colors, Typography} from 'nf-ui'
import pluralize from 'pluralize'
import React from 'react'
import {useAlert} from 'react-alert'
import {PANEL_WIDTH} from '~/constants'
import {getGraphQLErrorMessage} from '~/util'
import {PanelLayout, PanelLayoutButtons} from '../PanelLayout'
import {PanelHeader, usePanelStore} from '../PanelNavigator'

export const DeletePanel: React.FC<{deletePhoto: () => void; photoCount?: number}> = ({
    deletePhoto,
    photoCount = 1,
}) => {
    const panelStore = usePanelStore()
    const closePanel = panelStore.useStore((state) => state.closePanel)

    const alert = useAlert()

    const photoText = `${pluralize('this', photoCount)} ${pluralize('photo', photoCount)}`

    return (
        <PanelLayout width={PANEL_WIDTH}>
            <PanelHeader panel="delete">Delete {photoText}</PanelHeader>
            <Typography.Paragraph>
                Would you like to delete {photoText}? This action is irreversible.
            </Typography.Paragraph>
            <PanelLayoutButtons>
                <Button
                    variant="tertiary"
                    onClick={() => {
                        closePanel()
                    }}
                >
                    No, keep
                </Button>
                <Button
                    color={colors.red}
                    variant="tertiary"
                    onClick={async () => {
                        try {
                            closePanel()
                            await deletePhoto()
                        } catch (error) {
                            alert.error(getGraphQLErrorMessage(error))
                        }
                    }}
                >
                    Yes, delete
                </Button>
            </PanelLayoutButtons>
        </PanelLayout>
    )
}
