import {useField, useFormikContext} from 'formik'
import {labelFontStyle, paragraphFontStyle} from '../../Typography'
import React, {FC} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import {colors} from '../../theme'
import {useGroupContext} from '../Group'
import {Input, InputProps} from '../Input'
import format from 'date-fns/format'

const Wrapper = styled.div`
    ${paragraphFontStyle}
    .react-datepicker-wrapper {
        width: 100%;
        font-family: 'halyard-display';
    }

    .react-datepicker__current-month {
        ${labelFontStyle}
    }

    .react-datepicker__header {
        border-bottom: none;
        background-color: white;
    }

    .react-datepicker__day--selected {
        background-color: ${colors.primary[100]};
    }

    .react-datepicker {
        border: none;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
    }

    .react-datepicker__triangle {
        border-bottom-color: white !important;

        :before {
            border-top-color: white !important;
            border-bottom-color: white !important;
        }
    }

    .react-datepicker__navigation--years,
    &:hover .react-datepicker__navigation--years {
        border: 6px solid transparent;
        width: 0;
    }

    .react-datepicker__navigation--years-upcoming,
    &:hover .react-datepicker__navigation--years-upcoming {
        top: -8px;
        border-bottom-color: #101010;
    }

    .react-datepicker__navigation--years-previous,
    &:hover .react-datepicker__navigation--years-previous {
        top: 8px;
        border-top-color: #101010;
    }

    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {
        background-color: white;
    }
`

export type FormikDateProps = Omit<InputProps, 'onChange' | 'value'> & {
    hideError?: boolean
}

export const FormikDatePicker: FC<FormikDateProps> = ({hideError = false, ...rest}) => {
    const {name} = useGroupContext()
    const [field, {error}] = useField(name)
    const {setFieldValue} = useFormikContext<Record<string, string>>()
    const fieldError = hideError ? undefined : error
    const fieldDate = field.value ? new Date(field.value) : null

    const CustomInput: FC<{value?: string | number | undefined; onClick?: () => void}> = ({value, onClick}) => (
        <Input {...rest} name={name} error={fieldError} onClick={onClick} value={value} />
    )
    return (
        <Wrapper>
            <DatePicker
                onBlur={field.onBlur}
                selected={fieldDate}
                onChange={(date: Date) => setFieldValue(name, format(date, 'MM/dd/yyyy'))}
                customInput={<CustomInput />}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
            />
        </Wrapper>
    )
}
