import React from 'react'
import styled from 'styled-components'
import {colors} from '../theme'
import {StepProps, Step} from './Step'

export const StepperContext = React.createContext<{
    currentStepIndex: number
    index: number
    completed: boolean
}>({
    currentStepIndex: 0,
    index: 0,
    completed: false,
})

const Container = styled.div<{stepCount: number}>`
    position: relative;
    height: 42px;
    width: ${(props) => (props.stepCount - 1) * (80 + 16) + 16}px;
`

const Steps = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Line = styled.div`
    position: absolute;
    top: 7.5px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${colors.darkGray};
`

const ProgressLine = styled.div<{progress: number}>`
    height: 2px;
    background-color: ${colors.primary[100]};
    width: ${({progress}) => progress * 100}%;
    transition: 0.3s width ease-in-out;
`

interface StepperProps {
    children: (React.FunctionComponentElement<StepProps> | null)[]
    currentStepIndex: number
    completed?: boolean
}

interface StepperType extends React.FC<StepperProps> {
    Step: typeof Step
}

const getProgress = (stepIndex: StepperProps['currentStepIndex'], stepCount: number) => {
    const total = stepCount - 1

    let index = stepIndex
    index = index > total ? total : index
    index = index < 0 ? 0 : index

    return index / total
}

const Stepper: StepperType = ({children, currentStepIndex = -1, completed = false}) => {
    const progress = getProgress(currentStepIndex, children.length)

    return (
        <Container stepCount={children.length}>
            <Line>
                <ProgressLine progress={progress} />
            </Line>
            <Steps>
                {React.Children.map(children, (step, index) => {
                    return (
                        <StepperContext.Provider value={{currentStepIndex, index, completed}}>
                            {step}
                        </StepperContext.Provider>
                    )
                })}
            </Steps>
        </Container>
    )
}

Stepper.Step = Step

export {Stepper}
