import {colors, Spacer, Typography} from 'nf-ui'
import React from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {v4} from 'uuid'
import {useMutationUseInAppData} from '~/apollo/queries/UseInAppData'
import {GoogleButton} from '~/components/GoogleButton'
import ADP from '~/components/Integrations/logos/ADP.png'
import BasicData from '~/components/Integrations/logos/BasicData.png'
import CSV from '~/components/Integrations/logos/CSV.png'
import EXCEL from '~/components/Integrations/logos/EXCEL.png'
import Paychex from '~/components/Integrations/logos/Paychex.png'
import Paylocity from '~/components/Integrations/logos/Paylocity.png'
import Workday from '~/components/Integrations/logos/Workday.png'
import {ModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {useGoogleSheetsPicker} from '~/components/useGoogleSheetPicker'
import {DataSourceFileType, DataSourceType} from '~/objectTypes'
import {useFileInput} from '../Adminland/ConfigureDataSource/useFileInput'
import {ConfigureDataSourceModal} from '../Adminland/ConfigureDataSourceModal'
import {usePrimaryDataSource} from '../Adminland/ConnectPrimaryDataSource/ConnectPrimaryDataSource'
import {useParseCsv} from '../Adminland/useParseCsv'

const ConnectionButtonContainer = styled.button`
    display: flex;
    background-color: ${(props) => (props.disabled ? colors.lightGray : colors.white)};
    outline: none;
    border-radius: 4px;
    width: 202px;
    height: 56px;
    border: 1px solid ${colors.darkGray};
    padding: 0 12px;
    transition: 0.3s background-color ease-in-out;
    align-items: center;
    opacity: ${(props) => (props.disabled ? '60%' : '100%')};
    :hover {
        ${(props) => !props.disabled && 'cursor: pointer;'}
        background-color: ${colors.lightGray};
    }
`

const ButtonGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
`

const Logo = styled.img`
    width: 40px;
    height: 40px;
`

type Connection = {id: string; label: string; logo: string}

const HR_CONNECTIONS: Connection[] = [
    {id: 'adp', label: 'ADP', logo: ADP},
    {id: 'paychex', label: 'Paychex', logo: Paychex},
    {id: 'paylocity', label: 'Paylocity', logo: Paylocity},
    {id: 'workday', label: 'Workday', logo: Workday},
]

const ConnectionButton = ({
    label = '',
    logo,
    onClick,
    disabled,
    ...rest
}: {
    label?: string
    logo?: string
    onClick?: () => void
    disabled?: boolean
}) => {
    return (
        <ConnectionButtonContainer onClick={disabled ? () => {} : onClick} disabled={disabled} {...rest}>
            {logo && (
                <>
                    <Logo src={logo} />
                    <Spacer width={8} />
                </>
            )}
            <Typography.Paragraph bottomMargin={false} style={{marginTop: '-2px'}}>
                {label}
            </Typography.Paragraph>
        </ConnectionButtonContainer>
    )
}

const ConnectSheetButton = () => {
    const {setData} = usePrimaryDataSource()
    const {handlePick, loading} = useGoogleSheetsPicker({})

    const onClick = async () => {
        const saveResult = await handlePick()
        if (saveResult) {
            setData({
                type: DataSourceType.GOOGLE_SHEETS,
                ...saveResult,
            })
        }
    }

    return <GoogleButton large label="Google Sheet" loading={loading} onClick={onClick} />
}

const UploadFile = ({fileType, label, logo}: {fileType: DataSourceFileType | null; label?: string; logo?: string}) => {
    const {setData} = usePrimaryDataSource()
    const parseCsv = useParseCsv()

    const accept = fileType === DataSourceFileType.CSV ? '.csv' : '.xls,.xlsx'

    const {inputProps, openPicker, setAccept} = useFileInput(async ([file]) => {
        const data = await parseCsv(file)
        setData({
            data,
            type: DataSourceType.DIRECT_UPLOAD,
            file,
            fileType,
        })
    })

    return (
        <>
            <ConnectionButton
                label={label}
                onClick={() => {
                    console.log('Clicking')
                    setAccept(accept)
                    openPicker()
                }}
                logo={logo}
            />
            <input {...inputProps} />
        </>
    )
}

export const ConnectData = ({organisationIdStr}: {organisationIdStr: string}) => {
    const {modal, dataSourceData, setModal} = usePrimaryDataSource()
    const {push} = useHistory()
    const [reconnectPrimaryDataSource] = useQueryParam('reconnectPrimaryDataSource')
    const [, callUseInAppData] = useMutationUseInAppData()

    const requiredFields = () => {
        const fields = [{name: 'Email address'}, {name: 'First name'}, {name: 'Last name'}]
        return fields.map((field) => ({
            idStr: v4(),
            name: field.name,
            required: true,
        }))
    }

    return (
        <>
            <Typography.Heading>Connect your data</Typography.Heading>
            <Spacer height={16} />
            <Typography.Subheading>This is the data that will be used to populate your directory</Typography.Subheading>
            <Spacer height={32} />
            <Typography.Label>Start from scratch</Typography.Label>
            <Spacer height={32} />
            <ButtonGrid>
                <ConnectionButton
                    key="BasicData"
                    label="Basic Data"
                    logo={BasicData}
                    onClick={async () => {
                        await callUseInAppData(organisationIdStr)
                        window.location.href = `/${organisationIdStr}/admin`
                    }}
                ></ConnectionButton>
            </ButtonGrid>
            <Spacer height={32} />
            <Typography.Label>Connect a file</Typography.Label>
            <Spacer height={32} />
            <ButtonGrid>
                <ConnectSheetButton />
                <UploadFile logo={EXCEL} label="XLS" fileType={DataSourceFileType.XLS} />
                <UploadFile logo={CSV} label="CSV" fileType={DataSourceFileType.CSV} />
            </ButtonGrid>
            <Spacer height={32} />
            <Typography.Label>Contact us to connect your HR provider</Typography.Label>
            <Spacer height={32} />
            <ButtonGrid>
                {HR_CONNECTIONS.map((connection) => (
                    <ConnectionButton
                        key={connection.id}
                        label={connection.label}
                        logo={connection.logo}
                        disabled={true}
                    />
                ))}
            </ButtonGrid>
            <ModalNavigator
                modal={modal}
                setModal={(modal) => setModal(modal || '')}
                onClose={() => {
                    if (reconnectPrimaryDataSource) push('..')
                    else push('/')
                }}
            >
                <ConfigureDataSourceModal
                    key="configurePrimaryDataSource"
                    dataSourceData={dataSourceData!}
                    primary={true}
                    fetchStepFields={requiredFields}
                    organisationIdStr={organisationIdStr}
                />
            </ModalNavigator>
        </>
    )
}
