import {Data as PopperData, Placement, Offset, PopperOptions} from 'popper.js'
import {getBoundaries, getPopperOffsets, runModifiers} from 'popper.js/dist/umd/popper-utils.js'
import {from} from 'fromfrom'

//Typings are terrible for popper
type Boundary = Offset & {
    bottom: number
    right: number
}

const previousPlacement = Symbol('PreviousPlacement')

/**
 * Modifies are stateless so this function will fetch state saved on the instance
 *
 * @param {PopperData} data
 * @returns {Placement[][]}
 */
function getPreviousPlacements(data: PopperData): Placement[][] {
    //@ts-ignore
    if (data.instance.state[previousPlacement] == null) data.instance.state[previousPlacement] = []

    //@ts-ignore
    return data.instance.state[previousPlacement]
}

function* getValidPlacements(popperOffsets: Boundary, boundaries: Boundary): IterableIterator<Placement> {
    const overflowsRight = Math.floor(popperOffsets.right) >= Math.floor(boundaries.right)
    const overflowsBottom = Math.floor(popperOffsets.bottom) >= Math.floor(boundaries.bottom)
    const overflowsLeft = Math.floor(popperOffsets.left) < Math.floor(boundaries.left)
    const overflowsTop = Math.floor(popperOffsets.top) < Math.floor(boundaries.top)

    if (!overflowsBottom && !overflowsRight) yield 'bottom-start'
    if (!overflowsBottom && !overflowsLeft) yield 'bottom-end'
    if (!overflowsTop && !overflowsRight) yield 'top-start'
    if (!overflowsTop && !overflowsLeft) yield 'top-end'
    if (!overflowsBottom) yield 'bottom'
    if (!overflowsTop) yield 'top'
    if (!overflowsLeft) yield 'left'
    if (!overflowsRight) yield 'right'
}

function fallbackPlacement(previousPlacements: Placement[][], preposedPlacement: Placement) {
    const commonPlacements = from(previousPlacements)
        .flatMap((placements) => placements)
        .distinct()
        .filter((placement) => previousPlacements.every((placements) => placements.includes(placement)))
        .toArray()

    if (commonPlacements.length === 0 || commonPlacements.includes(preposedPlacement)) return preposedPlacement
    return commonPlacements[0]
}

function isInLoop(placements: Placement[][]) {
    const groupedPlacements = from(placements)
        .map((placement) => placement.join())
        .groupBy((v) => v)
        .toArray()

    return groupedPlacements.reduce((prevValue, currValue) => prevValue || currValue.items.length > 1, false)
}

export function customFlipModifier(data: PopperData, options: PopperOptions) {
    if (data.flipped) return data

    data.flipped = true

    const boundaries: Boundary = getBoundaries(
        data.instance.popper,
        data.instance.reference,
        //@ts-ignore
        options.padding,
        //@ts-ignore
        options.boundariesElement,
        //@ts-ignore
        data.positionFixed,
    )

    const popperOffsets = data.offsets.popper as Boundary
    const validPlacements = Array.from(getValidPlacements(popperOffsets, boundaries))
    const previousPlacements = getPreviousPlacements(data)

    previousPlacements.unshift(validPlacements)
    if (previousPlacements.length > 5) previousPlacements.pop()

    if (isInLoop(previousPlacements)) return data

    if (validPlacements.includes(data.placement) || !validPlacements.length) {
        previousPlacements.length = 0
        return data
    }

    let [newPlacement] = validPlacements

    //If the previous placements have started to increase we're in a loop as it clears when placement has stabalized
    //Attempt to find a new placement that is common to previous placement calculations as that tends to be a good fallback
    if (previousPlacements.length > 1) {
        newPlacement = fallbackPlacement(previousPlacements, newPlacement)
    }

    data.instance.options.placement = newPlacement
    data.placement = newPlacement

    const newOffset = getPopperOffsets(data.instance.popper, data.offsets.reference, newPlacement)

    data.offsets.popper = {
        ...data.offsets.popper,
        ...newOffset,
    }

    //@ts-ignore modifiers is also a property of the instance
    return runModifiers(data.instance.modifiers, data, 'flip')
}
