import {blendWhite, Icon, Spacer, Typography, useAnalyticsEventTracker, useTheme} from 'nf-ui'
import SvgChevronDown from 'nf-ui/Icons/ChevronDown'
import SvgChevronUp from 'nf-ui/Icons/ChevronUp'
import pluralize from 'pluralize'
import React from 'react'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {UserlandActionBar} from '../ActionBar'
import {useFilterCategory} from '../useFilterCategory'
import {FullOrgChartData_organisation_profiles} from '../__types__/FullOrgChartData'
import {useCycleMatchingProfiles} from './ActionBar/useCycleMatchingProfiles'
import {useMatchingProfiles} from './ActionBar/useMatchingProfiles'
import {OrgChartActions} from './Actions'
import {useOrgChartData} from './useOrgChartData'
import {useResetFilters} from './useResetFilters'

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
`

const CycleButton = styled.div`
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: ${(props) => blendWhite(props.theme.primary.color, 0.75)};

    :hover {
        cursor: pointer;
    }
`

const useSelectedCategory = () => {
    const orgIdStr = useOrganisationIdStr()
    const {homeItems} = useFilterCategory(orgIdStr)
    const [categoryIdStr] = useQueryParam<string>('categoryId')
    if (!categoryIdStr) return {category: null}

    const childCategories = homeItems.flatMap((homeItem) => homeItem.childCategories || [])
    const category = childCategories.find((childCategory) => categoryIdStr === childCategory.idStr)

    return {category}
}

const ActionBarLabel = ({
    profiles,
    branchView = false,
}: {
    profiles: FullOrgChartData_organisation_profiles[]
    branchView?: boolean
}) => {
    const theme = useTheme()
    const [search] = useQueryParam<string>('search')
    const {category} = useSelectedCategory()
    const {decrementMatchIndex, incrementMatchIndex, matchIndex} = useCycleMatchingProfiles()
    const {profilesFiltered, matchedCount: profilesMatchingFilter} = useMatchingProfiles(profiles)
    const trackEvent = useAnalyticsEventTracker()

    const profileCount = profiles.length
    const matchedCount = profilesFiltered ? profilesMatchingFilter : 0
    const count = profilesFiltered ? matchedCount : profileCount

    const searchLabel = search ? ` matching '${search}' ` : ''
    const categoryLabel = category ? ` in ${category.label}` : ''
    const orgChartLabel = !category && !branchView ? ' in Org chart' : ''
    const branchLabel = branchView ? ' in branch:' : ''

    const profileMatchDescriptor = `${pluralize(
        'person',
        count,
    )}${searchLabel}${categoryLabel}${orgChartLabel}${branchLabel}`

    if (matchedCount < 2) {
        return (
            <Typography.Paragraph bottomMargin={false} color={theme.primary.textColor}>
                {`${count} ${profileMatchDescriptor}`}
            </Typography.Paragraph>
        )
    }

    return (
        <LabelWrapper>
            <Typography.Paragraph bottomMargin={false} color={theme.primary.textColor}>
                {`${matchIndex} / ${matchedCount}`}
            </Typography.Paragraph>
            <Spacer width={8} />
            <CycleButton>
                <Icon
                    icon={SvgChevronUp}
                    tint={theme.primary.textColor}
                    onClick={() => {
                        decrementMatchIndex(matchedCount)
                        trackEvent('orgCharts', {
                            page: 'full-view',
                            component: 'button',
                            type: 'click',
                            action: 'up',
                            name: 'cycle_search_results',
                        })
                    }}
                />
            </CycleButton>
            <Spacer width={4} />
            <CycleButton>
                <Icon
                    icon={SvgChevronDown}
                    tint={theme.primary.textColor}
                    onClick={() => {
                        incrementMatchIndex(matchedCount)
                        trackEvent('orgCharts', {
                            page: 'full-view',
                            component: 'button',
                            type: 'click',
                            action: 'down',
                            name: 'cycle_search_results',
                        })
                    }}
                />
            </CycleButton>
            <Spacer width={8} />
            <Typography.Paragraph bottomMargin={false} color={theme.primary.textColor}>
                {profileMatchDescriptor}
            </Typography.Paragraph>
        </LabelWrapper>
    )
}

export const ActionBar = () => {
    const {resetFilters} = useResetFilters()
    const {data, topLevelProfiles, uniqueProfiles, branchView} = useOrgChartData()

    if (branchView) {
        return (
            <UserlandActionBar
                showSort={false}
                showGroupMessage={false}
                label={<ActionBarLabel profiles={uniqueProfiles} branchView />}
                right={<OrgChartActions data={data} />}
                pills={topLevelProfiles}
                onPillChange={resetFilters}
                pillLabelExtractor={(profile) => profile.fullName}
            />
        )
    }

    return (
        <UserlandActionBar
            showSort={false}
            showGroupMessage={false}
            label={<ActionBarLabel profiles={uniqueProfiles} />}
            right={<OrgChartActions data={data} />}
        />
    )
}
