import {from} from 'fromfrom'
import {colors, Typography, Button, Lightbox, Spacer, useAnalyticsEventTracker} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import React, {useState} from 'react'
import {DropResult} from 'react-beautiful-dnd'
import styled from 'styled-components'
import {FieldType} from '~/objectTypes'
import {DraggableItem} from '~/pages/Adminland/DraggableItem'
import {DraggableList} from '~/pages/Adminland/DraggableList'
import {reorderPriorityItems} from '~/util/priority'
import {FullOrgChartData_organisation_fields, FullOrgChartData} from '../../__types__/FullOrgChartData'
import {DetailConfig} from '../useDetailConfig'
import {useDisplayOptions} from '../useDisplayOptions'

const LightboxHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

type DraggableDetail = {
    idStr: string
    visible: boolean
    label: string
    priority: number
    type: FieldType | null
}

const dragEndFn = (dragCallback: (reorderedItems: DetailConfig[]) => void) => {
    return (result: DropResult, draggableItems: DraggableDetail[]) => {
        if (!result.destination) {
            return
        }
        const {source, destination} = result

        if (source.droppableId !== destination.droppableId) return

        const reorderedPriorityItems = reorderPriorityItems(
            draggableItems,
            result.source.index,
            result.destination.index,
        )
        const detailConfig = reorderedPriorityItems.map((item) => {
            return {fieldIdStr: item.idStr, visible: item.visible}
        })

        dragCallback(detailConfig)
    }
}

const FieldList = ({fields}: {fields: FullOrgChartData_organisation_fields[]}) => {
    const {detailConfig, toggleDetailVisibility, setDetailConfig} = useDisplayOptions()
    const trackEvent = useAnalyticsEventTracker()

    const firstName = fields.find((field) => field.type === FieldType.FIRST_NAME)!
    const name = {...firstName, label: 'Name'}
    const categories = fields.filter((field) => field.type === FieldType.CATEGORY && field.visible)

    const draggableItems = from([name, ...categories])
        .sortBy((item) => item.priority)
        .map((item) => {
            const configDetail = detailConfig?.find((config) => config.fieldIdStr === item.idStr)
            const priority = detailConfig?.findIndex((config) => config.fieldIdStr === configDetail?.fieldIdStr) || 0

            return {
                idStr: item.idStr,
                visible: configDetail?.visible || false,
                label: item.label,
                priority,
                type: item.type,
            }
        })
        .sortBy((item) => item.priority)
        .toArray()

    const toggleVisibility = (field: DraggableDetail) => {
        if (field.type === FieldType.FIRST_NAME) return undefined
        return (fieldIdStr: string) => {
            toggleDetailVisibility(fieldIdStr)

            trackEvent('orgCharts', {
                page: 'full-view',
                component: 'lightbox',
                type: 'click',
                action: 'edit',
                name: 'display_options',
            })
        }
    }

    const onDragEnd = dragEndFn((reorderedItems) => setDetailConfig(reorderedItems))

    return (
        <>
            <DraggableList onDragEnd={(result) => onDragEnd(result, draggableItems)} droppableId="CustomDetails">
                {draggableItems.map((field, index) => (
                    <DraggableItem
                        toggleVisibility={toggleVisibility(field)}
                        item={field}
                        index={index}
                        key={field.idStr}
                        expanded={[]}
                        setExpanded={() => []}
                        editable={false}
                    />
                ))}
            </DraggableList>
        </>
    )
}

export const CustomizeDetails = ({data}: {data: FullOrgChartData | undefined}) => {
    const [lightboxOpen, setLightboxOpen] = useState(false)

    if (!data) return null

    const fields = data.organisation.fields
    return (
        <>
            <Button
                variant="tertiary"
                color={colors.white}
                onClickAnalyticsEvent="orgCharts"
                onClickAnalyticsData={{
                    page: 'full-view',
                    component: 'button',
                    type: 'click',
                    action: 'open',
                    name: 'display_options',
                }}
                onClick={() => setLightboxOpen(true)}
            >
                Display options
            </Button>
            <Lightbox open={lightboxOpen} width={560} key="CustomizeDetailsLightBox">
                <LightboxHeader>
                    <Typography.Heading>Display options</Typography.Heading>
                    <Button
                        variant="tertiary"
                        onClick={() => {
                            setLightboxOpen(false)
                        }}
                        onClickAnalyticsEvent="orgCharts"
                        onClickAnalyticsData={{
                            page: 'full-view',
                            component: 'button',
                            type: 'click',
                            action: 'close',
                            name: 'display_options',
                        }}
                    >
                        Close <Button.Icon icon={SvgClose} />
                    </Button>
                </LightboxHeader>
                <Spacer height={16} />
                <Typography.Label>Toggle and reorder the displayed fields</Typography.Label>
                <Spacer height={8} />
                <FieldList fields={fields} />
            </Lightbox>
        </>
    )
}
