import * as React from 'react'
const SvgAlert = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgAlert(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" ref={svgRef} {...props}>
            <path d="M0 8a8 8 0 1116 0A8 8 0 110 8z" fill="#D5DBDF" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 3.464a.9.9 0 00-.9.9v4.707a.9.9 0 101.8 0V4.364a.9.9 0 00-.9-.9zm0 7.272a.9.9 0 100 1.8.9.9 0 000-1.8z"
                fill="#fff"
            />
        </svg>
    )
})
export default SvgAlert
