import * as React from 'react'
const SvgChevronDown = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgChevronDown(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M8.375 9.62l2.49-2.49a.53.53 0 10-.75-.75L8 8.496 5.885 6.38a.529.529 0 10-.75.75l2.49 2.49a.53.53 0 00.75 0z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgChevronDown
