import {from} from 'fromfrom'
import SvgChevronDown from 'nf-ui/Icons/ChevronDown'
import SvgChevronUp from 'nf-ui/Icons/ChevronUp'
import {useState} from 'react'

export function useTableSort<Column extends string, Value extends unknown>({
    data,
    defaultColumn,
}: {
    data: Record<Column, Value>[]
    defaultColumn?: Column
}) {
    const [activeColumn, setActiveColumn] = useState(defaultColumn)
    const [activeDirection, setActiveDirection] = useState<'ASC' | 'DESC'>('ASC')

    const toggleSortDirection = () => {
        setActiveDirection(activeDirection === 'ASC' ? 'DESC' : 'ASC')
    }

    let sortedData = from(data)
    if (activeColumn) {
        sortedData = sortedData.sortBy((row) =>
            typeof row[activeColumn] === 'string' ? (row[activeColumn] as string).toLowerCase() : row[activeColumn],
        )
        if (activeDirection === 'DESC') {
            sortedData = sortedData.reverse()
        }
    }

    const onClickCell = ({columnIndex, rowIndex}: {columnIndex: number; rowIndex?: number}) => {
        if (rowIndex != null && rowIndex !== 0) return

        const clickedColumn = Object.keys(data[0])[columnIndex] as Column
        if (activeColumn === clickedColumn) {
            toggleSortDirection()
        } else {
            setActiveColumn(clickedColumn)
            setActiveDirection('ASC')
        }
    }

    const headerIcon = activeDirection === 'ASC' ? SvgChevronUp : SvgChevronDown

    return {
        onClickCell,
        sortedData: sortedData.toArray(),
        headerIcon: activeColumn ? {column: activeColumn, icon: headerIcon} : undefined,
    }
}
