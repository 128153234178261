import html2canvas from 'html2canvas'
import {jsPDF} from 'jspdf'
import {useState} from 'react'

export type PrintType = 'png' | 'jpeg' | 'pdf'

const typeMimeMap: Record<PrintType, string> = {
    png: 'image/png',
    jpeg: 'image/jpeg',
    pdf: 'applciation/pdf',
}

const createPageWrapper = () => {
    const app = document.getElementById('app')

    const hidden = document.createElement('div')
    hidden.id = 'printContainer'
    hidden.style.position = 'absolute'
    hidden.style.top = '-10000px'

    const page = document.createElement('div')
    page.style.width = '24.6cm'
    page.style.padding = '70px'
    page.style.boxSizing = 'border-box'

    app?.appendChild(hidden)
    hidden.appendChild(page)

    return page
}

const createPageHeader = (headerHtmlString?: string) => {
    const header = document.createElement('div')
    header.innerHTML = headerHtmlString || ''

    return header
}

export const usePrintElement = () => {
    const [loading, setLoading] = useState(false)

    const cleanup = () => {
        const hiddenContainer = document.getElementById('printContainer')
        if (hiddenContainer) {
            hiddenContainer.remove()
        }
        setLoading(false)
    }

    const canvasToPDF = (canvas: HTMLCanvasElement, downloadName: string, page: HTMLElement) => {
        const imgData = canvas.toDataURL('image/jpeg', 1.0)
        const imgWidth = 210
        const pageHeight = 295
        const imgHeight = (canvas.height * imgWidth) / canvas.width
        const doc = new jsPDF('p', 'mm', 'a4')

        let heightLeft = imgHeight
        let position = 0

        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight)

        heightLeft -= pageHeight

        while (heightLeft >= 0) {
            position += heightLeft - imgHeight // top padding for other pages
            doc.addPage()
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
            heightLeft -= pageHeight
        }

        doc.save(`${downloadName}.pdf`)
        cleanup()
    }

    const canvasToImage = (canvas: HTMLCanvasElement, type: PrintType, downloadName: string) => {
        const imageFromBlob = (blob: Blob | null) => {
            const a = document.createElement('a')
            a.download = `${downloadName}.${type}`
            a.href = window.URL.createObjectURL(blob)
            a.click()
            cleanup()
        }

        canvas.toBlob(imageFromBlob, typeMimeMap[type])
    }

    const wrapElement = (node: Node) => {
        const div = document.createElement('div')
        div.appendChild(node)

        return div
    }

    const printElement = async ({
        elementId,
        downloadName,
        type,
        header: headerHtml,
        preProcessElement,
    }: {
        elementId: string
        downloadName: string
        type: PrintType
        header?: string
        preProcessElement?: (element: HTMLElement) => void
    }) => {
        const node = document.getElementById(elementId)?.cloneNode(true)
        if (!node) return
        setLoading(true)

        const wrappedElement = wrapElement(node)
        preProcessElement?.(wrappedElement)

        const page = createPageWrapper()
        const header = createPageHeader(headerHtml)

        page.appendChild(header)
        page.appendChild(wrappedElement)

        try {
            const canvas = await html2canvas(page, {
                useCORS: true,
                imageTimeout: 10000,
                allowTaint: false,
            })

            if (type === 'pdf') return canvasToPDF(canvas, downloadName, page)

            return canvasToImage(canvas, type, downloadName)
        } catch (e) {
            cleanup()
        }
    }

    return {
        printElement,
        printing: loading,
    }
}
