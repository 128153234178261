import {gql, useQuery} from '@apollo/client'
import {CategoryData, CategoryDataVariables} from './__types__/CategoryData'

const CATEGORY_DATA = gql`
    query CategoryData($categoryIdStr: String!) {
        category: mCategory(idStr: $categoryIdStr) {
            idStr
            name
            profilesCount
            childCategories {
                idStr
                label
                profilesCount
                profiles {
                    idStr
                    firstName
                    lastName
                    fullName
                    photos {
                        idStr
                        thumbUrl
                        priority
                    }
                }
            }
        }
    }
`

export const useQueryCategoryData = (categoryIdStr: string) => {
    const query = useQuery<CategoryData, CategoryDataVariables>(CATEGORY_DATA, {
        variables: {
            categoryIdStr,
        },
    })

    return [query]
}
