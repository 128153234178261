import {gql, useMutation} from '@apollo/client'
import produce from 'immer'
import {useAlert} from 'react-alert'
import {useQueryParam} from 'use-query-params'
import {CURRENT_ORGANISATION_DATA} from '~/components/CurrentOrganisationContext'
import {CurrentOrganisationData} from '~/components/__types__/CurrentOrganisationData'
import {GoogleSheetsData} from '~/objectTypes'
import {getGraphQLErrorMessage} from '~/util'
import {DirectUploadData} from '../AddDataSourceModal'
import {useConfigureDataSource} from '../useConfigureDataSource'
import {ConnectCsv, ConnectCsvVariables} from './__types__/ConnectCsv'
import {ConnectGoogleSheet, ConnectGoogleSheetVariables} from './__types__/ConnectGoogleSheet'

export const CONNECT_CSV = gql`
    mutation ConnectCsv($organisationIdStr: String!, $selectedColumns: [SelectedColumn!]!, $fileInput: Upload!) {
        connectCSV(organisationIdStr: $organisationIdStr, selectedColumns: $selectedColumns, fileInput: $fileInput)
    }
`

export const CONNECT_GOOGLE_SHEET = gql`
    mutation ConnectGoogleSheet(
        $organisationIdStr: String!
        $selectedColumns: [SelectedColumn!]!
        $data: GoogleSheetsData!
        $reconnectPrimaryDataSource: Boolean!
    ) {
        connectGoogleSheet(
            organisationIdStr: $organisationIdStr
            selectedColumns: $selectedColumns
            data: $data
            reconnectPrimaryDataSource: $reconnectPrimaryDataSource
        )
    }
`

export const usePrimaryDataSourceMutations = () => {
    const {state, organisationIdStr} = useConfigureDataSource()
    const [reconnectPrimaryDataSource] = useQueryParam('reconnectPrimaryDataSource')

    const alert = useAlert()

    const [connectCsvMutation, {loading: csvLoading}] = useMutation<ConnectCsv, ConnectCsvVariables>(CONNECT_CSV, {
        onError: (error) => alert.error(getGraphQLErrorMessage(error)),
        update: (cache) => {
            const currentOrganisations = cache.readQuery<CurrentOrganisationData>({
                query: CURRENT_ORGANISATION_DATA,
            })
            if (!currentOrganisations) return

            cache.writeQuery<CurrentOrganisationData>({
                query: CURRENT_ORGANISATION_DATA,
                data: produce(currentOrganisations, (draft) => {
                    draft.organisations.forEach(
                        (organsiation) => (organsiation.primaryDataSourceType = state.dataSourceData.type),
                    )
                    return draft
                }),
            })
        },
        refetchQueries: [{query: CURRENT_ORGANISATION_DATA}],
        awaitRefetchQueries: true,
    })

    const [connectGoogleSheetMutation, {loading: googleSheetLoading}] = useMutation<
        ConnectGoogleSheet,
        ConnectGoogleSheetVariables
    >(CONNECT_GOOGLE_SHEET, {
        onError: (error) => alert.error(getGraphQLErrorMessage(error)),
        update: (cache) => {
            const currentOrganisations = cache.readQuery<CurrentOrganisationData>({
                query: CURRENT_ORGANISATION_DATA,
            })
            if (!currentOrganisations) return

            cache.writeQuery<CurrentOrganisationData>({
                query: CURRENT_ORGANISATION_DATA,
                data: produce(currentOrganisations, (draft) => {
                    draft.organisations.forEach(
                        (organsiation) => (organsiation.primaryDataSourceType = state.dataSourceData.type),
                    )
                    return draft
                }),
            })
        },
        refetchQueries: [{query: CURRENT_ORGANISATION_DATA}],
        awaitRefetchQueries: true,
    })

    const connectCsv = async () => {
        const {selectedColumns} = state
        const dataSourceData = state.dataSourceData as DirectUploadData

        return connectCsvMutation({
            variables: {
                organisationIdStr,
                selectedColumns: Object.values(selectedColumns),
                fileInput: dataSourceData.file,
            },
        })
    }

    const connectGoogleSheet = async () => {
        const {selectedColumns} = state
        const dataSourceData = state.dataSourceData as GoogleSheetsData
        const data = {
            refreshToken: dataSourceData.refreshToken,
            sheetId: dataSourceData.sheetId,
        }

        return connectGoogleSheetMutation({
            variables: {
                organisationIdStr,
                selectedColumns: Object.values(selectedColumns),
                data,
                reconnectPrimaryDataSource: !!reconnectPrimaryDataSource,
            },
        })
    }

    return {
        connectCsv,
        connectGoogleSheet,
        loading: csvLoading || googleSheetLoading,
    }
}
