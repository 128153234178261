export function wait(ms = 50) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export type SpinWaitOptions = {
    /**
     * Time between checks
     *
     * @type {number}
     */
    interval?: number

    /**
     * Check to wait
     *
     * @type {number}
     */
    maxWait?: number
}

/**
 * Waits in a loop until the stopFn returns true.
 * Will throw if the elapsed time is passed.
 * Use when you can't rely on useEffect because it's outside of react
 *
 * @export
 * @param {() => boolean} stopFn
 * @param {SpinWaitOptions} [options={}]
 */
export async function spinWait(stopFn: () => boolean, options: SpinWaitOptions = {}) {
    const {interval = 50, maxWait = 5000} = options
    let resolved = false
    const waitFn = async () => {
        while (!stopFn()) {
            await wait(interval)
        }
        resolved = true
    }

    await Promise.race([
        waitFn(),
        //If you don't wait for resolved to be set to true it will annoy you when debugging
        wait(maxWait).then(() => (!resolved ? Promise.reject(`Max timeout of ${maxWait}ms elapsed`) : null)),
    ])
}

/**
 * Will retry an async function until it succeeds or reaches max retry count
 *
 * @export
 * @template T
 * @param {() => Promise<T>} fn
 * @param {number} [maxRetries=5]
 * @returns {Promise<T>}
 */
export async function asyncRetry<T>(fn: () => Promise<T>, maxRetries = 5) {
    let lastError: any
    for (let i = 0; i < maxRetries; i++) {
        try {
            //Don't remove the await, has to break within this block
            return await fn()
        } catch (err) {
            lastError = err
        }
    }
    throw lastError
}

/**
 * Factory function to create an async 'semaphore' (it's not but it logically acts like one).
 * This will take in a function that will be called. The first call will start the promise and all further
 * requests will then be forwarded until the initial execution completes.
 *
 * @export
 * @template TResult value that the function will return
 * @template TParams parameter types for the input
 * @param {(...args: TParams) => Promise<TResult>} fn the wrapped function that will have forward all futures
 * @returns
 */
export function asyncSemaphore<TResult, TParams extends any[] = any[]>(fn: (...args: TParams) => Promise<TResult>) {
    let executor: Promise<TResult> | null = null
    return async (...args: TParams) => {
        if (executor) return executor
        executor = fn(...args)
        const result = await executor
        // eslint-disable-next-line require-atomic-updates
        executor = null
        return result
    }
}
