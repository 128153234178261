import React, {FC, useEffect, useState} from 'react'
import styled from 'styled-components'
import SvgEditThin from 'nf-ui/Icons/EditThin'
import SvgCheck from 'nf-ui/Icons/Check'
import SvgClose from 'nf-ui/Icons/Close'
import {Spinner} from 'nf-ui'

const MenuEntry = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: 'halyard-display';
    font-size: 16px;
    font-weight: 300;
    padding: 0px 0px;
    background-color: white;
    border-top: 1px solid #d5dbdf;
    border-width: 1px 0px;
    border-radius: 0px;
    > * {
        padding: 16px 8px;
    }
    &.selected {
        font-weight: 500;
    }
    > :not(input) {
        cursor: pointer;
    }
    .edit {
        padding: 18px 8px 16px;
    }
    .check {
        margin: -4px 0px -2px;
        padding: 6px 6px 0px;
    }
    &:hover {
        background-color: #eee;
    }
    &.can-initiate-edit:not(:hover) {
        padding: 0px 32px 0px 0px;
    }
    &:not(:hover),
    &:not(.can-initiate-edit) {
        > .edit {
            display: none;
        }
    }
    &.sticky-top {
        position: sticky;
        top: 0px;
    }
    &.sticky-bottom {
        position: sticky;
        bottom: 0px;
        padding: 3px 0px;
        color: #3c8eff;
        font-weight: 500;
    }
`

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    > * {
        height: 20px;
    }
`

export interface PickCategoryProps {
    availableValues: string[]
    selectedValues: string[]
    searchValue: string
    mutating: {mutation: string; col?: number; row?: number} | undefined
    setMutating: (value: {mutation: string; col?: number; row?: number} | undefined) => void
    pickValue: (values: string[], options?: {removeMissingValues?: boolean}) => Promise<void>
    addValue: (value: string, options?: {removeMissingValues?: boolean}) => Promise<void>
    updateValue: (newValue: string, oldValue: string) => Promise<void>
    pickMultiple?: boolean
}

export const PickCategory: FC<PickCategoryProps> = (props: PickCategoryProps) => {
    const [editingValue, setEditingValue] = useState<string | undefined>(undefined)
    const [newValue, setNewValue] = useState('')
    const hasExactMatch = props.availableValues.some((value) => value === props.searchValue)
    const filteredValues = props.availableValues.filter((value) =>
        props.searchValue.split(' ').every((part) => value.toLowerCase().includes(part.toLowerCase())),
    )
    const initialScrollItemRef = React.createRef<HTMLDivElement>()

    const updateValue = async () => {
        props.setMutating({mutation: 'update-value'})
        await props.updateValue(newValue, editingValue!)
        props.setMutating(undefined)
    }
    useEffect(() => {
        initialScrollItemRef?.current?.scrollIntoView({block: 'center'})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div style={{maxHeight: '38vh', minHeight: '43px', minWidth: '200px', overflow: 'auto'}}>
            {filteredValues.map((value, index) => {
                const isSelected = props.selectedValues.includes(value)
                return value !== editingValue ? (
                    <MenuEntry
                        className={`${editingValue ? '' : 'can-initiate-edit'} ${isSelected ? 'selected' : ''}`}
                        key={index}
                        ref={isSelected ? initialScrollItemRef : undefined}
                    >
                        {props.mutating?.mutation !== `pick-value-${value}` ? (
                            <span
                                onClick={async () => {
                                    if (isSelected && !props.pickMultiple) return
                                    props.setMutating({mutation: `pick-value-${value}`})
                                    await props.pickValue(
                                        isSelected ? props.selectedValues.filter((v) => v !== value) : [value],
                                        {removeMissingValues: !props.pickMultiple || isSelected},
                                    )
                                    props.setMutating(undefined)
                                }}
                            >
                                <span>{value}</span>
                                {isSelected && <SvgCheck className="check"></SvgCheck>}
                                <span></span>
                            </span>
                        ) : (
                            <SpinnerContainer>
                                <Spinner></Spinner>
                            </SpinnerContainer>
                        )}
                        <SvgEditThin
                            className="edit"
                            onClick={() => {
                                setNewValue(value)
                                setEditingValue(value)
                            }}
                        ></SvgEditThin>
                    </MenuEntry>
                ) : (
                    <MenuEntry key={index}>
                        <input
                            type="text"
                            value={newValue}
                            onChange={(e) => setNewValue(e.target.value)}
                            onKeyUp={(e) => e.key === 'Enter' && editingValue !== newValue && updateValue()}
                            autoFocus
                        ></input>

                        {props.mutating?.mutation !== 'update-value' ? (
                            <span>
                                {editingValue !== newValue && <SvgCheck onClick={updateValue}></SvgCheck>}
                                <SvgClose onClick={() => setEditingValue('')}></SvgClose>
                            </span>
                        ) : (
                            <SpinnerContainer>
                                <Spinner></Spinner>
                            </SpinnerContainer>
                        )}
                    </MenuEntry>
                )
            })}
            {props.selectedValues.length && !props.searchValue ? (
                <MenuEntry className="sticky-bottom">
                    {props.mutating?.mutation !== 'clear-value' ? (
                        <span
                            onClick={async () => {
                                props.setMutating({mutation: 'clear-value'})
                                await props.pickValue([], {removeMissingValues: true})
                                props.setMutating(undefined)
                            }}
                        >
                            Clear Selection
                        </span>
                    ) : (
                        <SpinnerContainer>
                            <Spinner></Spinner>
                        </SpinnerContainer>
                    )}
                </MenuEntry>
            ) : null}
            {!hasExactMatch && props.searchValue && (
                <MenuEntry className="sticky-bottom">
                    {props.mutating?.mutation !== 'add-value' ? (
                        <span
                            onClick={async () => {
                                props.setMutating({mutation: 'add-value'})
                                await props.addValue(props.searchValue, {removeMissingValues: !props.pickMultiple})
                                props.setMutating(undefined)
                            }}
                        >
                            + New Category
                        </span>
                    ) : (
                        <SpinnerContainer>
                            <Spinner></Spinner>
                        </SpinnerContainer>
                    )}
                </MenuEntry>
            )}
        </div>
    )
}
