import {Spinner} from 'nf-ui'
import React, {CSSProperties} from 'react'
import styled from 'styled-components'

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const PageLoading: React.FC<{style?: CSSProperties}> = ({style}) => {
    return (
        <Container style={style} data-testid="PageLoading">
            <Spinner size={32} />
        </Container>
    )
}

export {PageLoading}
