import * as React from 'react'
const SvgReturn = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgReturn(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M3.44.83h7.05A5.51 5.51 0 0116 6.34a5.51 5.51 0 01-5.51 5.51H1.699l2.135 2.44a.53.53 0 01-.798.7l-2.9-3.32a.518.518 0 01-.002-.697L3.036 7.65a.529.529 0 01.745-.05.528.528 0 01.053.749l-2.135 2.44h8.791a4.451 4.451 0 004.45-4.45 4.451 4.451 0 00-4.45-4.45H3.44a.53.53 0 110-1.06z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgReturn
