import * as React from 'react'
const SvgProfile = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgProfile(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M16 15.913c-.013.028-.022.075-.04.08-.044.012-.093.004-.14.004H0c.013-.082.028-.165.038-.248.122-1.126.607-2.021 1.54-2.612.284-.18.584-.345.895-.46.727-.27 1.472-.49 2.197-.765.321-.122.631-.3.914-.502a.967.967 0 00.42-.897c-.022-.314.003-.631-.01-.946a.39.39 0 00-.108-.239c-.498-.485-.881-1.05-1.08-1.737-.044-.15-.08-.26-.244-.327-.123-.05-.232-.19-.304-.317a1.58 1.58 0 01-.215-.965c.022-.19.14-.365.208-.55.025-.066.065-.154.044-.208-.316-.796-.364-1.616-.211-2.454.207-1.134.71-2.062 1.697-2.639a.868.868 0 01.412-.116C7.159.005 8.126.028 9.092 0c.33-.009.541.13.73.372.042.055.072.121.121.166.05.045.113.097.174.102.63.056 1.086.39 1.4.954.335.602.443 1.269.48 1.953a3.964 3.964 0 01-.337 1.785c.425.487.375 1.42-.092 1.849-.059.053-.142.078-.203.13-.054.044-.118.1-.135.165-.195.738-.592 1.338-1.12 1.854a.393.393 0 00-.105.242c-.012.3.014.604-.01.903-.035.456.178.76.5 1.008.393.303.853.448 1.309.605.682.235 1.377.441 2.039.729 1.017.442 1.744 1.198 2.018 2.36.057.242.093.49.139.736"
                fill="#D5DBDF"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgProfile
