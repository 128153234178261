import * as React from 'react'
const SvgSkype = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgSkype(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M4.717 0c.716 0 1.41.163 2.039.47l.16.083.283-.034.183-.016.184-.011.37-.01a7.42 7.42 0 017.38 8.193l-.037.292.085.146c.29.528.475 1.107.542 1.711l.022.26.008.264a4.652 4.652 0 01-6.991 4.022l-.155-.097-.302.03-.236.014-.316.007A7.42 7.42 0 01.567 7.021l.03-.211-.08-.154a4.621 4.621 0 01-.424-1.481l-.021-.26-.008-.263A4.652 4.652 0 014.717 0zm0 1.118a3.51 3.51 0 00-2.5 1.035 3.51 3.51 0 00-1.035 2.5c0 .518.115 1.032.333 1.498l.1.197.104.19.027.154-.034.214c-.052.329-.079.665-.079.997a6.28 6.28 0 001.846 4.457 6.28 6.28 0 004.993 1.824l.266-.028.234-.03.164.038.196.13c.58.385 1.254.588 1.951.588a3.51 3.51 0 002.5-1.035 3.51 3.51 0 001.035-2.5c0-.578-.141-1.144-.41-1.652l-.123-.213-.125-.2-.034-.166.036-.233c.05-.322.076-.652.076-.98a6.282 6.282 0 00-1.846-4.457A6.282 6.282 0 007.31 1.633l-.311.038-.214.032-.155-.028-.188-.106a3.535 3.535 0 00-1.725-.45zm3.012 2.706c.727 0 1.395.17 2.006.511.635.355.944.763.944 1.224a.53.53 0 01-.203.441.806.806 0 01-.519.17c-.19 0-.368-.09-.54-.274l-.29-.304a2.356 2.356 0 00-.295-.267c-.316-.215-.724-.321-1.223-.321-.427 0-.764.09-1.009.276a.899.899 0 00-.377.747c0 .446.444.755 1.3.945l1.496.338c1.326.303 1.977 1.019 1.977 2.162 0 .713-.256 1.274-.77 1.69-.547.458-1.33.688-2.355.688-1.019 0-1.826-.22-2.424-.657-.507-.362-.755-.77-.755-1.23a.618.618 0 01.653-.644c.222 0 .424.109.61.335l.29.349c.164.193.271.306.327.345.326.258.76.388 1.295.388.463 0 .84-.116 1.127-.35.268-.212.405-.476.405-.783 0-.463-.288-.77-.837-.912L6.54 8.18C5.3 7.862 4.69 7.183 4.69 6.125c0-.774.316-1.36.95-1.771.551-.353 1.247-.53 2.088-.53z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgSkype
