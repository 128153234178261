import {List} from 'nf-ui'
import React from 'react'
import {Header} from './Header'
import {ProfileRow} from './ProfileRow'
import {ProfileSelectState} from './useProfileSelect'

const ProfilesList: React.FC<{profileSelection: ProfileSelectState}> = ({profileSelection}) => {
    return (
        <List
            width={400}
            heading={<Header multiselect={profileSelection} />}
            rows={profileSelection.profiles}
            renderRow={(profile) => {
                return <ProfileRow multiselect={profileSelection} profile={profile} />
            }}
        />
    )
}

export {ProfilesList}
