import {colors, Spinner} from 'nf-ui'
import React from 'react'
import styled from 'styled-components'
import GoogleLogo from './Google.png'
import './roboto.css'

const Button = styled.button`
    display: flex;
    padding: 1px;
    background-color: #4285f4;
    align-items: center;
    border: 0px;
    border-radius: 2px;
    :hover {
        cursor: pointer;
    }
`

const LogoContainer = styled.div<{large?: boolean}>`
    display: flex;
    height: ${(props) => (props.large ? 54 : 38)}px;
    width: ${(props) => (props.large ? 54 : 38)}px;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    background-color: white;
`

export const SpinnerContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`

const LabelContainer = styled.div<{large?: boolean}>`
    display: flex;
    padding: 11px 8px 11px 18px;
    padding-left: ${(props) => (props.large ? 16 : 8)}px;
    color: white;
    font-family: 'Roboto', sans-serif;
`

const Logo = styled.img`
    width: 24px;
    height: 24px;
`

const LoadingSpinner = () => {
    return (
        <SpinnerContainer>
            <Spinner tint={colors.white} />
        </SpinnerContainer>
    )
}

export const GoogleButton = ({
    label,
    onClick,
    large = false,
    loading = false,
}: {
    onClick: () => void
    label: string
    large?: boolean
    loading?: boolean
}) => {
    return (
        <Button onClick={onClick}>
            <LogoContainer large={large}>
                <Logo src={GoogleLogo} />
            </LogoContainer>
            {loading ? <LoadingSpinner /> : <LabelContainer large={large}>{label}</LabelContainer>}
        </Button>
    )
}
