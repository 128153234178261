import * as React from 'react'
const SvgImage = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgImage(props, svgRef) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M13.774 1.025l.115.005.118.018c.544.11.968.594.968 1.142v11.62l-.006.11-.018.116a1.22 1.22 0 01-1.176.94H2.138l-.11-.006-.12-.019c-.51-.108-.883-.576-.883-1.141V2.19l.005-.115.018-.126c.104-.535.55-.924 1.09-.924h11.636zm-2.177 6.952l-4.114 4.007-.066.053a.404.404 0 01-.43.038l-.064-.042L3.97 9.88 1.9 11.826l-.025 2.221 12.137.025.002-3.341-.063-.17-2.354-2.584zM1.9 1.927l-.042 8.588L3.53 8.902l.07-.053a.552.552 0 01.508-.039l.068.043 2.957 2.155L11.32 6.92l.07-.048a.5.5 0 01.276-.086.57.57 0 01.3.121l.085.066 1.962 2.27v-7.29L1.9 1.927zm4.963 2.729c-.438 0-.763.342-.763.802s.325.802.763.802c.462 0 .85-.367.85-.802 0-.435-.388-.802-.85-.802z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgImage
