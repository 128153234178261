import {ApolloError} from '@apollo/client'
import React, {useContext, useMemo} from 'react'
import {Profile, useFullOrgChartData} from '../useFullOrgChartData'
import {FullOrgChartData} from '../__types__/FullOrgChartData'

const OrgChartDataContext = React.createContext<{
    data: FullOrgChartData | undefined
    loading: boolean
    error: ApolloError | undefined
    branchView: boolean
    topLevelProfiles: Profile[]
    uniqueProfiles: Profile[]
    visibleProfiles: Profile[]
    orphanedProfiles: Profile[]
    getProfileFromIdStr: (idStr: string) => Profile | null
    categoryProfileCount: (categoryIdStr: string) => number
    categoryProfiles: Record<string, string[]>
} | null>(null)

export const useOrgChartData = () => {
    const context = useContext(OrgChartDataContext)
    if (!context) throw new Error('Cannot use org chart data context outside of the <OrgChartDataProvider />')

    return context
}

export const OrgChartDataProvider: React.FC = ({children}) => {
    const {
        data,
        loading,
        error,
        branchView,
        topLevelProfiles,
        uniqueProfiles,
        visibleProfiles,
        getProfileFromIdStr,
        categoryProfileCount,
        categoryProfiles,
        orphanedProfiles,
    } = useFullOrgChartData()

    const contextValue = useMemo(
        () => ({
            data,
            loading,
            error,
            branchView,
            topLevelProfiles,
            uniqueProfiles,
            visibleProfiles,
            getProfileFromIdStr,
            categoryProfileCount,
            categoryProfiles,
            orphanedProfiles,
        }),
        [
            branchView,
            categoryProfileCount,
            data,
            error,
            getProfileFromIdStr,
            loading,
            topLevelProfiles,
            uniqueProfiles,
            visibleProfiles,
            categoryProfiles,
            orphanedProfiles,
        ],
    )

    return <OrgChartDataContext.Provider value={contextValue}>{children}</OrgChartDataContext.Provider>
}
