import {motion, Variants} from 'framer-motion'
import {easeInOutQuad} from '../util'
import React from 'react'
import styled from 'styled-components'

const ExpandableContainer = styled(motion.div)`
    position: relative;
    overflow: hidden;
`

export const CollapsibleBody: React.FC = ({children}) => {
    return (
        <ExpandableContainer variants={heightVariants}>
            <motion.div variants={opacityVariants}>{children}</motion.div>
        </ExpandableContainer>
    )
}

const heightVariants: Variants = {
    expanded: {
        height: 'auto',
        transition: {
            duration: 0.3,
            ease: easeInOutQuad,
        },
    },
    collapsed: {
        height: 0,
        transition: {
            duration: 0.3,
            ease: easeInOutQuad,
        },
    },
}

const opacityVariants: Variants = {
    expanded: {opacity: 1},
    collapsed: {opacity: 0},
}
