import {Profile} from '../useFullOrgChartData'

export const getOtherManagersNames = ({
    additionalReport,
    parentIdStr,
    profile,
    getProfileFromIdStr,
}: {
    additionalReport: boolean
    parentIdStr?: string
    profile: Profile
    getProfileFromIdStr: (idStr: string) => Profile | null
}) => {
    const {additionalManagers, reportsTo} = profile
    const hasAdditionalManagers = additionalManagers.length > 0

    if (!parentIdStr || !hasAdditionalManagers) return null

    const allManagers = [...(reportsTo ? [reportsTo] : []), ...additionalManagers]
    const managersExcluding = (managerIdStr: string | null) => allManagers.filter((idStr) => idStr !== managerIdStr)

    const otherManagerIdStrs = additionalReport ? managersExcluding(parentIdStr) : managersExcluding(reportsTo)

    const names = otherManagerIdStrs
        .map((managerIdStr) => getProfileFromIdStr(managerIdStr))
        .filter((manager) => manager)
        .map((manager) => manager!.fullName)

    return names.length > 0 ? names : null
}
