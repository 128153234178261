import React, {FC} from 'react'
import {useGroupContext} from '../Group'
import {useField, useFormikContext} from 'formik'
import {Dropdown} from '../../Dropdown'
import {List} from '../../List'
import styled from 'styled-components'
import {Typography} from '../../Typography'
import SvgCheck from '../../Icons/Check'
import {colors} from '../../theme'
import {Icon} from '../../Icon'

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

type Option = {
    label: string
    value: string
}

type DropdownSelectProps = {
    options?: Option[] | null
    disabled?: boolean
}

function isValueArray(value: string | string[]): value is string[] {
    return Array.isArray(value)
}

const valueToArray = (value: string | string[]) => {
    return isValueArray(value) ? value : [value]
}

export const FormikDropdownSelect: FC<DropdownSelectProps> = ({options, disabled}) => {
    const {name} = useGroupContext()
    const [field, {error}] = useField<string[]>(name)
    const {setFieldValue} = useFormikContext<Record<string, string[]>>()

    const fieldValue = valueToArray(field.value)

    const getDisplay = (values: string[]) => {
        const selectedOptions = options?.filter((option) => values.includes(option.value))

        return selectedOptions?.map((options) => options.label).join(', ')
    }

    const selected = (option: Option) => {
        return fieldValue.includes(option.value)
    }

    const toggleOption = (option: Option) => {
        if (selected(option)) return

        setFieldValue(name, isValueArray(field.value) ? [option.value] : option.value)
    }

    return (
        <Dropdown buttonTarget>
            <Dropdown.Button error={error} disabled={disabled}>
                {getDisplay(fieldValue)}
            </Dropdown.Button>
            <Dropdown.Menu>
                {options && (
                    <List
                        onClick={toggleOption}
                        rows={options}
                        renderRow={(option) => {
                            return (
                                <RowContainer>
                                    <Typography.Paragraph bottomMargin={false}>{option.label}</Typography.Paragraph>
                                    {selected(option) && <Icon icon={SvgCheck} tint={colors.green[100]} />}
                                </RowContainer>
                            )
                        }}
                        width={304}
                    />
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}
