import {gql, useMutation} from '@apollo/client'
import {Formik} from 'formik'
import {Button, Form, FormikInput, Lightbox, Spacer, Typography} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import React from 'react'
import {useAlert} from 'react-alert'
import * as Yup from 'yup'
import {getGraphQLErrorMessage} from '~/util'
import {CurrentOrganisation} from './CurrentOrganisationContext'
import {RenameOrganisation, RenameOrganisationVariables} from './__types__/RenameOrganisation'

export type RenameOrganisationLightboxProps = {
    open: boolean
    onClose: () => void
}

export const RENAME_ORGANISATION = gql`
    mutation RenameOrganisation($idStr: String!, $name: String!) {
        updateOrganisation(organisationIdStr: $idStr, name: $name) {
            idStr
            name
        }
    }
`

export const RenameOrganisationLightbox: React.FC<RenameOrganisationLightboxProps> = ({open, onClose}) => {
    const alert = useAlert()
    const [renameOrganisation] = useMutation<RenameOrganisation, RenameOrganisationVariables>(RENAME_ORGANISATION, {
        onError: (error) => {
            alert.error(getGraphQLErrorMessage(error))
        },
        onCompleted: (response) => {
            alert.success(`Organisation successfully renamed to ${response.updateOrganisation.name}.`)
            onClose()
        },
    })
    const {currentOrganisation, loading} = CurrentOrganisation.useContainer()

    if (!currentOrganisation || loading) return null

    return (
        <Lightbox onClose={() => onClose()} open={open} width={560}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography.Heading>Rename organization</Typography.Heading>
                <Button variant="tertiary" onClick={onClose}>
                    Close <Button.Icon icon={SvgClose} />
                </Button>
            </div>
            <Spacer height={16} />
            <Formik
                isInitialValid={false}
                onSubmit={({name}) => renameOrganisation({variables: {idStr: currentOrganisation.idStr, name}})}
                validationSchema={Yup.object({name: Yup.string().required()})}
                initialValues={{name: currentOrganisation.name}}
            >
                {({handleSubmit, isSubmitting, isValid}) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group name="name">
                                <Form.Label>New organization name</Form.Label>
                                <FormikInput autoFocus type="text" hideError />
                            </Form.Group>
                            <Button type="submit" disabled={!isValid} loading={isSubmitting}>
                                Rename
                            </Button>
                        </Form>
                    )
                }}
            </Formik>
        </Lightbox>
    )
}
