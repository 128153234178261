import * as React from 'react'
const SvgArrowLeft = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgArrowLeft(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M6.805 4.816L4.312 7.309A.998.998 0 004.03 8c0 .258.103.507.282.692l2.492 2.492a.986.986 0 001.391 0l.081-.092a.987.987 0 00-.08-1.293l-.823-.818h3.617c.543 0 .98-.438.98-.98l-.007-.115a.98.98 0 00-.973-.865H7.373l.823-.817a.988.988 0 000-1.386.988.988 0 00-1.391-.001z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgArrowLeft
