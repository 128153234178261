import * as React from 'react'
const SvgDesignRegular = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgDesignRegular(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" ref={svgRef} {...props}>
            <path
                d="M12.2 0h-10C1.087 0 .09.924.006 2.037L0 2.19v.83c0 1.13.948 2.103 2.049 2.185l.151.006h10c.983 0 1.832-.697 2.07-1.663l.034-.163.043-.25H15v3.921H7.2a.606.606 0 00-.323.114l-.05.034-.04.069a.934.934 0 00-.059.145l-.018.07-.01.098v2.358l-.224.06c-.563.147-1.008.639-1.069 1.19l-.007.127v3.32c0 .692.575 1.286 1.269 1.354L6.8 16h.8c.706 0 1.323-.553 1.393-1.231L9 14.64v-3.32c0-.6-.357-1.09-.931-1.278l-.136-.038-.233-.053V8.116h7.8a.469.469 0 00.493-.428L16 7.586V2.605c0-.284-.152-.482-.402-.522l-.098-.008h-1.153l-.043-.25C14.135.832 13.33.082 12.363.006L12.2 0zM7.9 11.021v3.92H6.5v-3.92h1.4zm4.3-9.961c.541 0 1.03.454 1.093 1.01l.007.12v.83c0 .57-.453 1.06-.985 1.123l-.115.007h-10c-.542 0-1.03-.453-1.093-1.01L1.1 3.02v-.83c0-.569.453-1.06.985-1.123L2.2 1.06h10z"
                fill="#101010"
                fillRule="evenodd"
            />
        </svg>
    )
})
export default SvgDesignRegular
