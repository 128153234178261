import React from 'react'
import {Alert} from './index'
import {positions, transitions, Provider, AlertProviderProps} from 'react-alert'

const options: Omit<AlertProviderProps, 'template'> = {
    offset: '5px 32px',
    timeout: 5000,
    position: positions.BOTTOM_RIGHT,
    transition: transitions.FADE,
    containerStyle: {
        bottom: '27px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
}

export const AlertsProvider: React.FC = ({children}) => {
    return (
        <Provider template={Alert} {...options}>
            {children}
        </Provider>
    )
}
