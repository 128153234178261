import React, {forwardRef, ReactElement, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {setRef, createOrFindElement} from './util'

/**
 * Portal component
 */
export const Portal = forwardRef<
    HTMLDivElement,
    {children?: ReactElement; container: HTMLElement | string | undefined | null}
>(function Portal({children, container}, ref) {
    const containerElement = typeof container === 'string' ? createOrFindElement(container) : container

    useEffect(() => {
        if (!containerElement) return
        setRef(ref, containerElement)
        return () => setRef(ref, null)
    }, [containerElement, ref])

    return containerElement ? createPortal(children, containerElement) : <div ref={ref}>{children}</div>
})
