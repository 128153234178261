import React, {InputHTMLAttributes, ForwardRefExoticComponent, RefAttributes} from 'react'
import {Button} from '.'
import styled from 'styled-components'
import {ButtonProps} from './Button/Button'
import {Icon} from './Button/Icon'

const NativeFileInput = styled.input.attrs({
    type: 'file',
})`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
`

interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
    buttonProps?: ButtonProps
}

interface FileInputType extends ForwardRefExoticComponent<FileInputProps & RefAttributes<HTMLInputElement>> {
    Icon: typeof Icon
}

const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
    ({children, buttonProps = {}, type, onChange, ...rest}, ref) => {
        return (
            <Button {...buttonProps}>
                <NativeFileInput
                    onClick={(event) => event.stopPropagation()}
                    onChange={(event) => {
                        onChange && onChange(event)
                        event.target.value = ''
                    }}
                    ref={ref}
                    {...rest}
                />
                {children}
            </Button>
        )
    },
) as FileInputType

FileInput.Icon = Icon

export {FileInput}
