import * as React from 'react'
const SvgDataRegular = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgDataRegular(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 17 16" fill="none" ref={svgRef} {...props}>
            <path
                d="M9.289 1.079a1.165 1.165 0 00-.657-.039l-.119.037-7.007 2.888A.927.927 0 001.4 5.68l.115.052 1.856.783-1.865.786A.927.927 0 001.4 9.014l.115.052 1.856.782-1.865.786a.927.927 0 00-.108 1.713l.115.052 6.996 2.884c.191.076.445.088.655.039l.12-.037 7.009-2.89a.927.927 0 00.105-1.713l-.115-.052-1.856-.782 1.866-.786a.927.927 0 00.105-1.714l-.115-.052-1.856-.782 1.866-.787a.927.927 0 00.107-1.713l-.115-.052L9.289 1.08zm-.004 10.87l3.713-1.527 2.699 1.092-6.799 2.762-6.797-2.761 2.7-1.093 3.708 1.526c.19.077.447.09.657.04l.119-.038zm0-3.333l3.713-1.53 2.699 1.095-6.799 2.76-6.797-2.76 2.7-1.094 3.708 1.527c.191.076.447.088.657.039l.119-.037zM2.1 4.846l6.797-2.76 6.799 2.76-6.799 2.76-6.797-2.76z"
                fill="#101010"
            />
        </svg>
    )
})
export default SvgDataRegular
