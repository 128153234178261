import styled from 'styled-components'
import React from 'react'
import {Column, Row} from './Layout'

export type ErrorState = Record<string, {message: string; visible: boolean} | undefined>

export const ErrorMessage = styled.span`
    font-family: 'inter';
    font-size: 12px;
    color: #ff0000;
`

export const hideError = (key: string, errorState: ErrorState, setErrorState: (value: ErrorState) => void) => {
    if (errorState[key]?.visible) {
        setErrorState({
            ...errorState,
            ...Object.fromEntries([[key, {message: errorState[key]?.message || '', visible: false}]]),
        })
    }
}

export const ErrorRows = ({errorRecord}: {errorRecord: {message: string; visible: boolean} | undefined}) => {
    return (
        <>
            {errorRecord?.visible && errorRecord?.message && (
                <>
                    <Row height="10px" />
                    <Row>
                        <Column width="14px" grow={0} shrink={0}></Column>
                        <Column grow={0} shrink={1}>
                            <ErrorMessage>{errorRecord?.message || ''}</ErrorMessage>
                        </Column>
                        <Column width="14px" grow={1} shrink={0}></Column>
                    </Row>
                </>
            )}
        </>
    )
}
