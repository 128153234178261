/* eslint-disable react-hooks/exhaustive-deps */
import {gql, useMutation, useQuery} from '@apollo/client'
import {cloneDeep} from '@apollo/client/utilities'
import useComponentSize from '@rehooks/component-size'
import produce from 'immer'
import {Button, FileInput, Form, HeaderLayout, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgAdd from 'nf-ui/Icons/Add'
import React, {useLayoutEffect, useMemo, useState} from 'react'
import {useAlert} from 'react-alert'
import styled, {css} from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {AdminPhotosList, PHOTO_GAP} from '~/components/AdminPhotosList/AdminPhotosList'
import {AdminProfilesList} from '~/components/AdminProfilesList/AdminProfilesList'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {ModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {PageLoading} from '~/components/PageLoading'
import {PHOTO_WIDTH} from '~/components/Photo/Photo'
import {
    DeletePhotoMutationUpdater,
    DeletePhotosMutationUpdater,
    isFile,
    searchPhotos,
    searchProfiles,
    UPLOAD_PHOTO,
    useUploadPhotoQueue,
} from '~/components/PhotoManagementCommon'
import {UploadProgress} from '~/components/UploadProgress'
import {useCurrentUser} from '~/components/useCurrentUser'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {UploadPhoto, UploadPhotoVariables} from '~/components/__types__/UploadPhoto'
import {getGraphQLErrorMessage} from '~/util'
import {validImageMimeTypes} from '~/util/validImages'
import {PhotoCollection} from './PhotoCollection'
import {PhotoCroppingModal, PhotoCroppingState} from './PhotoCroppingModal'
import {PhotoMatchAllModal} from './PhotoMatchAllModal'
import {DownloadPhotos, DownloadPhotosVariables} from './__types__/DownloadPhotos'
import {DownloadPhotosReport, DownloadPhotosReportVariables} from './__types__/DownloadPhotosReport'
import {PhotosData, PhotosDataVariables} from './__types__/PhotosData'

const CONTAINER_MARGIN = 40
const PROFILE_LIST_MIN_WIDTH = 260

export const PAGE_DATA = gql`
    query PhotosData($organisationIdStr: String!) {
        organisation(idStr: $organisationIdStr) {
            idStr
            fields {
                idStr
                label
            }
            profiles {
                idStr
                fullName
                photoRequested
                createdAt
                photos {
                    idStr
                    thumbUrl
                    priority
                }
            }
            photos {
                idStr
                filename
                thumbUrl
                priority
                originalUrl
                createdAt
                profile {
                    idStr
                    fullName
                }
                pendingProfile {
                    idStr
                    fullName
                }
            }
        }
    }
`

const DOWNLOAD_PHOTOS_REPORT = gql`
    mutation DownloadPhotosReport($organisationIdStr: String!) {
        downloadPhotosReport(organisationIdStr: $organisationIdStr)
    }
`

const DOWNLOAD_PHOTOS = gql`
    mutation DownloadPhotos($organisationIdStr: String!) {
        downloadPhotos(organisationIdStr: $organisationIdStr)
    }
`

/**
 * Gets the refetch query for photos data
 *
 * @export
 * @param {string} organisationIdStr
 * @returns
 */
export function getPhotosDataQuery(organisationIdStr: string) {
    return {
        query: PAGE_DATA,
        variables: {
            organisationIdStr,
        },
    }
}

const FullHeaderLayout = styled(HeaderLayout)`
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
`

const Layout = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-content: flex-start;
`

const ProfilesListContainer = styled.div`
    min-width: ${PROFILE_LIST_MIN_WIDTH}px;
    flex-grow: 1;
`

const PhotosListContainer = styled.div<{width: 'auto' | number}>`
    display: flex;
    flex-direction: column;
    margin-left: ${CONTAINER_MARGIN}px;
    ${(props) =>
        props.width !== 'auto' &&
        css`
            width: ${props.width}px;
        `}
`

export const Photos: React.FC = () => {
    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const [modal, setModal] = useQueryParam<string | null>('modal')
    const [photoCropping, setPhotoCropping] = useState<PhotoCroppingState>({currentIndex: 0, selection: []})

    const {me} = useCurrentUser()

    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const [layoutElement, setLayoutElement] = useState<HTMLDivElement | null>(null)
    const layoutSize = useComponentSize({current: layoutElement})

    const [photosContainerWidth, setPhotosContainerWidth] = useState<'auto' | number>('auto')

    useLayoutEffect(() => {
        const availableSpace = layoutSize.width - PROFILE_LIST_MIN_WIDTH - CONTAINER_MARGIN
        const newWidth = Math.floor(availableSpace / (PHOTO_WIDTH + PHOTO_GAP)) * (PHOTO_WIDTH + PHOTO_GAP) - PHOTO_GAP
        setPhotosContainerWidth(newWidth)
    }, [layoutSize.width])

    const [profileAndPhotoSearch, setProfileAndPhotoSearch] = useState('')
    const organisationIdStr = useOrganisationIdStr()
    const photoCollectionEnabled = currentOrganisation?.appFeatures?.photoCollection
    const downloadPhotosEnabled = currentOrganisation?.appFeatures?.downloadPhotos

    const {data, error, loading} = useQuery<PhotosData, PhotosDataVariables>(PAGE_DATA, {
        variables: {
            organisationIdStr,
        },
    })
    const [uploadPhoto] = useMutation<UploadPhoto, UploadPhotoVariables>(UPLOAD_PHOTO, {
        update: (cache, {data}) => {
            if (!data) return
            if (!data.uploadPhoto) return

            const getPhotosResult = cache.readQuery<PhotosData, PhotosDataVariables>({
                query: PAGE_DATA,
                variables: {
                    organisationIdStr,
                },
            })

            if (!getPhotosResult) return

            cache.writeQuery<PhotosData, PhotosDataVariables>({
                query: PAGE_DATA,
                data: {
                    organisation: {
                        ...getPhotosResult.organisation,
                        photos: getPhotosResult.organisation.photos.concat([data.uploadPhoto]),
                    },
                },
            })
        },
    })

    const [downloadLatestPhotosReport, {loading: downloadingLatestPhotosReport}] = useMutation<
        DownloadPhotosReport,
        DownloadPhotosReportVariables
    >(DOWNLOAD_PHOTOS_REPORT, {
        onError: (error) => {
            alert.error(getGraphQLErrorMessage(error))
            throw error
        },
    })

    const [downloadLatestPhotos, {loading: downloadingLatestPhotos}] = useMutation<
        DownloadPhotos,
        DownloadPhotosVariables
    >(DOWNLOAD_PHOTOS, {
        onError: (error) => {
            alert.error(getGraphQLErrorMessage(error))
            throw error
        },
    })

    const {addItems, current, completed, total, cancel, cancelling, profilesLoading} = useUploadPhotoQueue(uploadPhoto)
    const alert = useAlert()

    const profiles = data?.organisation.profiles ?? []
    const filteredProfiles = useMemo(() => searchProfiles(profiles, profileAndPhotoSearch), [
        profiles,
        profileAndPhotoSearch,
    ])

    const photos = data?.organisation.photos ?? []
    const filteredPhotos = useMemo(() => searchPhotos(photos, profileAndPhotoSearch), [photos, profileAndPhotoSearch])

    if (!data || loading) return <PageLoading />
    if (error) return <Typography.Heading>Error</Typography.Heading>

    const deletePhotoUpdate: DeletePhotoMutationUpdater = (cache, {data}) => {
        if (!data || !data.deletePhoto || !data.deletePhoto.photo) return
        const idStr = data.deletePhoto.photo.idStr
        const getPhotosResult = cache.readQuery<PhotosData, PhotosDataVariables>({
            query: PAGE_DATA,
            variables: {organisationIdStr},
        })
        if (!getPhotosResult) return

        cache.writeQuery<PhotosData, PhotosDataVariables>({
            query: PAGE_DATA,
            data: produce(cloneDeep(getPhotosResult), (draftState) => {
                draftState.organisation.photos = draftState.organisation.photos.filter((photo) => photo.idStr !== idStr)
                draftState.organisation.profiles.forEach((profile) => {
                    if (profile.photos?.[0]) {
                        profile.photos = profile.photos.filter((photo) => photo.idStr !== idStr)
                    }
                })
            }),
            variables: {organisationIdStr},
        })
    }
    const deletePhotosUpdate: DeletePhotosMutationUpdater = (cache, {data}) => {
        if (!data || !data.deletePhotos || !data.deletePhotos.photos) return
        const deletedPhotoIds = data.deletePhotos.photos.map((photo) => photo.idStr)

        const getPhotosResult = cache.readQuery<PhotosData, PhotosDataVariables>({
            query: PAGE_DATA,
            variables: {organisationIdStr},
        })
        if (!getPhotosResult) return

        cache.writeQuery<PhotosData, PhotosDataVariables>({
            query: PAGE_DATA,
            data: produce(cloneDeep(getPhotosResult), (draftState) => {
                draftState.organisation.photos = draftState.organisation.photos.filter(
                    (photo) => !deletedPhotoIds.includes(photo.idStr),
                )

                draftState.organisation.profiles.forEach((profile) => {
                    if (profile.photos?.[0]) {
                        profile.photos = profile.photos.filter((photo) => !deletedPhotoIds.includes(photo.idStr))
                    }
                })
            }),
            variables: {organisationIdStr},
        })
    }

    const currentFile = current[0]

    return (
        <FullHeaderLayout
            heading="All photos"
            subheading="Collect, upload, and match photos"
            rightContent={
                <>
                    <Form.Group name="search" style={{flex: 1, maxWidth: 304, minWidth: 0}}>
                        <Form.SearchInput
                            width="100%"
                            onChange={setProfileAndPhotoSearch}
                            value={profileAndPhotoSearch}
                        />
                    </Form.Group>
                    <Spacer width={16} />
                    {me?.isSuperAdmin && (
                        <>
                            <Button
                                style={{flexShrink: 0}}
                                variant="secondary"
                                loading={downloadingLatestPhotosReport}
                                onClick={async () => {
                                    const result = await downloadLatestPhotosReport({
                                        variables: {organisationIdStr: organisationIdStr},
                                    })
                                    const url = result.data?.downloadPhotosReport
                                    if (url) window.open(url, '_blank')
                                }}
                            >
                                Download photos report
                            </Button>
                            <Spacer width={16} />
                        </>
                    )}
                    {(me?.isSuperAdmin || downloadPhotosEnabled) && (
                        <>
                            <Button
                                style={{flexShrink: 0}}
                                variant="secondary"
                                loading={downloadingLatestPhotos}
                                onClick={async () => {
                                    const result = await downloadLatestPhotos({
                                        variables: {organisationIdStr: organisationIdStr},
                                    })
                                    const url = result.data?.downloadPhotos
                                    if (url) window.open(url, '_blank')
                                }}
                            >
                                Download photos
                            </Button>
                            <Spacer width={16} />
                        </>
                    )}
                    {photoCollectionEnabled && (
                        <Button
                            style={{flexShrink: 0}}
                            variant="secondary"
                            onClick={() => {
                                setModal('request-photos')
                            }}
                            onClickAnalyticsEvent="select_collect_photos"
                            onClickAnalyticsData={{
                                organisation: currentOrganisation?.name || '',
                                organisationId: organisationIdStr,
                            }}
                        >
                            Collect photos
                        </Button>
                    )}
                    <Spacer width={16} />
                    <Button
                        style={{flexShrink: 0}}
                        variant="secondary"
                        onClick={() => {
                            setModal('match-all-photos')
                        }}
                        onClickAnalyticsEvent="select_match_photos"
                    >
                        Match all photos
                    </Button>
                    <Spacer width={16} />
                    <FileInput
                        multiple
                        buttonProps={{variant: 'primary', style: {flexShrink: 0}}}
                        accept={validImageMimeTypes}
                        onChange={(event) => {
                            try {
                                if (!event.target.files) return
                                trackAnalyticsEvent('select_upload_photos', {
                                    organisation: currentOrganisation?.name || '',
                                    organisationId: organisationIdStr,
                                })
                                addItems(Array.from(event.target.files))
                            } catch (error) {
                                alert.show(error.message)
                            }
                        }}
                    >
                        Upload photos <FileInput.Icon icon={SvgAdd} />
                    </FileInput>
                </>
            }
        >
            <ModalNavigator modal={modal || null} setModal={setModal}>
                <PhotoCollection
                    key="request-photos"
                    profiles={data.organisation.profiles}
                    organisationIdStr={organisationIdStr}
                    isOnboarding={false}
                />
                <PhotoMatchAllModal key="match-all-photos" fields={data.organisation.fields} />
                {photoCropping.selection.length && (
                    <PhotoCroppingModal
                        key={`crop-photo-${photoCropping.currentIndex}`}
                        photoCropping={photoCropping}
                        setPhotoCropping={setPhotoCropping}
                        setModal={setModal}
                    />
                )}
            </ModalNavigator>

            <Layout
                ref={(ref) => {
                    if (!ref) return
                    setLayoutElement(ref)
                }}
            >
                <ProfilesListContainer>
                    <AdminProfilesList
                        profiles={filteredProfiles}
                        profilesLoading={profilesLoading}
                        search={profileAndPhotoSearch}
                        uploadPhotos={addItems}
                    />
                </ProfilesListContainer>

                <PhotosListContainer width={photosContainerWidth}>
                    <Spacer height={8} />
                    <AdminPhotosList
                        photos={filteredPhotos}
                        profiles={data.organisation.profiles}
                        deletePhotoUpdate={deletePhotoUpdate}
                        deletePhotosUpdate={deletePhotosUpdate}
                        search={profileAndPhotoSearch}
                        selectable
                        startCropAndRotatePhotos={(state: PhotoCroppingState) => {
                            setPhotoCropping(state)
                            setModal(`crop-photo-${state.currentIndex}`)
                        }}
                    />
                </PhotosListContainer>

                {currentFile && (
                    <UploadProgress
                        file={isFile(currentFile) ? currentFile : currentFile.file}
                        completed={completed}
                        total={total}
                        cancel={cancel}
                        cancelling={cancelling}
                    />
                )}
            </Layout>
        </FullHeaderLayout>
    )
}
