import produce from 'immer'
import {Reducer, useEffect, useReducer} from 'react'

interface State<ItemType> {
    pending: ItemType[]
    current: ItemType | undefined
    completed: ItemType[]
    cancelling: boolean
}

type AddItemsAction<ItemType> = {
    type: 'ADD_ITEMS'
    items: ItemType[]
}

type UploadCompleteAction = {
    type: 'UPLOAD_COMPLETE'
}

type CancelAction = {
    type: 'CANCEL'
}

type ResetAction = {
    type: 'RESET'
}

type Action<ItemType> = AddItemsAction<ItemType> | UploadCompleteAction | CancelAction | ResetAction

const initialState = {
    pending: [],
    current: undefined,
    completed: [],
    cancelling: false,
}

function reducer<ItemType>(state: State<ItemType>, action: Action<ItemType>) {
    switch (action.type) {
        case 'ADD_ITEMS':
            return produce(state, (draftState) => {
                if (!draftState.current) {
                    // If there's no current uploading file,
                    // remove the first file from action.files and
                    // assign it to state.current
                    const firstFile = action.items.shift()
                    // @ts-ignore
                    draftState.current = firstFile
                }

                // @ts-ignore
                draftState.pending.push(...action.items)
            })
        case 'UPLOAD_COMPLETE':
            return produce(state, (draftState) => {
                if (!draftState.current) return
                //@ts-ignore
                draftState.completed.push(draftState.current)
                draftState.current = draftState.pending.shift()
            })
        case 'CANCEL':
            return produce(state, (draftState) => {
                draftState.cancelling = true
            })
        case 'RESET':
            return initialState
        default:
            return state
    }
}

type ItemAction<ItemType> = (item: ItemType) => Promise<any>

export function useQueue<ItemType>(process: ItemAction<ItemType>) {
    const [state, dispatch] = useReducer<Reducer<State<ItemType>, Action<ItemType>>>(reducer, initialState)

    useEffect(() => {
        const doUpload = async () => {
            if (!state.current || state.cancelling) return dispatch({type: 'RESET'})

            await process(state.current)

            dispatch({type: 'UPLOAD_COMPLETE'})
        }

        doUpload()
        // TODO: Fix dependencies
        // eslint-disable-next-line
    }, [state.current])

    const totalItemCount = state.completed.length + state.pending.length + (state.completed ? 1 : 0)

    return {
        cancelling: state.cancelling,
        completed: state.completed.length,
        current: state.current ? [state.current] : [],
        total: totalItemCount,
        addItems: (items: ItemType[]) => dispatch({type: 'ADD_ITEMS', items}),
        cancel: () => dispatch({type: 'CANCEL'}),
        pending: state.pending,
    }
}
