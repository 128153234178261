import {AnimatePresence, motion} from 'framer-motion'
import {easeInOutQuad} from 'nf-ui'
import React from 'react'
import {FullProfile, FULL_PROFILE_WIDTH} from './FullProfile/FullProfile'

export const profilesGridTransition = {
    duration: 0.3,
    ease: easeInOutQuad,
}

export const FullProfileSidePanel: React.FC<{profileId?: string; cache?: boolean}> = ({profileId, cache = true}) => {
    return (
        <>
            {profileId && <div style={{width: FULL_PROFILE_WIDTH}} />}
            <AnimatePresence exitBeforeEnter initial={false}>
                {profileId && (
                    <motion.div
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={{
                            open: {x: 0},
                            closed: {x: FULL_PROFILE_WIDTH},
                        }}
                        transition={profilesGridTransition}
                        style={{
                            zIndex: 1,
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                        }}
                    >
                        {profileId && <FullProfile profileId={profileId} cache={cache} />}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}
