import {Spacer, Typography} from 'nf-ui'
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {ProfileLine} from './FullProfile'

export type CategoryDetailProps = {
    category: ProfileLine
    profileIdStr?: string | null
}

export const categoryDetailStyle = css`
    a {
        color: inherit;
        text-decoration: underline;
    }
    a:hover {
        color: inherit;
    }
`

const Value = styled(Typography.Paragraph)`
    ${categoryDetailStyle}
`

export const CategoryContainer: FC<CategoryDetailProps> = ({category, profileIdStr}) => {
    const organisationIdStr = useOrganisationIdStr()
    const [profileId] = useQueryParam<string>('profileId')
    const categoryData = category.extraData.childCategories
    if (!categoryData) return null

    const lastChildIndex = categoryData.length - 1

    return (
        <>
            {categoryData.map((childCategory, index) => (
                <Link
                    key={childCategory.idStr}
                    to={`/${organisationIdStr}/childCategory/${childCategory.idStr}?profileId=${profileIdStr ||
                        profileId}`}
                    style={{marginRight: lastChildIndex !== index ? 8 : 0}}
                >
                    {childCategory.label}
                </Link>
            ))}
        </>
    )
}

export const CategoryDetail: FC<CategoryDetailProps> = ({category, profileIdStr}) => {
    return (
        <>
            <Typography.Label block>{category.label}</Typography.Label>
            <Value bottomMargin={false}>
                <CategoryContainer category={category} profileIdStr={profileIdStr} />
            </Value>
            <Spacer height={16} />
        </>
    )
}
