import {gql, useMutation} from '@apollo/client'
import {Button, colors, Lightbox} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {CURRENT_USER} from '~/components/useCurrentUser'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {CurrentUserData} from '~/components/__types__/CurrentUserData'
import {MeetingsCarousel, NUMBER_OF_STEPS} from './MeetingsCarousel'
import {SetViewedMeetings, SetViewedMeetingsVariables} from './__types__/SetViewedMeetings'

const SET_VIEWED_MEETINGS = gql`
    mutation SetViewedMeetings($organisationIdStr: String!) {
        setViewedMeetings(organisationIdStr: $organisationIdStr)
    }
`

const CloseButtonWrapper = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 5;
`

export type WelcomeLightboxProps = {
    open: boolean
    onClose: () => void
}

const CloseButton = styled.div`
    border-radius: 3px;
    transition: 0.15s background-color ease-in-out;
    :hover {
        cursor: pointer;
        background-color: ${colors.lightGray};
    }
`

export const MeetingsLightbox = ({showLightbox}: {showLightbox: boolean}) => {
    const organisationIdStr = useOrganisationIdStr()
    const [step, setStep] = useState<number>(1)
    const lastStep = useRef(1)
    const [open, setOpen] = useState<boolean>(showLightbox)

    const [setViewedMeetings] = useMutation<SetViewedMeetings, SetViewedMeetingsVariables>(SET_VIEWED_MEETINGS, {
        update: (cache) => {
            const currentUser = cache.readQuery<CurrentUserData>({query: CURRENT_USER})
            if (!currentUser || !currentUser.me) return

            cache.writeQuery<CurrentUserData>({
                query: CURRENT_USER,
                data: {
                    me: {
                        ...currentUser.me,
                        hasViewedMeetings: true,
                    },
                },
            })
        },
    })

    const onClose = () => {
        setOpen(false)
        setViewedMeetings({variables: {organisationIdStr}})
    }

    useEffect(() => {
        if (step > lastStep.current) lastStep.current = step
    }, [step])

    const nextStep = () => {
        if (step === NUMBER_OF_STEPS) {
            onClose()
        } else {
            setStep((prevStep) => prevStep + 1)
        }
    }

    return (
        <Lightbox onClose={onClose} open={open} width={560} padding={0}>
            <CloseButtonWrapper>
                <CloseButton onClick={onClose}>
                    <Button.Icon icon={SvgClose} size={24} />
                </CloseButton>
            </CloseButtonWrapper>
            <MeetingsCarousel step={step} nextStep={nextStep} setStep={setStep} />
        </Lightbox>
    )
}
