import {motion, useMotionValue, useTransform, AnimatePresence} from 'framer-motion'
import React, {useCallback, useRef} from 'react'
import styled from 'styled-components'
import {Button} from './Button/Button'
import {hexToRGB} from './theme'
import {Typography} from './Typography'
import {Pill, PillContainer} from './Pill'
import {useOnResize} from './util'
import {useTheme} from './NFThemeProvider'

const FadeContainer = styled.div`
    position: relative;
    min-width: 0;
`

const Container = styled.div`
    align-items: center;
    overflow-x: scroll;
    padding-bottom: 8px;
    margin-bottom: -8px;

    /* Color to see the pills */
    background-color: ${(props) => props.theme.primary.color};

    ${PillContainer} {
        margin-left: 8px;
    }

    > button {
        font-weight: 300;
    }

    /* This will only work for Chrome */
    ::-webkit-scrollbar {
        display: none;
    }
`

const InnerContainer = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding-right: 3px;
`

const Fade = styled(motion.div)<{left?: boolean}>`
    position: absolute;
    left: ${(props) => (props.left ? 0 : 'unset')};
    right: ${(props) => (props.left ? 'unset' : 0)};
    top: 0;
    pointer-events: none;
    width: 100px;
    height: 100%;
    margin: 0;
    z-index: 0;

    background-image: linear-gradient(
        90deg,
        ${(props) => hexToRGB(props.theme.primary.color, props.left ? '1' : '0')} 0%,
        ${(props) => hexToRGB(props.theme.primary.color, props.left ? '0' : '1')} 100%
    );
`

type PillCollectionType<T> = {
    labelSelect?: keyof T | {(value: T): string}
    pills: T[]
    onChange?: (values: T[]) => void
    clearAll?: boolean
    keySelector: (value: T) => string
}

const useGradientOpacity = () => {
    const scrollOffset = useMotionValue(0)
    const opacity = useTransform(scrollOffset, [1, 20], [0, 1])

    return {
        opacity,
        setScrollOffset: scrollOffset.set.bind(scrollOffset),
    }
}

export function PillCollection<T = any>({
    pills,
    clearAll = false,
    onChange,
    labelSelect,
    keySelector,
}: PillCollectionType<T>) {
    if (clearAll && !onChange)
        console.warn('Clear all is set but onChange is not, will not display clear all until onChange is set')

    const showClearAll = onChange && clearAll && pills.length > 1
    const handleClearAll = useCallback(() => {
        if (!onChange) return
        onChange([])
    }, [onChange])

    const theme = useTheme()

    const leftGradient = useGradientOpacity()
    const rightGradient = useGradientOpacity()

    const containerRef = useRef<HTMLDivElement>(null)
    const innerContainerRef = useRef<HTMLDivElement>(null)

    useOnResize(innerContainerRef, () => {
        if (!containerRef.current) return
        handleScroll(containerRef.current)
    })

    function handleScroll(div: HTMLDivElement) {
        if (!innerContainerRef.current) return

        leftGradient.setScrollOffset(div.scrollLeft)
        rightGradient.setScrollOffset(innerContainerRef.current.offsetWidth - div.offsetWidth - div.scrollLeft)
    }

    const getPillText = useCallback(
        (pill: T) => {
            if (!labelSelect) {
                if (typeof pill !== 'string')
                    console.warn('Pill is a complex type, not choosing a selector may cause unexpected display')

                return pill + ''
            }

            return typeof labelSelect === 'function' ? labelSelect(pill) : pill[labelSelect]
        },
        [labelSelect],
    )

    return (
        <>
            <FadeContainer>
                <Fade left style={{opacity: leftGradient.opacity}} />
                <Container ref={containerRef} onScroll={(e) => handleScroll(e.currentTarget)}>
                    <InnerContainer ref={innerContainerRef}>
                        <AnimatePresence initial={false}>
                            {pills.map((pill, index) => {
                                const remove = onChange
                                    ? () => onChange(pills.filter((_, i) => i !== index))
                                    : undefined

                                return (
                                    <Pill key={keySelector(pill)} remove={remove} data-testid="pillContainer">
                                        {getPillText(pill)}
                                    </Pill>
                                )
                            })}
                        </AnimatePresence>
                    </InnerContainer>
                </Container>
                <Fade style={{opacity: rightGradient.opacity}} />
            </FadeContainer>
            {showClearAll && (
                <Button variant="tertiary" onClick={handleClearAll} style={{flexShrink: 0}}>
                    <Typography.Paragraph bottomMargin={false} color={theme.primary.textColor}>
                        Clear all
                    </Typography.Paragraph>
                </Button>
            )}
        </>
    )
}
