import {gql, useMutation, useQuery} from '@apollo/client'
import {CURRENT_USER} from '~/components/useCurrentUser'
import {CurrentUserData} from '~/components/__types__/CurrentUserData'
import {
    GenerateGoogleCalendarCredentials,
    GenerateGoogleCalendarCredentialsVariables,
} from './__types__/GenerateGoogleCalendarCredentials'
import {GoogleCalendarOAuthUrl} from './__types__/GoogleCalendarOAuthUrl'

const GOOGLE_CALENDAR_OAUTH_URL = gql`
    query GoogleCalendarOAuthUrl {
        googleCalendarOAuthUrl
    }
`

const GENERATE_GOOGLE_CALENDAR_CREDENTIALS = gql`
    mutation GenerateGoogleCalendarCredentials($code: String!) {
        generateGoogleCalendarCredentials(code: $code)
    }
`

const DISCONNECT_GOOGLE_CALENDAR = gql`
    mutation DisconnectGoogleCalendar {
        disconnectGoogleCalendar
    }
`

export const useGoogleCalendarOAuthUrl = () => {
    const {data, loading} = useQuery<GoogleCalendarOAuthUrl>(GOOGLE_CALENDAR_OAUTH_URL)
    return {oAuthUrl: data?.googleCalendarOAuthUrl, loading}
}

export const useGenerateGoogleCalendarCredentials = () => {
    const [generateCredentialsMutation, {loading}] = useMutation<
        GenerateGoogleCalendarCredentials,
        GenerateGoogleCalendarCredentialsVariables
    >(GENERATE_GOOGLE_CALENDAR_CREDENTIALS)

    const generateCredentials = async (code: string) => generateCredentialsMutation({variables: {code}})

    return {generateCredentials, loading}
}

export const useDisconnectGoogleCalendar = () => {
    const [disconnectCalendar, {loading}] = useMutation<GenerateGoogleCalendarCredentials>(DISCONNECT_GOOGLE_CALENDAR, {
        update: (cache) => {
            const currentUser = cache.readQuery<CurrentUserData>({query: CURRENT_USER})
            if (!currentUser || !currentUser.me) return

            cache.writeQuery<CurrentUserData>({
                query: CURRENT_USER,
                data: {
                    me: {
                        ...currentUser.me,
                        calendarConnected: false,
                    },
                },
            })
        },
    })

    return {disconnectCalendar, loading}
}
