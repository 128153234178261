import {Typography} from 'nf-ui'
import React from 'react'
import {useQueryParam} from 'use-query-params'
import {PageLoading} from '~/components/PageLoading'
import {getGraphQLErrorMessage} from '~/util'
import {useOrgChartData} from './FullOrgChart/useOrgChartData'
import {Orientation, PAGE_ORIENTATIONS} from './OrgChartPdf/constants'
import {OrgChartPdf} from './OrgChartPdf/OrgChartPdf'

export const PrintOrgChart: React.FC = () => {
    const {data, error, topLevelProfiles, getProfileFromIdStr} = useOrgChartData()

    const [orientation] = useQueryParam<Orientation>('orientation')

    const pageOrientation = () => {
        if (orientation && PAGE_ORIENTATIONS.includes(orientation)) return orientation
        return 'landscape'
    }

    if (error) return <Typography.Heading>{getGraphQLErrorMessage(error)}</Typography.Heading>
    if (!data) return <PageLoading />

    return (
        <OrgChartPdf
            orientation={pageOrientation()}
            profiles={topLevelProfiles}
            organisation={data.organisation}
            getProfileFromIdStr={getProfileFromIdStr}
        />
    )
}
