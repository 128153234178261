// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./PublicoBanner-Bold-Web.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./PublicoBanner-Bold-Web.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./PublicoBanner-Bold-Web.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./PublicoBanner-Roman-Web.eot");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./PublicoBanner-Roman-Web.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./PublicoBanner-Roman-Web.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./PublicoBanner-Light-Web.eot");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./PublicoBanner-Light-Web.woff2");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./PublicoBanner-Light-Web.woff");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./Inter-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_10___ = require("./Inter-Regular.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://use.typekit.net/kiw0gpl.css);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
exports.push([module.id, "@font-face {\n    font-family: 'publico-banner';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('embedded-opentype'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n    font-weight: 600;\n    font-style: normal;\n    font-stretch: normal;\n}\n\n@font-face {\n    font-family: 'publico-banner';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('embedded-opentype'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff');\n    font-weight: 400;\n    font-style: normal;\n    font-stretch: normal;\n}\n\n@font-face {\n    font-family: 'publico-banner';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ");\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format('embedded-opentype'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format('woff');\n    font-weight: 300;\n    font-style: normal;\n    font-stretch: normal;\n}\n\n* {\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\n@font-face {\n    font-family: 'inter';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_12___ + ") format('woff2');\n    font-weight: 600;\n    font-style: normal;\n    font-stretch: normal;\n}\n\n@font-face {\n    font-family: 'inter';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_13___ + ") format('woff2');\n    font-weight: 400;\n    font-style: normal;\n    font-stretch: normal;\n}\n", ""]);
// Exports
module.exports = exports;
