import {gql, useApolloClient, useMutation} from '@apollo/client'
import {cloneDeep} from '@apollo/client/utilities'
import {from} from 'fromfrom'
import {Button, colors, Form, Panel, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'
import React, {FC, useRef, useState} from 'react'
import styled from 'styled-components'
import {HomeItemFragment} from '~/apollo/queries/HomeItemFragment'
import {HomeItemData} from '~/apollo/queries/__types__/HomeItemData'
import {PanelLayout, PanelLayoutButtons} from '~/components/PanelLayout'
import {useCurrentUser} from '~/components/useCurrentUser'
import {useMessages} from '~/components/useMessages'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {useRelativeRoute} from '~/components/useRelativeRoute'
import {HomeItemType} from '~/objectTypes'
import {UPDATE_CATEGORY_PRIORITY} from './HomeItemList'
import {DeletHomeItem, DeletHomeItemVariables} from './__types__/DeletHomeItem'
import {UpdateCategoryPriority, UpdateCategoryPriorityVariables} from './__types__/UpdateCategoryPriority'
import {UpdateHomeItem, UpdateHomeItemVariables} from './__types__/UpdateHomeItem'

const Header = styled.div`
    display: flex;
    align-items: flex-start;
`

const BackButton = styled(Button)`
    margin-left: -20px;
    margin-right: 4px;
    margin-top: 14px;
    flex-shrink: 0;
`

export const UPDATE_HOME_ITEM = gql`
    mutation UpdateHomeItem($homeItem: HomeItemInput!) {
        updateHomeItem(homeItem: $homeItem) {
            idStr
            priority
            label
            visible
        }
    }
`

const DELETE_HOME_ITEM = gql`
    mutation DeletHomeItem($homeItemId: String!) {
        deleteHomeItem(homeIdStr: $homeItemId) {
            idStr
        }
    }
`

const HomeItemContainer = styled.div`
    display: flex;
    padding: 8px;
    flex-direction: row-reverse;
`

export type HomeItem = NonNullable<HomeItemData['homeItems']>[0]

function useUpdateHomeItemLabel() {
    const client = useApolloClient()
    const organisationIdStr = useOrganisationIdStr()

    return (idStr: string, label: string) => {
        const fragment = {
            fragment: HomeItemFragment,
            id: 'OrganisationObject:' + organisationIdStr,
        }

        const data = client.readFragment<HomeItemData>(fragment)

        if (!data) return

        const updatedData = cloneDeep(data)
        const homeItem = updatedData.homeItems?.find((hi) => hi.idStr === idStr)
        if (!homeItem) return

        homeItem.label = label

        client.writeFragment({
            ...fragment,
            data: updatedData,
        })
    }
}

export const EditHomeItem: FC<{homeItem: HomeItem}> = ({homeItem}) => {
    const [label, setLabel] = useState(homeItem.label)
    const showRemoveFromHome = homeItem.type === HomeItemType.CHILD_CATEGORY
    const organisationIdStr = useOrganisationIdStr()

    const {me} = useCurrentUser()
    const canAutoSort = me?.isSuperAdmin

    const {onCompleted} = useMessages()
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const updateHomeItemCache = useUpdateHomeItemLabel()

    const {pushRelative} = useRelativeRoute()
    const goBack = () => {
        updateHomeItemCache(homeItem.idStr, label)
        pushRelative('')
    }

    const [panelOpen, setPanelOpen] = useState(false)
    const buttonRef = useRef<HTMLButtonElement>(null)

    const [updateCategoryPriority] = useMutation<UpdateCategoryPriority, UpdateCategoryPriorityVariables>(
        UPDATE_CATEGORY_PRIORITY,
        {onCompleted},
    )

    const [updateHomeItem] = useMutation<UpdateHomeItem, UpdateHomeItemVariables>(UPDATE_HOME_ITEM, {
        onCompleted,
    })

    const [deleteHomeItem] = useMutation<DeletHomeItem, DeletHomeItemVariables>(DELETE_HOME_ITEM, {
        optimisticResponse: ({homeItemId}) => {
            return {
                deleteHomeItem: {
                    __typename: 'HomeItemObject',
                    idStr: homeItemId,
                },
            }
        },
        update: (cache, result) => {
            if (!result.data) return
            const fragment = {
                fragment: HomeItemFragment,
                id: 'OrganisationObject:' + organisationIdStr,
            }

            const data = cache.readFragment<HomeItemData>(fragment)
            if (!data) return
            const deletedId = result.data.deleteHomeItem.idStr

            cache.writeFragment({
                ...fragment,
                data: {
                    ...data,
                    homeItems: data.homeItems.filter((item) => item.idStr !== deletedId),
                },
            })
        },
        onCompleted,
    })

    const orderCategoriesAlphabetically = () => {
        if (homeItem.type !== HomeItemType.CATEGORY) return

        const categories = from(homeItem.childCategories || [])
            .sortBy((category) => category.label.toLowerCase())
            .toArray()
            .map(({idStr, __typename}, index) => ({__typename, idStr, priority: index + 1}))

        updateCategoryPriority({
            variables: {categories: categories.map(({__typename, ...rest}) => rest), organisationIdStr},
            optimisticResponse: {
                updateChildCategoryPriority: categories,
            },
        })
    }

    return (
        <>
            <Header>
                <BackButton variant="tertiary" onClick={goBack}>
                    <Button.Icon icon={SvgArrowLeft} />
                </BackButton>
                <Typography.Heading style={{wordBreak: 'break-word', minHeight: 56}}>{label}</Typography.Heading>
            </Header>
            <Spacer height={24} />
            <Form.Group name="homeItemLabel">
                <Form.Label>Label</Form.Label>
                <Form.Input
                    value={label}
                    onChange={(value) => {
                        setLabel(value)
                        trackAnalyticsEvent('change_label_name', {label: value})
                    }}
                    onBlur={(e) => {
                        const label = e.target.value

                        updateHomeItem({
                            variables: {
                                homeItem: {
                                    idStr: homeItem.idStr,
                                    label,
                                },
                            },
                            optimisticResponse: {
                                updateHomeItem: {
                                    ...homeItem,
                                    label,
                                },
                            },
                        })
                    }}
                />
            </Form.Group>
            <Spacer height={16} />

            {canAutoSort && (
                <HomeItemContainer>
                    <Button variant="tertiary" onClick={() => orderCategoriesAlphabetically()}>
                        Order categories alphabetically
                    </Button>
                </HomeItemContainer>
            )}
            {showRemoveFromHome && (
                <HomeItemContainer>
                    <Button ref={buttonRef} variant="tertiary" color={colors.red} onClick={() => setPanelOpen(true)}>
                        Remove from Home
                    </Button>
                    <Panel open={panelOpen} targetRef={buttonRef} onClose={() => setPanelOpen(false)}>
                        <PanelLayout width={304}>
                            <Typography.Label>Remove Field from Home Page?</Typography.Label>
                            <Typography.Paragraph bottomMargin={false}>
                                Removing this field from Home Page will mean it is no longer displayed on the Home page
                                of your directory. Do you want to continue?
                            </Typography.Paragraph>
                            <PanelLayoutButtons>
                                <Button variant="tertiary" onClick={() => setPanelOpen(false)}>
                                    Don't remove
                                </Button>
                                <Button
                                    variant="tertiary"
                                    onClick={() => {
                                        setPanelOpen(false)
                                        deleteHomeItem({variables: {homeItemId: homeItem.idStr}})
                                        goBack()
                                    }}
                                >
                                    Yes, remove
                                </Button>
                            </PanelLayoutButtons>
                        </PanelLayout>
                    </Panel>
                </HomeItemContainer>
            )}
        </>
    )
}
