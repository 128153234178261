import {gql, useQuery} from '@apollo/client'
import {useCallback, useEffect, useMemo} from 'react'
import {useQueryParam} from 'use-query-params'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {
    ReportingLinesData,
    ReportingLinesDataVariables,
    ReportingLinesData_profiles,
} from './__types__/ReportingLinesData'

export const REPORTING_LINES_DATA = gql`
    query ReportingLinesData($idStr: String!) {
        profiles(organisationIdStr: $idStr, includeOpenPositions: true) {
            firstName
            lastName
            idStr
            fullName
            secondaryLine
            photos {
                idStr
                thumbUrl
                priority
            }
            managerIdStr
            directReports
        }
    }
`

export type ReportingLinesProfile = ReportingLinesData_profiles

const getReportingLine = (
    profielIdStr: string,
    getProfileFromIdStr: (profileIdStr: string) => ReportingLinesProfile | undefined,
) => {
    const profile = getProfileFromIdStr(profielIdStr)
    if (!profile) return []
    if (!profile.managerIdStr) return [profile]

    const reportingLine = [profile]
    let currentDirectReport: ReportingLinesProfile | undefined = getProfileFromIdStr(profile.managerIdStr)

    while (currentDirectReport) {
        reportingLine.push(currentDirectReport)
        currentDirectReport = currentDirectReport.managerIdStr
            ? getProfileFromIdStr(currentDirectReport.managerIdStr)
            : undefined
    }

    return reportingLine.reverse()
}

export const useReportingLinesData = () => {
    const organisationIdStr = useOrganisationIdStr()
    const [selectedProfileId, setSelectedProfileId] = useQueryParam<string | null>('profileId')

    const {data, error} = useQuery<ReportingLinesData, ReportingLinesDataVariables>(REPORTING_LINES_DATA, {
        variables: {
            idStr: organisationIdStr,
        },
    })

    const profiles = data?.profiles ?? undefined

    const profileIdStrMap = useMemo(() => {
        const profiles = data?.profiles || []
        const profileIdStrMap: Record<string, ReportingLinesProfile> = {}

        for (const profile of profiles) {
            profileIdStrMap[profile.idStr] = profile
        }

        return profileIdStrMap
    }, [data])

    const getProfileFromIdStr = useCallback(
        (profileIdStr: string) => {
            return profileIdStrMap[profileIdStr] || null
        },
        [profileIdStrMap],
    )

    const reportingLineProfiles: ReportingLinesProfile[] = useMemo(() => {
        return selectedProfileId && profiles ? getReportingLine(selectedProfileId, getProfileFromIdStr) : []
    }, [selectedProfileId, profiles, getProfileFromIdStr])

    const topLevelProfiles = useMemo(() => {
        return (
            data?.profiles
                .filter((currentProfile) => !currentProfile.managerIdStr && currentProfile.directReports.length)
                .sort((a, b) => (a.directReports.length > b.directReports.length ? -1 : 1)) || []
        )
    }, [data])

    const getAdjacentProfiles = useCallback(
        (profile: ReportingLinesProfile) => {
            if (!profile.managerIdStr) {
                return (
                    profiles?.filter(
                        (currentProfile) => !currentProfile.managerIdStr && currentProfile.directReports.length,
                    ) || []
                )
            }

            const manager = getProfileFromIdStr(profile.managerIdStr)
            return manager?.directReports.map((reportIdStr) => getProfileFromIdStr(reportIdStr)).filter(Boolean) || []
        },
        [getProfileFromIdStr, profiles],
    )

    const getDirectReportsFor = useCallback(
        (selectedProfile: ReportingLinesProfile) => {
            return (
                selectedProfile.directReports.map((reportIdStr) => getProfileFromIdStr(reportIdStr)).filter(Boolean) ||
                []
            )
        },
        [getProfileFromIdStr],
    )

    useEffect(() => {
        if (!selectedProfileId && topLevelProfiles.length > 0) {
            setSelectedProfileId(topLevelProfiles[0]?.idStr || null)
        }
    }, [selectedProfileId, setSelectedProfileId, topLevelProfiles])

    return {
        profiles,
        error,
        topLevelProfiles,
        selectedProfileId,
        reportingLineProfiles,
        getAdjacentProfiles,
        getDirectReportsFor,
    }
}
