import {gql, useApolloClient, useMutation} from '@apollo/client'
import {Button, colors, Form, List, Spacer, Typography, useAnalyticsEventTracker, Panel} from 'nf-ui'
import SvgArrowLeft from 'nf-ui/Icons/ArrowLeft'
import AutoSizer from 'react-virtualized-auto-sizer'
import React, {FC, useRef, useState} from 'react'
import {cloneDeep} from '@apollo/client/utilities'
import styled from 'styled-components'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {useRelativeRoute} from '~/components/useRelativeRoute'
import {ListContainer, MergeButtonContainer} from './MergeCategories'
import {Row, Column} from '../PhotoCollection/ListComponents'
import {UnmergeCategoryPanel} from './useMergeCategories'
import {PanelNavigatorProvider} from '~/components/PanelNavigator'
import {LoadingContainer} from '~/components/LoadingContainer'
import {HomeItemType} from '~/globalTypes'
import {UpdateChildCategoryLabel, UpdateChildCategoryLabelVariables} from './__types__/UpdateChildCategoryLabel'
import {HomeItemFragment} from '~/apollo/queries/HomeItemFragment'
import {HomeItemData, HomeItemData_homeItems_childCategories} from '~/apollo/queries/__types__/HomeItemData'

const Header = styled.div`
    display: flex;
    align-items: flex-start;
`

const NameContainer = styled.div`
    flex: 1;
`

const BackButton = styled(Button)`
    margin-left: -20px;
    margin-right: 4px;
    margin-top: 14px;
    flex-shrink: 0;
`

export const UPDATE_CHILD_CATEGORY_LABEL = gql`
    mutation UpdateChildCategoryLabel($idStr: String!, $label: String!) {
        updateChildCategoryLabel(idStr: $idStr, label: $label) {
            idStr
            label
        }
    }
`

export type ChildCategory = HomeItemData_homeItems_childCategories

function useUpdateChildItemLabel() {
    const client = useApolloClient()
    const organisationIdStr = useOrganisationIdStr()

    return (idStr: string, label: string) => {
        const fragment = {
            fragment: HomeItemFragment,
            id: 'OrganisationObject:' + organisationIdStr,
        }

        const data = client.readFragment<HomeItemData>(fragment)
        if (!data) return

        const clonedData = cloneDeep(data)
        const child = clonedData.homeItems
            .filter((item) => item.type === HomeItemType.CATEGORY)
            .flatMap((item) => item.childCategories || [])
            .find((child) => child.idStr === idStr)

        if (!child) return

        child.label = label
        client.writeFragment({
            ...fragment,
            data: clonedData,
        })
    }
}

export const EditChildCategory: FC<{childCategory: ChildCategory}> = ({childCategory}) => {
    const [label, setLabel] = useState(childCategory.label)
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const updateCategoryCache = useUpdateChildItemLabel()

    const {pushRelative} = useRelativeRoute()
    const unmergeRef = useRef<HTMLButtonElement>(null)
    const [unMerging, setUnMerging] = useState(false)
    const [panelOpen, setPanelOpen] = useState<boolean>(false)
    const canUnmerge = childCategory.combinedCategories.length > 0
    const goBack = () => {
        updateCategoryCache(childCategory.idStr, label)
        pushRelative('')
    }

    const [updateChildCategory] = useMutation<UpdateChildCategoryLabel, UpdateChildCategoryLabelVariables>(
        UPDATE_CHILD_CATEGORY_LABEL,
        {},
    )

    return (
        <PanelNavigatorProvider>
            <LoadingContainer fullHeight loading={unMerging}>
                <Header>
                    <BackButton variant="tertiary" onClick={goBack}>
                        <Button.Icon icon={SvgArrowLeft} />
                    </BackButton>
                    <Typography.Heading style={{wordBreak: 'break-word', minHeight: 56}}>{label}</Typography.Heading>
                </Header>
                <Spacer height={24} />
                <Form.Group name="homeItemLabel">
                    <Form.Label>Label</Form.Label>
                    <Form.Input
                        value={label}
                        onChange={(value) => {
                            trackAnalyticsEvent('change_label_name', {label: value})
                            setLabel(value)
                        }}
                        onBlur={(e) => {
                            updateChildCategory({
                                variables: {
                                    idStr: childCategory.idStr,
                                    label,
                                },
                            })
                        }}
                    />
                    {childCategory.label !== childCategory.name && (
                        <Button
                            variant="tertiary"
                            onClick={(e) => {
                                trackAnalyticsEvent('reset_label_name', {label: childCategory.name})
                                setLabel(childCategory.name)
                                updateChildCategory({
                                    variables: {
                                        idStr: childCategory.idStr,
                                        label: '',
                                    },
                                })
                            }}
                        >
                            Reset
                        </Button>
                    )}
                </Form.Group>
                {canUnmerge && (
                    <Form.Group name="editItemUnmerge">
                        <Spacer height={16} />
                        <Form.Label>Categories included</Form.Label>
                        <ListContainer>
                            <AutoSizer disableHeight>
                                {({width}) => (
                                    <>
                                        <List
                                            width={width}
                                            rows={[...childCategory.combinedCategories].sort()}
                                            renderRow={(category: string) => {
                                                return (
                                                    <Row style={{opacity: 0.6}}>
                                                        <Column>
                                                            <NameContainer>{category}</NameContainer>
                                                        </Column>
                                                    </Row>
                                                )
                                            }}
                                        />
                                    </>
                                )}
                            </AutoSizer>
                        </ListContainer>
                    </Form.Group>
                )}
            </LoadingContainer>
            {canUnmerge && (
                <MergeButtonContainer style={{flexDirection: 'row'}}>
                    <Button
                        color={colors.red}
                        ref={unmergeRef}
                        onClick={() => setPanelOpen(true)}
                        variant="tertiary"
                        onClickAnalyticsEvent="select_unmerge_categories"
                    >
                        Unmerge categories
                    </Button>
                    <Panel targetRef={unmergeRef} open={panelOpen} onClose={() => setPanelOpen(false)}>
                        <UnmergeCategoryPanel
                            onClose={() => setPanelOpen(false)}
                            categoryIdStr={childCategory.idStr}
                            setLoading={(loading: boolean) => setUnMerging(loading)}
                        />
                    </Panel>
                </MergeButtonContainer>
            )}
        </PanelNavigatorProvider>
    )
}
