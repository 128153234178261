import {Typography} from 'nf-ui'
import React from 'react'

export const TermsCopy = () => {
    return (
        <Typography.Paragraph maxWidth={230} bottomMargin={false}>
            By continuing you agree to our{' '}
            <a
                href="https://www.namesandfaces.com/policies/user-terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
            >
                Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://www.namesandfaces.com/policies/privacy-policy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
            </a>
        </Typography.Paragraph>
    )
}
