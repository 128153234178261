import styled from 'styled-components'
import {HideableCss} from './css'
import {HideableCssProps} from './types'

export const Spacer = styled.div<{width?: string; height?: string} & HideableCssProps>`
    width: ${({width}) => width || '0px'};
    height: ${({height}) => height || '0px'};
    flex-shrink: 0;
    ${HideableCss}
`
