import {gql, useMutation} from '@apollo/client'
import {Formik} from 'formik'
import {Button, Form, FormikInput, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React, {FC, useCallback} from 'react'
import {useAlert} from 'react-alert'
import {useHistory} from 'react-router-dom'
import * as yup from 'yup'
import {BackButton} from '~/components/BackButton'
import {Token} from '~/components/TokenContext'
import {UnstyledLink} from '~/components/UnstyledLink'
import {getGraphQLErrorMessage} from '~/util'
import {Login, LoginVariables} from './__types__/Login'

const LOGIN_MUTATION = gql`
    mutation Login($email: String!, $password: String!) {
        signIn(email: $email, password: $password) {
            accessToken
            subId
        }
    }
`

const loginSchema = yup.object({
    email: yup
        .string()
        .required()
        .email(),
    password: yup.string().required(),
})

type LoginPasswordProps = {
    email: string
    onClickBack: () => void
}

export const LoginPassword: FC<LoginPasswordProps> = ({email, onClickBack}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const {setToken} = Token.useContainer()
    const [loginMutation, {loading}] = useMutation<Login, LoginVariables>(LOGIN_MUTATION)
    const {push} = useHistory()
    const alert = useAlert()

    const handleSubmit = useCallback(
        async (variables: LoginVariables) => {
            try {
                const {data} = await loginMutation({variables})
                trackAnalyticsEvent('sign_in')
                setToken(data!.signIn.accessToken)

                push('/')
            } catch (err) {
                alert.error(getGraphQLErrorMessage(err))
            }
        },
        [alert, loginMutation, setToken, push, trackAnalyticsEvent],
    )

    return (
        <>
            <Typography.Heading>
                <BackButton onClick={onClickBack} />
                Enter your password
            </Typography.Heading>
            <Spacer height={32} />
            <Formik onSubmit={handleSubmit} validationSchema={loginSchema} initialValues={{password: '', email}}>
                {({handleSubmit, isValid}) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group name="password">
                                <FormikInput type="password" autoFocus hideError />
                            </Form.Group>
                            <Button disabled={!isValid} type="submit" loading={loading}>
                                Sign in
                                <Button.Icon icon={SvgArrowRight} />
                            </Button>
                        </Form>
                    )
                }}
            </Formik>
            <Spacer height={32} />
            <Typography.Label>
                <UnstyledLink to={'/forgotpassword'} onClick={() => trackAnalyticsEvent('tap_forgot_password')}>
                    Forgot password?
                </UnstyledLink>
            </Typography.Label>
        </>
    )
}
