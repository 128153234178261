import {colors} from '../theme'

export const ROW_HEIGHT = 40

export const CELL_PADDING = 8

export const COLUMN_MAX_WIDTH = 232

export const BORDER_WIDTH = 1

export const BORDER_COLOR = colors.darkGray

export const CELL_FONT_WEIGHT = 300

export const HEADER_FONT_WEIGHT = 400

export const TRANSITION_DURATION = 300 //ms

export const transition = (...properties: string[]) => {
    return properties.map((property) => `${TRANSITION_DURATION}ms ${property} ease-in-out`).join(', ')
}

/**
 * The spring transition definition for all UI that needs to transition when the currently hovered cell changes.
 */
export const TRANSITION = {type: 'spring', stiffness: 140, damping: 90, mass: 0.5}
