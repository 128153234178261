import {gql, useMutation} from '@apollo/client'
import {Button, Spacer, Typography} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React from 'react'
import {useAlert} from 'react-alert'
import {TermsCopy} from '~/components/TermsCopy'
import {CURRENT_USER} from '~/components/useCurrentUser'
import {WizardLayout} from '~/components/WizardLayout'
import {CurrentUserData} from '~/components/__types__/CurrentUserData'
import {getGraphQLErrorMessage} from '~/util'

const ACCEPT_TERMS = gql`
    mutation AcceptTerms {
        acceptTerms
    }
`

export const AcceptTerms: React.FC = () => {
    const alert = useAlert()
    const [acceptTerms, {loading}] = useMutation(ACCEPT_TERMS, {
        onError: (error) => alert.error(getGraphQLErrorMessage(error)),
        update: (cache) => {
            const currentUser = cache.readQuery<CurrentUserData>({query: CURRENT_USER})
            if (!currentUser || !currentUser.me) return

            cache.writeQuery<CurrentUserData>({
                query: CURRENT_USER,
                data: {
                    me: {
                        ...currentUser.me,
                        hasAcceptedTerms: true,
                    },
                },
            })
        },
    })

    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <Typography.Heading>Terms of service</Typography.Heading>
                <Spacer height={16} />
                <TermsCopy />
                <Spacer height={24} />
                <Button onClick={() => acceptTerms()} loading={loading}>
                    Continue
                    <Button.Icon icon={SvgArrowRight} />
                </Button>
            </WizardLayout.Body>
        </WizardLayout>
    )
}
