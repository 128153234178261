import {useState, useCallback} from 'react'

type MultiselectOptions<TItem> = {
    users: TItem[]
    disabledIds?: string[]
    getItemId: (item: TItem) => string
}

export type Multiselect<TItem> = {
    setSelected: React.Dispatch<React.SetStateAction<string[]>>
    isSelected: (item: TItem) => boolean
    toggleSelectAll: () => void
    toggleSelected: (item: TItem) => void
    allSelected: boolean
    selected: string[]
    isDisabled: (item: TItem) => boolean
}

export function useMultiselect<TItem>({
    users,
    getItemId,
    disabledIds = [],
}: MultiselectOptions<TItem>): Multiselect<TItem> {
    const [selected, setSelected] = useState<string[]>([])

    const toggleSelected = useCallback(
        (item: TItem) => {
            const itemId = getItemId(item)
            if (disabledIds.includes(itemId)) return

            setSelected((selected) => {
                if (selected.includes(itemId)) {
                    return selected.filter((u) => u !== itemId)
                } else {
                    return [...selected, itemId]
                }
            })
        },
        [getItemId, disabledIds],
    )

    const allSelected = selected.length > 0 && selected.length === users.length - disabledIds.length

    const toggleSelectAll = useCallback(() => {
        setSelected(() => {
            if (allSelected) return []
            return users
                .filter((u) => {
                    return !disabledIds.includes(getItemId(u))
                })
                .map((u) => getItemId(u))
        })
    }, [users, getItemId, allSelected, disabledIds])

    const isSelected = useCallback((item: TItem) => selected.includes(getItemId(item)), [selected, getItemId])

    const isDisabled = useCallback((item: TItem) => disabledIds.includes(getItemId(item)), [disabledIds, getItemId])

    return {
        setSelected,
        isSelected,
        toggleSelectAll,
        toggleSelected,
        allSelected,
        selected,
        isDisabled,
    }
}
