import pluralize from 'pluralize'
import React from 'react'
import {ProfilesGridLayout} from '~/components/ProfilesGridLayout/ProfilesGridLayout'
import {UserlandActionBar} from './ActionBar'
import {ChildCategoryData_childCategory} from '~/apollo/queries/__types__/ChildCategoryData'

export const ChildCategoryProfiles = ({childCategory}: {childCategory: ChildCategoryData_childCategory}) => {
    const actionBarContent = `${childCategory.profilesCount} ${pluralize('person', childCategory.profilesCount)} in ${
        childCategory.label
    }`
    const profileIdStrs = childCategory.profiles.map((profile) => profile.idStr)

    return (
        <>
            <ProfilesGridLayout data={{profiles: childCategory.profiles}} />
            <UserlandActionBar label={actionBarContent} profileIdStrs={profileIdStrs} />
        </>
    )
}
