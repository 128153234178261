import {gql, useMutation} from '@apollo/client'
import isMobile from 'ismobilejs'
import {Button, Form, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgArrowRight from 'nf-ui/Icons/ArrowRight'
import React, {FC, FormEvent, useCallback} from 'react'
import {useAlert} from 'react-alert'
import {useHistory} from 'react-router-dom'
import {Illustrations} from '~/components/Illustrations'
import {PasswordInput} from '~/components/PasswordInput'
import {WizardLayout} from '~/components/WizardLayout'
import IceFishing from '~/illustrations/ice_fishing.png'
import {getGraphQLErrorMessage} from '~/util'
import {usePasswordUpdate} from './usePasswordUpdate'
import {SetPassword_Submit, SetPassword_SubmitVariables} from './__types__/SetPassword_Submit'

const RESET_PASSWORD = gql`
    mutation SetPassword_Submit($token: String!, $password: String!, $acceptTerms: Boolean) {
        resetPassword(token: $token, password: $password, acceptTerms: $acceptTerms)
    }
`

export const SetPassword: FC<{token: string}> = ({token}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const passwordState = usePasswordUpdate()
    const alert = useAlert()
    const [resetPassword, {loading}] = useMutation<SetPassword_Submit, SetPassword_SubmitVariables>(RESET_PASSWORD)
    const {push} = useHistory()

    const handlePasswordUpdate = useCallback(
        async (event: FormEvent) => {
            try {
                event.preventDefault()
                await resetPassword({
                    variables: {
                        password: passwordState.password,
                        token: decodeURIComponent(token),
                        acceptTerms: true,
                    },
                })

                trackAnalyticsEvent('select_confirm_password')

                if (isMobile(window.navigator).phone) {
                    push(`/mobilesetpasswordsuccess`)
                } else {
                    alert.success('Password successfully updated')
                    push(`/`)
                }
            } catch (error) {
                alert.error(getGraphQLErrorMessage(error))
            }
        },
        [passwordState, alert, resetPassword, token, push, trackAnalyticsEvent],
    )

    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <Typography.Heading>Set a password</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading>Follow the instructions below.</Typography.Subheading>
                <Spacer height={32} />
                <Form onSubmit={handlePasswordUpdate}>
                    <PasswordInput autoFocus passwordState={passwordState} />
                    <Spacer height={24} />
                    <Button disabled={!passwordState.valid} type="submit" loading={loading}>
                        Confirm password
                        <Button.Icon icon={SvgArrowRight} />
                    </Button>
                </Form>
            </WizardLayout.Body>
            <Illustrations RightIllutration={IceFishing} />
        </WizardLayout>
    )
}
