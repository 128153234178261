import {gql, useMutation} from '@apollo/client'
import {cloneDeep} from '@apollo/client/utilities'
import produce from 'immer'
import {Button, Spacer, Spinner, Typography} from 'nf-ui'
import React, {useEffect} from 'react'
import {CURRENT_ORGANISATION_DATA} from '~/components/CurrentOrganisationContext'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {WizardLayout} from '~/components/WizardLayout'
import {CurrentOrganisationData} from '~/components/__types__/CurrentOrganisationData'
import {getGraphQLErrorMessage} from '~/util'

const SET_UP_ADP_DATA_SOURCE = gql`
    mutation SetUpADPDataSource($organisationIdStr: String!) {
        setUpADPDataSource(organisationIdStr: $organisationIdStr)
    }
`

const Error: React.FC<{error: string; onClickRetry: () => void}> = ({error, onClickRetry}) => {
    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <Typography.Heading>Something went wrong</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading>{error}</Typography.Subheading>
                <Spacer height={32} />
                <Button variant="primary" onClick={onClickRetry}>
                    Try again
                </Button>
            </WizardLayout.Body>
        </WizardLayout>
    )
}

export const ADPOnboarding: React.FC = () => {
    const organisationIdStr = useOrganisationIdStr()

    const [setUpADPDataSource, {error, loading}] = useMutation(SET_UP_ADP_DATA_SOURCE, {
        variables: {organisationIdStr},
        update: (cache) => {
            const data = cache.readQuery<CurrentOrganisationData>({query: CURRENT_ORGANISATION_DATA})
            if (!data) return

            cache.writeQuery({
                query: CURRENT_ORGANISATION_DATA,
                data: produce(cloneDeep(data), (draftData) => {
                    for (const organisation of draftData.organisations) {
                        if (organisation.idStr === organisationIdStr) {
                            organisation.showADPOnboarding = false
                            break
                        }
                    }
                }),
            })
        },
    })

    useEffect(() => {
        setUpADPDataSource()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationIdStr])

    if (error && !loading) {
        return <Error error={getGraphQLErrorMessage(error)} onClickRetry={() => setUpADPDataSource()} />
    }

    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <Typography.Heading>Importing data</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading>
                    This may take a while. Photos will be imported in the background and appear after a few minutes.
                    <br />
                    Please do not close this browser tab.
                </Typography.Subheading>
                <Spacer height={32} />
                <Spinner size={32} />
            </WizardLayout.Body>
        </WizardLayout>
    )
}
