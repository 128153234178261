import {colors, Spacer, Typography} from 'nf-ui'
import React, {FC, useEffect} from 'react'
import styled from 'styled-components'
import {WizardLayout} from '~/components/WizardLayout'
import {MOBILE_APP_URL} from '~/constants'
import PlayStore from '~/components/playstore.svg'
import AppStore from '~/components/appstore.svg'

const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 8px;
`

const Number = styled(Typography.Subheading)`
    width: 20px;
    margin-right: 4px;
`

const ItemContent = styled.div``

const MobileContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    border-radius: 3px;
`

const TextLink = styled.a`
    color: ${colors.black} !important;
`

const SvgWrapper = styled.div`
    :hover {
        cursor: pointer;
    }
`

const appFallback = () => {
    const clickedAt = +new Date()
    setTimeout(function() {
        if (+new Date() - clickedAt < 2000) {
            window.location.href = 'https://redirect.namesandfaces.com'
        }
    }, 400)
}

const openLink = (link: string) => {
    window.open(link, '_blank')
}

export const MobileSetPasswordSuccess: FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <WizardLayout>
            <WizardLayout.Navigation />
            <WizardLayout.Body>
                <Typography.Heading>Password successfully updated</Typography.Heading>
                <Spacer height={24} />
                <Row>
                    <Number>1.</Number>
                    <ItemContent>
                        <Typography.Subheading maxWidth={520}>
                            Download the Names & Faces mobile app.
                        </Typography.Subheading>
                        <Spacer height={8} />
                        <MobileContainer>
                            <SvgWrapper
                                onClick={() => openLink('https://itunes.apple.com/za/app/names-faces/id949875464')}
                            >
                                <AppStore style={{height: '40px', width: 'auto'}} />
                            </SvgWrapper>
                            <Spacer width={16} />
                            <SvgWrapper
                                onClick={() =>
                                    openLink('https://play.google.com/store/apps/details?id=za.co.polymorph.namesfaces')
                                }
                            >
                                <PlayStore style={{height: '40px', width: 'auto'}} />
                            </SvgWrapper>
                        </MobileContainer>
                    </ItemContent>
                </Row>
                <Spacer height={16} />
                <Row>
                    <Number>2.</Number>
                    <ItemContent>
                        <Typography.Subheading maxWidth={520}>
                            Once downloaded,{' '}
                            <TextLink href={MOBILE_APP_URL} onClick={appFallback}>
                                open the app
                            </TextLink>{' '}
                            and sign in with your password.
                        </Typography.Subheading>
                    </ItemContent>
                </Row>
                <Spacer height={16} />
            </WizardLayout.Body>
        </WizardLayout>
    )
}
