import {CleanupRef} from './useCleanupOnUnmount'

const windowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'

export const OAuthSuccessType = 'OAuthSuccess'
export const OAuthFailureType = 'OAuthFailure'
export const OAuthCancelledType = 'OAuthCancelled'

export type OAuthSuccessMessage = {
    type: typeof OAuthSuccessType
    code: string
}

export type OAuthFailureMessage = {
    type: typeof OAuthFailureType
}

export type OAuthCancelledMessage = {
    type: typeof OAuthCancelledType
}

function isOAuthSuccessMessage(message: any): message is OAuthSuccessMessage {
    if (!message || typeof message !== 'object') return false
    return message.type === OAuthSuccessType && typeof message.code === 'string'
}

function isOAuthFailureMessage(message: any): message is OAuthFailureMessage {
    if (!message || typeof message !== 'object') return false
    return message.type === OAuthFailureType
}

export const waitForOAuthMessage = (authWindow: Window, cleanupRef: CleanupRef) =>
    new Promise<OAuthSuccessMessage | OAuthFailureMessage | OAuthCancelledMessage>((resolve, reject) => {
        const handleMessage = ({data}: MessageEvent) => {
            if (isOAuthSuccessMessage(data) || isOAuthFailureMessage(data)) {
                authWindow.close()
                cleanup()
                resolve(data)
            }
        }

        window.addEventListener('message', handleMessage)

        const intervalId = setInterval(() => {
            if (authWindow.closed) {
                cleanup()
                resolve({type: OAuthCancelledType})
            }
        }, 16)

        const cleanup = () => {
            if (intervalId) clearInterval(intervalId)
            window.removeEventListener('message', handleMessage)
        }

        cleanupRef.current = cleanup
    })

export const createAuthWindow = (authUrl: string) => {
    const authWindow = window.open(authUrl, undefined, windowFeatures)
    authWindow?.focus()
    return authWindow
}
