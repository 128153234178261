import {Button, colors, Spacer, Typography} from 'nf-ui'
import React, {useMemo} from 'react'
import styled from 'styled-components'

const Container = styled.div<{hasFooter: boolean}>`
    height: 88px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.14);
    background-color: ${colors.white};
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    position: fixed;
    right: 32px;
    /* Bottom includes 72px footer spacing ATM */
    bottom: ${(props) => (props.hasFooter ? 72 + 32 : 32)}px;
    overflow: hidden;
`

const ImagePreview = styled.div`
    background-size: cover;
    background-position: center;
    width: 88px;
    height: 88px;
`

const RightContents = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    position: relative;
`

const ProgressBar = styled.div<{progress: number}>`
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${(props) => props.progress * 100}%;
    background-color: ${colors.primary[100]};
    height: 2px;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    transition: 0.2s width linear;
`

const UploadProgress: React.FC<{
    file: File
    completed: number
    total: number
    cancel: () => any
    cancelling: boolean
    hasFooter?: boolean
}> = ({file, completed, total, cancel, cancelling, hasFooter}) => {
    const fileUrl = useMemo(() => window.URL.createObjectURL(file), [file])

    return (
        <Container hasFooter={hasFooter || false}>
            <ImagePreview style={{backgroundImage: `url('${fileUrl}')`}} />
            <RightContents>
                <Typography.Paragraph bottomMargin={false}>
                    Uploading {completed + 1} of {total}
                </Typography.Paragraph>
                <Spacer width={24} />
                <Button loading={cancelling} variant="secondary" onClick={() => cancel()}>
                    Cancel
                </Button>
                <ProgressBar progress={completed / total} />
            </RightContents>
        </Container>
    )
}

export {UploadProgress}
