/* eslint-disable @typescript-eslint/no-unused-expressions */
import {List, Spacer, TooltipTarget, Typography} from 'nf-ui'
import React, {useState} from 'react'
import {useAlert} from 'react-alert'
import {ModalLayout} from '~/components/ModalLayout'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {useGoogleSheetsPicker} from '~/components/useGoogleSheetPicker'
import {DataSourceFileType, DataSourceType} from '~/globalTypes'
import {getGraphQLErrorMessage} from '~/util'
import {DataSource} from './AddDataSourceModal/DataSource'
import CSV from './AddDataSourceModal/icons/CSV.png'
import EXCEL from './AddDataSourceModal/icons/EXCEL.png'
import GOOGLE_SHEETS from './AddDataSourceModal/icons/GOOGLE_SHEETS.png'
import {RequestList} from './AddDataSourceModal/RequestList'
import {useFileInput} from './ConfigureDataSource/useFileInput'
import {DataSourceData} from './ConfigureDataSourceModal'
import {useParseCsv} from './useParseCsv'

export type DirectUploadData = {
    type: DataSourceType.DIRECT_UPLOAD
    file: File
    data: Record<string, string>[]
    fileType: DataSourceFileType | null
}

export type GoogleSheetsData = {
    type: DataSourceType.GOOGLE_SHEETS
    sheetId: string
    refreshToken: string
    data: Record<string, string>[]
}

export type AddDataSource = DirectUploadData | GoogleSheetsData

export type AddDataSourceModalProps = {
    onPick: (data: DataSourceData) => void
    addEnabled: boolean
}

export const AddDataSourceModal: React.FC<AddDataSourceModalProps> = ({onPick, addEnabled}) => {
    const {onClose} = useModalNavigator()
    const {loading: googleSheetloading, handlePick} = useGoogleSheetsPicker({})
    const alert = useAlert()
    const [selectedFileType, setSelectedFileType] = useState<DataSourceFileType | null>(null)
    const [directUploadLoading, setDirectUploadLoading] = useState(false)
    const parseCsv = useParseCsv()

    const {inputProps, openPicker, setAccept} = useFileInput(async ([file]) => {
        try {
            setDirectUploadLoading(true)

            const data = await parseCsv(file)

            onClose()

            onPick({
                type: DataSourceType.DIRECT_UPLOAD,
                file,
                data,
                fileType: selectedFileType,
            })
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }

        setDirectUploadLoading(false)
    })

    const availableDataSources = [
        {
            icon: GOOGLE_SHEETS,
            label: 'Google Sheet',
            loading: googleSheetloading,
            onClick: async () => {
                const saveResult = await handlePick()
                if (saveResult) {
                    onClose()
                    onPick({
                        type: DataSourceType.GOOGLE_SHEETS,
                        ...saveResult,
                    })
                }
            },
            analyticsEvent: 'select_google_sheet',
        },
        {
            icon: CSV,
            label: 'CSV',
            loading: selectedFileType === DataSourceFileType.CSV && directUploadLoading,
            onClick: () => {
                setSelectedFileType(DataSourceFileType.CSV)
                setAccept('.csv')
                openPicker()
            },
            analyticsEvent: 'select_csv',
        },
        {
            icon: EXCEL,
            label: 'XLS',
            loading: selectedFileType === DataSourceFileType.XLS && directUploadLoading,
            onClick: () => {
                setSelectedFileType(DataSourceFileType.XLS)
                setAccept('.xls,.xlsx')
                openPicker()
            },
            analyticsEvent: 'select_xls',
        },
    ]

    return (
        <ModalLayout testId="AddDataSource">
            <ModalLayout.Navigation onClose={onClose} />
            <ModalLayout.Body>
                <Typography.Heading>Add a data source</Typography.Heading>
                <Spacer height={32} />
                <Typography.Label>Connect a file</Typography.Label>
                <Spacer height={16} />
                <List
                    width={304}
                    rows={availableDataSources}
                    padding={false}
                    renderRow={(dataSource) => {
                        return (
                            <TooltipTarget
                                title="Only one data source can be connected at a time"
                                position="right"
                                maxWidth={144}
                                enabled={!addEnabled}
                                testId="disabledTooltip"
                            >
                                <DataSource dataSource={dataSource} enabled={addEnabled} buttonLabel="Add" />
                            </TooltipTarget>
                        )
                    }}
                />
                <Spacer height={56} />
                <RequestList />
                <Spacer height={56} />
                <input {...inputProps} />
            </ModalLayout.Body>
        </ModalLayout>
    )
}
