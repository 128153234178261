import {gql, useApolloClient} from '@apollo/client'
import {AvailableFields, AvailableFieldsVariables} from './__types__/AvailableFields'

export const AVAILABLE_FIELDS = gql`
    query AvailableFields($organisationIdStr: String!) {
        availableFields(organisationIdStr: $organisationIdStr) {
            idStr
            name
            type
        }
    }
`

export const useAvailableFields = (organisationIdStr: string) => {
    const client = useApolloClient()

    const fetchAvailableFields = async () => {
        const {data} = await client.query<AvailableFields, AvailableFieldsVariables>({
            query: AVAILABLE_FIELDS,
            variables: {organisationIdStr},
        })

        return data.availableFields
    }

    return {fetchAvailableFields}
}
