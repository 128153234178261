import styled from 'styled-components'
import {colors} from '../../theme'
import React, {FC} from 'react'
import {FormikCheckboxOption} from './CheckboxOption'
import {useGroupContext} from '../Group'
import {useField} from 'formik'
import {InputLabel} from '../Input'

const ListItemWrapper = styled.div<{lastItem: boolean; error: string | undefined}>`
    display: flex;
    align-items: center;
    padding: 12px 16px;
    height: 32px;
    transition: border-color 0.3s ease-in-out;
    border-bottom: ${(props) => (!props.lastItem ? `1px solid` : '')};
    border-color: ${(props) => (props.error ? colors.red : colors.darkGray)};
`

type Option = {
    label: string
    value: string
}

type CheckboxListProps = {
    options?: Option[] | null
    multiple?: boolean
}

export const FormikCheckboxList: FC<CheckboxListProps> = ({options, multiple = true}) => {
    const {name} = useGroupContext()
    const [, {error}] = useField<string[]>(name)

    return (
        <>
            {options?.map((option, index) => (
                <ListItemWrapper key={index} lastItem={index === options!.length - 1} error={error}>
                    <FormikCheckboxOption label={option.label} option={option.value} multiple={multiple} />
                </ListItemWrapper>
            ))}
            {error && <InputLabel color="red">{error}</InputLabel>}
        </>
    )
}
