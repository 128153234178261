import React, {FC, useEffect, useRef, useState} from 'react'
import {colors, Form, List, Panel, WhiteButton} from 'nf-ui'
import SvgSwitcher from 'nf-ui/Icons/Switcher'
import styled from 'styled-components'

const FieldGroup = styled(Form.Group)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 258px;
    min-height: 48px;
`

const FieldLabel = styled.div<{readOnly?: boolean}>`
    padding-bottom: 0;
    font-weight: 300;
    color: ${({readOnly}) => (readOnly ? colors.darkGray2 : colors.black)};
`

type FormFieldProps = {
    name: string
    type: 'dropdown' | 'switch' | 'text'
    initialValue: string | boolean
    label?: string
    readOnly?: boolean
    dropDownValues?: {value: string; label?: string}[]
    onValueChanged: (value: string | boolean) => void
}

export const FormField: FC<FormFieldProps> = (props) => {
    const [dropDownPanel, setDropDownPanel] = useState(false)
    const [value, setValue] = useState<string | boolean>(props.initialValue)
    const [cleanValue, setCleanValue] = useState<string | boolean>(props.initialValue)
    const dropDownRef = useRef<HTMLButtonElement>(null)
    useEffect(() => {
        setValue(props.initialValue)
    }, [props.initialValue])

    return props.type === 'switch' ? (
        <FieldGroup name={props.name}>
            {props.label && <FieldLabel readOnly={props.readOnly}>{props.label}</FieldLabel>}
            {!props.readOnly ? (
                <Form.Switch
                    checked={!!value}
                    onChange={() => {
                        setValue(!value)
                        props.onValueChanged(!value)
                    }}
                />
            ) : (
                <Form.Switch checked={!!value} disabled />
            )}
        </FieldGroup>
    ) : props.type === 'dropdown' ? (
        <>
            <FieldGroup name={props.name}>
                {props.label && <FieldLabel readOnly={props.readOnly}>{props.label}</FieldLabel>}
                <span style={{width: '170px'}}>
                    <WhiteButton
                        disabled={props.readOnly}
                        icon={SvgSwitcher}
                        ref={dropDownRef}
                        onClick={() => setDropDownPanel(true)}
                    >
                        {props.dropDownValues?.find((availableValue) => availableValue.value === value)?.label || value}
                    </WhiteButton>
                </span>
            </FieldGroup>
            <Panel open={dropDownPanel} onClose={() => setDropDownPanel(false)} targetRef={dropDownRef}>
                <List
                    rows={props.dropDownValues!}
                    renderRow={({label, value}) => label || value}
                    width={250}
                    variant="dropdown"
                    onClick={({value}) => {
                        setValue(value)
                        if (value !== cleanValue) {
                            setCleanValue(value)
                            props.onValueChanged(value)
                        }
                        setDropDownPanel(false)
                    }}
                />
            </Panel>
        </>
    ) : props.type === 'text' ? (
        <>
            <FieldGroup name={props.name}>
                {props.label && <FieldLabel readOnly={props.readOnly}>{props.label}</FieldLabel>}
                <Form.Input
                    value={value as string}
                    disabled={props.readOnly}
                    onChange={(value) => {
                        setValue(value)
                    }}
                    onBlur={(e) => {
                        if (e.target.value !== cleanValue) {
                            setCleanValue(e.target.value)
                            props.onValueChanged(e.target.value)
                        }
                    }}
                    width={170}
                />
            </FieldGroup>
        </>
    ) : (
        <></>
    )
}
