import {stringify as qsStringify} from 'querystring'
import React, {useEffect} from 'react'
import {useAlert} from 'react-alert'
import {v4 as uuidv4} from 'uuid'
import {bugsnagClient} from '~/bugSnag'
import {PageLoading} from '~/components/PageLoading'

export const oAuthRedirectURI = `${window.location.protocol}//${window.location.host}/oauth/callback`

type Config = {
    authorize_uri: string
    scope: string
    client_id: string
    response_type: string
    identity_provider?: string | null
}

export const OAuthRedirect: React.FC<{
    config: Config | null
    onError?: () => void
}> = ({config, onError}) => {
    const alert = useAlert()

    useEffect(() => {
        if (!config) {
            alert.error('Unexpected error')
            bugsnagClient?.notify(new Error('OAuth option missing public_config'))
            onError && onError()
            return
        }

        const {authorize_uri, scope, client_id, response_type, identity_provider} = config

        const state = uuidv4()
        localStorage.setItem('oAuthState', state)
        localStorage.setItem('oAuthClientId', client_id)

        const authorizeParams = {
            scope,
            client_id,
            response_type,
            identity_provider,
            state,
            redirect_uri: oAuthRedirectURI,
        }

        window.location.href = `${authorize_uri}/oauth2/authorize/?${qsStringify(authorizeParams)}`
    }, [config, onError, alert])

    return <PageLoading />
}
