import {ApolloCache, gql, useMutation} from '@apollo/client'
import {cloneDeep} from '@apollo/client/utilities'
import pluralize from 'pluralize'
import {useCallback} from 'react'
import {useAlert} from 'react-alert'
import {UserRole} from '~/globalTypes'
import {MakeAdmin, MakeAdminVariables} from './__types__/MakeAdmin'
import {RemoveAdmin, RemoveAdminVariables} from './__types__/RemoveAdmin'
import {UserRolesQuery, UserRolesQueryVariables} from './__types__/UserRolesQuery'
import {SetSyncTypeManual, SetSyncTypeManualVariables} from './__types__/SetSyncTypeManual'
import {SetSyncTypeAuto, SetSyncTypeAutoVariables} from './__types__/SetSyncTypeAuto'

//include this in your query if you want this mutation to correctly update the cache
export const USER_ROLE_DATA = gql`
    fragment UserRolesData on OrganisationObject {
        idStr
        users {
            idStr
            role
        }
    }
`

const USER_ROLE_QUERY = gql`
    query UserRolesQuery($idStr: String!) {
        organisation(idStr: $idStr) {
            ...UserRolesData
        }
    }
    ${USER_ROLE_DATA}
`

const MAKE_ADMIN_MUTATION = gql`
    mutation MakeAdmin($organisationIdStr: String!, $userIdStrs: [String!]!) {
        makeAdmin(organisationIdStr: $organisationIdStr, userIdStrs: $userIdStrs)
    }
`

const REMOVE_ADMIN_MUTATION = gql`
    mutation RemoveAdmin($organisationIdStr: String!, $userIdStrs: [String!]!) {
        removeAdmin(organisationIdStr: $organisationIdStr, userIdStrs: $userIdStrs)
    }
`

const SET_SYNC_TYPE_MANUAL_MUTATION = gql`
    mutation SetSyncTypeManual($organisationIdStr: String!, $userIdStrs: [String!]!) {
        setSyncTypeManual(organisationIdStr: $organisationIdStr, userIdStrs: $userIdStrs)
    }
`

const SET_SYNC_TYPE_AUTO_MUTATION = gql`
    mutation SetSyncTypeAuto($organisationIdStr: String!, $userIdStrs: [String!]!) {
        setSyncTypeAuto(organisationIdStr: $organisationIdStr, userIdStrs: $userIdStrs)
    }
`

export function updateUserRolesCache<T>(
    organisationIdStr: string,
    userIdStrs: string[],
    newRole: (oldRole: UserRole) => UserRole,
) {
    return (cache: ApolloCache<T>) => {
        const result = cache.readQuery<UserRolesQuery, UserRolesQueryVariables>({
            query: USER_ROLE_QUERY,
            variables: {idStr: organisationIdStr},
        })

        if (!result) return

        const clonedResult = cloneDeep(result)
        clonedResult.organisation.users
            .filter((u) => userIdStrs.includes(u.idStr))
            .forEach((u) => {
                u.role = newRole(u.role)
            })

        cache.writeQuery({
            query: USER_ROLE_QUERY,
            data: clonedResult,
        })
    }
}

export function useRoleMutations() {
    const {success, error} = useAlert()
    pluralize.addIrregularRule('is', 'are')

    const [addAdminMutation, addAdminStatus] = useMutation<MakeAdmin, MakeAdminVariables>(MAKE_ADMIN_MUTATION, {
        onCompleted: ({makeAdmin: count}) =>
            success(`${count} ${pluralize('user', count)} ${pluralize('is', count)} now ${pluralize('admin', count)}`),
        onError: () => error('Failed to make user admin'),
    })
    const [removeAdminMutation, removeAdminStatus] = useMutation<RemoveAdmin, RemoveAdminVariables>(
        REMOVE_ADMIN_MUTATION,
        {
            onCompleted: ({removeAdmin: count}) =>
                success(
                    `${count} ${pluralize('user', count)} ${pluralize('is', count)} no longer ${pluralize(
                        'admin',
                        count,
                    )}`,
                ),
            onError: () => error('Failed to revoke admin rights'),
        },
    )
    const [makeGuestMutation, makeGuestStatus] = useMutation<SetSyncTypeManual, SetSyncTypeManualVariables>(
        SET_SYNC_TYPE_MANUAL_MUTATION,
        {
            onCompleted: ({setSyncTypeManual: count}) =>
                success(
                    `${count} ${pluralize('user', count)} ${pluralize('is', count)} now ${pluralize('guest', count)}`,
                ),
            onError: () => error('Failed to make user a guest'),
        },
    )
    const [makeUserMutation, makeUserStatus] = useMutation<SetSyncTypeAuto, SetSyncTypeAutoVariables>(
        SET_SYNC_TYPE_AUTO_MUTATION,
        {
            onCompleted: ({setSyncTypeAuto: count}) =>
                success(
                    `${count} ${pluralize('guest', count)} ${pluralize('is', count)} now ${pluralize('user', count)}`,
                ),
            onError: () => error('Failed to make guest a user'),
        },
    )

    const addAdmin = useCallback(
        async (variables: MakeAdminVariables) => {
            await addAdminMutation({
                variables,
                update: updateUserRolesCache(variables.organisationIdStr, variables.userIdStrs, (oldRole) =>
                    oldRole === UserRole.User ? UserRole.AdminUser : UserRole.AdminGuest,
                ),
            })
        },
        [addAdminMutation],
    )

    const removeAdmin = useCallback(
        async (variables: RemoveAdminVariables) => {
            await removeAdminMutation({
                variables,
                update: updateUserRolesCache(variables.organisationIdStr, variables.userIdStrs, (oldRole) =>
                    oldRole === UserRole.AdminUser ? UserRole.User : UserRole.Guest,
                ),
            })
        },
        [removeAdminMutation],
    )

    const makeGuest = useCallback(
        async (variables: SetSyncTypeManualVariables) => {
            await makeGuestMutation({
                variables,
                update: updateUserRolesCache(variables.organisationIdStr, variables.userIdStrs, (oldRole) =>
                    oldRole === UserRole.AdminUser ? UserRole.AdminGuest : UserRole.Guest,
                ),
            })
        },
        [makeGuestMutation],
    )

    const makeUser = useCallback(
        async (variables: SetSyncTypeManualVariables) => {
            await makeUserMutation({
                variables,
                update: updateUserRolesCache(variables.organisationIdStr, variables.userIdStrs, (oldRole) =>
                    oldRole === UserRole.AdminGuest ? UserRole.AdminUser : UserRole.User,
                ),
            })
        },
        [makeUserMutation],
    )

    return {
        addAdmin,
        addAdminStatus,
        removeAdmin,
        removeAdminStatus,
        makeGuest,
        makeGuestStatus,
        makeUser,
        makeUserStatus,
    }
}
