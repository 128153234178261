/* eslint-disable @typescript-eslint/no-unused-expressions */
import {gql, useMutation} from '@apollo/client'
import {Button, Infobox, Lightbox, Spacer, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgClose from 'nf-ui/Icons/Close'
import {default as React, useState} from 'react'
import {useAlert} from 'react-alert'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {DataSourceRequestType} from '~/globalTypes'
import {getGraphQLErrorMessage} from '~/util'
import {DATA} from '../AddDataSourceModal/RequestList'
import {DataSource} from './DataSource'
import {
    AddDataSourceModal_RequestDataSource,
    AddDataSourceModal_RequestDataSourceVariables,
} from './__types__/AddDataSourceModal_RequestDataSource'

export const REQUEST_DATA_SOURCE = gql`
    mutation AddDataSourceModal_RequestDataSource($organisationIdStr: String!, $dataSource: DataSourceRequestType!) {
        requestDataSource(organisationIdStr: $organisationIdStr, dataSource: $dataSource) {
            idStr
        }
    }
`

const dataSourceRequestLabels: Record<DataSourceRequestType, string> = {
    [DataSourceRequestType.ACTIVE_DIRECTORY]: 'Active Directory',
    [DataSourceRequestType.ADP]: 'ADP',
    [DataSourceRequestType.AIRTABLE]: 'Airtable',
    [DataSourceRequestType.BAMBOOHR]: 'BambooHR',
    [DataSourceRequestType.GOOGLE_DRIVE]: 'Google Drive',
    [DataSourceRequestType.GUSTO]: 'Gusto',
    [DataSourceRequestType.HIBOB]: 'HiBob',
    [DataSourceRequestType.KRONOS]: 'Kronos',
    [DataSourceRequestType.MICROSOFT_TEAMS]: 'Microsoft Teams',
    [DataSourceRequestType.NAMELY]: 'Namely',
    [DataSourceRequestType.ORACLE]: 'Oracle',
    [DataSourceRequestType.PAYCHEX]: 'Paychex',
    [DataSourceRequestType.PAYCOM]: 'Paycom',
    [DataSourceRequestType.PAYLOCITY]: 'Paylocity',
    [DataSourceRequestType.RIPPLING]: 'Rippling',
    [DataSourceRequestType.SAGE_PEOPLE]: 'Sage People',
    [DataSourceRequestType.SALESFORCE]: 'Salesforce',
    [DataSourceRequestType.SAP_SUCCESS_FACTORS]: 'SAP Success Factors',
    [DataSourceRequestType.SHAREPOINT]: 'Sharepoint',
    [DataSourceRequestType.SLACK]: 'Slack',
    [DataSourceRequestType.TRINET]: 'Trinet',
    [DataSourceRequestType.ULTIPRO]: 'Ultipro',
    [DataSourceRequestType.WORKDAY]: 'Workday',
    [DataSourceRequestType.ZENEFITS]: 'Zenefits',
    [DataSourceRequestType.OTHER]: 'Suggest an integration',
}

type RequestDataSourceProps = {
    dataSource: DataSourceRequestType
    requested: boolean
}

export const RequestDataSource: React.FC<RequestDataSourceProps> = ({dataSource, requested}) => {
    const [lightboxOpen, setLightboxOpen] = useState(false)

    const organisationIdStr = useOrganisationIdStr()
    const alert = useAlert()
    const trackAnalyticsEvent = useAnalyticsEventTracker()

    const [requestDataSource, {loading}] = useMutation<
        AddDataSourceModal_RequestDataSource,
        AddDataSourceModal_RequestDataSourceVariables
    >(REQUEST_DATA_SOURCE, {
        onError: (error) => alert.error(getGraphQLErrorMessage(error)),
        refetchQueries: [
            {
                query: DATA,
                variables: {idStr: organisationIdStr},
            },
        ],
        awaitRefetchQueries: true,
    })

    const name = dataSourceRequestLabels[dataSource]

    let label = name
    if (requested) label += ' (Requested)'

    return (
        <>
            <DataSource
                enabled={!requested}
                buttonLabel="Request"
                dataSource={{
                    label,
                    loading,
                    icon: require(`./icons/${dataSource}.png`),
                    onClick: async () => {
                        await requestDataSource({
                            variables: {
                                organisationIdStr,
                                dataSource,
                            },
                        })

                        setLightboxOpen(true)
                    },
                }}
            />
            <Lightbox onClose={() => setLightboxOpen(false)} open={lightboxOpen} width={720}>
                <div
                    data-testid="lightbox"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography.Heading>{name} is coming</Typography.Heading>
                    <Button
                        variant="tertiary"
                        disabled={loading}
                        onClick={() => setLightboxOpen(false)}
                        onClickAnalyticsEvent="select_close"
                    >
                        Close <Button.Icon icon={SvgClose} />
                    </Button>
                </div>
                <Spacer height={16} />
                <Typography.Subheading>
                    You'll be notified by email as soon as this integration is available.
                </Typography.Subheading>
                <Spacer height={32} />
                <Infobox>
                    Start building now with a {name} export.&nbsp;&nbsp;
                    <a
                        href="https://help.namesandfaces.com/managing-your-index-with-file-uploads"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => trackAnalyticsEvent('select_find_out_how')}
                    >
                        Find out how
                    </a>
                </Infobox>
            </Lightbox>
        </>
    )
}
