import {gql, useMutation} from '@apollo/client'
import {Icon, Spacer, Typography} from 'nf-ui'
import SvgAlert from 'nf-ui/Icons/Alert'
import React, {useState} from 'react'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {CurrentOrganisation, CURRENT_ORGANISATION_DATA} from '~/components/CurrentOrganisationContext'
import {ModalLayout} from '~/components/ModalLayout'
import {UploadProfilePhoto} from '~/components/UploadProfilePhoto'
import {FULL_PROFILE_DATA} from '../FullProfile/FullProfile'
import {UploadProfilePicture, UploadProfilePictureVariables} from './__types__/UploadProfilePicture'

const UploadNoticeWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

const UPLOAD_PROFILE_PICTURE = gql`
    mutation UploadProfilePicture($profileIdStr: String!, $file: Upload!) {
        uploadPhotoForProfile(profileIdStr: $profileIdStr, file: $file) {
            idStr
            profileUrl
        }
    }
`

export const ProfilePictureTab = ({
    currentPhoto,
    pendingPhoto,
    canEditProfilePicture,
}: {
    currentPhoto?: string
    pendingPhoto?: string
    canEditProfilePicture: boolean
}) => {
    const {currentOrganisation} = CurrentOrganisation.useContainer()
    const editableProfile = currentOrganisation?.editableProfile

    const [profileIdStr] = useQueryParam<string>('editProfileId')
    const [uploadPhoto] = useMutation<UploadProfilePicture, UploadProfilePictureVariables>(UPLOAD_PROFILE_PICTURE, {
        refetchQueries: [
            {query: FULL_PROFILE_DATA, variables: {idStr: profileIdStr}},
            {query: CURRENT_ORGANISATION_DATA},
        ],
        awaitRefetchQueries: true,
    })
    const [uploaded, setUploaded] = useState(false)

    const hasPendingPhoto = !!pendingPhoto || uploaded
    const photoNeedsApproval = canEditProfilePicture && !!currentOrganisation?.appFeatures?.photoApproval

    const actionRequired = !!editableProfile && editableProfile.profilePictureActionRequired

    return (
        <>
            <UploadProfilePhoto
                onFile={async (file) => {
                    const result = await uploadPhoto({variables: {profileIdStr: profileIdStr!, file}})
                    setUploaded(true)
                    return result.data?.uploadPhotoForProfile.profileUrl
                }}
                successMessage="Photo uploaded successfully"
                currentPhoto={pendingPhoto || currentPhoto}
                actionRequired={actionRequired}
                disabled={!canEditProfilePicture}
            />
            {!canEditProfilePicture && (
                <>
                    <Spacer height={24} />
                    <Typography.Paragraph>
                        Updates to your profile picture are managed by your admin
                    </Typography.Paragraph>
                </>
            )}
            {photoNeedsApproval && (
                <>
                    <Spacer height={24} />
                    <UploadNoticeWrapper>
                        <Icon style={{marginTop: '4px'}} icon={SvgAlert} />
                        <Spacer width={16} />
                        <Typography.Paragraph maxWidth={245}>
                            {hasPendingPhoto
                                ? 'Your profile picture is pending approval'
                                : 'Profile pictures require admin approval to display'}
                        </Typography.Paragraph>
                    </UploadNoticeWrapper>
                </>
            )}
            <ModalLayout.Footer />
        </>
    )
}
