import {gql, useApolloClient, useQuery} from '@apollo/client'
import {List, Spacer, Table, Typography, useAnalyticsEventTracker} from 'nf-ui'
import {useTableSort} from 'nf-ui/Table/useTableSort'
import React, {FC, useState} from 'react'
import {useAlert} from 'react-alert'
import {NumberParam, StringParam, useQueryParams} from 'use-query-params'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {ModalLayout} from '~/components/ModalLayout'
import {useModalNavigator} from '~/components/ModalLayout/ModalNavigator'
import {PageLoading} from '~/components/PageLoading'
import {useOrganisationIdStr} from '~/components/useOrganisationIdStr'
import {WizardLayoutError} from '~/components/WizardLayoutError'
import {getGraphQLErrorMessage} from '~/util'
import {useFullScreenTable} from '../ConfigureDataSource/useFullScreenTable'
import {AdditionalDataToken, AdditionalDataTokenVariables} from './__types__/AdditionalDataToken'
import {ViewDataModalData, ViewDataModalDataVariables} from './__types__/ViewDataModalData'

const DATA = gql`
    query ViewDataModalData($idStr: String!) {
        organisation(idStr: $idStr) {
            idStr
            profiles {
                idStr
                firstName
                lastName
                additional
                profileLines(includeEmptyValues: true) {
                    idStr
                    label
                    value
                }
            }
        }
    }
`

const ADDITIONAL_DATA_TOKEN = gql`
    query AdditionalDataToken($organisationIdStr: String!, $profileIdStr: String!) {
        additionalDataToken(organisationIdStr: $organisationIdStr, profileIdStr: $profileIdStr)
    }
`

type ActiveCell = {
    columnIndex: number
    rowIndex: number
}

export const ViewDataModal: FC<{hasAdditionalFields: boolean}> = ({hasAdditionalFields}) => {
    const {onClose} = useModalNavigator()
    const {ref, height, width} = useFullScreenTable({offsetHeight: 70})
    const {currentOrganisation, hrisProvider} = CurrentOrganisation.useContainer()
    const {data, error, loading, refetch} = useQuery<ViewDataModalData, ViewDataModalDataVariables>(DATA, {
        variables: {idStr: currentOrganisation!.idStr},
    })
    const [activeCell, setActiveCell] = useState<ActiveCell | undefined>()

    let tableData: Record<string, string>[] = []
    if (data) {
        tableData = data.organisation.profiles.map((profile) => {
            const profileData: Record<string, string> = {
                idStr: profile.idStr,
                Source: profile.additional ? 'Other' : hrisProvider,
                'First name': profile.firstName,
                'Last name': profile.lastName,
            }
            profile.profileLines.forEach((profileLine) => {
                profileData[profileLine.label] = profileLine.value
            })

            return profileData
        }, [] as Record<string, string>[])
    }

    const {sortedData, onClickCell, headerIcon} = useTableSort({data: tableData})

    if (error) {
        return <WizardLayoutError error={getGraphQLErrorMessage(error)} onClickRetry={refetch} />
    }

    if (!data || loading) {
        return (
            <ModalLayout>
                <ModalLayout.Navigation onClose={onClose} />
                <ModalLayout.Body>
                    <PageLoading />
                </ModalLayout.Body>
            </ModalLayout>
        )
    }

    const selectedProfile = activeCell && sortedData[activeCell.rowIndex - 1]

    return (
        <ModalLayout footerProps={{isOnboarding: false}}>
            <ModalLayout.Navigation onClose={onClose} closeText="Close" />
            <ModalLayout.Body>
                <Typography.Heading>Your data</Typography.Heading>
                <Spacer height={16} />
                <Typography.Subheading>
                    This is the current data available for display in your Names & Faces index.
                </Typography.Subheading>
                <Spacer height={32} />
                <Table
                    ref={ref}
                    data={sortedData}
                    hiddenColumns={['idStr']}
                    hoverHighlight={hasAdditionalFields ? ['row'] : []}
                    maxWidth={width}
                    maxHeight={height}
                    panel={<ProfileOptions selectedProfile={selectedProfile?.idStr} />}
                    activeCell={hasAdditionalFields ? activeCell : undefined}
                    onClickCell={({columnIndex, rowIndex}) => {
                        if (rowIndex === 0) {
                            onClickCell({columnIndex, rowIndex})
                        } else {
                            setActiveCell({columnIndex, rowIndex})
                        }
                    }}
                    headerCellsClickable
                    onClosePanel={() => setActiveCell(undefined)}
                    panelBoundaries="scrollParent"
                    headerIcon={headerIcon}
                />
            </ModalLayout.Body>
        </ModalLayout>
    )
}

const ProfileOptions: React.FC<{selectedProfile?: string}> = ({selectedProfile}) => {
    const [, setQuery] = useQueryParams({modal: StringParam, step: NumberParam, defaultSelectedProfile: StringParam})
    const organisationIdStr = useOrganisationIdStr()
    const apolloClient = useApolloClient()
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const alert = useAlert()

    const openCollectionForm = async () => {
        if (!selectedProfile) return

        try {
            const response = await apolloClient.query<AdditionalDataToken, AdditionalDataTokenVariables>({
                query: ADDITIONAL_DATA_TOKEN,
                variables: {
                    organisationIdStr,
                    profileIdStr: selectedProfile,
                },
            })

            window.open(`/data_collection/${response.data.additionalDataToken}`, '_blank')
        } catch (error) {
            alert.error(getGraphQLErrorMessage(error))
        }
    }

    return (
        <List
            heading={<Typography.Label>What would you like to do?</Typography.Label>}
            rows={['Collect responses', 'Edit fields']}
            renderRow={(row) => row}
            width={304}
            onClick={async (row) => {
                if (row === 'Collect responses') {
                    setQuery({
                        modal: 'addAdditionalData',
                        step: 1,
                        defaultSelectedProfile: selectedProfile,
                    })
                    trackAnalyticsEvent('click_collect_responses')
                } else if (row === 'Edit fields') {
                    openCollectionForm()
                    trackAnalyticsEvent('click_edit_fields')
                }
            }}
            variant="dropdown"
        />
    )
}
