import {Typography} from 'nf-ui'
import React from 'react'
import styled from 'styled-components'
import {useCurrentUser} from '~/components/useCurrentUser'
import {ConnectCalendarCard} from './Meetings/ConnectCalendarCard'
import {MeetingsLightbox} from './Meetings/MeetingsLightbox/MeetingsLightbox'
import {WeeklyMeetingList} from './Meetings/WeeklyMeetingList'
import {useCalendarConnected} from './Meetings/useCalendarConnected'

const Container = styled.div`
    padding: 0 32px 32px;
`

const Header = styled.div`
    align-items: center;
    justify-content: space-between;
    padding: 32px 0 24px 0;
    position: relative;
`

export const Meetings: React.FC = () => {
    const {me} = useCurrentUser()

    const showLightbox = !me?.hasViewedMeetings

    const {connected} = useCalendarConnected()

    return (
        <>
            <Container>
                <Header>
                    <Typography.Heading>This week's meetings</Typography.Heading>
                </Header>

                {connected ? <WeeklyMeetingList /> : <ConnectCalendarCard />}
            </Container>
            {showLightbox && <MeetingsLightbox showLightbox={showLightbox} />}
        </>
    )
}
