import {Button, Panel, Spacer, Tabs} from 'nf-ui'
import SvgCaret from 'nf-ui/Icons/Caret'
import SvgCog from 'nf-ui/Icons/Cog'
import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {PageLoading} from '~/components/PageLoading'
import {ProfileSidePanel} from './AttendeeSidePanel'
import {MeetingsList} from './MeetingsList'
import {MeetingsSettingsPanel} from './MeetingsSettingsPanel'
import {useCalendarConnected} from './useCalendarConnected'
import {useUpcomingMeetings} from './useUpcomingMeetings'

const OuterContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;
    position: relative;
`

const MeetingsListContainer = styled.div`
    flex: 1;
    position: relative;
`

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const WeeklyMeetingList = () => {
    const {meetingsThisWeek, error, loading} = useUpcomingMeetings()
    const {setConnected} = useCalendarConnected()

    const settingsRef = useRef<HTMLDivElement>(null)
    const [settingsPanelOpen, setSettingsPanelOpen] = useState(false)

    if (error) {
        setConnected(false)
        return null
    }

    if (loading) return <PageLoading />

    return (
        <OuterContainer>
            <MeetingsListContainer>
                <FlexWrapper>
                    <Tabs activeTabIndex={0} onChangeActiveTabIndex={() => {}}>
                        <Tabs.Tab>Upcoming in the next week</Tabs.Tab>
                    </Tabs>
                    <div ref={settingsRef}>
                        <Button variant="tertiary" onClick={() => setSettingsPanelOpen(true)}>
                            <div style={{marginRight: '-4px'}}>
                                <Button.Icon icon={SvgCog} size={24} />
                            </div>
                            <Button.Icon icon={SvgCaret} />
                        </Button>
                    </div>
                </FlexWrapper>
                <Spacer height={32} />
                <MeetingsList meetings={meetingsThisWeek} />
                <Panel
                    open={settingsPanelOpen}
                    onClose={() => setSettingsPanelOpen(false)}
                    targetRef={settingsRef}
                    placement="bottom-end"
                    boundariesElement="scrollParent"
                >
                    <MeetingsSettingsPanel onClose={() => setSettingsPanelOpen(false)} />
                </Panel>
            </MeetingsListContainer>
            <ProfileSidePanel />
        </OuterContainer>
    )
}
