import {Button} from 'nf-ui'
import React, {useEffect} from 'react'
import {playground} from '~/pages/Playground'

import {Form} from './Form'

export const Consent = ({directoryName, onNext}: {directoryName: string; onNext: () => Promise<void>}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Form
            directoryName={directoryName}
            heading="You're almost done"
            subHeading={`Do you consent to having this information and family photo included in the ${directoryName} Names & Faces Directory?`}
            progress={0}
            onNext={onNext}
        >
            <Button
                style={{
                    padding: '0px 24px',
                    borderRadius: '5px',
                    height: '48px',
                }}
                onClick={onNext}
            >
                Yes, I grant my consent
            </Button>
        </Form>
    )
}

playground.push({
    path: 'src/components/CommunityBuild/Consent.tsx',
    component: Consent,
    props: {directoryName: 'Wetpups Grade 3'},
    propOptions: {
        onNext: () => {},
    },
})
