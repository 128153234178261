import {ActionBar, Button, easeInOutQuadCSS, PillCollection, Portal, Typography, useTheme} from 'nf-ui'
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {useQueryParam} from 'use-query-params'
import {CurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {useElementSize} from '~/components/ProfilesGridLayout/useElementSize'
import {UserlandSortButton} from '~/components/SortProfiles'
import {MessageOption} from '~/globalTypes'
import {useProfileGroupContext} from './ProfileGroupContext'
import {UserlandModal} from './UserlandRoot'

const HORIZONTAL_MARGIN = 64

const ActionBarContext = React.createContext<{
    width: number | null
    setWidth: (width: number | null) => void
    container: HTMLDivElement | null
} | null>(null)

export const useSetActionBarWidth = (width: number) => {
    const actionBarContext = useContext(ActionBarContext)
    actionBarContext && actionBarContext.setWidth(width)
}

export const ActionBarSizer: React.FC = ({children}) => {
    const ref = useRef<HTMLDivElement>(null)
    const {width} = useElementSize(ref)
    useSetActionBarWidth(width - HORIZONTAL_MARGIN * 2)

    return (
        <div ref={ref} style={{width: '100%', height: '100%'}}>
            {children}
        </div>
    )
}

const PortalContainer = styled.div`
    z-index: 1;
    position: absolute;
    bottom: 32px;
    left: ${HORIZONTAL_MARGIN}px;
    right: ${HORIZONTAL_MARGIN}px;
`

export const UserlandActionBarContext: React.FC = ({children}) => {
    const [width, setWidth] = useState<number | null>(null)
    const [container, setContainer] = useState<HTMLDivElement | null>(null)

    const contextValue = useMemo(
        () => ({
            width,
            setWidth,
            container,
        }),
        [width, setWidth, container],
    )

    return (
        <ActionBarContext.Provider value={contextValue}>
            {children}
            <PortalContainer ref={setContainer} style={{width: width || undefined}} />
        </ActionBarContext.Provider>
    )
}

const PillsContainer = styled.div`
    display: flex;
    justify-items: flex-start;
    flex: 1;
    min-width: 0;
`
interface ActionBarProps<T = any> {
    label?: string | React.ReactNode
    pills?: T[] | null
    onPillChange?: (pill: T) => void
    pillLabelExtractor?: (pill: T) => string
    loading?: boolean
    showSort?: boolean
    showGroupMessage?: boolean
    right?: React.ReactNode
    profileIdStrs?: string[]
}

export const UserlandActionBar: React.FC<ActionBarProps> = ({
    label,
    pills,
    onPillChange,
    pillLabelExtractor,
    loading,
    showSort = true,
    showGroupMessage = true,
    profileIdStrs = [],
    right,
}) => {
    const theme = useTheme()
    const {currentOrganisation} = CurrentOrganisation.useContainer()

    const {setWidth, container, width} = useContext(ActionBarContext) || {}
    const [, setModal] = useQueryParam<UserlandModal>('modal')
    const {setProfileIdStrs} = useProfileGroupContext()

    useEffect(() => {
        if (!loading && setWidth) setWidth(null)
    }, [loading, setWidth])

    const groupMessageEnabled = showGroupMessage && currentOrganisation?.messageOptions.includes(MessageOption.EMAIL)

    return (
        <Portal container={container}>
            <ActionBar style={{width: width || undefined, transition: `0.3s width ${easeInOutQuadCSS}`}} static>
                {typeof label === 'string' ? (
                    <div style={{minWidth: '88px'}}>
                        <Typography.Paragraph bottomMargin={false} color={theme.primary.textColor}>
                            {label}
                        </Typography.Paragraph>
                    </div>
                ) : (
                    <div>{label}</div>
                )}
                {pills && (
                    <PillsContainer>
                        <PillCollection
                            labelSelect={pillLabelExtractor}
                            pills={pills}
                            onChange={onPillChange}
                            clearAll
                            keySelector={(pill) => pill.idStr}
                        />
                    </PillsContainer>
                )}

                <div>
                    {showSort && <UserlandSortButton />}
                    {right ||
                        (groupMessageEnabled && (
                            <Button
                                variant="secondary"
                                organisationTheme={theme}
                                onClick={() => {
                                    setProfileIdStrs(profileIdStrs)
                                    setModal('groupMessage')
                                }}
                                onClickAnalyticsEvent={'click_email_group'}
                            >
                                Email Group
                            </Button>
                        ))}
                </div>
            </ActionBar>
        </Portal>
    )
}
