import {colors, Typography, Spacer} from 'nf-ui'
import React, {useRef} from 'react'
import styled from 'styled-components'
import {useElementSize} from '~/components/ProfilesGridLayout/useElementSize'
import {Profile} from '../../useFullOrgChartData'
import {getProfileReports} from '../getProfileReports'
import {ProfileBrick} from '../ProfileBrick'
import {
    PROFILE_ROW_HEIGHT,
    LINE_WIDTH,
    BRACKET_WIDTH,
    GUTTER,
    INDENT,
    BRACKET_OFFSET,
    DIRECT_LINE_OFFSET,
} from './constants'
import {GridVerticalLines} from './VerticalLines'

const DirectReportsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${GUTTER}px;
`

const DirectReportsContainer = styled.div`
    display: flex;
`

const InnerReportsContainer = styled.div`
    flex: 1;
`

const DirectReportsCount = styled.div`
    display: flex;
    position: absolute;
    height: 18px;
    width: 18px;
    top: ${PROFILE_ROW_HEIGHT}px;
    left: ${DIRECT_LINE_OFFSET}px;
    transform: translateX(-50%);
    border: 1px solid ${colors.darkGray};
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: 0px;
    background-color: ${colors.white};
    justify-content: center;
    z-index: -2;
    transition: opacity 0.3s ease-in-out;
`

const Bracket = styled.div.attrs({className: 'org-chart-line'})<{verticalHeight: number | null}>`
    position: absolute;
    height: ${(props) => (props.verticalHeight ? props.verticalHeight - PROFILE_ROW_HEIGHT - 2 * LINE_WIDTH : 0)}px;
    width: ${BRACKET_WIDTH}px;
    top: ${PROFILE_ROW_HEIGHT + GUTTER + PROFILE_ROW_HEIGHT / 2}px;
    left: ${INDENT - BRACKET_WIDTH - BRACKET_OFFSET - LINE_WIDTH}px;
    border-left: ${LINE_WIDTH}px solid ${colors.black};
    border-top: ${LINE_WIDTH}px solid ${colors.black};
    border-bottom: ${LINE_WIDTH}px solid ${colors.black};
    z-index: -1;
`

const BracketConnector = styled.div.attrs({className: 'org-chart-line'})<{verticalHeight: number | null}>`
    position: absolute;
    width: ${INDENT - BRACKET_OFFSET - BRACKET_WIDTH - DIRECT_LINE_OFFSET - LINE_WIDTH}px;
    top: ${(props) => PROFILE_ROW_HEIGHT + GUTTER + (props.verticalHeight ? props.verticalHeight / 2 : 0)}px;
    left: ${DIRECT_LINE_OFFSET}px;
    border-top: ${LINE_WIDTH}px solid ${colors.black};
    z-index: -1;
`

const Indentation = styled.div`
    width: ${INDENT}px;
    flex-shrink: 0;
`

const Count = styled(Typography.Tiny)`
    font-weight: 500;
    margin-top: 1px;
`

export const ProfileChildren: React.FC<{
    profile: Profile
    getProfileFromIdStr: (idStr: string) => Profile | null
}> = React.memo(({profile, getProfileFromIdStr}) => {
    const gridRef = useRef<HTMLDivElement>(null)
    const {height: gridContainerHeight} = useElementSize(gridRef)

    const {
        profilesWithReports,
        reportsCount,
        profilesForGrid,
        singleProfiles,
        additionalManagerProfiles,
        additionalReports,
        firstChild,
        lastChild,
        lastDirectChild,
    } = getProfileReports(profile, getProfileFromIdStr)

    const hasGridProfiles = profilesForGrid.length > 0
    const hasAdditionalReports = additionalReports.length > 0

    const profileRows = [...singleProfiles, ...additionalManagerProfiles, ...profilesWithReports, ...additionalReports]

    const onlyGrid = singleProfiles.length + additionalManagerProfiles.length + profilesWithReports.length === 0

    const isFirstChild = (report: Profile) => {
        return report.idStr === firstChild?.idStr
    }

    const isAdditional = (report: Profile) => {
        return profile.additionalReports.includes(report.idStr)
    }

    const getVerticalLines = (report: Profile) => {
        return {
            bottomVerticalLine: {
                visible: report.idStr !== lastChild?.idStr,
                additional: report.idStr === lastDirectChild?.idStr && hasAdditionalReports,
            },
            topVerticalLine: {
                visible: true,
                additional: isAdditional(report),
            },
        }
    }

    return (
        <>
            {reportsCount > 0 && (
                <div className="reports-count">
                    <DirectReportsCount>
                        <Count>{reportsCount}</Count>
                    </DirectReportsCount>
                </div>
            )}

            {hasGridProfiles && (
                <div className="bracket">
                    <GridVerticalLines gridContainerHeight={gridContainerHeight} siblings={!onlyGrid} />
                    <Bracket verticalHeight={gridContainerHeight} />
                    <BracketConnector verticalHeight={gridContainerHeight} />
                </div>
            )}

            <DirectReportsContainer>
                <Indentation />
                <InnerReportsContainer>
                    {profilesForGrid.length > 0 && (
                        <>
                            <DirectReportsGrid ref={gridRef}>
                                {profilesForGrid.map((report, index) => (
                                    <ProfileBrick
                                        key={report.idStr}
                                        profile={report}
                                        getProfileFromIdStr={getProfileFromIdStr}
                                        grid={profilesForGrid.length}
                                        showLine={index % 3 === 0}
                                        parentIdStr={profile.idStr}
                                    />
                                ))}
                            </DirectReportsGrid>
                            <Spacer height={GUTTER} />
                        </>
                    )}

                    {profileRows.length > 0 &&
                        profileRows.map((report) => (
                            <ProfileBrick
                                key={report.idStr}
                                profile={report}
                                getProfileFromIdStr={getProfileFromIdStr}
                                parentIdStr={profile.idStr}
                                isFirstChild={isFirstChild(report)}
                                additional={isAdditional(report)}
                                {...getVerticalLines(report)}
                            />
                        ))}
                </InnerReportsContainer>
            </DirectReportsContainer>
        </>
    )
})
