import {Button, colors, Form, HeaderLayout, Spacer, Spinner, Typography} from 'nf-ui'
import React from 'react'
import {ModalLayout} from '~/components/ModalLayout'
import styled from 'styled-components'
import {fieldTypes, ManageFieldsState} from './useManageFields'
import {Input} from 'nf-ui/Form/Input'
import {FieldsData_dataSource_importConfig_fieldRules} from './__types__/FieldsData'

const Container = styled.div`
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background-color: #fff;
    width: ${(props: {width: string}) => props.width}px;
`

const FormContainer = styled.div`
    max-width: 450px;
    padding: 16px;
`

const SpinnerContainer = styled.div`
    font-family: halyard-display;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
`

const PadRight16 = styled.span`
    padding: 0px 16px 0px 0px;
`

const Scroller = styled.div`
    overflow-y: scroll;
    max-height: 400px;
    border-top: 1px solid #d5dbdf;
    border-width: 1px 0;
`

const ResultRow = styled.div`
    padding: 16px 0px 16px 16px;
    border-bottom: 1px solid #d5dbdf;
    border-width: 1px 0;
    cursor: pointer;
    &:hover {
        background-color: ${colors.lightGray};
    }
`

const FooterText = styled.span`
    color: white;
    font-family: halyard-display;
    font-weight: 500;
`

const canSave = (state: ManageFieldsState) =>
    state.defineField?.draftFieldRule?.fieldName &&
    state.defineField?.draftFieldRule?.fieldType &&
    !['External Id', 'Photo URL', 'Reports To'].includes(state.defineField?.draftFieldRule?.fieldName) &&
    (state.defineField?.editingFieldRule ||
        !state.fieldRules?.map((fr) => fr.fieldName)?.includes(state.defineField?.draftFieldRule?.fieldName)) &&
    (!state.defineField?.editingFieldRule ||
        state.defineField?.editingFieldRule === state.defineField?.draftFieldRule?.fieldName ||
        !state.fieldRules?.map((fr) => fr.fieldName)?.includes(state.defineField?.draftFieldRule?.fieldName))

interface DefineFieldProps {
    state: ManageFieldsState
    setSearchString: (search: string) => void
    startFieldSearch: () => void
    completeFieldSearch: () => void
    updateDraftFieldRule: (draftFieldRule: Partial<FieldsData_dataSource_importConfig_fieldRules>) => void
    completeFieldRuleEdit: () => Promise<void>
    setModal: (modal: string) => void
}

export const DefineField: React.FC<DefineFieldProps> = ({
    state,
    setSearchString,
    startFieldSearch,
    completeFieldSearch,
    updateDraftFieldRule,
    completeFieldRuleEdit,
    setModal,
}) => {
    return (
        <ModalLayout>
            <ModalLayout.Navigation closeable={false}></ModalLayout.Navigation>
            <ModalLayout.Body>
                <HeaderLayout
                    heading={
                        state.defineField?.editingFieldRule
                            ? `Editing field: '${state.defineField?.editingFieldRule}'`
                            : 'Add a field'
                    }
                    skipPadding={true}
                    rightContent={
                        <>
                            {!state.defineField?.showingSearch && (
                                <PadRight16>
                                    <Button
                                        variant="primary"
                                        disabled={!canSave(state)}
                                        onClick={() => {
                                            if (!canSave(state)) return
                                            completeFieldRuleEdit().then(() => {
                                                setModal('manageFields')
                                            })
                                        }}
                                    >
                                        Save
                                    </Button>
                                </PadRight16>
                            )}
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    if (state.defineField?.showingSearch && state.defineField?.editingFieldRule) {
                                        completeFieldSearch()
                                    } else {
                                        setModal('manageFields')
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        </>
                    }
                >
                    {state.defineField?.showingSearch && (
                        <Container width="960">
                            <FormContainer>
                                <Typography.Label>Search for your field</Typography.Label>
                                <Spacer height={16}></Spacer>
                                <Form.SearchInput
                                    onChange={setSearchString}
                                    value={(state.defineField?.searchString || '').toLowerCase()}
                                    width={450}
                                ></Form.SearchInput>
                            </FormContainer>
                            {!state.footerAction?.busy &&
                                state.loading.indexData &&
                                (state.defineField?.searchString?.length || 0) > 2 && (
                                    <>
                                        <SpinnerContainer>
                                            <div>Building search index</div>
                                        </SpinnerContainer>
                                        <SpinnerContainer>
                                            <Spinner size={32} />
                                        </SpinnerContainer>
                                    </>
                                )}
                            <Scroller>
                                {!state.footerAction?.busy &&
                                    !state.loading.indexData &&
                                    (state.defineField?.searchResults || []).map((searchResult, searchResultIndex) => (
                                        <ResultRow
                                            key={searchResultIndex}
                                            onClick={() => {
                                                updateDraftFieldRule({expression: searchResult.expression})
                                                completeFieldSearch()
                                            }}
                                        >
                                            <div>
                                                {searchResult.path.map((pathPart) => (
                                                    <span key={`${pathPart.normal}${pathPart.highlighted}`}>
                                                        <Typography.Tiny>{pathPart.normal}</Typography.Tiny>
                                                        <Typography.Tiny bold={true}>
                                                            {pathPart.highlighted}
                                                        </Typography.Tiny>
                                                    </span>
                                                ))}
                                            </div>
                                            <Typography.Paragraph bottomMargin={false}>
                                                {searchResult.partialPreview}
                                            </Typography.Paragraph>
                                        </ResultRow>
                                    ))}
                            </Scroller>
                        </Container>
                    )}
                    {!state.defineField?.showingSearch && (
                        <Container width="960">
                            <FormContainer>
                                <Typography.Label>Field</Typography.Label>
                                <Spacer height={16}></Spacer>
                                <Form.Input
                                    value={state.defineField?.draftFieldRule?.expression?.toLowerCase()}
                                    readOnly={true}
                                    onClick={() => {
                                        setSearchString(
                                            state.defineField?.draftFieldRule?.expression?.toLowerCase() || '',
                                        )
                                        startFieldSearch()
                                    }}
                                ></Form.Input>
                                <Spacer height={16}></Spacer>
                                <Typography.Label>Field Name</Typography.Label>
                                <Spacer height={16}></Spacer>
                                <Form.Input
                                    value={state.defineField?.draftFieldRule?.fieldName || ''}
                                    onChange={(value) =>
                                        updateDraftFieldRule({...state.defineField?.draftFieldRule, fieldName: value})
                                    }
                                ></Form.Input>
                                <Spacer height={16}></Spacer>
                                <Typography.Label>Behaviour</Typography.Label>
                                <Spacer height={16}></Spacer>
                                <Input
                                    isSelect={true}
                                    value={state.defineField?.draftFieldRule?.fieldType || ''}
                                    onChange={(value) =>
                                        updateDraftFieldRule({...state.defineField?.draftFieldRule, fieldType: value})
                                    }
                                >
                                    <option></option>
                                    {Object.keys(fieldTypes).map((fieldType) => (
                                        <option key={fieldType} value={fieldType}>
                                            {fieldTypes[fieldType]}
                                        </option>
                                    ))}
                                </Input>
                            </FormContainer>
                        </Container>
                    )}
                </HeaderLayout>
            </ModalLayout.Body>
            {state.footerAction && (
                <ModalLayout.Footer color="#3c8eff">
                    <PadRight16>
                        <FooterText>{state.footerAction.message}</FooterText>
                    </PadRight16>
                    <Button variant="secondary" onClick={state.footerAction.handler} loading={state.footerAction.busy}>
                        {state.footerAction.button}
                    </Button>
                </ModalLayout.Footer>
            )}
        </ModalLayout>
    )
}
