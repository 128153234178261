import {MOBILE_MEDIA_QUERY, SIDEBAR_WIDTH} from 'nf-ui'
import React, {FC} from 'react'
import styled, {css} from 'styled-components'

type IllustrationComponentProps = {
    footer?: boolean
    maxWidth?: number
    sidebar?: boolean
}

const IllustrationComponent = styled.div<IllustrationComponentProps>`
    position: fixed;
    bottom: ${(props) => (props.footer ? '104px' : '32px')};
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: ${({sidebar = false}) => (sidebar ? `calc(100% - ${SIDEBAR_WIDTH + 32}px)` : `100%`)};
    justify-content: space-between;
    z-index: -100;
    ${(props) => css`
        @media (max-width: ${props.maxWidth}px) {
            display: none;
        }
    `}
    ${MOBILE_MEDIA_QUERY} {
        display: none;
    }
`

const LeftIllustrationComponent = styled.div`
    margin-left: 32px;
`
const RightIllustrationComponent = styled.div<{leftExists: boolean}>`
    margin-right: 32px;

    ${(props) =>
        props.leftExists
            ? ''
            : css`
                  flex: 1;
                  display: flex;
                  justify-content: flex-end;
              `}
`

export type IllustrationProps = {
    LeftIllustration?: string
    RightIllutration?: string
    width?: number
} & IllustrationComponentProps

export const Illustrations: FC<IllustrationProps> = ({
    LeftIllustration,
    RightIllutration,
    footer,
    maxWidth = 0,
    sidebar,
    width,
}) => {
    return (
        <IllustrationComponent footer={!!footer} maxWidth={maxWidth} sidebar={sidebar}>
            {LeftIllustration && (
                <LeftIllustrationComponent>
                    <img width={width} srcSet={`${LeftIllustration} 2x`} alt="" />
                </LeftIllustrationComponent>
            )}
            {RightIllutration && (
                <RightIllustrationComponent leftExists={!!LeftIllustration}>
                    <img width={width} srcSet={`${RightIllutration} 2x`} alt="" />
                </RightIllustrationComponent>
            )}
        </IllustrationComponent>
    )
}
