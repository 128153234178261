import {isIOS} from 'react-device-detect'

const DEFAULT_FINISH_TIMEOUT_MILLISECONDS = 400

export const scrollIfNeeded = (
    element: HTMLElement | null,
    options?: {
        startTimeoutMilliseconds?: number
        finishTimeoutMilliseconds?: number
        marginTop?: number
        marginBottom?: number
        scrollContainer?: HTMLElement | null
        scrolling?: {scrollToY: number; finishTimeoutMilliseconds: number}
        align?: 'top' | 'center' | 'bottom'
        onScrollBegin?: (element: HTMLElement) => void
        onScrollComplete?: (element: HTMLElement) => void
    },
) => {
    if (!element) return
    const container = {
        width: options?.scrollContainer
            ? options.scrollContainer.clientWidth
            : isIOS
            ? window.visualViewport!.width
            : window.innerWidth,
        height: options?.scrollContainer
            ? options.scrollContainer.clientHeight
            : isIOS
            ? window.visualViewport!.height
            : window.innerHeight,
    }
    const scroll = {
        x: options?.scrollContainer ? options.scrollContainer.scrollLeft : window.scrollX,
        y: options?.scrollContainer ? options.scrollContainer.scrollTop : window.scrollY,
    }
    const offset = {
        x: element.offsetLeft,
        y: element.offsetTop,
        width: element.offsetWidth,
        height: element.offsetHeight,
    }
    const neededTop = scroll.y - offset.y + (options?.marginTop || 0)
    const neededBottom = offset.y + offset.height + (options?.marginBottom || 0) - scroll.y - container.height
    const scrollingNeededY =
        offset.height > 0 && (neededTop > 0 || neededBottom > 0) && (neededTop <= 0 || neededBottom <= 0)
    const scrollToY =
        options?.align === 'top'
            ? offset.y - (options?.marginTop || 0)
            : options?.align === 'bottom'
            ? offset.y + (offset.height + (options?.marginBottom || 0)) - container.height
            : offset.y +
              (offset.height + (options?.marginBottom || 0) - (options?.marginTop || 0)) / 2 -
              container.height / 2
    const initiateNewScrolling = scrollingNeededY && (!options?.scrolling || options.scrolling.scrollToY !== scrollToY)

    // console.log(
    //     'scrollIfNeeded',
    //     'container',
    //     container,
    //     'scroll',
    //     scroll,
    //     'offset',
    //     offset,
    //     'scrollingNeededY',
    //     scrollingNeededY,
    //     'scrollToY',
    //     scrollToY,
    //     'options',
    //     options
    // )

    if (options?.scrolling && options.scrolling.finishTimeoutMilliseconds <= 0) {
        options?.onScrollComplete && options.onScrollComplete(element)
        return
    }

    if (!scrollingNeededY && (options?.startTimeoutMilliseconds || 0) <= 0) {
        options?.onScrollComplete && options.onScrollComplete(element)
        return
    }

    if (initiateNewScrolling) {
        options?.onScrollBegin && options.onScrollBegin(element)
        const scrollContainer = options?.scrollContainer || window
        if (
            (options?.scrolling?.finishTimeoutMilliseconds !== undefined
                ? options?.scrolling?.finishTimeoutMilliseconds
                : options?.finishTimeoutMilliseconds !== undefined
                ? options?.finishTimeoutMilliseconds
                : DEFAULT_FINISH_TIMEOUT_MILLISECONDS) <= 0
        ) {
            scrollContainer.scrollTo({top: scrollToY})
            options?.onScrollComplete && options.onScrollComplete(element)
            return
        } else {
            scrollContainer.scrollTo({top: scrollToY, behavior: 'smooth'})
        }
    }

    setTimeout(
        () =>
            scrollIfNeeded(element, {
                startTimeoutMilliseconds: (options?.startTimeoutMilliseconds || 0) - 200,
                marginTop: options?.marginTop,
                marginBottom: options?.marginBottom,
                align: options?.align,
                scrollContainer: options?.scrollContainer,
                scrolling: initiateNewScrolling
                    ? {
                          scrollToY,
                          finishTimeoutMilliseconds: options?.scrolling
                              ? (options?.scrolling?.finishTimeoutMilliseconds || 0) - 200
                              : options?.finishTimeoutMilliseconds !== undefined
                              ? options?.finishTimeoutMilliseconds
                              : DEFAULT_FINISH_TIMEOUT_MILLISECONDS,
                      }
                    : options?.scrolling
                    ? {
                          ...options?.scrolling,
                          finishTimeoutMilliseconds: (options?.scrolling?.finishTimeoutMilliseconds || 0) - 200,
                      }
                    : undefined,
                onScrollComplete: options?.onScrollComplete,
            }),
        200,
    )
}
