import {hex, hsl} from 'color-convert'
import {motion, useAnimation} from 'framer-motion'
import {easeInOutQuadCSS, Icon, Spacer, Typography, useTheme} from 'nf-ui'
import SvgChevronRight from 'nf-ui/Icons/ChevronRight'
import React, {ReactElement, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {areEqual, FixedSizeGrid, FixedSizeGridProps, GridChildComponentProps} from 'react-window'
import styled from 'styled-components'
import {useCurrentOrganisation} from '~/components/CurrentOrganisationContext'
import {OrganisationDataWithTypes, useOrganisationContext} from '~/components/OrganisationContext'
import {PHOTO_HEIGHT, PHOTO_WIDTH} from '~/components/Photo/Photo'
import {Profile} from '~/components/Profile'
import {
    getPaddingObject,
    InnerElement,
    OuterElement,
    PaddingContext,
    PaddingProp,
} from '~/components/ProfilesGrid/padding'
import {getThumbLinesByProfile, LinesWithValuesByProfile} from '~/components/ProfilesGrid/ProfilesGrid'
import {LETTERS} from '~/components/Rolodex'
import {useSortProfiles} from '~/components/SortProfiles'
import {useRelativeRoute} from '~/components/useRelativeRoute'
import {SortMode, UserSettings} from '~/components/UserSettingsContext'
import {useSetActionBarWidth} from '~/pages/Userland/ActionBar'
import {profilesGridTransition} from '~/pages/Userland/FullProfileSidePanel'
import {useAllHomeItem} from '~/pages/Userland/useAllStaffData'
import {UnreachableCaseError} from '~/util'

const SPACING = 8

export type ProfilesGridProps = {
    width: number
    maxHeight?: number
    data: OrganisationDataWithTypes
    rowLimit?: boolean
    rowCount?: number
    padding?: PaddingProp
    actionBar?: ReactElement
    onScroll?: FixedSizeGridProps['onScroll']
    sort?: boolean
}

const hoverColor = (hexColor: string) => {
    const [h, s, l] = hex.hsl(hexColor)
    return '#' + hsl.hex([h, s, l * 0.85])
}

const AllPeopleLinkContainer = styled.div`
    height: ${PHOTO_HEIGHT}px;
    width: ${PHOTO_WIDTH * 2 + SPACING}px;
    background: ${({theme}) => theme.primary.color};
    display: flex;
    cursor: pointer;
    justify-content: center;
    border-radius: 3px;
    align-items: center;
    transition: background 300ms ${easeInOutQuadCSS};

    :hover {
        background: ${({theme}) => hoverColor(theme.primary.color)};
    }
`

type ItemData = Pick<ProfilesGridProps, 'data' | 'rowLimit'> & {
    columnCount: number
    lines: LinesWithValuesByProfile
    label?: string
}

type CellProps = Omit<GridChildComponentProps, 'data'> & {
    data: ItemData
}

const Cell = React.memo<CellProps>(
    ({rowIndex, columnIndex, style, data: {columnCount, rowLimit, data, label, lines}}) => {
        const dataIndex = rowIndex * columnCount + columnIndex
        const theme = useTheme()
        const {pushRelative} = useRelativeRoute()

        // If in rowLimit its true, this counts the number of profiles to be displayed before the AllProfilesContainer
        const count = columnCount * 2 - 3

        if (
            dataIndex === count + 1 &&
            rowLimit &&
            data.profiles.length > count &&
            !data.profiles.some((profile) => profile.match)
        ) {
            return (
                <div style={style} onClick={() => pushRelative(`/all`, {keepSearch: true})}>
                    <AllPeopleLinkContainer>
                        <Typography.Label color={theme.primary.textColor}> View {label}</Typography.Label>
                        <Spacer width={16} />

                        <Icon icon={SvgChevronRight} tint={theme.primary.textColorHex} />
                    </AllPeopleLinkContainer>
                </div>
            )
        }

        if (dataIndex > data.profiles.length - 1 || (dataIndex > count && rowLimit)) return null

        const profile = data.profiles[dataIndex]

        return (
            <div style={style}>
                <Profile profile={profile} lines={lines[profile.idStr]} />
            </div>
        )
    },
    areEqual,
)

const ROW_HEIGHT = PHOTO_HEIGHT + SPACING

/**
 * Find the index of the first profile that matches a given letter.
 */
const profileIndexForLetter = ({
    profiles,
    letter,
    sortMode,
}: {
    profiles: OrganisationDataWithTypes['profiles']
    letter: string
    sortMode: SortMode
}): number => {
    const lowercaseLetter = letter.toLowerCase()

    const index = profiles.findIndex((profile) => {
        let fieldValue = ''
        if (sortMode === 'firstName') fieldValue = profile.firstName
        else if (sortMode === 'lastName') fieldValue = profile.lastName
        else throw new UnreachableCaseError(sortMode)

        return fieldValue.toLowerCase().startsWith(lowercaseLetter)
    })

    // If we don't find a profile for that letter, try and find one for the next letter
    if (index === -1) {
        const nextLetter = LETTERS[LETTERS.indexOf(letter) + 1]
        if (nextLetter) return profileIndexForLetter({profiles, letter: nextLetter, sortMode})
        return profiles.length - 1
    }

    return index
}

/**
 * Scroll the grid to the first profile matching a given letter.
 */
const scrollToLetter = ({
    letter,
    gridRef,
    profiles,
    columnCount,
    sortMode,
}: {
    columnCount: number
    letter: string
    gridRef: React.RefObject<FixedSizeGrid>
    profiles: OrganisationDataWithTypes['profiles']
    sortMode: SortMode
}) => {
    if (!gridRef.current) return

    const index = profileIndexForLetter({profiles, letter, sortMode})
    if (index === -1) return

    const rowIndex = Math.floor(index / columnCount)
    const columnIndex = index - rowIndex * columnCount

    gridRef.current.scrollToItem({
        rowIndex,
        columnIndex,
        align: 'start',
    })
}

export type ProfilesGrid = {
    /**
     * Scroll the grid to the first profile matching a given letter.
     */
    scrollToLetter: (letter: string) => void
}

export const gridSizeFor = (width: number) => {
    const columnCount = Math.floor((width + SPACING) / (PHOTO_WIDTH + SPACING))
    const contentWidth = columnCount * (PHOTO_WIDTH + SPACING) - SPACING

    return {
        contentWidth,
        columnCount,
    }
}

/**
 * When a new `currentWidth` is passsed, fade the list to to opacity `0.5`,
 * then update the list's width while it's faded out,
 * then fade back to opacity `1`.
 */
const useFadeWidthChange = (currentWidth: number) => {
    const [width, setWidth] = useState(currentWidth)
    const animate = useAnimation()

    const previousWidth = useRef(0)
    useEffect(() => {
        // Initial width is <= 0 because AutoSizer hasn't kicked in yet.
        // So let's wait until the width is set by AutoSizer before we do any animating.
        if (previousWidth.current <= 0) {
            previousWidth.current = currentWidth
            setWidth(currentWidth)
            return
        }

        // Just to be safe
        if (previousWidth.current === currentWidth) return

        previousWidth.current = currentWidth

        let stale = false

        const run = async () => {
            await animate.start({opacity: 0.5})
            if (stale) return
            setWidth(currentWidth)
            await animate.start({opacity: 1})
        }

        run()

        return () => {
            stale = true
        }
    }, [currentWidth, animate])

    const transition = {...profilesGridTransition, duration: profilesGridTransition.duration / 2}

    return {
        width,
        animationProps: {
            animate,
            transition,
        },
    }
}

export const HomeProfilesGrid = React.forwardRef<ProfilesGrid, ProfilesGridProps>(
    (
        {
            data,
            sort = true,
            rowLimit,
            rowCount: propRowCount,
            width: propWidth,
            maxHeight: propMaxHeight,
            padding: paddingProp = 0,
            onScroll,
        },
        ref,
    ) => {
        const {
            userSettings: {sortMode},
        } = UserSettings.useContainer()

        const [dataEditValues] = useOrganisationContext.useDataEditValues()
        const [dataEditFields] = useOrganisationContext.useDataEditFields()
        const [profileLines] = useOrganisationContext.useProfileLines()
        const {currentOrganisation} = useCurrentOrganisation()

        const {label: allPeopleLabel} = useAllHomeItem()
        const sortedPorfiles = useSortProfiles(data.profiles)
        const profiles = sort ? sortedPorfiles : data.profiles

        const lines = getThumbLinesByProfile(
            {...data, dataEditValues, dataEditFields, profileLines},
            {
                numberOfLines: 2,
                removeProfileLineGaps: currentOrganisation?.appFeatures?.masterDirectory,
            },
        )

        const padding = getPaddingObject(paddingProp)
        const computedWidth = propWidth - padding.horizontal
        const maxHeight = propMaxHeight ? propMaxHeight - padding.vertical : undefined

        const {width, animationProps} = useFadeWidthChange(computedWidth)

        const {columnCount} = gridSizeFor(computedWidth)

        let rowCount = propRowCount || Math.ceil(profiles.length / columnCount)
        if (rowLimit) rowCount = rowCount === 1 ? rowCount : 2
        const contentHeight = rowCount * PHOTO_HEIGHT + rowCount * SPACING

        const height = maxHeight ? Math.min(contentHeight, maxHeight) : contentHeight

        const itemData: ItemData = {
            data,
            lines,
            columnCount,
            rowLimit,
            label: allPeopleLabel,
        }

        // We're calculating the columnCount here based on `propWidth`, not the delayed
        // `width` (from our useFadeWidthChange hook), because we want the actionBar to
        // move with the profile, not when the profile grid fades.
        const propColumnCount = Math.floor((propWidth + SPACING) / (PHOTO_WIDTH + SPACING))
        useSetActionBarWidth(propColumnCount * (PHOTO_WIDTH + SPACING) - 64 - SPACING)

        const gridRef = useRef<FixedSizeGrid>(null)

        useImperativeHandle(ref, () => ({
            scrollToLetter: (letter) => {
                scrollToLetter({
                    columnCount,
                    letter,
                    gridRef,
                    profiles,
                    sortMode,
                })
            },
        }))

        return (
            <PaddingContext.Provider value={padding}>
                <motion.div {...animationProps}>
                    <FixedSizeGrid
                        columnCount={columnCount}
                        columnWidth={PHOTO_WIDTH + SPACING}
                        height={height}
                        itemData={itemData}
                        rowCount={rowCount}
                        rowHeight={ROW_HEIGHT}
                        width={width + SPACING}
                        outerElementType={OuterElement}
                        innerElementType={InnerElement}
                        ref={gridRef}
                        overscanRowCount={5}
                        onScroll={onScroll}
                    >
                        {Cell}
                    </FixedSizeGrid>
                </motion.div>
            </PaddingContext.Provider>
        )
    },
)
