import {Button, Panel, Typography, useAnalyticsEventTracker} from 'nf-ui'
import SvgFilter from 'nf-ui/Icons/Filter'
import pluralize from 'pluralize'
import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {useProfileFilter} from '~/components/useProfileFilter'
import {OptionType, SortList} from '../SortList'
import {PhotoFilter} from '../usePhotoFilter'
import {useProfileSort} from '../useProfileSort'

interface HeaderProps {
    count: number
    profileFilter: ReturnType<typeof useProfileFilter>
    profileSort: ReturnType<typeof useProfileSort>
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 8px 0;
`

const FiltersOptions: OptionType<PhotoFilter>[] = [
    {title: 'View all', filter: 'ALL', analyticsEvent: 'filter_view_all'},
    {title: 'View unmatched', filter: 'UNMATCHED', analyticsEvent: 'filter_view_unmatched'},
    {title: 'View matched', filter: 'MATCHED', analyticsEvent: 'filter_view_matched'},
]

const SortOptions: OptionType<PhotoFilter>[] = [
    {title: 'Unmatched', sort: 'UNMATCHED', analyticsEvent: 'sort_unmatched'},
    {title: 'Date added (newest first)', sort: 'DATE_DESC', analyticsEvent: 'sort_date_added'},
    {title: 'Alphabetical (A-Z)', sort: 'NAME_ASC', analyticsEvent: 'sort_date_added_alphabetical'},
]

const Header: React.FC<HeaderProps> = ({count, profileFilter, profileSort}) => {
    const trackAnalyticsEvent = useAnalyticsEventTracker()
    const [open, setOpen] = useState(false)
    const targetRef = useRef<HTMLButtonElement>(null)

    const isOptionChecked = (option: OptionType<PhotoFilter>) =>
        option.filter === profileFilter.filter || option.sort === profileSort.sort

    return (
        <Container>
            <Typography.Label>
                {count} {pluralize('person', count)}
            </Typography.Label>
            <Button variant="tertiary" ref={targetRef} onClick={() => setOpen(true)}>
                <Button.Icon icon={SvgFilter} /> Sort
            </Button>
            <Panel targetRef={targetRef} open={open} onClose={() => setOpen(false)}>
                <SortList
                    heading="Filter People"
                    options={FiltersOptions}
                    onClick={(row) => {
                        if (row.filter) {
                            row.analyticsEvent &&
                                trackAnalyticsEvent('select_profiles_sort', {option: row.analyticsEvent})
                            profileFilter.setFilter(row.filter)
                        }
                        setOpen(false)
                    }}
                    isOptionChecked={isOptionChecked}
                />
                <SortList
                    heading="Sort People"
                    options={SortOptions}
                    onClick={(row) => {
                        if (row.sort) {
                            row.analyticsEvent &&
                                trackAnalyticsEvent('select_profiles_sort', {option: row.analyticsEvent})
                            profileSort.setSort(row.sort)
                        }
                        setOpen(false)
                    }}
                    isOptionChecked={isOptionChecked}
                />
            </Panel>
        </Container>
    )
}

export {Header}
