import * as React from 'react'
const SvgScenariosBold = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(function SvgScenariosBold(
    props,
    svgRef,
) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" ref={svgRef} {...props}>
            <path
                d="M12.848 15.758H6.35c-1.535 0-2.814-1.227-2.904-2.758l-.005-.172v-.29h-.29c-1.536 0-2.815-1.226-2.905-2.758l-.005-.17V3.17c0-1.552 1.225-2.833 2.74-2.923l.17-.005H9.55c1.616 0 2.91 1.214 3 2.756l.006.173v.29h.29c1.536 0 2.815 1.226 2.905 2.758l.005.17v6.44c0 1.552-1.225 2.834-2.74 2.924l-.17.005zM9.55 11.51c1.06 0 1.863-.757 1.934-1.756l.005-.145V3.171c0-1.022-.755-1.825-1.79-1.896l-.149-.005h-6.4c-.996 0-1.857.784-1.933 1.76l-.006.14v6.44c0 .992.816 1.821 1.798 1.894l.141.006h6.4zm3.297 3.219c.981 0 1.764-.77 1.837-1.752l.005-.149V6.39c0-1.003-.736-1.82-1.697-1.895l-.145-.006h-.291v5.12c0 1.566-1.237 2.835-2.828 2.924l-.178.004H4.509v.291c0 1.003.736 1.82 1.696 1.895l.146.006h6.497z"
                fill="#3C8EFF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.855 16l.176-.005C14.675 15.898 16 14.509 16 12.828V6.383l-.005-.179c-.097-1.657-1.48-2.985-3.146-2.985h-.049v-.055l-.005-.18C12.696 1.309 11.29 0 9.55 0H3.144l-.176.005C1.324.102 0 1.49 0 3.171v6.445l.005.179c.097 1.657 1.48 2.985 3.146 2.985H3.2v.055l.005.179C3.302 14.67 4.685 16 6.351 16h6.504zM.247 9.78c.09 1.532 1.369 2.757 2.904 2.757h.291v.291l.005.172c.09 1.531 1.369 2.758 2.904 2.758h6.497l.17-.005c1.514-.09 2.739-1.372 2.739-2.925V6.39l-.005-.171c-.09-1.532-1.369-2.757-2.904-2.757h-.291V3.17l-.005-.173C12.462 1.456 11.167.242 9.55.242h-6.4l-.17.005C1.468.337.243 1.618.243 3.171v6.438l.005.171zm9.482 2.753c1.59-.09 2.828-1.358 2.828-2.924V4.49h.29l.146.006c.961.076 1.697.892 1.697 1.895v6.438l-.005.15c-.073.982-.856 1.75-1.837 1.75H6.35l-.146-.005c-.96-.075-1.696-.892-1.696-1.895v-.29H9.55l.178-.005zm-4.978.247h4.807l.185-.005c1.713-.096 3.056-1.466 3.056-3.166V4.732h.044l.136.005c.826.067 1.469.772 1.469 1.653v6.434l-.005.14c-.066.861-.75 1.522-1.595 1.522H6.356l-.136-.005c-.826-.067-1.469-.772-1.469-1.653v-.048zm6.492-3.04c-.063.868-.758 1.527-1.692 1.527H3.156l-.132-.005c-.861-.066-1.57-.796-1.57-1.653V3.175l.006-.13c.068-.845.82-1.532 1.691-1.532h6.396l.141.004c.907.065 1.56.763 1.56 1.654v6.434l-.005.136zm.242.014c-.07.999-.874 1.756-1.934 1.756h-6.4l-.141-.006c-.982-.073-1.798-.902-1.798-1.895V3.171l.006-.14c.076-.977.937-1.76 1.933-1.76h6.4l.15.004c1.034.071 1.79.874 1.79 1.896v6.438l-.006.145z"
                fill="#3C8EFF"
            />
        </svg>
    )
})
export default SvgScenariosBold
