import {DataSourceType} from '~/globalTypes'

const INTEGRATION_DATASOURCES = [
    DataSourceType.ADP,
    DataSourceType.PAYCHEX,
    DataSourceType.PAYLOCITY,
    DataSourceType.WORKDAY_REPORT,
]

const IntegrationNames: Record<string, string> = {
    [DataSourceType.ADP]: 'ADP',
    [DataSourceType.PAYCHEX]: 'Paychex',
    [DataSourceType.PAYLOCITY]: 'Paylocity',
    [DataSourceType.DIRECT_UPLOAD]: 'Uploaded file',
    [DataSourceType.GOOGLE_SHEETS]: 'Google Sheets',
    [DataSourceType.WORKDAY_REPORT]: 'Workday',
}

const EditIntegrationLink: Record<string, {link: string; analyticsEvent: string}> = {
    [DataSourceType.ADP]: {
        link: '/login/adp',
        analyticsEvent: 'click_edit_in_adp',
    },
}

export const isIntegration = (type?: DataSourceType | null) => (type ? INTEGRATION_DATASOURCES.includes(type) : false)
export const isAdditional = (type?: DataSourceType | null) => !isIntegration(type)
export const getHRISName = (type?: DataSourceType | null) => (type ? IntegrationNames[type] || type : 'Other')
export const getEditIntegrationLink = (type?: DataSourceType | null) =>
    type ? EditIntegrationLink[type] || null : null
