import {motion, Variants} from 'framer-motion'
import styled from 'styled-components'

export const listVariants: Variants = {
    open: {
        height: 'auto',
        transition: {
            duration: 0.3,
        },
    },
    collapsed: {
        height: 0,
        transition: {
            duration: 0.3,
        },
    },
}

export const animateChildVariants: Variants = {
    open: {
        opacity: 1,
        transition: {
            duration: 0.3,
        },
    },
    collapsed: {
        opacity: 0,
        transition: {
            duration: 0.3,
        },
    },
}

export const AnimatedList = styled(motion.div)`
    position: relative;
    overflow: hidden;
`

AnimatedList.defaultProps = {
    variants: listVariants,
    initial: 'collapsed',
    animate: 'open',
    exit: 'collapsed',
}
