import {gql, useMutation} from '@apollo/client'
import {Typography} from 'nf-ui'
import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {useQueryParam} from 'use-query-params'
import {PageLoading} from '~/components/PageLoading'
import {Token} from '~/components/TokenContext'
import {VerifySignIn as VerifySignInMutation, VerifySignInVariables} from './__types__/VerifySignIn'

const VERIFY_SIGN_IN = gql`
    mutation VerifySignIn($token: String!) {
        verifySignIn(token: $token) {
            accessToken
        }
    }
`

const useVerifySignIn = () => {
    const {setToken} = Token.useContainer()
    const {push} = useHistory()

    return useMutation<VerifySignInMutation, VerifySignInVariables>(VERIFY_SIGN_IN, {
        onCompleted: (result) => {
            setToken(result.verifySignIn.accessToken)
            push('/')
        },
        onError: () => {
            push('/login')
        },
    })
}

export const VerifySignIn: React.FC = () => {
    const [token] = useQueryParam<string>('token')
    const [verifySignIn] = useVerifySignIn()

    useEffect(() => {
        if (token) verifySignIn({variables: {token}})
    }, [verifySignIn, token])

    if (!token) return <Typography.Heading>Error: Missing email token</Typography.Heading>

    return <PageLoading />
}
